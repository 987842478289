<cashaa-sidenav>
    <section class="mh-100">
        <div class="bg-white p-4">
            <div class="d-flex mr-3" style="height: 47px;">
                <div class="text-right mr-4 mt-2 ng-star-inserted">
                    <h4 class="font-weight-bold sm-profile-heading-mobile-2">KYC Verification</h4>
                </div>
            </div>
        </div>

        <div class="p-5 w-phone-100-personal-kyc2">
            <h5 class="font-weight-bolder">Get Verified</h5>
            <hr />
            <p *ngIf="!isStaking" class="mb-5 font-weight-bolder">
                Cashaa requires additional user information before any deposits or withdrawals can be made in order to meet KYC/AML legal requirements. There are multiple tier levels of funding limits, each with increasing amounts of deposit/withdraw limits.
            </p>
            <p *ngIf="isStaking" class="mb-5">
                Complete KYC to stake and receive the bonus rewards.
            </p>
            <div class="text-center" *ngIf="loading">
                <mdb-spinner spinnerColor="blue"></mdb-spinner>
            </div>
            <div class="text-center" *ngIf="loading2">
                <mdb-spinner spinnerColor="blue"></mdb-spinner>
            </div>
            <div class="w-90 mx-auto stepper w-phone-100-personal-kyc2 " *ngIf="!loading">
                <mdb-stepper #stepper [linear]="true">
                    <mdb-step name="Basic Information" class="step">
                        <div class="sm-div-height sm-div-height-mobile">
                            <cashaa-kyc-personal-information (continue)="onInformationContinue($event)" [isReadOnly]="isReadOnly" [data]="data">
                            </cashaa-kyc-personal-information>
                        </div>
                    </mdb-step>
                    <mdb-step name="Personal Address" class="step">
                        <cashaa-kyc-personal-address [data]="data" [isReadOnly]="isReadOnly" (continue)="onAddressContinue($event)">
                        </cashaa-kyc-personal-address>
                    </mdb-step>
                    <mdb-step name="Identity Proof" class="step" *ngIf="manualKyc && (!isKycDone && !completed)">
                        <cashaa-kyc-personal-identity-proof (continue)="onIdentityContinue($event)">
                        </cashaa-kyc-personal-identity-proof>
                    </mdb-step>
                    <mdb-step name="Address Proof" class="step" *ngIf="manualKyc && (!isKycDone && !completed)">
                        <cashaa-kyc-personal-address-proof (continue)="onAddressProofContinue($event)">
                        </cashaa-kyc-personal-address-proof>
                    </mdb-step>
                    <mdb-step name="Selfie" class="step" *ngIf="manualKyc && (!isKycDone && !completed)">
                        <cashaa-kyc-personal-selfie-upload (continue)="onSelfieyContinue($event)" [identityDocumentType]="identityDocumentType" [addressDocumentType]="addressDocumentType">
                        </cashaa-kyc-personal-selfie-upload>
                    </mdb-step>
                    <mdb-step name="Review" class="step">
                        <cashaa-kyc-personal-review [data]="data" [completed]="completed" [manualKyc]="manualKyc" [isKycDone]="isKycDone" [createKycDone]="createKycDone">
                        </cashaa-kyc-personal-review>
                    </mdb-step>
                </mdb-stepper>
            </div>
        </div>
    </section>
</cashaa-sidenav>
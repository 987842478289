import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder } from "@angular/forms";
import { ValidatorUtility } from "src/app/core/utility/validator.utility";
import { AccountService } from "../sevices/account.service";
import { ActivatedRoute, Router } from "@angular/router";
import { APP_ROUTES, UNABLE_TO_PERFORM_ERROR } from "src/app/app.constants";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "cashaa-account-forget-password",
  templateUrl: "./account-forget-password.component.html",
  styleUrls: ["./account-forget-password.component.scss"],
})
export class AccountForgetPasswordComponent implements OnInit {
  /** Form group */
  form: FormGroup;

  /** Whether to show loading indicator or not */
  showLoadingIndicator = false;

  /** Erorr message if any */
  errorMessage: string;

  /**Sucessfull email sent */
  islinkSent: boolean;

  /** new user reset password */
  isrestnew: string;

  constructor(
    private fb: FormBuilder,
    private accountService: AccountService,
    private router: Router,
    private toastrService: ToastrService,
    private activatedRoute: ActivatedRoute,
  ) {
    this.createForm();
  }

  ngOnInit(): void {
    this.islinkSent = false;
    this.accountService.setDisableCross(false);
    this.activatedRoute.queryParams.subscribe(params => {
      this.isrestnew = params['c'];

    });
  }

  /**
   * Create Forget password form
   */
  createForm(): void {
    this.form = this.fb.group({
      email: ["", [ValidatorUtility.Required]],
    });
  }

  /**
   * On Forget password form submit
   */
  onForgetPasswordSubmit(): void {
    this.showLoadingIndicator = true;
    this.errorMessage = undefined;
    this.accountService.forgetPassword(this.form.value.email).subscribe(
      (res) => {
        this.showLoadingIndicator = false;
        this.islinkSent = true;
        //this.router.navigateByUrl(APP_ROUTES.SIGN_IN);
      },
      (err) => {
        this.showLoadingIndicator = false;
        if (err.error.message) {
          this.toastrService.error(err.error.message);
          this.errorMessage = err.error.message;
        } else {
          this.toastrService.error(UNABLE_TO_PERFORM_ERROR);
        }
      }
    );
  }
}
import { Component, OnInit } from '@angular/core';
import { NavigationService } from 'src/app/core/services/navigation/navigation.service';

@Component({
  selector: 'cashaa-invite-home',
  templateUrl: './invite-home.component.html',
  styleUrls: ['./invite-home.component.scss']
})
export class InviteHomeComponent implements OnInit {

  constructor(private navigationService: NavigationService) { }

  ngOnInit(): void {
  }

  backRoute() {
    this.navigationService.back();
  }
  
}

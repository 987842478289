import { FormBuilder } from "@angular/forms";
import { IFormSelect } from "src/app/core/models/form-select";
import { FormGroup } from "@angular/forms";
import { KycService } from "./../services/kyc.service";
import { CreateKYC } from "src/app/core/models/create-kyc";
import { ValidatorUtility } from "../../core/utility/validator.utility";
import { Component, OnInit, Input, OnChanges } from "@angular/core";
import { UNABLE_TO_PERFORM_ERROR } from "src/app/app.constants";
import { ToastrService } from "ngx-toastr";
import { FileUpload, IFile } from "src/app/core/models/Lerex/enum-load";
import { DomainService } from "src/app/core/services/domain/domain.service";
const addressdocumentTypes = require("../../../assets/json/addressProofDocumentType.json");
const identitydocumentTypes = require("../../../assets/json/identityProofDocumentType.json");
@Component({
  selector: "cashaa-kyc-personal-review",
  templateUrl: "./kyc-personal-review.component.html",
  styleUrls: ["./kyc-personal-review.component.scss"],
})
export class KycPersonalReviewComponent {
  @Input()
  data: any;

  @Input()
  completed = false;

  loading = false;
  loader = false;
  /**  error message if any */
  errorMessage: string;

  @Input()
  isKycDone = false;
  @Input()
  manualKyc = false;
  @Input()
  createKycDone = false;
  kycdata: any;
  kycdocuploaded = false;
  createKycData: CreateKYC;
  addressProofOptions: Array<any>;
  identityProofOptions: Array<any>;

  constructor() {
  }
}

import { ChangePasswordRequest } from "./../../core/models/chnagepassword";
import { ValidatorUtility } from "./../../core/utility/validator.utility";
import { SettingsService } from "./../services/settings.service";
import { FormGroup, FormBuilder } from "@angular/forms";
import {
  Component,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { SBItemComponent } from "ng-uikit-pro-standard";
import {
  APP_ROUTES,
  PASSWORD_MESG,
  SEESION_FLAG,
  UNABLE_TO_PERFORM_ERROR,
  USER_ID,
} from "src/app/app.constants";
import { Router } from "@angular/router";
import { ActivityService } from "src/app/activity/services/activity.service";

@Component({
  selector: "cashaa-settings-password",
  templateUrl: "./settings-password.component.html",
  styleUrls: ["./settings-password.component.scss"],
})
export class SettingsPasswordComponent implements OnInit {
  /** is opened */
  isOpen = false;
  @ViewChildren(SBItemComponent) collapses: QueryList<SBItemComponent>[];

  /** is some call in progress */
  isInProgress = false;

  /** form group */
  form: FormGroup;

  errorMessage: string;

  passwordInvalid: boolean;

  constructor(
    private fb: FormBuilder,
    private settingsService: SettingsService,
    private toastrService: ToastrService,
    private router: Router,
    private activityService: ActivityService
  ) {
    this.createForm();
  }

  ngOnInit(): void {}

  /**
   * Create new form
   */
  createForm(): void {
    this.form = this.fb.group({
      oldpassword: ["", ValidatorUtility.PasswordValidator],
      newpassword: [""],
    });
  }

  /**
   * o password change form submit
   */
  onPasswordFormSubmit(): void {
    this.errorMessage = "";
    this.isInProgress = true;
    const data = new ChangePasswordRequest(
      this.form.controls["oldpassword"].value,
      this.form.controls["newpassword"].value
    );

    this.settingsService.changePassword(data).subscribe(
      (res) => {
        this.form.reset();
        this.isInProgress = false;
        this.collapse(true);
        localStorage.clear();
        localStorage.setItem(SEESION_FLAG, "false");
        sessionStorage.clear();
        this.activityService.refreshToken(USER_ID).subscribe( res=> {
          
        })
        this.router.navigateByUrl(APP_ROUTES.MESG_BOX+"?source=reset-password");
      },
      (err) => {
        if (err.error.message) {
          this.errorMessage = err.error.message;
        } else {
          this.toastrService.error(UNABLE_TO_PERFORM_ERROR);
        }
        this.isInProgress = false;
      }
    );
  }

  collapse(collapsed: boolean) {
    this.collapses.forEach((el: any) => {
      el.toggle(collapsed);
    });
  }

  passwordValid(event) {
    this.passwordInvalid = event;
  }
}

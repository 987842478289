import { Component, OnInit } from '@angular/core';
import { ICustomer } from 'src/app/core/models/recipient/recipient-add-usd';
import { ModalService } from 'src/app/core/services/modal/modal.service';

@Component({
  selector: 'cashaa-add-customer-home',
  templateUrl: './add-customer-home.component.html',
  styleUrls: ['./add-customer-home.component.scss']
})
export class AddCustomerHomeComponent implements OnInit {
  constructor(private modalService: ModalService) {}
  ngOnInit(): void {}

  /**
   * Open add recipient modal
   */
  addCustomer(): void {
    this.modalService.openRecipientBrokerModal("broker");
  }

  editCustomer(data:{
    selectedCustomer: ICustomer;
    isUploadKyc: boolean;
  }) {
    this.modalService.openCustomerEditModal(data);
  }

}

<div class="sm-form-padding">
  <p class="mb-4">Authentication Code</p>
  <div class="row google-code" (paste)="onCodePaste($event)">
    <div class="col-1 sm-code">
      <input type="text" maxlength="1" [(ngModel)]="code[0]" (input)="checkIfCodeIsFilled(0)" autofocus #inputs
        (change)="removeError()" />
    </div>
    <div class="col-1 sm-code">
      <input type="text" maxlength="1" [(ngModel)]="code[1]" (input)="checkIfCodeIsFilled(1)" #inputs
        (change)="removeError()" (keyup)="removeBack($event, 0)" />
    </div>
    <div class="col-1 sm-code">
      <input type="text" maxlength="1" [(ngModel)]="code[2]" (input)="checkIfCodeIsFilled(2)" #inputs
        (change)="removeError()" (keyup)="removeBack($event, 1)" />
    </div>
    <div class="col-1 sm-code">
      <input type="text" maxlength="1" [(ngModel)]="code[3]" (input)="checkIfCodeIsFilled(3)" #inputs
        (change)="removeError()" (keyup)="removeBack($event, 2)" />
    </div>
    <div class="col-1 sm-code">
      <input type="text" maxlength="1" [(ngModel)]="code[4]" (input)="checkIfCodeIsFilled(4)" #inputs
        (change)="removeError()" (keyup)="removeBack($event, 3)" />
    </div>
    <div class="col-1 ">
      <input type="text" maxlength="1" [(ngModel)]="code[5]" (input)="checkIfCodeIsFilled(5)" #inputs
        (change)="removeError()" (keyup)="removeBack($event, 4)" />
    </div>
  </div>
</div>
<!-- <strong>{{ledger | json }}</strong> -->
<section class="activity-box p-3">
  <div class="date p-2">
    Thursday, 12th
  </div>
  <div class="details mb-3">
    <mdb-accordion [multiple]="false" aria-multiselectable="false">
      <mdb-accordion-item class="no-collase">
        <mdb-accordion-item-head class="p-2" mdbWavesEffect>
          <mdb-icon fas icon="cloud-download-alt" class="pull-left"></mdb-icon>
          <div class="status pl-4 mb-2">
            To <b>{{ledger.fullName}}</b>
            <br>
            Spent
          </div>
          <div class="amount pr-1 mb-2">
            <b>{{ledger.amount}}</b><br>
            <span class="text-right">
              100EUR
            </span>
          </div>
        </mdb-accordion-item-head>
        <mdb-accordion-item-body class="border-top border-light px-4">
          <div class="container px-4 pt-2">
            <div class="row">
              <div class="col-md-3">
                Transaction Details :
              </div>

              <div class="col-md-4">
                Conversion Fee <br> <b>0.12 EUR</b>
              </div>
              <div class="col-md-4">
                Exchange Rate <br> <b>4.5875</b>
              </div>
              <div class="col-md-1">

                <div class="text-right">
                  <i class="fa fa-ellipsis-h border p-2 m-3 rounded border-dark"></i></div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-md-3"></div>

              <div class="col-md-4">
                Date <br> <b>{{ledger.created}}</b>
              </div>
              <div class="col-md-4">
                Transaction Type <br> <b>ATM withdrawal</b>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-md-3"></div>
              <div class="col-md-4">
                Status <br> <b>Complete</b>
              </div>
              <div class="col-md-4">
                Transaction ID <br> <b>{{ledger.id}}</b>
              </div>
            </div>
            <div class="row mt-3">
              <button class="btn btn-outline-dark rounded" (click)="help()">Need any help?</button>
            </div>
          </div>
        </mdb-accordion-item-body>
      </mdb-accordion-item>
    </mdb-accordion>
  </div>
</section>
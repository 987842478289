export interface IAddRecipientUsd {
  currencyId?: string;
  recipient?: IRecipient;
  recipientAccount?: IRecipientAccount;
  recipientAgent?: IRecipientAgent;
  userType?: string;
  googleDriveUrl?:string;
}

export interface IRecipient {
  person?: IPerson;
}

export interface IPerson {
  firstName?: string;
  middleName?: string;
  lastName?: string;
  postalAddress?: IPostalAddress;
  mobile?: number;
  email?: string;
  countryOfBirth?: string;
  dob?: string;
  nickname?: string;
}

export interface IPostalAddress {
  addressLine1?: string;
  addressLine2?: string;
  addressLine3?: string;
  postalCode?: string;
  city?: string;
  province?: string;
  country?: string;
}

export interface IRecipientAccount {
  account?: IAccount;
}

export interface IAccount {
  type?: string;
  bankName?: string;
  accountNumber?: string;
  currencyCode?: string;
  routingNumber?: string;
  bankCode?: string
}

export interface IRecipientAgent {
  type?: string;
  name?: string;
  code?: string;
  countryCode?: string;
}

export interface IGBPEURO {
  currency?: string;
  userType?: string
  email?: string;
  fullName?: string;
  sortCode?: string;
  iban?: string;
  accountNo?: string;
  beneficiaryType?: string;
  bankName?: string;
  branchName?: string;
  city?: string;
  address1?: string;
  postalCode?: string;
  countryCode?: string;
  swiftCode?: string;
}

export class AddRecipientUsd implements IAddRecipientUsd {
  constructor(
    public currencyId?: string,
    public recipient?: IRecipient,
    public recipientAccount?: IRecipientAccount,
    public userType?: string,
    public recipientType?: string,
    public beneficiaryType?: string,
    public googleDriveUrl?:string,
  ) {}
}
export class Recipient implements IRecipient {
  constructor(public person?: IPerson) {}
}
export class Person implements IPerson {
  constructor(
    public firstName?: string,
    public middleName?: string,
    public lastName?: string,
    public mobile?: number,
    public email?: string,
    public nickname?: string,
    public postalAddress?: IPostalAddress
  ) {}
}
export class PostalAddress implements IPostalAddress {
  constructor(
    public addressLine1?: string,
    public addressLine2?: string,
    public addressLine3?: string,
    public postalCode?: string,
    public city?: string,
    public province?: string,
    public country?: string,
    public countryOfBirth?: string
  ) {}
}
export class Account implements IAccount {
  constructor(
    public type?: string,
    public bankName?: string,
    public accountNumber?: string,
    public currencyCode?: string,
    public routingNumber?: string,
    public bankCode?: string
  ) {}
}
export class RecipientAccount implements IRecipientAccount {
  constructor(public account?: IAccount) {}
}
export class RecipientAgent implements IRecipientAgent {
  constructor(
    public type?: string,
    public name?: string,
    public code?: string,
    public countryCode?: string
  ) {}
}
export class RecipientAddGbp implements IGBPEURO {
  constructor(
    public currency: string,
    public userType: string,
    public recipientType: number,
    public fullName?: string,
    public sortCode?: string,
    public iban?: string,
    public accountNo?: string,
    public beneficiaryType?: string,
    public bankName?: string,
    public branchName?: string,
    public city?: string,
    public email?: string,
    public swiftCode?: string,
    public address1?: string,
    public postalCode?: string,
    public countryCode?: string,
    
  ) {}
}

export interface ICustomer extends IGBPEURO  {
  customerId?: string;
  currencyId?: string;
  googleDriveUrl?: string;
  status?: number;
  verified?: boolean;
  identityProofFileName?: string;
  identityProofFilePath?: string;
  identityDocumentType?: string;
  addressProofFileName?: string;
  addressProofFilePath?: string;
  addressDocumentType?: string;
  selfieFileName?: string;
  selfieFilePath?: string;
  count?: number;
  failedCount?:number;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  postalAddress?: IPostalAddress;
  mobile?: number;
  email?: string;
  countryOfBirth?: string;
  dob?: string;
  nickname?: string;
  addressLine1?: string;
  addressLine2?: string;
  addressLine3?: string;
  postalCode?: string;
  city?: string;
  province?: string;
  country?: string;
  type?: string;
  bankName?: string;
  accountNumber?: string;
  currencyCode?: string;
  routingNumber?: string;
  bankCode?: string;
  isOperationCompleted?: boolean;
  name?:string;
  recipientType?:number; 
}

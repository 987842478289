import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StatementRoutingModule } from './statement-routing.module';
import { GenerateStatementComponent } from './generate-statement/generate-statement.component';
import { SharedModule } from '../shared/shared.module';
import { ToastrModule } from 'ngx-toastr';
import { MDBBootstrapModulesPro } from 'ng-uikit-pro-standard';
import { FormsModule } from '@angular/forms';


@NgModule({
  declarations: [GenerateStatementComponent],
  imports: [
    CommonModule,
    StatementRoutingModule,
    SharedModule,
    ToastrModule.forRoot(),
    MDBBootstrapModulesPro.forRoot(),
    FormsModule
  ]
})
export class StatementModule { }

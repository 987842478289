import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MDBBootstrapModulesPro } from "ng-uikit-pro-standard";
import { SharedModule } from "../shared/shared.module";
import { ReactiveFormsModule } from "@angular/forms";
import { ToastrModule } from "ngx-toastr";
import { SwapHomeComponent } from "./swap-home/swap-home.component";
import { SwapRoutingModule } from "./swap-routing.module";
import { QRCodeModule } from "angular2-qrcode";

@NgModule({
  declarations: [SwapHomeComponent],
  imports: [
    CommonModule,
    MDBBootstrapModulesPro.forRoot(),
    ToastrModule.forRoot(),
    SharedModule,
    ReactiveFormsModule,
    SwapRoutingModule,
    QRCodeModule,
  ],
})
export class SwapModule {}

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  api: "https://aws-user-api-dev.cashaa.com/api/v1",
  // api: "https://localhost:44376/api/v1",
  rateWebsocket: "",
  ipInfo: "https://ipinfo.io/?token=de58bb1860f4f7",
  whiteListUserId:"34b15f2b-fdad-4c05-8a37-12538ae2e100,ace9e0a0-5365-432f-8540-f694eab92521,babd0381-f60d-4464-85d7-e0e36db3c768,acf0bbb5-558e-4be7-82da-e0273d2e7a20",
  CAS:"https://explorer.binance.org/tx/",
  BNB:"https://explorer.binance.org/tx/",
  BCH:"https://blockexplorer.one/bitcoin-cash/testnet/tx/",
  XRP:"https://blockexplorer.one/xrp/mainnet/tx/",
  LTC:"https://blockexplorer.one/litecoin/testnet/tx/",
  DOG:"https://blockexplorer.one/dogecoin/testnet/tx/",
  ETH:"https://blockexplorer.one/ethereum/rinkeby/tx/",
  BTC:"https://blockexplorer.one/bitcoin/testnet/tx/",
  USDT:"https://blockexplorer.one/ethereum/rinkeby/tx/",
  LINK: "https://rinkeby.etherscan.io/tx/",
  USDC: "https://rinkeby.etherscan.io/tx/",
  UNI: "https://rinkeby.etherscan.io/tx/",
  NETWORK_ERC20: "https://rinkeby.etherscan.io/tx/",
  NETWORK_BEP20: "https://testnet.bscscan.com/tx/",
  NETWORK_POLYGON: "https://mumbai.polygonscan.com/tx/",
  NETWORK_TRC20: "https://tronscan.org/#/transaction/",
  HEADER_SIGN_KEY:"IntcIml2XCI6XCJXdU9wTU9wUFNYZTVXL3V3UnZGN2J3PT1cIixcInZhbHVlXCI6XCJTd21MNzkvbnY1WDlzNVIvR21COWI0cDRzQksrdS9aVXVuY09BK3Y1bkNZPVwiLFwibWFjXCI6XCJlZTA2ZGViYWI2NmNiY2UxMTg5ZTQ5OWVkZjdhZDgwZmYyYTU3NDc5Yzk0YmQ2N2RkNjljZTNlZDRhZTkxM2UzXCJ9Ig==",
  CASHAA_USER_ID_TRC_ENABLED: "381edd05-e7be-4382-b642-28d9bdf102d5"
};


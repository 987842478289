import { DepositHomeComponent } from "./deposit-home/deposit-home.component";
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthGuardService } from "./../core/services/auth-guard/auth-guard.service";

const routes: Routes = [
  {
    path: "deposit",
    component: DepositHomeComponent,
    canActivate: [AuthGuardService],
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DepositRoutingModule { }

import { Injectable } from '@angular/core'
import { Location } from '@angular/common'
import { Router } from '@angular/router'

@Injectable({ providedIn: 'root' })
export class NavigationService {
  constructor(private location: Location, private router: Router) {
  }
  back(): void {
    const state : any = this.location.getState();
    if (state && (state.navigationId === 1 || sessionStorage.getItem('isRedirect'))) {
        sessionStorage.removeItem('isRedirect');
        this.router.navigateByUrl("/activity");
    } else {
      this.location.back();
    }
  }
}
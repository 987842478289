import { ICurrency } from "./../../core/models/currency";
import { DepositService } from "./../services/deposit.service";
import { ValidatorUtility } from "src/app/core/utility/validator.utility";
import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy,
} from "@angular/core";
import { FormGroup, FormBuilder } from "@angular/forms";
import {
  CreateDepositAmount,
  IDepositAmount,
} from "src/app/core/models/deposit";
import { ActivatedRoute } from "@angular/router";
import { DomainService } from "src/app/core/services/domain/domain.service";
import { SubSink } from "subsink";
import { SUBSCRIPTION_BUSINESS_PLAN, SUBSCRIPTION_PERSONAL_PLAN, USER_ID, USER_TYPE } from "src/app/app.constants";
import { environment } from "./../../../environments/environment";
import { UserSubscriptionPlan } from "src/app/core/models/account-upgrade/subscription-plan-response";
import { AccountUpgradeService } from "src/app/account-upgrade/services/account-upgrade.service";
import { RecipientType } from "src/app/core/enums/recipientTypeEnum";
import { FiatService } from "src/app/fiat/services/fiat.service";
import { FiatRecipient } from "src/app/core/models/fIat/fiat-pay";
import { IFiatSendFees } from "src/app/core/models/fiat-send";
import { param } from "jquery";


@Component({
  selector: "cashaa-deposit-amount",
  templateUrl: "./deposit-amount.component.html",
  styleUrls: ["./deposit-amount.component.scss"],
})
export class DepositAmountComponent implements OnInit, OnDestroy {
  form: FormGroup;

  fee = 0;

  currentCur: ICurrency;

  fiatCurrency: ICurrency[] = [];

  setCurrency;

  userId = sessionStorage.getItem(USER_ID);

  userType = sessionStorage.getItem(USER_TYPE)

  enableGBP = environment.whiteListUserId;

  @Output() continue = new EventEmitter<string>();
  @Output() hideAddSender = new EventEmitter<string>();

  initialValue;

  subsink = new SubSink();

  currencySource: string = "deposit";

  errorMessage: string;

  isGBPActive: boolean = true;

  currentUserPlanDetail: UserSubscriptionPlan;

  isPremiumPlan: boolean = true;

  feesData: IFiatSendFees;

  constructor(
    private fb: FormBuilder,
    private depositService: DepositService,
    private domainService: DomainService,
    private activateRoute: ActivatedRoute,
    private upgradeService: AccountUpgradeService,
    private fiatService: FiatService
  ) { }

  ngOnInit(): void {
    // if (this.userType === '4') {
    //   this.isGBPActive = false;
    //   this.initialValue = "2633816a-dc95-4015-2536-08d7b39928d1";
    // } else {
    //   this.isGBPActive = false;
    //   this.initialValue = "2633816a-dc95-4015-2536-08d7b39928d1";
    // }
    this.activateRoute.queryParams.subscribe((params) => {
      let activeCur = params["cur"];
      this.initialValue = activeCur.id
    });
   
    this.createForm();
    this.activateRoute.queryParams.subscribe(param => {
      if(param && param.recipientType){
        if(param.recipientType === '2'){
          this.hideAddSender.emit('true');
        }
        if(param.recipientType === '0'){
          param.recipientType = '1';
        }
      this.form.get('recipientType').setValue(param.recipientType);
      }
    })
    this.subsink.add(
      this.domainService
        .getFiatCurrency()
        .subscribe((currency: ICurrency[]) => {
          if (currency !== undefined) {
            this.fiatCurrency = currency;
            this.activateRoute.queryParams.subscribe((params) => {
              let activeCur = params["cur"];
              if (
                (activeCur === "EUR" ||
                  activeCur === "USD" ||
                  activeCur === "GBP") &&
                this.fiatCurrency.length > 0
              ) {
                const curData = this.fiatCurrency.filter(
                  (cur) => cur.symbol === activeCur
                );
                this.initialValue = curData[0].id;
                this.currentCur = curData[0];
              }
            });
          }
        })
    );

    this.subsink.add(
      this.depositService.getDepositStatus().subscribe((data) => {
        if (data) {
          this.form.reset();
          this.errorMessage = null;
          // if (this.userType === '4') {
          //   this.isGBPActive = false;
          //   this.initialValue = "2633816a-dc95-4015-2536-08d7b39928d1";
          // } else {
          //   this.isGBPActive = false;
          //   this.initialValue = "2633816a-dc95-4015-2536-08d7b39928d1";
          // }
          this.form.patchValue({
            currency: this.currentCur.id,
            recipientType: "1"
          });
          setTimeout(() => {
            this.initialValue = null;
            this.errorMessage = null;
          }, 1000);
        }
      })
    );
    setTimeout(() => {
      this.initialValue = null;
      this.errorMessage = null;
    }, 1000);

    this.subsink.add(this.upgradeService.getUserActionSubscriptionPlan().subscribe((data: UserSubscriptionPlan) => {
      this.currentUserPlanDetail = data;
    }));

    this.subsink.add(this.form.controls["recipientType"].valueChanges.subscribe((val) => {
      console.log(val);
      if (Number(val) === RecipientType.Others) {
        this.hideAddSender.emit('true');
        if (this.userType === '4') {
          if (SUBSCRIPTION_PERSONAL_PLAN.BASIC === this.currentUserPlanDetail.planName) {
            this.isPremiumPlan = false;
          }
        } else {
          if (SUBSCRIPTION_BUSINESS_PLAN.BASIC === this.currentUserPlanDetail.planName) {
            this.isPremiumPlan = false;
          }
        }

      } else {
        this.hideAddSender.emit('false');
        this.isPremiumPlan = true;
      }
      if (val) {
        this.getFee(this.currentCur.symbol === 'EUR' ? 1 : this.currentCur.symbol === 'GBP' ? 4: Number(this.form.get('deliveryMechanism').value), Number(val));
      }
    }));
    this.subsink.add(this.form.controls["deliveryMechanism"].valueChanges.subscribe((val) => {
      if (val) {
        this.getFee(Number(val), Number(this.form.controls.recipientType.value));
      }
    }));
  }

  /**
   * Create new form
   */
  createForm(): void {
    this.form = this.fb.group({
      currency: ["", [ValidatorUtility.Required]],
      amount: [
        "",
        [
          ValidatorUtility.Required,
          ValidatorUtility.OnlyNumberRequiredWithFormat,
        ],
      ],
      recipientType: ["1"],
      deliveryMechanism: []
    });
    this.listenToCurrencyChange();
  }

  getFee(deliveryMechanism: number, recipientType: number): void {
    if (deliveryMechanism && recipientType) {
      this.depositService.getFiatSendFees(this.currentCur.symbol, 2, deliveryMechanism, 2,
        Number(this.userType === '6' ? '5' : this.userType), recipientType).subscribe((data: IFiatSendFees) => {
          this.feesData = data;
          let amount = Number(this.form.controls.amount.value.replaceAll(",", ""));
          if (amount) {
            if ((amount * this.feesData.feeInPercent) > this.feesData.minimumFee) {
              this.fee = amount * this.feesData.feeInPercent;
            } else {
              this.fee = this.feesData.minimumFee;
            }
          }
          this.showErrorMsg();
        });
    }
  }

  listenToCurrencyChange(): void {
    this.subsink.add(this.form.controls.currency.valueChanges.subscribe((newValue) => {
      const deposit = new CreateDepositAmount(
        this.form.controls.currency.value,
        this.form.controls.amount.value,
        0,
        this.currentCur.symbol === 'EUR' ? 1 : this.currentCur.symbol === 'GBP' ? 4: Number(this.form.get('deliveryMechanism').value)
      );
      this.depositService.emitDepositAmount(deposit);
    }));
    this.subsink.add(this.form.controls.amount.valueChanges.subscribe((newValue) => {
      this.showErrorMsg();
    }));
    this.subsink.add(this.form.controls.deliveryMechanism.valueChanges.subscribe((newValue) => {
      this.showErrorMsg();
    }));
  }

  showErrorMsg() {
    if(this.feesData && this.feesData.minSendAmount) {
    let value = this.form.controls.amount.value;
    if (value) {
      let amount = Number(value.replaceAll(",", ""))
      if (amount < this.feesData.minSendAmount) {
          this.errorMessage = "Minimum deposit amount is " +this.feesData.minSendAmount + " " + this.currentCur.symbol;
      
      } else {
        this.errorMessage = null;
      }
      if ((amount * this.feesData.feeInPercent) > this.feesData.minimumFee) {
        this.fee = amount * this.feesData.feeInPercent;
      } else {
        this.fee = this.feesData.minimumFee;
      }
    }
  }
  }

  onContinue(): void {
    let achAndWire =  Number(this.form.get('deliveryMechanism').value);
    const data: IDepositAmount = {
      amount: +this.form.controls.amount.value.replaceAll(",", ""),
      currency: this.form.controls.currency.value,
      fee: this.fee,
      deliveryMech: this.currentCur.symbol === 'EUR' ? 1 : this.currentCur.symbol === 'GBP' ? 4: Number(this.form.get('deliveryMechanism').value),
      symbol: this.currentCur.symbol,
      option:  achAndWire === 2 ? 3 : 4,
      recipientType: this.form.get('recipientType').value

    };
    this.depositService.emitDepositAmount(data);
    this.fiatService.emitCurrencySymbolEmitter(new FiatRecipient(data.symbol, undefined, Number(this.form.get('recipientType').value)))
    this.continue.emit(this.form.get('recipientType').value);
  }

  onCurrencyChange(cur: ICurrency): void {
    this.currentCur = cur;
    this.form.patchValue(
      {
        currency: cur.id,
      },
      {
        emitEvent: false,
      }
    );

    this.form.controls.deliveryMechanism.setValue(undefined);
    if (cur.symbol === 'USD') {
      this.form.controls.deliveryMechanism.setValidators(ValidatorUtility.Required);
    } else {
      this.form.controls.deliveryMechanism.clearValidators();
    }
    this.form.controls.deliveryMechanism.updateValueAndValidity();
    this.getFee(this.currentCur.symbol === 'EUR' ? 1 :this.currentCur.symbol === 'GBP' ? 4: Number(this.form.get('deliveryMechanism').value), Number(this.form.controls.recipientType.value));
  }

  getAmount(): boolean {
    let amount: number = Number(this.form.controls.amount.value.replaceAll(",", ""));
     return  amount-this.fee > 0 ? true: false;
  }

  // isDeliveryMechanismSelected() {
  //   console.log(this.form.controls.deliveryMechanism.value);
  //   if(this.form.controls.deliveryMechanism.value) {
  //     return  this.form.controls.deliveryMechanism.value ? true : false;
  //   } else {
  //     return true;
  //   }
  // }

  ngOnDestroy() {
    this.subsink.unsubscribe();
  }
}

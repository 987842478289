import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { CreateFiatFlowPay } from "src/app/core/models/fiat-flow-pay";
import { HttpClient, HttpResponse } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { TransferFeeType } from "src/app/core/models/fIat/transfer-type";
import { FiatFlow } from "src/app/core/models/fIat/fiat-flow";
import { Recipient, IRecipient } from "src/app/core/models/fIat/recipients";
import { IFiatRecipient, IPay, Pay, SkipBenificry } from "src/app/core/models/fIat/fiat-pay";
import { FiatPayPasswordConfirm } from "src/app/core/models/fIat/fiat-pay-confirm";

@Injectable({
  providedIn: "root",
})
export class FiatService {
  /** api url */
  private apiUrl = environment.api;

  private tranId;

  setTransactionId(transId: string) {
    this.tranId = transId;
  }

  getTransactionId() {
    return this.tranId;
  }

  /** TransferFeeType data emitter */
  private fiatFlowPaydDataEmitter = new BehaviorSubject<FiatFlow | undefined>(
    undefined
  );

  private recipientDataEmitter = new BehaviorSubject<Recipient | undefined>(
    undefined
  );

  /** PayRequest data emitter */
  private payRequestDataEmitter = new BehaviorSubject<Pay | undefined>(
    undefined
  );

  private passwordAuthDataEmitter = new BehaviorSubject<boolean | undefined>(
    undefined
  );

  private currencySymbolEmitter = new BehaviorSubject<IFiatRecipient | undefined>(
    undefined
  );

  constructor(private http: HttpClient) {}

  Skiprecipent(data: SkipBenificry): Observable<boolean> {
    return this.http.post<boolean>(
      `${this.apiUrl}/recipients/approvetopay`,
      data
    );
  }

  /**
   * send pay request
   * /api/v1/send/fiatsend
   */
  sendPayRequest(
    paydata: Pay
  ): Observable<{ statusCode: number; message: string }> {
    return this.http.post<{ statusCode: number; message: string }>(
      `${this.apiUrl}/send/fiatsend`,
      paydata
    );
  }
  /**
   * send pay request
   * /api/v1/send/fiatsend
   */
  isCryptoSendVerified(
    id
  ): Observable<{ statusCode: number; message: string }> {
    return this.http.post<{ statusCode: number; message: string }>(
      `${this.apiUrl}/send/cryptowithdrawconfirmation?transactionId=${id}`,
      id
    );
  }
  /**
   * send pay request
   * /api/v1/send/fiatsend
   */
  isCryptoReSendVerification(id): Observable<{ message: boolean }> {
    return this.http.post<{ message: boolean }>(
      `${this.apiUrl}/send/resendwithdrawtokenrequest?transactionId=${id}`,
      id
    );
  }
  /**
   * send pay request
   * /api/v1/send/fiatsend
   */
  cancelTransaction(id): Observable<{ message: string }> {
    return this.http.get<{ message: string }>(
      `${this.apiUrl}/send/canceletransaction?leadgerid=${id}`
    );
  }
  /**
   * send pay request
   * /api/v1/send/fiatsend
   */
  verifyRecipient(recipientId, currency): Observable<{ message: boolean }> {
    return this.http.post<{ message: boolean }>(
      `${this.apiUrl}/recipients/verifyrecipient?recipientId=${recipientId}&currency=${currency}`,
      {
        recipientId: recipientId,
        currency: currency,
      }
    );
  }

  /**
   * white list a device
   * Get getRecipents data emitter
   */
  getRecipents(symbol: string, network: number, recipientType?: number): Observable<Array<IRecipient>> {
    let url = `${this.apiUrl}/recipients/${symbol}`;
    if (recipientType) {
      url = url + `/${recipientType}`
    }
    if (network) {
      url = url + `?network=${network}`;
    }
    return this.http.get<Array<IRecipient>>(
      `${url}`
    );
  }

  getCurrencyType(fromCurrencyId: any): Observable<{ message: string }> {
    return this.http.get<{ message: string }>(
      `${this.apiUrl}/currencies/getcurrencytype?currencyId=${fromCurrencyId}`
    );
  }

  /**
   * Approve request
   *
   */
  passwordConfirm(passwordData: FiatPayPasswordConfirm): Observable<boolean> {
    return this.http.post<boolean>(
      `${this.apiUrl}/account/approvetransfer`,
      passwordData
    );
  }

  /**
   * Get fiatFlowPay data emitter
   */
  getfiatFlowPaydDataEmitter(): Observable<FiatFlow> {
    return this.fiatFlowPaydDataEmitter.asObservable();
  }

  /**
   * Emit fiatFlowPay data
   */
  emitfiatFlowPaydDataEmitter(data: FiatFlow): void {
    this.fiatFlowPaydDataEmitter.next(data);
  }

  /**
   * Get Currency Symbol data emitter
   */
  getCurrencySymbolEmitter(): Observable<IFiatRecipient> {
    return this.currencySymbolEmitter.asObservable();
  }

  /**
   * Emit Currency Symbol data
   */
  emitCurrencySymbolEmitter(data: IFiatRecipient): void {
    this.currencySymbolEmitter.next(data);
  }

  /** Emit Fiat flow Data */
  emitFiatRecipientdData(data: Recipient): void {
    this.recipientDataEmitter.next(data);
  }
  /** Emit Fiat Recipient Data */
  getemitRecipientdData(): Observable<Recipient> {
    return this.recipientDataEmitter.asObservable();
  }

  emitPayRequestDataEmitter(data: Pay): void {
    this.payRequestDataEmitter.next(data);
  }
  /** Emit Pay Request Data */
  getPayRequestData(): Observable<Pay> {
    return this.payRequestDataEmitter.asObservable();
  }

  emitPasswordAuthDataEmitter(data: boolean): void {
    this.passwordAuthDataEmitter.next(data);
  }
  /** Emit Pay Request Data */
  getPasswordAuthData(): Observable<boolean> {
    return this.passwordAuthDataEmitter.asObservable();
  }
  getmonthlyfreewithdrawlimit(): Observable<any> {
    return this.http.get<any>(
      `${this.apiUrl}/users/getmonthlyfreewithdrawlimit`
    );
  }
  getcryptosendfee(symbol: string,networkType: number): Observable<{ nativeFee: number, casFee: number }> {
    return this.http.get<{ nativeFee: number, casFee: number, minSendAmount: number, maxSendAmount: number }>(
      `${this.apiUrl}/transferfees/getcryptosendfee?symbol=${symbol}&networkType=${networkType}`
    );
  }
}

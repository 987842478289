<div  class="py-3 px-5 my-3 bg-white">
  <div class="d-flex">
    <div class="mt-n1">
      <img class="mr-3 flag-icon mt-2" [src]="'../../../../assets/icons/' + currency + '.png'" />
    </div>
    <div class=" text-center bal-align">
      <h6 class="text-dark text-initial font-weight-bolder m-auto" *ngIf="currency === 'USDT'|| currency === 'USDC'; else nonStable">
        {{ currency }} {{ balance | number: "0.0-2" }}
        
        <br>
        ${{userBalanceInUSD | number: "0.0-2"}}
      </h6>
      <ng-template #nonStable>
        <h6 class="text-dark text-initial font-weight-bolder m-auto">
          {{ currency }} {{ balance | number: "0.0-4" }}
          <br>
          ${{userBalanceInUSD | number: "0.0-4"}}
        </h6>
      </ng-template>
    </div>
  </div>
  
  <hr>
  <div *ngIf="!isEarn" class="d-flex justify-content-between">
    <div class="label ml-1">Credit Line</div>
    <h6 *ngIf="creditBalanceInUSD > 0" class="text-dark font-weight-bolder mr-1">
      ${{creditBalanceInUSD}}
    </h6>
    <h6 *ngIf="creditBalanceInUSD == 0" class="text-dark font-weight-bolder mr-1">
      N/A
    </h6>
</div>
<div *ngIf="!isEarn" class="d-flex justify-content-between">
  <div class="label ml-1">LTV</div>
  <h6 *ngIf="item.creditLine > 0" class="text-dark font-weight-bolder mr-1">
    {{ item.creditLine }}
  </h6>
  <h6 *ngIf="item.creditLine == 0" class="text-dark font-weight-bolder mr-1">
    N/A
  </h6>
</div>
<div *ngIf="!isEarn" class="d-flex justify-content-between">
  <div class="label ml-1">ROI% (Annually)</div>
  <h6 *ngIf="item.interestRate > 0" class="text-dark font-weight-bolder mr-1">
    {{ item.interestRate }}
  </h6>
  <h6 *ngIf="item.interestRate == 0" class="text-dark font-weight-bolder mr-1">
    N/A
  </h6>
</div>

<!-- <div *ngIf="!isEarn" class="d-flex justify-content-between">
  <div class="label ml-1">Interest Rate</div>
    <h6 class="text-dark font-weight-bolder mr-1">
      {{ item.noInterestRate }}%
    </h6>
  </div> -->
  <hr>
  <div class="d-flex justify-content-between">
    <button [style.visibility]="item.isFDActive && isEarn ? 'visible' : 'hidden'" mdbBtn type="button" class="btn-sm earn-upto"
  (click)="openEarnPlan($event)">Earn
  up to
  {{item.earnUpTo}}%</button>
  <button *ngIf="item.currencynature === 2" [style.visibility]="!isHidden(currency) && isDepositActive ? 'visible' : 'hidden'"
  [style.marginLeft]="!isHidden(currency) && isDepositActive ? '6' : '-30px'" type="submit" (click)="
                getCryptoAccountDetails($event, item, idx);
                selectedCrypto == idx
                  ? (selectedCrypto = -1)
                  : (selectedCrypto = idx);" mdbBtn type="button" class="btn-sm app-primary-btn">
    Deposit</button>
    <button *ngIf="item.currencynature === 1" type="submit" (click)="
    getCryptoAccountDetails($event, item, idx);
    selectedCrypto == idx
      ? (selectedCrypto = -1)
      : (selectedCrypto = idx);" mdbBtn type="button" class="btn-sm app-primary-btn">
Deposit</button>
  </div>
  <div class="d-flex justify-content-between">
    <button *ngIf="item.currencynature === 1" mdbBtn type="button" class="btn-sm app-secondary-btn"
    (click)="onConvertClick(item.symbol)">Exchange</button>
    <button  *ngIf="!isHidden(currency) && isDepositActive && item.currencynature === 2; else nonBankingFiat" mdbBtn type="button" class="btn-sm app-secondary-btn"
    (click)="onConvertClick(item.symbol)">Exchange</button>
    <ng-template #nonBankingFiat>
      <button *ngIf="item.currencynature === 2"  mdbBtn type="button" class="btn-sm app-secondary-btn"
      (click)="onBuyClick(item.symbol)">Buy</button>
      <button *ngIf="item.currencynature === 2" mdbBtn type="button" class="btn-sm app-secondary-btn"
      (click)="onConvertClick(item.symbol)">Sell</button>
    </ng-template>
    <button [disabled]="!isSendActive" mdbBtn type="button" class="btn-sm app-tertiary-btn"
    (click)="onSendClick(item.symbol)">Withdraw</button>
  </div>
</div>
import { CurrencyTypeData } from "./../../core/models/currency-type-data";
import { ICryptoAccountDetails } from "./../../core/models/crypto-account-details";
import { IAccountDetails } from "./../../core/models/account-details";
import { Router, ActivatedRoute } from "@angular/router";
import { WalletService } from "./../services/wallet.service";
import {
  Component,
  OnInit,
  ViewChildren,
  QueryList,
  ElementRef,
  ChangeDetectorRef,
  ViewChild,
} from "@angular/core";
import { ICurrencyList } from "../../core/models/currency";
import {
  APP_ROUTES,
  IS_KYC_DONE_PERSONAL,
  UNABLE_TO_PERFORM_ERROR,
  USER_COUNTRY_CODE,
  USER_ID,
  USER_INTEREST_MODE,
  USER_TYPE,
} from "../../app.constants";
import { IUserBalance } from "src/app/core/models/user-balance";
import { PopoverDirective, SBItemComponent } from "ng-uikit-pro-standard";
import { KycService } from "src/app/kyc/services/kyc.service";
import { ICreateKYC } from "src/app/core/models/create-kyc";
import { CryptoAccountRequestModel } from "./../../core/models/crypto-account-request-model";
import { ToastrService } from "ngx-toastr";
import { AccountUpgradeService } from "src/app/account-upgrade/services/account-upgrade.service";
import { environment } from "src/environments/environment";
import { ModalService } from "src/app/core/services/modal/modal.service";
import { UserService } from "src/app/core/services/user/user.service";
import { IDepositInterestPlan } from "src/app/core/models/deposit-interest/deposit-interest";
import { DepositInterestService } from "src/app/deposit-interest/service/deposit-interest.service";
import { IUserPlan } from "src/app/core/models/user-plan/user-detail";
import { NetworkType } from "src/app/core/enums/network-type-enum";

@Component({
  selector: "cashaa-wallet-dashboard",
  templateUrl: "./wallet-dashboard.component.html",
  styleUrls: ["./wallet-dashboard.component.scss"],
})
export class WalletDashboardComponent implements OnInit {
  fiatCurrencyList: IUserBalance[];
  @ViewChild('mdbPopover') mdbPopover: PopoverDirective;
  localBankFiatCurrencyList: IUserBalance[] = [];
  accountBankFiatCurrencyList: IUserBalance[] = [];

  cryptoBankFiatCurrencyList: IUserBalance[] = [];

  currencyToEmit: string;
  currencyType: string;
  accountDetailsByCurrencyData: IAccountDetails;
  accountDetailsByAccountCurrencyData: IAccountDetails;
  accountDetailsCryptoData: ICryptoAccountDetails;

  accountDetailsETHCryptoData: ICryptoAccountDetails;

  /** Error message if any */
  errorMessage?: string;

  /** Error message if any */
  errorMessageCrypto?: string;

  /** selected local bank */
  selectedLocalBank = -1;

  selectedAccountBank = -1;

  /** selected crypto bank */
  selectedCrypto = -1;

  localBankLoading = false;

  accountBankLoading = false;

  cryptoLoading = false;

  loading = false;

  copied = false;

  copiedMemo = false;

  // Is Personal Kyc verified
  isPersonalKycVerified = false;
  isBusinessKycVerified = false;
  isBusinessVerified
  // get logged user type
  userType = sessionStorage.getItem(USER_TYPE);

  // hold personal user kyc details
  personalKycVerifiedInfo: ICreateKYC;
  usdKycStatus;
  usdButtonText: string;
  usdButtonRoute: string;
  usdButtonDisable: boolean;
  @ViewChildren("collapses") collapses: QueryList<SBItemComponent>[];
  @ViewChildren("collapsesAccount")
  collapsesAccount: QueryList<SBItemComponent>[];
  casDepositRates: number[] = [];
  maxInterestModeRate: number[] = [];
  @ViewChildren("collapsesCrypto")
  collapsesCrypto: QueryList<SBItemComponent>[];
  rateType: string = sessionStorage.getItem(USER_INTEREST_MODE);
  whiteListUserId = environment.whiteListUserId;

  excludeFiatCurrencies: string[] = ["SGD", "AUD", "CNH", "CAD", "CHF", "NZD", "JPY", "USD", "GBP"];

  excludeCryptoDisable: string[] = ["BNB20"];
  hideZeroBalance = false;
  temp = [];

  constructor(
    private walletService: WalletService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private kycService: KycService,
    private toastrService: ToastrService,
    private upgardeService: AccountUpgradeService,
    private modalService: ModalService,
    private userService: UserService,
    private depositService: DepositInterestService
  ) { }

  ngOnInit(): void {
    this.getFiatCurrency();
    if (this.userType === '4') {
      this.getPersonalKycDetail();
    } else {
      this.getBusinessKycDetail();
    }
    this.getUsdStatus();
    // this.mdbPopover.show();
  }

  getBusinessKycDetail(): void {
    this.upgardeService.usdStatus().subscribe(
      (flag) => {
        if (flag.status === 5) {
          this.isBusinessKycVerified = true;
        }
      },
    );
  }

  getUsdStatus(): void {
    this.loading = true;
    this.usdButtonDisable = false;
    this.upgardeService.usdStatus().subscribe(
      (flag) => {
        this.usdKycStatus = flag.status;
        switch (flag.status) {
          case 1:
            this.usdButtonText = "Apply";
            break;

          case 2:
            this.usdButtonText = "Update KYC Details";
            break;

          case 3:
            this.usdButtonText = "KYC in Progress";
            break;

          case 4:
            this.usdButtonText = "Update KYC Details";
            break;

          case 5:
            this.usdButtonText = "KYC in Progress";
            break;

          case 6:
            this.usdButtonText = "Account Creation in Progress";
            this.usdButtonDisable = true;
            break;

          case 7:
            this.usdButtonText = "";
            this.usdButtonDisable = true;
            break;
        }

        // if (flag.status === 7) {
        //   this.enableActiveForPremierFlag = true;
        // } else {
        //   this.enableActiveForPremierFlag = false;
        // }
        if (flag.status === 1) {
          this.usdButtonRoute = "/subscription/plan";
        } else {
          this.usdButtonRoute =
            this.userType === "5" || this.userType === "6" ? "/kyc/business" : "/kyc/US/personal";
        }
        this.loading = false;
      },
      (err) => {
        this.loading = false;
      }
    );
  }

  //get given currency
  getGivenCurrency(): void {
    this.activatedRoute.queryParamMap.subscribe((params) => {
      const currency = params.get("c");
      if (currency) {
        const found = this.localBankFiatCurrencyList.filter(
          (lc) => lc.currencyType === currency
        );
        if (found.length > 0) {
          const idx = this.localBankFiatCurrencyList.indexOf(found[0]);
          this.collapses.forEach((el: any, i: number) => {
            if (idx !== i) {
              el.toggle(true);
            } else {
              el.toggle(false);
              this.getAccountDetailsByCurrency(null, found[0], idx);
              this.selectedLocalBank = i;
            }
          });
        }

        const foundAccount = this.accountBankFiatCurrencyList.filter(
          (lc) => lc.currencyType === currency
        );
        if (foundAccount.length > 0) {
          const idx = this.accountBankFiatCurrencyList.indexOf(foundAccount[0]);
          this.collapsesAccount.forEach((el: any, i: number) => {
            if (idx !== i) {
              el.toggle(true);
            } else {
              el.toggle(false);
              this.getAccountDetailsByAccountCurrency(null, foundAccount[0], idx);
              this.selectedAccountBank = i;
            }
          });
        }

        const foundCrypto = this.cryptoBankFiatCurrencyList.filter(
          (lc) => lc.currencyType === currency
        );
        if (foundCrypto.length > 0) {
          const idx = this.cryptoBankFiatCurrencyList.indexOf(foundCrypto[0]);
          this.collapsesCrypto.forEach((el: any, i: number) => {
            if (idx !== i) {
              el.toggle(true);
            } else {
              el.toggle(false);
              this.getCryptoAccountDetails(null, foundCrypto[0], idx);
              this.selectedCrypto = i;
            }
          });
        }

        const el = document.getElementById(currency);
        if (el) {
          el.scrollIntoView();
        }
      }
    });
  }

  onAccountReadyPage(currency: IUserBalance, currencyType: string, networkType?: string): void {
    if ("BEP-20" === networkType) {
      this.currencyToEmit = 'ETH';
      currency = this.cryptoBankFiatCurrencyList.filter(cur => cur.currencyType === 'ETH')[0];
    } else {
      this.currencyToEmit = currency.currencyType;
    }
    this.currencyType = currencyType;
    const currencyData = new CurrencyTypeData(
      this.currencyToEmit,
      this.currencyType
    );

    if (this.currencyType === "Crypto") {
      this.cryptoLoading = true;
      const inputCurrencyCrypto = new CryptoAccountRequestModel(this.currencyToEmit);
      this.walletService
        .OpenCryptoAccount(inputCurrencyCrypto)
        .subscribe((res) => {
          this.getCryptoCurrency(currency, networkType);
        });
    } else {
      this.walletService.emitCurrencySymbol(currencyData);
      this.router.navigateByUrl(APP_ROUTES.WALLET_READY_TO_GO);
    }
  }

  getFiatCurrency(): void {
    this.loading = true;
    this.walletService.getUserBalance().subscribe((res) => {
      this.fiatCurrencyList = res;
      this.localBankFiatCurrencyList = this.fiatCurrencyList.filter(
        (el) => el.currencynature === 2 && el.currencyType !== "AUSD"
      );
      this.accountBankFiatCurrencyList = this.fiatCurrencyList.filter(
        (el) => el.currencyType === "AUSD"
      );
      this.cryptoBankFiatCurrencyList = this.fiatCurrencyList.filter(
        (el) => el.currencynature === 1
      );
      let tempFiatCurrList = [];
      for (let curr of this.localBankFiatCurrencyList) {
        if (curr.currencyType === "USD") {
          tempFiatCurrList.unshift(curr);
        } else {
          tempFiatCurrList.push(curr);
        }
      }
      this.localBankFiatCurrencyList = [...tempFiatCurrList];
      this.loading = false;
      setTimeout(() => this.getGivenCurrency(), 100);
    });
  }

  getAccountDetailsByCurrency(event, currency: IUserBalance, idx: number): void {
    if (this.selectedLocalBank === idx) {
      return;
    }
    this.localBankLoading = true;

    this.collapses.forEach((el: any, i: number) => {
      if (idx !== i) {
        el.toggle(true);
      }
    });

    this.accountDetailsByCurrencyData = null;
    this.errorMessage = null;

    // this.walletService.getAccountDetailsByCurrency(currency.currencyType).subscribe(
    //   (res) => {
    //     this.accountDetailsByCurrencyData = res;
    //     this.localBankLoading = false;

    //   },
    //   (err) => {
    //     if (err.error.message) {
    //       //this.toastrService.error(err.error.message);
    //       this.errorMessage = err.error.message;
    //     } else {
    //       //this.toastrService.error(UNABLE_TO_PERFORM_ERROR);
    //     }
    //     this.localBankLoading = false;
    //   }
    // );

    if (this.isEarnAndFixedDepositActive()) {
      if (this.isFixedDepositEnable(currency.currencyType)) {
        this.loadFixedDepositRate(currency);
      } else if (this.isFixedDepositAndEarnEnable(currency.currencyType)) {
        let rate: number;
        this.userService.getPlanDetailsByCurrencyId(currency.currencyId).subscribe((data: IUserPlan[]) => {
          if (data.length > 0) {
            currency.plans = data;
            currency.diamondPlan = data.filter((plan: IUserPlan) => plan.loyaltyPlanName === 'Diamond')[0];
            let savingInterest: number = Math.trunc(currency.diamondPlan.casRate) + Math.trunc(currency.diamondPlan.kindRate);
            this.depositService.getDepositInterestPlan(currency.currencyId).subscribe((data: IDepositInterestPlan[]) => {
              if (data.length > 0) {
                this.casDepositRates = [];
                this.maxInterestModeRate = [];
                const plans: IDepositInterestPlan[] = data.filter((plan) => plan.isActive && plan.isDefault);
                plans.forEach(plan => {
                  if (plan.rateType == (this.rateType === 'InCas' ? 2 : 1)) {
                    this.maxInterestModeRate.push(plan.rate)
                  }
                  this.casDepositRates.push(plan.rate);
                });
                if (this.casDepositRates.length > 0) {
                  let rates: number[] = [...this.casDepositRates];
                  rates.push(savingInterest);
                  currency.earnRate = Math.max(...rates);
                }
              }
            });
          }
          this.localBankLoading = false;
        });
      }
      this.localBankLoading = false;
    } else {
      this.localBankLoading = false;
    }

    if (event) {
      $("html,body").animate(
        {
          scrollTop: event.srcElement.offsetParent.offsetTop,
        },
        600
      );
    }
  }

  getAccountDetailsByAccountCurrency(
    event,
    currency: IUserBalance,
    idx: number
  ): void {
    if (this.selectedAccountBank === idx) {
      return;
    }
    this.accountBankLoading = true;

    this.collapsesAccount.forEach((el: any, i: number) => {
      if (idx !== i) {
        el.toggle(true);
      }
    });

    this.accountDetailsByAccountCurrencyData = null;
    this.errorMessage = null;

    this.walletService.getAccountDetailsByCurrency(currency.currencyId).subscribe(
      (res) => {
        this.accountDetailsByAccountCurrencyData = res;
        this.accountBankLoading = false;
        if (this.isEarnAndFixedDepositActive()) {
          if (this.isFixedDepositEnable(currency.currencyType)) {
            this.loadFixedDepositRate(currency);
          } else if (this.isFixedDepositAndEarnEnable(currency.currencyType)) {
            let rate: number;
            this.userService.getPlanDetailsByCurrencyId(currency.currencyId).subscribe((data: IUserPlan[]) => {
              if (data.length > 0) {
                currency.plans = data;
                currency.diamondPlan = data.filter((plan: IUserPlan) => plan.loyaltyPlanName === 'Diamond')[0];
                let savingRate = Math.trunc(currency.diamondPlan.casRate) + Math.trunc(currency.diamondPlan.kindRate);
                this.depositService.getDepositInterestPlan(currency.currencyId).subscribe((data: IDepositInterestPlan[]) => {
                  if (data.length > 0) {
                    this.maxInterestModeRate = [];
                    this.casDepositRates = [];
                    const plans: IDepositInterestPlan[] = data.filter((plan) => plan.isActive && plan.isDefault);
                    plans.forEach(plan => {
                      if (plan.rateType == (this.rateType === 'InCas' ? 2 : 1)) {
                        this.maxInterestModeRate.push(plan.rate);
                      }
                      this.casDepositRates.push(plan.rate);
                    });
                    if (this.casDepositRates.length > 0) {
                      let rates: number[] = [...this.casDepositRates];
                      rates.push(savingRate);
                      currency.earnRate = Math.max(...rates);
                    }
                  } else {
                    currency.earnRate = savingRate;
                  }
                });
              }
            });
          }
        }
      },
      (err) => {
        if (err.error.message) {
          this.errorMessage = err.error.message;
        }
        this.accountBankLoading = false;
      }
    );

    if (event) {
      $("html,body").animate(
        {
          scrollTop: event.srcElement.offsetParent.offsetTop,
        },
        600
      );
    }
  }

  getCryptoAccountDetails(event, currency: IUserBalance, idx: number): void {
    if (this.selectedCrypto === idx) {
      return;
    }

    this.cryptoLoading = true;
    this.collapsesCrypto.forEach((el: any, i: number) => {
      if (idx !== i) {
        el.toggle(true);
      }
    });
    this.getCryptoCurrency(currency);

    if (event) {
      $("html,body").animate(
        {
          scrollTop: event.srcElement.offsetParent.offsetTop,
        },
        600
      );
    }

    // if (currency.currencyType === 'CAS' || currency.currencyType === 'BNB' || currency.currencyType === 'XRP') {
    //   this.modalService.openWarningModal(currency.currencyType);
    // }
  }

  getCryptoCurrency(currency: IUserBalance, networkType?: string) {
    if (networkType === 'BEP-20') {
      this.accountDetailsETHCryptoData = null;
    } else {
      this.accountDetailsCryptoData = null;
    }
    this.walletService.getCryptoAccountDetails(currency.currencyType).subscribe(
      (res) => {
        if (networkType === 'BEP-20') {
          this.accountDetailsETHCryptoData = res;
        } else {
          this.accountDetailsCryptoData = res;
        }
        if (this.isEarnAndFixedDepositActive()) {
          if (this.isFixedDepositEnable(currency.currencyType)) {
            this.loadFixedDepositRate(currency);
          } else if (this.isFixedDepositAndEarnEnable(currency.currencyType)) {
            if (currency.currencyType === 'CAS') {
              this.loadFixedDepositRate(currency);
            } else {
              let rate: number;
              this.userService.getPlanDetailsByCurrencyId(currency.currencyId).subscribe((data: IUserPlan[]) => {
                if (data.length > 0) {
                  currency.plans = data;
                  currency.diamondPlan = data.filter((plan: IUserPlan) => plan.loyaltyPlanName === 'Diamond')[0];
                  let savingRate: number = Math.trunc(currency.diamondPlan.casRate) + Math.trunc(currency.diamondPlan.kindRate);
                  if (!this.isEarnModuleNotActive(currency.currencyType)) {
                    this.depositService.getDepositInterestPlan(currency.currencyId).subscribe((data: IDepositInterestPlan[]) => {
                      if (data.length > 0) {
                        this.casDepositRates = [];
                        this.maxInterestModeRate = [];
                        const plans: IDepositInterestPlan[] = data.filter((plan) => plan.isActive && plan.isDefault);
                        plans.forEach(plan => {
                          if (plan.rateType == (this.rateType === 'InCas' ? 2 : 1)) {
                            this.maxInterestModeRate.push(plan.rate)
                          }
                          this.casDepositRates.push(plan.rate);
                        });
                        if (this.casDepositRates.length > 0) {
                          let rates: number[] = [...this.casDepositRates];
                          rates.push(savingRate);
                          currency.earnRate = Math.max(...rates);
                        }
                      } else {
                        currency.earnRate = savingRate;
                      }
                    });
                  } else {
                    currency.earnRate = savingRate;
                  }
                }
                this.cryptoLoading = false;
              });
            }
          }
        } else {
          this.cryptoLoading = false;
        }
        this.cryptoLoading = false;
      },
      (err) => {
        this.cryptoLoading = false;
      }
    );
  }

  copyAddress(): void {
    if (this.accountDetailsCryptoData.address) {
      this.copied = true;
      navigator.clipboard.writeText(this.accountDetailsCryptoData.address);
      this.toastrService.toastrConfig.preventDuplicates = true;
      this.toastrService.success("Text copied to clipboard");
      setTimeout(() => (this.copied = false), 300);
    }
  }

  copyAddressETH(): void {
    if (this.accountDetailsETHCryptoData.address) {
      this.copied = true;
      navigator.clipboard.writeText(this.accountDetailsETHCryptoData.address);
      this.toastrService.toastrConfig.preventDuplicates = true;
      this.toastrService.success("Text copied to clipboard");
      setTimeout(() => (this.copied = false), 300);
    }
  }

  copyMemoAddress(): void {
    if (this.accountDetailsCryptoData.memo) {
      this.copiedMemo = true;
      navigator.clipboard.writeText(this.accountDetailsCryptoData.memo);
      this.toastrService.toastrConfig.preventDuplicates = true;
      this.toastrService.success("Text copied to clipboard");
      setTimeout(() => (this.copiedMemo = false), 300);
    }
  }

  onConvertClick(currency: string): void {
    this.router.navigateByUrl(`/convert?c=${currency}`);
  }

  onSendClick(currency: string): void {
    this.router.navigateByUrl(`/fiat?c=${currency}`);
  }

  onDepositInterestClick(currency: IUserBalance): void {
    // this.modalService.openEarnPlanDetailPopup(currency.plans, currency.diamondPlan, currency.available, currency.currencyId, currency.currencyType,
    //   this.casDepositRates, this.isFixedDepositEnable(currency.currencyType), this.isFixedDepositAndEarnEnable(currency.currencyType),
    //   this.maxInterestModeRate, this.isEarnModuleNotActive(currency.currencyType));
  }

  onAddClick(currency: string): void {
    this.router.navigateByUrl(`/deposit?cur=${currency}`);
  }

  /***Get the Personal Kyc details to create bank account */

  getPersonalKycDetail(): void {
    this.loading = true;
    this.kycService.getKycDetails().subscribe((res) => {
      if (res) {
        this.isPersonalKycVerified = res.verified;
        this.personalKycVerifiedInfo = res;
      }
      this.loading = false;
    });
  }

  getTootlTip(value, currency) {
    return value === 0 ? "You don't have any " + currency + " to convert" : "";
  }

  getEarnTootlTip(value, currency) {
    return value === 0 ? "You don't have any " + currency + " to Earn Interest" : "";
  }

  getCurrencyName(curr): string {
    if (curr === "AUSD") return "USD";
    if (curr === "DOG") return "DOGE";
    else return curr;
  }

  isDisable(currency: string): boolean {
    if (this.excludeFiatCurrencies.includes(currency)) {
      return true;
    } else {
      return false;
    }
  }

  isSendDisable(currency: string): boolean {
    if (this.excludeFiatCurrencies.includes(currency)) {
      return true;
    } else {
      return false;
    }
  }

  isEarnAndFixedDepositActive(): boolean {
    return sessionStorage.getItem(USER_COUNTRY_CODE) !== 'US';
  }

  isFixedDepositEnable(symbol: string): boolean {
    return sessionStorage.getItem(USER_TYPE) !== '4' || symbol === 'CAS';
  }

  isFixedDepositAndEarnEnable(symbol: string): boolean {
    return sessionStorage.getItem(USER_TYPE) === '4' && symbol !== 'CAS';
  }

  isEarnModuleNotActive(symbol: string): boolean {
    return sessionStorage.getItem(USER_TYPE) === '4' && symbol === 'DOG';
  }

  loadFixedDepositRate(currency: IUserBalance) {
    if (!this.isEarnModuleNotActive(currency.currencyType)) {
      this.depositService.getDepositInterestPlan(currency.currencyId).subscribe((data: IDepositInterestPlan[]) => {
        if (data.length > 0) {
          this.casDepositRates = [];
          this.maxInterestModeRate = [];
          const plans: IDepositInterestPlan[] = data.filter((plan) => plan.isActive && plan.isDefault);
          plans.forEach(plan => {
            if (plan.rateType == (this.rateType === 'InCas' ? 2 : 1)) {
              this.maxInterestModeRate.push(plan.rate)
            }
            this.casDepositRates.push(plan.rate);
          });
          if (this.casDepositRates.length > 0) {
            currency.earnRate = Math.max(...this.casDepositRates);
          }
        }
        this.cryptoLoading = false;
      });
    }
  }

  isCryptoDisable(currency: string): boolean {
    if (this.excludeCryptoDisable.includes(currency)) {
      return true;
    } else {
      return false;
    }
  }

  isCryptoSendDisable(currency: string): boolean {
    let excludeCurr = [...this.excludeCryptoDisable]
    excludeCurr.push('USDC');
    if (excludeCurr.includes(currency)) {
      return true;
    } else {
      return false;
    }
  }

  public getTabHeaderData1(symbol: string): string {
    if (symbol === 'BNB' || symbol === 'CAS') {
      return 'BEP-2';
    } else if (symbol === 'USDT' || symbol === 'LINK' || symbol === 'UNI' || symbol === 'USDC' || symbol === 'ETH' || symbol === 'BNB') {
      return 'ERC-20';
    } else if (symbol === 'XRP' || symbol === 'DOG' || symbol === 'LTC' || symbol === 'BTC' || symbol === 'BCH') {
      return this.getCurrencyName(symbol);
    }
  }

  public getContentData(symbol: string) {
    const tabData = this.getTabHeaderData1(symbol);
    if (tabData === 'BEP-2' || tabData === 'ERC-20') {
      return `${symbol} (${tabData})`;
    } else {
      return symbol;
    }
  }
  filterNonZeroBalance() {
    if (this.localBankFiatCurrencyList.length > 0) {
      this.temp = this.localBankFiatCurrencyList;
    }
    if (this.hideZeroBalance) {
      this.localBankFiatCurrencyList = this.temp;
    } else {
      this.localBankFiatCurrencyList = this.localBankFiatCurrencyList.filter(item => item.available > 0);
    }
  }
}

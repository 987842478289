<div class="sm-form-title">
  <button type="button" class="close pull-right sm-close" aria-label="Close" (click)="modalRef.hide()">
    <span aria-hidden="true">×</span>
  </button>
</div>
<div class="text-center">
  <img class="icon" [src]="'../../../../assets/icons/verify_mes_icon.png'" />
</div>
<h3 class="w-100 text-center font-weight-bolder text-primary">{{ title }}</h3>
<div class="modal-body sm-font-family">
  {{ body }}
</div>

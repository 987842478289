import { MDBModalService } from "ng-uikit-pro-standard";
import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy,
} from "@angular/core";
import { FiatFlow } from "src/app/core/models/fIat/fiat-flow";

import { FiatService } from "../services/fiat.service";
import { Router } from "@angular/router";
import { Recipient, IRecipient } from "src/app/core/models/fIat/recipients";
import { Pay } from "src/app/core/models/fIat/fiat-pay";
import { DomainService } from "src/app/core/services/domain/domain.service";
import { ICurrency } from "src/app/core/models/currency";
import {
  IFiatInOutTransferFees,
  ITransferFees,
  TransferFeesByCurrencyModel,
} from "src/app/core/models/transfer-types";
import {
  APP_ROUTES,
  UNABLE_TO_PERFORM_ERROR,
  USER_FEE_MODE,
} from "src/app/app.constants";
import { UserService } from "src/app/core/services/user/user.service";
import { IUserBalance } from "src/app/core/models/user-balance";
import { ModalService } from "src/app/core/services/modal/modal.service";
import { WithdrawService } from "src/app/withdraw/services/withdraw.service";
import { CreateWithdrawRequest } from "src/app/core/models/withdraw-fund";
import { ToastrService } from "ngx-toastr";
import { SubSink } from "subsink";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ValidatorUtility } from "src/app/core/utility/validator.utility";

@Component({
  selector: "cashaa-fiat-flow-review",
  templateUrl: "./fiat-flow-review.component.html",
  styleUrls: ["./fiat-flow-review.component.scss"],
})
export class FiatFlowReviewComponent implements OnInit, OnDestroy {
  /** go back given number of steps */
  @Output() goback = new EventEmitter<number>();

  @Output() completed = new EventEmitter<string>();

  form: FormGroup;

  /** FiatFlow data */
  fiatFlowData?: FiatFlow;

  /** fiat recipient data */
  fiatRecipientData?: Recipient;

  // hold pay data request
  fromcurrencyObject: ICurrency;

  /** to currency object */
  tocurrencyObject: ICurrency;

  /** fee amount object */
  feeAmountObject: ITransferFees;

  /** balance data */
  balanceData?: IUserBalance[];

  /** conditions accepted */
  conditionsAccepted = false;

  defaultAccountNo = [
    "bnb13q87ekxvvte78t2q7z05lzfethnlht5agfh4ur",
    "bnb1jygevqedp4dfcgqjxvdz0jlp6jtg687k799jxt",
    "bnb1gx5n6tnu70h0aec3cd68l8g27lrnnrvvu7nla8",
  ];
  loading = false;
  /** feemode variable */
  userFeeMode = sessionStorage.getItem(USER_FEE_MODE);
  transferFeesByCurrencyModel: IFiatInOutTransferFees;
  isCashaaFee?: number;
  fiatCurrencies = ["USD", "GBP", "EUR", "AUSD", "SGD", "AUD", "CNH", "CAD", "CHF", "NZD", "JPY"];
  subSink = new SubSink();
  memo: string;
  isMemoActive: boolean;
  /**
   * This currency id for CAS Fee
   *
   *
   * @memberOf FiatFlowReviewComponent
   */
  _casCurrecnyId = "43796AC1-8F4E-4875-2533-08D7B39928D1";
  constructor(
    private fb: FormBuilder,
    private fiatService: FiatService,
    private domainService: DomainService,
    private modalService: ModalService,
    private mdbModalService: MDBModalService,
    private withdrawService: WithdrawService,
    private toastrService: ToastrService
  ) { }

  ngOnInit(): void {
    this.listenToDataEmitted();
    this.form = this.fb.group({
      memo: [
        "",
        [
          Validators.required,
          Validators.maxLength(20),
          ValidatorUtility.OnlyNumberRequired,
          Validators.minLength(4),
        ],
      ],
    });
    this.form.controls.memo.valueChanges.subscribe((val) => {
      this.memo = val;
    });
  }

  /**
   * Listen to data emitted in previous
   * steps
   */
  listenToDataEmitted(): void {
    this.subSink.add(
      this.fiatService
        .getemitRecipientdData()
        .subscribe((fiatRecipientdData) => {
          if (fiatRecipientdData) {
            this.fiatRecipientData = fiatRecipientdData;
          }
        })
    );

    this.subSink.add(
      this.fiatService
        .getfiatFlowPaydDataEmitter()
        .subscribe((fiatFlowData) => {
          if (fiatFlowData) {
            this.fiatFlowData = fiatFlowData;
            this.getFromCurrency(this.fiatFlowData);
            this.getFee(this.fiatFlowData);
            this.getToCurrency(this.fiatFlowData);
            if (this.fiatFlowData.memo) {
              this.form.get("memo").setValue(this.fiatFlowData.memo);
              this.form.get("memo").markAsTouched();
              this.form.get("memo").updateValueAndValidity();
            }
          }
        })
    );
  }

  /**
   * Get to currency data
   */
  getToCurrency(fiatFlow: FiatFlow): void {
    this.tocurrencyObject = fiatFlow.fromcurrencyObject;
  }

  /**
   * Get fee data
   */
  getFromCurrency(fiatFlow: FiatFlow): void {
    this.fromcurrencyObject = fiatFlow.fromcurrencyObject;
  }

  /**
   * Get fee data
   */
  getFee(fiatFlow: FiatFlow): void {
    this.feeAmountObject = fiatFlow.feeAmountObject;
  }

  getTransferFeeByCurrecny(): void {
    const data = new TransferFeesByCurrencyModel(
      this.fiatFlowData.sendercurrencyId,
      String(this.fiatFlowData?.senderAmount),
      1,
      false
    );

    this.subSink.add(
      this.domainService.getTransferFeeByCurrecny(data).subscribe((res) => {
        this.transferFeesByCurrencyModel = res;
        if (res.otherFeeAmountInPercent > 0) {
          const otherFee = res.otherFeeAmountInPercent;
          const finalFee = otherFee * this.fiatFlowData?.senderAmount;
          this.isCashaaFee = res.feeAmount + finalFee;
        }
      })
    );
  }

  getDecimalNumber(symbol) {
    let toFix = "0";
    if (symbol === "CAS" || symbol === "BNB") {
      toFix = "0.4";
    } else if (this.fiatCurrencies.includes(symbol)) {
      toFix = "0.2-2";
    } else {
      toFix = "0.4";
    }
    return toFix;
  }

  convertToDecimal(val, symbol) {
    let toFix = 0;
    if (this.fiatCurrencies.includes(symbol)) {
      toFix = 2;
    } else {
      toFix = 4;
    }

    return parseFloat(parseFloat(val).toFixed(toFix)) * 1;
  }

  /**
   * On submission of form
   */
  onFormSubmit(): void {
    let data;
    data = new Pay(
      this.fiatFlowData.sendercurrencyId,
      this.fiatFlowData.recivercurrencyId,
      Number(this.userFeeMode === 'InCashaa' && this.fromcurrencyObject.symbol === 'CAS' ? this.fiatFlowData.reciverAmount : this.fiatFlowData.senderAmount),
      this.fiatFlowData.sendertransferFeeId,
      // this.memo ? this.memo : this.fiatFlowData.memo,
      this.fiatRecipientData.memo,
      this.fiatRecipientData.id,
      this.fiatRecipientData.isrecipientSkiped,
      this.fromcurrencyObject.symbol,
      this.fiatRecipientData.accountNumber
    );

    if (this.fiatFlowData.networkType?.length > 0) {
      data.networkType = this.fiatFlowData.networkType;
    }

    if (this.fiatFlowData.deliveryMech) {
      data.deliveryMech = this.fiatFlowData.deliveryMech;
    }

    if (this.fiatFlowData.recipientType) {
      data.recipientType = this.fiatFlowData.recipientType;
    }

    const IsRecipientSkip = data.isrecipientSkiped;
    if (!IsRecipientSkip) {
      this.fiatService.emitPayRequestDataEmitter(data);
      this.modalService.openSendMoneySecurityConfirmation();
      this.subSink.add(
        this.mdbModalService.closed.subscribe(
          (r) => {
            if (this.fiatService.getTransactionId()) {
              this.completed.emit(this.fiatService.getTransactionId());
            }
          },
          (err) => {
            this.loading = false;
            if (err.error.message) {
              this.toastrService.error(err.error.message);
            } else {
              this.toastrService.error(UNABLE_TO_PERFORM_ERROR);
            }
          }
        )
      );
    } else {
      this.loading = true;
      if (
        data.currency == "GBP" ||
        data.currency == "EUR" ||
        data.currency == "AUSD" ||
        data.currency == "USD"
      ) {
        this.subSink.add(
          this.fiatService.sendPayRequest(data).subscribe((payRequestData) => {
            this.loading = false;
            this.completed.emit(payRequestData.message);
          })
        );
      } else {
        const withdrawRequestData = new CreateWithdrawRequest(
          data.currency,
          this.fiatRecipientData.id,
          data.amount,
          // data.remark,
          this.fiatRecipientData.memo? this.fiatRecipientData.memo : "",
          // this.fiatFlowData.networkType?.length > 0 ? this.fiatFlowData.networkType : undefined
          this.fiatRecipientData.network.toString().length > 0 ? this.fiatRecipientData.network.toString(): undefined
        );
        this.subSink.add(
          this.withdrawService
            .createWithdrawRequest(withdrawRequestData)
            .subscribe(
              (res) => {
                this.loading = false;
                this.completed.emit(res.message);
              },
              (err) => {
                this.loading = false;
                if (err.error.message) {
                  this.toastrService.error(err.error.message);
                } else {
                  this.toastrService.error(UNABLE_TO_PERFORM_ERROR);
                }
              }
            )
        );
      }
    }
  }

  /**
   * On transfer detail edit click
   */
  onTransferDetailEdit(): void {
    this.goback.emit(2);
  }

  /**
   * On transfer detail edit click
   */
  onRecipientDetailEdit(): void {
    this.goback.emit(1);
  }

  getLabel(): string {
    if (
      this.fiatFlowData?.fromcurrencyObject?.symbol === "USDT" ||
      this.fiatFlowData?.fromcurrencyObject?.symbol === "CAS" ||
      this.fiatFlowData?.fromcurrencyObject?.symbol === "BTC" ||
      this.fiatFlowData?.fromcurrencyObject?.symbol === "ETH" ||
      this.fiatFlowData?.fromcurrencyObject?.symbol === "BNB"
    ) {
      return "Memo";
    } if (this.fiatFlowData?.fromcurrencyObject?.symbol === "XRP") {
      return "Tag"
    } else {
      return "Reference";
    }
  }

  ngOnDestroy(): void {
    this.subSink.unsubscribe();
  }

  getMemoReferenceValid(): boolean {
    if (
      (this.fiatFlowData?.fromcurrencyObject?.symbol === "CAS" || this.fiatFlowData?.fromcurrencyObject?.symbol === "BNB" || this.fiatFlowData?.fromcurrencyObject?.symbol === "XRP") &&
      (this.fiatFlowData?.memo === "" ||
        this.form.invalid ||
        this.isMemoActive) &&
      this.defaultAccountNo.includes(this.fiatRecipientData?.accountNumber)
    ) {
      this.isMemoActive = true;
      return true;
    } else {
      return false;
    }
  }
  getCurrencyName(curr): string {
    if (curr === "AUSD") return "USD";
    if (curr === "DOG") return "DOGE";
    else return curr;
  }
}
import { Component, OnInit, ViewChild } from '@angular/core';
import {
  FormGroup,
  FormBuilder
} from "@angular/forms";
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { IDepositInterestPlan } from 'src/app/core/models/deposit-interest/deposit-interest';
import { ValidatorUtility } from 'src/app/core/utility/validator.utility';
import { WalletService } from '../services/wallet.service';
import { ModalService } from 'src/app/core/services/modal/modal.service';
import { Subscription } from 'rxjs';
import { CommonSavedData } from 'src/app/shared/services/commonSavedData.service';
import { UNABLE_TO_PERFORM_ERROR } from 'src/app/app.constants';

@Component({
  selector: 'cashaa-loan-repayment',
  templateUrl: './loan-repayment.component.html',
  styleUrls: ['./loan-repayment.component.scss']
})
export class LoanRepaymentComponent implements OnInit {
  loanDetailsForm: FormGroup;
  toSymbol;
  toLockedCurrency;
  toLockedCurrencyAmount;
  interestPlanList: IDepositInterestPlan[] = [];
  radioOptionToShow = [];
  loading = false;
  loanId;
  loanRepaymentDetails;
  outstandingLoanBeforeRepay;
  outstandingLoanAfterRepay;
  loanRepaymentHistoryInfo;
  currencyRate;
  payToCloseLoan = 0;
  availableCurrencyCreditWallet = [];
  creditWalletForSelectedCurrency;
  allCurrencyBalance = [];
  savingWalletOnHold;
  lockedAmount;
  rateSymbol;
  errorMsg = false;
  message;
  outStandingAmount;
  actualOutstandingAmount;
  currencyType;
  lockedInfo;
  feeAmount = 0;
  feeAmountSymbol;
  @ViewChild("currencyPair", { static: true }) currencyPair: any;
  sub: Subscription;
  constructor(private fb: FormBuilder, private walletService: WalletService,
    private activatedRoute: ActivatedRoute, private toastrService: ToastrService,
    private router: Router, private modalService: ModalService, private commonSavedData: CommonSavedData

  ) { }

  ngOnInit(): void {
    this.loanDetailsForm = this.fb.group({
      currency: ["", ValidatorUtility.Required],
      amount: ["", ValidatorUtility.Required],
      walletType: ["1"]
    });
    this.activatedRoute.params.subscribe(param => {
      this.loanId = window.atob(param.id);
      console.log(param);
      console.log(this.loanId);
    })
    if (this.loanId) {
      this.getLoanRepaymentDetails();
      this.getLoanRepaymentHistory();
      this.getLockedAmount();

    }
    this.loanDetailsForm.get('amount').valueChanges.subscribe(res => {
    this.getOutstandingAndFeeAmount();

      let amountIncludeFee = Number(res.split(",").join("")) + this.feeAmount;
      if (this.loanDetailsForm.controls.walletType.value == '1' && (amountIncludeFee > this.savingWalletOnHold)) {
        this.errorMsg = true;
        this.message = 'Amount is higher than balance';
      } else if (this.loanDetailsForm.controls.walletType.value == '2' && (amountIncludeFee > this.toLockedCurrencyAmount)) {
        this.errorMsg = true;
        this.message = 'Amount is higher than balance';
      } else if (amountIncludeFee > this.actualOutstandingAmount) {
        this.errorMsg = true;
        this.message = 'Amount is higher than loan amount';

      } else {
        this.errorMsg = false;
      }
      this.loanDetailsForm.get('walletType').valueChanges.subscribe(walletType => {
        if (walletType == 1 && res > this.savingWalletOnHold) {
          this.errorMsg = true;
          this.message = 'Amount is higher than balance';
        }
        else if (walletType == 2 && res > this.toLockedCurrencyAmount) {
          this.errorMsg = true;
          this.message = 'Amount is higher than balance';
        }
        else {
          this.errorMsg = false;
        }
      });

    });

  }

  onCurrencyTypeChange(event) {
    console.log(event);
    // this.outstandingLoanAfterRepay = 0;
    this.payToCloseLoan = 0;
    this.toSymbol = event.symbol;
    this.currencyType = event.currencyType;
    this.getCrediWalletAmount();
    this.getUserSavingBalance();
    if (this.toSymbol) {
      this.loanDetailsForm.get('amount').setValue('');
    }

    // this.getCurrencyPairs();
    this.interestPlanList = [];
    this.radioOptionToShow = [];
  }

  getCrediWalletAmount() {
    this.walletService.getLoanDetailsById(this.loanId).subscribe(res => {
      this.availableCurrencyCreditWallet = res.creditLines;
      const result = this.availableCurrencyCreditWallet.filter(item => item.currencySymbol === this.toSymbol);
      this.creditWalletForSelectedCurrency = result[0];
      if (!this.creditWalletForSelectedCurrency?.remainingAmount) {
        this.toLockedCurrencyAmount = 0.0;
      }
      else {
        this.toLockedCurrencyAmount = this.creditWalletForSelectedCurrency?.remainingAmount;
      }
    }, err => {

    })
  }

  getLockedAmount() {
    this.walletService.getLockedCurrencyByLoanId(this.loanId).subscribe(res => {
      console.log('res::  ', res);
      this.lockedInfo = res;
    });
  }

  getUserSavingBalance() {
    this.walletService.getUserBalance().subscribe(res => {
      this.allCurrencyBalance = res;
      this.savingWalletOnHold = this.allCurrencyBalance.filter(item => item.currencyType === this.toSymbol)[0].available;

    })
  }

  getLoanRepaymentDetails() {
    this.loading = true;
    this.walletService.getLoanRepaymentData(this.loanId).subscribe(res => {
      this.loading = false;
      this.loanRepaymentDetails = res;
      this.outstandingLoanBeforeRepay = res.outstandingLoan;
      // this.getCurrencyPairs();

    }, err => {
      this.loading = false;
    })
  }

  getLoanRepaymentHistory() {
    this.loading = true;
    this.walletService.getLoanRepaymentHistory(this.loanId).subscribe(res => {
      this.loading = false;
      this.loanRepaymentHistoryInfo = res.repayment;

    }, err => {
      this.loading = false;
    })
  }

  // getCurrencyPairs(): void {
  //   this.domainService.getCurrencyPairListById(this.loanRepaymentDetails.currencyId).subscribe(res => {
  //     // this.currencyPairs.push({
  //     //   value: res[0].currencyId,
  //     //   label: res[0].fromSymbol,
  //     //   icon: `../../../../assets/icons/${res[0].fromSymbol}.png`,
  //     //   symbol: res[0].fromSymbol
  //     // })
  //     this.currencyPairs = res.map((currencies) => {
  //       return {
  //         value: currencies.toCurrencyId,
  //         label: currencies.toSymbol,
  //         icon: `../../../../assets/icons/${currencies.toSymbol}.png`,
  //         symbol: currencies.toSymbol
  //       };
  //     });
  //   })
  // }

  getOutstandingAndFeeAmount() {
    this.walletService.getOutStandingAmount(this.loanId, this.loanRepaymentDetails.currencySymbol, this.toSymbol, this.outstandingLoanBeforeRepay, this.loanDetailsForm.controls.amount.value)
      .subscribe(res => {
        this.actualOutstandingAmount = res.outStandingAmount;
        this.outStandingAmount = res.outStandingAmount - this.loanDetailsForm.controls.amount.value;
        this.outstandingLoanAfterRepay = this.outstandingLoanBeforeRepay - res.amount;
        this.payToCloseLoan = this.outStandingAmount;
        if (this.loanRepaymentDetails.currencySymbol !== this.toSymbol) {
          this.feeAmount = res.feeAmount;
          this.feeAmountSymbol = res.feeAmountSymbol;
        } else {
          this.feeAmount = 0;
          this.feeAmountSymbol = '';
        }
        this.getLoanRepaymentDetails();
        this.getLoanRepaymentHistory();
      })
  }

  payToClose() {
    this.loanDetailsForm.controls['amount'].setValue(this.payToCloseLoan);
  }

  onFormSubmit(): void {
    this.modalService.openConfirmationModal("Do you want to proceed for loan repayment?");
    this.sub = this.commonSavedData.getConfirmationBoxStatus().subscribe((status: boolean) => {
      if (status) {
        this.loading = true;
        const data = this.loanDetailsForm.value;
        console.log(data);
        const payload = {
          "amount": data.amount,
          "currencyId": data.currency.value,
          "creditRepaymentType": data.walletType,
          "creditLoanId": this.loanId
        };
        this.walletService.repay(payload).subscribe(res => {
          if (res) {
            this.toastrService.success(res.message);
            this.getLoanRepaymentDetails();
            this.getUserSavingBalance();
            this.router.navigateByUrl('repay');
          }

        }, err => {
          this.loading = false;
          if (err.error.message) {
            this.toastrService.error(err.error.message);
          } else {
            this.toastrService.error(UNABLE_TO_PERFORM_ERROR);
          }
        });
      }
      this.sub.unsubscribe();
    })

  }

  selectLockedCurrency(lockedDetails) {
    if(lockedDetails.isPayable) {
      
    }
    // this.toLockedCurrency = lockedDetails.currencySymbol;
    // this.toLockedCurrencyAmount = lockedDetails.amount;
    // this.toSymbol = lockedDetails.currencySymbol;
    // this.currencyType = lockedDetails.currencyType;
    // // this.currencyPair.getCurrencyPairList(lockedDetails.currencyId);
    // let currencyObject = {
    //   currencyType: lockedDetails.currencyType,
    //    icon: `../../../../assets/icons/${lockedDetails.currencySymbol}.png`,
    //    label: lockedDetails.currencySymbol,
    //    symbol: lockedDetails.currencySymbol,
    //    value: lockedDetails.currencyId
    // }

    // this.currencyPair.onCurrencyChange(currencyObject);
    // this.loanDetailsForm.controls.walletType.setValue("2");
  }

}

import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormBuilder
} from "@angular/forms";
import { RecipientService } from "../services/recipient.service";
import { MDBModalRef } from "ng-uikit-pro-standard";
import {
  UNABLE_TO_PERFORM_ERROR,
  MEMO_UPDATED,
} from "src/app/app.constants";
import { ToastrService } from "ngx-toastr";
@Component({
  selector: "cashaa-update-memo",
  templateUrl: "./update-memo.component.html",
  styleUrls: ["./update-memo.component.scss"],
})
export class UpdateMemoComponent implements OnInit {
  /** Recipient Form Group */
  updateMemoForm: FormGroup;
  /** is some network call in progress */
  isInProgress = false;
  currency;
  currencyId;
  accountNumber;
  recipientId; 
  networkTypeValue;
  networkTypeLabel;
  errorMessage = false;
  isLoading = true;
  networkTypeOptions = [];
  constructor(
    private fb: FormBuilder,
    public modalRef: MDBModalRef,
    private recipientService: RecipientService,
    private toastrService: ToastrService,
  ) { }
  initialValue;
  ngOnInit(): void {
    this.initialValue = this.currencyId;
    this.createForm();
  }

  /**
   * Create new form
   */
  createForm(): void {
    this.updateMemoForm = this.fb.group({
      currency: [""],
      cryptoAddress: [""],
      networkType: [""],
      cryptoMemo: [""]

    });
    this.updateMemoForm.controls.currency.disable();
    this.updateMemoForm.controls.cryptoAddress.disable();
    this.updateMemoForm.controls["cryptoAddress"].setValue(this.accountNumber);
    this.networkTypeOptions = [{ value: this.networkTypeValue.toString(), label: this.networkTypeLabel }];
    this.updateMemoForm.controls["networkType"].setValue(this.networkTypeValue.toString());
  }

  /**
   * On submission of form
   */
  onFormSubmit(): void {
    const data = {
      "id": this.recipientId ,
      "memo": this.updateMemoForm.get('cryptoMemo').value
    }
    this.recipientService.updateMemo(data).subscribe((res) => {
              this.isInProgress = false;
              this.modalRef.hide();
              this.updateMemoForm.reset();
              this.toastrService.success(MEMO_UPDATED);
              window.location.reload();
            },
            (err) => {
              this.isInProgress = false;
              if (err.error.message) {
                this.toastrService.error(err.error.message);
              } else {
                this.toastrService.error(UNABLE_TO_PERFORM_ERROR);
              }
            }
          );
    } 
 
}
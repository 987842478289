import { CreateKYC } from "src/app/core/models/create-kyc";
import { ValidatorUtility } from "./../../core/utility/validator.utility";
import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import { FormGroup, FormBuilder } from "@angular/forms";

@Component({
  selector: "cashaa-kyc-personal-address",
  templateUrl: "./kyc-personal-address.component.html",
  styleUrls: ["./kyc-personal-address.component.scss"],
})
export class KycPersonalAddressComponent implements OnInit {
  @Input()
  data: CreateKYC;
  @Input()
  isReadOnly: boolean;
  form: FormGroup;

  initialValueCountry;

  initialValueRegion;

  /** on continue click, emit event */
  @Output() continue = new EventEmitter<any>();

  constructor(private fb: FormBuilder) {
    this.createForm();
  }

  ngOnInit(): void {
    if (this.data) {
      this.initialValueCountry = this.data.countryId;
      this.updateForm();
    }
  }

  createForm(): void {
    this.form = this.fb.group({
      address1: ["", [ValidatorUtility.Required]],
      address2: ["", [ValidatorUtility.Required]],
      city: ["", [ValidatorUtility.Required]],
      //  region: ["", [ValidatorUtility.Required]],
      countryId: ["", [ValidatorUtility.Required]],
      region: ["", [ValidatorUtility.Required]],
      postalCode: [
        "",
        [ValidatorUtility.Required, ValidatorUtility.postalcode],
      ],
    });
  }

  updateForm(): void {
    if (this.isReadOnly) {
      this.form.disable();
    }
    this.form.controls.address1.setValue(this.data.address1);
    this.form.controls.address2.setValue(this.data.address2);
    this.form.controls.city.setValue(this.data.city);
    this.form.controls.countryId.setValue(this.data.countryId);
    this.form.controls.postalCode.setValue(this.data.postalCode);
    this.form.controls.region.setValue(this.data.region);
  }

  onContinue(): void {
    const data = this.form.value as CreateKYC;
    this.continue.emit(data);
  }
}

import { Component, OnInit } from "@angular/core";
import { HelpdeskTicketService } from "../services/helpdesk-ticket.service";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { ValidatorUtility } from "src/app/core/utility/validator.utility";
import {
  IHelpDeskCategories,
  IHelpDeskTicket,
  ICreateHelpDesk,
} from "src/app/core/models/create-helpdesk";
import { IFormSelect } from "src/app/core/models/form-select";
import { MDBModalRef, MDBModalService } from "ng-uikit-pro-standard";
import { ToastrService } from "ngx-toastr";
import {
  TICKET_CREATED_SUCCESS,
  UNABLE_TO_PERFORM_ERROR,
} from "src/app/app.constants";
import { LedgerType } from "src/app/core/enums/ledgerType";

@Component({
  selector: "cashaa-helpdesk-create-ticket",
  templateUrl: "./helpdesk-create-ticket.component.html",
  styleUrls: ["./helpdesk-create-ticket.component.scss"],
})
export class HelpdeskCreateTicketComponent implements OnInit {
  /** Form Group */
  createHelpdeskTicket: FormGroup;
  ticketDetail: IHelpDeskTicket[] = [];
  helpCategories: IFormSelect[] = [];
  selectedLedgerType: number;
  isInProgress: boolean;
  public attachedFile: File;
  constructor(
    private helpdeskService: HelpdeskTicketService,
    private fb: FormBuilder,
    public modalRef: MDBModalRef,
    private toastrService: ToastrService
  ) {
    this.createForm();
  }

  ngOnInit(): void {
    this.helpdeskService
      .getHelpDeskCatagories()
      .subscribe((catagories: IHelpDeskCategories[]) => {
        this.helpCategories = catagories.map((catagory) => {
          if (catagory.active) {
            return {
              value: catagory.id,
              label: catagory.categoryName,
            };
          }
        });

        if (this.selectedLedgerType) {
          if (
            this.selectedLedgerType === LedgerType.sent ||
            this.selectedLedgerType === LedgerType.Withdraw ||
            this.selectedLedgerType === LedgerType.Received ||
            this.selectedLedgerType === LedgerType.convert
          ) {
            const categoryType = this.getCategoryType(this.selectedLedgerType);
            this.createHelpdeskTicket.controls["categoryId"].setValue(
              categoryType.value
            );
            this.createHelpdeskTicket.updateValueAndValidity();
          }
        }
      });
  }

  /**
   * Create new form
   */
  createForm(): void {
    this.createHelpdeskTicket = this.fb.group({
      categoryId: ["", [ValidatorUtility.Required]],
      subject: ["", [ValidatorUtility.Required]],
      message: ["", [ValidatorUtility.Required]],
      attachment: [""]
    });
  }
  /**
   * Create new form
   */
  close(): void {
    this.modalRef.hide();
  }

  submit(): void {
    this.isInProgress = true;
    const formData = new FormData();
    formData.append("categoryId", this.createHelpdeskTicket.value.categoryId);
    formData.append("subject", this.createHelpdeskTicket.value.subject);
    formData.append("message", this.createHelpdeskTicket.value.message);
    formData.append("attachment", this.attachedFile);
    this.helpdeskService.createHelpDesk(formData).subscribe(
      (data: { statusCode: number; message: string }) => {
        if (data.message !== undefined) {
          this.isInProgress = false;
          this.toastrService.success(TICKET_CREATED_SUCCESS);
          this.createHelpdeskTicket.reset();
          this.modalRef.hide();
        }
      },
      (err) => {
        this.isInProgress = false;
        if (err.error.message) {
          this.toastrService.error(err.error.message);
        } else {
          this.toastrService.error(UNABLE_TO_PERFORM_ERROR);
        }
      }
    );
  }

  getCategoryType(ledgerType: number): IFormSelect {
    if (ledgerType === LedgerType.sent) {
      return this.helpCategories.filter((cat) => cat.label === "Transfer")[0];
    } else if (ledgerType === LedgerType.Withdraw) {
      return this.helpCategories.filter((cat) => cat.label === "Withdraw")[0];
    } else if (ledgerType === LedgerType.Received) {
      return this.helpCategories.filter(
        (cat) => cat.label === "Crypto Deposit"
      )[0];
    } else if (ledgerType === LedgerType.convert) {
      return this.helpCategories.filter((cat) => cat.label === "Convert")[0];
    }
  }

  
  onFileChange(event, control): void {
    if (event.target.files.length > 0) {
      if (control === "attachment") {
        this.attachedFile = event.target.files[0];
    
      const file = event.target.files[0];
      this.attachedFile = event.target.files[0];
      this.createHelpdeskTicket.patchValue({
        control: file,
      });
    }
  }
}

}

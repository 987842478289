import { Component, OnDestroy, OnInit } from "@angular/core";
import { RecipientService } from "../services/recipient.service";
import { IRecipient } from "src/app/core/models/fIat/recipients";
import { FiatService } from "src/app/fiat/services/fiat.service";
import { ToastrService } from "ngx-toastr";
import { UNABLE_TO_PERFORM_ERROR, VERIFICATION_MESG } from "src/app/app.constants";
import { MDBModalService } from "ng-uikit-pro-standard";
import { SubSink } from "subsink";

@Component({
  selector: "cashaa-recipient-list",
  templateUrl: "./recipient-list.component.html",
  styleUrls: ["./recipient-list.component.scss"],
})
export class RecipientListComponent implements OnInit, OnDestroy {
  /** recipient list */
  recipientList: IRecipient[] = [];

  /** bank accounts of user */
  bankAccounts: IRecipient[] = [];
  timerFlag = {};
  timerStart = {};
  timerTime = {};
  disableBtn = {};
  verificationClickCount = {};
  loading: boolean = false;
  subsink = new SubSink();
  cryptoAccount: IRecipient[] = [];
  constructor(
    private recipientService: RecipientService,
  ) {}

  ngOnInit(): void {
    this.getRecipientList();
    this.UpdateRecipentView()

    this.subsink.add(this.recipientService.getDeleteRecipientFlagEmitter().subscribe(flag=> {
      if (flag) {
        this.getRecipientList();
      }
    }))
  }

  UpdateRecipentView() {
    this.subsink.add(this.recipientService.getaddRecipientFlagEmitter().subscribe((res) => {
      this.getRecipientList();
    }));
  }

  /**
   * Get Recipient list
   */
  getRecipientList(): void {
    this.loading = false;
    this.recipientService.getRecipientList().subscribe((list) => {
      console.log(list);
      this.recipientList = list.filter((recipient: IRecipient) => {
        return recipient.typeName !== "Self Bank Account" && recipient.currencyType !== 1;
      });
      this.bankAccounts = list.filter((recipient: IRecipient) => {
        return recipient.typeName === "Self Bank Account";
      });
      this.cryptoAccount = list.filter((recipient: IRecipient) => {
        return recipient.currencyType === 1;
      });

      this.loading = true;
    });
  }

  
  ngOnDestroy() {
    this.subsink.unsubscribe();
  }
}

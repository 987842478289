import { Component, OnInit, Input } from "@angular/core";
import { ILedgerList } from "src/app/core/models/activity/recent-transaction-list";
import { ModalService } from "src/app/core/services/modal/modal.service";

@Component({
  selector: "cashaa-activity-balance-converted",
  templateUrl: "./activity-balance-converted.component.html",
  styleUrls: ["./activity-balance-converted.component.scss"],
})
export class ActivityBalanceConvertedComponent implements OnInit {
  @Input() ledger: ILedgerList;
  constructor(private modalService: ModalService) {}

  ngOnInit(): void {}

  openDetailBox(): void {
    this.modalService.openActivityTransferDetail(this.ledger);
  }

  getCurrencyName(curr): string {
    if (curr === "AUSD") return "USD";
    if (curr === "DOG") return "DOGE";
    else return curr;
  }

  getCurrencyAccName(curr): string {
    if (curr === "AUSD") return "USD Account";
    else if (curr === "USD") return "USD Wallet";
    else return curr;
  }
}

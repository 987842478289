<form [formGroup]="form" (ngSubmit)="onSubmit(form.value)" class="my-4">
  <div class="text-center" *ngIf="loading">
    <mdb-spinner spinnerColor="blue"></mdb-spinner>
  </div>
  <div class="row mb-4">
    <div class="col-12 col-sm-6">
      <cashaa-form-input name="firstName" type="text" [form]="form" [control]="form.controls.firstName"
        placeholder="Enter First Name" label="First Name">
      </cashaa-form-input>
    </div>
    <div class="col-12 col-sm-6 mobile-mt-4">
      <cashaa-form-input name="middleName" type="text" [form]="form" [control]="form.controls.middleName"
        placeholder="Enter Middle Name" label="Middle Name">
      </cashaa-form-input>
    </div>
  </div>
  <div class="row mb-4">
    <div class="col-12 col-sm-6">
      <cashaa-form-input name="lastName" type="text" [form]="form" [control]="form.controls.lastName"
        placeholder="Enter Last Name" label="Last Name">
      </cashaa-form-input>
    </div>
    <div class="col-12 col-sm-6 mobile-mt-4">
      <label class="heading">Date of birth</label>
      <mdb-date-picker [selDate]="form?.controls['birthDate'].value" [inline]="true" name="birthDate"
        [options]="myDatePickerOptions" [placeholder]="'Enter Date of birth'" formControlName="birthDate"
        class="datepicker-white"></mdb-date-picker>
    </div>
  </div>
  <div class="row mb-4">
    <div class="col-12 col-sm-6">
      <cashaa-form-input name="occupation" type="text" [form]="form" [control]="form.controls.occupation"
        placeholder="Enter Occupation" label="Occupation">
      </cashaa-form-input>
    </div>
    <div class="col-12 col-sm-6 mobile-mt-4">
      <cashaa-form-select-country name="countryOfBirth" [initialValue]="setCountryValue" type="countryOfBirth"
        [form]="form" [control]="form.controls.countryOfBirth" placeholder="Select Country"
        label="Country" (changed)="onCountryChange($event)"></cashaa-form-select-country>
    </div>
  </div>
  <div class="row mb-4">
    <div class="col-12 col-sm-6">
      <cashaa-form-select-transfer-currency [disable]="true" [initialValue]="setCurrencyValue"
        (sendCurrencyChangeEvent)="onCurrencyChange($event)" [noInput]='true' [form]="form" class="width-100"
        [currencyControl]="form.controls.currencyCode" label="Currency" currencyControlName="currencyCode" source="all">
      </cashaa-form-select-transfer-currency>
    </div>
  </div>
  <button mdbBtn block="true" type="submit"
    class="w-25 rounded-sm position-relative btn btn-primary waves-effect waves-light btn-block" mdbWavesEffect
    [disabled]="form.invalid">
    {{isDataLoaded ? 'Next' : 'Save & Next'}}
  </button>
</form>
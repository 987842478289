import { Location } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { USER_TYPE } from 'src/app/app.constants';
import { ValidatorUtility } from 'src/app/core/utility/validator.utility';
import { CommonSavedData } from 'src/app/shared/services/commonSavedData.service';
import { ApiManagerService } from '../services/api-manager.service';

@Component({
  selector: 'cashaa-add-webhook-url',
  templateUrl: './add-webhook-url.component.html',
  styleUrls: ['./add-webhook-url.component.scss']
})
export class AddWebhookURLComponent implements OnInit {

  isInProgress = false;
  twoFactor = false;
  userEmail?: string;
  errorMessageFlag = false;
  errorMessage = "";
  response = "";
  isLoading = true;
  currFlag;
  allCodeEntered = false;
  reserveBalance;
  code: string[] = ["", "", "", "", "", ""];
  @ViewChildren("inputs") inputs: QueryList<ElementRef>;
  errorMsg: string;
  balanceError: boolean;
  balanceErrorMsg: string;
  isBusiness: boolean;
  isStaking: boolean;
  stakingHeading: string;
  stakingBody: string;
  userType = sessionStorage.getItem(USER_TYPE);
  apiId: string;
  public addWebhookForm: FormGroup;
  webhookDetails;
  isWebhookURLAdded = false;
  constructor(
    private fb: FormBuilder,
    // public modalRef: MDBModalRef,
    private toastrService: ToastrService,
    private apiManagerService: ApiManagerService,
    private commanData: CommonSavedData,
    private location: Location,
    private activateRoute: ActivatedRoute

  ) { 
    this.addWebhookForm = this.fb.group({
        callbackURL: ["", ValidatorUtility.Required],
        sendCallbackURL: ["", ValidatorUtility.Required],
        sendStatusCallbackURL: ["", ValidatorUtility.Required]
      });
  }

  ngOnInit(): void {
    this.activateRoute.queryParams.subscribe((params) => {
        this.apiId = params["id"];
      });
      this.getWebhookURL();
      
  }

 getWebhookURL() {
  this.apiManagerService.getWebhookURL(this.apiId).subscribe((data) => {
    this.isWebhookURLAdded = true;
    this.addWebhookForm.controls.callbackURL.setValue(data?.callbackURL);
    this.addWebhookForm.controls.sendCallbackURL.setValue(data?.sendCallBackUrl);
    this.addWebhookForm.controls.sendStatusCallbackURL.setValue(data?.sendStatusCallbackUrl);  
 }, err => {

 });
}

  /**
   * On submission of form
   */
  onFormSubmit(): void {
    this.isInProgress = true;
    const payload = {
        "apiManagerId" : this.apiId,
        "callbackURL": this.addWebhookForm.value.callbackURL,
        "sendCallBackUrl":this.addWebhookForm.value.sendCallbackURL,
        "sendStatusCallbackUrl":this.addWebhookForm.value.sendStatusCallbackURL

    }
    this.apiManagerService.addWebhoolURL(payload).subscribe((data) => {
      if (data.statusCode === 200) {
        // this.modalRef.hide();
        this.webhookDetails = data;
        this.toastrService.success('Webhook URL added successfully');
        this.commanData.emitConfirmationBoxStatus(true);
      }
      this.isInProgress = false;
    }, (err: HttpErrorResponse) => {
      this.isInProgress = false;
      this.toastrService.error(err.error.message);
    })
  }


  goBack() {
    this.location.back();
  }

}

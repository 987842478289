<div class="modal-header position-relative sm-form-title">
    <button type="button" class="close pull-right sm-close" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">×</span>
    </button>
    <h3 class="modal-title w-100 text-primary font-weight-bolder" id="myModalLabel">
        Confirmation Delete API
    </h3>
</div>
<div class="modal-body">
    <div *ngIf="twoFactor" class="sm-form-padding">
        <p class="mb-3 font-weight-bold">Authentication Code</p>
        <div class="row google-code" (paste)="onCodePaste($event)">
            <div class="col-1 sm-code">
                <input type="text" [disabled]="isInProgress" maxlength="1" [(ngModel)]="code[0]"
                    (input)="checkIfCodeIsFilled(0)" autofocus #inputs (change)="removeError()" />
            </div>
            <div class="col-1 sm-code">
                <input type="text" [disabled]="isInProgress" maxlength="1" [(ngModel)]="code[1]"
                    (input)="checkIfCodeIsFilled(1)" #inputs (change)="removeError()" (keyup)="removeBack($event, 0)" />
            </div>
            <div class="col-1 sm-code">
                <input type="text" [disabled]="isInProgress" maxlength="1" [(ngModel)]="code[2]"
                    (input)="checkIfCodeIsFilled(2)" #inputs (change)="removeError()" (keyup)="removeBack($event, 1)" />
            </div>
            <div class="col-1 sm-code">
                <input type="text" [disabled]="isInProgress" maxlength="1" [(ngModel)]="code[3]"
                    (input)="checkIfCodeIsFilled(3)" #inputs (change)="removeError()" (keyup)="removeBack($event, 2)" />
            </div>
            <div class="col-1 sm-code">
                <input type="text" [disabled]="isInProgress" maxlength="1" [(ngModel)]="code[4]"
                    (input)="checkIfCodeIsFilled(4)" #inputs (change)="removeError()" (keyup)="removeBack($event, 3)" />
            </div>
            <div class="col-1 ">
                <input type="text" [disabled]="isInProgress" maxlength="1" [(ngModel)]="code[5]"
                    (input)="checkIfCodeIsFilled(5)" #inputs (change)="removeError()" (keyup)="removeBack($event, 4)" />
            </div>
        </div>
    </div>
    <div class="position-relative mt-3 mb-5">
        <mdb-error class="mt-2 w-100 text-center error-message" *ngIf="errorMessageFlag">{{ errorMsg }}</mdb-error>
        <mdb-error class="mt-2 w-100 text-center error-message" *ngIf="balanceError">{{ balanceErrorMsg }}</mdb-error>
    </div>

    <div class="mb-4 ml-4" *ngIf="!twoFactor">
        <span>
            Your google 2FA is not enabled
            <a [routerLink]="['/settings']" [queryParams]="{step:'2fa'}" (click)="modalRef.hide()">click here</a> to
            enable.</span>
    </div>
    <div class="mt-4">
        <button (click)="onFormSubmit()" block="true" type="button" [disabled]="!allCodeEntered || !twoFactor"
            class="rounded-sm position-relative btn btn-primary waves-light btn-block sm-font" mdbWavesEffect>
            Confirm
        </button>
    </div>
    <mdb-progress-bar *ngIf="isInProgress" class="progress primary-color-dark position-absolute" mode="indeterminate">
    </mdb-progress-bar>
</div>
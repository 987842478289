import { ICurrency } from "../currency";
import { ITransferFees } from "../transfer-types";
import { TransferFeeType } from "./transfer-type";

export interface IFiatFlow {
  sendercurrencyId: string;
  senderAmount: number;
  recivercurrencyId: string;
  reciverAmount: number;
  sendertransferFeeId: string;
  memo: string;
  fromcurrencyObject: ICurrency;
  feeAmountObject: ITransferFees;
  fees: number;
  deliveryMech?: string;
  networkType?: string;
  recipientType?: number
  //  fiatBeneficiaryId : string;
}

export class FiatFlow implements IFiatFlow {
  constructor(
    public sendercurrencyId: string,
    public senderAmount: number,
    public recivercurrencyId: string,
    public reciverAmount: number,
    public sendertransferFeeId: string,
    public memo: string,
    public fromcurrencyObject: ICurrency,
    public feeAmountObject: ITransferFees,
    public fees: number,
    public deliveryMech?: string,
    public networkType?: string,
    public recipientType?: number
  ) {}
}

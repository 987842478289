import { ValidatorUtility } from "./../../core/utility/validator.utility";
import { SettingsService } from "./../services/settings.service";
import {
  Google2faDto,
  Enabletwofarequest,
  Createtwofa,
} from "./../../core/models/EnableTwoFa";
import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  QueryList,
  ViewChildren,
} from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { HttpErrorResponse } from "@angular/common/http";
import { ToastrService } from "ngx-toastr";
import {
  APP_ROUTES,
  ENABLE_2FA,
  UNABLE_TO_PERFORM_ERROR,
  USER_EMAIL_PATH,
  USER_TWO_FACTOR_CODE,
  USER_TYPE,
  VERIFICATION_MESG,
} from "src/app/app.constants";
import { SBItemComponent } from "ng-uikit-pro-standard";
import { ActivatedRoute, Router } from "@angular/router";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: "cashaa-settings-two-factor-auth",
  templateUrl: "./settings-two-factor-auth.component.html",
  styleUrls: ["./settings-two-factor-auth.component.scss"],
})
export class SettingsTwoFactorAuthComponent implements OnInit {
  google2fa: Google2faDto;

  /** is opened */
  isOpen = false;

  /** show form or not */
  showForm = false;

  /** is some call in progress */
  isInProgress = false;

  isLoading = false;

  /** form group */
  form: FormGroup;

  copied: boolean = false;

  errorMessage: string;

  isCollapsed = true;

  allCodeEntered = false;
  code: string[] = ["", "", "", "", "", ""];

  @ViewChildren("inputs") inputs: QueryList<ElementRef>;

  @ViewChildren(SBItemComponent) collapses: QueryList<SBItemComponent>[];

  userEmailId: string = sessionStorage.getItem(USER_EMAIL_PATH);

  encodeKey(key: string): string {
    return encodeURIComponent(key);
  }
  encodeValue(value: string): string {
    return encodeURIComponent(value);
  }
  decodeKey(key: string): string {
    return decodeURIComponent(key);
  }
  decodeValue(value: string): string {
    return decodeURIComponent(value);
  }

  /** event name */
  event: string;

  /** token */
  token: string;

  twofaBoxEnable: boolean = false;

  fileUrl;
  private navigationKeys = [
    'Backspace',
    'Delete',
    'Tab',
    'Escape',
    'Enter',
    'Home',
    'End',
    'ArrowLeft',
    'ArrowRight',
    'Clear',
    'Copy',
    'Paste'
  ];
  constructor(
    private fb: FormBuilder,
    private settingsService: SettingsService,
    private toastrService: ToastrService,
    private activateRoute: ActivatedRoute,
    private router: Router,
    private domTest: DomSanitizer
  ) {
    this.createForm();
  }

  ngOnInit(): void {
    
    this.getTwoFaAuth(false);
    this.onChanges();
    this.activateRoute.queryParams.subscribe((params) => {
      const setting2FA = params["step"];
      if (setting2FA === "2fa") {
        this.isCollapsed = false;
        this.isOpen = true;
      }
    });

    this.activateRoute.queryParams.subscribe((params) => {
      let decodeVal: string = params["token"]
        ? this.decodeKey(params["token"])
        : "";

      this.event = params["event"];
      this.token = decodeVal ? decodeVal.split(" ").join("+") : "";
      if (this.event === 'disabletwofa' && this.token) {
        this.isOpen = true;
            setTimeout(() => {
              this.collapse(false);
            });
          this.twofaBoxEnable = true;  
      }
    });
  }

  /**
   * create new form
   */
  createForm(): void {
    this.form = this.fb.group({
      code: ["", ValidatorUtility.Required],
    });
  }

  /**
   * get 2fa info
   */
  getTwoFaAuth(getkey: boolean): void {
    const createKey = new Createtwofa(getkey);
    if (!getkey) {
      this.isLoading = true;
    } 
    this.settingsService.createTwoFaAuth(createKey).subscribe(
      (res) => {
        this.google2fa = res;
        this.isLoading = false;
        if (this.google2fa.secrateKey) {
          this.showForm = true;
          const blob = new Blob([this.google2fa.secrateKey], { type: 'application/octet-stream' });
          this.fileUrl = this.domTest.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blob));
        }
        this.isInProgress = false;
        if (!this.google2fa.isActive) {
             sessionStorage.removeItem(USER_TWO_FACTOR_CODE);
        }
      },
      (err) => {
         this.isLoading = false;
        if (err.error.message) {
          this.toastrService.error(err.error.message);
        } else {
          this.toastrService.error(UNABLE_TO_PERFORM_ERROR);
        }
      }
    );
  }

  get2fakey(): void {
    this.isInProgress = true;
    this.getTwoFaAuth(true);
  }

  disableTwoFa(code?: string, token?: string): void {
    this.isInProgress = true;
    this.settingsService.disableTwofaCode(code, token).subscribe((data)=> {
      if (data.message === 'success') {
          this.isInProgress = false;
          this.showForm = false;
          this.isOpen = false;
          this.collapse(true);
          this.twofaBoxEnable = false;
          if (code && token) {
            sessionStorage.setItem(USER_TWO_FACTOR_CODE, 'false');
            this.google2fa = undefined;
            this.code = ["", "", "", "", "", ""];
            this.router.navigateByUrl(APP_ROUTES.SETTING_BASE);
          } else {
            this.toastrService.success(VERIFICATION_MESG);
          }
      } else {
        this.toastrService.success(UNABLE_TO_PERFORM_ERROR);
      }
      
    },
    (err) => {
      this.isInProgress = false;
      if (err.error.message) {
        this.toastrService.error(err.error.message);
      } else {
        this.toastrService.error(UNABLE_TO_PERFORM_ERROR);
      }
    })
  }

  onChanges(): void {
    this.form.valueChanges.subscribe((val) => {
      if (val !== undefined) {
        this.errorMessage = undefined;
      }
    });
  }

  /**
   * on auth form submit
   */
  onAuthFormSubmit(code?: string): void {
    if (this.twofaBoxEnable) {
        this.disableTwoFa(code, this.token);
    } else {
      this.isInProgress = true;
      const data = new Enabletwofarequest(
        code ? code : this.form.controls["code"].value
      );
      
      this.settingsService.enablegoogleAuth(data).subscribe(
        (res) => {
          this.google2fa = res;
          this.isInProgress = false;
          sessionStorage.setItem(USER_TWO_FACTOR_CODE, "true");
          this.showForm = false;
          this.isOpen = false;
          this.collapse(true);
          this.toastrService.success(ENABLE_2FA);
        },
        (err: HttpErrorResponse) => {
          this.isInProgress = false;
          this.code = ["", "", "", "", "", ""];
          if (err.error.message) {
            this.toastrService.error(err.error.message);
          } else {
            this.toastrService.error(UNABLE_TO_PERFORM_ERROR);
          }
        }
      );
    }
    
  }

  copyAddress(): void {
    this.copied = true;
    navigator.clipboard.writeText(this.google2fa.secrateKey);
    this.toastrService.toastrConfig.preventDuplicates = true;
    this.toastrService.success("Text copied to clipboard");
    setTimeout(() => (this.copied = false), 300);
  }

  removeError(): void {
    if (this.errorMessage !== undefined) {
      this.errorMessage = undefined;
    }
  }

  /**
   * Check if google input authenticator
   * code is filled in all 6 boxes
   */
  checkIfCodeIsFilled(idx: number): void {
    if (idx >= 0 && idx < 5 && this.code[idx]) {
      this.inputs.toArray()[idx + 1].nativeElement.focus();
    }

    this.allCodeEntered = true;
    this.code.forEach((val) => {
      if (val === null || val.length === 0) {
        this.allCodeEntered = false;
      }
    });
    if (this.code[5] && idx === -1) {
      this.inputs.toArray()[5].nativeElement.focus();
    }

    if (this.allCodeEntered) {
      this.isInProgress = true;
      const code = this.code.join("");
      this.onAuthFormSubmit(code);
    }
  }

  /**
   * On code paste automatically
   * fill all 6 boxes
   */
  onCodePaste(event: ClipboardEvent): void {
    const clipboardData = event.clipboardData || (window as any).clipboardData;
    const pastedText = clipboardData.getData("text");

    if (pastedText && pastedText.length === 6) {
      this.code = pastedText.split("");
      this.checkIfCodeIsFilled(-1);
    }
  }

  removeBack(event: any, index: number) {
    const keyCode = event.keyCode;
    if (keyCode === 8) {
      this.inputs.toArray()[index].nativeElement.focus();
    }
  }

  isTwoFaEnable(): boolean {
    return this.google2fa && this.google2fa?.isActive;
  }

  collapse(collapsed: boolean) {
    this.collapses.forEach((el: any) => {
      el.toggle(collapsed);
    });
  }

  downloadFileName(): string {
    return `cashaa-2FA-${this.userEmailId}.txt`;
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(e: KeyboardEvent) {
    if (
      // Allow: Delete, Backspace, Tab, Escape, Enter, etc
      this.navigationKeys.indexOf(e.key) > -1 ||
      (e.key === 'a' && e.ctrlKey === true) || // Allow: Ctrl+A
      (e.key === 'c' && e.ctrlKey === true) || // Allow: Ctrl+C
      (e.key === 'v' && e.ctrlKey === true) || // Allow: Ctrl+V
      (e.key === 'x' && e.ctrlKey === true) || // Allow: Ctrl+X
      (e.key === 'a' && e.metaKey === true) || // Cmd+A (Mac)
      (e.key === 'c' && e.metaKey === true) || // Cmd+C (Mac)
      (e.key === 'v' && e.metaKey === true) || // Cmd+V (Mac)
      (e.key === 'x' && e.metaKey === true) // Cmd+X (Mac)
    ) {
      return;
    }
    if (e.key === ' ' || isNaN(Number(e.key))) {
      e.preventDefault();
    }
  }
}

<section class="p-4 sm-width-phone-700">
  <!-- Bank details -->
  <h6 class="heading">Your bank accounts({{ bankAccounts?.length }})</h6>
  <hr class="mb-4" />
  <div [hidden]="!loading" *ngFor="let account of bankAccounts;  let i = index">
    <cashaa-recipient [data]="account"></cashaa-recipient>
  </div>
  <div class="p-4 text-center" [hidden]="loading">
    <mdb-spinner spinnerColor="blue"></mdb-spinner>
  </div>
  <div class="d-flex pl-5 pt-3 mb-5" *ngIf="bankAccounts.length == 0 && loading">
    <mdb-icon fas icon="user-plus" size="3x"></mdb-icon>
    <div class="ml-4">
      <p class="font-weight-bold mb-1">Add one of your bank accounts</p>
      <p class="font-weight-normal">
        Click the 'Add your bank account' button above or mark one from your
        list of other recipients below.
      </p>
    </div>
  </div>

  <!-- Recipients -->
  <!-- <ng-container > -->
    <!-- <div *ngIf="recipient.currencyType==='2'"> -->
     <h6 class="heading mt-3">
    Other bank accounts ({{ recipientList?.length }})
  </h6>
  <hr class="mb-4" />
  <div [hidden]="!loading" *ngFor="let recipient of recipientList; let i = index">
    <cashaa-recipient [data]="recipient"></cashaa-recipient>
  </div>

  <h6 class="heading mt-3">
    Crypto addresses ({{ cryptoAccount?.length }})
  </h6>
  <hr class="mb-4" />
  <div [hidden]="!loading" *ngFor="let account of cryptoAccount; let i = index">
    <cashaa-recipient [data]="account"></cashaa-recipient>
  </div>
    <!-- </div> -->
  <!-- </ng-container> -->
  
  <div class="p-4 text-center" [hidden]="loading">
    <mdb-spinner spinnerColor="blue"></mdb-spinner>
  </div>
</section>

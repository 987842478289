<h5 class="heading text-center mb-4">Does everything look right?</h5>
<div class="card p-4 m-auto w-50">
    <div class="d-flex justify-content-between">
        <div>Ticket Type</div>
        <div class="font-weight-600" *ngIf="confirmedTicketDetails?.eventSubscription == 1">Visitor</div>
        <div class="font-weight-600" *ngIf="confirmedTicketDetails?.eventSubscription == 2">Premium</div>
        <div class="font-weight-600" *ngIf="confirmedTicketDetails?.eventSubscription == 3">VIP</div>
    </div>
    <div class="d-flex justify-content-between">
        <div>Amount</div>
        <div  class="font-weight-600" *ngIf="confirmedTicketDetails?.eventSubscription == 1; else otherTicket">0</div>
        <ng-template #otherTicket>
          <div class="font-weight-600">{{confirmedTicketDetails?.amount}} {{confirmedTicketDetails?.symbol}}</div>
        </ng-template>
    </div>
    <div class="d-flex justify-content-between">
        <div>Date</div>
        <div class="font-weight-600">{{currentDate | date:'dd-MM-yyyy'}}</div>
    </div>
</div>

<div class="text-center py-4 m-auto w-50">
    <button mdbBtn type="button" class="btn-sm app-success-btn m-auto w-100" (click)="continue()">Continue
    </button>
  </div>
  <div class="go-back pointer sm-blue-custom" (click)="goToPreviousPage()">
    <mdb-icon class="sm-cursor-pointer" fas icon="arrow-left"> </mdb-icon>
    <span class="sm-cursor-pointer" style="padding-left: 10px;">Go back</span>
  </div>
  <div class="p-4 text-center" [hidden]="!loading">
    <mdb-spinner spinnerColor="blue"></mdb-spinner>
  </div>
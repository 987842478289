import { KycService } from "./../services/kyc.service";
import { IFormSelect } from "./../../core/models/form-select";
import {
  ICreateBusinessKYC,
  IStakeholders,
} from "./../../core/models/create-business-kyc";
import { ValidatorUtility } from "./../../core/utility/validator.utility";
import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import { FormGroup, FormBuilder, FormArray, FormControl, Validators } from "@angular/forms";
import {
  DATA_SUCCESS_MESG,
  UNABLE_TO_PERFORM_ERROR,
} from "src/app/app.constants";
import { ToastrService } from "ngx-toastr";
import { IMyOptions } from "ng-uikit-pro-standard";

@Component({
  selector: "cashaa-kyc-business-director-detail",
  templateUrl: "./kyc-business-director-detail.component.html",
  styleUrls: ["./kyc-business-director-detail.component.scss"],
})
export class KycBusinessDirectorDetailComponent implements OnInit {
  @Output()
  continue = new EventEmitter<ICreateBusinessKYC>();
  // get a emitted values
  @Input()
  informationData: ICreateBusinessKYC;

  directorData: IStakeholders[];
  errorMessage = "";
  errorMessage2 = "";
  loader = false;

  orgType?: IFormSelect[];
  form: FormGroup;
  currentDate: Date;

  shareexceeded: boolean;
  maxBirthdayDate = new Date();
  myDatePickerOptions: IMyOptions = {
    minYear: 1900,
    maxYear:  new Date().getFullYear() - 18
  };

  get directorKycdetailsList(): FormArray {
    return this.form.get("directorKycdetailsList") as FormArray;
  }

  constructor(
    private fb: FormBuilder,
    private kycService: KycService,
    private toastrService: ToastrService
  ) {
    this.shareexceeded = false;
  }

  disableInputs() {
    (<FormArray>this.form.get("directorKycdetailsList")).controls.forEach(
      (form) => {
        // form.disable();
      }
    );
  }

  loadOldDirector() {
    this.kycService.getKYCDirectorDetails().subscribe((directlist) => {
      if (directlist && directlist.length > 0) {
        let index = 0;
        directlist.forEach((element) => {
          this.directorKycdetailsList.push(
            this.createItem(
              false,
              element.id,
              element.firstName,
              element.lastName,
              element.dateOfBirth,
              element.email,
              element.phone,
              element.type,
              element.percentageShares,
              element.isMainApplicant,
              element.homeAddressLine1,
              element.homeAddressCountryCode,
              element.homeAddressPostCode,
              element.homeAddressCity,
              index
            )
          );
          index = index + 1;
        });
        this.disableInputs();
      } else {
        this.directorKycdetailsList.push(
          this.createItem(
            true,
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            0,
            false,
            "",
            "",
            "",
            "",
            0
          )
        );
      }
    });
  }
  ngOnInit(): void {
    this.getContactType();
    this.createForm();
    this.loadOldDirector();
  }

  // this.info.continue.
  /**
   * Create new form
   */
  createForm(): void {
    this.form = this.fb.group({
      directorKycdetailsList: this.fb.array([]),
    });
  }

  /**
   * Create item of array
   */
  createItem(
    isNew: boolean,
    id: string,
    firstName: string,
    lastName: string,
    dateOfBirth: string,
    email: string,
    phone: string,
    type: string,
    percentageShares: number,
    isMainApplicants: boolean,
    homeAddressLine1: string,
    homeAddressCountryCode: string,
    homeAddressPostCode: string,
    homeAddressCity: string,
    index: number
  ): FormGroup {
    let group = this.fb.group({
      id: [isNew === true ? "" : id],
      firstName: [isNew === true ? "" : firstName, [ValidatorUtility.Required]],
      lastName: [isNew === true ? "" : lastName, [ValidatorUtility.Required]],
      dateOfBirth: [
        isNew === true ? "" : dateOfBirth,
        [ValidatorUtility.Required],
      ],
      email: [isNew === true ? "" : email, [ValidatorUtility.Required]],
      phone: [isNew === true ? "" : phone, [ValidatorUtility.Required, ValidatorUtility.mobilenumber]],
      type: [isNew === true ? "" : type, [ValidatorUtility.Required]],
      percentageShares: [
        isNew === true ? "" : percentageShares,
        [ValidatorUtility.Required, ValidatorUtility.OnlyNumberRequired],
      ],
      homeAddressLine1: [
        isNew === true ? "" : homeAddressLine1,
        [ValidatorUtility.Required],
      ],
      homeAddressCountryCode: [
        isNew === true ? "" : homeAddressCountryCode,
        [ValidatorUtility.Required, Validators.maxLength(3)],
      ],
      homeAddressPostCode: [
        isNew === true ? "" : homeAddressPostCode,
        [ValidatorUtility.Required, Validators.maxLength(13), Validators.minLength(2)],
      ],
      homeAddressCity: [
        isNew === true ? "" : homeAddressCity,
        [ValidatorUtility.Required],
      ],
    });

    group.addControl(
      `isMainApplicant${index}`,
      new FormControl(isNew === true ? "" : isMainApplicants.toString(), [
        ValidatorUtility.Required,
      ])
    );
    return group;
  }

  /**
   * Add item in array
   */
  addItem(): void {
    if (this.directorKycdetailsList.value.length > 9 || this.form.invalid) {
      return;
    }
    this.directorKycdetailsList.push(
      this.createItem(
        true,
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        0,
        false,
        "",
        "",
        "",
        "",
        this.directorKycdetailsList.length
      )
    );
  }

  /**
   * Remove item from array
   */
  removeItem(index: number): void {
    this.directorKycdetailsList.removeAt(index);
  }

  /**
   * If share exceeded 100%
   *
   */
  sumSharePercentage(
    data: Array<IStakeholders>,
    sharePercentage: number
  ): void {
    let sum = 0;

    data.forEach((a) => (sum += Number(a.percentageShares)));

    if (sum === sharePercentage) {
      this.shareexceeded = false;
    } else if (sum < 100) {
      this.shareexceeded = true;
      this.errorMessage2 = "Total shared can't be less than 100%";
    }
  }
  // load Business Type
  // tslint:disable-next-line: typedef
  getContactType() {
    this.kycService.getContactType().subscribe((resData) => {
      this.orgType = resData.map((d) => {
        return {
          value: d.key,
          label: d.key,
        };
      });
    });
  }

  // DOB Options

  setFormControl(date: any, i: number) {
    (<FormArray>this.form.get("directorKycdetailsList")).controls[i]
      .get("dateOfBirth")
      .setValue(date.actualDateFormatted);
  }
  onFormSubmit(): void {
    const data = this.form.controls["directorKycdetailsList"]
      .value as Array<IStakeholders>;
    // this.sumSharePercentage(data, 100);
    data.forEach((row, index) => {
      let temp = row["isMainApplicant" + index];
      delete row["isMainApplicant" + index];
      row.isMainApplicant = temp;
    });
    this.informationData.stakeholdersList = data;
    if (!this.shareexceeded) {
      this.loader = true;
      this.kycService.createBusinessKyc(this.informationData).subscribe(
        (res) => {
          this.toastrService.success(DATA_SUCCESS_MESG);
          this.loader = false;
          this.continue.emit(this.informationData);
        },
        (err) => {
          if (err.error.message) {
            this.toastrService.error(err.error.message);
            this.errorMessage = err.error.message;
          } else {
            this.toastrService.error(UNABLE_TO_PERFORM_ERROR);
          }
          this.loader = false;
          this.errorMessage = err.error.error;
        }
      );
    }
    /**
     * End emitted
     */
  }

  // isAddItem(flag): boolean {
  //   const data = this.form.controls["directorKycdetailsList"]
  //     .value as Array<IStakeholders>;
  //   let sum = 0;
  //   data.forEach((a) => (sum += Number(a.percentageShares)));
  //   if (sum === 100) {
  //     this.errorMessage = "";
  //     this.errorMessage2 = "";
  //     this.shareexceeded = false;
  //     return true;
  //   } else {
  //     this.shareexceeded = false;
  //     this.errorMessage = "";
  //   }
  // }

  isEmailDuplicate(): boolean {
    const data = this.form.controls["directorKycdetailsList"]
      .value as Array<IStakeholders>;
    let i = 0;
    let set = new Set();
    data.forEach((a) => {
      set.add(a.email);
      i++;
    });

    if (i === set.size) {
      this.errorMessage = "";
      return false;
    } else {
      this.errorMessage = "Duplicate Email not allowed.";
      return true;
    }
  }
}

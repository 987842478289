import { Component, OnInit, Input, OnChanges } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { CONTROLS } from "../../../app.constants";
import { ValidatorUtility } from "src/app/core/utility/validator.utility";
import { DecimalPipe } from "@angular/common";

@Component({
  selector: "cashaa-form-input",
  templateUrl: "./form-input.component.html",
  styleUrls: ["./form-input.component.scss"],
})
export class FormInputComponent implements OnInit {
  /** type of input field */
  @Input() type = CONTROLS.TEXT;

  /** Placeholder for input */
  @Input() placeholder = "";

  /** input form group */
  @Input() form: FormGroup;

  /** input form group */
  @Input() transformNumber: boolean;

  /** form control */
  @Input() control: FormControl;

  /** name of control */
  @Input() name: string;

  /** show eye or not */
  @Input() eye = true;

  @Input() label = "";

  @Input() isFile = false;

  @Input() id;

  @Input() onlyValue = false;

  @Input() pattern: string = "";

  @Input() maxLength: string = "";

  @Input() isRequired: boolean;

  @Input() isBrokerFile: boolean;

  @Input() isSetting: boolean;

  @Input() infoMesg: string;

  email: boolean = false;

  emailFocus: boolean;

  isEmailError: boolean;

  /** Whether to show password in text  */
  showPassword = false;

  /** is Password type */
  isPasswordType = false;

  validKeyCodes = [
    48,
    49,
    50,
    51,
    52,
    53,
    54,
    55,
    56,
    57,
    8,
    46,
    96,
    97,
    98,
    99,
    100,
    101,
    102,
    103,
    104,
    105,
    110,
    190,
  ];

  constructor(private decimalNumber: DecimalPipe) {}

  ngOnInit(): void {
    if (this.type === CONTROLS.PASSWORD) {
      this.isPasswordType = true;
    }

    if (this.type === CONTROLS.EMAIL) {
      this.email = true;
    }
  }

  /**
   * Get Error message for controls
   */
  getErrorMessage(): string {
    for (const propertyName in this.control.errors) {
      if (
        this.control.errors.hasOwnProperty(propertyName) &&
        this.control?.invalid &&
        (this.control?.dirty || this.control?.touched)
      ) {
        return ValidatorUtility.GetValidatorErrorMessage(
          propertyName,
          this.control.errors[propertyName]
        );
      }
    }
  }

  getEmailErrorMesg(): string {
    if (this.email && this.emailFocus) {
      const val = ValidatorUtility.EmailValidator(this.control);
      if (val != null) {
        return "Invalid email address";
      }
    }
  }

  /**
   * Toggle password show in text
   */
  toggleShowPassword(): void {
    this.showPassword = !this.showPassword;
  }

  focusOut($event) {
    if (this.email) {
      this.emailFocus = true;
      const val = ValidatorUtility.EmailValidator(this.control);
      if (val != null) {
        this.isEmailError = true;
      }
    }
  }

  focusin() {
    if (this.email) {
      this.emailFocus = false;
      this.isEmailError = false;
    }
  }

  transformNumberKeyUp(event) {
    let keyCode = event.keyCode;
    if (this.transformNumber && this.validKeyCodes.indexOf(keyCode) >= 0) {
      if (
        event.keyCode !== 190 &&
        event.keyCode !== 110 &&
        this.control.value
      ) {
        let decimalPoint =
          "0." +
          (this.control.value.split(".")[1]
            ? this.control.value.split(".")[1].length
            : "0") +
          "-4";
        this.control.setValue(
          this.decimalNumber.transform(
            this.control.value.replaceAll(",", ""),
            decimalPoint
          )
        );
      }
    }
  }
}

<form [formGroup]="form" class="my-4">
    <div class="row mb-4">
        <div class="col-12 col-sm-6">
            <cashaa-form-input name="address1" type="address1" [form]="form" [isRequired]="true" [control]="form.controls.address1" placeholder="Street" label="Street"></cashaa-form-input>
        </div>
        <div class="col-12 col-sm-6 mobile-mt-4">
            <cashaa-form-input name="address2" type="address2" [form]="form" [isRequired]="true" [control]="form.controls.address2" placeholder="Enter House Number" label="House Number"></cashaa-form-input>
        </div>
    </div>
    <div class="row mb-3">
        <div class="col-12 col-sm-6">
            <cashaa-form-input name="city" type="city" [form]="form" [isRequired]="true" [control]="form.controls.city" placeholder="Enter City" label="City"></cashaa-form-input>
        </div>
        <div class="col-12 col-sm-6 mobile-mt-4">
            <cashaa-form-input name="region" type="region" [form]="form" [isRequired]="true" [control]="form.controls.region" placeholder="Enter Region" label="Region"></cashaa-form-input>
        </div>
    </div>
    <div class="row mb-5">
        <div class="col-12 col-sm-6">
            <cashaa-form-select-country [initialValue]="initialValueCountry" [isRequired]="true" name="countryId" type="countryId" [form]="form" [control]="form.controls.countryId" placeholder="Select Country" label="Country">
            </cashaa-form-select-country>
        </div>
        <div class="col-12 col-sm-6 mobile-mt-4">
            <cashaa-form-input name="postalCode" type="postalCode" [form]="form" [isRequired]="true" [control]="form.controls.postalCode" placeholder="Enter Zip / Postal Code" label="Zip / Postal Code"></cashaa-form-input>
        </div>
    </div>
    <div class="row mb-4 d-flex justify-content-center">
        <div class="col-12 col-sm-6 d-flex">
            <button [disabled]="form.invalid" mdbBtn block="true" type="button" class="rounded-sm position-relative btn btn_green waves-effect waves-light btn-block text-light" mdbWavesEffect (click)="onContinue()">
        Continue
      </button>
        </div>
    </div>
</form>
import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { UserService } from "src/app/core/services/user/user.service";
import { IUserBalance } from "src/app/core/models/user-balance";
import {
  CURRENCY_TYPE,
  TRADE_MODE,
  CRYPTO_CURRENCY,
  WALLET_CURRENCY,
} from "src/app/app.constants";

@Component({
  selector: "cashaa-form-select-balance",
  templateUrl: "./form-select-balance.component.html",
  styleUrls: ["./form-select-balance.component.scss"],
})
export class FormSelectBalanceComponent implements OnInit {
  /** Currency type */
  @Input() type = CURRENCY_TYPE.CRYPTO;

  /** Trade mode to use */
  @Input() mode = TRADE_MODE.SELL;

  /** output emitter */
  @Output() changed = new EventEmitter<IUserBalance>();

  /** selected currency from dropdown */
  selectedBalance: IUserBalance;

  /** All currencies to show in dropdown */
  balances: IUserBalance[] = [];

  /** All currencies */
  allBalances: IUserBalance[] = [];

  /** is dropdown opened */
  isDropdownOpened = false;

  /** is current device mobile */
  isMobile = window.matchMedia("(max-width: 575px)").matches;

  constructor(private userService: UserService) {}

  ngOnInit(): void {
    this.userService.getUserBalance().subscribe((res) => {
      this.allBalances = this.filterBalance(res);
      this.selectedBalance = this.allBalances[0];
      this.changed.emit(this.selectedBalance);
      this.balances = this.filterOutSelectedBalance(this.allBalances);
    });
  }

  /**
   * Filter balance accroding to given
   * type
   */
  filterBalance(input: IUserBalance[]): IUserBalance[] {
    return input.filter((balance) => {
      if (this.type === CURRENCY_TYPE.WALLET) {
        return Object.keys(WALLET_CURRENCY).includes(balance.currencyType);
      } else if (this.type === CURRENCY_TYPE.CRYPTO) {
        return Object.keys(CRYPTO_CURRENCY).includes(balance.currencyType);
      }
    });
  }

  /**
   * Filter out the selected balance
   * @param input input balance
   */
  filterOutSelectedBalance(input: IUserBalance[]): IUserBalance[] {
    return input.filter(
      (balance) => balance.currencyType !== this.selectedBalance.currencyType
    );
  }

  /**
   * On another balance selected
   */
  onBalanceSelected(balance: IUserBalance): void {
    this.selectedBalance = balance;
    this.isDropdownOpened = false;
    this.balances = this.filterOutSelectedBalance(this.allBalances);
    this.changed.emit(this.selectedBalance);
  }
}

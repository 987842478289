<div class="container-fluid bg-primary p-5 mb-5">
  <!-- Content here -->
  <div class=" p-5 mb-5">
    <h4 class="text-white text-center">
      Your {{ getEmittedCurrency }} account is ready to go
    </h4>
    <div class="text-center p-3">
      <mdb-icon far class="text-success-color fa-3x" icon="check-circle"></mdb-icon>
    </div>
    <div>
      <p class="text-center text-white">
        Now you can add and hold Money in this <br />Currency. want to get paid
        in {{ getEmittedCurrency }}? you get bank <br />
        details to go with this account too.
      </p>
    </div>

    <!---Personal Account Fund source-->
    <div class="text-center">
      <button *ngIf="userType === '5'" class="btn btn-primary px-3" (click)="onAccountOpenClick()">
        Open an account
      </button>
      <button *ngIf="userType === '4'" class="btn btn-primary px-3" (click)="onPersonalAccountOpenClick()">
        Open an account
      </button>
    </div>
  </div>
</div>
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  ChangeDetectorRef,
} from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { ValidatorUtility } from "src/app/core/utility/validator.utility";
import { pairwise } from "rxjs/operators";
import {
  CRYPTO_CURRENCY_MAX_VALUES,
  CRYPTO_CURRENCY_MIN_VALUES,
} from "src/app/app.constants";
import { isNumber } from "util";

@Component({
  selector: "cashaa-form-input-currency",
  templateUrl: "./form-input-currency.component.html",
  styleUrls: ["./form-input-currency.component.scss"],
})
export class FormInputCurrencyComponent implements OnInit, OnChanges {
  /** Placeholder for input */
  @Input() placeholder = "";

  /** Input currency */
  @Input() currency: string;

  /** input form group */
  @Input() form: FormGroup;

  /** form control */
  @Input() control: FormControl;

  /** name of control */
  @Input() name: string;

  /** on value changed */
  @Output() changed = new EventEmitter<number>();

  constructor() { }

  ngOnInit(): void {
    this.listenToValueChange();
  }

  ngOnChanges(): void {
    this.setLimits();
  }

  /**
   * Set currency limits
   */
  setLimits(): void {
    if (this.control) {
      this.control.clearValidators();
      const maxValue = CRYPTO_CURRENCY_MAX_VALUES[this.currency];
      const minValue = CRYPTO_CURRENCY_MIN_VALUES[this.currency];

      this.control.setValidators([
        Validators.min(minValue),
        Validators.max(maxValue),
      ]);

      this.control.updateValueAndValidity();
    }
  }

  /**
   * Listen to value change
   */
  listenToValueChange(): void {
    this.control.valueChanges
      .pipe(pairwise())
      .subscribe(([prev, next]: number[]) => {
        if (prev !== next) {
          const newValue = parseFloat((next || 0).toFixed(2));
          this.control.setValue(newValue);
        }
      });
  }

  /**
   * On input
   */
  onInput(value: string): void {
    const newValue = parseFloat((parseFloat(value) || 0).toFixed(2));
    this.control.setValue(newValue);
    // emit new value
    this.changed.emit(newValue);
  }

  /**
   * Get Error message for controls
   */
  getErrorMessage(): string {
    for (const propertyName in this.control.errors) {
      if (this.control.errors.hasOwnProperty(propertyName)) {
        return ValidatorUtility.GetValidatorErrorMessage(
          propertyName,
          this.control.errors[propertyName]
        );
      }
    }
  }
}

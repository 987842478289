<section class="activity-box px-3 mb-2">
    <div class="details">
        <mdb-accordion [multiple]="false" aria-multiselectable="false">
            <mdb-accordion-item class="no-collase">
                <mdb-accordion-item-head class="p-2" mdbWavesEffect>
                    <div class="d-flex flex-row px-4 py-2 align-items-center">
                        <div class="">
                            <img src="../../../assets/icons/api.png" style="width: 40px;" />
                        </div>
                        <div class="status ml-4" style="width: 50%;">
                            <span class="text-primary-dark font-weight-bold">
                                <h5>
                                    {{data.apiName}}
                                </h5>
                            </span>
                            <br />
                            <span class="text-gray"> </span>
                            <span style="font-size: 0.75rem !important" class="green-text font-weight-bolder"></span>
                        </div>
                    </div>
                </mdb-accordion-item-head>
                <mdb-accordion-item-body class="border-top border-light px-4 position-relative">
                    <div class="container px-4 pt-2">
                        <div class="row">
                            <div class="col-sm">
                                Key<br />
                                <b class="text-primary">{{data.apiKey}}
                                </b>
                            </div>
                            <div class="col-sm">
                                API Restrictions <br />
                                <b class="text-primary">General</b>&nbsp;
                                <div *ngFor="let item of data.apiFeatureTypeList" class="d-inline-flex">
                                    <b class="text-primary">{{item.name}}</b>&nbsp;
                                </div>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col-sm">
                                IP Restriction<br />
                                <div *ngFor="let item of data.ipList" class="d-inline-flex">
                                    <b class="text-primary">{{item.ip}}</b>&nbsp;
                                </div>
                            </div>
                            <div class="col">
                                <div class="bd-highlight">
                                    <button style="height: 46px;" mdbBtn type="button" outline="true" mdbWavesEffect class="sm-btn-input-hover btn-border btn-sm" (click)="updateDomain()">
                                        Update Domain
                                    </button>
                                </div>
                            </div>
                        </div>
                        <hr *ngIf="data.webHookList">
                        <ng-container *ngIf="data.webHookList">
                            <div class="row">
                                <div class="col-sm">
                                    Domain <br />
                                    <div  class="d-inline-flex">
                                        <b class="text-primary">{{data.webHookList?.domainUrl}}</b>&nbsp;
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="row">
                                        <div class="col">Domain Code
                                        <b class="text-primary">{{data.webHookList?.domainVerificatioinCode}}</b>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="row">
                                <div class="col">Download Link
                                <b class="text-primary">{{data.webHookList?.domainVerificatioinCode}}</b>
                                </div>
                                    <div class="col">
                                    <div class="bd-highlight">
                                        <button style="height: 46px;" mdbBtn type="button" outline="true" mdbWavesEffect class="sm-btn-input-hover btn-border btn-sm" (click)="editAPI()">
                                            Verify Domain
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                      
                    </div>
                    <hr />
                    <div class="d-flex bd-highlight">
                        <div class="bd-highlight">
                            <button style="height: 46px;" mdbBtn type="button" outline="true" mdbWavesEffect class="sm-btn-input-hover btn-border btn-sm" (click)="editAPI()">
                                Edit API
                            </button>
                        </div>
                        <div class="bd-highlight">
                            <button style="height: 46px;" mdbBtn type="button" outline="true" mdbWavesEffect class="sm-btn-input-hover btn-border btn-sm" (click)="addWebhookURL()">
                                Add Webhook URL
                            </button>
                        </div>
                        <!-- <div class="bd-highlight">
                            <button style="height: 46px;" mdbBtn type="button" outline="true" mdbWavesEffect class="sm-btn-input-hover btn-border btn-sm" (click)="verifyDomain()">
                                Verify Domain
                            </button>
                        </div>
                        <div class="bd-highlight">
                            <button style="height: 46px;" mdbBtn type="button" outline="true" mdbWavesEffect class="sm-btn-input-hover btn-border btn-sm" (click)="getDomainStatus()">
                                Get Domain Status
                            </button>
                        </div> -->
                        <div class="ml-auto bd-highlight">
                            <button style="height: 46px;" class="btn-border-delete btn-sm" mdbBtn type="button" outline="true" mdbWavesEffect (click)="deleteAPI()">
                                <span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"
                                    *ngIf="loader"></span>
                                Delete API
                            </button>
                        </div>
                    </div>
                </mdb-accordion-item-body>
            </mdb-accordion-item>
        </mdb-accordion>
    </div>
</section>
import { UserService } from "src/app/core/services/user/user.service";
import { WalletService } from "./../../wallet/services/wallet.service";
import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { KycService } from "../services/kyc.service";
import {
  IKYCDirectorDetailList,
  IKYCDirectorDetail,
} from "src/app/core/models/kyc-director-details";
import {
  IDirectorVerification,
  DirectorVerification,
} from "src/app/core/models/director-verification-link";

import { ICryptoAccountDetails } from "src/app/core/models/crypto-account-details";
import { IUserBalance } from "src/app/core/models/user-balance";
import { PayFee } from "src/app/core/models/pay-fee";
import { ModalService } from "src/app/core/services/modal/modal.service";
import { ToastrService } from "ngx-toastr";
import { FasDirective } from "ng-uikit-pro-standard";
import {
  APP_ROUTES,
  FEE_ALREADY_PAID,
  FEE_SUCCESS_MESG,
  UNABLE_TO_PERFORM_ERROR,
  USER_TYPE,
} from "src/app/app.constants";
import { Router } from "@angular/router";
import { IPaymentFeesDetail, UpgradePayment } from "src/app/core/models/kyc-us-personal/us-personal";

@Component({
  selector: "cashaa-kyc-busines-application-status",
  templateUrl: "./kyc-busines-application-status.component.html",
  styleUrls: ["./kyc-busines-application-status.component.scss"],
})
export class KycBusinesApplicationStatusComponent implements OnInit {
  kycDirectorDetails: IKYCDirectorDetailList;
  accountDetailsCryptoData: ICryptoAccountDetails;
  premuimFeesDetails: IPaymentFeesDetail;
  loader = true;
  balanceData?: IUserBalance;
  balanceDataFiat?: IUserBalance
  feePayment = false;
  copied = false;
  copiedMemo = false;
  isTick: boolean = false;
  isFeePaid: boolean = false;
  userType: string;
  subscriptionId: string;
  showTransfer: boolean = false;
  @Output()
  public continue = new EventEmitter<void>();
  public isCasBalSufficent: boolean = false;
  public isFiatBalSufficent: boolean = false;
  form: FormGroup

  constructor(
    private kycService: KycService,
    private walletService: WalletService,
    private userService: UserService,
    private modalService: ModalService,
    private toastrService: ToastrService,
    private router: Router,
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      feesType: ["", Validators.required],
    });
    this.form.controls.feesType.valueChanges.subscribe((val) => {
      if (val) {
        if (val === "cas") {
          if (!this.isCasBalSufficent) {
            this.showTransfer = true;
          } else {
            this.showTransfer = false;
          }
        } else if (val === "fiat") {
          if (!this.isFiatBalSufficent) {
            this.showTransfer = true;
          } else {
            this.showTransfer = false;
          }
        }
      }
    });
    this.getPremuimFeesDetail();
    this.userType = sessionStorage.getItem(USER_TYPE);
    this.subscriptionId = this.userType === '5' ? '49bff817-3e86-4824-c1a5-08d864841dac' : 'c1ef0c02-8e9d-4a78-c1a3-08d864841dac';
  }

  getPremuimFeesDetail(): void {
    this.kycService.getPremuimFeesDetail().subscribe(
      (res) => {
        if (res) {
          this.premuimFeesDetails = res;
          this.getCashaaAddress();
        }
      },
      (err) => {
        this.toastrService.error(JSON.parse(err.error).Message);
        this.loader = false;
      }
    );
  }

  copyAddress(): void {
    if (this.accountDetailsCryptoData.address) {
      this.copied = true;
      navigator.clipboard.writeText(this.accountDetailsCryptoData.address);
      this.toastrService.toastrConfig.preventDuplicates = true;
      this.toastrService.success("Text copied to clipboard");
      setTimeout(() => (this.copied = false), 300);
    }
  }

  copyMemoAddress(): void {
    if (this.accountDetailsCryptoData.memo) {
      this.copiedMemo = true;
      navigator.clipboard.writeText(this.accountDetailsCryptoData.memo);
      this.toastrService.toastrConfig.preventDuplicates = true;
      this.toastrService.success("Text copied to clipboard");
      setTimeout(() => (this.copiedMemo = false), 300);
    }
  }


  SendVerificationmail(id: string): void {
    this.loader = true;
    const data = new DirectorVerification(id);
    this.kycService.sendVerificationLink(data).subscribe((list) => {
      this.loader = false;
    });
  }
  getCashaaAddress(): void {
    this.loader = true;
    this.walletService.getCryptoAccountDetails("CAS").subscribe(
      (res) => {
        if (res) {
          this.accountDetailsCryptoData = res;
          this.getUserBalance();
        }
      },
      (err) => {
        this.toastrService.error(JSON.parse(err.error).Message);
        this.loader = false;
      }
    );
  }
  /**
   * Get logged user CAS balance
   */
  getUserBalance(): void {
    // tslint:disable-next-line: deprecation
    this.userService.getUserBalance().subscribe((balanceData) => {
      if (balanceData) {
        // this.balanceData = balanceData;
        this.balanceData = balanceData.filter(
          (x) => x.currencyType === "CAS"
        )[0];
        this.balanceDataFiat = balanceData.filter(
          (x) => x.currencyType ===  this.premuimFeesDetails.fiatFeeSymbol
        )[0];
        if (this.balanceData.available >= this.premuimFeesDetails.feeInCAS) {
          this.isCasBalSufficent = true;
        }

        if (this.balanceDataFiat.available >= this.premuimFeesDetails.feeInEUR) {
          this.isFiatBalSufficent = true;
        }
        // alert(this.balanceData[0].currencyType);
      }
      this.loader = false;
    });
  }
  payfee(): void {
    this.loader = true;
    let isFeesInCAS = this.form.get('feesType').value === 'cas';
    this.kycService.payPremiumFees(new UpgradePayment(!isFeesInCAS, isFeesInCAS, this.premuimFeesDetails.id)).subscribe((data) => {
      if (data.statusCode === 200) {
        this.loader = false;
        if (this.userType === '5') {
          sessionStorage.setItem(USER_TYPE, '6');
        }
        this.toastrService.success(FEE_SUCCESS_MESG);
        this.router.navigateByUrl(APP_ROUTES.ACTIVITY_BASE);
      }
    }, (err) => {
      this.loader = false;
      if (err.error.message) {
        this.toastrService.error(err.error.message);
      } else {
        this.toastrService.error(UNABLE_TO_PERFORM_ERROR);
      }
    });
  }

  helpDeskRedirect(): void {
    this.modalService.openTicketCreateModal();
  }

  isTickCheck(event): void {
    if (event.checked) {
      this.isTick = true;
    } else {
      this.isTick = false;
    }
  }

  goToCASCurrency(): void {
    this.router.navigateByUrl("/wallet?c=CAS#CAS");
  }

  isDisable(): boolean {
    if (this.form.get('feesType').value === 'cas' && !this.isCasBalSufficent) {
      return true;
    }

    if (this.form.get('feesType').value === 'fiat' && !this.isFiatBalSufficent) {
      return true;
    }

    return false;
  }

  getCurrencySymbol(): string {
    if (this.form.get('feesType').value !== '') {
      return this.form.get('feesType').value === 'cas' ? this.premuimFeesDetails.casFeeSymbol : this.premuimFeesDetails.fiatFeeSymbol;
    } else {
      return null;
    }
  }

  onSubmit() {
    this.continue.next();
  }
}

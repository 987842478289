import { IFormSelect } from "./../../core/models/form-select";
import { KycService } from "./../services/kyc.service";
import {
  CreateBusinessKYC,
  ICreateBusinessKYC,
} from "./../../core/models/create-business-kyc";
import { ValidatorUtility } from "./../../core/utility/validator.utility";
import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { IBusinessKycDetails } from "src/app/core/models/business-account/business-key-detail-list";
import { IMyOptions } from "ng-uikit-pro-standard";
import { ToastrService } from "ngx-toastr";
import { UNABLE_TO_PERFORM_ERROR } from "src/app/app.constants";

@Component({
  selector: "cashaa-kyc-business-information",
  templateUrl: "./kyc-business-information.component.html",
  styleUrls: ["./kyc-business-information.component.scss"],
})
export class KycBusinessInformationComponent implements OnInit {
  form: FormGroup;
  IncroporationDate: string;
  @Output()
  continue = new EventEmitter<ICreateBusinessKYC>();
  industryCodeType?: IFormSelect[];
  companyType?: IFormSelect[];
  companyCode?: IFormSelect[];
  companyDetails: IBusinessKycDetails;
  loader: boolean = false;
  myDatePickerOptions: IMyOptions = {
    minYear: 1900,
    maxYear: 2099,
  };
  constructor(
    private fb: FormBuilder,
    private kycService: KycService,
    private toastrService: ToastrService
  ) {
    this.createForm();
  }

  ngOnInit(): void {
    this.getIndistryCode();
    this.getCompanyCode();
    this.getCompanyDetails();
  }

  /**
   * Create new form
   */
  createForm(): void {
    this.form = this.fb.group({
      // accountType: ["", [ValidatorUtility.Required]],
      legalBusinessName: ["", [ValidatorUtility.Required]],
      businessEmail: ["", [ValidatorUtility.Required]],
      businessPhone: ["", [ValidatorUtility.Required, ValidatorUtility.mobilenumber]],
      industryCode: ["", [ValidatorUtility.Required]],
      businessType: ["", [ValidatorUtility.Required]],
      incorporationDate: ["", [ValidatorUtility.Required]],
      registration: ["", [ValidatorUtility.Required]],
      isRegulated: ["", ""],
      tradingAddressLine1: ["", [ValidatorUtility.Required]],
      tradingAddressCountryCode: [
        "",
        [ValidatorUtility.Required, Validators.maxLength(3)],
      ],
      tradingAddressPostCode: ["", [ValidatorUtility.Required, Validators.maxLength(13), Validators.minLength(2)]],
      tradingAddressCity: ["", [ValidatorUtility.Required]],
      registeredAddressLine1: ["", [ValidatorUtility.Required]],
      registeredAddressCountryCode: ["", [ValidatorUtility.Required, Validators.maxLength(3)]],
      registeredAddressPostCode: ["", [ValidatorUtility.Required, Validators.maxLength(13), Validators.minLength(2)]],
      registeredAddressCity: ["", [ValidatorUtility.Required]],
      stakeholdersList: this.fb.array([]),
    });
  }

  /**
   * Get Profile detail
   */
  getCompanyDetails(): void {
    this.loader = true;
    this.kycService.getBusinessKycDetails().subscribe(
      (res) => {
        this.companyDetails = res;
        if (this.companyDetails) {
          this.form.controls["legalBusinessName"].setValue(
            res.legalBusinessName
          );
          this.form.controls["businessEmail"].setValue(res.businessEmail);
          this.form.controls["businessPhone"].setValue(res.businessPhone);
          this.form.controls["industryCode"].setValue(res.industryCode);

          this.form.controls["incorporationDate"].setValue(
            res.incorporationDate
          );
          this.form.controls["businessType"].setValue(res.businessType);
          this.form.controls["registration"].setValue(res.registrationNumber);
          this.form.controls["tradingAddressLine1"].setValue(
            res.tradingAddressLine1
          );
          this.form.controls["tradingAddressCountryCode"].setValue(
            res.tradingAddressCountryCode
          );
          this.form.controls["tradingAddressPostCode"].setValue(
            res.tradingAddressPostCode
          );
          this.form.controls["tradingAddressCity"].setValue(
            res.tradingAddressCity
          );
          this.form.controls["isRegulated"].setValue(
            res.isRegulated.toString()
          );
          this.form.controls["registeredAddressLine1"].setValue(
            res.registeredAddressLine1
          );
          this.form.controls["registeredAddressCountryCode"].setValue(
            res.registeredAddressCountryCode
          );
          this.form.controls["registeredAddressPostCode"].setValue(
            res.registeredAddressPostCode
          );
          this.form.controls["registeredAddressCity"].setValue(
            res.registeredAddressCity
          );
        }
        this.loader = false;
      },
      (err) => {
        this.loader = false;
        if (err.error.message) {
          this.toastrService.error(err.error.message);
        } else {
          this.toastrService.error(UNABLE_TO_PERFORM_ERROR);
        }
      }
    );
  }
  setFormControl(date: any) {
    this.form.controls["incorporationDate"].setValue(date.actualDateFormatted);
  }

  /**
   * Load Industry Code
   */
  // tslint:disable-next-line: typedef
  getIndistryCode() {
    this.kycService.getIndustryCode().subscribe((resData) => {
      this.industryCodeType = resData.map((d) => {
        return {
          value: d.key,
          label: d.key,
        };
      });
    });
  }
  // load Business Type
  getCompanyCode() {
    this.kycService.getCompanyCode().subscribe((resData) => {
      this.companyType = resData.map((d) => {
        return {
          value: d.key,
          label: d.key,
        };
      });
    });
  }

  onFormSubmit(): void {
    const data = this.form.value;

    const createBusinessKycData = new CreateBusinessKYC(
      data.legalBusinessName,
      data.businessEmail,
      data.businessPhone,
      data.industryCode,
      data.businessType,
      data.incorporationDate,
      data.registration,
      data.isRegulated,
      data.tradingAddressLine1,
      data.tradingAddressCountryCode,
      data.tradingAddressPostCode,
      data.tradingAddressCity,
      data.registeredAddressLine1,
      data.registeredAddressCountryCode,
      data.registeredAddressPostCode,
      data.registeredAddressCity,
      data.stakeholdersList
    );

    this.continue.emit(createBusinessKycData);
  }
}

<section
  class="wrapper d-flex justify-content-center align-items-center position-relative bg-white px=5"
>
  <img
    class="position-absolute logo text-danger"
    src="../../../assets/media/logo-dark.svg"
  />

  <div class="position-absolute close text-primary mr-5">
    <mdb-icon fas icon="user"></mdb-icon>
  </div>
  <div
    class="position-absolute close text-primary"
    routerLink="/account/signin"
  >
    <mdb-icon fas icon="times"></mdb-icon>
  </div>
  <div class="container-fluid bg-white">
    <!-- Content here -->
    <div class="row">
      <div class="col-md-12 p-3 text-center">
        <h3>Which currency would you like to receive & hold money in?</h3>
        <div class="p-3">
          <cashaa-form-select></cashaa-form-select>
        </div>
        <p class="p3">
          You'll be able to hold more than 50 currencies Local bank details are
          available for receiving money
          <br />
          in GBP,EUR,USD,PLN,AUD or NZD with more coming soon.
        </p>

        <!-- Show this div in case of Screen : Accountes - selected -->

        <!-- <p class="p3">
              Your USD balance comes with a wire routing number, ACH routhing number, SWIFT code and
              <br>
              account number. You'll be able to share these with people who want to send USD to you. They will
              <br>
              avoid hidden fees and bad exchange rates.

          </p> -->

        <div class="p3">
          <button
            class="btn btn-primary"
            (click)="OnOpenAccountClick()"
            disabled
          >
            Open an account
          </button>
        </div>
      </div>
    </div>
  </div>
</section>

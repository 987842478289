import { Component, OnInit, Input } from "@angular/core";
import { FormGroup, FormControl } from "@angular/forms";
import { IFormSelect } from "src/app/core/models/form-select";
import { DomainService } from "src/app/core/services/domain/domain.service";
import { UserType } from "src/app/core/enums/userType";

@Component({
  selector: "cashaa-form-select-accounttype",
  templateUrl: "./form-select-accounttype.component.html",
  styleUrls: ["./form-select-accounttype.component.scss"],
})
export class FormSelectAccounttypeComponent implements OnInit {
  /** Placeholder for input */
  //personalplan,businessplan,all
  @Input() source: string;

  /** Placeholder for input */
  @Input() placeholder = "Country of residence";

  /** input form group */
  @Input() form: FormGroup;

  /** form control */
  @Input() control: FormControl;

  /** name of control */
  @Input() name: string;

  @Input() label = "";

  /** form select values */
  options: IFormSelect[] = [];

  constructor(private domainService: DomainService) {}

  ngOnInit(): void {
    this.getAccountList();
  }

  /**
   * Get list of available subsciption
   */
  getAccountList(): void {
    const userType =
      this.source === "personalplan"
        ? UserType.Personal
        : this.source === "businessplan"
        ? UserType.Business
        : UserType.Admin;

    this.domainService.getSubscription(userType).subscribe((list) => {
      this.options = list.subscriptionPlans.map((subscription) => {
        return {
          value: subscription.id,
          label: subscription.name,
        };
      });
      this.addOtherOption();
    });
  }

  /**
   * Add other options in the list
   */
  addOtherOption(): void {
    this.options.push({
      value: "-1",
      label: "Other",
    });
  }
}

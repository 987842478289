<mdb-card>
  <mdb-card-body
    class="p-4 p-sm-5 wrapper-box position-relative d-flex justify-content-center align-items-center sm-width-phone-370">
    <h3 *ngIf="errorMessage">{{ errorMessage }}</h3>
    <h3 *ngIf="isInProgress">Whitelisting device...</h3>

    <!-- Progress bar -->
    <mdb-progress-bar *ngIf="isInProgress" class="progress primary-color-dark position-absolute" mode="indeterminate">
    </mdb-progress-bar>
  </mdb-card-body>
</mdb-card>
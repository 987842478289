<mdb-card class="sm-signin-step2-width-phone">
  <mdb-card-body (paste)="onCodePaste($event)" class="p-4 p-sm-5 wrapper-box position-relative">
    <div class="mb-4 text-center">
      <img src="../../../assets/media/2FA-security-mobile.png" />
    </div>
    <h4 class="text-center text-primary font-weight-bold mb-4">
      Two - Factor Authentication
    </h4>
    <div class="d-flex align-items-center mb-4">
      <img class="locker-icon" src="../../../assets/media/locker.svg" />
      <p class="small mb-0 ml-3">
        Input the 6-digit code in your Authentication app
      </p>
    </div>
    <p class="mb-4">Authentication Code</p>
    <div class="row google-code mb-4">
      <div class="col-2">
        <input type="number" [disabled]="isInProgress" maxlength="1" [(ngModel)]="code[0]"
          (input)="checkIfCodeIsFilled(0)" autofocus #inputs (change)="removeError()" />
      </div>
      <div class="col-2">
        <input type="number" [disabled]="isInProgress" maxlength="1" [(ngModel)]="code[1]"
          (input)="checkIfCodeIsFilled(1)" #inputs (change)="removeError()" (keyup)="removeBack($event, 0)"/>
      </div>
      <div class="col-2">
        <input type="number" [disabled]="isInProgress" maxlength="1" [(ngModel)]="code[2]"
          (input)="checkIfCodeIsFilled(2)" #inputs (change)="removeError()" (keyup)="removeBack($event, 1)"/>
      </div>
      <div class="col-2">
        <input type="number" [disabled]="isInProgress" maxlength="1" [(ngModel)]="code[3]"
          (input)="checkIfCodeIsFilled(3)" #inputs (change)="removeError()" (keyup)="removeBack($event, 2)"/>
      </div>
      <div class="col-2">
        <input type="number" [disabled]="isInProgress" maxlength="1" [(ngModel)]="code[4]"
          (input)="checkIfCodeIsFilled(4)" #inputs (change)="removeError()" (keyup)="removeBack($event, 3)"/>
      </div>
      <div class="col-2">
        <input type="number" [disabled]="isInProgress" maxlength="1" [(ngModel)]="code[5]"
          (input)="checkIfCodeIsFilled(5)" #inputs (change)="removeError()" (keyup)="removeBack($event, 4)"/>
      </div>
    </div>
    <div class="position-relative" *ngIf="errorMessage">
      <mdb-error class="mt-2 w-100 text-center">{{ errorMessage }}</mdb-error>
    </div>
    <!-- Progress bar -->
    <mdb-progress-bar class="sm-signup-err-font-mobile" *ngIf="isInProgress && !errorMessage"
      class="progress primary-color-dark position-absolute" mode="indeterminate">
    </mdb-progress-bar>
  </mdb-card-body>
</mdb-card>
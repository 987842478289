import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FileUpload, IFile } from 'src/app/core/models/Lerex/enum-load';
import { ValidatorUtility } from 'src/app/core/utility/validator.utility';
const addressdocumentTypes = require("../../../assets/json/addressProofDocumentType.json");

@Component({
  selector: 'cashaa-kyc-personal-address-proof',
  templateUrl: './kyc-personal-address-proof.component.html',
  styleUrls: ['./kyc-personal-address-proof.component.scss']
})
export class KycPersonalAddressProofComponent implements OnInit {

  addressProofOptions;
  addressfile: IFile = new FileUpload(null);
  form: FormGroup;
  count: number = 0;
  @Output() continue = new EventEmitter<any>();
  constructor(private fb: FormBuilder) { }

  ngOnInit(): void {
    this.addressProofOptions = addressdocumentTypes;
    this.addressfile.docType = "AddressFile";
    this.form = this.fb.group({
      addressdocumentType: ["", ValidatorUtility.Required]
    });
  }

  onIdentitySubmit(): void {
    this.continue.next(this.form.value.addressdocumentType);
  }

  status(event) {
    ++this.count;
  }

}

import { ICurrency } from "src/app/core/models/currency";
import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { FormGroup, FormControl } from "@angular/forms";
import { IFormSelect } from "src/app/core/models/form-select";
import { DomainService } from "src/app/core/services/domain/domain.service";
import { CurrencyType } from "src/app/core/enums/currencyType";
import { Observable, Subject } from "rxjs";
import { startWith, map } from "rxjs/operators";
import { ActivatedRoute } from "@angular/router";
@Component({
  selector: "cashaa-form-select-currency",
  templateUrl: "./form-select-currency.component.html",
  styleUrls: ["./form-select-currency.component.scss"],
})
export class FormSelectCurrencyComponent implements OnInit {
  /** Placeholder for sourcetype fiatCurrency,cryptoCurrency,allCurrency */
  @Input() source: string;

  /** Placeholder for input */
  @Input() placeholder = "Select currency";

  /** input form group */
  @Input() form: FormGroup;

  /** form control */
  @Input() control: FormControl;

  /** name of control */
  @Input() name: string;

  /** Form select values */
  @Input() options: any[] = [];

  @Input() label = "";

  @Input() setCurrency;
  @Input() isFDActive;

  @Input() set initialValue(data) {
    if (data) {
      this.getCurrencyList(data);
    }
  }

  @Output() changed = new EventEmitter<ICurrency>();

  currencies: ICurrency[] = [];

  searchText = new Subject();

  filteredOptions: Observable<any[]>;

  optionIcon: string;

  constructor(private domainService: DomainService, private activateRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this.getCurrencyList();
    this.activateRoute.queryParams.subscribe((params) => {
      this.setCurrency = params["cur"];
    });
  }

  filter(value: string): any[] {
    const filterValue = value.toLowerCase();
    return this.options.filter((option: any) =>
      option.label.toLowerCase().includes(filterValue)
    );
  }

  /**
   * Get Currency list
   */
  getCurrencyList(data?): void {
    const currencyType =
      this.source === "allCurrency"
        ? CurrencyType.all
        : this.source === "fiatCurrency"
        ? CurrencyType.Fiat
        : CurrencyType.Crypto;

    this.domainService.getCurrenciesList(currencyType).subscribe((list) => {
      if(this.isFDActive) {
        list.currencies = list.currencies.filter(item =>  item.isFDActive === true );
      }
      this.currencies = list.currencies;
      if (currencyType === CurrencyType.Fiat) {
        this.domainService.emitFiatCurrency(this.currencies);
      }
      this.options = list.currencies.map((currencies) => {
        return {
          value: currencies.id,
          label: currencies.symbol,
          icon: `../../../../assets/icons/${currencies.symbol}.png`,
          symbol : currencies.symbol
        };
      });

      this.filteredOptions = this.searchText.pipe(
        startWith(""),
        map((value: string) => {
          return this.filter(value);
        })
      );
      console.log(this.searchText);
      if (this.setCurrency || data) {
        let cur;
        if (this.setCurrency) {
          cur = this.options.filter((c) => c.label === this.setCurrency);
        } else {
          cur = this.options.filter((c) => c.value === data);
        }
        setTimeout(() => {
          if (this.options.length > 0) {
            this.form.controls[this.name].patchValue(cur[0], {
              emitEvent: false,
            });
            this.control.setValue(cur[0]);
            this.onCurrencyChange(cur[0]);
          }
        });
      }
    });
  }

  onCurrencyChange(value: IFormSelect): void {
    console.log(value);
    const cur = this.options.filter((c) => c.value === value.value);
    if (cur.length > 0) {
      this.changed.emit(cur[0]);
    }
    this.selectedImg(
      this.options.find((option: IFormSelect) => {
        return option.value === value.value;
      })
    );
  }

  selectedImg(v: IFormSelect) {
    if (v.icon) {
      this.optionIcon = v.icon;
    }
  }
}

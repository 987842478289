import { data } from "jquery";
import { CreateKYC } from "src/app/core/models/create-kyc";
import { ValidatorUtility } from "./../../core/utility/validator.utility";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import { IMyOptions } from "ng-uikit-pro-standard";

@Component({
  selector: "cashaa-kyc-personal-information",
  templateUrl: "./kyc-personal-information.component.html",
  styleUrls: ["./kyc-personal-information.component.scss"],
})
export class KycPersonalInformationComponent implements OnInit {
  @Input()
  data: CreateKYC;
  @Input()
  isReadOnly: boolean;

  form: FormGroup;

  _formValue = CreateKYC;

  /** on continue click, emit event */
  @Output() continue = new EventEmitter<any>();

  myDatePickerOptions: IMyOptions = {
    minYear: 1900,
    maxYear: 2099,
    editableDateField: false,
  };

  constructor(private fb: FormBuilder) {
    this.createForm();
  }

  ngOnInit(): void {
    if (this.data) {
      this.updateForm();
    }
  }

  createForm(): void {
    this.form = this.fb.group({
      firstName: ["", [ValidatorUtility.Required]],
      lastName: ["" , [ValidatorUtility.Required]],
      dateOfBirth: ["", [ValidatorUtility.Required]],
      phone: [
        "",
        [
          ValidatorUtility.Required,
          ValidatorUtility.OnlyNumberRequired,
          ValidatorUtility.mobilenumber,
        ],
      ],
    });
  }

  updateForm(): void {
    if (this.isReadOnly) {
      this.form.disable();
    }
    this.form.controls.firstName.setValue(this.data.firstName);
    this.form.controls.lastName.setValue(this.data.lastName);
    this.form.controls.phone.setValue(this.data.phone);
    this.form.controls.dateOfBirth.setValue(this.data.dateOfBirth);
  }

  onFromDateChange(date: any): void {
    this.form.controls.dateOfBirth.setValue(date.actualDateFormatted);
  }

  onContinue(): void {
    const data = this.form.value as CreateKYC;
    this.continue.emit(data);
  }
}

<form [formGroup]="form" (ngSubmit)="onSubmit(form.value)" class="my-4">
  <div class="row mb-4">
    <div class="col-12 col-sm-6">
      <cashaa-form-input name="addressLine1" type="text" [form]="form" [control]="form.controls.addressLine1"
        placeholder="Enter Address Line 1" label="Address Line 1">
      </cashaa-form-input>
    </div>
    <div class="col-12 col-sm-6 mobile-mt-4">
      <cashaa-form-input name="addressLine2" type="text" [form]="form" [control]="form.controls.addressLine2"
        placeholder="Enter Address Line 2" label="Address Line 2">
      </cashaa-form-input>
    </div>
  </div>
  <div class="row mb-4">
    <div class="col-12 col-sm-6">
      <cashaa-form-input name="addressLine3" type="text" [form]="form" [control]="form.controls.addressLine3"
        placeholder="Enter Address Line 3" label="Address Line 3">
      </cashaa-form-input>
    </div>
    <div class="col-12 col-sm-6 mobile-mt-4">
      <cashaa-form-input name="postalCode" type="text" [form]="form" [control]="form.controls.postalCode"
        placeholder="Enter Zip /Postal Code" label="Zip /Postal Code">
      </cashaa-form-input>
    </div>
  </div>
  <div class="row mb-4">
    <div class="col-12 col-sm-6">
      <cashaa-form-input name="city" type="text" [form]="form" [control]="form.controls.city" placeholder="Enter City"
        label="City">
      </cashaa-form-input>
    </div>
    <div class="col-12 col-sm-6 mobile-mt-4">
      <cashaa-form-input name="province" type="text" [form]="form" [control]="form.controls.province"
        placeholder="Enter State" label="State">
      </cashaa-form-input>
    </div>
  </div>
  <div class="row mb-4">
    <div class="col-12 col-sm-6">
      <cashaa-form-select-country [initialValue]="initialValueCountry" name="country" type="country" [form]="form"
        [control]="form.controls.country" placeholder="Select Country" label="Country"
        (changed)="onCountryChange($event)"></cashaa-form-select-country>
    </div>
  </div>
  <button mdbBtn block="true" type="submit"
    class="w-25 rounded-sm position-relative btn btn-primary waves-effect waves-light btn-block" mdbWavesEffect
    [disabled]="form.invalid">
    {{isDataLoaded ? 'Next' : 'Save & Next'}}
  </button>
</form>
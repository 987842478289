import { MdbStepperComponent } from "ng-uikit-pro-standard";
import { Component, OnInit, ViewChild } from "@angular/core";
import { DepositService } from "../services/deposit.service";
import { NavigationService } from "src/app/core/services/navigation/navigation.service";
import { FiatService } from "src/app/fiat/services/fiat.service";
import { Recipient } from "src/app/core/models/fIat/recipients";
import { FiatTransactionType } from "src/app/core/enums/fiat-transaction-type";

@Component({
  selector: "cashaa-deposit-home",
  templateUrl: "./deposit-home.component.html",
  styleUrls: ["./deposit-home.component.scss"],
})
export class DepositHomeComponent implements OnInit {
  @ViewChild("stepper") stepper: MdbStepperComponent;

  completed = false;
  hideSenderStepper: boolean;
  recipientType: string;
  resetRecData: Recipient =  {id: "",name: "", currency: "", accountNumber: "", iban: "",
  sortCode: "" , fiatAddressDetailsId: "", isrecipientSkiped: false,typeName: "",recipientType: "" 
, type:  FiatTransactionType.SCAN};
  constructor(private depositService: DepositService,
    private navigationService: NavigationService, private fiatService: FiatService) { }

  ngOnInit(): void { }

  continueAmount(event): void {
    this.recipientType = event;
    this.resetRecData['recipientType'] = this.recipientType;
    this.fiatService.emitFiatRecipientdData(this.resetRecData);
    this.stepper.next();
  }

  continue(): void {
    this.stepper.next();
  }
  hideSender(event) {
    if(event === 'true'){
      this.hideSenderStepper = true;
    } else {
      this.hideSenderStepper = false;
    }
  }

  back(): void {
    this.stepper.previous();
  }

  reset(): void {
    if (this.completed) {
      this.depositService.emitDepositStatus(true);
    }
    this.resetRecData['recipientType'] = '';
    this.fiatService.emitFiatRecipientdData(this.resetRecData);
    this.completed = false;
    this.stepper.resetAll();
  }

  complete(): void {
    this.completed = true;
  }

  backRoute() {
    this.navigationService.back();
  }
}

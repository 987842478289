<section class="activity-box px-3">
  <div class="details">
    <mdb-accordion [multiple]="false" aria-multiselectable="false">
      <mdb-accordion-item class="no-collase ledger-list-item">
        <mdb-accordion-item-head class="px-2" mdbWavesEffect>
          <div class="d-flex flex-row px-2 align-items-center">
            <div class="p-2 circle">
              <img src="../../../assets/images/Exchanged.png"/>
            </div>
            <div class="status ml-2 transaction-detail">
              To
              <span class="text-primary-dark">your {{ getCurrencyAccName(ledger?.convertTocurrency) }}
                balance</span>
                            <br />
                            <span class="text-gray">Exchanged</span>
                        </div>
                        <div class="amount ml-auto mr-4">
                            <b>{{ ledger?.convertToamount |number: "0.0-4" }}
                {{ getCurrencyName(ledger?.convertTocurrency) }}</b><br />
                            <span class="text-gray">
                {{ ledger?.convertFromamount |number: "0.0-4" }}
                {{ getCurrencyName(ledger?.convertfromcurrency) }}
              </span>
                        </div>
                    </div>
                </mdb-accordion-item-head>
                <mdb-accordion-item-body class="px-4 position-relative">
                    <div class="container px-4 pt-2">
                        <div class="row">
                            <div class="col-sm">
                                Moved From <br />
                                <b class="text-primary">Your {{ getCurrencyName(ledger?.convertfromcurrency) }} balance</b>
                            </div>

                            <div class="col-sm">
                                Amount exchanged <br />
                                <b class="text-primary">{{ ledger?.convertFromamount }} {{ getCurrencyName(ledger?.convertfromcurrency)
                  }}</b>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col-sm">
                                Exchanged to<br />
                                <b class="text-primary">{{ ledger?.convertToamount | number: "0.0-4" }} {{
                  getCurrencyName(ledger?.convertTocurrency)
                  }}</b>
                            </div>

                            <div class="col-sm">
                                Completed <br />
                                <b class="text-primary">{{ ledger?.created | date:'longDate'}}</b>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col-sm">
                                Exchange Rate <br />
                                <b class="text-primary">{{ ledger?.exchangeRate }} {{
                  getCurrencyName(ledger?.convertfromcurrency) }}/
                  {{ getCurrencyName(ledger?.convertTocurrency) }}</b>
                            </div>
                            <div class="col-sm">
                                Fee <br />
                                <b class="text-primary">{{ ledger?.convertfee }} {{ getCurrencyName(ledger?.feeCurrency) }}</b>

                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col-sm">
                                Transaction Number <br />
                                <b class="text-primary">{{ ledger?.transactionId }}</b>
                            </div>
                        </div>
                    </div>
                </mdb-accordion-item-body>
            </mdb-accordion-item>
        </mdb-accordion>
    </div>
</section>
<div class="wrapper-box p-md-5 p-3 rounded-lg bg-white animated fadeInLeft position-relative sm-width-phone-370">
  <h4 class="text-center text-primary font-weight-bold">Welcome Back!</h4>
  <p class="text-center font-weight-normal">
    New to Cashaa? <a routerLink="/account/signup">Signup</a>
    <!-- routerLink="/account/signup" -->
  </p>
  <form [formGroup]="form" class="my-4">
    <div class="position-relative mb-4">
      <cashaa-form-input name="email" type="email" [form]="form" [control]="form.controls.email"
        placeholder="Enter email address"></cashaa-form-input>
    </div>
    <div class="position-relative ">
      <cashaa-form-input name="password" type="password" [form]="form" [control]="form.controls.password"
        placeholder="Enter password"></cashaa-form-input>
    </div>
  </form>
  <div *ngIf="showOtpBox" class="mb-4">
    <p class="mb-2">Verification Code
      <button type="button" class="sm-tooltip" mdbTooltip="OTP has been sent to your Email." placement="top">
        <mdb-icon class="pl-2 info-circle" title="" fas icon="info-circle"></mdb-icon>
      </button>
    </p>
    <div class="row google-code" (paste)="onCodePaste($event)">
      <div class="col-1 sm-code">
        <input type="text" maxlength="1" [(ngModel)]="code[0]" (input)="checkIfCodeIsFilled(0)" autofocus #inputs
          (change)="removeError()" />
      </div>
      <div class="col-1 sm-code">
        <input type="text" maxlength="1" [(ngModel)]="code[1]" (input)="checkIfCodeIsFilled(1)" #inputs
          (change)="removeError()" (keyup)="removeBack($event, 0)" />
      </div>
      <div class="col-1 sm-code">
        <input type="text" maxlength="1" [(ngModel)]="code[2]" (input)="checkIfCodeIsFilled(2)" #inputs
          (change)="removeError()" (keyup)="removeBack($event, 1)" />
      </div>
      <div class="col-1 sm-code">
        <input type="text" maxlength="1" [(ngModel)]="code[3]" (input)="checkIfCodeIsFilled(3)" #inputs
          (change)="removeError()" (keyup)="removeBack($event, 2)" />
      </div>
      <div class="col-1 sm-code">
        <input type="text" maxlength="1" [(ngModel)]="code[4]" (input)="checkIfCodeIsFilled(4)" #inputs
          (change)="removeError()" (keyup)="removeBack($event, 3)" />
      </div>
      <div class="col-1 ">
        <input type="text" maxlength="1" [(ngModel)]="code[5]" (input)="checkIfCodeIsFilled(5)" #inputs
          (change)="removeError()" (keyup)="removeBack($event, 4)" />
      </div>
    </div>
  </div>
  <mdb-error style="display: block;position: relative;" class="mt-2 mb-2" *ngIf="errorMessage">{{ errorMessage }}
  </mdb-error>
  <button mdbBtn [disabled]="form.invalid || isSigningIn || (showOtpBox && whitelistCode.length<6)"
    (click)="onFormSubmit()" block="true" type="button"
    class="rounded-sm position-relative btn btn-primary waves-effect waves-light btn-block" mdbWavesEffect>
    <i class="fa-lock fas position-absolute"></i>Log in
  </button>
  <p class="text-right font-weight-normal mb-2">
    <a routerLink="/account/forget-password">Forgot password?</a>
  </p>
  <!-- <p class="font-weight-normal mb-2">
    Or login with
  </p>
  <div class="mb-4">
    <cashaa-account-social-signin></cashaa-account-social-signin>
  </div> -->
  <mdb-checkbox (change)="onSelectRememberMe()">
    <p class="font-weight-normal">Remember this device</p>
  </mdb-checkbox>
  <p class="mt-5 small">
    By continuing you accept our <a target="_blank" href="https://cashaa.com/term-condition">Terms of Use</a> and
    <a target="_blank" href="https://cashaa.com/privacy-policy">Privacy Policy</a>
  </p>

  <!-- Progress bar -->
  <mdb-progress-bar *ngIf="isSigningIn" class="progress primary-color-dark position-absolute" mode="indeterminate">
  </mdb-progress-bar>
</div>
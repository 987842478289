<cashaa-sidenav>
    <section class="mh-100">
        <div class="bg-white p-4 sm-media-padding">
            <div class="d-flex sm-phone-display-none mr-3" style="height: 47px;">
                <button routerLink="/wallet" mdbBtn type="button" class="btn-sm app-primary-btn">Back</button>
            </div>
        </div>
    </section>
    <h5 class="heading px-4 pt-4"> Loan Details </h5>
    <div class="table_btn_section" [hidden]="loading">
        <table class="table table-bordered border-primary align-middle">
            <thead>
                <tr class="">
                    <th scope="col">
                        Availed On
                    </th>
                    <th scope="col">
                        Loan Id
                    </th>
                    <th scope="col">
                        Amount
                    </th>
                    <th scope="col">
                        Outstanding Loan
                    </th>
                    <th scope="col">
                        Interest Rate
                    </th>
                    <th scope="col">
                        Status
                    </th>
                    <th scope="col">
                        Action
                    </th>
                </tr>
            </thead>
            <tbody class="">
                <tr class="cursor-pointer table-row" *ngFor="let item of creditLoanList; let i = index">
                    <td>{{item.availedOn | date: 'dd MMMM, yyyy'}}</td>
                    <td>{{item?.loanNumber}}
                        <i mdbTooltip="Copy loan id" placement="top" class="fas fa-clone"
                        [ngClass]="copied ? 'text-primary' : ''" (click)="copyLoanId(item);$event.stopPropagation()"></i>
                    </td>
                    <td>{{item.amount}}&nbsp;{{item.currencySymbol}}</td>
                    <td>{{item.outStandingLoan}}&nbsp;{{item.currencySymbol}}</td>
                    <td>{{item.rate}}%</td>
                    <td *ngIf="item.status === 1; else closed">Open</td>
                    <ng-template #closed>
                        <td>Closed</td>
                    </ng-template>
                    <td>
                        <button mdbBtn type="button" class="btn-sm app-primary-btn"
                            (click)="getInterestOnLoan(item.id)">Interest</button>
                        <button *ngIf="item.status === 1" mdbBtn type="button" class="btn-sm app-secondary-btn "
                            (click)="loanRepayment(item)">Repay</button>
                        <button mdbBtn type="button" class="btn-sm app-tertiary-btn "
                            (click)="getLoanDetails(item)">Details</button>
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="all_btns">
            <!-- <button type="button" class="btn btn-primary intrest_btn" routerLink="/Loan-Interest">Intrest</button>
            <button type="button" class="btn btn-primary " routerLink="/Loan-Repayment">Repay</button> -->
            <!-- <button type="button" class="btn btn-primary" routerLink="/Loan-Details">Details</button> -->
        </div>
    </div>
    <div class="p-4 text-center" [hidden]="!loading">
        <mdb-spinner spinnerColor="blue"></mdb-spinner>
    </div>
</cashaa-sidenav>
import { Component, ElementRef, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BASE_PLAN, DELETE_REFERRAL_SUCCESS, DIAMOND_PLAN, GOLD_PLAN, RESEND_REFERRAL_SUCCESS, SILVER_PLAN, USER_INTEREST_MODE, USER_REFERRAL_CODE, USER_TYPE } from 'src/app/app.constants';
import { DeleteReferral, IReferralDetail, IReferralDetailResponse, IReferralInfo, ISendReferralInvitation } from 'src/app/core/models/referral/referral';
import { IUserPlan, IUserPlanDetail } from 'src/app/core/models/user-plan/user-detail';
import { UserService } from 'src/app/core/services/user/user.service';
import { ValidatorUtility } from 'src/app/core/utility/validator.utility';
import { DepositInterestService } from 'src/app/deposit-interest/service/deposit-interest.service';
import { CommonSavedData } from 'src/app/shared/services/commonSavedData.service';
import { StatementService } from 'src/app/statement/services/statement.service';
import { ReferralService } from '../services/referral.service';
import * as XLSX from "xlsx";
import { IDepositInterestPlan } from 'src/app/core/models/deposit-interest/deposit-interest';

@Component({
  selector: 'cashaa-referral',
  templateUrl: './referral.component.html',
  styleUrls: ['./referral.component.scss']
})
export class ReferralComponent implements OnInit {
  referralData: IReferralDetail[];
  referralInfo: IReferralInfo;
  loader: boolean = false;
  referralCode = sessionStorage.getItem(USER_REFERRAL_CODE);
  userCurrentPlan: IUserPlanDetail;
  loading: boolean = false;
  isBase: boolean = false;
  isSilver: boolean = false;
  isGold: boolean = false;
  isDiamond: boolean = false;
  basePlans: IUserPlan[] = [];
  silverPlans: IUserPlan[] = [];
  goldPlans: IUserPlan[] = [];
  daimondPlans: IUserPlan[] = [];
  allPlans = [];
  // interestList: IUserInterestList;
  borrowBenefit;
  highLTVRate = [];
  exchangeCashback = [];
  earnBenefits = [];
  additionalBenefits = [];
  basePlanDesc = [];
  silverPlanDesc = [];
  goldPlanDesc = [];
  diamondPlanDesc = [];
  maxCryptoRate;
  maxFiatRate;
  maxStableCoinsRate;
  maxCashaaRate;
  userType: string = sessionStorage.getItem(USER_TYPE);
  allplanCasBonus = [{ 'base': 0.5 }, { 'silver': 1 }, { 'gold': 2 }, { 'diamond': 3 }];
  symbol;
  basePlanNameId = '7c57b4ea-3cfc-4520-b2bd-db442be4d763';
  silverPlanNameId = '48654d6c-bd60-4054-85cc-a484ceeeef92';
  goldPlanNameId = 'c138f175-28d7-4ab4-99b6-8bb8b9f8251a';
  diamondPlanNameId = '016688f1-9caa-40ed-b77c-2095d5c85baa';
  cryptoCurrencyWithMaxRate = [];
  fiatCurrencyWithMaxRate = [];
  stableCurrencyWithMaxRate = [];
  referralCalculatorForm: FormGroup;
  userInterestMode: string = sessionStorage.getItem(USER_INTEREST_MODE);
  errorMessage: string;
  defaultSelectedInkind = true;
  isInProgress = false;
  interestPlanList: IDepositInterestPlan[] = [];
  rate: string;
  interestInUSD;
  referalInterestInUSD;
  fixedDepositData = [];
  environmentOrigin: string;
  activityType: number;
  fromDate: string;
  toDate: string;
  searchText: string;
  pageSize: number = 10;
  pageIndex: number = 1;
  totalPageSize: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  length: number;
  filterOptions = [
    {
      label: "Joined",
      value: "1",
    }, {
      label: "Pending",
      value: "0",
    }];
  planData: any = [{
    percent: '',
  }, {
    percent: ''
  }, {
    percent: ''
  }, {
    percent: ''
  }];
  viewDetails = true;
  radioOptionToShow = [];
  showMonthlyMessage = false;
  duration;
  interestType;
  constructor(private referralService: ReferralService, private toasterService: ToastrService,
    private userService: UserService, private fb: FormBuilder, private depositInterest: DepositInterestService,
    private router: Router, private commonDataService: CommonSavedData, private statementService: StatementService) { }

  ngOnInit(): void {
    // if(this.commonDataService.isPersonalUS()) {
    //   this.router.navigateByUrl('activity');
    // }
    this.environmentOrigin = this.commonDataService.getEnvironmentOrigin();
    this.referralCalculatorForm = this.fb.group({
      referralInvest: [
        "",
        [ValidatorUtility.Required]
      ],
      currency: ["", ValidatorUtility.Required],
      planName: ["", ValidatorUtility.Required],
      rateType: ["2", ValidatorUtility.Required]
      // interestType: ["", ValidatorUtility.Required]
    });
    this.loader = true;
    this.getReferralInterest();
    this.loadReferralList();
    this.getUserPlanDetails();
    this.getAllPlanInfo();
    this.referralService.getReferralInfo().subscribe((info: IReferralInfo) => {
      this.loader = false;
      this.referralInfo = info;
    });
    this.listenToInterestPlanChange();
  }

  isJoined(item: IReferralDetail): boolean {
    return item.status;
  }

  isPending(item: IReferralDetail): boolean {
    return !item.status;
  }

  onDeleteOrResend(item: IReferralDetail): void {
    item.isDeleteOrResend = true;
    if (item.status) {
      this.referralService.deleteReferral(new DeleteReferral(item.id)).subscribe((data) => {
        if (data.statusCode === 200) {
          item.isDeleteOrResend = false;
          this.toasterService.success(DELETE_REFERRAL_SUCCESS);
          this.loadReferralList();
        }
      })
    } else {
      let referralInvitation: ISendReferralInvitation = { toemail: [{ recipientemail: item.email }] }
      this.referralService.sendReferralInvitation(referralInvitation).subscribe((data) => {
        if (data.statusCode === 200) {
          item.isDeleteOrResend = false;
          this.toasterService.success(RESEND_REFERRAL_SUCCESS);
        }
      })
    }
  }

  loadReferralList(): void {
    this.loader = true;
    this.referralService.getReferralList(1, 10).subscribe((data: IReferralDetailResponse) => {
      this.loader = false;
      this.referralData = data.data;
      this.pageSize = data.pageSize;
      this.pageIndex = data.pageIndex;
      this.length = data.totalCount;
      this.totalPageSize = data.totalPageCount;
      this.hasPreviousPage = data.hasPreviousPage;
      this.hasNextPage = data.hasNextPage;
    });
  }

  copyReferralCode(data: string): void {
    // this.copied = true;
    navigator.clipboard.writeText(data);
    this.toasterService.toastrConfig.preventDuplicates = true;
    this.toasterService.success("Text copied to clipboard");
    // setTimeout(() => (this.copied = false), 300);
  }

  getUserPlanDetails() {
    this.userService.getUserPlanDetail().subscribe((data: IUserPlanDetail) => {
      this.userCurrentPlan = data;
      if (this.userCurrentPlan.name === BASE_PLAN) {
        this.isBase = true;
      } else if (this.userCurrentPlan.name === SILVER_PLAN) {
        this.isSilver = true;
      } else if (this.userCurrentPlan.name === GOLD_PLAN) {
        this.isGold = true;
      } else {
        this.isDiamond = true;
      }
      // this.loading = false;
    });
  }

  goToPlans() {
    this.router.navigateByUrl("/plans")
  }
  getAllPlanInfo() {
    this.userService.getAllPlansDetails().subscribe((data: IUserPlan[]) => {
      this.symbol = data[0].symbol;
      this.allPlans = data;
      this.basePlans = data.filter(plans => plans.loyaltyPlanName === BASE_PLAN);
      this.silverPlans = data.filter(plans => plans.loyaltyPlanName === SILVER_PLAN);
      this.goldPlans = data.filter(plans => plans.loyaltyPlanName === GOLD_PLAN);
      this.daimondPlans = data.filter(plans => plans.loyaltyPlanName === DIAMOND_PLAN);
      const cryptodiamondPlan = this.allPlans.filter(ele => ele.currencyNature === 'Crypto' && ele.loyaltyPlanNameId === this.diamondPlanNameId);
      const cryptoMaxRate = cryptodiamondPlan.map(ele => ele.kindRate);
      let cryptoMax = Math.max(...cryptoMaxRate);
      let cryptoCurrWdMax;
      this.allPlans.filter(ele => {
        if (ele.currencyNature === 'Crypto' && ele.kindRate === cryptoMax) {
          cryptoCurrWdMax = ele;
        }
      });
      let allPlanofCurr = []
      this.allPlans.filter(ele => {
        if (cryptoCurrWdMax.currencyId === ele.currencyId) {
          allPlanofCurr.push(ele);
        }
      })
      allPlanofCurr.map(ele => {
        if (ele.loyaltyPlanNameId === this.basePlanNameId) {
          this.cryptoCurrencyWithMaxRate[0] = ele;
        }
        if (ele.loyaltyPlanNameId === this.silverPlanNameId) {
          this.cryptoCurrencyWithMaxRate[1] = ele;
        }
        if (ele.loyaltyPlanNameId === this.goldPlanNameId) {
          this.cryptoCurrencyWithMaxRate[2] = ele;
        }
        if (ele.loyaltyPlanNameId === this.diamondPlanNameId) {
          this.cryptoCurrencyWithMaxRate[3] = ele;
        }
      })
      const fiatDiamondPlan = this.allPlans.filter(ele => ele.currencyNature === 'Fiat' && ele.loyaltyPlanNameId === this.diamondPlanNameId);
      const fiatMaxRate = fiatDiamondPlan.map(ele => ele.kindRate);
      let fiatMax = Math.max(...fiatMaxRate);
      let fiatCurrWdMax;
      this.allPlans.filter(ele => {
        if (ele.currencyNature === 'Fiat' && ele.kindRate === fiatMax) {
          fiatCurrWdMax = ele;
        }
      });
      let allFiatPlanofCurr = []
      this.allPlans.filter(ele => {
        if (fiatCurrWdMax.currencyId === ele.currencyId) {
          allFiatPlanofCurr.push(ele);
        }
      })
      allFiatPlanofCurr.map(ele => {
        if (ele.loyaltyPlanNameId === this.basePlanNameId) {
          this.fiatCurrencyWithMaxRate[0] = ele;
        }
        if (ele.loyaltyPlanNameId === this.silverPlanNameId) {
          this.fiatCurrencyWithMaxRate[1] = ele;
        }
        if (ele.loyaltyPlanNameId === this.goldPlanNameId) {
          this.fiatCurrencyWithMaxRate[2] = ele;
        }
        if (ele.loyaltyPlanNameId === this.diamondPlanNameId) {
          this.fiatCurrencyWithMaxRate[3] = ele;
        }
      })

      const stableDiamondPlan = this.allPlans.filter(ele => ele.currencyNature === 'StableCoins' && ele.loyaltyPlanNameId === this.diamondPlanNameId);
      const stableMaxRate = stableDiamondPlan.map(ele => ele.kindRate);
      let stableMax = Math.max(...stableMaxRate);
      let stableCurrWdMax;
      this.allPlans.filter(ele => {
        if (ele.currencyNature === 'StableCoins' && ele.kindRate === stableMax) {
          stableCurrWdMax = ele;
        }
      });
      let allStablePlanofCurr = []
      this.allPlans.filter(ele => {
        if (stableCurrWdMax.currencyId === ele.currencyId) {
          allStablePlanofCurr.push(ele);
        }
      })
      allStablePlanofCurr.map(ele => {
        if (ele.loyaltyPlanNameId === this.basePlanNameId) {
          this.stableCurrencyWithMaxRate[0] = ele;
        }
        if (ele.loyaltyPlanNameId === this.silverPlanNameId) {
          this.stableCurrencyWithMaxRate[1] = ele;
        }
        if (ele.loyaltyPlanNameId === this.goldPlanNameId) {
          this.stableCurrencyWithMaxRate[2] = ele;
        }
        if (ele.loyaltyPlanNameId === this.diamondPlanNameId) {
          this.stableCurrencyWithMaxRate[3] = ele;
        }
      })
    });
  }
  onCurrencyTypeChange(event) {
    this.symbol = event.symbol;
    this.interestPlanList = [];
    this.radioOptionToShow = [];
    this.getFDByCurrencyId(event.value);
  }

  listenToInterestPlanChange(): void {
    this.referralCalculatorForm.controls.rateType.valueChanges.subscribe((value) => {
      this.loading = true;
      this.radioOptionToShow = [];
      this.interestPlanList = [];
      this.interestPlanList = this.fixedDepositData.filter(item => item.rateType === Number(this.referralCalculatorForm.get('rateType').value));;
      setTimeout(() => {
        this.loading = false;
      }, 1000)
    });
  }

  setMode() {
    this.defaultSelectedInkind = !this.defaultSelectedInkind;
    const rateType = this.referralCalculatorForm.get('rateType').value;
    if (rateType === '1') {
      this.referralCalculatorForm.get('rateType').setValue('2');
    } else {
      this.referralCalculatorForm.get('rateType').setValue('1');
    }
  }

  getFDByCurrencyId(currencyId) {
    this.fixedDepositData = [];
    this.depositInterest.getByDepositFDCurrency(currencyId).subscribe((res: any) => {
      this.fixedDepositData = res;
      let filterData = res.filter(item => item.rateType === Number(this.referralCalculatorForm.get('rateType').value));
      this.interestPlanList = filterData;
    })
  }

  selectedPlanTerm(selectedItem) {
    // let durationAndRate = this.rateAndDuration.filter(item => item.duration === duration);
    this.radioOptionToShow = [];
    this.rate = selectedItem.rate;
    this.duration = selectedItem.duration;
    this.showMonthlyMessage = false;
    this.interestType = selectedItem.interestOptType;
    this.referralCalculatorForm.patchValue({ planName: selectedItem.id });
  }

  exportStatement(): void {
    this.statementService.getStatementList(1, this.length, this.activityType, this.fromDate, this.toDate, this.searchText, this.symbol)
      .subscribe((data) => {
        /* table id is passed over here */
        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data.data);

        /* generate workbook and add the worksheet */
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Statement Detail");

        /* save to file */
        XLSX.writeFile(wb, "Statement.xlsx");
      });
  }

  filterData(event) {
    this.referralService.getReferralList(1, 10, event.value).subscribe((data: IReferralDetailResponse) => {
      this.loader = false;
      this.referralData = data.data;
      this.pageSize = data.pageSize;
      this.pageIndex = data.pageIndex;
      this.length = data.totalCount;
      this.totalPageSize = data.totalPageCount;
      this.hasPreviousPage = data.hasPreviousPage;
      this.hasNextPage = data.hasNextPage;
    });
  }
  getReferralInterest() {
    this.loader = true;
    this.referralService.getReferralInterest().subscribe((data: any) => {
      this.loader = false;
      this.planData = data;
    }, err => {
      this.toasterService.error(err.error.message);
      this.loader = false;
    });
  }

  calculateFixedDepositEarning() {
    if (this.referralCalculatorForm.valid && this.rate !== undefined) {
      this.referralService.getFixedDepositCalculationInterest(this.referralCalculatorForm.value, this.rate, this.symbol, this.interestType, this.duration).subscribe((res: any) => {
        this.interestInUSD = res.interestInUSD;
        this.referalInterestInUSD = res.referalInterestInUSD
      })
    }
  }
}

<section class="p-5 w-65 mx-auto fiat-flow-amount">
  <div class="p-4 text-center" [hidden]="!loading">
    <mdb-spinner spinnerColor="blue"></mdb-spinner>
  </div>
  <h5 class="font-weight-bold">How would you like to pay?</h5>
  <hr />
  <div class="d-flex align-items-center mb-4">
    <span class="font-weight-bolder">Net amount you will deposit</span>&nbsp;
    <h5 class="green-text font-weight-bold pt-2">
      <span *ngIf="symbol == 'GBP'">&pound;</span><span *ngIf="symbol == 'EUR'">&euro;</span> <span
        *ngIf="symbol == 'USD'">$</span>
      {{ depositData?.amount - depositData?.fee| number: "0.0-4" }}
    </h5>
  </div>
  <p class="font-weight-bold">Where will you receive bank transfer from?</p>

  <div [formGroup]="form" class="mb-4">
    <!-- <ng-container> -->
      <div>
        <input type="radio" class="form-check-input" id="online" name="transferType" mdbInput [value]="0"
          formControlName="transferType" />
        <label class="form-check-label" for="online">Online Banking</label>
      </div>
      <div>
        <input type="radio" class="form-check-input" id="telephone" name="transferType" formControlName="transferType"
          [value]="1" mdbInput />
        <label class="form-check-label" for="telephone">Telephone Banking</label>
      </div>
      <div>
        <input type="radio" class="form-check-input" id="branch" name="transferType" formControlName="transferType"
          [value]="2" mdbInput />
        <label class="form-check-label" for="branch">In Branch</label>
      </div>
    <!-- </ng-container> -->
    <!-- <ng-container *ngIf="symbol === 'USD'">
      <div>
        <input type="radio" class="form-check-input" id="withInUSA" name="transferType" mdbInput [value]="3"
          formControlName="transferType" />
        <label class="form-check-label" for="withInUSA">Within the US</label>
      </div>
      <div>
        <input type="radio" class="form-check-input" id="outsideUSA" name="transferType" formControlName="transferType"
          [value]="4" mdbInput />
        <label class="form-check-label" for="outsideUSA">Outside the US</label>
      </div>
    </ng-container> -->
    <br>
    <ng-container *ngIf="false">
      <div class="md-form sm-currency-select">
        <img *ngIf="optionIcon !== undefined" [src]="optionIcon" class="rounded-circle sm-curr-img" height="34"
          width="34" />
        <mdb-select-2 [outline]="true" name="institutionlist" placeholder="Select Bank"
          (selected)="onBankChange($event)"
          [ngClass]="{'sm-form-select':optionIcon !== undefined, 'sm-text-indent':optionIcon === undefined}"
          [dropdownClass]="'mdb-select-dropdown-colorful mdb-select-dropdown-primary'"
          [formControl]="form.controls.institutionlist">
          <mdb-select-option *ngFor="let option of options" [value]="option.value">
            <div class="d-flex justify-content-between align-items-center">
              <img [src]="option.icon" class="rounded-circle" height="34" width="34">
              <span>{{ option.label }}</span>
            </div>
          </mdb-select-option>
        </mdb-select-2>
      </div>
    </ng-container>
  </div>
  <div class="d-flex">
    <button style="width: 50%!important;" mdbBtn block="true" type="button" [disabled]="loading"
      class="w-auto sm-btn-input-hover btn-border btn-sm undefined btn btn-outline- mr-3" mdbWavesEffect
      (click)="onBack()">
      Back
    </button>
    <button [disabled]="isTransferTypeSelected()" style="width: 50%!important;" mdbBtn block="true" type="button"
      class="btn-sm btn-primary btn btn-height w-auto" mdbWavesEffect (click)="onContinue()">
      Continue
    </button>
  </div>
</section>
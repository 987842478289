import { Location } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { USER_FEE_MODE, USER_TYPE, WALLET_CURRENCY } from 'src/app/app.constants';
import { CurrencyType } from 'src/app/core/enums/currencyType';
import { UserSubscriptionPlan } from 'src/app/core/models/account-upgrade/subscription-plan-response';
import { ICurrency } from 'src/app/core/models/currency';
import { IBrokerFees } from 'src/app/core/models/transfer-types';
import { DomainService } from 'src/app/core/services/domain/domain.service';
import { SubSink } from 'subsink';
import { WalletService } from '../../services/wallet.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'cashaa-borrow',
  templateUrl: './borrow.component.html',
  styleUrls: ['./borrow.component.scss']
})
export class BorrowComponent implements OnInit {

  // creditLineList: CreditLine[];
  // userCreditBalance;
  totalCreditInUSD;
  totalAvailableCreditInUSD;
  conditionsAccepted: any;
  isLoader;

  form: FormGroup;
  iscasfee = false;

  defaultFromCurrency: ICurrency;
  rateSymbol: string;

  /** casSymbol varibale */
  casSymbol: string;

  /** casrate varibale */
  casrate: number = 0;
  minAmount: number;
  minAmountSymbol: string;
  minCASAmount: number;
  minCASAmountSymbol: string;
  brokerFees: IBrokerFees;
  selectedfeeamount: number;
  actulaconvertedamount: number;
  isMinSendValueError: boolean = false;
  isMinReceiveValueError: boolean = false;

  defaultToCurrency: ICurrency;
  defautCreditCurrency: ICurrency;
  sendCurrencySymbol: string;
  receiveCurrencySymbol: string;
  rate: number;
  loanAmountInUSD: number;
  currentUserPlanDetail: UserSubscriptionPlan;
  userType = sessionStorage.getItem(USER_TYPE);
  userFeeMode = sessionStorage.getItem(USER_FEE_MODE);
  creditError = false;
  fiatCurrencies = ["USD", "GBP", "EUR", "AUSD", "SGD", "AUD", "CNH", "CAD", "CHF", "NZD", "JPY"];

  subsink = new SubSink();
  currencyMap = new Map();
  @Input() inView;
  @Output() onConfirm = new EventEmitter();
  amountAfterLoanAvailed: number = 0;
  annuallyInterestCharged;
  lockedCurrency: any = [];
  minAmountError = false;
  //receivercurrencyId = ;
  loading = false;
  minLoanAmount;
  currencyType;
  borrowInSameCurrency;
  isBorrowMore = false;
  clicked = false;
  zeroMoreDesc = '';
  approxDailyInterest;
  data;
  planName;
  showUpgradeOption = false;
  constructor(private walletService: WalletService,
    private domainService: DomainService,
    private fb: FormBuilder,
    private location: Location,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    this.createForm();
    this.rate = 1;
    this.sendCurrencySymbol = "USD";
    this.selectedfeeamount = 0;
    this.actulaconvertedamount = 0;
    this.receiveCurrencySymbol = "CAS";
    this.casSymbol = "CAS";
  }

  ngOnInit(): void {
  
    this.getGivenCurrency(); 
    console.log(this.defaultFromCurrency);
    this.form.get('senderAmount').valueChanges.subscribe(res => {
      console.log(res);
      if (res) {
        this.loanAmountInUSD = (res.split(",").join("") * this.rate);
        // this.amountAfterLoanAvailed =  (this.userCreditBalance?.totalCreditInUSD -( res.split(",").join("") * this.rate));
        this.amountAfterLoanAvailed = this.borrowInSameCurrency - Number(res.split(",").join(""));
        this.amountAfterLoanAvailed = this.amountAfterLoanAvailed < 0 ? 0 : this.amountAfterLoanAvailed;
        this.creditError = this.loanAmountInUSD > (this.totalCreditInUSD) ? true : false;
        this.minAmountError = res.split(",").join("") >= this.minLoanAmount ? false : true;
      }
    })
    this.sortNoInterestRate();
  }

  onsendCurrencyChangeEvent(data: ICurrency): void {
    // this.iscasfee = false;
    console.log(data);
    this.data = data;
    this.loading = true;
    this.borrowInSameCurrency = 0;
    this.actulaconvertedamount = 0;
    this.sendCurrencySymbol = data.symbol;
    this.currencyType = data.currencyType;
    this.walletService.getDailyInterestRate(data.id, this.isBorrowMore).subscribe(res => {
      // this.dailyInterestCharged = res.dailyInterest;
      this.minLoanAmount = res.minLoanAmount;
      this.rate = res.rate;
      if (this.currencyType == 2) {
        this.borrowInSameCurrency = this.rate * this.totalAvailableCreditInUSD;
      } else {
        this.borrowInSameCurrency = this.totalAvailableCreditInUSD / this.rate;
      }
      this.amountAfterLoanAvailed = this.borrowInSameCurrency;
      this.loading = false;
    }, err => {
      this.loading = false;
    });

    this.resetAmountvalue();


  }


  /***
   * Create new form
   */
  createForm(): void {
    this.form = this.fb.group({
      sendercurrencyId: [""],
      senderAmount: [""]
    });
  }

  resetAmountvalue(): void {
    if (this.form.controls["senderAmount"]) {
      this.form.controls["senderAmount"].patchValue(null, {
        emitEvent: false,
        onlySelf: true,
      });
    }
    this.actulaconvertedamount = 0;
    this.isMinSendValueError = false;
    this.isMinReceiveValueError = false;
  }

  getGivenCurrency(): void {
    const pathName = window.location.pathname;

    this.domainService
      .getCurrenciesList(CurrencyType.all)
      .subscribe((list) => {
        this.activatedRoute.queryParamMap.subscribe((val) => {
          this.sendCurrencySymbol = val.get("c");
        })
        if(this.sendCurrencySymbol) {
          this.defaultFromCurrency = list.currencies.filter(
            (c) => c.symbol === this.sendCurrencySymbol
          )[0];
        } else {
          this.defaultFromCurrency = list.currencies.filter(
            (c) => c.symbol === WALLET_CURRENCY.USD
          )[0];
        }
      
        this.defaultToCurrency = list.currencies.filter(
          (c) => c.symbol === WALLET_CURRENCY.USD
        )[0];
        this.form.controls.sendercurrencyId.setValue(
          this.defaultFromCurrency.id
        );
        if (this.form.controls.receivercurrencyId) {
          this.form.controls.receivercurrencyId.setValue(
            this.defaultToCurrency.id
          );
        }

        // this.defautCreditCurrency = list.currencies.filter(
        //   (c) => c.symbol === WALLET_CURRENCY.USD
        // )[0];

      });
  }

  // getCreditLine() {
  //   this.loading = true;
  //   this.walletService.getCreditLines().subscribe((res: UserCreditBalance) => {
  //     this.loading = false;
  //     if (res) {
  //       this.creditLineList = res.creditLines;
  //       this.userCreditBalance = res.usercreditbalance;      
  //     }
  //   }, err => {
  //     this.loading = false;
  //   })
  // }

  confirm() {
    this.getLockedAmount();
  }

  getLockedAmount() {
    const payload = {
      "amount": this.form.value.senderAmount,
      "currencyId": this.form.value.sendercurrencyId,
      "isInterestFetch": this.isBorrowMore
    }
    this.loading = true;
    this.clicked = true;
    this.walletService.getLockedCurrencyAmount(payload).subscribe(res => {
      this.loading = false;
      this.clicked = false;
      this.lockedCurrency = res;
      const payload = {
        amount: this.form.value.senderAmount,
        currencyId: this.form.value.sendercurrencyId,
        amountBeforeLoan: this.totalCreditInUSD,
        amountAfterLoan: this.amountAfterLoanAvailed,
        dailyInterestCharged: this.annuallyInterestCharged,
        lockedCurrency: this.lockedCurrency,
        isInterestFetch: this.isBorrowMore
      }
      this.walletService.setLoanDetails(payload);
      this.onConfirm.emit(payload);
    }, err => {
      this.loading = false;
    })
  }

  goBack() {
    this.location.back();
  }

  sortNoInterestRate() {
    this.isBorrowMore = !this.isBorrowMore;
    if (this.isBorrowMore) {
      this.zeroMoreDesc = 'Pay daily interest to borrow higher amount';
      this.showUpgradeOption = false;
    } else {
      this.zeroMoreDesc = 'Pay 0% interest by borrowing lower amount';
      if(this.planName === 'Base') {
        this.showUpgradeOption = true;
      }
    }
    this.getCreditLineAmout();
  }

  getCreditLineAmout() {
    this.walletService.getCreditLineQuote(this.isBorrowMore).subscribe(res => {
      this.totalCreditInUSD = res.totalCreditInUSD;
      this.annuallyInterestCharged = res.dailyInterest;
      this.approxDailyInterest = this.annuallyInterestCharged / 365;
      this.totalAvailableCreditInUSD = res.totalAvailableCreditInUSD;
      this.planName = res.planName;
      // if(this.totalAvailableCreditInUSD  <= 0) {
      //   this.router.navigateByUrl('wallet');
      // }
      if (this.currencyType == 2) {
        this.borrowInSameCurrency = this.rate * this.totalAvailableCreditInUSD;
      } else {
        this.borrowInSameCurrency = this.totalAvailableCreditInUSD / this.rate;
      }
      if (this.form.controls.senderAmount.value) {
        this.amountAfterLoanAvailed = this.borrowInSameCurrency - Number(this.form.controls.senderAmount.value.split(",").join(""));
      } else {
        this.amountAfterLoanAvailed = this.borrowInSameCurrency;
      }

    })
    if (this.data) {
      this.onsendCurrencyChangeEvent(this.data);
    }
  }

  goToPlans(): void {
    this.router.navigateByUrl("/plans")
}


}

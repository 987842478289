<mdb-accordion>
  <mdb-accordion-item [collapsed]="true">
    <mdb-accordion-item-head [customClass]="isOpen ? 'pl-4' : 'settings-card pl-4'"
      (click)="isOpen = !isOpen; showForm = false">
      <div class="d-flex align-items-center">
        <img [src]="'../../../../assets/images/settings/email.svg'" />
        <!-- <mdb-icon far icon="envelope" class="p-2 card-icon" [ngClass]="isOpen ? '' : 'bg-white'"></mdb-icon> -->
        <div class="ml-4 setting-card-open">
          <h6 class="mb-1 heading">Email</h6>
          <span class="mb-0 font-weight-normal">{{ useremail }}</span>
          <span class="green-text font-weight-bolder">&nbsp;(Verified)</span>
        </div>
      </div>
    </mdb-accordion-item-head>
    <mdb-accordion-item-body customClass="pl-5 position-relative">
      <div class="ml-5">
        <hr class="mb-5" />
        <!-- <div class="email-info" *ngIf="!showForm"> -->
          <!-- <button mdbBtn (click)="showForm = true" block="true" type="button"
            class="rounded-sm position-relative btn btn-primary waves-effect waves-light btn-block w-auto ml-0 mb-4"
            mdbWavesEffect>
            Change email address
          </button> -->
          <!-- <p>
          </p>
        </div> -->
        <div class="email-form">
          <div class="mb-4 w-25 w-60">
            <cashaa-form-input name="currentEmail" type="email" [form]="form" [control]="form.controls.currentEmail"
              placeholder="Previous Email" label="Old email address">
            </cashaa-form-input>
          </div>
          <div class="mb-4 w-25 w-60">
            <cashaa-form-input name="newEmail" type="email" [form]="form" [control]="form.controls.newEmail"
              placeholder="New Email" label="New email address">
            </cashaa-form-input>
          </div>
          <button
            [disabled]="isInProgress || form.invalid || form.controls.currentEmail.value===form.controls.newEmail.value"
            mdbBtn (click)="onEmailChangeFormSubmit()" block="true" type="button"
            class="rounded-sm position-relative btn btn-primary waves-effect waves-light btn-block w-60 w-25 ml-0 mb-4"
            mdbWavesEffect>
            {{event === 'updatemail' && token ? 'Change email address' : 'Get reset link'}}
          </button>
          <mdb-error class="sm-error-message mt-2" style="position: initial!important;"
            *ngIf="form.controls.currentEmail.value===form.controls.newEmail.value">New email can not be same as your
            current email.</mdb-error>
        </div>
      </div>

      <!-- Progress bar -->
      <mdb-progress-bar *ngIf="isInProgress" class="progress primary-color-dark position-absolute" mode="indeterminate">
      </mdb-progress-bar>
    </mdb-accordion-item-body>
  </mdb-accordion-item>
</mdb-accordion>
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { IDeleteReferral, IReferralDetailResponse, IReferralInfo, ISendReferralInvitation } from "src/app/core/models/referral/referral";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class ReferralService {
  private apiUrl = environment.api;

  constructor(private http: HttpClient) { }
  getReferralList(page: number, pageSize: number, status?: string): Observable<IReferralDetailResponse> {
    console.log(status);
    let url = `${this.apiUrl}/userreferral/getuserreferrallist?page=${page}&pageSize=${pageSize}`
    if (status === '0') {
      url = url + `&status=false`
    } else if (status === '1') {
      url = url + `&status=true`
    }
    return this.http.get<IReferralDetailResponse>(url);
  }

  getReferralInfo(): Observable<IReferralInfo> {
    return this.http.get<IReferralInfo>(`${this.apiUrl}/userreferral/getreferraldetails`);
  }

  deleteReferral(deleteReferral: IDeleteReferral): Observable<{ message: string, statusCode: number }> {
    return this.http.post<{ message: string, statusCode: number }>(`${this.apiUrl}/userreferral/deletereferralrequest`, deleteReferral);
  }

  sendReferralInvitation(sendInvitation: ISendReferralInvitation): Observable<{ message: string, statusCode: number }> {
    return this.http.post<{ message: string, statusCode: number }>(`${this.apiUrl}/userreferral/SendReferralInvitation`, sendInvitation);
  }

  getFixedDepositCalculationInterest(formValue, rate, symbol, interestType, duration?) {
    return this.http.get<IReferralInfo>(`${this.apiUrl}/depositinterest/fixeddepositcalculator?amount=${formValue.referralInvest || formValue.senderAmount}&rate=${rate}&duration=${formValue.duration || duration}&rateType=${formValue.rateType}&symbol=${symbol}&interestOptType=${interestType}`);
  }

  getReferralInterest(): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/userreferral/getReferralInterestPercent`);
  }
}

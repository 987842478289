export interface ICurrencyTypeData {
    currencySymbol: string;
    currencyType: string;

}


export class CurrencyTypeData implements ICurrencyTypeData {
    constructor(
        public currencySymbol: string,
        public currencyType: string
    ) { }
}
<cashaa-sidenav>
    <section class="mh-100">
        <div class="bg-white p-4 sm-media-padding">
            <div class="d-flex sm-phone-display-none mr-3" style="height: 47px;">
                <button routerLink="/repay" mdbBtn type="button" class="btn-sm app-primary-btn">Back</button>
            </div>
            <div class="sm-recipient-btn-padding-mobile sm-phone-display-visible" style="display: none;">
            </div>
        </div>
    </section>

    <!--  -->
    <form [formGroup]="loanDetailsForm" class="position-relative sm-font-family sm-form-padding">
        <div class="text_cntnt table_btn_section" style="padding: 24px;">
            <h5 class="mb-4 heading loan-repay">Loan repayment</h5>
            <!-- <p class="" style="color: #3E4B5B;">Interest charged daily will automatically be deducted from your saving wallet in the currency of the loan availed. In case of unavailability of ballance, the interest amount will accrue to your outstanding loan amount</p> -->
            <div class="repay sm-width-phone-700">
                <h5 class="heading my-3 p10-40">Loan No. {{loanRepaymentDetails?.loanNumber}}</h5>
                <hr>
                <div class="py-3 p10-40">
                    <h5 class="mb-4">Outstanding loan amount: <span class="amount">{{outstandingLoanBeforeRepay}} {{loanRepaymentDetails?.currencySymbol}}</span></h5>
                    <h6 class="heading">Pay with</h6>
                    <cashaa-form-select-currency-pair #currencyPair class="pay-loan" placeholder="Filter by" [control]="loanDetailsForm.controls.currency" name="currency"
                    [form]="loanDetailsForm" [initialValue]="loanRepaymentDetails?.currencyId" (changed)="onCurrencyTypeChange($event)">
                    </cashaa-form-select-currency-pair>
                    <div class="mt-4 row mb-4">
                        <div class="form-check sm-userType-boxes d-flex">
                            <input type="radio"  class="form-check-input" id="savingWalletType" name="walletType" mdbInput value="1" 
                            formControlName="walletType"/>
                            <label class="form-check-label" for="savingWalletType">Saving Wallet</label>
                            <div class="wallet-amount mx-2" *ngIf="toSymbol">
                                <div *ngIf="currencyType === 1" class="text-center symbol-amount">{{toSymbol}}&nbsp;{{savingWalletOnHold | number: '0.0-5'}}</div>
                                <div *ngIf="currencyType === 2" class="text-center symbol-amount">{{toSymbol}}&nbsp;{{savingWalletOnHold | number: '0.0-5'}}</div>
                            </div>
                            <input disabled type="radio"  class="form-check-input" id="creditWalletType" name="walletType" mdbInput value="2" 
                             formControlName="walletType" />
                            <label class="form-check-label" for="creditWalletType">Pay From Collateral
                            </label>
                            <div>
                                <i class="d-block fa fa-info-circle text-hold cursor-pointer" [mdbPopover]="template"
                                placement="bottom" 
                                >&nbsp;</i>
                            </div>
                            <div class="wallet-amount ml-2"  *ngIf="toLockedCurrency">
                                <div *ngIf="currencyType === 1; else other" class="text-center symbol-amount">{{toLockedCurrency}}&nbsp;{{toLockedCurrencyAmount | number: '0.0-5'}}</div>
                                <ng-template #other>
                                    <div class="text-center symbol-amount">{{toLockedCurrency}}&nbsp;{{toLockedCurrencyAmount | number: '0.0-5'}}</div>
                                </ng-template>
                            </div>
                        </div>
                    </div>

                    <div class="my-4">
                        <cashaa-form-input name="amount" type="text" [form]="loanDetailsForm"
                        placeholder="0.00" [control]="loanDetailsForm.controls.amount" label="Amount">
                        </cashaa-form-input>
                    </div>
                    <div *ngIf="feeAmount > 0" class="mb-4 position-relative pl-5">
                        <div class="d-flex align-items-center text-primary-dark">
                            <p class="heading">{{feeAmount | number: "0.0-4"}}</p>
                            <p class="heading ml-3">Exchange fee</p>
                        </div>
                        <span class="line-left">
                            <mdb-icon class="line-icon first-icon" fas icon="plus" [style.marginTop]="'1.3rem'">
                            </mdb-icon>
                          </span>
                    </div>
                       <!-- <i class="fa fa-minus-circle cursor-pointer" style="color:#1531aa">&nbsp;<span class="amount">{{feeAmount | number: "0.0-4"}}&nbsp;Fee</span></i>                    </div> -->
                    <p class="red-text mb-1 font-weight-normal" *ngIf="errorMsg">
                        {{message}}
                      </p>
                    <div class="mt-4" *ngIf="payToCloseLoan > 0">
                        <h5>You need to pay another <span class="amount cursor-pointer" (click)="payToClose()">{{payToCloseLoan | number: "0.0-4"}} {{toSymbol}}</span> to close the loan</h5>
                    </div>
                    <div class="mt-5" *ngIf="outstandingLoanAfterRepay > 0">
                        <h5>Outstanding loan after repayment: <span class="amount">{{outstandingLoanAfterRepay | number}} {{loanRepaymentDetails?.currencySymbol}}</span></h5>
                    </div>
                    <div class="mt-4">
                        <button type="button" class="btn btn-primary" (click)="onFormSubmit()"  [disabled]="errorMsg == true || loanDetailsForm.invalid || loanDetailsForm.controls.amount.value <= 0">
                            Repay
                        </button>
                    </div>

                
                
                </div>
            </div>
            <br>
            <table class="table table-bordered border-primary align-middle">
                <thead>
                    <tr class="">
                        <th scope="col">Date</th>
                        <th scope="col">Transaction Id</th>
                        <th scope="col">Amount</th>
                    </tr>
                </thead>
                <tbody class="">
                    <tr class="" *ngFor="let info of loanRepaymentHistoryInfo">
                        <td>{{info?.date | date: 'dd MMMM, yyyy'}}</td>
                        <td>{{info?.id}}</td>
                        <td><img src="../../../assets/icons/{{info?.currencySymbol}}.png" alt="{{info?.currencySymbol}}" width="25px">&nbsp;{{info?.amount}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </form>
    <ng-template #template>
        <div style="min-width: 125px;z-index: 99999;" class="mb-1 px-1">
      <div *ngFor="let lockInf of lockedInfo">
        <img class="text-hold fa fa-lock my-2" src="../../../assets/images/lock.png"><span class="ml-1">&nbsp;
            {{lockInf?.amount}}&nbsp;<a style="text-decoration: underline;" (click)="selectLockedCurrency(lockInf)">{{lockInf?.currencySymbol}}</a></span>
      </div>
      </div>
    </ng-template>
  
    <div class="p-4 text-center" [hidden]="!loading">
        <mdb-spinner spinnerColor="blue"></mdb-spinner>
    </div>

</cashaa-sidenav>
export enum AuthStatus {
  /// <summary>
  /// The success
  /// </summary>

  Success = 1,
  /// <summary>
  /// The email not confirmed
  /// </summary>

  EmailNotConfirmed = 2,
  /// <summary>
  /// The otp required
  /// </summary>

  OtpRequired = 3,
  /// <summary>
  /// The otp and two factor authentication required
  /// </summary>

  OtpAndTwoFactorAuthRequired = 4,
  /// <summary>
  /// The invalid two factor code
  /// </summary>

  InvalidTwoFactorCode = 5,
  /// <summary>
  /// Creates new signindevice.
  /// </summary>

  NewSignInDevice = 6,
  /// <summary>
  /// The change password token required
  /// </summary>

  ChangePasswordTokenRequired = 7,
}

import { MDBBootstrapModulesPro } from "ng-uikit-pro-standard";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { SharedModule } from "./../shared/shared.module";
import { WalletRoutingModule } from "./wallet-routing.module";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { WalletDashboardComponent } from "./wallet-dashboard/wallet-dashboard.component";
import { WalletOpenComponent } from "./wallet-open/wallet-open.component";
import { WalletReadyComponent } from "./wallet-ready/wallet-ready.component";
import { QRCodeModule } from "angular2-qrcode";
//import { QRCodeModule } from "angularx-qrcode";
import { ToastrModule } from "ngx-toastr";
// import { WalletPageComponent } from "./wallet-page/wallet-page.component";
import { CurrencyAssetsComponent } from "./currency-assets/currency-assets.component";
import { CreditLineComponent } from './credit-line/credit-line.component';
import { LoanInterestComponent } from './loan-interest/loan-interest.component';
import { RepayComponent } from './repay/repay.component';
import { BorrowComponent } from './credit-line/borrow/borrow.component';
import { ReviewSecurityVerificationComponent } from './credit-line/review-security-verification/review-security-verification.component';
import { ConfirmComponent } from './credit-line/confirm/confirm.component';
import { LoanDetailsComponent } from './loan-details/loan-details.component';
import { LoanRepaymentComponent } from './loan-repayment/loan-repayment.component';
import { PortfolioHistoryComponent } from './portfolio-history/portfolio-history.component';

@NgModule({
  declarations: [
    WalletDashboardComponent,
    WalletOpenComponent,
    WalletReadyComponent,
    CurrencyAssetsComponent,
    CreditLineComponent,
    LoanInterestComponent,
    RepayComponent,
    BorrowComponent,
    ReviewSecurityVerificationComponent,
    ConfirmComponent,
    LoanDetailsComponent,
    LoanRepaymentComponent,
    PortfolioHistoryComponent
  ],
  imports: [
    CommonModule,
    WalletRoutingModule,
    SharedModule,
    ReactiveFormsModule,
    FormsModule,
    ToastrModule.forRoot(),
    MDBBootstrapModulesPro.forRoot(),
    QRCodeModule,
  ],
})
export class WalletModule {}

export interface IReferralDetailResponse {
  data: IReferralDetail[];
  pageIndex: number;
  pageSize: number;
  totalCount: number;
  totalPageCount: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
}

export interface IReferralDetail {
  id: string;
  email: string;
  status: boolean,
  amount: number,
  amountCurrency: string,
  joinedDate: Date | string
  isDeleteOrResend: boolean
}

export interface IReferralInfo {
  joined: number,
  pending: number,
  totalReferral: number,
  totalAmount: number,
  interestOptType: number
}

export interface IDeleteReferral {
  id: string
}

export interface ISendReferralInvitation {
  toemail: IRecipientEmail[]
}

export interface IRecipientEmail {
  recipientemail: string
}

export class DeleteReferral implements IDeleteReferral {
  constructor(public id: string) { }
}

import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import {
  DEPOSIT_TIMING,
} from "src/app/app.constants";

@Component({
  selector: "cashaa-deposit-timer",
  templateUrl: "./deposit-timer.component.html",
  styleUrls: ["./deposit-timer.component.scss"],
})
export class DepositTimerComponent implements OnInit, OnDestroy {
  timeLeft;
  interval;
  depositTime: any = DEPOSIT_TIMING;
  createdTime;
  timeDiff;
  currentTime;
  secondTimer;
  secondInterval;
  @Input() otherDetails;
  copiedAddress: boolean;
  minutes;
  extraSeconds;
  constructor(private toastrService: ToastrService) {
    this.createdTime = localStorage.getItem('cashaa/ticketCreatedTime');
  }

  ngOnInit(): void {
    console.log(this.depositTime, this.timeDiff);
    this.calTimeDiffernce();
    this.startTimer();
  }

  startTimer() {
    this.interval = setInterval(() => {
      this.calTimeDiffernce();
    }, 1000)
  }

  calTimeDiffernce() {
    let curTime = new Date();
    curTime.setMinutes(new Date().getMinutes() + 330)
    this.currentTime = curTime.toLocaleTimeString('en',
      { hour12: false, timeZone: 'UTC' });
    this.timeDiff = (this.strToMins(this.currentTime) - this.strToMins(this.createdTime));
    console.log(this.timeDiff);
    this.timeLeft = 1800 - this.timeDiff;
    this.minutes = Math.floor(this.timeLeft / 60);
    this.extraSeconds = this.timeLeft % 60;
    // this.minutes = this.minutes < 10 ? 0 + this.minutes : this.minutes;
    // this.extraSeconds = this.extraSeconds < 10 ? 0 + this.extraSeconds : this.extraSeconds;
    console.log(this.timeLeft);
    if(this.minutes <= 0  && this.extraSeconds <=0) {
      // this.interval.unsubscribe();
      clearInterval(this.interval);
    }
  }

  strToMins(t) {
    var s = t.split(":");
    return Number(s[0]) * 3600 + Number(s[1]) * 60 + (+Number(s[2]) || 0);
    // return Number(s[0]) * 60 + Number(s[1]) + Number(s[2]);
  }

  copyMemoAddress(): void {
    if (this.otherDetails?.address) {
      this.copiedAddress = true;
      navigator.clipboard.writeText(this.otherDetails?.address);
      this.toastrService.toastrConfig.preventDuplicates = true;
      this.toastrService.success("Text copied to clipboard");
      setTimeout(() => (this.copiedAddress = false), 2000);
    }
  }

  ngOnDestroy() {
    // if(this.interval) {
    //   this.interval.unsubscribe();
    // }
   
  }

}
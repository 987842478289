import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ICountry } from "src/app/core/models/country";
import {
  IPostalAddress,
  IUSPersonal,
} from "src/app/core/models/kyc-us-personal/us-personal";
import { ValidatorUtility } from "src/app/core/utility/validator.utility";
import { KycService } from "../services/kyc.service";

@Component({
  selector: "cashaa-kyc-us-personal-postal-address",
  templateUrl: "./kyc-us-personal-postal-address.component.html",
  styleUrls: ["./kyc-us-personal-postal-address.component.scss"],
})
export class KycUsPersonalPostalAddressComponent implements OnInit {
  public form: FormGroup;
  @Output()
  public continue = new EventEmitter<any>();
  selectedCountry: ICountry;
  isDataLoaded: boolean = false;
  initialValueCountry = "85eee32a-262a-4442-8f1d-ee6f0ce37f7a";

  constructor(private fb: FormBuilder, private kycService: KycService) {}

  ngOnInit(): void {
    this.createForm();
    this.kycService.getKycUsPersonal().subscribe((data: IUSPersonal) => {
      if (data !== undefined) {
        this.isDataLoaded = true;
        // this.kycService.emitKycUsPersonalMobileCode(this.selectedCountry.phoneCode);
        this.loadFormData(data);
      }
    });
  }

  createForm(): void {
    this.form = this.fb.group({
      addressLine1: [
        "",
        [ValidatorUtility.Required, Validators.maxLength(100)],
      ],
      addressLine2: ["", Validators.maxLength(100)],
      addressLine3: ["", Validators.maxLength(100)],
      postalCode: ["", [ValidatorUtility.Required, Validators.maxLength(35)]],
      city: ["", ValidatorUtility.Required],
      province: ["", [ValidatorUtility.Required, Validators.maxLength(100)]],
      country: [{ value: "" }, ValidatorUtility.Required],
    });
  }

  onSubmit(data: IPostalAddress) {
    data.country = this.selectedCountry.id;
    this.kycService.emitKycUsPersonalMobileCode(this.selectedCountry.phoneCode);
    this.continue.emit(data);
  }

  onCountryChange(data: { option: any; country: ICountry }) {
    setTimeout(() => {
      this.form.patchValue({
        country: data.option,
      });
    });
    this.selectedCountry = data.country;
  }

  loadFormData(data: IUSPersonal): void {
    if (data) {
      this.form.controls["addressLine1"].setValue(
        data.postalAddress.addressLine1
      );
      this.form.controls["addressLine2"].setValue(
        data.postalAddress.addressLine2
      );
      this.form.controls["addressLine3"].setValue(
        data.postalAddress.addressLine3
      );
      this.form.controls["postalCode"].setValue(data.postalAddress.postalCode);
      this.form.controls["city"].setValue(data.postalAddress.city);
      this.form.controls["province"].setValue(data.postalAddress.province);
      this.initialValueCountry = data.postalAddress.country;
    }
  }
}

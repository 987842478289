export interface IBusinessProfile {
  companyName: string;
  companyType: number;
  phoneNumber: string;
  city: string;
  postalCode: string;
  countryId: string;
  addressLine1: string;
  addressLine2: string;
  registration?: string;
  website?: string;
  category?: string;
  companyCategoryLabel?: string;
  subCategory?: string;
  userRoleLabel?: string;
  countryLabel?: string;
  country?: string;
  userRole?: string;
  email?: string;
}

export class BusinessProfile implements IBusinessProfile {
  constructor(
    public companyName: string,
    public companyType: number,
    public phoneNumber: string,
    public city: string,
    public postalCode: string,
    public countryId: string,
    public addressLine1: string,
    public addressLine2: string,
    public registration?: string,
    public website?: string,
    public category?: string,
    public subCategory?: string,
    public companyCategoryLabel?: string,
    public userRoleLabel?: string,
    public countryLabel?: string,
    public country?: string,
    public userRole?: string,
    public email?: string,

  ) {}
}

import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { MdbStepperComponent } from "ng-uikit-pro-standard";
import { ToastrService } from "ngx-toastr";
import { UNABLE_TO_PERFORM_ERROR } from "src/app/app.constants";
import {
  IContactDetail,
  IDocuments,
  IPostalAddress,
  IUSPersonal,
} from "src/app/core/models/kyc-us-personal/us-personal";
import { KycService } from "../services/kyc.service";

@Component({
  selector: "cashaa-kyc-us-personal-home",
  templateUrl: "./kyc-us-personal-home.component.html",
  styleUrls: ["./kyc-us-personal-home.component.scss"],
})
export class KycUsPersonalHomeComponent implements OnInit {
  @ViewChild("stepper") stepper: MdbStepperComponent;
  public loading = false;
  loader: boolean = false;
  private kycUsPersonal: IUSPersonal;
  kycCompleteStatus: boolean;
  isStaking: boolean;
  constructor(
    private kycService: KycService,
    private toastrService: ToastrService,
    private activatedRoute: ActivatedRoute
  ) {
    this.activatedRoute.queryParams.subscribe((params) => {
      if (params["from"]) {
        this.isStaking = true;
      } else {
        this.isStaking = false;
      }
    });
  }

  ngOnInit(): void {
    this.kycService.getUSPersonalKYC().subscribe((data: IUSPersonal) => {
      if (data !== undefined) {
        this.kycService.emitKycUsPersonal(data);
        if (data.kycStatus === 2) {
          this.kycCompleteStatus = true;
        }
      }
    });
  }

  onBasicInfoContinue(data: IUSPersonal): void {
    this.kycUsPersonal = data;
    this.stepper.next();
  }

  onPostalAddressContinue(data: IPostalAddress): void {
    this.kycUsPersonal.postalAddress = data;
    this.stepper.next();
  }

  onContactDetailContinue(data: IContactDetail): void {
    this.kycUsPersonal.contactDetail = data;
    this.stepper.next();
  }

  onDocumentContinue(data: IDocuments[]): void {
    this.loader = true;
    this.kycUsPersonal.documents = data;
    this.kycUsPersonal.tp = "I";
    this.kycUsPersonal.consent = "Yes";
    this.kycService.createUSPersoanlKYC(this.kycUsPersonal).subscribe(
      (id) => {
        this.toastrService.success("KYC Updated Successfully");
        this.stepper.next();
        this.loader = false;
      },
      (err) => {
        this.loader = false;
        if (err.error.message) {
          this.toastrService.error(err.error.message);
        } else {
          this.toastrService.error(UNABLE_TO_PERFORM_ERROR);
        }
      }
    );
  }
}

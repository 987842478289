import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ReferralRoutingModule } from './referral-routing.module';
import { ReferralComponent } from './referral/referral.component';
import { SharedModule } from '../shared/shared.module';
import { MDBBootstrapModulesPro } from 'ng-uikit-pro-standard';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


@NgModule({
  declarations: [ReferralComponent],
  imports: [
    CommonModule,
    ReferralRoutingModule,
    ReactiveFormsModule,
    MDBBootstrapModulesPro.forRoot(),
    SharedModule,
    FormsModule
  ]
})
export class ReferralModule { }

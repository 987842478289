<cashaa-sidenav>
    <section class="mh-100">
        <div class="bg-white p-4 sm-media-padding">
            <div class="d-flex sm-phone-display-none mr-3" style="height: 48px;">
                <h6 class="heading my-auto">
                    Statement
                </h6>
            </div>
        </div>
    </section>

    <div *ngIf="!depositInterestId" class="filter-section row mt-5 mx-2 pt-3 position-relative">
        <div class="col-6 col-sm-3">
            <mdb-date-picker class="date-sel" [inline]="true" name="mydate" [placeholder]="'From date'" [options]="myDatePickerOptions" (dateChanged)="onFromDateChange($event)" required>
            </mdb-date-picker>
        </div>
        <div class="col-6 col-sm-3">
            <mdb-date-picker class="date-sel" [inline]="true" name="mydate" [placeholder]="'To date'" [options]="myDatePickerOptions" (dateChanged)="onToDateChange($event)" required>
            </mdb-date-picker>
        </div>
        <div class="col-6 col-sm-3">
            <cashaa-form-select-currency class="curr-sel" placeholder="Currency" name="currency" [control]="searchForm.controls.currency" source="allCurrency" (changed)="onCurrencyTypeChange($event)">
            </cashaa-form-select-currency>
        </div>
        <div class="col-6 col-sm-3">
            <cashaa-form-select-activity-type-filter class="filter-sel" placeholder="Filter By" (changed)="onActivityTypeChange($event)">
            </cashaa-form-select-activity-type-filter>
        </div>
    </div>

    <div *ngIf="depositInterestId" class="mt-4">
        <mdb-accordion [multiple]="false" aria-multiselectable="false">
            <mdb-accordion-item class="no-collase">
                <mdb-accordion-item-head class="px-2 py-1" mdbWavesEffect>
                    <div class="d-flex flex-row px-2 align-items-center">
                        <div class="px-2 circle">
                            <img src="../../../assets/images/fixed-deposit.svg" style="width: 25px;" />
                        </div>
                        <div class="status ml-2 transaction-detail">
                            <span class="text-primary-dark">{{cur}}
                            Fixed Deposit</span>
                        </div>
                        <div class="amount ml-auto mr-4">
                            <b>{{ val |number: "0.0-4" }}
                            {{cur}}</b><br />
                        </div>
                    </div>
                </mdb-accordion-item-head>
                <mdb-accordion-item-body class="border-top border-light px-4 position-relative">
                    <div class="container px-4 pt-2">
                        <div class="row">
                            <div class="col-sm-6">
                                Moved From <br />
                                <b class="text-primary">Your {{ cur}}
                                balance</b>
                            </div>

                            <div class="col-sm-3">
                                Deposited<br />
                                <b class="text-primary">{{val | number: "0.0-4" }} {{
                                cur
                                }}</b>
                            </div>
                            <div class="col-sm-3">
                                <!-- <span class="content mr-4" style="color: #3570F4;"><a routerLink="/statement"
                                    [queryParams]="{id: ledger?.depositInterestId, cur: getCurrencyName(ledger?.currency), val: ledger?.amount}"
                                    style="text-decoration: underline;">View Statement</a></span> -->
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col-sm">
                                Start Date <br />
                                <b class="text-primary">{{ ledger.startDate| date:'longDate'}}</b>
                            </div>

                            <div class="col-sm">
                                End Date <br />
                                <b class="text-primary">{{ ledger.endDate| date:'longDate'}}</b>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col-sm">
                                Interest Rate Type <br />
                                <b class="text-primary">{{ rateType[ledger.rateType].split('In').join('In ') }}</b>
                            </div>
                            <div class="col-sm">
                                Interest Rate <br />
                                <b class="text-primary">{{ ledger.interestRate }} %</b>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col-sm">
                                Transaction Number <br />
                                <b class="text-primary">{{ ledger?.transactionId }}</b>
                            </div>
                        </div>
                    </div>
                </mdb-accordion-item-body>
            </mdb-accordion-item>
        </mdb-accordion>
    </div>
    <div class="text-center" *ngIf="loader">
        <mdb-spinner spinnerColor="blue"></mdb-spinner>
    </div>
    <div class="table-controls-container mb-2 mr-5 pt-4" style="flex-direction: row-reverse!important;">
        <div class="d-flex">
            <div class="">
                <form class="form-inline w-100 h-75 mt-2">
                    <input class="form-control form-control-sm mr-3 w-75 h-100 sm-mr-3-search-mobile" type="text" placeholder="Search Transactions" aria-label="Search" mdbInput (change)="onSearchTextChanged($event.target.value)" />
                </form>
            </div>
            <div style="display: inline-grid;">
                <button mdbBtn type="button" outline="true" mdbWavesEffect class="sm-btn-input-hover bg-white btn-border btn-sm" (click)="exportStatement()">
                    <img src="../../../../assets/images/Download.png" style="width: 25px; padding-bottom: 5px;">
                    <label class="text-dark font-weight-normal ml-2" style="margin-top: 9px;">Download report</label>
                </button>

            </div>
        </div>
    </div>

    <div class="row p-4 mr-2" *ngIf="!loader">
        <table mdbTable>
            <thead style="background: #1740A4;color: white;">
                <tr>
                    <th style="width: 10%;">
                        <h6>Sr No</h6>
                    </th>
                    <th>
                        <h6>Amount</h6>
                    </th>
                    <th>
                        <h6>CR/DR</h6>
                    </th>
                    <th>
                        <h6>Activity</h6>
                    </th>
                    <th>
                        <h6>Date</h6>
                    </th>
                </tr>
            </thead>
            <tbody class="bg-white">
                <tr mdbTableCol *ngFor="let item of statementData; let i = index">
                    <td style="width: 10%;">
                        <span class="content">{{i+1}}</span>
                        <!-- <span class="col-seperator">|</span> -->
                    </td>
                    <td>
                        <span class="content">{{item.amount | number: "0.0-4"}} {{item.symbol}}</span>
                        <!-- <span class="col-seperator">|</span> -->
                    </td>
                    <td>
                        <!-- <div class="" > -->
                        <div class="d-flex border cr-dr justify-content-center" style="border-radius: 50px;" [ngClass]="{'debit-status': item.transferType === 'Debit', 
                        'credit-status': item.transferType === 'Credit'}">
                            <div  class="dot content"></div>
                            <div>{{item.transferType}}</div>
                        <!-- </div> -->
                            <!-- <div  class="dot cr-dr content p-1"></span><span
                                class="mr-1 ml-0 mt-0">{{item.transferType}}</span> -->
                        </div>
                       
                        
                    </td>
                    <td>
                        <span class="content" style="color: #1531AA;">{{item.transactionData}}</span>
                        <!-- <span class="col-seperator">|</span> -->
                    </td>
                    <td>
                        <span class="content">{{item.createdDate | date}}</span>
                    </td>
                </tr>
            </tbody>
        </table>

    </div>
    <div class="d-flex row p-4 mr-2" *ngIf="!loader">
        <div class="col-md-6 p-0">
            <span class="content" style="font-weight: 600;">{{pageIndex}}-{{pageSize}} of {{length}}</span>
        </div>
        <div class="col-md-6 p-0" style="text-align-last: end;">
            <span class="content mr-2" style="font-weight: 600;">The page you're on {{pageIndex}}</span>
            <mdb-icon (click)="previous()" class="arrow-icon arrow-left mr-2" fas icon="chevron-left"></mdb-icon>
            <mdb-icon (click)="next()" class="arrow-icon arrow-right" fas icon="chevron-right"></mdb-icon>
        </div>
    </div>

</cashaa-sidenav>

import { Component, OnInit } from '@angular/core';
import { MDBModalRef } from 'ng-uikit-pro-standard';
import { CommonSavedData } from '../../services/commonSavedData.service';

@Component({
  selector: 'cashaa-warning-memo-box',
  templateUrl: './warning-memo-box.component.html',
  styleUrls: ['./warning-memo-box.component.scss']
})
export class WarningMemoBoxComponent implements OnInit {
  public currency: string;
  public memoOrTag: string;
  public XrpOrREP: string;
  constructor(public modalRef: MDBModalRef, private commonSavedData: CommonSavedData) { }

  ngOnInit(): void {
    this.memoOrTag = this.currency === 'XRP' ? 'Tag' : 'Memo';
    this.XrpOrREP =  this.currency === 'XRP' ? 'XRP' : 'BEP-2' ;
  }

 onClick(status: boolean): void  {
   this.modalRef.hide();
   this.commonSavedData.emitConfirmationBoxStatus(status);
  } 

}

export enum WalletCurrency {
    /// <summary>
    /// The inr
    /// </summary>

    INR = 1,
    /// <summary>
    /// The GBP
    /// </summary>

    GBP = 2,
    /// <summary>
    /// The eur
    /// </summary>

    EUR = 3,
    /// <summary>
    /// The usd
    /// </summary>

    USD = 4

}
<!--Navbar-->
<mdb-navbar SideClass="navbar navbar-expand-lg navbar-dark bg-primary fixed-top scrolling-navbar">
  <!-- Navbar brand -->
  <mdb-navbar-brand><a class="navbar-brand" href="#">CASHAA</a></mdb-navbar-brand>

  <!-- Collapsible content -->
  <links>
    <!-- Links -->
    <ul class="navbar-nav mr-auto">
      <!-- <li class="nav-item">
        <a
          routerLinkActive="active-nav"
          routerLink="/personal"
          class="nav-link waves-light"
          mdbWavesEffect
          >PERSONAL<span class="sr-only">(current)</span></a
        >
      </li>
      <li class="nav-item">
        <a
          routerLinkActive="active-nav"
          routerLink="/business"
          class="nav-link waves-light"
          mdbWavesEffect
          >BUSINESS</a
        >
      </li>
      <li class="nav-item">
        <a class="nav-link waves-light" mdbWavesEffect>CAS TOKEN</a>
      </li> -->
    </ul>
    <!-- Links -->

    <ul class="navbar-nav">
      <li class="nav-item">
        <a (click)="signIn()" class="nav-link waves-light" mdbWavesEffect>Sign In</a>
      </li>
      <li class="nav-item">
        <a routerLink="/account/signup" class="nav-link waves-light" mdbWavesEffect>Create An Account</a>
      </li>
    </ul>
  </links>
  <!-- Collapsible content -->
</mdb-navbar>
<!--/.Navbar-->
import { Component, OnInit, Input } from "@angular/core";
import { TRADE_MODE } from "src/app/app.constants";
import { TradeService } from "../services/trade.service";
import { ITradeChange } from "src/app/core/models/trade";

@Component({
  selector: "cashaa-trade-buy-sell-confirmation",
  templateUrl: "./trade-buy-sell-confirmation.component.html",
  styleUrls: ["./trade-buy-sell-confirmation.component.scss"],
})
export class TradeBuySellConfirmationComponent implements OnInit {
  /** buy or sell mode */
  mode = TRADE_MODE.BUY;

  /** trade Data change */
  tradeData: ITradeChange;

  constructor(private tradeService: TradeService) {}

  ngOnInit(): void {
    this.listenToTradeDataChange();
  }

  /**
   * Listen to trade data change
   */
  listenToTradeDataChange(): void {
    this.tradeService.getTradeChangeEmitter().subscribe((data) => {
      if (data) {
        this.mode = data.mode;
        this.tradeData = data;
      }
    });
  }
}

<section class="bg-white px-3 w-phone-100 w-75 mx-auto mt-4 mb-4">
    <h3 class="w-100 heading p-3">
        Loan successfully processed!
    </h3>
    <div class="body border-top py-3 text-center">
        <!-- <div class="circle">
            <i class="fa fa-check"></i>
        </div> -->
        <img src="../../../../assets/images/account/credit-line-success.svg" alt="credit line" height="200px">
        <p class="mx-5 mt-3 font-weight-500">Congratulations! Your loan has been successfully processed. Please check your savings wallet balance for the loan amount.</p>
        <div class="mb-3">
            <button mdbBtn type="button" class="btn-sm app-primary-btn w-50" (click)="close()">Go to Account</button>
        </div>
        <span class="font-weight-normal">For any queries
            <a target="_blank" href="https://help.cashaa.com/contactus/" style="padding: 0px;">
                contact support</a></span> 
        
    </div>
</section>
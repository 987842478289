import { FiatTransactionType } from "../../enums/fiat-transaction-type";

export interface IRecipient {
  id: string;
  name: string;
  currency: string;
  accountNumber: string;
  iban: string;
  sortCode: string;
  type: FiatTransactionType;
  fiatAddressDetailsId: string;
  isrecipientSkiped: boolean;
  typeName: string;
  recipientType: string,
  isVerified?: boolean;
  network?: number;
  currencyType?: number;
  memo?:string;
  currencyId?: string;
  isCashaaAddress?: boolean;
}
export class Recipient implements IRecipient {
  constructor(
    public id: string,
    public name: string,
    public currency: string,
    public accountNumber: string,
    public iban: string,
    public sortCode: string,
    public type: FiatTransactionType,
    public fiatAddressDetailsId: string,
    public isrecipientSkiped: boolean,
    public typeName: string,
    public recipientType: string,
    public network?: number,
    public memo?: string
  ) {}
}

export interface IRecipientDelete {
  recipientId: string;
}

export class RecipientDelete implements IRecipientDelete {
  constructor(
    public recipientId: string
    ) {}
}

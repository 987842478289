import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ReceiveRoutingModule } from "./receive-routing.module";
import { ReceiveFundComponent } from "./receive-fund/receive-fund.component";
import { SharedModule } from "../shared/shared.module";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { MDBBootstrapModulesPro } from "ng-uikit-pro-standard";


@NgModule({
  declarations: [ReceiveFundComponent],
  imports: [
    CommonModule,
    ReceiveRoutingModule,
    SharedModule,
    ReactiveFormsModule,
    FormsModule,
    MDBBootstrapModulesPro.forRoot()
  ]
})
export class ReceiveModule { }

import { IBrokerFees, IQuote, IQuoteDetail, IQuoteId } from "./../../core/models/quote";
import { IConvert } from "./../../core/models/convert";
import { FiatFlow } from "./../../core/models/fIat/fiat-flow";
import { BehaviorSubject, Observable } from "rxjs";
import { environment } from "src/environments/environment";

import { Injectable } from "@angular/core";
import { IConvertMoneyRequest } from "../../core/models/convert/convert-request-model";
import { HttpClient, HttpResponse } from "@angular/common/http";
import { IConvertComplete } from "../../core/models/convert/convert-complete-model";
@Injectable({
  providedIn: "root",
})
export class ConvertService {
  private apiUrl = environment.api;

  /** TransferFeeType data emitter */
  private convertFlowDataEmitter = new BehaviorSubject<IConvert | undefined>(
    undefined
  );

  /** Currency Symbol emitter */
  private currencySymbolEmitter = new BehaviorSubject<string | undefined>(
    undefined
  );

  /** Convert Complete Data emitter */
  private convertCompleteDataEmitter = new BehaviorSubject<
    IConvertComplete | undefined
  >(undefined);

  /** Convert Complete Data emitter */
  private quoteDataEmitter = new BehaviorSubject<IQuoteDetail | undefined>(
    undefined
  );

  constructor(private http: HttpClient) {}

  convertMoneyQuote(
    data: IQuoteId
  ): Observable<{ statusCode: number; message: string }> {
    return this.http.post<{ statusCode: number; message: string }>(
      `${this.apiUrl}/trades/create`,
      data
    );
  }

  /**
   * Get fiatFlowPay data emitter
   */
  getConvertFlowdDataEmitter(): Observable<IConvert> {
    return this.convertFlowDataEmitter.asObservable();
  }

  /**
   * Get fiatFlowPay data emitter
   */
  getQuoteDataEmitter(): Observable<IQuoteDetail> {
    return this.quoteDataEmitter.asObservable();
  }

  emitQuoteData(data: IQuoteDetail): void {
    this.quoteDataEmitter.next(data);
  }

  /**
   * Emit fiatFlowPay data
   */
  emitConvertFlowData(data: IConvert): void {
    this.convertFlowDataEmitter.next(data);
  }

  /*** Get Convert Complete data emitter*/
  getConvertCompletedDataEmitter(): Observable<IConvertComplete> {
    return this.convertCompleteDataEmitter.asObservable();
  }

  /**
   * Emit Convert Complete data
   */
  emitConvertCompletedData(data: IConvertComplete): void {
    this.convertCompleteDataEmitter.next(data);
  }

  /*** Get send Currency data emitter
   */
  getCurrencySymbolEmitter(): Observable<string> {
    return this.currencySymbolEmitter.asObservable();
  }

  /**
   * Emit currency symbol data
   */
  emitCurrencySymbolData(data: string): void {
    this.currencySymbolEmitter.next(data);
  }

  getQuote(data: IQuote): Observable<{ statusCode: number; message: string }> {
    return this.http.post<{ statusCode: number; message: string }>(
      `${this.apiUrl}/trades/quote`,
      data
    );
  }

  getQuoteDetail(id: string): Observable<IQuoteDetail> {
    return this.http.get<IQuoteDetail>(`${this.apiUrl}/trades/getquote/${id}`);
  }

  getBrokerFees(symbol: string, userMode: string): Observable<IBrokerFees> {
    return this.http.get<IBrokerFees>(`${this.apiUrl}/transferfees/getbrokerfee/${symbol}/${userMode}`);
  }

  getMerchantRecognitionId(): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/exchangerates/getmerchantrecognitionid`);
  }
  
  getExchangeRateOnRamp(data) {
    return this.http.post<{ statusCode: number; message: string }>(
      `${this.apiUrl}/exchangerates/createonrampquote`,
      data
    );
  }

  getOnRampPairList(fromCurrencyId) : Observable<any> {
    return this.http.get<any>(
      `${this.apiUrl}`+ `/exchangerates/getonramppairlist?currencyId=${fromCurrencyId}`,
    );
  }

  getOnRampRate(fromCurrencyId, toCurrencyInfo, networkInfo) : Observable<any> {
    return this.http.get<any>(
      `${this.apiUrl}`+ `/currencies/getonramprate?fromCurrencyId=${fromCurrencyId}&toCurrencyId=${toCurrencyInfo.currencyId}&coinId=${toCurrencyInfo.coinId}&chainId=${networkInfo.chainId}&fiatType=${toCurrencyInfo.fiatType}`,
    );
  }

}

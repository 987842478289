export interface ICryptoAccountRequestModel {
    currency: string;
    networkType?: string;
}




export class CryptoAccountRequestModel implements ICryptoAccountRequestModel {
    constructor(
        public currency: string,
        public networkType?: string,


    ) { }
}
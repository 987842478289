import { ValidatorUtility } from "../../core/utility/validator.utility";
import { SettingsService } from "../services/settings.service";
import {
  Google2faDto,
  Enabletwofarequest,
  Createtwofa,
} from "../../core/models/EnableTwoFa";
import {
  Component,
  ElementRef,
  OnInit,
  QueryList,
  ViewChildren,
} from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { HttpErrorResponse } from "@angular/common/http";
import { ToastrService } from "ngx-toastr";
import {
  ENABLE_2FA,
  UNABLE_TO_PERFORM_ERROR,
  USER_EMAIL_PATH,
  USER_TWO_FACTOR_CODE,
} from "src/app/app.constants";
import { SBItemComponent } from "ng-uikit-pro-standard";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "cashaa-settings-subscription-plan",
  templateUrl: "./settings-subscription-plan.component.html",
  styleUrls: ["./settings-subscription-plan.component.scss"],
})
export class SettingsSubscriptionPlanComponent implements OnInit {
  status: boolean;
  isCollapsed = true;
  /** is opened */
  isOpen = false;
  constructor(
    private settingsService: SettingsService,
    private toastrService: ToastrService
  ) {}

  ngOnInit(): void {
    this.settingsService.subscriptionPlan().subscribe(
      (res) => {
        this.status = res.message === "success";
      },
      (err) => {
        if (err.error.message) {
          this.toastrService.error(err.error.message);
        } else {
          this.toastrService.error(UNABLE_TO_PERFORM_ERROR);
        }
      }
    );
  }
}

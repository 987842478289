import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { IMyOptions } from "ng-uikit-pro-standard";
import {
  IContactDetail,
  IUSPersonal,
} from "src/app/core/models/kyc-us-personal/us-personal";
import { ValidatorUtility } from "src/app/core/utility/validator.utility";
import { KycService } from "../services/kyc.service";

@Component({
  selector: "cashaa-kyc-us-personal-contact-detail",
  templateUrl: "./kyc-us-personal-contact-detail.component.html",
  styleUrls: ["./kyc-us-personal-contact-detail.component.scss"],
})
export class KycUsPersonalContactDetailComponent implements OnInit {
  public form: FormGroup;
  @Output()
  public continue = new EventEmitter<any>();
  isDataLoaded: boolean = false;
  myDatePickerOptions: IMyOptions = {
    minYear: 1900,
    maxYear: 2099,
  };
  constructor(private fb: FormBuilder, private kycService: KycService) {}

  ngOnInit(): void {
    this.createForm();
    this.kycService.getKycUsPersonalMobileCode().subscribe((data) => {
      this.form.controls["mobileCountryCode"].setValue(data);
    });

    this.kycService.getKycUsPersonal().subscribe((data: IUSPersonal) => {
      if (data !== undefined) {
        this.isDataLoaded = true;
        this.loadFormData(data);
      }
    });
  }

  createForm(): void {
    this.form = this.fb.group({
      mobileCountryCode: [{ value: "" }, [Validators.maxLength(5)]],
      mobileNumber: ["", [ValidatorUtility.Required]],
      emailAddress: ["", [ValidatorUtility.Required]],
    });
  }

  onSubmit(data: IContactDetail) {
    data.mobileCountryCode = this.form.get("mobileCountryCode").value;
    this.continue.emit(data);
  }

  loadFormData(data: IUSPersonal): void {
    if (data) {
      this.form.controls["mobileNumber"].setValue(
        data.contactDetail.mobileNumber
      );
      this.form.controls["emailAddress"].setValue(
        data.contactDetail.emailAddress
      );
    }
  }
}

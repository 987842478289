import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NavbarComponent } from "./components/navbar/navbar.component";
import { InputsModule, MDBBootstrapModulesPro } from "ng-uikit-pro-standard";
import { RouterModule } from "@angular/router";
import { FormInputComponent } from "./components/form-input/form-input.component";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { FormSelectComponent } from "./components/form-select/form-select.component";
import { FormSelectCountryComponent } from "./components/form-select-country/form-select-country.component";
import { MessageBoxComponent } from "./components/message-box/message-box.component";
import { FormSelectBalanceComponent } from "./components/form-select-balance/form-select-balance.component";
import { FormInputCurrencyComponent } from "./components/form-input-currency/form-input-currency.component";
import { CurrencyBoxComponent } from "./components/currency-box/currency-box.component";
import { SidenavComponent } from "./components/sidenav/sidenav.component";
import { FormSelectCurrencyComponent } from "./components/form-select-currency/form-select-currency.component";
import { FormSelectTransferTypeComponent } from "./components/form-select-transfer-type/form-select-transfer-type.component";
import { FormSelectCurrencySymbolComponent } from "./components/form-select-currency-symbol/form-select-currency-symbol.component";
import { FormSelectAccounttypeComponent } from "./components/form-select-accounttype/form-select-accounttype.component";
import { FormSelectPhonecodeComponent } from "./components/form-select-phonecode/form-select-phonecode.component";
import { FormInputDirectorRoleComponent } from "./components/form-input-director-role/form-input-director-role.component";
import { FormSelectWithdrawAddressComponent } from "./components/form-select-withdraw-address/form-select-withdraw-address.component";
import { FormSelectBusinessCategoryComponent } from "./components/form-select-business-category/form-select-business-category.component";
import { BalanceBoxComponent } from "./components/balance-box/balance-box.component";
import { FormSelectTransferCurrencyComponent } from "./components/form-select-transfer-currency/form-select-transfer-currency.component";
import { RecipientBoxComponent } from "./components/recipient-box/recipient-box.component";

import { NumDecimalDirective } from "./Directives/num-decimal.directive";
import { TimerComponent } from "./components/timer/timer.component";
import { NotFoundComponent } from "./components/not-found/not-found.component";
import { UnauthorizedComponent } from "./components/unauthorized/unauthorized.component";
import { FormSelectActivityTypeFilterComponent } from "./components/form-select-activity-type-filter/form-select-activity-type-filter.component";
import { FormInputSortcodeComponent } from "./components/form-input-sortcode/form-input-sortcode.component";
import { FormTextareaComponent } from "./components/form-textarea/form-textarea.component";
import { FormSelectHelpdeskCategoryComponent } from "./components/form-select-helpdesk-category/form-select-helpdesk-category.component";
import { ToastrModule } from "ngx-toastr";
import { GoogleAuthBoxesComponent } from "./components/google-auth-boxes/google-auth-boxes.component";
import { PasswordStrengthComponent } from "./components/password-strength/password-strength.component";
import { FormInputFileComponent } from './components/form-input-file/form-input-file.component';
import { ConfirmationBoxComponent } from './components/confirmation-box/confirmation-box.component';
import { WarningMemoBoxComponent } from './components/warning-memo-box/warning-memo-box.component';
// import { AccountBoxComponent } from "./components/account-box/account-box.component";
import { AccountBoxComponent } from "./components/account-box/account-box.component";
import { SocialMediaComponent } from "./components/social-media/social-media-component";
import { UnderMaintenanceComponent } from "./components/under-maintenance/under-maintenance.component";
import { TermsAndConditionsComponent } from './components/terms-and-conditions/terms-and-conditions.component';
import { FormSelectFilterComponent } from "./components/form-select-filter/form-select-filter.component";
import { FormSelectPlanComponent } from './components/form-select-plan/form-select-plan.component';
import { AccountBoxMobileComponent } from "./components/acount-box-mobile/account-box-mobile.component";
import { FormSelectCurrencyPairComponent } from "./components/form-select-currency-pair/form-select-currency-pair.component";
import { SwitchComponent } from "./switch/switch.component";
import { SafePipe } from "./pipes/safe.pipe";
import { FormSelectNetworkTypeComponent } from "./components/form-select-network-type/form-select-network-type.component";

@NgModule({
  declarations: [
    NavbarComponent,
    FormInputComponent,
    FormSelectComponent,
    FormSelectCountryComponent,
    MessageBoxComponent,
    FormSelectBalanceComponent,
    FormInputCurrencyComponent,
    CurrencyBoxComponent,
    SidenavComponent,
    FormSelectCurrencyComponent,
    FormSelectTransferTypeComponent,
    FormSelectCurrencySymbolComponent,
    FormSelectCurrencySymbolComponent,
    FormSelectAccounttypeComponent,
    FormSelectPhonecodeComponent,
    FormInputDirectorRoleComponent,
    FormSelectWithdrawAddressComponent,
    FormSelectBusinessCategoryComponent,
    FormSelectHelpdeskCategoryComponent,
    BalanceBoxComponent,
    FormSelectTransferCurrencyComponent,
    RecipientBoxComponent,
    NumDecimalDirective,
    TimerComponent,
    NotFoundComponent,
    UnauthorizedComponent,
    FormSelectActivityTypeFilterComponent,
    FormInputSortcodeComponent,
    FormTextareaComponent,
    FormSelectHelpdeskCategoryComponent,
    GoogleAuthBoxesComponent,
    PasswordStrengthComponent,
    FormInputFileComponent,
    ConfirmationBoxComponent,
    WarningMemoBoxComponent,
    AccountBoxComponent,
    SocialMediaComponent,
    UnderMaintenanceComponent,
    TermsAndConditionsComponent,
    FormSelectFilterComponent,
    FormSelectPlanComponent,
    AccountBoxMobileComponent,
    FormSelectCurrencyPairComponent,
    SwitchComponent,
    SafePipe,
    FormSelectNetworkTypeComponent
  ],
  imports: [
    CommonModule,
    MDBBootstrapModulesPro.forRoot(),
    ToastrModule.forRoot(),
    InputsModule.forRoot(),
    RouterModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    NavbarComponent,
    FormInputComponent,
    FormSelectComponent,
    FormSelectCountryComponent,
    FormSelectBalanceComponent,
    FormInputCurrencyComponent,
    CurrencyBoxComponent,
    SidenavComponent,
    FormSelectCurrencyComponent,
    FormSelectTransferTypeComponent,
    FormSelectCurrencySymbolComponent,
    FormSelectAccounttypeComponent,
    FormSelectPhonecodeComponent,
    FormInputDirectorRoleComponent,
    FormSelectWithdrawAddressComponent,
    BalanceBoxComponent,
    FormSelectBusinessCategoryComponent,
    FormSelectTransferCurrencyComponent,
    RecipientBoxComponent,
    NumDecimalDirective,
    TimerComponent,
    FormSelectActivityTypeFilterComponent,
    FormSelectHelpdeskCategoryComponent,
    FormInputSortcodeComponent,
    FormTextareaComponent,
    GoogleAuthBoxesComponent,
    PasswordStrengthComponent,
    FormInputFileComponent,
    AccountBoxComponent,
    SocialMediaComponent,
    UnderMaintenanceComponent,
    FormSelectFilterComponent,
    FormSelectPlanComponent,
    AccountBoxMobileComponent,
    FormSelectCurrencyPairComponent,
    SwitchComponent,
    SafePipe,
    FormSelectNetworkTypeComponent
  ],
})
export class SharedModule {}

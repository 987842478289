import { Component, Input, OnInit } from '@angular/core';
import { ILedgerList } from 'src/app/core/models/activity/recent-transaction-list';
import { ModalService } from 'src/app/core/services/modal/modal.service';


@Component({
  selector: 'cashaa-activity-credit-interest',
  templateUrl: './activity-credit-interest.component.html',
  styleUrls: ['./activity-credit-interest.component.scss']
})
export class ActivityCreditInterestComponent {
  @Input() ledger: ILedgerList;

  constructor(private modalService: ModalService) { }

  getCurrencyName(curr: string): string {
    if (curr === "AUSD") return "USD";
    if (curr === 'DOG') return 'DOGE';
    else return curr;
  }

}



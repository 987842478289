import { Component, OnInit, OnDestroy } from "@angular/core";
import { IUserBalance } from "src/app/core/models/user-balance";
import { RateWebsocketService } from "src/app/core/services/rate-websocket/rate-websocket.service";
import { TradeService } from "../services/trade.service";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Subscription } from "rxjs";
import { IMarketRate } from "src/app/core/models/market-rate";
import { ITradeChange } from "src/app/core/models/trade";
import { TRADE_MODE } from "src/app/app.constants";

@Component({
  selector: "cashaa-trade-sell-form",
  templateUrl: "./trade-sell-form.component.html",
  styleUrls: ["./trade-sell-form.component.scss"],
})
export class TradeSellFormComponent implements OnInit, OnDestroy {
  /** currency form group */
  form: FormGroup;

  /** From currency */
  fromCurrency = "";

  /** to currency */
  toCurrency = "";

  /** market data subscription */
  sub$: Subscription;

  /** Market rate */
  marketData: IMarketRate[] = [];

  constructor(
    private rateWebsocketService: RateWebsocketService,
    private tradeService: TradeService,
    private fb: FormBuilder
  ) {
    this.createForm();
  }

  ngOnInit(): void {
    this.listenToMarketData();
    this.emitTradeData(0);
  }

  /**
   * Create new form
   */
  createForm(): void {
    this.form = this.fb.group({
      fromAmount: [0],
      toAmount: [0],
    });
  }

  /**
   * Listen to market data change
   */
  listenToMarketData(): void {
    this.sub$ = this.rateWebsocketService
      .getMarketDataEmitter()
      .subscribe((data) => {
        this.marketData = data;
      });
  }

  /**
   * on currency change
   * @param change new currency with balance
   */
  onFromCurrencyChange(change: IUserBalance): void {
    this.fromCurrency = change.currencyType;
    this.convertCurrencyRates(this.form.controls.fromAmount.value, true);
  }

  /**
   * on currency change
   * @param change new currency with balance
   */
  onToCurrencyChange(change: IUserBalance): void {
    this.toCurrency = change.currencyType;
    this.convertCurrencyRates(this.form.controls.fromAmount.value, true);
  }

  /**
   * On from Currency amount change
   */
  onFromCurrencyAmountChange(newAmount: number): void {
    this.convertCurrencyRates(newAmount, true);
  }

  /**
   * on to currency amount change
   * @param change new currency
   */
  onToCurrencyAmountChange(newAmount: number): void {
    this.convertCurrencyRates(newAmount, false);
  }

  /**
   * Convert currency changes
   */
  convertCurrencyRates(amount: number, from: boolean): void {
    const found = this.marketData.filter(
      (data) =>
        data.From === this.fromCurrency &&
        data.To === this.toCurrency &&
        data.side === 2
    );

    let rate = 0;
    if (found.length > 0) {
      rate = found[0].Rate;
    }

    if (from) {
      const newValue = rate ? (amount || 0) / rate : 0;
      this.form.controls.toAmount.setValue(newValue);
    } else {
      const newValue = rate ? (amount || 0) * rate : 0;
      this.form.controls.fromAmount.setValue(newValue);
    }
    this.emitTradeData(rate);
  }

  /**
   * Emit trade data
   */
  emitTradeData(rate: number): void {
    const data: ITradeChange = {
      from: this.fromCurrency,
      to: this.toCurrency,
      mode: TRADE_MODE.SELL,
      fromAmount: this.form.controls.fromAmount.value,
      toAmount: this.form.controls.toAmount.value,
      error: this.form.invalid,
      rate,
    };
    this.tradeService.emitTradeChange(data);
  }

  /**
   * On destroy life cycle
   */
  ngOnDestroy(): void {
    if (this.sub$) {
      this.sub$.unsubscribe();
    }
  }
}

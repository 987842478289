import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
  ChangeDetectorRef,
  OnChanges,
} from "@angular/core";
import {
  CRYPTO_CURRENCY,
  CRYPTO_CURRENCY_NAMES,
  WALLET_CURRENCY,
} from "src/app/app.constants";
import { RateWebsocketService } from "src/app/core/services/rate-websocket/rate-websocket.service";
import { Subscription, Subject } from "rxjs";

@Component({
  selector: "cashaa-currency-box",
  templateUrl: "./currency-box.component.html",
  styleUrls: ["./currency-box.component.scss"],
})
export class CurrencyBoxComponent implements OnInit, OnChanges, OnDestroy {
  /** currency to show */
  @Input() currency = CRYPTO_CURRENCY.CAS;

  /** Convert currency to */
  @Input() convert = WALLET_CURRENCY.GBP;

  /** if current box is selected */
  @Input() selected = false;

  /** reset data */
  @Input() reset: Subject<void>;

  /** on element clicked */
  @Output() clicked: EventEmitter<string> = new EventEmitter<string>();

  /** Currency full name */
  currencyName = "";

  /** current rate */
  currentRate = 0;

  /** market data subscription */
  sub$: Subscription;

  /** reset subscription */
  subReset$: Subscription;

  constructor(
    private rateWebsocketService: RateWebsocketService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    //this.listenToMarketData();
  }

  ngOnChanges(): void {
    this.currencyName = CRYPTO_CURRENCY_NAMES[this.currency];
    this.listenToResetData();
  }

  /**
   * Listen to reset data
   */
  listenToResetData(): void {
    if (this.reset && !this.subReset$) {
      this.subReset$ = this.reset.subscribe(() => {
        this.currentRate = 0;

        // re subscribe to market data
        if (this.sub$) {
          this.sub$.unsubscribe();
          this.listenToMarketData();
        }
      });
    }
  }

  /**
   * Listen to market data change
   */
  listenToMarketData(): void {
    this.sub$ = this.rateWebsocketService
      .getMarketDataEmitter()
      .subscribe((data) => {
        const found = data.filter(
          (rate) =>
            rate.side === 1 &&
            rate.From === this.convert &&
            rate.To === this.currency
        );
        if (found.length > 0) {
          this.currentRate = found[0].Rate;
          this.cd.detectChanges();
        }
      });
  }

  /**
   * On selection of currency
   */
  onCurrencySelect(): void {
    this.clicked.emit(this.currency);
  }

  /**
   * On destroy life cycle
   */
  ngOnDestroy(): void {
    if (this.sub$) {
      this.sub$.unsubscribe();
    }
    if (this.subReset$) {
      this.subReset$.unsubscribe();
    }
  }
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { IApiListRespose } from 'src/app/core/models/api-manager/api-manager';
import { CommonSavedData } from 'src/app/shared/services/commonSavedData.service';
import { ApiManagerService } from '../services/api-manager.service';

@Component({
  selector: 'cashaa-api-manager-operations',
  templateUrl: './api-manager-operations.component.html',
  styleUrls: ['./api-manager-operations.component.scss']
})
export class ApiManagerOperationsComponent implements OnInit, OnDestroy {

  apiData: IApiListRespose[] = [];
  sub: Subscription;
  loader: boolean;
  constructor(private apiManagerService: ApiManagerService, private commonSavedData: CommonSavedData, private toastrService: ToastrService) { }

  ngOnInit(): void {
    this.loadApiData();
    this.sub = this.commonSavedData.getConfirmationBoxStatus().subscribe((status: boolean) => {
      if (status) {
        this.loadApiData();
      }
    });
  }

  loadApiData(): void {
    this.loader = true;
    this.apiManagerService.getAllApiList().subscribe((data: IApiListRespose[]) => {
      this.loader = false;
      this.apiData = data;
    }, err => {
      this.toastrService.error(err.error.message);
      this.loader = false;
    });
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}

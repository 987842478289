<div *ngIf="form" [formGroup]="form" class="position-relative text-left sm-input">
  <label *ngIf="label" class="heading" [ngClass]=" getErrorMessage() ? 'sm-label-red' : ''">{{ label }}</label>
  <textarea [rows]="rows" mdbInput *ngIf="!control?.disabled || !onlyValue" [formControlName]="name"
    class="form-control" [ngClass]="{
      'error-border': control?.invalid && (control?.dirty || control?.touched),
      'mb-5': getErrorMessage()?.length > 50
    }" [placeholder]="placeholder"></textarea>
  <p *ngIf="control?.disabled && onlyValue" class="font-weight-bolder text-primary-dark">
    {{ control?.value }}
  </p>
  <mdb-error *ngIf="control?.invalid && (control?.dirty || control?.touched)" [ngClass]="label ? 'hasLabel' : ''">
    {{ getErrorMessage() }}</mdb-error>
</div>

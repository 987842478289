<cashaa-sidenav>
    <section class="mh-100">
        <div class="bg-white p-4 sm-media-padding">
            <div class="d-flex sm-phone-display-none mr-3" style="height: 47px;">
                <button (click)="goBack()" mdbBtn type="button"
                    class="cursor-pointer btn-sm app-primary-btn">Back</button>
            </div>
        </div>
    </section>

<div class="card p-4 mt-4 mx-auto w-50">
    <form [formGroup]="updateDomainForm" class="my-4">
        <div class="col-sm-6 my-3">
            <cashaa-form-input name="domainURL" type="domainURL" [form]="updateDomainForm" [control]="updateDomainForm.controls.domainURL"
            placeholder="Enter Domain URL" label="Domain URL">
        </cashaa-form-input>
        </div>
        <div class="col-sm-6 my-3">
            <label class="heading">Domain Verification Code</label>
            <button mdbBtn block="true" type="button"
                class="rounded-sm position-relative btn btn-primary waves-effect waves-light btn-block"
                mdbWavesEffect [disabled]="!updateDomainForm.controls.domainURL.value" (click)="generateCode()">
                Generate
            </button>
            <input type="text" class="form-control sm-input-height" placeholder="Enter Domain Verification Code" formControlName="domainVerificationCode">
            
        </div>
        <div class="col my-3">
            <label class="heading">Domain Verification File</label>
            <div>
                <a class="link-primary" (click)="download()">
                    Download with link
                </a>
            </div>
            </div>
            <div class="col">
            <mdb-checkbox formControlName="makeSureCheck">
                <p class="font-weight-normal">Make sure you have downloaded file from above download link.</p>
            </mdb-checkbox>
        </div>
        <div class="ml-1 mt-4 sm-pos-right">
            <button mdbBtn (click)="onFormSubmit()" block="true" type="button"
                class="rounded-sm position-relative btn btn-primary waves-effect waves-light btn-block"
                mdbWavesEffect [disabled]="updateDomainForm.invalid || !isChecked">
                Update Domain
            </button>
        </div>
    </form>
</div>

</cashaa-sidenav>
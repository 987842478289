import { Component, OnInit } from "@angular/core";
import {
  ActivatedRoute,
  Router,
  NavigationEnd,
  NavigationStart,
} from "@angular/router";
import { APP_ROUTES } from "./app.constants";
import { RateWebsocketService } from "./core/services/rate-websocket/rate-websocket.service";

@Component({
  selector: "cashaa-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  /** Show header or not */
  showHeader = false;
  showSocailMediaIcon = false;
  constructor(
    private router: Router,
    private rateWebsocketService: RateWebsocketService
  ) { }

  ngOnInit(): void {
    this.listenToRouteChange();
    // this.rateWebsocketService.initiateConnection();
  }

  /**
   * Listen to route change and
   * do some action accordingaly
   */
  listenToRouteChange(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (
          event.url.startsWith(APP_ROUTES.ACCOUNT_BASE) ||
          event.urlAfterRedirects.startsWith(APP_ROUTES.ACCOUNT_BASE)
            ||  event.url.startsWith(APP_ROUTES.ACTIVITY_BASE) ||  event.urlAfterRedirects.startsWith(APP_ROUTES.ACTIVITY_BASE)
        ) {
          // this.showHeader = false;
          this.showSocailMediaIcon = false;
        } else {
          // this.showHeader = false;
          this.showSocailMediaIcon = true;
        }
      }
    });
  }
}

<div>
  <h6 class="font-weight-bold mb-4">
    Sell From
  </h6>
  <div class="mb-5">
    <cashaa-form-select-balance
      [type]="'crypto'"
      [mode]="'sell'"
      (changed)="onFromCurrencyChange($event)"
    ></cashaa-form-select-balance>
  </div>
  <h6 class="font-weight-bold mb-4">
    Select Receiving Wallet
  </h6>
  <div class="mb-5">
    <cashaa-form-select-balance
      [type]="'wallet'"
      [mode]="'sell'"
      (changed)="onToCurrencyChange($event)"
    ></cashaa-form-select-balance>
  </div>
  <h6 class="font-weight-bold mb-3">
    Amount
  </h6>
  <div class="d-flex align-items-center">
    <div class="flex-1">
      <cashaa-form-input-currency
        [currency]="fromCurrency"
        [form]="form"
        [control]="form?.controls?.fromAmount"
        (changed)="onFromCurrencyAmountChange($event)"
        name="fromAmount"
      ></cashaa-form-input-currency>
    </div>
    <mdb-icon fas icon="exchange-alt" class="exchange-arrow"></mdb-icon>
    <div class="flex-1">
      <cashaa-form-input-currency
        [currency]="toCurrency"
        [form]="form"
        [control]="form?.controls?.toAmount"
        (changed)="onToCurrencyAmountChange($event)"
        name="toAmount"
      ></cashaa-form-input-currency>
    </div>
  </div>
</div>

<cashaa-sidenav>
  <section class="mh-100">
    <div class="bg-white p-4">
      <!-- <h6 class="heading">Loan repayment</h6> -->
      <!-- <div class="switch-sec font-weight-normal px-4 mt-3"> Hide Zero Balance Assets <label class="switch"> 
        <input checked="true" type="checkbox" [(ngModel)]="hideZeroBalance" (click)="filterNonZeroBalance()"
        >
          <span class="slider round"></span> </label>
      </div> -->
      <div class="d-flex mr-3" style="height: 48px;"></div>
    </div>
  </section>

  <div *ngIf="!isBalance && !loading && hideZeroBalance" class="text-center mt-4">
    <h5 class="heading">Oops.. you have no Balance</h5>
    <img class="mb-4" src="../../../assets/images/zero-bal.png" alt="zero balance">
    <!-- <button class="app-primary-btn d-block m-auto px-5" (click)="navigateToDeposite()">Deposit</button> -->
  </div>

  <!-- <div class="mx-5"> -->
  <div class="mt-4 mx-5 row justify-content-between" *ngIf="!isMobile">
    <div class="col-3 bg-white portfolio p-3">
      <img src="../../../assets/images/account/money-bag.svg" alt="Portfolio balance">
      <span class="ml-1 mb-0 text-gray font-weight-bolder">
        Portfolio Balance
      </span>
      <h5 class="heading mt-3">${{userCreditBalance?.totalBalanceInUSD | number: "0.0-2"}}</h5>
      <div class="d-flex justify-content-between align-item-end">
        <i class="fa fa-lock text-gray text-hold">&nbsp;${{creditlineData?.lockedbalance}}</i>
        <img src="../../../assets/images/account/bar-chart.svg" alt="">
      </div>
    </div>
    <div class="col-3 bg-white portfolio p-3">
      <img src="../../../assets/images/interest-earned.svg
        " alt="">
      <span class="ml-1 mb-0 text-gray font-weight-bolder">
        Interest Earned
      </span>
      <h5 class="heading mt-3">${{earninterestbalance | number: "0.0-2"}}</h5>
      <div class="d-flex justify-content-between text-interest-earned font-weight-600 align-item-end">
        <div></div>
        <img src="../../../assets/images/account/line-chart.svg" alt="">
      </div>
    </div>
    <div class="col-3 bg-white portfolio p-3">
      <img src="../../../assets/images/plan/{{creditlineData?.loyalityPlan}}.svg" alt="" width="45px">
      <span class="ml-2 mb-0 text-gray font-weight-bolder">
        Loyalty Level
      </span>
      <div class="d-flex justify-content-between">
        <div class="font-weight-bold my-2" style="font-size:20px">{{creditlineData?.loyalityPlan}}</div>
        <a *ngIf="creditlineData?.sinceCount > 0 && creditlineData?.sinceCount !== 1" class="time"
          routerLink="/wallet/portfolio-history">
          Since {{creditlineData?.sinceCount}} days
        </a>
        <a *ngIf="creditlineData?.sinceCount == 1" class="time" routerLink="/wallet/portfolio-history">
          Since {{creditlineData?.sinceCount}} day
        </a>
        <a *ngIf="creditlineData?.sinceCount == 0 || creditlineData?.sinceCount < 0" class="time"
          routerLink="/wallet/portfolio-history">Since Today</a>
      </div>
        <button style="margin-top: 60px;" mdbBtn type="button" class="btn-sm app-secondary-btn mx-auto" (click)="goToPlans()">Upgrade
          <i class="fa fa-star" aria-hidden="true"></i>
        </button>
    </div>
    <div class="col-3 bg-white portfolio p-3">
      <div class="ml-2 mb-0 text-gray font-weight-bolder">
        <p class="mb-0">Total Credit Line</p>
        <h5 class="heading">${{userCreditBalance?.totalCreditInUSD | number: "0.0-2"}}</h5>
      </div>
      <div class="ml-2 mt-4 mb-0 text-gray font-weight-bolder">
        <p class="mb-0">Available Credit Line</p>
        <h5 class="heading">${{userCreditBalance?.totalAvailableCreditInUSD | number: "0.0-2"}}</h5>
      </div>
      <div class="ml-2 mt-4 mb-0 text-gray font-weight-bolder">
        <p class="mb-0">Availed Credit</p>
        <h5 class="heading">${{userCreditBalance?.totalAvailedCreditInUSD | number: "0.0-2"}}</h5>
      </div>
    </div>

  </div>
  <!-- </div> -->
  <div class="mt-4 " *ngIf="isMobile">
    <div class="row m-5">
      <div class="col mx-2 bg-white portfolio p-3">
        <img src="../../../assets/images/account/money-bag.svg" alt="Portfolio balance">
      <span class="ml-1 mb-0 text-gray font-weight-bolder">
        Portfolio Balance
      </span>
        <h5 class="heading mt-3">${{userCreditBalance?.totalBalanceInUSD | number: "0.0-2"}}</h5>
        <div class="d-flex justify-content-between align-item-end">
          <i class="fa fa-lock text-gray text-hold">&nbsp;${{creditlineData?.lockedbalance}}</i>
          <img src="../../../assets/images/account/bar-chart.svg" alt="">
        </div>
      </div>
      <div class="col mx-2 bg-white portfolio p-3">
        <img src="../../../assets/images/interest-earned.svg
      " alt="">
        <span class="ml-1 mb-0 text-gray font-weight-bolder">
          Interest Earned
        </span>
        <h5 class="heading mt-3">${{earninterestbalance | number: "0.0-2"}}</h5>
        <div class="d-flex justify-content-between align-item-end text-interest-earned font-weight-600">
          <div> </div>
          <img src="../../../assets/images/account/line-chart.svg" alt="">
        </div>
      </div>
    </div>
    <div class="row m-5">
      <div class="col mx-2 bg-white portfolio p-3">
        <img src="../../../assets/images/plan/{{creditlineData?.loyalityPlan}}.svg" alt="" width="45px">
        <span class="ml-2 mb-0 text-gray font-weight-bolder">
          Loyalty Level
        </span>
        <div class="d-flex justify-content-between">
          <div class="font-weight-bold my-2" style="font-size:20px">{{creditlineData?.loyalityPlan}}</div>
          <div *ngIf="creditlineData?.sinceCount > 0; else today" class="time"><a
              routerLink="/wallet/portfolio-history">Since {{creditlineData?.sinceCount}} days</a></div>
          <ng-template #today>
            <div class="time"><a routerLink="/wallet/portfolio-history">Since Today</a></div>
          </ng-template>
        </div>
        <button mdbBtn style="margin-top: 60px;" type="button" class="btn-sm app-secondary-btn mx-auto" (click)="goToPlans()">Upgrade
          <i class="fa fa-star" aria-hidden="true"></i>
        </button>
      </div>
      <div class="col mx-2 bg-white portfolio p-3">
        <div class="ml-2 mb-0 text-gray font-weight-bolder">
          <p class="mb-0">Total Credit Line</p>
          <h5 class="heading">${{userCreditBalance?.totalCreditInUSD | number: "0.0-2"}}</h5>
        </div>
        <div class="ml-2 mt-4 mb-0 text-gray font-weight-bolder">
          <p class="mb-0">Available Credit Line</p>
          <h5 class="heading">${{userCreditBalance?.totalAvailableCreditInUSD | number: "0.0-2"}}</h5>
        </div>
        <div class="ml-2 mt-4 mb-0 text-gray font-weight-bolder">
          <p class="mb-0">Availed Credit</p>
          <h5 class="heading">${{userCreditBalance?.totalAvailedCreditInUSD | number: "0.0-2"}}</h5>
        </div>
      </div>
    </div>


  </div>
  <!-- <div class="p-4" [hidden]="loading" *ngIf="isMobile">
    <div class="px-3 d-flex justify-content-between">
        <h6 class="heading">Trending Coins</h6>
        <div class="d-flex">
            <span class="content mr-4" style="color: #3570F4;"><a routerLink="/statement" style="text-decoration: underline;">View Statement</a></span>
            <div class="mr-2">
                <mdb-icon (click)="previous()" class="arrow-icon arrow-left" fas icon="chevron-left"></mdb-icon>
            </div>
            <div>
                <mdb-icon (click)="next()" class="arrow-icon arrow-right" fas icon="chevron-right"></mdb-icon>
            </div>
        </div>
    </div>
    <mdb-carousel #slide [isControls]="false" class="carousel-multi-item multi-animation" [type]="'carousel-multi-item'" [animation]="'slide'" [interval]="'0'">
        <mdb-carousel-item *ngFor="let item of slides">
          
            <div class="col-12 col-md-3 col-lg-4 col-xl-5" *ngFor="let info of item">
              <p>{{info}}</p>
              <cashaa-account-first-sec [info]="info"></cashaa-account-first-sec>
            </div>
        </mdb-carousel-item>
    </mdb-carousel>
</div> -->
  <div class="p-4 text-center" [hidden]="!loading">
    <mdb-spinner spinnerColor="blue"></mdb-spinner>
  </div>

  <div class="d-flex justify-content-between mx-5 mt-4">
    <div class="d-flex credit-line p-2" (click)="openCreditLine()" [ngClass]="{'disable': !isBorrowEnabled}">
      <div class="img-div">
        <img class="" src="../../../assets/images/account/credit-line.svg" />
      </div>
      <div class="img-text">
        <h5 class="heading mb-0">
          Borrow
        </h5>
        <p class="mb-0">with Crypto, Cash or Stablecoins</p>
        <p *ngIf="!isBorrowEnabled" class="text-danger">Your credit line is zero.</p>
      </div>
    </div>
    <div class="d-flex repay p-2" routerLink="/repay">
      <div class="img-div">
        <img class="" src="../../../assets/images/account/repay.svg" />
      </div>
      <div class="img-text">
        <h5 class="heading mb-0">
          Repay
        </h5>
        <p class="mb-0">with Crypto, Cash or Stablecoins</p>
      </div>
    </div>
    <div class="d-flex exchange p-2" routerLink="/convert">
      <div class="img-div">
        <img class="" src="../../../assets/images/account/exchnage.svg" />
      </div>
      <div class="img-text">
        <h5 class="heading mb-0">
          Exchange
        </h5>
        <p class="mb-0">Buy, Sell & Swap</p>
      </div>
    </div>

  </div>
  <div class="d-flex justify-content-between mx-5 mt-4 p-3 bg-white">
    <!-- <div class="switch-sec font-weight-normal">
      <div [ngClass]="{'checked' : !isEarn}"> Borrow</div><label class="switch-sm" [ngClass]="{'checked' : !isEarn}">
        <input checked="true" type="checkbox" (click)="showEarnBorrow()">
        <span class="slider-sm round"></span></label><div [ngClass]="{'checked' : isEarn}">Earn</div>
    </div> -->
    <div class="switch-sec  font-weight-normal"> <app-switch id="borrowEarn" size="small" (change)="showEarnBorrow()" [(ngModel)]="isEarn"
      [labelOn]="'Borrow'" [labelOff]="'Earn'" ></app-switch>
    </div>

  <div *ngIf="!isEarn" class="switch-sec  font-weight-normal mr-2 ml-5"> <app-switch id="borrowMore" size="small" name="skip_notification" (change)="sortNoInterestRate()" [(ngModel)]="isBorrowMore"
    [labelOn]="'Borrow at 0%*'" [labelOff]="'Borrow more'" ></app-switch>
  </div>
 

    <div class="switch-sec font-weight-normal mr-2 ml-5"> Hide currencies with 0 balance <label class="switch">
        <input checked="true" type="checkbox" [(ngModel)]="hideZeroBalance" (click)="filterNonZeroBalance()">
        <span class="slider round"></span> </label>
    </div>
  </div>
  <!-- 2nd row's column -->
  <div class="row justify-content-between mx-5 mt-4 p-3 bg-white heading-assets"  *ngIf="!isMobile && !showUpgradeOption">
    <div class="column-heading mr-auto col">
      Assets
    </div>
    <div *ngIf="!isEarn" class="column-heading col text-center ml-5">
      Credit Line
    </div>
    <div *ngIf="!isEarn" class="column-heading col text-center">
      LTV
    </div>
    <div *ngIf="!isEarn" class="column-heading text-center col" style="margin-right:26rem;">ROI% (Annually)</div>
    <div *ngIf="isEarn" class="column-heading ml-5" style="margin-right:20.5rem;">
      Earn Interest
    </div>
  </div>


  <div class="d-flex bg-white mx-5 p-3"
    *ngIf="!isMobile && allCurrencyList.length > 0 && !loading && (!hideZeroBalance || isBalance)">
  </div>
  <section *ngIf="!isMobile && !loading && (!hideZeroBalance || isBalance) && !showUpgradeOption" #cryptoSection
    class="banks crypto-section pr-1 mx-5 mb-5 sm-width-phone-700">
    <mdb-accordion *ngFor="let item of allCurrencyList; let idx = index">
      <mdb-accordion-item #collapsesCrypto [ngClass]="selectedCrypto == idx ? '' : 'bg-unset'">
        <!-- <mdb-accordion-item-head > -->
        <account-box [idx]="idx" [isEarn]="isEarn" [currency]="item.symbol" [balance]="item.availableBalance"
          [userBalanceInUSD]="item.userBalanceInUSD" [creditBalanceInUSD]="item.userCreditBalanceInUSD"
          [currencyId]="item.currencyId" [isDepositActive]="item.isDepositActive" [isSendActive]="item.isSendActive"
          [item]="item" (isLoadingMDBAccordionBody)="getCryptoAccountDetails($event)">
        </account-box>
        <!-- </mdb-accordion-item-head> -->
        <mdb-accordion-item-body>
          <div class="custom-mx-px">
            <mdb-tabset #staticTabs [buttonClass]="'nav md-pills pills-primary mb-2'" [justified]="true"
              class="recipientTab">
              <mdb-tab heading={{getTabHeaderData1(item)}} *ngIf="item.symbol !== 'CAS'">
                <div class="background px-5 py-4" *ngIf="!cryptoLoading && !accountDetailsCryptoData">
                  <h4 class="text-dark mb-4 font-weight-bolder">
                    Get your {{ getCurrencyName(item.symbol) }} address
                  </h4>
                  <p class="text-dark font-weight-normal">
                    With {{ getCurrencyName(item.symbol) }} address, people can send
                    {{ getCurrencyName(item.symbol) }} to your account.
                  </p>
                  <div class="mt-4">
                    <button class="btn btn-outline-primary rounded text-primary btn-sm"
                      (click)="onAccountReadyPage(item, 'Crypto')">
                      Get address
                    </button>
                  </div>
                </div>
                <div class="px-5 mx-5 mt-3 text-center" *ngIf="cryptoLoading">
                  <mdb-spinner spinnerColor="blue"></mdb-spinner>
                </div>
                <div *ngIf="accountDetailsCryptoData">
                  <div class="py-4 pb-0">
                    <p class="font-weight-bold text-primary-dark">
                      Your Blockchain details to receive {{ item.symbol === "DOG" ? "DOGE" : item.symbol }}
                    </p>
                    <p class="font-weight-normal text-dark">
                      <!-- Use your {{ item.symbol }} address to receive
                      {{ item.symbol }}. -->
                    </p>
                  </div>
                  <div class="row background px-5 py-4">
                    <div class="d-flex justify-content-center w-100">
                      <div class="w-75">
                        <h5 class="text-dark mb-3 font-weight-bolder">
                          <!-- {{ item.symbol }} Deposit -->
                        </h5>
                      </div>
                      <div class="f-shrink-1 p-1" style="background-color: white;"
                        *ngIf="item.symbol === 'BNB' || item.symbol==='CAS' || item.symbol==='XRP'">
                        <span class="mb-3 font-weight-normal">
                          <mdb-icon far icon="question-circle"></mdb-icon> <a class="p-1" target="_blank"
                            routerLinkActive="customactive"
                            href="https://help.cashaa.com/knowledgebase/how-to-deposit-cryptocurrencies-to-your-cashaa-account/">How
                            to deposit?</a>
                        </span>
                      </div>
                    </div>
                    <div class="col-md-8">
                      <div class="mb-5">
                        <div class="input-group mb-3 sm-nowrap qr-code-inp-grp">
                          <div class="sm-qr-box sm-padding-qr-box">
                            <div class="sm-text-center">
                              <h4 class="sm-heading-text">Address</h4>
                            </div>
                            <div class="sm-qr-box-inner">
                              <qr-code [size]="200" [value]="accountDetailsCryptoData.address"></qr-code>
                            </div>
                            <div class="sm-qr-input-box">
                              <span class="sm-spam-text sm-span-text-padding">{{accountDetailsCryptoData.address
                                }}</span>
                              <div class="input-group-append">
                                <span class="sm-input-group-text"><i mdbTooltip="Copy address" placement="top"
                                    class="fas fa-clone sm-copy-color" [ngClass]="copied ? 'text-primary' : ''"
                                    (click)="copyAddress()"></i></span>
                              </div>
                            </div>
                          </div>
                          <div *ngIf="item.symbol == 'CAS' || item.symbol == 'BNB' || item.symbol == 'XRP'"
                            class="input-group mb-3 pl-4 sm-border-line">
                            <div class="sm-qr-box">
                              <div class="sm-text-center">
                                <h4 class="sm-heading-text">{{item.symbol == 'XRP'
                                  ? 'TAG': 'MEMO' }}</h4>
                              </div>
                              <div class="sm-qr-box-inner">
                                <qr-code [size]="200" [value]="accountDetailsCryptoData.memo"></qr-code>
                              </div>
                              <div class="sm-qr-input-box">
                                <span class="sm-spam-text">{{accountDetailsCryptoData.memo}}</span>
                                <div class="input-group-append">
                                  <span class="sm-input-group-text"><i mdbTooltip="Copy memo" placement="top"
                                      class="fas fa-clone sm-copy-color" [ngClass]="copiedMemo ? 'text-primary' : ''"
                                      (click)="copyMemoAddress()"></i></span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <p class="text-dark font-weight-bolder">Important</p>
                      <ul class="text-dark font-weight-bolder ul-not">
                        <li>
                          Send only {{getContentData(item.symbol)}} to this
                          address. Sending
                          any other coins or token to this address may result in the
                          loss of your deposit.
                        </li>
                        <li>
                          Coins will be deposited immediately after 3 network
                          confirmation.
                        </li>
                        <li>
                          Minimum deposit amount is $10 equivalent. Amounts less than this will be lost.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </mdb-tab>
      
              <mdb-tab heading="BEP-20" *ngIf="!(item.symbol === 'BTC' || item.symbol === 'BNB')">
                <div class="background px-5 py-4" *ngIf="!cryptoLoading && !accountDetailsETHCryptoData">
                  <h4 class="text-dark mb-4 font-weight-bolder">
                    Get your {{ getCurrencyName(item.symbol) }} address
                  </h4>
                  <p class="text-dark font-weight-normal">
                    With {{ getCurrencyName(item.symbol) }} address, people can send
                    {{ getCurrencyName(item.symbol) }} to your account.
                  </p>
                  <div class="mt-4">
                    <button class="btn btn-outline-primary rounded text-primary btn-sm"
                      (click)="onAccountReadyPage(item, 'Crypto', 'BEP-20')">
                      Get address
                    </button>
                  </div>
                </div>
                <div class="px-5 mx-5 mt-3 text-center" *ngIf="cryptoLoading">
                  <mdb-spinner spinnerColor="blue"></mdb-spinner>
                </div>
                <div *ngIf="accountDetailsETHCryptoData">
                  <div class="py-4 pb-0">
                    <p class="font-weight-bold text-primary-dark">
                      Your Blockchain details to receive {{ item.symbol }}
                    </p>
                    <p class="font-weight-normal text-dark">
                      <!-- Use your {{ item.symbol }} address to receive
                      {{ item.symbol }}. -->
                    </p>
                  </div>
                  <div class="row background px-5 py-4">
                    <div class="d-flex justify-content-center w-100">
                      <div class="w-75">
                        <h5 class="text-dark mb-3 font-weight-bolder">
                          <!-- {{ item.symbol }} Deposit -->
                        </h5>
                      </div>
                      <div class="f-shrink-1 p-2" style="background-color: white;"
                        *ngIf="item.symbol === 'BNB' || item.symbol==='CAS' || item.symbol==='XRP'">
                        <span class="mb-3 font-weight-normal">
                          <mdb-icon far icon="question-circle"></mdb-icon> <a class="p-2" target="_blank"
                            routerLinkActive="customactive"
                            href="https://help.cashaa.com/knowledgebase/how-to-deposit-cryptocurrencies-to-your-cashaa-account/">How
                            to deposit?</a>
                        </span>
                      </div>
                    </div>
                    <div class="col-md-8">
                      <div class="mb-5">
                        <div class="input-group mb-3 sm-nowrap">
                          <div class="sm-qr-box sm-padding-qr-box">
                            <div class="sm-text-center">
                              <h4 class="sm-heading-text">Address</h4>
                            </div>
                            <div class="sm-qr-box-inner">
                              <qr-code [size]="200" [value]="accountDetailsETHCryptoData.address"></qr-code>
                            </div>
                            <div class="sm-qr-input-box">
                              <span class="sm-spam-text sm-span-text-padding">{{accountDetailsETHCryptoData.address
                                }}</span>
                              <div class="input-group-append">
                                <span class="sm-input-group-text"><i mdbTooltip="Copy address" placement="top"
                                    class="fas fa-clone sm-copy-color" [ngClass]="copied ? 'text-primary' : ''"
                                    (click)="copyAddressETH()"></i></span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <p class="text-dark font-weight-bolder">Important</p>
                      <ul class="text-dark font-weight-bolder ul-not">
                        <li>
                          Send only {{item.symbol}} (BEP-20) to this
                          address. Sending
                          any other coins or token to this address may result in the
                          loss of your deposit.
                        </li>
                        <li>
                          Coins will be deposited immediately after 3 network
                          confirmation.
                        </li>
                        <li>
                          Minimum deposit amount is $10 equivalent. Amounts less than this will be lost.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </mdb-tab>

              <mdb-tab heading="{{getTabHeaderData3(item)}}" *ngIf="item.symbol === 'USDT'">
                <div class="background px-5 py-4" *ngIf="!cryptoLoading && !accountDetailsCryptoData?.tronAddress">
                  <h4 class="text-dark mb-4 font-weight-bolder">
                    Get your {{ getCurrencyName(item.symbol) }} address
                  </h4>
                  <p class="text-dark font-weight-normal">
                    With {{ getCurrencyName(item.symbol) }} address, people can send
                    {{ getCurrencyName(item.symbol) }} to your account.
                  </p>
                  <div class="mt-4">
                    <button class="btn btn-outline-primary rounded text-primary btn-sm"
                      (click)="onAccountReadyPage(item, 'Crypto', getTabHeaderData3(item))">
                      Get address
                    </button>
                  </div>
                </div>
                <div class="px-5 mx-5 mt-3 text-center" *ngIf="cryptoLoading">
                  <mdb-spinner spinnerColor="blue"></mdb-spinner>
                </div>
                <div *ngIf="accountDetailsCryptoData && accountDetailsCryptoData?.tronAddress">
                  <div class="py-4 pb-0">
                    <p class="font-weight-bold text-primary-dark">
                      Your Blockchain details to receive {{ item.symbol === "DOG" ? "DOGE" : item.symbol }}
                    </p>
                    <p class="font-weight-normal text-dark">
                      <!-- Use your {{ item.symbol }} address to receive
                      {{ item.symbol }}. -->
                    </p>
                  </div>
                  <div class="row background px-5 py-4">
                    <div class="d-flex justify-content-center w-100">
                      <div class="w-75">
                        <h5 class="text-dark mb-3 font-weight-bolder">
                          <!-- {{ item.symbol }} Deposit -->
                        </h5>
                      </div>
                      <div class="f-shrink-1 p-1" style="background-color: white;"
                        *ngIf="item.symbol === 'BNB' || item.symbol==='CAS' || item.symbol==='XRP'">
                        <span class="mb-3 font-weight-normal">
                          <mdb-icon far icon="question-circle"></mdb-icon> <a class="p-1" target="_blank"
                            routerLinkActive="customactive"
                            href="https://help.cashaa.com/knowledgebase/how-to-deposit-cryptocurrencies-to-your-cashaa-account/">How
                            to deposit?</a>
                        </span>
                      </div>
                    </div>
                    <div class="col-md-8">
                      <div class="mb-5">
                        <div class="input-group mb-3 sm-nowrap qr-code-inp-grp">
                          <div class="sm-qr-box sm-padding-qr-box">
                            <div class="sm-text-center">
                              <h4 class="sm-heading-text">Address</h4>
                            </div>
                            <div class="sm-qr-box-inner">
                              <qr-code [size]="200" [value]="accountDetailsCryptoData.tronAddress"></qr-code>
                            </div>
                            
                            <div class="sm-qr-input-box">
                              <span class="sm-spam-text sm-span-text-padding">{{accountDetailsCryptoData.tronAddress
                                }}</span>
                              <div class="input-group-append">
                                <span class="sm-input-group-text"><i mdbTooltip="Copy address" placement="top"
                                    class="fas fa-clone sm-copy-color" [ngClass]="copied ? 'text-primary' : ''"
                                    (click)="copyTronAddress()"></i></span>
                              </div>
                            </div>
                       
                          </div>
                          <div *ngIf="item.symbol == 'CAS' || item.symbol == 'BNB' || item.symbol == 'XRP'"
                            class="input-group mb-3 pl-4 sm-border-line">
                            <div class="sm-qr-box">
                              <div class="sm-text-center">
                                <h4 class="sm-heading-text">{{item.symbol == 'XRP'
                                  ? 'TAG': 'MEMO' }}</h4>
                              </div>
                              <div class="sm-qr-box-inner">
                                <qr-code [size]="200" [value]="accountDetailsCryptoData.memo"></qr-code>
                              </div>
                              <div class="sm-qr-input-box">
                                <span class="sm-spam-text">{{accountDetailsCryptoData.memo}}</span>
                                <div class="input-group-append">
                                  <span class="sm-input-group-text"><i mdbTooltip="Copy memo" placement="top"
                                      class="fas fa-clone sm-copy-color" [ngClass]="copiedMemo ? 'text-primary' : ''"
                                      (click)="copyMemoAddress()"></i></span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <p class="text-dark font-weight-bolder">Important</p>
                      <ul class="text-dark font-weight-bolder ul-not">
                        <li>
                          Send only {{getContentData2(item)}} to this
                          address. Sending
                          any other coins or token to this address may result in the
                          loss of your deposit.
                        </li>
                        <li>
                          Coins will be deposited immediately after 3 network
                          confirmation.
                        </li>
                        <li>
                          Minimum deposit amount is $10 equivalent. Amounts less than this will be lost.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </mdb-tab>

              <!-- <mdb-tab heading="Polygon" *ngIf="item.symbol === 'CAS'">
                <div class="background px-5 py-4" *ngIf="!cryptoLoading && !accountDetailsETHCryptoData">
                  <h4 class="text-dark mb-4 font-weight-bolder">
                    Get your {{ getCurrencyName(item.symbol) }} address
                  </h4>
                  <p class="text-dark font-weight-normal">
                    With {{ getCurrencyName(item.symbol) }} address, people can send
                    {{ getCurrencyName(item.symbol) }} to your account.
                  </p>
                  <div class="mt-4">
                    <button class="btn btn-outline-primary rounded text-primary btn-sm"
                      (click)="onAccountReadyPage(item, 'Crypto', 'BEP-20')">
                      Get address
                    </button>
                  </div>
                </div>
                <div class="px-5 mx-5 mt-3 text-center" *ngIf="cryptoLoading">
                  <mdb-spinner spinnerColor="blue"></mdb-spinner>
                </div>
                <div *ngIf="accountDetailsETHCryptoData">
                  <div class="py-4 pb-0">
                    <p class="font-weight-bold text-primary-dark">
                      Your Blockchain details to receive {{ item.symbol }}
                    </p>
                    <p class="font-weight-normal text-dark">
                     
                    </p>
                  </div>
                  <div class="row background px-5 py-4">
                    <div class="d-flex justify-content-center w-100">
                      <div class="w-75">
                        <h5 class="text-dark mb-3 font-weight-bolder">
                        </h5>
                      </div>
                      <div class="f-shrink-1 p-2" style="background-color: white;"
                        *ngIf="item.symbol === 'BNB' || item.symbol==='CAS' || item.symbol==='XRP'">
                        <span class="mb-3 font-weight-normal">
                          <mdb-icon far icon="question-circle"></mdb-icon> <a class="p-2" target="_blank"
                            routerLinkActive="customactive"
                            href="https://help.cashaa.com/knowledgebase/how-to-deposit-cryptocurrencies-to-your-cashaa-account/">How
                            to deposit?</a>
                        </span>
                      </div>
                    </div>
                    <div class="col-md-8">
                      <div class="mb-5">
                        <div class="input-group mb-3 sm-nowrap">
                          <div class="sm-qr-box sm-padding-qr-box">
                            <div class="sm-text-center">
                              <h4 class="sm-heading-text">Address</h4>
                            </div>
                            <div class="sm-qr-box-inner">
                              <qr-code [size]="200" [value]="accountDetailsETHCryptoData.address"></qr-code>
                            </div>
                            <div class="sm-qr-input-box">
                              <span class="sm-spam-text sm-span-text-padding">{{accountDetailsETHCryptoData.address
                                }}</span>
                              <div class="input-group-append">
                                <span class="sm-input-group-text"><i mdbTooltip="Copy address" placement="top"
                                    class="fas fa-clone sm-copy-color" [ngClass]="copied ? 'text-primary' : ''"
                                    (click)="copyAddressETH()"></i></span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <p class="text-dark font-weight-bolder">Important</p>
                      <ul class="text-dark font-weight-bolder ul-not">
                        <li>
                          Send only CAS (Polygon) to this
                          address. Sending
                          any other coins or token to this address may result in the
                          loss of your deposit.
                        </li>
                        <li>
                          Coins will be deposited immediately after 3 network
                          confirmation.
                        </li>
                        <li>
                          Minimum deposit amount is $10 equivalent. Amounts less than this will be lost.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </mdb-tab> -->
            </mdb-tabset>
            <div class="d-flex">
              <!-- <div class="text-left mt-3"> -->
              <!-- <span> -->
              <!-- <button *ngIf="isEarnAndFixedDepositActive() && item.isFDActive; else US" type="submit" (click)="openEarnPlan()"
                    class="earn-upto m-2 w-10">Earn
                    up to {{rate}}%
                  </button>
                  <ng-template #US>
                    <button *ngIf="userType !== '4' && item.isFDActive" type="submit" (click)="openEarnPlan()"
                    class="earn-upto m-2 w-10">Earn
                    up to {{rate}}%
                  </button>
                  </ng-template> -->
              <!-- </span> -->
              <!-- <span [ngClass]="{'sm-disable-span':item.available===0 || isCryptoDisable(item.symbol)}"
                  [title]="getTootlTip(item.available,item.symbol)"> -->
              <!-- <button class="app-secondary-btn m-2 w-10" (click)="onConvertClick(item.symbol)"
                    >Exchange</button> -->
              <button class="app-secondary-btn m-2 w-10 ml-auto" (click)="onHide(idx, item.symbol)">Hide</button>
              <!-- </span> -->
              <!-- <span [ngClass]="{'sm-disable-span':item.available===0 || isCryptoSendDisable(item.symbol)}"
                  [title]="getTootlTip(item.available,item.symbol)"> -->
              <!-- <button *ngIf="!isHidden(item.symbol)" type="submit" [disabled]="!item.isSendActive"
                    class="app-tertiary-btn m-2 w-10" (click)="onSendClick(item.symbol)">Send</button> -->
              <!-- </span> -->
              <span *ngIf="isEarnAndFixedDepositActive() && ((item.symbol === 'DOG' && userType === '4') || item.symbol !== 'DOG') && 
                        (item.earnRate && item.earnRate > 0)" [ngClass]="{'sm-disable-span':item.available===0}">
              </span>
              <!-- </div> -->
            </div>
          </div>
        </mdb-accordion-item-body>
      </mdb-accordion-item>
    </mdb-accordion>
  </section>
  <!-- <div class="d-flex justify-content-end my-3" style="margin-right: 2rem">
    <div *ngIf="isMobile" class="switch-sec font-weight-normal px-2 justify-content-end" style="line-height:1.5;"> Hide
      Zero Balance Assets <label class="switch">
        <input checked="true" type="checkbox" [(ngModel)]="hideZeroBalance" (click)="filterNonZeroBalance()">
        <span class="slider round"></span> </label>
    </div>
  </div> -->
  <div *ngIf="isMobile && !showUpgradeOption" class="heading mx-5 my-2">
    Assets
  </div>
  <section *ngIf="isMobile && !loading && (!hideZeroBalance || isBalance) && !showUpgradeOption" #cryptoSection
    class="banks crypto-section pr-1 mx-5 mb-5 sm-width-phone-700">
    <mdb-accordion *ngFor="let item of allCurrencyList; let idx = index">
      <mdb-accordion-item #collapsesCrypto [ngClass]="selectedCrypto == idx ? '' : 'bg-unset'">
        <!-- <mdb-accordion-item-head > -->

        <account-box-mobile [idx]="idx" [isEarn]="isEarn" [currency]="item.symbol" [balance]="item.availableBalance"
          [userBalanceInUSD]="item.userBalanceInUSD" [creditBalanceInUSD]="item.userCreditBalanceInUSD"
          [currencyId]="item.currencyId" [isDepositActive]="item.isDepositActive" [isSendActive]="item.isSendActive"
          [item]="item" (isLoadingMDBAccordionBody)="getCryptoAccountDetails($event)">
        </account-box-mobile>
        <!-- <ng-template #other> -->
        <!-- </ng-template> -->


        <!-- </mdb-accordion-item-head> -->
        <mdb-accordion-item-body>
          <div class="custom-mx-px">
            <mdb-tabset #staticTabs [buttonClass]="'nav md-pills pills-primary mb-2'" [justified]="true"
              class="recipientTab">
              <mdb-tab heading={{getTabHeaderData1(item)}}  *ngIf="item.symbol !== 'CAS'">
                <div class="background px-5 py-4" *ngIf="!cryptoLoading && !accountDetailsCryptoData">
                  <h4 class="text-dark mb-4 font-weight-bolder">
                    Get your {{ getCurrencyName(item.symbol) }} address
                  </h4>
                  <p class="text-dark font-weight-normal">
                    With {{ getCurrencyName(item.symbol) }} address, people can send
                    {{ getCurrencyName(item.symbol) }} to your account.
                  </p>
                  <div class="mt-4">
                    <button class="btn btn-outline-primary rounded text-primary btn-sm"
                      (click)="onAccountReadyPage(item, 'Crypto')">
                      Get address
                    </button>
                  </div>
                </div>
                <div class="px-5 mx-5 mt-3 text-center" *ngIf="cryptoLoading">
                  <mdb-spinner spinnerColor="blue"></mdb-spinner>
                </div>
                <div *ngIf="accountDetailsCryptoData">
                  <div class="py-4 pb-0">
                    <p class="font-weight-bold text-primary-dark">
                      Your Blockchain details to receive {{ item.symbol }}
                    </p>
                    <p class="font-weight-normal text-dark">
                      <!-- Use your {{ item.symbol }} address to receive
                    {{ item.symbol }}. -->
                    </p>
                  </div>
                  <div class="row background px-5 py-4">
                    <div class="d-flex justify-content-center w-100">
                      <div class="w-75">
                        <h5 class="text-dark mb-3 font-weight-bolder">
                          <!-- {{ item.symbol }} Deposit -->
                        </h5>
                      </div>
                      <div class="f-shrink-1 p-1" style="background-color: white;"
                        *ngIf="item.symbol === 'BNB' || item.symbol==='CAS' || item.symbol==='XRP'">
                        <span class="mb-3 font-weight-normal">
                          <mdb-icon far icon="question-circle"></mdb-icon> <a class="p-1" target="_blank"
                            routerLinkActive="customactive"
                            href="https://help.cashaa.com/knowledgebase/how-to-deposit-cryptocurrencies-to-your-cashaa-account/">How
                            to deposit?</a>
                        </span>
                      </div>
                    </div>
                    <div class="col-md-8">
                      <div class="mb-5">
                        <div class="input-group mb-3 sm-nowrap qr-code-inp-grp">
                          <div class="sm-qr-box sm-padding-qr-box">
                            <div class="sm-text-center">
                              <h4 class="sm-heading-text">Address</h4>
                            </div>
                            <div class="sm-qr-box-inner">
                              <qr-code [size]="200" [value]="accountDetailsCryptoData.address"></qr-code>
                            </div>
                            <div class="sm-qr-input-box">
                              <span class="sm-spam-text sm-span-text-padding">{{accountDetailsCryptoData.address
                                }}</span>
                              <div class="input-group-append">
                                <span class="sm-input-group-text"><i mdbTooltip="Copy address" placement="top"
                                    class="fas fa-clone sm-copy-color" [ngClass]="copied ? 'text-primary' : ''"
                                    (click)="copyAddress()"></i></span>
                              </div>
                            </div>
                          </div>
                          <div *ngIf="item.symbol == 'CAS' || item.symbol == 'BNB' || item.symbol == 'XRP'"
                            class="input-group mb-3 pl-4 sm-border-line">
                            <div class="sm-qr-box">
                              <div class="sm-text-center">
                                <h4 class="sm-heading-text">{{item.symbol == 'XRP'
                                  ? 'TAG': 'MEMO' }}</h4>
                              </div>
                              <div class="sm-qr-box-inner">
                                <qr-code [size]="200" [value]="accountDetailsCryptoData.memo"></qr-code>
                              </div>
                              <div class="sm-qr-input-box">
                                <span class="sm-spam-text">{{accountDetailsCryptoData.memo}}</span>
                                <div class="input-group-append">
                                  <span class="sm-input-group-text"><i mdbTooltip="Copy memo" placement="top"
                                      class="fas fa-clone sm-copy-color" [ngClass]="copiedMemo ? 'text-primary' : ''"
                                      (click)="copyMemoAddress()"></i></span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <p class="text-dark font-weight-bolder">Important</p>
                      <ul class="text-dark font-weight-bolder ul-not">
                        <li>
                          Send only {{getContentData(item.symbol)}} to this
                          address. Sending
                          any other coins or token to this address may result in the
                          loss of your deposit.
                        </li>
                        <li>
                          Coins will be deposited immediately after 3 network
                          confirmation.
                        </li>
                        <li>
                          Minimum deposit amount is $10 equivalent. Amounts less than this will be lost.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </mdb-tab>
              <mdb-tab heading="BEP-20" *ngIf="!(item.symbol === 'BTC' || item.symbol === 'BNB')">
                <div class="background px-5 py-4" *ngIf="!cryptoLoading && !accountDetailsETHCryptoData">
                  <h4 class="text-dark mb-4 font-weight-bolder">
                    Get your {{ getCurrencyName(item.symbol) }} address
                  </h4>
                  <p class="text-dark font-weight-normal">
                    With {{ getCurrencyName(item.symbol) }} address, people can send
                    {{ getCurrencyName(item.symbol) }} to your account.
                  </p>
                  <div class="mt-4">
                    <button class="btn btn-outline-primary rounded text-primary btn-sm"
                      (click)="onAccountReadyPage(item, 'Crypto', 'BEP-20')">
                      Get address
                    </button>
                  </div>
                </div>
                <div class="px-5 mx-5 mt-3 text-center" *ngIf="cryptoLoading">
                  <mdb-spinner spinnerColor="blue"></mdb-spinner>
                </div>
                <div *ngIf="accountDetailsETHCryptoData">
                  <div class="py-4 pb-0">
                    <p class="font-weight-bold text-primary-dark">
                      Your Blockchain details to receive {{ item.symbol }}
                    </p>
                    <p class="font-weight-normal text-dark">
                      <!-- Use your {{ item.symbol }} address to receive
                    {{ item.symbol }}. -->
                    </p>
                  </div>
                  <div class="row background px-5 py-4">
                    <div class="d-flex justify-content-center w-100">
                      <div class="w-75">
                        <h5 class="text-dark mb-3 font-weight-bolder">
                          <!-- {{ item.symbol }} Deposit -->
                        </h5>
                      </div>
                      <div class="f-shrink-1 p-2" style="background-color: white;"
                        *ngIf="item.symbol === 'BNB' || item.symbol==='CAS' || item.symbol==='XRP'">
                        <span class="mb-3 font-weight-normal">
                          <mdb-icon far icon="question-circle"></mdb-icon> <a class="p-2" target="_blank"
                            routerLinkActive="customactive"
                            href="https://help.cashaa.com/knowledgebase/how-to-deposit-cryptocurrencies-to-your-cashaa-account/">How
                            to deposit?</a>
                        </span>
                      </div>
                    </div>
                    <div class="col-md-8">
                      <div class="mb-5">
                        <div class="input-group mb-3 sm-nowrap">
                          <div class="sm-qr-box sm-padding-qr-box">
                            <div class="sm-text-center">
                              <h4 class="sm-heading-text">Address</h4>
                            </div>
                            <div class="sm-qr-box-inner">
                              <qr-code [size]="200" [value]="accountDetailsETHCryptoData.address"></qr-code>
                            </div>
                            <div class="sm-qr-input-box">
                              <span class="sm-spam-text sm-span-text-padding">{{accountDetailsETHCryptoData.address
                                }}</span>
                              <div class="input-group-append">
                                <span class="sm-input-group-text"><i mdbTooltip="Copy address" placement="top"
                                    class="fas fa-clone sm-copy-color" [ngClass]="copied ? 'text-primary' : ''"
                                    (click)="copyAddressETH()"></i></span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <p class="text-dark font-weight-bolder">Important</p>
                      <ul class="text-dark font-weight-bolder ul-not">
                        <li>
                          Send only {{item.symbol}} (BEP-20) to this
                          address. Sending
                          any other coins or token to this address may result in the
                          loss of your deposit.
                        </li>
                        <li>
                          Coins will be deposited immediately after 3 network
                          confirmation.
                        </li>
                        <li>
                          Minimum deposit amount is $10 equivalent. Amounts less than this will be lost.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </mdb-tab>
              <mdb-tab heading="{{getTabHeaderData3(item)}}" *ngIf="item.symbol === 'USDT'">
                <div class="background px-5 py-4" *ngIf="!cryptoLoading && !accountDetailsCryptoData?.tronAddress">
                  <h4 class="text-dark mb-4 font-weight-bolder">
                    Get your {{ getCurrencyName(item.symbol) }} address
                  </h4>
                  <p class="text-dark font-weight-normal">
                    With {{ getCurrencyName(item.symbol) }} address, people can send
                    {{ getCurrencyName(item.symbol) }} to your account.
                  </p>
                  <div class="mt-4">
                    <button class="btn btn-outline-primary rounded text-primary btn-sm"
                      (click)="onAccountReadyPage(item, 'Crypto', getTabHeaderData3(item))">
                      Get address
                    </button>
                  </div>
                </div>
                <div class="px-5 mx-5 mt-3 text-center" *ngIf="cryptoLoading">
                  <mdb-spinner spinnerColor="blue"></mdb-spinner>
                </div>
                <div *ngIf="accountDetailsCryptoData && accountDetailsCryptoData?.tronAddress">
                  <div class="py-4 pb-0">
                    <p class="font-weight-bold text-primary-dark">
                      Your Blockchain details to receive {{ item.symbol === "DOG" ? "DOGE" : item.symbol }}
                    </p>
                    <p class="font-weight-normal text-dark">
                      <!-- Use your {{ item.symbol }} address to receive
                      {{ item.symbol }}. -->
                    </p>
                  </div>
                  <div class="row background px-5 py-4">
                    <div class="d-flex justify-content-center w-100">
                      <div class="w-75">
                        <h5 class="text-dark mb-3 font-weight-bolder">
                          <!-- {{ item.symbol }} Deposit -->
                        </h5>
                      </div>
                      <div class="f-shrink-1 p-1" style="background-color: white;"
                        *ngIf="item.symbol === 'BNB' || item.symbol==='CAS' || item.symbol==='XRP'">
                        <span class="mb-3 font-weight-normal">
                          <mdb-icon far icon="question-circle"></mdb-icon> <a class="p-1" target="_blank"
                            routerLinkActive="customactive"
                            href="https://help.cashaa.com/knowledgebase/how-to-deposit-cryptocurrencies-to-your-cashaa-account/">How
                            to deposit?</a>
                        </span>
                      </div>
                    </div>
                    <div class="col-md-8">
                      <div class="mb-5">
                        <div class="input-group mb-3 sm-nowrap qr-code-inp-grp">
                          <div class="sm-qr-box sm-padding-qr-box">
                            <div class="sm-text-center">
                              <h4 class="sm-heading-text">Address</h4>
                            </div>
                            <div class="sm-qr-box-inner">
                              <qr-code [size]="200" [value]="accountDetailsCryptoData.tronAddress"></qr-code>
                            </div>
                            
                            <div class="sm-qr-input-box">
                              <span class="sm-spam-text sm-span-text-padding">{{accountDetailsCryptoData.tronAddress
                                }}</span>
                              <div class="input-group-append">
                                <span class="sm-input-group-text"><i mdbTooltip="Copy address" placement="top"
                                    class="fas fa-clone sm-copy-color" [ngClass]="copied ? 'text-primary' : ''"
                                    (click)="copyTronAddress()"></i></span>
                              </div>
                            </div>
                       
                          </div>
                          <div *ngIf="item.symbol == 'CAS' || item.symbol == 'BNB' || item.symbol == 'XRP'"
                            class="input-group mb-3 pl-4 sm-border-line">
                            <div class="sm-qr-box">
                              <div class="sm-text-center">
                                <h4 class="sm-heading-text">{{item.symbol == 'XRP'
                                  ? 'TAG': 'MEMO' }}</h4>
                              </div>
                              <div class="sm-qr-box-inner">
                                <qr-code [size]="200" [value]="accountDetailsCryptoData.memo"></qr-code>
                              </div>
                              <div class="sm-qr-input-box">
                                <span class="sm-spam-text">{{accountDetailsCryptoData.memo}}</span>
                                <div class="input-group-append">
                                  <span class="sm-input-group-text"><i mdbTooltip="Copy memo" placement="top"
                                      class="fas fa-clone sm-copy-color" [ngClass]="copiedMemo ? 'text-primary' : ''"
                                      (click)="copyMemoAddress()"></i></span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <p class="text-dark font-weight-bolder">Important</p>
                      <ul class="text-dark font-weight-bolder ul-not">
                        <li>
                          Send only {{getContentData2(item)}} to this
                          address. Sending
                          any other coins or token to this address may result in the
                          loss of your deposit.
                        </li>
                        <li>
                          Coins will be deposited immediately after 3 network
                          confirmation.
                        </li>
                        <li>
                          Minimum deposit amount is $10 equivalent. Amounts less than this will be lost.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </mdb-tab>

              <!-- <mdb-tab heading="Polygon" *ngIf="item.symbol === 'CAS'">
                <div class="background px-5 py-4" *ngIf="!cryptoLoading && !accountDetailsETHCryptoData">
                  <h4 class="text-dark mb-4 font-weight-bolder">
                    Get your {{ getCurrencyName(item.symbol) }} address
                  </h4>
                  <p class="text-dark font-weight-normal">
                    With {{ getCurrencyName(item.symbol) }} address, people can send
                    {{ getCurrencyName(item.symbol) }} to your account.
                  </p>
                  <div class="mt-4">
                    <button class="btn btn-outline-primary rounded text-primary btn-sm"
                      (click)="onAccountReadyPage(item, 'Crypto', 'BEP-20')">
                      Get address
                    </button>
                  </div>
                </div>
                <div class="px-5 mx-5 mt-3 text-center" *ngIf="cryptoLoading">
                  <mdb-spinner spinnerColor="blue"></mdb-spinner>
                </div>
                <div *ngIf="accountDetailsETHCryptoData">
                  <div class="py-4 pb-0">
                    <p class="font-weight-bold text-primary-dark">
                      Your Blockchain details to receive {{ item.symbol }}
                    </p>
                    <p class="font-weight-normal text-dark">
                    </p>
                  </div>
                  <div class="row background px-5 py-4">
                    <div class="d-flex justify-content-center w-100">
                      <div class="w-75">
                        <h5 class="text-dark mb-3 font-weight-bolder">
                        </h5>
                      </div>
                      <div class="f-shrink-1 p-2" style="background-color: white;"
                        *ngIf="item.symbol === 'BNB' || item.symbol==='CAS' || item.symbol==='XRP'">
                        <span class="mb-3 font-weight-normal">
                          <mdb-icon far icon="question-circle"></mdb-icon> <a class="p-2" target="_blank"
                            routerLinkActive="customactive"
                            href="https://help.cashaa.com/knowledgebase/how-to-deposit-cryptocurrencies-to-your-cashaa-account/">How
                            to deposit?</a>
                        </span>
                      </div>
                    </div>
                    <div class="col-md-8">
                      <div class="mb-5">
                        <div class="input-group mb-3 sm-nowrap">
                          <div class="sm-qr-box sm-padding-qr-box">
                            <div class="sm-text-center">
                              <h4 class="sm-heading-text">Address</h4>
                            </div>
                            <div class="sm-qr-box-inner">
                              <qr-code [size]="200" [value]="accountDetailsETHCryptoData.address"></qr-code>
                            </div>
                            <div class="sm-qr-input-box">
                              <span class="sm-spam-text sm-span-text-padding">{{accountDetailsETHCryptoData.address
                                }}</span>
                              <div class="input-group-append">
                                <span class="sm-input-group-text"><i mdbTooltip="Copy address" placement="top"
                                    class="fas fa-clone sm-copy-color" [ngClass]="copied ? 'text-primary' : ''"
                                    (click)="copyAddressETH()"></i></span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <p class="text-dark font-weight-bolder">Important</p>
                      <ul class="text-dark font-weight-bolder ul-not">
                        <li>
                          Send only CAS (Polygon) to this
                          address. Sending
                          any other coins or token to this address may result in the
                          loss of your deposit.
                        </li>
                        <li>
                          Coins will be deposited immediately after 3 network
                          confirmation.
                        </li>
                        <li>
                          Minimum deposit amount is $10 equivalent. Amounts less than this will be lost.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </mdb-tab> -->
            </mdb-tabset>
            <div class="d-flex">
              <button class="app-secondary-btn m-2 w-10 ml-auto" (click)="onHide(idx, item.symbol)">Hide</button>
              <span *ngIf="isEarnAndFixedDepositActive() && ((item.symbol === 'DOG' && userType === '4') || item.symbol !== 'DOG') && 
                      (item.earnRate && item.earnRate > 0)" [ngClass]="{'sm-disable-span':item.available===0}">
              </span>
              <!-- </div> -->
            </div>
          </div>
        </mdb-accordion-item-body>
      </mdb-accordion-item>
    </mdb-accordion>
  </section>

  <div *ngIf="showUpgradeOption" class="text-center my-4">
    <img class="mb-4" src="../../../assets/images/borrow-zero.svg" alt="borrow zero">
    <h5 class="heading">To avail Borrow at 0% you need to upgrade your loyalty level</h5>
     <button class="app-success-btn btn-sm" (click)="goToPlans()">Upgrade now</button>
  </div>

</cashaa-sidenav>
import { Component, OnInit, Input } from "@angular/core";
import { ILedgerList } from "src/app/core/models/activity/recent-transaction-list";
import { LedgerType } from "src/app/core/enums/ledgerType";
import { ModalService } from "src/app/core/services/modal/modal.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "cashaa-activity-balance-recieved",
  templateUrl: "./activity-balance-recieved.component.html",
  styleUrls: ["./activity-balance-recieved.component.scss"],
})
export class ActivityBalanceRecievedComponent implements OnInit {
  @Input() ledger: ILedgerList;

  constructor(private modalService: ModalService) { }

  ngOnInit(): void { }

  openDetailBox(): void {
    this.modalService.openActivityTransferDetail(this.ledger);
  }

  getType(id: number): any {
    return LedgerType[id];
  }

  isTransactionIdExistMigration(): boolean {
    return (
      this.ledger.transactionId?.toLowerCase().includes("migration") ||
      this.ledger.transactionId?.toLowerCase().includes("-")
    );
  }
  getCurrencyName(curr): string {
    if (curr === "AUSD") return "USD";
    if (curr === "DOG") return "DOGE";
    else return curr;
  }

  isCrypto(): boolean {
    return this.ledger.currency === "ETH" ||
      this.ledger.currency == "BTC" ||
      this.ledger.currency == "CAS" ||
      this.ledger.currency == "BNB" ||
      this.ledger.currency == "USDT" ||
      this.ledger.currency == "DOG" ||
      this.ledger.currency == "XRP" ||
      this.ledger.currency == "LTC" ||
      this.ledger.currency == "ADA" ||
      this.ledger.currency == "BCH" ||
      this.ledger.currency == "BNB" ||
      this.ledger.currency == "BNB20" ||
      this.ledger.currency == "LINK" ||
      this.ledger.currency == "UNI" ||
      this.ledger.currency == "USDC"

  }

  isFiatAndAccount(): boolean {
    return this.ledger.currency == "GBP" ||
    this.ledger.currency == "EUR" ||
    this.ledger.currency == "AUSD" ||
    this.ledger.currency == "USD" ||
    this.ledger.currency == "SGD" ||
    this.ledger.currency == "AUD" ||
    this.ledger.currency == "CNH" ||
    this.ledger.currency == "CAD" ||
    this.ledger.currency == "CHF" ||
    this.ledger.currency == "NZD" ||
    this.ledger.currency == "JPY" ||
    this.ledger.currency == "MXN" ||
    this.ledger.currency == "SHIB"
  }

  getRef(): string {
    if (this.ledger.currency === 'CAS') {
      return this.ledger.networkType ? this.getRefValue(this.ledger.currency) : environment.CAS + this.ledger.transactionId;
    } else if (this.ledger.currency === 'BNB') {
      return this.ledger.networkType ? this.getRefValue(this.ledger.currency) : environment.BNB + this.ledger.transactionId;
    } else if (this.ledger.currency === 'BCH') {
      return this.ledger.networkType ? this.getRefValue(this.ledger.currency) : environment.BCH + this.ledger.transactionId;
    } else if (this.ledger.currency === 'XRP') {
      return this.ledger.networkType ? this.getRefValue(this.ledger.currency) : environment.XRP + this.ledger.transactionId;
    } else if (this.ledger.currency === 'LTC') {
      return this.ledger.networkType ? this.getRefValue(this.ledger.currency) : environment.LTC + this.ledger.transactionId;
    } else if (this.ledger.currency === 'DOG') {
      return this.ledger.networkType ? this.getRefValue(this.ledger.currency) : environment.DOG + this.ledger.transactionId;
    } else if (this.ledger.currency === 'ETH') {
      return this.ledger.networkType ? this.getRefValue(this.ledger.currency) : environment.ETH + this.ledger.transactionId;
    } else if (this.ledger.currency === 'USDT') {
      return this.ledger.networkType ? this.getRefValue(this.ledger.currency) : environment.USDT + this.ledger.transactionId;
    } else if (this.ledger.currency === 'BTC') {
      return this.ledger.networkType ? this.getRefValue(this.ledger.currency) : environment.BTC + this.ledger.transactionId;
    } else if (this.ledger.currency === 'UNI') {
      return this.ledger.networkType ? this.getRefValue(this.ledger.currency) : environment.UNI + this.ledger.transactionId;
    } else if (this.ledger.currency === 'LINK') {
      return this.ledger.networkType ? this.getRefValue(this.ledger.currency) : environment.LINK + this.ledger.transactionId;
    } else if (this.ledger.currency === 'USDC') {
      return this.ledger.networkType ? this.getRefValue(this.ledger.currency) : environment.USDC + this.ledger.transactionId;
    }
  }

  getRefValue(currency: string): string {
    if (currency === 'LINK' || currency === 'USDC' || currency === 'UNI' || currency === 'USDT' || currency === 'CAS') {
      if (this.ledger.networkType == 1) {
        return environment.NETWORK_ERC20 + this.ledger.transactionId;
      } else if (this.ledger.networkType == 2) {
        return environment.NETWORK_BEP20 + this.ledger.transactionId;
      } else if (this.ledger.networkType == 4 && currency === 'USDT') {
        return environment.NETWORK_TRC20 + this.ledger.transactionId;
      }
    }

    if ((currency === 'LTC' || currency === 'BNB' || currency === 'ETH' || currency === 'BCH' || currency === 'XRP' || currency === 'DOG') &&
      this.ledger.networkType == 2) {
      return environment.NETWORK_BEP20 + this.ledger.transactionId;
    }

    if (currency === 'CAS' && this.ledger.networkType == 3) {
      return environment.NETWORK_POLYGON + this.ledger.transactionId;
    }
    
  }
}
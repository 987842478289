import { IdentityProof } from "./../enums/identityProof";
import { AddressProof } from "./../enums/addressProof";
export interface ICreateKYC {
  id?: string;
  cashaaUserId?: string;
  firstName?: string;
  lastName?: string;
  dateOfBirth?: Date;
  phone?: string;
  address1?: string;
  address2?: string;
  city?: string;
  countryId?: string;
  region?: string;
  postalCode?: string;
  addressDocumentType?: number;
  addressProofFilePath?: string;
  identityDocumentType?: number;
  identityProofFrontsideFilePath?: string;
  selfieFilePath?: string;
  identityProofBacksideFilePath?: string
  lastModified?: string;
  verified?: boolean;
}

export interface IDeleteApplicationDoc {
  id: string;
}

export class DeleteApplicationFormDoc {
  constructor(
    public id: string
  ){}
}
  
export class CreateKYC implements ICreateKYC {
  constructor(
    public firstName: string,
    public phone: string,
    public address1: string,
    public city: string,
    public countryId: string,
    public region: string,
    public postalCode: string,
    public dateOfBirth?: Date,
    public lastName?: string,
    public address2?: string
  ) {}
}
export class CreateKYCDocument implements ICreateKYC {
  constructor(
    public addressDocumentType?: AddressProof,
    public addressProofFilePath?: string,
    public identityDocumentType?: IdentityProof,
    public identityProofFrontsideFilePath?: string,
    public selfieFilePath?: string,
    public identityProofBacksideFilePath?: string
  ) {}
}

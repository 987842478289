import { IBusinessDocList } from "./../../core/models/kyc-business-document-list";
import { IFormSelect } from "./../../core/models/form-select";
import { KycService } from "./../services/kyc.service";
import {
  ICreateBusinessKYC,
  IStakeholders,
} from "./../../core/models/create-business-kyc";
import { ValidatorUtility } from "src/app/core/utility/validator.utility";
import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from "@angular/core";
import { FormGroup, FormBuilder } from "@angular/forms";
import { IBusinessKycDetails } from "./../../core/models/business-account/business-key-detail-list";
import { FILE_DELETE_MESG, FILE_SUCCESS_MESG, UNABLE_TO_PERFORM_ERROR } from "src/app/app.constants";
import { ToastrService } from "ngx-toastr";
import { FileUpload, IAPIEnumLoad, IFile } from "src/app/core/models/Lerex/enum-load";
import { ActivatedRoute } from "@angular/router";
import { AccountDocumentType } from "src/app/core/enums/accountDocumentTypeEnum";
import { SubSink } from "subsink";
import { DomainService } from "src/app/core/services/domain/domain.service";
import { CommonSavedData } from "src/app/shared/services/commonSavedData.service";
import { ModalService } from "src/app/core/services/modal/modal.service";
import { Subscription } from "rxjs";

@Component({
  selector: "cashaa-kyc-business-checklist",
  templateUrl: "./kyc-business-checklist.component.html",
  styleUrls: ["./kyc-business-checklist.component.scss"],
})
export class KycBusinessChecklistComponent implements OnInit, OnDestroy {
  @Input() set informationData(data) {
    if (data) {
        this.info = data;
    }
  }

  info: ICreateBusinessKYC;

  @Input() set directorData(data) {
      if (data && !this.companyDetails) {
        this.getCompanyDetails();
      }
  }

  @Output()
  continue = new EventEmitter<string>();

  BusinessDocList: IBusinessDocList[];

  form: FormGroup;
  totalDocUploaded = 0;

  errorMessage = "";

  loader = false;
  companyDetails: IBusinessKycDetails;
  documentTypes?: IFile[];
  bId: string;
  isFileUploadValid = true;
  contactfile: File;
  plan: string;
  subSink = new SubSink();
  fileCount = 0;
  sub: Subscription;

  constructor(
    private fb: FormBuilder,
    private domainService: DomainService,
    private toastrService: ToastrService,
    private kycService: KycService,
    private activatedRoute: ActivatedRoute,
    private commonSavedData: CommonSavedData,
    private modalService: ModalService
  ) {
    this.createForm();
  }

  ngOnInit(): void {
    this.getCompanyDetails();
    this.subSink.add(this.activatedRoute.queryParams.subscribe(params => {
      this.plan = params['plan'];
      if (this.plan === 'Crypto') {
        this.plan = AccountDocumentType.GBP_EUR.toString();
      }
    }));

    this.subSink.add(this.domainService.getKycUploadFile().subscribe(data=> {
      if (data && data.docType === "Business") {
          this.onFileChange(data);
      }
    }));
  }

  /*** Create new form*/
  createForm(): void {
    this.form = this.fb.group({
      uploadedFile: ["", ValidatorUtility.Required],
      documentType: ["", ValidatorUtility.Required],
    });
  }

  onFormSubmit(item: IFile): void {
    item.fileActionLoader = true;
    const formData = new FormData();
    formData.append("File", this.contactfile);
    formData.append("documentType", item.apiData.value.toString());
    formData.append("DocFor", "Business");
    this.errorMessage = undefined;
    this.kycService.createBusinessKycContact(formData).subscribe(
      (res:any) => {
        item.fileActionLoader = false;
        this.toastrService.success(FILE_SUCCESS_MESG);
        item.isDeletedValid = true;
        item.fileId = res.message;
      },
      (err) => {
        if (err.error.message) {
          this.toastrService.error(err.error.message);
        } else {
          this.toastrService.error(UNABLE_TO_PERFORM_ERROR);
        }
        item.fileActionLoader = false;
      }
    );
  }

  getBusinessDocuments(businessId: string): void {
    this.kycService.getBusinessDocuments(businessId).subscribe(
      (res) => {
        this.BusinessDocList = res;
        this.totalDocUploaded = this.BusinessDocList.length;
        this.getDocumentsType();
      },
      (err) => {
        this.loader = false;
      }
    );
  }
  /**
   * Get Company detail
   */
  getCompanyDetails(): void {
    this.kycService.getBusinessKycDetails().subscribe((res) => {
      if (res) {
        this.companyDetails = res;
        this.bId = res.businessId;
        this.getBusinessDocuments(res.id);
      }
    });
  }
  // load Business Type
  getDocumentsType() {
    this.kycService.getBusinessDocumentTypes(AccountDocumentType[this.plan]).subscribe((resData) => {
      this.documentTypes = resData.map((data: IAPIEnumLoad)=> {
        let doc : IBusinessDocList = this.BusinessDocList.filter((d)=>d.documentType === data.value.toString())[0];
        let file = new FileUpload(data);
        if (doc) {
          file.fileId = doc.documentId;
          file.isDeletedValid = true;
          file.fileName = doc.name;
          ++this.fileCount;
        }
        file.docType = "Business";
        return file;
      });
    });
  }
  

  /**
   * On File change
   */
   onFileChange(docValue:IFile): void {
     ++ this.fileCount;
    this.contactfile = docValue.file;
    this.onFormSubmit(docValue);
  }

  onSubmit() {
    this.continue.emit(this.companyDetails.id);
  }

  onFileDelete(event, item:IFile) {
    this.modalService.openConfirmationModal("Do you want to delete the file?");
    this.sub = this.commonSavedData.getConfirmationBoxStatus().subscribe((status: boolean) => {
      if (status) {
        item.fileActionLoader = true;
        this.kycService.deleteBusinessDocument(item.fileId).subscribe((res: any) => {
          item.fileActionLoader = false;
          if (res.message === "success") {
             --this.fileCount;
            item.isDeletedValid = false;
             this.toastrService.success(FILE_DELETE_MESG);
          } else {
            this.toastrService.error(UNABLE_TO_PERFORM_ERROR);
          }
          
      },
      (err) => {
        item.fileActionLoader = false;
        if (err.error.message) {
          this.toastrService.error(err.error.message);
          this.errorMessage = err.error.message;
        } else {
          this.toastrService.error(UNABLE_TO_PERFORM_ERROR);
        }
         });    
    }
    this.sub.unsubscribe();
  });
  }

  ngOnDestroy() {
    this.subSink.unsubscribe();
  }
}

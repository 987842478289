<div class="modal-header position-relative sm-form-title">
  <button type="button" class="close pull-right sm-close" aria-label="Close" (click)="modalRef.hide()">
    <span aria-hidden="true">×</span>
  </button>
  <h3 class="modal-title w-100 heading" id="myModalLabel">
    Cancel Fixed Deposit
  </h3>
</div>
<div class="modal-body">
  <div class="p-4 text-center" [hidden]="!isLoading">
    <mdb-spinner spinnerColor="blue"></mdb-spinner>
  </div>
  <div class="my-2">
    Do you really want to cancel this fixed-term deposit? <br>
    Please note - <b class="font-weight-bold">{{cancelFee}}{{currency}}</b>&nbsp;will be deducted for the premature
    cancellation.
  </div>
  <div class="row">
  <ng-container *ngFor="let info of cancelFeeDetails; let i=index">
      <div class="col-3">
        <div *ngIf="info.discountFee > 0;else other "><s class="text-primary">{{info.feeInPercent}}%</s><span class="font-weight-bold ml-1">{{info.discountFee}}%</span></div>
          <ng-template #other>
            <div ><span class="font-weight-bold ml-1">{{info.feeInPercent}}%</span></div>
          </ng-template> 
        <div>{{ info.duration }}</div>
        <div *ngIf="i!==0" class="vertical-divider"></div>
      </div> 
  </ng-container>
</div>
  <div>*Any interest given will be also adjusted from principle.</div>
<div class="modal-footer mt-2">
  <button mdbBtn type="button" color="primary" mdbWavesEffect (click)="confirm()" class="btn-sm">
    Yes
  </button>
  <button mdbBtn type="button" outline="true" mdbWavesEffect class="sm-btn-input-hover btn-border btn-sm"
    (click)="modalRef.hide()">
    NO
  </button>
</div>
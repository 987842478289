import { Component, OnInit } from "@angular/core";
import {
  ILedgerList,
  IRecentTransactionList,
} from "src/app/core/models/activity/recent-transaction-list";
import { ActivityService } from "../services/activity.service";
import { LedgerType } from "src/app/core/enums/ledgerType";
import { IFormSelect } from "src/app/core/models/form-select";
import { SearchFilter } from "src/app/core/models/activity/search-filter";
import { IMyOptions } from "ng-uikit-pro-standard";
import { APP_ROUTES, USER_TYPE } from "src/app/app.constants";
import { UserType } from "src/app/core/enums/userType";
import { Router } from "@angular/router";
import { CommonSavedData } from "src/app/shared/services/commonSavedData.service";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ValidatorUtility } from "src/app/core/utility/validator.utility";
import { ModalService } from "src/app/core/services/modal/modal.service";
import { UserService } from "src/app/core/services/user/user.service";

@Component({
  selector: "cashaa-activity-home",
  templateUrl: "./activity-home.component.html",
  styleUrls: ["./activity-home.component.scss"],
})
export class ActivityHomeComponent implements OnInit {
  ledgerList: IRecentTransactionList[] = [];

  error = false;

  loading = false;

  showFilter = false;
  showStakingBanner: boolean = false;
  activityType: string;
  fromDate: string;
  toDate: string;
  searchText: string;
  symbol = [];
  isPersonal: boolean;
  myDatePickerOptions: IMyOptions = {
    minYear: 1900,
    maxYear: 2099,
    closeAfterSelect: true
  };
  searchForm: FormGroup;
  termsAndCondition = `<p>This notice informs you that Crypto Innovation UAB has acquired the Cashaa brand and the CAS tokens available on the books of Cashaa Holdings O.U., which executed the CAS token I.C.O. in 2017, without any liabilities, to continue the development of the CAS ecosystem. </p>
  <p>Chain Tech SRL, the company managing current Cashaa crypto account  is closing its crypto-asset business and withdrawing any pending applications with the regulators. As such, by no later than May 30, 2022, <b>Chain Tech S.R.L.</b> will no longer offer any crypto-asset business with any client. 
  </p>
  <p>These new terms and conditions set out provisions for the one-time migration of Cashaa wallet accounts from Chain Tech SRL., ​​a Romania registered company <b>(Registration No.  42007900)</b> with its registered office at Str. Principala 42, 545200, Ludus, Romania, to <b>Crypto Innovations U.A.B.</b>, a licensed virtual currency wallet and exchange operator
   <b>(Registration No. 305997087)</b> with its registered office at Svitrigailos st. 11K-109, Vilnius, Lithuania.</p> 
  
  <p>However, <b>Crypto Innovations UAB</b>. will not migrate, onboard, or service any accounts domiciled or operating in the <b>N.Y., CT, WA, VT, NV, HI states of U.S.A., China, or sanctioned countries</b>. Any users domiciled in the above countries must withdraw their balance no later than May 30, 2022. </p>
  
  <p>These terms and conditions are an attachment to and form an integral part of the “Cryptocurrency Terms,” “Wallet Terms,” “Earn Terms,” and “Exchange Terms” available on www.cashaa.com. All the above-mentioned “Terms” already agreed to by Cashaa’s account holders will continue to be applicable in full, unless otherwise set out in this document,
   and will continue to be a legally binding agreement between the parties, namely “Cashaa- and any holding company, subsidiary or entity belonging to the Cashaa group of companies” and the “Client” or “you.”</p>
  
  <p>With effect from May 30, 2022, all the existing Cashaa “Account” and associated “Balances” and “Interest ” will be owned, serviced, and maintained by Crypto Innovations UAB. No “Liabilities ” will transfer to the books of Crypto Innovations UAB. from Chain Tech S.R.L. or any existing Cashaa subsidiary companies.</p>
  
  <p>It bears specific notice that Crypto Innovations UAB is a new company with a completely different set of directors who have never held any similar position in Cashaa or any of its subsidiaries. Any grievances before 30 may 2022 related to Cashaa services will not be entertained by Crypto Innovations UAB.</p>`
  constructor(
    private activityService: ActivityService,
    private router: Router,
    private commonSavedData: CommonSavedData,
    private fb: FormBuilder,
    private modalService: ModalService
  ) {
    const userType = sessionStorage.getItem(USER_TYPE);
    if (+userType === UserType.Personal) {
      this.isPersonal = true;
    }
  }
  ngOnInit(): void {
    this.getTransactionsList();
    this.checkConsent();
    // this.showEventImage();
    //  if(sessionStorage.getItem('isConsent') === 'false') {
    //  this.modalService.openTermsConditionPopup(this.termsAndCondition);
    //  }
    // this.checkStakingBanner();
    this.searchForm = this.fb.group({
      currency: [""]
    });
  }
  
  showEventImage() {
    this.modalService.openTermsConditionPopup("", 'https://cashaa.com/wp-content/uploads/2023/01/popup.jpg');
  }

  checkConsent() {
    this.activityService.checkConsent().subscribe((res:any) => {
      if(!res.isConsent) {
        this.modalService.openTermsConditionPopup(this.termsAndCondition);
      }
    })
  }

  checkStakingBanner() {
    let filterData = {
      activityType: "14",
      fromDate: "",
      toDate: "",
      symbol: [],
      searchText: "",
    };
    this.activityService.getRecentTransactions(filterData).subscribe(
      (res) => {
        if (res.length !== 0) {
        } else {
          filterData.activityType = "";
          this.activityService.getRecentTransactions(filterData).subscribe(
            (res) => {
              let flag = false;
              res.forEach((dateRow) => {
                dateRow.activitylist.forEach((row) => {
                  if (row.mondaylist !== null) {
                    flag = true;
                  }
                });
              });

              if (!flag && this.isPersonal) {
                this.showStakingBanner = true;
              }
            },
            (err) => { }
          );
        }
      },
      (err) => { }
    );
  }

  getTransactionsList(): void {
    this.loading = true;
    const filterData = new SearchFilter("", "", "", [],"");
    this.activityService.getRecentTransactions(filterData).subscribe(
      (res) => {
        this.ledgerList = res;
        this.loading = false;
      },
      (err) => {
        this.error = true;
        this.loading = false;
      }
    );
  }

  onActivityTypeChange(activity: IFormSelect) {
    this.loading = true;
    this.activityType = activity.value;
    const filterData = new SearchFilter(
      this.activityType,
      this.fromDate,
      this.toDate,
      this.symbol,
      this.searchText
    );

    this.activityService.getRecentTransactions(filterData).subscribe(
      (filterList) => {
        this.ledgerList = filterList;
        this.loading = false;
      },
      (err) => {
        this.loading = false;
        this.error = true;
      }
    );
  }

  onFromDateChange(date: any) {
    this.fromDate = date.actualDateFormatted;

    const filterData = new SearchFilter(
      this.activityType,
      this.fromDate,
      this.toDate,
      this.symbol,
      this.searchText
    );

    this.activityService.getRecentTransactions(filterData).subscribe(
      (filterList) => {
        this.ledgerList = filterList;
      },
      (err) => {
        this.error = true;
      }
    );
  }

  onToDateChange(date: any) {
    this.toDate = date.actualDateFormatted;

    const filterData = new SearchFilter(
      this.activityType,
      this.fromDate,
      this.toDate,
      this.symbol,
      this.searchText
    );
    this.activityService.getRecentTransactions(filterData).subscribe(
      (filterList) => {
        this.ledgerList = filterList;
      },
      (err) => {
        this.error = true;
      }
    );
  }
  onCurrencyTypeChange(event) {
    this.symbol = [event.symbol];
    const filterData = new SearchFilter(
      this.activityType,
      this.fromDate,
      this.toDate,
      this.symbol,
      this.searchText
    );
    this.activityService.getRecentTransactions(filterData).subscribe(
      (filterList) => {
        this.ledgerList = filterList;
        // this.symbol = [];
      },
      (err) => {
        this.error = true;
      }
    );
  }

  onSearchTextChanged(searchText: string) {
    this.searchText = searchText;
    const filterData = new SearchFilter(
      this.activityType,
      this.fromDate,
      this.toDate,
      this.symbol,
      this.searchText
    );
    this.activityService.getRecentTransactions(filterData).subscribe(
      (filterList) => {
        this.ledgerList = filterList;
      },
      (err) => {
        this.error = true;
      }
    );
  }
  stakingUrl() {
    this.router.navigateByUrl(this.commonSavedData.getStakingUrl());
  }

  getFeesStatus(item: ILedgerList): boolean {
    return item.ledgerType === 13 || item.ledgerType === 15 || item.ledgerType === 17 || 
    item.ledgerType === 18 || item.ledgerType === 21 || item.ledgerType === 24;
  }

  reloadTransaction(): void {
    this.showFilter = false;
    this.symbol = [];
    this.activityType = '';
    this.getTransactionsList();
  }
  updateLedgerList(event){
    if(event.isSelected) {
    this.symbol.push(event.currency);
    } else {
     this.symbol = this.symbol.filter(item => item !== event.currency);
    }
    const filterData = new SearchFilter(
      this.activityType,
      this.fromDate,
      this.toDate,
      this.symbol,
      this.searchText
    );
    this.loading = true;
    this.activityService.getRecentTransactions(filterData).subscribe(
      (filterList) => {
        this.loading = false;
        this.ledgerList = filterList;
      },
      (err) => {
        this.loading = false;
        this.error = true;
      }
    );  
  }

}

import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { IMyOptions } from "ng-uikit-pro-standard";
import { ToastrService } from "ngx-toastr";
import { IS_CAS_SWAP_Enabled, UNABLE_TO_PERFORM_ERROR } from "src/app/app.constants";
import { ICurrency } from "src/app/core/models/currency";
import { IFormSelect } from "src/app/core/models/form-select";
import { ICustomer } from "src/app/core/models/recipient/recipient-add-usd";
import {
  ISwapListData,
  ISwapReceivingAddress,
} from "src/app/core/models/swap/swap";
import { ModalService } from "src/app/core/services/modal/modal.service";
import { ValidatorUtility } from "src/app/core/utility/validator.utility";
import { SwapService } from "../services/swap.service";
import { Status } from "src/app/core/enums/swapEnum";
import { environment } from "src/environments/environment";
import { Router } from "@angular/router";

const customerType = require("../../../assets/json/customerType.json");
const addressdocumentTypes = require("../../../assets/json/addressProofDocumentType.json");
const identitydocumentTypes = require("../../../assets/json/identityProofDocumentType.json");

@Component({
  selector: "cashaa-swap-home",
  templateUrl: "./swap-home.component.html",
  styleUrls: ["./swap-home.component.scss"],
})
export class SwapHomeComponent implements OnInit {
  depositAddress: string;
  receivingWallet: ISwapReceivingAddress;
  listData: ISwapListData[];
  copiedValue: string;
  copiedAddress: boolean;
  loader: boolean;
  receivedBalance = 0;
  public form: FormGroup;
  status = Status;
  walletOptions;
  walletEnum = {
    1: "Cashaa Wallet",
    2: "Trust Wallet",
    3: "Ledger NanoS",
    4: "Binance DEX",
    5: "Other BEP2 Wallet",
  };
  constructor(
    private swapService: SwapService,
    private toastrService: ToastrService,
    private fb: FormBuilder,
    private router: Router
  ) {}

  ngOnInit(): void {
    if(sessionStorage.getItem(IS_CAS_SWAP_Enabled) == 'false') {
      this.router.navigateByUrl('/activity')
    }
    this.createForm();
    this.getAddress();
    //this.getReceivingWallet();
    this.getList();
    this.walletOptions = [
      { value: 1, label: "Cashaa Wallet" },
      { value: 2, label: "Trust Wallet" },
      { value: 3, label: "Ledger NanoS" },
      { value: 4, label: "Binance DEX" },
      { value: 5, label: "Other BEP2 Wallet" },
    ];

    this.form.controls.walletType.valueChanges.subscribe((ev) => {
      this.form.controls["address"].clearValidators();
      this.form.controls["address"].updateValueAndValidity({
        onlySelf: true,
      });
      this.form.updateValueAndValidity({ onlySelf: true });
      if (ev !== 1) {
        this.form.controls["address"].setValidators(Validators.required);
      }
      this.form.updateValueAndValidity();
    });
  }
  createForm(): void {
    this.form = this.fb.group({
      walletType: ["", Validators.required],
      address: [""],
    });
  }

  /**
   * Open add recipient modal
   */
  getAddress(): void {
    this.loader = true;
    this.swapService
      .getDepositAddress({
        currencyId: "ffc6c1df-073f-4f50-2534-08d7b39928d1",
        currency: "ETH",
      })
      .subscribe(
        (res) => {
          this.depositAddress = res.address;
          this.loader = false;
        },
        (err) => {
          this.loader = false;
          if (err.error.message) {
            this.toastrService.error(err.error.message);
          } else {
            this.toastrService.error(UNABLE_TO_PERFORM_ERROR);
          }
        }
      );
  }
  getReceivingWallet(): void {
    this.loader = true;
    this.swapService.getReceivingWallet().subscribe(
      (res) => {
        if (res.walletType) {
          this.receivingWallet = res;
        }
        this.loader = false;
      },
      (err) => {
        this.loader = false;
        if (err.error.message) {
          this.toastrService.error(err.error.message);
        } else {
          this.toastrService.error(UNABLE_TO_PERFORM_ERROR);
        }
      }
    );
  }
  getList(): void {
    this.loader = true;
    this.swapService.getList().subscribe(
      (res) => {
        if (res) {
          this.listData = res;
          this.listData.forEach((row) => {
            this.receivedBalance += row.amount;
          });
        } else {
          this.receivedBalance = 0;
        }
        this.loader = false;
      },
      (err) => {
        this.loader = false;
        if (err.error.message) {
          this.toastrService.error(err.error.message);
        } else {
          this.toastrService.error(UNABLE_TO_PERFORM_ERROR);
        }
      }
    );
  }

  copyItem(value): void {
    if (value && value !== this.copiedValue) {
      this.copiedValue = value.trim();
      navigator.clipboard.writeText(value);
      this.toastrService.toastrConfig.preventDuplicates = true;

      this.toastrService.success("Text copied to clipboard");
    }
  }

  onSubmit(val) {
    this.loader = true;
    let data: ISwapReceivingAddress = {
      walletType: val.walletType,
      verified: false,
    };
    if (val.walletType !== 1) {
      data.address = val.address;
    }
    this.swapService.submitReceivingWallet(data).subscribe(
      (res) => {
        this.receivingWallet = res;
        this.loader = false;
      },
      (err) => {
        this.loader = false;
        if (err.error.message) {
          this.toastrService.error(err.error.message);
        } else {
          this.toastrService.error(UNABLE_TO_PERFORM_ERROR);
        }
      }
    );
  }
  resendVerification() {
    this.loader = true;
    this.swapService.resendVerificationEmail().subscribe(
      (res) => {
        this.toastrService.success(res.message);
        this.loader = false;
      },
      (err) => {
        this.loader = false;
        if (err.error.message) {
          this.toastrService.error(err.error.message);
        } else {
          this.toastrService.error(UNABLE_TO_PERFORM_ERROR);
        }
      }
    );
  }

  copyMemoAddress(): void {
    if (this.depositAddress) {
      this.copiedAddress = true;
      navigator.clipboard.writeText(this.depositAddress);
      this.toastrService.toastrConfig.preventDuplicates = true;
      this.toastrService.success("Text copied to clipboard");
      setTimeout(() => (this.copiedAddress = false), 2000);
    }
  }

  getValue(id: string): string {
    return environment.NETWORK_ERC20 + id;
  }
}

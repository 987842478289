import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { RecipientAddComponent } from "./recipient-add/recipient-add.component";
import { RecipientHomeComponent } from "./recipient-home/recipient-home.component";
import { RecipientListComponent } from "./recipient-list/recipient-list.component";
import { AuthGuardService } from "./../core/services/auth-guard/auth-guard.service";
import { RecipientAddressConfirmationComponent } from "./recipient-address-confirmation/recipient-address-confirmation.component";
const routes: Routes = [
  {
    path: "recipient",
    component: RecipientHomeComponent,
    children: [
      {
        path: "list",
        component: RecipientListComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: "add",
        component: RecipientAddComponent,
        canActivate: [AuthGuardService],
      },
    ],
  },

  {
    path: "account/activityconfirmation",
    component: RecipientAddressConfirmationComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class RecipientRoutingModule {}

<section class="">
  <h3 class="mb-5  text-center" style="margin-top: 50px;color: #1840a4; font-weight: 700;">
    <u>Invite via email</u>
  </h3>
</section>
<div class="box-center">
  <div class="mb-5 row d-flex align-items-flex-end">
    <div class="col-9">
      <cashaa-form-input [form]="form" [control]="form.controls.email" name="email" label="Enter friend's email address"
        type="email">
      </cashaa-form-input>
    </div>
    <button mdbBtn block="true" type="button" style="height: 48px;"
      class="col-3 ml-1 mb-0 px-1 add-button py-2 btn btn-primary waves-effect waves-light btn-block font-weight-normal"
      mdbWavesEffect (click)="addEmailAddress()" [disabled]="loader">
      Add
    </button>
  </div>
  <div *ngFor="let email of emailAddress; let i = index"
    class="w-100 text-left d-flex justify-content-between align-items-center mb-3">
    <h6 class="font-weight-normal mr-4 mb-0">{{ email }}</h6>
    <mdb-icon far icon="trash-alt" class="blue-text" size="2x" (click)="deleteEmail(i)"></mdb-icon>
  </div>
  <button mdbBtn block="true" type="button"
    class="rounded-sm position-relative btn btn-primary waves-effect waves-light btn-block font-weight-normal"
    mdbWavesEffect [disabled]="loader || emailAddress?.length == 0" (click)="invitePeople()">
    <span class="spinner-border spinner-border-sm mr-2" *ngIf="loader" role="status" aria-hidden="true"></span>
    Send invites
  </button>

  <h6 routerLink="/invite/link" class="font-weight-bolder blue-text pt-4 text-center">
    <u>Send another way</u>
  </h6>
</div>
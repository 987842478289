import { ModalService } from "src/app/core/services/modal/modal.service";
import { Component, OnInit, Input } from "@angular/core";
import { ILedgerList } from "src/app/core/models/activity/recent-transaction-list";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import {
  TRANSACTION_SUCCESS_CANCELLED,
  UNABLE_TO_PERFORM_ERROR,
  VERIFICATION_MESG,
} from "src/app/app.constants";
import { FiatService } from "src/app/fiat/services/fiat.service";
import { ActivityService } from "../services/activity.service";
import { CommonSavedData } from "src/app/shared/services/commonSavedData.service";
import { Subscription } from "rxjs";
import { environment } from "src/environments/environment";

@Component({
  selector: "cashaa-activity-balance-transfer",
  templateUrl: "./activity-balance-transfer.component.html",
  styleUrls: ["./activity-balance-transfer.component.scss"],
})
export class ActivityBalanceTransferComponent implements OnInit {
  @Input() ledger: ILedgerList;
  timerFlag;
  timerStart;
  timerTime;
  disableBtn;
  verificationClickCount = 0;
  isCancelFlag: boolean = false;
  isCancelLoading: boolean = false;
  isStackingLocked: boolean = false;
  sub: Subscription
  constructor(
    private modalService: ModalService,
    private router: Router,
    private toastrService: ToastrService,
    private fiatService: FiatService,
    private activityService: ActivityService,
    private commonSavedData: CommonSavedData
  ) { }

  ngOnInit(): void {
    this.isCancelFlag =
      this.ledger.status === 9 && this.ledger.withdrawConfirm === false;
    this.isStackingLocked =
      this.ledger.status === 11 && this.ledger.islocked === true;
  }

  openDetailBox(): void {
    this.modalService.openActivityTransferDetail(this.ledger);
  }

  help(): void {
    this.modalService.openTicketCreateModal(this.ledger.ledgerType);
  }

  cancel(id?): void {
    this.modalService.openConfirmationModal("Do you want to cancel the transaction?");
    this.sub = this.commonSavedData.getConfirmationBoxStatus().subscribe((status: boolean) => {
      if (status) {
        this.isCancelLoading = true;
        this.fiatService.cancelTransaction(id).subscribe(
          (res) => {
            if (res.message === "success") {
              this.isCancelFlag = true;
              this.isCancelLoading = false;
              this.activityService.emitBalanceRefreshStatus(true);
              this.toastrService.success(TRANSACTION_SUCCESS_CANCELLED);
            }
          },
          (err) => {
            this.isCancelLoading = false;
            this.toastrService.error(UNABLE_TO_PERFORM_ERROR);
          }
        );
      }
      this.sub.unsubscribe();
    });
  }

  isCancel(): boolean {
    return this.isCancelFlag;
  }

  isCancelStacking(): boolean {
    return this.isStackingLocked;
  }

  isSuccessClass(step): boolean {
    if (this.ledger.ledgerType === 11) {
      if (step === 4) {
        if (this.ledger.islocked) {
          return false;
        } else {
          return true;
        }
      }
      return true;
    } else if (this.isFiatAndAccount() || this.isCrypto()) {
      switch (step) {
        case 2:
          if (this.ledger.status === 1 && !this.ledger.withdrawConfirm) {
            return true;
          } else if (this.ledger.status === 2 && this.ledger.withdrawConfirm) {
            return true;
          } else if (this.ledger.withdrawConfirm && this.ledger.status === 1) {
            return true;
          } else if (this.isCancelFlag) {
            return true;
          }
          return false;
        case 3:
          if (this.ledger.status === 1 && this.ledger.withdrawConfirm) {
            return true;
          } else if (this.ledger.status === 2 && this.ledger.withdrawConfirm) {
            return true;
          } else if (this.isCancelFlag) {
            return true;
          }
          return false;
        case 4:
          if (this.ledger.status === 2 && this.ledger.withdrawConfirm) {
            return true;
          }
          return false;
      }
    } else {
      return true;
    }
  }

  timerExpire() {
    if (this.verificationClickCount < 3) {
      this.timerFlag = false;
      this.timerStart = false;
    } else {
      this.disableBtn = true;
      this.timerStart = false;
    }
  }

  getVerificaionLink() {
    this.fiatService.isCryptoReSendVerification(this.ledger.id).subscribe(
      (flag) => {
        if (flag.message) {
          this.timerTime = 60;
          this.timerFlag = true;
          this.timerStart = true;
          this.verificationClickCount++;
          this.toastrService.success(VERIFICATION_MESG);
        }
      },
      (err) => {
        if (err.error.message) {
          this.toastrService.error(err.error.message);
        } else {
          this.toastrService.error(UNABLE_TO_PERFORM_ERROR);
        }
      }
    );
  }
  getCurrencyName(curr): string {
    if (curr === "AUSD") return "USD";
    if (curr === "DOG") return "DOGE";
    else return curr;
  }
  isCrypto(): boolean {
    return this.ledger.currency === "ETH" ||
      this.ledger.currency == "BTC" ||
      this.ledger.currency == "CAS" ||
      this.ledger.currency == "BNB" ||
      this.ledger.currency == "USDT" ||
      this.ledger.currency == "DOG" ||
      this.ledger.currency == "XRP" ||
      this.ledger.currency == "LTC" ||
      this.ledger.currency == "ADA" ||
      this.ledger.currency == "BCH" ||
      this.ledger.currency == "BNB" ||
      this.ledger.currency == "BNB20" ||
      this.ledger.currency == "LINK" ||
      this.ledger.currency == "UNI" ||
      this.ledger.currency == "USDC"
  }

  isFiatAndAccount(): boolean {
    return this.ledger.currency == "GBP" ||
    this.ledger.currency == "EUR" ||
    this.ledger.currency == "AUSD" ||
    this.ledger.currency == "USD" ||
    this.ledger.currency == "SGD" ||
    this.ledger.currency == "AUD" ||
    this.ledger.currency == "CNH" ||
    this.ledger.currency == "CAD" ||
    this.ledger.currency == "CHF" ||
    this.ledger.currency == "NZD" ||
    this.ledger.currency == "JPY" ||
    this.ledger.currency == "MXN" ||
    this.ledger.currency == "SHIB"

  }

  getRef(): string {
    if (this.ledger.currency === 'CAS') {
      return this.ledger.networkType ? this.getRefValue(this.ledger.currency) : environment.CAS + this.ledger.transactionId;
    } else if (this.ledger.currency === 'BNB') {
      return this.ledger.networkType ? this.getRefValue(this.ledger.currency) : environment.BNB + this.ledger.transactionId;
    } else if (this.ledger.currency === 'BCH') {
      return this.ledger.networkType ? this.getRefValue(this.ledger.currency) : environment.BCH + this.ledger.transactionId;
    } else if (this.ledger.currency === 'XRP') {
      return this.ledger.networkType ? this.getRefValue(this.ledger.currency) : environment.XRP + this.ledger.transactionId;
    } else if (this.ledger.currency === 'LTC') {
      return this.ledger.networkType ? this.getRefValue(this.ledger.currency) : environment.LTC + this.ledger.transactionId;
    } else if (this.ledger.currency === 'DOG') {
      return this.ledger.networkType ? this.getRefValue(this.ledger.currency) : environment.DOG + this.ledger.transactionId;
    } else if (this.ledger.currency === 'ETH') {
      return this.ledger.networkType ? this.getRefValue(this.ledger.currency) : environment.ETH + this.ledger.transactionId;
    } else if (this.ledger.currency === 'USDT') {
      return this.ledger.networkType ? this.getRefValue(this.ledger.currency) : environment.USDT + this.ledger.transactionId;
    } else if (this.ledger.currency === 'BTC') {
      return this.ledger.networkType ? this.getRefValue(this.ledger.currency) : environment.BTC + this.ledger.transactionId;
    } else if (this.ledger.currency === 'UNI') {
      return this.ledger.networkType ? this.getRefValue(this.ledger.currency) : environment.UNI + this.ledger.transactionId;
    } else if (this.ledger.currency === 'LINK') {
      return this.ledger.networkType ? this.getRefValue(this.ledger.currency) : environment.LINK + this.ledger.transactionId;
    } else if (this.ledger.currency === 'USDC') {
      return this.ledger.networkType ? this.getRefValue(this.ledger.currency) : environment.USDC + this.ledger.transactionId;
    }
  }

  getRefValue(currency: string): string {
    if (currency === 'LINK' || currency === 'USDC' || currency === 'UNI' || currency === 'USDT') {
      if (this.ledger.networkType == 1) {
        return environment.NETWORK_ERC20 + this.ledger.transactionId;
      } else if (this.ledger.networkType == 2) {
        return environment.NETWORK_BEP20 + this.ledger.transactionId;
      }
    }

    if ((currency === 'CAS' || currency === 'BNB' || currency === 'ETH' || currency === 'BCH' || currency === 'XRP' || currency === 'DOG') &&
      this.ledger.networkType == 2) {
      return environment.NETWORK_BEP20 + this.ledger.transactionId;
    }

    if (currency === 'CAS' && this.ledger.networkType == 3) {
      return environment.NETWORK_POLYGON + this.ledger.transactionId;
    }
    
  }
}

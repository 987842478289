import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { Observable } from "rxjs";
import {  HttpClient } from "@angular/common/http";
import { IUserBalance } from "../../models/user-balance";
import { IUserInterestList, IUserPlan, IUserPlanDetail, PlanDetails } from "../../models/user-plan/user-detail";


@Injectable({
  providedIn: "root",
})
export class UserService {

  /** Api url */
  private apiUrl = environment.api;

  constructor(private http: HttpClient) { }

  /**
   * Get User balance
   */
  getUserBalance(): Observable<IUserBalance[]> {
    return this.http.get<IUserBalance[]>(`${this.apiUrl}/balance`);
  }

  /**
   * Get IP Information of User
   */
  // getIpInfo(): Observable<IIpInfoHttp> {
  //   const header = new HttpHeaders({ "No-Auth": "True" });
  //   return this.http.get<IIpInfo>(this.ipInfoUrl, {
  //     headers: header,
  //   });
  // }

  getPlanDetailsByCurrencyId(currencyId: string): Observable<IUserPlan[]> {
    return this.http.get<IUserPlan[]>(`${this.apiUrl}/userplan/getbycurrency?CurrencyId=${currencyId}`);
  }

  getUserPlanDetail(): Observable<IUserPlanDetail> {
    return this.http.get<IUserPlanDetail>(`${this.apiUrl}/userplan/getuserplandetails`);
  }

  getAllPlansDetails(): Observable<IUserPlan[]> {
    return this.http.get<IUserPlan[]>(`${this.apiUrl}/userplan/allplanlist`);
  }
  getInterestList(): Observable<IUserInterestList> {
    return this.http.get<IUserInterestList>(`${this.apiUrl}/userplan/getInterestList`);
  }
  getPlanList(): Observable<PlanDetails> {
    return this.http.get<PlanDetails>(`${this.apiUrl}/userplan/plannamelist`);
  }
  getFlexAndFdDetail(): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/activity/getflexandfddetails`);
  }

  subscribeUnsubscribe(
    currencyId: string
  ): Observable<{ statusCode: number; message: string }> {
    const payload = {
      currencyId: currencyId
    }
    return this.http.post<{ statusCode: number; message: string }>(
      `${this.apiUrl}/userplan/flexsubscribe`,
      payload
    );
  }
}

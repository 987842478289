import { Component, OnDestroy, OnInit, ViewChild, EventEmitter, Output } from "@angular/core";
import { ActivityService } from "../services/activity.service";
import { UserService } from "src/app/core/services/user/user.service";
import { IUserBalance } from "src/app/core/models/user-balance";
import { CarouselComponent } from "ng-uikit-pro-standard";
import { SubSink } from "subsink";

@Component({
  selector: "cashaa-activity-balance-list",
  templateUrl: "./activity-balance-list.component.html",
  styleUrls: ["./activity-balance-list.component.scss"],
})
export class ActivityBalanceListComponent implements OnInit, OnDestroy {
  @ViewChild("slide", { static: true }) slideController: CarouselComponent;

  /** Balance data */
  balanceData?: IUserBalance[];

  slides: any = [[]];

  loading = false;

  subSink = new SubSink();
  @Output() selectedCurrency = new EventEmitter();
  creditInterest;
  currencyInfo;
  constructor(
    private activityService: ActivityService,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    this.getCurrencyInfo();
    // this.getUserPlanDetails();
    this.subSink.add(
      this.activityService.getBalanceStatus().subscribe((data) => {
        if (data) {
          this.getBalance();
        }
      }));
  }

  getUserPlanDetails() {
    this.userService.getUserPlanDetail().subscribe(res => {
      console.log(res);
      this.creditInterest = res.borrowInterest;
    }, err => {

    })
  }

  /**
   * Get balance data
   */
  getBalance(): void {
    this.loading = true;
    this.userService.getUserBalance().subscribe((balanceData) => {
      this.loading = false;
      if (balanceData) {
        this.balanceData = balanceData;
        // this.currencyInfo.filter(item => item.currencyId == this.currencyId)
        for(let i = 0; i < this.currencyInfo.length; i++) {
          for(let j = 0; j < this.balanceData.length; j++)
          if(this.balanceData[j]['currencyId'] == this.currencyInfo[i].currencyId) {
            this.balanceData[j]['borrowAt'] = this.currencyInfo[i].borrowAt;
            this.balanceData[j]['loyaltyPlanName'] = this.currencyInfo[i].loyaltyPlanName;
            this.balanceData[j]['upgradeTo'] = this.currencyInfo[i].upgradeTo;
            this.balanceData[j]['fLexTerm'] = this.currencyInfo[i].fLexTerm;
            this.balanceData[j]['fixedTerm'] = this.currencyInfo[i].fixedTerm;
            this.balanceData[j]['earnUpTo'] = this.currencyInfo[i].earnUpTo;
            this.balanceData[j]['longEarnUpTo'] = this.currencyInfo[i].longEarnUpTo;
            this.balanceData[j]['minFlexAmount'] = this.currencyInfo[i].minFlexAmount;
            this.balanceData[j]['isFlexSubscribed'] = this.currencyInfo[i].isFlexSubscribed;
            this.balanceData[j]['casBonus'] = this.currencyInfo[i].casBonus;
            
            console.log('this.balanceData:', this.balanceData);
          }
          
        }
        
        // let otherArr = this.balanceData.filter(a => a.currencyType !== 'CAS');
        // const CASDetails = this.balanceData.filter(a => a.currencyType === 'CAS');
        // for (let i = 1; i < otherArr.length; i++) {
        //   for (let j = 0; j < i; j++) {
        //     if ((otherArr[i].available + (otherArr[i].onhold || 0)) > (otherArr[j].available + (otherArr[j].onhold || 0))) {
        //       let x = otherArr[i];
        //       otherArr[i] = otherArr[j];
        //       otherArr[j] = x;
        //     }
        //   }
        // }
        // this.balanceData = [...CASDetails, ...otherArr];
        if (window.matchMedia("(min-width: 1199px)").matches) {
          this.slides = this.chunk(this.balanceData, 4);
        } else if (window.matchMedia("(min-width: 991px)").matches) {
          this.slides = this.chunk(this.balanceData, 3);
        } else if (window.matchMedia("(min-width: 767px)").matches) {
          this.slides = this.chunk(this.balanceData, 2);
        } else {
          this.slides = this.chunk(this.balanceData, 1);
        }
      }
    });
  }

  // getCurrencyInfo() {
  //   this.userService.getFlexAndFdDetail().subscribe((res) => {
  //     console.log('res:  ', res);
  //     this.currencyInfo = res;
   
  //   }, err => {
  //     this.loading = false;
  //   });
  // }


  chunk(arr, chunkSize): any {
    let R = [];
    for (let i = 0, len = arr.length; i < len; i += chunkSize) {
      R.push(arr.slice(i, i + chunkSize));
    }
    return R;
  }

  next(): void {
    this.slideController.nextSlide();
  }

  previous(): void {
    this.slideController.previousSlide();
  }

  ngOnDestroy(): void {
    this.subSink.unsubscribe();
  }
  updateLedgerList(event) {
    console.log(event);
    this.selectedCurrency.emit(event);
  }

  getCurrencyInfo() {
    this.userService.getFlexAndFdDetail().subscribe((res) => {
      this.currencyInfo = res;
      this.getBalance();
    }, err => {
      this.loading = false;
    });
  }
}

import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { FiatFlowWrapperComponent } from "./fiat-flow-wrapper/fiat-flow-wrapper.component";
import { AuthGuardService } from "../core/services/auth-guard/auth-guard.service";

const routes: Routes = [
  {
    path: "fiat",
    component: FiatFlowWrapperComponent,
    canActivate: [AuthGuardService],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class FiatRoutingModule {}

import { KycCustomerVerificationEmailComponent } from "./kyc-customer-verification-email/kyc-customer-verification-email.component";
import { KycVerificationEmailComponent } from "./kyc-verification-email/kyc-verification-email.component";
import { KycBusinessComponent } from "./kyc-business/kyc-business.component";
import { KycPersonalComponent } from "./kyc-personal/kyc-personal.component";
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { KycBusinesApplicationStatusComponent } from "./kyc-busines-application-status/kyc-busines-application-status.component";
import { AuthGuardService } from "./../core/services/auth-guard/auth-guard.service";
import { KycUsPersonalHomeComponent } from "./kyc-us-personal-home/kyc-us-personal-home.component";
const routes: Routes = [
  {
    path: "kyc",
    children: [
      {
        path: "personal",
        component: KycPersonalComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: "US/personal",
        component: KycUsPersonalHomeComponent,
        canActivate: [AuthGuardService],
      },

      {
        path: "business",
        component: KycBusinessComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: "application-status",
        component: KycBusinesApplicationStatusComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: "verification-email",
        component: KycVerificationEmailComponent,
      },
      {
        path: "customer-verification",
        component: KycCustomerVerificationEmailComponent,
      },
    ],
  },
  {
    path: "payment",
    component: KycBusinesApplicationStatusComponent,
    canActivate: [AuthGuardService]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class KycRoutingModule {}

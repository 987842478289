import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { IMyOptions } from "ng-uikit-pro-standard";
import { ToastrService } from "ngx-toastr";
import { IFormSelect } from "src/app/core/models/form-select";
import {
  IDocuments,
  IUSPersonal,
} from "src/app/core/models/kyc-us-personal/us-personal";
import { ValidatorUtility } from "src/app/core/utility/validator.utility";
import { KycService } from "../services/kyc.service";

const personalDocumentType = require("../../../assets/json/personalKycDocumentType.json");

@Component({
  selector: "cashaa-kyc-us-personal-documents",
  templateUrl: "./kyc-us-personal-documents.component.html",
  styleUrls: ["./kyc-us-personal-documents.component.scss"],
})
export class KycUsPersonalDocumentsComponent implements OnInit {
  public form: FormGroup;
  @Output()
  public continue = new EventEmitter<any>();
  public documentType: IFormSelect[] = [];
  isDataLoaded: boolean = false;
  isSameDocument: boolean;
  myDatePickerOptions: IMyOptions = {
    minYear: 1900,
    maxYear: 2099,
  };
  constructor(
    private fb: FormBuilder,
    private kycService: KycService,
    private toastrService: ToastrService
  ) {}

  ngOnInit(): void {
    this.getDocumentType();
    this.createForm();
    this.addItem();
    this.kycService.getKycUsPersonal().subscribe((data: IUSPersonal) => {
      if (data) {
        this.isDataLoaded = true;
        this.loadFormData(data);
      }
    });
  }

  loadFormData(data: IUSPersonal): void {
    if (data) {
      let index = 0;
      this.documents.controls.forEach((form: FormGroup) => {
        form.controls["docType"].setValue(data.documents[index].docType);
        form.controls["docIdentifier"].setValue(
          data.documents[index].docIdentifier
        );
        form.controls["docExpiryDate"].setValue(
          data.documents[index].docExpiryDate
        );
        index = index + 1;
      });
    }
  }

  get documents(): FormArray {
    return this.form.get("documents") as FormArray;
  }

  createForm(): void {
    this.form = this.fb.group({
      documents: this.fb.array([]),
    });
  }

  removeItem(index: number): void {
    this.documents.removeAt(index);
  }

  createItem(): FormGroup {
    return this.fb.group({
      docType: ["", [ValidatorUtility.Required]],
      docIdentifier: [
        "",
        [
          ValidatorUtility.Required,
          Validators.maxLength(35),
        ],
      ],
      docExpiryDate: ["", ValidatorUtility.Required],
    });
  }

  getDocumentType(): void {
    this.documentType = personalDocumentType;
  }

  addItem(): void {
    if (this.documents.value.length >= 2 || this.form.invalid) {
      return;
    }
    let group_1: FormGroup = this.createItem();
    group_1.get("docType").setValue("D003");
    // group_1.get('docType').disable();
    this.documents.push(group_1);

    let group_2: FormGroup = this.createItem();
    group_2.get("docType").setValue("D006");
    // group_2.get('docType').disable();
    this.documents.push(group_2);
  }

  selectChange(d) {
    let documents: IDocuments[] = this.form.value.documents as IDocuments[];

    if (
      documents[0].docType &&
      documents[1].docType &&
      documents[0].docType === documents[1].docType
    ) {
      this.isSameDocument = true;
      this.toastrService.error("Please select document.");
    } else {
      this.isSameDocument = false;
    }
  }

  onSubmit(data: any) {
    let documents: IDocuments[] = data.documents as IDocuments[];

    this.continue.emit(
      documents.map((doc: IDocuments) => {
        doc.docIdentifier = Number(doc.docIdentifier);
        return doc;
      })
    );
  }
}

import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FileUpload, IFile } from 'src/app/core/models/Lerex/enum-load';
import { ValidatorUtility } from 'src/app/core/utility/validator.utility';
const identitydocumentTypes = require("../../../assets/json/identityProofDocumentType.json");

@Component({
  selector: 'cashaa-kyc-personal-identity-proof',
  templateUrl: './kyc-personal-identity-proof.component.html',
  styleUrls: ['./kyc-personal-identity-proof.component.scss']
})
export class KycPersonalIdentityProofComponent implements OnInit {
  identityProofOptions;
  identityFront: IFile = new FileUpload(null);
  identityBack: IFile = new FileUpload(null);
  form: FormGroup;
  count: number = 0;
  @Output() continue = new EventEmitter<any>();
  constructor( private fb: FormBuilder) { }

  ngOnInit(): void {
    this.identityProofOptions = identitydocumentTypes;
    this.identityFront.docType = "IdentityFront";
    this.identityBack.docType = "IdentityBack";
    this.form = this.fb.group({
      identitydocumentType: ["", ValidatorUtility.Required],
  });
}

onAddressSubmit(): void  {
  this.continue.next(this.form.value.identitydocumentType);
}

status(event) {
  ++this.count;
}
}

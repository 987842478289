<div
  class="currency-box p-2 text-center position-relative"
  [ngClass]="selected ? 'selected' : ''"
  (click)="onCurrencySelect()"
>
  <img
    [src]="'../../../../assets/icons/' + currency + '.png'"
    class="icon mb-2"
  />
  <p class="font-weight-normal mb-2">{{ currencyName }}</p>
  <p class="small text-gray">
    {{ currentRate | number: "0.0-4" }} {{ convert }}
  </p>

  <img
    *ngIf="selected"
    class="tick position-absolute"
    src="../../../../assets/media/tick.png"
  />
</div>

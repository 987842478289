import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { TradeHomeComponent } from "./trade-home/trade-home.component";
import { AuthGuardService } from "./../core/services/auth-guard/auth-guard.service";
const routes: Routes = [
  {
    path: "trade",
    component: TradeHomeComponent,
    canActivate: [AuthGuardService],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class TradeRoutingModule {}

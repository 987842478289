import { IDepositHistory } from "./../../core/models/deposit";
import { DepositService } from "./../services/deposit.service";
import { Component, OnInit } from "@angular/core";
import { IDepositHistoryPayload } from "src/app/core/models/deposit";

@Component({
  selector: "cashaa-deposit-history",
  templateUrl: "./deposit-history.component.html",
  styleUrls: ["./deposit-history.component.scss"],
})
export class DepositHistoryComponent implements OnInit {
  pendingList: IDepositHistory[] = [];

  acceptedList: IDepositHistory[] = [];

  constructor(private depositService: DepositService) {}

  ngOnInit(): void {
    this.depositService.getDepositAmountEmitter().subscribe((data) => {
      if (data && data.currency) {
        this.getPendingHistory(data.currency);
        this.getAcceptedHistory(data.currency);
      } else {
        this.getAllHistoryPending();
        this.getAllHistoryAccepted();
      }
    });
  }

  getPendingHistory(currency: string): void {
    const data: IDepositHistoryPayload = {
      currencyId: currency,
      status: 1,
    };

    this.depositService.getDepositHistory(data).subscribe((res) => {
      this.pendingList = res;
    });
  }

  getAcceptedHistory(currency: string): void {
    const data: IDepositHistoryPayload = {
      currencyId: currency,
      status: 2,
    };

    this.depositService.getDepositHistory(data).subscribe((res) => {
      this.acceptedList = res;
    });
  }

  getAllHistoryPending(): void {
    const data: IDepositHistoryPayload = {
      currencyId: "",
      status: 1,
    };

    this.depositService.getDepositHistory(data).subscribe((res) => {
      this.pendingList = res;
    });
  }

  getAllHistoryAccepted(): void {
    const data: IDepositHistoryPayload = {
      currencyId: "",
      status: 2,
    };

    this.depositService.getDepositHistory(data).subscribe((res) => {
      this.acceptedList = res;
    });
  }
}

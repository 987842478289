import { HttpClient } from "@angular/common/http";
import { environment } from "./../../../environments/environment";
import { Observable } from "rxjs/internal/Observable";
import {
  IDepositAmount,
  IDepositDetail,
  IDepositHistory,
  IDepositHistoryPayload,
  IDepositCreate,
  IPayousDepositCreate,
} from "./../../core/models/deposit";
import { BehaviorSubject } from "rxjs";
import { Injectable } from "@angular/core";
import { IFiatSendFees } from "src/app/core/models/fiat-send";
//import institutionlist from '../../../assets/json/institutionlist_uat.json';

@Injectable({
  providedIn: "root",
})
export class DepositService {
  depositAmountEmitter = new BehaviorSubject<IDepositAmount>(undefined);

  paymentStatus = new BehaviorSubject<boolean>(undefined);
  depositDetails = new BehaviorSubject<IDepositDetail>(undefined);

  apiUrl = environment.api;

  constructor(private http: HttpClient) { }

  getDepositAmountEmitter(): Observable<IDepositAmount> {
    return this.depositAmountEmitter.asObservable();
  }

  emitDepositAmount(data: IDepositAmount): void {
    this.depositAmountEmitter.next(data);
  }

  getBankDetail(currency: string, recipientType): Observable<IDepositDetail> {
    return this.http.get<IDepositDetail>(
      `${this.apiUrl}/cashaainternalbankaccounts/${currency}?recipientType=${recipientType}`
    );
  }

  getDepositHistory(
    data: IDepositHistoryPayload
  ): Observable<IDepositHistory[]> {
    return this.http.post<IDepositHistory[]>(
      `${this.apiUrl}/InternalBankDeposit/getdepositlist`,
      data
    );
  }

  createDeposit(
    data: FormData
  ): Observable<{ statusCode: number; message: string }> {
    return this.http.post<{ statusCode: number; message: string }>(
      `${this.apiUrl}/cashaainternalbankaccounts/create`,
      data
    );
  }

  // getInstitutinalList(): Observable<any> {
  //  // return institutionlist;

  // }

  createPayousDeposit(data: IPayousDepositCreate): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/payous/payment`, data);
  }

  getDepositStatus(): Observable<boolean> {
    return this.paymentStatus.asObservable();
  }

  emitDepositStatus(data: boolean): void {
    this.paymentStatus.next(data);
  }

  getDepositDetails(): Observable<IDepositDetail> {
    return this.depositDetails.asObservable();
  }

  emitDepositDetails(data: IDepositDetail): void {
    this.depositDetails.next(data);
  }

  getFiatSendFees(symbol: string, feeMode: number, deliveryMechanism: number, transferType: number, userType: number, recipientType: number): Observable<IFiatSendFees> {
    return this.http.get<IFiatSendFees>(
      `${this.apiUrl}/transferfees/getfiatsendfee?symbol=${symbol}&feeMode=${feeMode}&deliveryMechanism=${deliveryMechanism}&transferType=${transferType}&userType=${userType}&recipientType=${recipientType}`
    );
  }
}

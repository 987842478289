<button type="button" class="close pull-right sm-close" aria-label="Close" style="cursor: pointer; z-index: 999;"
  (click)="modalRef.hide()">
  <span style="font-size: 35px;" aria-hidden="true">×</span>
</button>
<mdb-card>
  <mdb-card-body class="p-4 p-sm-5 wrapper-box position-relative">
    <div class="mb-4 text-center">
      <img src="../../../assets/media/2FA-security-mobile.png" />
    </div>
    <!-- <h4 class="text-center text-primary font-weight-bold mb-4">
      Enter your password to approve this transfer
    </h4>
    <div class="d-flex align-items-center mb-4 justify-content-center">
      <p class="small mb-0 ml-3">
        This is a security check to keep your account safe.
      </p>
    </div> -->
    <!-- <div class="position-relative mb-4">
      <cashaa-form-input name="password" type="password" text="100" [form]="authApprovalForm"
        [control]="authApprovalForm.controls.password" placeholder="Enter password">
      </cashaa-form-input>
    </div> -->
    <div *ngIf="isTwoFactorEnable">
      <h6 class="heading text-center">
        Two - Factor Authentication
      </h6>
      <div class="d-flex align-items-center mb-4">
        <img class="locker-icon" src="../../../assets/media/locker.svg" />
        <p class="small mb-0 ml-3">
          Input the 6-digit code in your Google Authentication app
        </p>
      </div>
      <div class="d-flex justify-content-center google-code my-2" (paste)="onTwoFACodePaste($event)">
        <div>
          <input type="text" pattern="[0-9]" maxlength="1" [(ngModel)]="code[0]"
            (input)="checkIfTwoFACodeIsFilled(0)" autofocus #twoFAInputs (change)="removeError()" />
        </div>
        <div>
          <input type="text" pattern="[0-9]" maxlength="1" [(ngModel)]="code[1]"
            (input)="checkIfTwoFACodeIsFilled(1)" #twoFAInputs (change)="removeError()"
            (keyup)="removeTwoFAOTPBack($event, 0)" />
        </div>
        <div>
          <input type="text" pattern="[0-9]" maxlength="1" [(ngModel)]="code[2]"
            (input)="checkIfTwoFACodeIsFilled(2)" #twoFAInputs (change)="removeError()"
            (keyup)="removeTwoFAOTPBack($event, 1)" />
        </div>
        <div>
          <input type="text" pattern="[0-9]" maxlength="1" [(ngModel)]="code[3]"
            (input)="checkIfTwoFACodeIsFilled(3)" #twoFAInputs (change)="removeError()"
            (keyup)="removeTwoFAOTPBack($event, 2)" />
        </div>
        <div>
          <input type="text" pattern="[0-9]" maxlength="1" [(ngModel)]="code[4]"
            (input)="checkIfTwoFACodeIsFilled(4)" #twoFAInputs (change)="removeError()"
            (keyup)="removeTwoFAOTPBack($event, 3)" />
        </div>
        <div>
          <input type="text" pattern="[0-9]" maxlength="1" [(ngModel)]="code[5]"
            (input)="checkIfTwoFACodeIsFilled(5)" #twoFAInputs (change)="removeError()"
            (keyup)="removeTwoFAOTPBack($event, 4)" />
        </div>
      </div>
    </div>
    <div class="font-weight-bold mb-3" *ngIf="!isTwoFactorEnable">
      <span>
        Your google 2FA is not enabled
        <a [routerLink]="['/settings']" [queryParams]="{step:'2fa'}" class="p-0" (click)="hideModal()"> click here </a> to
        enable.</span>
    </div>

    <button *ngIf="isTwoFactorEnable" [disabled]="isInProgress" mdbBtn (click)="onFormSubmit()" block="true" type="button"
      class="rounded-sm position-relative btn btn-primary waves-effect waves-light btn-block" mdbWavesEffect>
      <i class="fa-lock fas position-absolute"></i>Done
    </button>

    <!-- Progress bar -->
    <mdb-progress-bar *ngIf="isInProgress && !errorMessage" class="progress primary-color-dark position-absolute"
      mode="indeterminate">
    </mdb-progress-bar>

    <div class="m-auto" *ngIf="errorMessage">
      <mdb-error class="mt-2">{{ errorMessage }}</mdb-error>
    </div>
  </mdb-card-body>
</mdb-card>
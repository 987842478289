<section class="bg-white p-3 w-phone-100 w-75 mx-auto mt-4">
    <div class="text-center" [hidden]="!loading">
        <mdb-spinner spinnerColor="blue"></mdb-spinner>
    </div>
    <div class="d-flex justify-content-between py-3 px-1">
        <h3 class="title heading" id="myModalLabel">
            Borrow
        </h3>
        <!-- <div  class="switch-sec  font-weight-normal mr-2 ml-5"><div [ngClass]="{'checkedBorrowMore' : isBorrowMore}">Borrow more</div><label class="switch-sm" [ngClass]="{'checkedBorrowAt' : isBorrowMore}">
            <input checked="true" type="checkbox" (click)="sortNoInterestRate()">
            <span class="slider-sm round"></span></label><div [ngClass]="{'checkedBorrowAtZero' : !isBorrowMore}">Borrow at 0%</div>
        </div> -->
        <app-switch size="small" name="skip_notification" (change)="sortNoInterestRate()" [(ngModel)]="isBorrowMore"
        [labelOn]="'Borrow at 0%*'" [labelOff]="'Borrow more'" [desc]="zeroMoreDesc"></app-switch>

    </div>
    <hr>
    <div *ngIf="!showUpgradeOption" class="body px-1">
        <div class="d-flex justify-content-between border-bottom py-2">
            <div class="mb-0 text-gray font-weight-bolder ">
                <p class="mb-0">Total credit line</p>
                <h5 class="heading">${{totalCreditInUSD | number: "0.0-2"}}</h5>
            </div>
            <div class="mb-0 text-gray font-weight-bolder">
                <p class="mb-0">Available credit line</p>
                <h5 class="heading">${{totalAvailableCreditInUSD | number: "0.0-2"}}</h5>
               
            </div>
            <!-- <div class="mb-0 text-gray font-weight-bolder cursor-pointer" (click)="sortNoInterestRate()">
                Interest Rate <i class="fa"  [ngClass]="{'fa-arrow-up' : isBorrowMore, 'fa-arrow-down': !isBorrowMore}"></i>
            </div> -->
        </div>
        <br>
        <div class="">
            <div class="mb-0">
                <div class="d-flex justify-content-center mb-4">
                    <cashaa-form-select-transfer-currency (sendCurrencyChangeEvent)="onsendCurrencyChangeEvent($event)" [form]="form"
                      class="width-100" [module]="'borrow'" [amountControl]="form.controls.senderAmount" [isSendModule]="false"  [isConvertModule]="true"
                      [isSendInput]="true"  [currencyControl]="form.controls.sendercurrencyId" [controlCurrency]="sendCurrencySymbol"
                      label="Loan amount" [noAccountCurrency]="true" amountControlName="senderAmount"
                      currencyControlName="sendercurrencyId" type="send"
                      [recieveCurrencySymbol]="receiveCurrencySymbol" [currencyMap]="currencyMap">
                    </cashaa-form-select-transfer-currency>
                  </div>
                  <p class="red-text mb-1 font-weight-normal" *ngIf="creditError">
                    Amount is higher than available Credit.
                  </p>
                  <p class="red-text mb-1 font-weight-normal" *ngIf="!creditError && minAmountError && currencyType === 2">
                    Minimum loan amount should be {{minLoanAmount | number: "0.0-0"}}
                  </p> 
                  <p class="red-text mb-1 font-weight-normal" *ngIf="!creditError && minAmountError && currencyType === 1">
                    Minimum loan amount should be {{minLoanAmount | number: "0.0-4"}}
                  </p>  
            </div>
        </div>
        <div class="table-container">
            <table class="table table-bordered">
                <thead>
                  <tr>
                    <th class="heading">Estimated</th>
                    <th class="heading">Available credit line</th>
                  </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Before</td>
                        <td *ngIf="currencyType === 2">{{borrowInSameCurrency | number: "0.0-2"}}&nbsp;{{sendCurrencySymbol}}</td>
                        <td *ngIf="currencyType === 1">{{borrowInSameCurrency | number: "0.0-4"}}&nbsp;{{sendCurrencySymbol}}</td>
                    </tr>
                    <tr>
                        <td scope="row">After loan taken</td>
                        <td *ngIf="currencyType === 2">{{amountAfterLoanAvailed | number: "0.0-2"}}&nbsp;{{sendCurrencySymbol}}</td>
                        <td *ngIf="currencyType === 1">{{amountAfterLoanAvailed | number: "0.0-4"}}&nbsp;{{sendCurrencySymbol}}</td>
                    </tr>
                    <tr>
                        <td>Annual interest payable</td>
                        <td>{{annuallyInterestCharged}}%&nbsp;~({{approxDailyInterest | number: '0.0-3'}}%&nbsp;Daily)</td>
                    </tr>
                  </tbody>
            </table>
             
        </div>
        <div class="d-flex my-2">
            <mdb-checkbox [(ngModel)]="conditionsAccepted">
                <span class="font-weight-normal">I accept the
                    <a target="_blank" href="https://cashaa.com/term-condition" style="padding: 0px;">Terms of
                        Use</a></span>
            </mdb-checkbox>
        </div>
        <div class="d-flex justify-content-between">
            <button mdbBtn type="button" class="btn-sm app-secondary-btn w-50 btn" (click)="goBack()">Cancel</button>
            <button mdbBtn type="button" class="btn-sm app-primary-btn w-50" [disabled]="form.value.senderAmount <= 0 || conditionsAccepted !== true  || creditError || minAmountError || clicked" (click)="confirm()">Confirm</button>
        </div>
        <div class="disclaimer my-2">
            <h5 class="heading my-4">Credit Terms</h5>
            <h6 class="heading mb-3">Credit Line</h6>
            <ul class="credit-line">
                <li class="item" *ngIf="currencyType === 2">Minimum loan amount is {{minLoanAmount | number: "0.0-0"}}&nbsp;{{sendCurrencySymbol}}.</li>
                <li class="item" *ngIf="currencyType === 1">Minimum loan amount is {{minLoanAmount | number: "0.0-4"}}&nbsp;{{sendCurrencySymbol}}.</li>
                <!-- <li class="item">Minimum loan amount is Rs. 10,000 or USDT worth Rs. 10,000.</li> -->
                <li class="item">Loan is processed only after it is confirmed via email verification.</li>
                <li class="item">Once confirmed, loan availed is instantly credited to your account.</li>
            </ul>
    
            <h6 class="heading my-3">Repayment</h6>
            <ul class="repayment">
                <li class="item text-justify">
                    No minimum amount for repayment of loan principal amount. You can repay your loan amount partially or fully in any currency.
                     Please note exchange fees will apply if you repay your loan in any currency other than the currency in which the loan has been taken.
                </li>
                <li class="item text-justify">
                    Daily interest will be deducted automatically from your savings wallet in the same currency as the one in which the loan has been taken.
                     In case of insufficient balance in that currency, the interest will be deducted in other currencies available in your savings wallet. Once your savings wallet is exhausted, interest will be deducted from your collateral locked with us until a certain LTV ceiling is hit.
                      You will be regularly notified during each step to top up your savings wallet. Finally the pending interest will accrue to the outstanding loan amount and will lead to full liquidation of the locked collateral assets and closure of loan.
                </li>
            </ul>
        </div>
    </div>

    <div *ngIf="showUpgradeOption" class="text-center my-4">
        <img class="mb-4" src="../../../assets/images/borrow-zero.svg" alt="borrow zero">
        <h5 class="heading">To avail Borrow at 0% you need to upgrade your loyalty level</h5>
         <button class="app-success-btn btn-sm" (click)="goToPlans()">Upgrade now</button>
      </div>
</section>



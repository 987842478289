export interface IReceiveFundRequest {
    currencyId: string;
    currency: string;

}

export class ReceiveFundRequest implements IReceiveFundRequest {
    constructor(
        public currencyId: string,
        public currency: string
    ) { }
}


export interface IReceiveFundResponse {
    currencyId: string;
    address: string;
    currency: string;
    memo: string;
}


export class ReceiveFundResponse implements IReceiveFundResponse {
    constructor(
        public currencyId: string,
        public address: string,
        public currency: string,
        public memo: string
    ) { }
}




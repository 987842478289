<cashaa-sidenav>
    <section class="mh-100">
        <div class="bg-white p-4 sm-media-padding">
            <div class="d-flex sm-phone-display-none mr-3" style="height: 47px;">
                <div class="text-right mr-4 mt-2" *ngIf="!showFilter">
                    <button (click)="showFilter = true"
                        class="rounded btn-sm sm-btn-input-hover">
                        <mdb-icon fas icon="search" class="mr-1"></mdb-icon> Search Transactions
                    </button>
                </div>
                <!-- <button  routerLink="/fiat" class="btn-secondary sm-custom-sec sm-btn-send" mdbBtn
          type="button" outline="true" mdbWavesEffect>
          Send money</button> -->
                <!-- <a (click)="stakingUrl()">
          <div *ngIf="showStakingBanner" class="banner_staking"></div>
        </a> -->
            </div>
            <div class="sm-recipient-btn-padding-mobile sm-phone-display-visible" style="display: none;">
                <!-- <button routerLink="/fiat" class="btn-secondary sm-custom-sec sm-btn-send" mdbBtn type="button" outline="true"
          mdbWavesEffect>
          Send money</button> -->
                <!-- <button mdbBtn type="button" outline="true" mdbWavesEffect *ngIf="showStakingBanner"
          class="sm-btn-input-hover btn-border btn-sm sm-btn-send">
          Stake & Earn 30% Apr
        </button> -->
            </div>
            <div class="row mt-3 position-relative" *ngIf="showFilter" [ngClass]="{'padding-top':showStakingBanner}">
                <div class="col-2">
                    <cashaa-form-select-activity-type-filter placeholder="Filter By"
                        (changed)="onActivityTypeChange($event)">
                    </cashaa-form-select-activity-type-filter>
                </div>
                <div class="col-2">
                    <mdb-date-picker [inline]="true" name="mydate" [placeholder]="'From date'"
                        [options]="myDatePickerOptions" (dateChanged)="onFromDateChange($event)" required>
                    </mdb-date-picker>
                </div>
                <div class="col-2">
                    <mdb-date-picker [inline]="true" name="mydate" [placeholder]="'To date'"
                        [options]="myDatePickerOptions" (dateChanged)="onToDateChange($event)" required>
                    </mdb-date-picker>
                </div>
                <div class="col-2">
                    <cashaa-form-select-currency placeholder="Currency" name="currency"
                        [control]="searchForm.controls.currency" source="allCurrency"
                        (changed)="onCurrencyTypeChange($event)">
                    </cashaa-form-select-currency>
                </div>
                <div class="col-3 d-flex align-items-center">
                    <form class="form-inline w-100 h-100 pb-2">
                        <input class="form-control form-control-sm mr-3 w-75 h-100 sm-mr-3-search-mobile" type="text"
                            placeholder="Search Transactions" aria-label="Search" mdbInput
                            (change)="onSearchTextChanged($event.target.value)" />
                        <mdb-icon fas icon="search" aria-hidden="true"></mdb-icon>
                    </form>
                </div>
                <mdb-icon fas icon="times" size="2x"
                    class="mr-1 position-absolute close-filter text-primary-light sm-filter-cross-right-mobile"
                    (click)="reloadTransaction()"></mdb-icon>
            </div>
        </div>
    </section>

    <!-- ERROR MESSAGE -->
    <div class="px-5 py-3" *ngIf="error">
        <div class="alert alert-danger mt-4" role="alert">
            <p class="error error-heading mt-2 mb-2">
                Sorry, we couldn't finish loading this page.
            </p>
            <p class="error error-desc mb-1">
                Sorry things aren't as they should be right now - we're working on it, holding tight and check back soon
            </p>
        </div>
    </div>

    <!-- WELCOME MESSAGE -->
    <cashaa-activity-welcome *ngIf="ledgerList.length == 0 && !loading"></cashaa-activity-welcome>

    <!-- BALANCE LIST -->
    <cashaa-activity-balance-list *ngIf="!showFilter" (selectedCurrency)="updateLedgerList($event)">
    </cashaa-activity-balance-list>

    <div class="text-center" *ngIf="loading">
        <mdb-spinner spinnerColor="blue"></mdb-spinner>
    </div>

    <!-- LEDGER LIST -->
    <div *ngIf="ledgerList" class="px-4 py-0 sm-width-phone-700 bg-white mx-5 mt-4">
        <div *ngFor="let item of ledgerList" class="mb-4">
            <div class="border-bottom border-light date px-1 py-2 font-weight-bolder text-primary mb-2">
                {{ item.date | date: "EEEE, MMMM d, y" }}
            </div>
            <div *ngFor="let itemActivity of item.activitylist">
                <cashaa-activity-balance-recieved *ngIf="itemActivity.ledgerType === 1 || itemActivity.ledgerType === 34 ||
                itemActivity.ledgerType === 35 || itemActivity.ledgerType === 36" [ledger]="itemActivity">
                </cashaa-activity-balance-recieved>

                <cashaa-activity-balance-transfer *ngIf="itemActivity.ledgerType === 8" [ledger]="itemActivity">
                </cashaa-activity-balance-transfer>

                <cashaa-activity-balance-transfer *ngIf="itemActivity.ledgerType === 11" [ledger]="itemActivity">
                </cashaa-activity-balance-transfer>

                <cashaa-activity-balance-usd-staking *ngIf="itemActivity.ledgerType === 12" [ledger]="itemActivity">
                </cashaa-activity-balance-usd-staking>

                <cashaa-activity-balance-onboarding-fee *ngIf="getFeesStatus(itemActivity)" [ledger]="itemActivity">
                </cashaa-activity-balance-onboarding-fee>

                <cashaa-activity-balance-weekly-reward *ngIf="itemActivity.ledgerType === 14" [ledger]="itemActivity">
                </cashaa-activity-balance-weekly-reward>

                <cashaa-activity-balance-converted *ngIf="itemActivity.ledgerType === 9" [ledger]="itemActivity">
                </cashaa-activity-balance-converted>

                <cashaa-activity-balance-withdrawn [ledger]="itemActivity" *ngIf="itemActivity.ledgerType === 2">
                </cashaa-activity-balance-withdrawn>

                <cashaa-activity-deposit-interest [ledger]="itemActivity" *ngIf="itemActivity.ledgerType === 16">
                </cashaa-activity-deposit-interest>

                <cashaa-activity-credit-availed [ledger]="itemActivity" *ngIf="itemActivity.ledgerType === 26">
                </cashaa-activity-credit-availed>

                <cashaa-activity-credit-repaid [ledger]="itemActivity" *ngIf="itemActivity.ledgerType === 27">
                </cashaa-activity-credit-repaid>

                <cashaa-activity-credit-locked [ledger]="itemActivity" *ngIf="itemActivity.ledgerType === 29">
                </cashaa-activity-credit-locked>

                <cashaa-activity-credit-interest [ledger]="itemActivity" *ngIf="itemActivity.ledgerType === 30">
                </cashaa-activity-credit-interest>
                
                <cashaa-activity-credit-locked-released [ledger]="itemActivity" *ngIf="itemActivity.ledgerType === 31">
                </cashaa-activity-credit-locked-released>
                <cashaa-activity-rolling-reserve [ledger]="itemActivity" *ngIf="itemActivity.ledgerType === 32">
                </cashaa-activity-rolling-reserve>
                <cashaa-activity-staking-rolling-reserve [ledger]="itemActivity" *ngIf="itemActivity.ledgerType === 33">
                </cashaa-activity-staking-rolling-reserve>
            </div>
        </div>
    </div>
</cashaa-sidenav>
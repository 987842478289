import { DepositService } from "./../services/deposit.service";
import { ValidatorUtility } from "src/app/core/utility/validator.utility";
import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
} from "@angular/core";
import { FormGroup, FormBuilder } from "@angular/forms";
import { IDepositCreate, IDepositAmount, IDepositDetail } from "src/app/core/models/deposit";
import { IMyOptions, MDBDatePickerComponent } from "ng-uikit-pro-standard";
import { SubSink } from "subsink";
import { FiatService } from "src/app/fiat/services/fiat.service";
import { Recipient } from "src/app/core/models/fIat/recipients";

@Component({
  selector: "cashaa-deposit-transfer-number",
  templateUrl: "./deposit-transfer-number.component.html",
  styleUrls: ["./deposit-transfer-number.component.scss"],
})
export class DepositTransferNumberComponent implements OnInit {
  /** form group */
  form: FormGroup;

  depositData: IDepositAmount;
  depositBankDetails: IDepositDetail;

  @Output() continue = new EventEmitter<void>();

  @Output() resetAll = new EventEmitter<void>();

  @Output() completed = new EventEmitter<void>();

  loader = false;

  @ViewChild("datePicker") datePicker: MDBDatePickerComponent;

  fiatRecipientData: Recipient;

  myDatePickerOptions: IMyOptions = {
    minYear: 1900,
    maxYear: 2099,
  };

  subsink = new SubSink();
  receiptFile: File;
  sourceOfFund: File;

  constructor(private fb: FormBuilder, private depositService: DepositService, private fiatService: FiatService) {
    this.createForm();
  }

  ngOnInit(): void {
    this.depositService.getDepositAmountEmitter().subscribe((data) => {
      this.depositData = data;
    });
    this.depositService.getDepositDetails().subscribe((data) => {
      this.depositBankDetails = data;
    });

    this.getRecipientData();

    this.subsink.add(
      this.depositService.getDepositStatus().subscribe((data) => {
        if (data) {
          this.form.reset();
          this.datePicker.clearDate();
        }
      })
    );
  }

  createForm(): void {
    this.form = this.fb.group({
      transferNumber: [""],
      remarks: [""],
      date: ["", ValidatorUtility.Required],
      receiptFile: [""],
      sourceOfFund: [""]
    });
  }

  onDateChange(date: any): void {
    this.form.controls.date.setValue(date.actualDateFormatted);
  }

  getRecipientData(): void {
    this.fiatService.getemitRecipientdData().subscribe((fiatRecipientdData) => {
      if (fiatRecipientdData) {
        this.fiatRecipientData = fiatRecipientdData;
      }
    });
  }

  onContinue(): void {
    this.loader = true;
    const data = this.form.value;
    // const payload: IDepositCreate = {
    //   transactionId: data.transferNumber,
    //   currencyId: this.depositData.currency,
    //   paymentMode: this.depositData.option,
    //   amount: this.depositData.amount,
    //   serviceFee: 0,
    //   remark: data.remarks,
    //   transactionDate: data.date,
    //   deliveryMech: this.depositData.deliveryMech,
    //   recipientType: this.fiatRecipientData?.recipientType,
    //   recipientId: this.fiatRecipientData?.id
    // };

    const formData = new FormData();
    formData.append("transactionId", data.transferNumber);
    formData.append("currencyId", this.depositData.currency);
    formData.append("paymentMode", this.depositData.option.toString());
    formData.append("amount", this.depositData.amount.toString());
    formData.append("serviceFee", '0');
    formData.append("remark", data.remarks);
    formData.append("transactionDate", data.date);
    formData.append("deliveryMech", this.depositData.deliveryMech.toString());
    formData.append("recipientType", this.fiatRecipientData?.recipientType);
    formData.append("recipientId", this.fiatRecipientData?.id);
    formData.append("receiptFile", this.receiptFile)
    formData.append("sourceOfFund", this.sourceOfFund)
    formData.append("cashaaInternalBankAccountId", this.depositBankDetails.bankId);
    this.depositService.createDeposit(formData).subscribe(
      (res) => {
        this.depositService.emitDepositAmount(undefined);
        this.completed.emit();
        this.loader = false;
      },
      (err) => {
        this.loader = false;
      }
    );
  }

  onReset(): void {
    this.resetAll.emit();
  }
  onFileChange(event, control): void {
    if (event.target.files.length > 0) {
      if (control === "receiptFile") {
        this.receiptFile = event.target.files[0];
      } else if (control === "sourceOfFund") {
        this.sourceOfFund = event.target.files[0];
      }
      const file = event.target.files[0];
      this.form.patchValue({
        control: file,
      });
    }
  }

  ngOnDestroy() {
    this.subsink.unsubscribe();
  }
}

<h5 class="heading text-center mb-2">Please wait for 30 minute</h5>
<h4 class="heading-desc text-center my-4">This deposit details valid for 30 minutes.</h4>
<div class="d-flex justify-content-center">
  <div *ngIf="minutes >= 10" class="p-3 bg-white mx-1 font-weight-600 minute">{{minutes}}</div>
  <div *ngIf="minutes < 10 || minutes === 0" class="p-3 bg-white mx-1 font-weight-600 minute">0{{minutes}}</div>
  <div class="p-3 mx-1 font-weight-600">:</div>
  <div *ngIf="extraSeconds >= 10" class="p-3 bg-white mx-1 font-weight-600 second">{{extraSeconds}}</div>
  <div *ngIf="extraSeconds < 10 || extraSeconds === 0 " class="p-3 bg-white mx-1 font-weight-600 second">0{{extraSeconds}}</div>
</div>
  
<div class="row px-5 pt-2 my-4">
  <div class="col-md-12">
      <div class="input-group mb-3 sm-nowrap">
        <div class="sm-qr-box">
          <div class="sm-qr-box-inner">
            <qr-code [size]="150" [value]="otherDetails?.address"></qr-code>
          </div>
          <div class="sm-qr-input-box">
            <span class="sm-spam-text sm-span-text-padding">{{otherDetails?.address}}</span>
            <div class="input-group-append">
              <span class="sm-input-group-text"><i mdbTooltip="Copy address" placement="top"
                  class="fas fa-clone sm-copy-color" [ngClass]="copiedAddress ? 'text-primary' : ''"
                  (click)="copyMemoAddress()"></i></span>
            </div>
          </div>
          <div class="sm-spam-text2  text-center pt-2 pb-2"> <span class="font-weight-600">Deposit {{otherDetails?.symbol}}: </span>{{otherDetails?.amount}}</div>
        </div>
      </div>
  </div>
</div>
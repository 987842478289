import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'cashaa-account-mesg-box',
  templateUrl: './account-mesg-box.component.html',
  styleUrls: ['./account-mesg-box.component.scss']
})
export class AccountMesgBoxComponent implements OnInit {

  mesg: string;
  constructor(private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.activatedRoute.queryParamMap.subscribe((val) => {
      const value = val.get("source");
      if (value === 'reset-password') {
         this.mesg = "Congratulations! Your password has been changed successfully.";
      }
      if (value === 'sign-up') {
        this.mesg = "Congratulations! Your Account has been registered successfully.";
      }
    })
}
}

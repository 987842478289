<form [formGroup]="form" class="my-4">
  <div class="p-4 text-center" [hidden]="!loader">
    <mdb-spinner spinnerColor="blue"></mdb-spinner>
  </div>

  <h4 class="heading">
    Upload company documents
  </h4>
  <div class="row mb-4" *ngIf="companyDetails || info">
    <div class="d-flex justify-content-between w-100">
      <div class="bg-white w-50 col-12 col-sm-6 pb-4 pl-4">
        <h5 class="heading pt-4">Business Details</h5>
        <h6 class="heading pt-4">Name</h6>
        <span class="content">{{info?.legalBusinessName}}</span>
        <br>
        <h6 class="heading pt-4">Email</h6>
        <span class="content">{{info?.businessEmail}}</span>
      </div>
      <div class="bg-white w-50 ml-4 col-12 col-sm-6 pb-4 pl-4">
        <h5 class="heading pt-4">Status</h5>
        <h6 class="heading pt-4">KYB Status</h6>
        <span class="content">{{companyDetails?.kycStatus?companyDetails?.kycStatus: "Pending"}}</span>
        <br>
        <h6 class="heading pt-4">Company Type</h6>
        <span class="content">{{info?.businessType? info?.businessType: "N/A"}}</span>
      </div>
    </div>
  </div>
</form>
<div>
  <h4 class="heading pt-5">
    Documents List
  </h4>
  <!-- <p>{{companyDetails | json}}</p> -->
  <div class="my-4">
    <table mdbTable>
      <thead style="background: #E7ECF9;">
        <tr>
          <th style="width:50%">
            <h5 class="heading">Document Type</h5>
          </th>
          <th style="padding-left: 100px;">
            <h5 class="heading">Actions</h5>
          </th>
        </tr>
      </thead>
      <tbody class="bg-white">
        <tr mdbTableCol *ngFor="let item of documentTypes; let i = index">
          <td style="padding-top:45px">
            <span class="content">{{item.apiData.key}}</span>
          </td>
          <td>
            <cashaa-form-input-file [docType]="item" (deleteFile)="onFileDelete($event, item)"
              [fileUploadStatus]="isFileUploadValid" [isFileUploadCompleted]="item.isDeletedValid"
              [isOperationCompleted]="item.fileActionLoader" [isDeletedActive]="true">
            </cashaa-form-input-file>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="txt_centre pt-2">
    <button [disabled]="fileCount < 2" mdbBtn (click)="onSubmit()" block="true" type="button"
      class="w-25 rounded-sm position-relative btn btn_color waves-effect waves-light btn-block" mdbWavesEffect>
      Next
    </button>
  </div>
</div>
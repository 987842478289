import { Component, OnInit,  } from '@angular/core';
import { MDBModalRef } from 'ng-uikit-pro-standard';
import { CommonSavedData } from '../../services/commonSavedData.service';

@Component({
  selector: 'cashaa-confirmation-box',
  templateUrl: './confirmation-box.component.html',
  styleUrls: ['./confirmation-box.component.scss']
})
export class ConfirmationBoxComponent implements OnInit {
  mesg: string;
  constructor(public modalRef: MDBModalRef, private commonSavedData: CommonSavedData) { }

  ngOnInit(): void {
  }

 onClick(status: boolean): void  {
   this.modalRef.hide();
   this.commonSavedData.emitConfirmationBoxStatus(status);
  } 
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'cashaa-trade-home',
  templateUrl: './trade-home.component.html',
  styleUrls: ['./trade-home.component.scss']
})
export class TradeHomeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

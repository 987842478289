import { Component, OnInit, NgZone, EventEmitter, Output } from "@angular/core";
import { FormGroup, FormBuilder } from "@angular/forms";
import { ValidatorUtility } from "src/app/core/utility/validator.utility";
import { WithdrawService } from "../services/withdraw.service";
import { IUserBalance } from "src/app/core/models/user-balance";
import { ReceiveFundRequest } from "src/app/core/models/receive-fund";
import { WithdrawFundRequest, WithdrawFundResponse, CreateWithdrawRequest } from "src/app/core/models/withdraw-fund";
import { SYMBOL_FOR_MEMO } from 'src/app/app.constants';

@Component({
  selector: "cashaa-withdraw-fund",
  templateUrl: "./withdraw-fund.component.html",
  styleUrls: ["./withdraw-fund.component.scss"]
})
export class WithdrawFundComponent implements OnInit {


  createWithdrawFund: FormGroup;

  serviceResponse: WithdrawFundResponse;

  BalanceResponse: IUserBalance;

  show = true;


  constructor(private zone: NgZone, private withdrawService: WithdrawService, private fb: FormBuilder) { }

  ngOnInit(): void {
    this.createForm();

  }

  createForm(): void {
    this.createWithdrawFund = this.fb.group({
      address: ["", [ValidatorUtility.Required]],
      memo: ["",],
      notes: ["",],
      amountToTransfer: ["", [ValidatorUtility.Required, ValidatorUtility.OnlyNumberRequired]],
    });
  }


  onFormSubmit(): void {

    const data = this.createWithdrawFund.value;
    alert(this.BalanceResponse.currencyType);
    const withdrawRequestData = new CreateWithdrawRequest(
      "2", // this.BalanceResponse.currencyType,
      data.address,
      data.amountToTransfer,
      data.memo? data.memo: "",

    );

    this.withdrawService.createWithdrawRequest(withdrawRequestData).subscribe(
      (res) => {

      },
      (err) => {

      }
    );
  }

  // checkBalance(): void {

  // }

  OnCurrencySelect(userBalance: IUserBalance): void {


    this.withdrawService.emitWithdrawAddressSymbolEmitter(userBalance.currencyType);
    if (userBalance.currencyType === SYMBOL_FOR_MEMO) {
      this.show = true;
    } else {
      this.show = false;
    }
  }

}

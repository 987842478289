import { Component, OnInit } from '@angular/core';
import { WalletService } from '../services/wallet.service';

@Component({
  selector: 'cashaa-portfolio-history',
  templateUrl: './portfolio-history.component.html',
  styleUrls: ['./portfolio-history.component.scss']
})
export class PortfolioHistoryComponent implements OnInit {

  portfolioData;
  loader: boolean = false;
  loading: boolean = false;

  constructor(private walletService: WalletService) { }

  ngOnInit(): void {
    this.walletService.getPorfolioHistory().subscribe((data) => {
    this.portfolioData = data;
    }, err => {
      
    });
  }

}



export interface IChangeEmailRequest {
    currentEmail: string,
    newEmail: string,
    changeEmailToken: string,

}

export class ChangeEmailRequest implements IChangeEmailRequest {
    constructor(
        public currentEmail: string,
        public newEmail: string,
        public changeEmailToken: string,

    ) { }
}
<section class="activity-box px-3">
    <div class="details">
        <mdb-accordion [multiple]="false" aria-multiselectable="false">
            <mdb-accordion-item class="no-collase ledger-list-item">
                <mdb-accordion-item-head class="px-2 py-1" mdbWavesEffect>
                    <div class="d-flex flex-row px-2 align-items-center">
                        <div class="px-2 circle">
                            <img src="../../../assets/images/Rolling Reserve.png"/>
                        </div>
                        <div class="status ml-2 transaction-detail">
                            <span class="text-primary-dark">{{ getCurrencyName(ledger?.currency) }}
                                <b class="text-primary">{{ ledger.transactionData }}</b></span>
                        </div>
                        <div class="amount ml-auto mr-4">
                            <b>{{ ledger.amount |number: "0.0-4" }}
                                {{ getCurrencyName(ledger?.currency) }}</b><br />
                        </div>
                    </div>
                </mdb-accordion-item-head>
                <mdb-accordion-item-body class="px-4 position-relative">
                    <div class="container px-4 pt-2">
                        <div class="row">
                            <div class="col-sm-6">
                                Date of deposit<br />
                                <b class="text-custom-black ml-2 font-weight-bold">{{ ledger.startDate | date:'longDate'}}</b>
                            </div>
                            <div class="col-sm-3">
                                Deposited<br />
                                <b class="text-custom-black font-weight-bold">{{getSum(ledger?.convertFromamount, ledger?.rollingReserveAmount) | number: "0.0-4"}}&nbsp;{{ ledger?.feeCurrency}}</b>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col-sm">
                                6-month rolling reserve %<br />
                                <b class="text-custom-black font-weight-bold">{{ ledger.interestRate}}</b>
                            </div>
                            <div class="col-sm">
                                Rolling reserve amount <br />
                                <b class="text-custom-black font-weight-bold">{{ ledger?.rollingReserveAmount}}&nbsp;{{ledger?.currency}}</b>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div *ngIf="ledger?.amount === ledger?.rollingReserveAmount" class="col-sm">
                                Sender account name<br />
                                <b class="text-custom-black font-weight-bold">{{ledger?.transactionId}}</b>
                            </div>
                            <div *ngIf="ledger?.amount !== ledger?.rollingReserveAmount" class="col-sm">
                                Transaction number<br />
                                <b class="text-custom-black font-weight-bold">{{ledger?.transactionId}}</b>
                            </div>
                            <div class="col-sm">
                                Due-date of release <br />
                                <b class="text-custom-black font-weight-bold">{{ ledger?.endDate | date:'longDate'}}</b>
                            </div>
                        </div>
                    </div>
                </mdb-accordion-item-body>
            </mdb-accordion-item>
        </mdb-accordion>
    </div>
</section>
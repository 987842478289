import { KycService } from "./../services/kyc.service";
import { CreateKYC } from "./../../core/models/create-kyc";
import { MdbStepperComponent } from "ng-uikit-pro-standard";
import { Component, OnInit, ViewChild } from "@angular/core";
import { ICreateKYC } from "../../core/models/create-kyc";
import { ToastrService } from "ngx-toastr";
import { UNABLE_TO_PERFORM_ERROR } from "src/app/app.constants";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "cashaa-kyc-personal",
  templateUrl: "./kyc-personal.component.html",
  styleUrls: ["./kyc-personal.component.scss"],
})
export class KycPersonalComponent implements OnInit {
  @ViewChild("stepper") stepper: MdbStepperComponent;

  data: ICreateKYC;

  completed = false;

  submitForm = false;
  createKycData: CreateKYC;
  loading = false;
  loading2 = false;
  manualKyc;
  isKycDone;
  createKycDone;
  isReadOnly: boolean;
  isStaking: boolean;
  identityDocumentType;
  addressDocumentType;
  constructor(
    private kycService: KycService,
    private toastrService: ToastrService,
    private activatedRoute: ActivatedRoute
  ) {
    this.activatedRoute.queryParams.subscribe((params) => {
      if (params["from"]) {
        this.isStaking = true;
      } else {
        this.isStaking = false;
      }
    });
  }

  ngOnInit(): void {
    this.getKycDetail();
  }

  getKycDetail(): void {
    this.loading = true;
    this.kycService.getKycDetails().subscribe((res) => {
      if (res) {
        this.data = res;
        this.isReadOnly = res.verified;
        if (
          res.selfieFilePath == null ||
          res.addressProofFilePath == null ||
          res.identityProofFrontsideFilePath == null ||
          res.identityProofBacksideFilePath == null
        ) {
          this.completed = false;
        } else {
          this.completed = true;
          this.createKycDone = false;
          if (res.verified) {
            this.isKycDone = true;
          } else {
            this.createKycDone = true;
          }
        }
      }
      this.loading = false;
    });
  }

  /**
   * on information continue
   */
  onInformationContinue(data: any): void {
    this.data = {
      firstName: data.firstName,
      lastName: data.lastName,
      dateOfBirth: data.dateOfBirth,
      phone: data.phone,
    };
    this.submitForm = false;
    this.stepper.next();
  }

  onIdentityContinue(data: any): void {
    this.identityDocumentType = data;
    this.stepper.next();
  }

  onSelfieyContinue(data: any): void {
    this.createKycDone = true;
    this.stepper.next();
  }

  onAddressProofContinue(data: any) {
    this.addressDocumentType = data;
    this.stepper.next();
  }

  onAddressContinue(data: any): void {
    if (this.isKycDone) {
      this.stepper.next();
    } else {
      this.data.address1 = data.address1;
      this.data.address2 = data.address2;
      this.data.city = data.city;
      this.data.postalCode = data.postalCode;
      this.data.region = data.region;
      this.data.countryId = data.countryId.value;

      this.createKycData = new CreateKYC(
        this.data.firstName,
        this.data.phone,
        this.data.address1,
        this.data.city,
        this.data.countryId,
        this.data.region,
        this.data.postalCode,
        this.data.dateOfBirth,
        this.data.lastName,
        this.data.address2
      );
      this.loading2 = true;
      this.kycService.createKyc(this.createKycData).subscribe(
        (res) => {
          if (res.message === "DENIED") {
            this.manualKyc = true;
          } else {
            const token =
              "https://ivs.idenfy.com/api/v2/redirect?authToken=" + res.message;
            window.open(token, "_blank", "height=700,width=700");
            this.createKycDone = true;
            this.loading2 = false;
          }
          this.stepper.next();
          this.kycService.emitCreateKycData(this.createKycData);
          this.loading2 = false;
          setTimeout(() => {
            document.getElementsByClassName("step")[2].classList.add("done");
            document
              .getElementsByClassName("step")[2]
              .classList.remove("active");
          }, 500);
          this.loading2 = false;
        },
        (err) => {
          this.loading2 = false;
          if (err.error.message) {
            this.toastrService.error(err.error.message);
          } else {
            this.toastrService.error(UNABLE_TO_PERFORM_ERROR);
          }
        }
      );
    }
  }
}

export interface IAPIEnumLoad {
    key: string;
    value: number;
    // fileInfo?: IFile;
}

export interface IFile {
    file?: File;
    fileId?: string;
    isDeletedValid?: boolean;
    fileActionLoader?: boolean;
    docType?: string;
    fileName?: string;
    apiData?: IAPIEnumLoad;
}

export class FileUpload implements IFile {
    constructor(
                public apiData: IAPIEnumLoad,
                public file?: File,
                public fileId?: string,
                public isDeletedValid?: boolean,
                public fileActionLoader?: boolean,
                public docType?: string,
                public fileName?: string
            ) { }
}
import { CryptoCurrency } from "../enums/cryptoCurrency";

export interface IAddressWhitelist {
  currencyId: string;
  address: string;
  addressLabel: string;
  twoFactorCode: string;
  network?: number
  email?: string;
  userType?: string;
  memo?: number;
}

export class AddressWhitelist implements IAddressWhitelist {
  constructor(
    public currencyId: string,
    public address: string,
    public addressLabel: string,
    public twoFactorCode: string,
    public network?: number,
    public email?: string,
    public userType?: string,
    public memo?: number
  ) {}
}

import { ConvertService } from "src/app/convert/services/convert.service";
import { ValidatorUtility } from "src/app/core/utility/validator.utility";
import {
  Component,
  OnInit,
  ViewChild,
  Output,
  EventEmitter,
  OnDestroy,
} from "@angular/core";
import { FormGroup, FormBuilder } from "@angular/forms";
import { MdbStepperComponent } from "ng-uikit-pro-standard";
import { NavigationService } from "src/app/core/services/navigation/navigation.service";
import { Location } from '@angular/common';
@Component({
  selector: "cashaa-convert-home",
  templateUrl: "./convert-home.component.html",
  styleUrls: ["./convert-home.component.scss"],
})
export class ConvertHomeComponent implements OnInit, OnDestroy {
  @ViewChild("stepper", { static: true }) stepper: MdbStepperComponent;

  /** amount form */
  amountForm: FormGroup;

  /** Active step */
  activeStep = 0;

  /** is completed */
  completed = false;

  constructor(private fb: FormBuilder, private convertService: ConvertService, 
    private navigationService: NavigationService, private location: Location) {
    this.createEmptyForm();
  }

  ngOnInit(): void {
    this.completed = false;
  }

  /**
   * create initial empty forms
   */
  createEmptyForm(): void {
    this.amountForm = this.fb.group({
      valid: ["", ValidatorUtility.Required],
    });
  }

  /**
   * on amount form continue click
   */
  onAmountFormContinue(): void {
    this.amountForm.controls.valid.setValue(true);
    this.stepper.next();
    this.activeStep = 0;
  }

  /**
   * on go back click, go back
   * given number of steps
   */
  onGoBack(step: number): void {
    this.activeStep = 0;
    this.stepper.previous();
  }

  convertCompleted(status : boolean): void {
   this.completed = true;
  }

  ngOnDestroy(): void {
    this.convertService.emitConvertCompletedData(undefined);
    this.convertService.emitConvertFlowData(undefined);
    this.convertService.emitCurrencySymbolData(undefined);
    this.convertService.emitQuoteData(undefined);
  }

  backRoute() {
    this.navigationService.back();
  }
}

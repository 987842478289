import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { IMyOptions } from 'ng-uikit-pro-standard';
import { IFormSelect } from 'src/app/core/models/form-select';
import { IStatementData } from 'src/app/core/models/statement/statement';
import { StatementService } from '../services/statement.service';
import * as XLSX from "xlsx";
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'cashaa-generate-statement',
  templateUrl: './generate-statement.component.html',
  styleUrls: ['./generate-statement.component.scss']
})
export class GenerateStatementComponent implements OnInit {
  searchForm: FormGroup;
  loader: boolean = false;
  myDatePickerOptions: IMyOptions = {
    minYear: 1900,
    maxYear: 2099,
  };
  activityType: number;
  fromDate: string;
  toDate: string;
  searchText: string;
  symbol: string;
  pageSize: number = 10;
  pageIndex: number = 1;
  totalPageSize: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  length: number;
  statementData: IStatementData[];
  depositInterestId: string;
  cur: string;
  val: string;
  ledger;
  rateType;

  constructor(private fb: FormBuilder, private statementService: StatementService, private activateRoute: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
    this.searchForm = this.fb.group({
      currency: [""]
    });
    // this.router.navigateByUrl('activity');
    this.activateRoute.queryParams.subscribe(params => {
      this.depositInterestId = params['id'];
      this.val = params['val'];
      this.cur = params['cur'];
      if (this.depositInterestId) {
        this.loadFixedDepositStatement(this.pageIndex, this.pageSize);
      } else {
        this.loadStatementData(this.pageIndex, this.pageSize);
      }
    });

    this.ledger = JSON.parse(sessionStorage.getItem('ledger'))
    this.rateType = JSON.parse(sessionStorage.getItem('rateType'))
    console.log(this.ledger)
    
  }

  

  loadStatementData(pageIndex: number, pageSize: number, activityType?: number, fromDate?: string, toDate?: string, searchTerm?: string, symbol?: string): void {
    this.loader = true;
    this.statementService.getStatementList(pageIndex, pageSize, activityType, fromDate, toDate, searchTerm, symbol).subscribe(data => {
      this.loader = false;
      this.statementData = data.data;
      this.pageSize = data.pageSize;
      this.pageIndex = data.pageIndex;
      this.length = data.totalCount;
      this.totalPageSize = data.totalPageCount;
      this.hasPreviousPage = data.hasPreviousPage;
      this.hasNextPage = data.hasNextPage;
    });
  }

  loadFixedDepositStatement(pageIndex: number, pageSize: number, activityType?: number, fromDate?: string, toDate?: string, searchTerm?: string, symbol?: string): void {
    this.loader = true;
    this.statementService.getFixedDepositStatementList(this.depositInterestId, pageIndex, pageSize, activityType, fromDate, toDate, searchTerm, symbol).subscribe(data => {
      this.loader = false;
      this.statementData = data.data;
      this.pageSize = data.pageSize;
      this.pageIndex = data.pageIndex;
      this.length = data.totalCount;
      this.totalPageSize = data.totalPageCount;
      this.hasPreviousPage = data.hasPreviousPage;
      this.hasNextPage = data.hasNextPage;
    });
  }

  onActivityTypeChange(activity: IFormSelect) {
    this.activityType = Number(activity.value);
    this.loadStatementData(1, 10, this.activityType, this.fromDate, this.toDate, this.searchText, this.symbol);
  }

  onFromDateChange(date: any) {
    this.fromDate = date.actualDateFormatted;
    this.loadStatementData(1, 10, this.activityType, this.fromDate, this.toDate, this.searchText, this.symbol);
  }

  onToDateChange(date: any) {
    this.toDate = date.actualDateFormatted;
    this.loadStatementData(1, 10, this.activityType, this.fromDate, this.toDate, this.searchText, this.symbol);
  }

  onCurrencyTypeChange(event) {
    this.symbol = event.symbol;
    this.loadStatementData(1, 10, this.activityType, this.fromDate, this.toDate, this.searchText, this.symbol);

  }

  onSearchTextChanged(searchText: any) {
    this.searchText = searchText;
    this.loadStatementData(1, 10, this.activityType, this.fromDate, this.toDate, this.searchText, this.symbol);
  }

  previous() {
    if (this.hasPreviousPage) {
      this.loadStatementData(this.pageIndex - 1, this.pageSize, this.activityType, this.fromDate, this.toDate, this.searchText, this.symbol);
    }
  }

  next() {
    if (this.hasNextPage) {
      this.loadStatementData(this.pageIndex + 1, this.pageSize, this.activityType, this.fromDate, this.toDate, this.searchText, this.symbol);
    }
  }

  exportStatement(): void {
    this.statementService.getStatementList(1, this.length, this.activityType, this.fromDate, this.toDate, this.searchText, this.symbol)
      .subscribe((data) => {
        /* table id is passed over here */
        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data.data);

        /* generate workbook and add the worksheet */
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Statement Detail");

        /* save to file */
        XLSX.writeFile(wb, "Statement.xlsx");
      });
  }

}

<section class="p-5 w-75 mx-auto fiat-flow-amount">
  <div class="text-center" [hidden]="!loading">
    <mdb-spinner spinnerColor="blue" class="sm-loader-center"></mdb-spinner>
  </div>
  <h4 class="text-primary-dark font-weight-bolder mb-4 text-center">
    How much would you like to transfer?
  </h4>

  <form [formGroup]="form" *ngIf="(this.sendCurrencySymbol == 'GBP' || this.sendCurrencySymbol == 'USD' || this.sendCurrencySymbol == 'EUR')&& !loading">
    <div class="row mb-2 pt-2 pb-2">
      <div class="form-check col-5 sm-userType-boxes">
        <input type="radio" formControlName="recipientType" class="form-check-input" id="self" name="recipientType"
          mdbInput value="1" />
        <label class="form-check-label" for="self">Self</label>
        <button type="button" class="sm-tooltip" mdbTooltip="Own account" placement="top">
          <mdb-icon class="pl-2 info-circle" title="" fas icon="info-circle"></mdb-icon>
        </button>
      </div>
      <div class="form-check col-5 sm-userType-boxes">
        <input type="radio" formControlName="recipientType" class="form-check-input" id="others" name="recipientType"
          mdbInput value="2" />
        <label class="form-check-label" for="others">Others</label>
        <button type="button" class="sm-tooltip" mdbTooltip="Third-party bank account" placement="top">
          <mdb-icon class="pl-2 info-circle" title="" fas icon="info-circle"></mdb-icon>
        </button>
      </div>
    </div>
    <span *ngIf="!isPremiumPlan" class="font-weight-normal pb-4">*Do you want to send money to someone else? </span>
    <a [routerLink]="['/subscription/plan']" *ngIf="!isPremiumPlan" class="p-0 pb-4">
      <span class="mt-2 font-weight-normal">Click here to upgrade.</span>
    </a>

  </form>
  
  <div class="d-flex justify-content-center my-4">
    <cashaa-form-select-transfer-currency (sendCurrencyChangeEvent)="onsendCurrencyChangeEvent($event)" 
      (minSendAmount)="minSendAmount($event)" [form]="form"
      [amountControl]="form.controls.senderAmount" [currencyControl]="form.controls.sendercurrencyId" label="You send"
      [controlCurrency]="sendCurrencySymbol" amountControlName="senderAmount" currencyControlName="sendercurrencyId"
      [isSendModule]="true" type="send" showBalance="true" class="w-100">
    </cashaa-form-select-transfer-currency>
  </div>
  <div class="mb-4 position-relative pl-5">
    <div class="d-flex align-items-center text-primary-dark">
      <p *ngIf="userFeeMode==='InNative'" class="font-weight-bold ml-2 mr-3 mb-0">
        {{ convertToDecimal(selectedfeeamount,sendCurrencySymbol) }} {{ getCurrencyName(sendCurrencySymbol) }}

      </p>
      <!--[routerLink]="['/settings']"
  [queryParams]="{step:'fee'}-->
      <p *ngIf="userFeeMode==='InNative'" class="font-weight-bold ml-2 mb-0 p-0">Fee </p>
      <p *ngIf="userFeeMode==='InCashaa'" class="font-weight-bold ml-2 mr-3 mb-0">
        {{ convertToDecimal(selectedfeeamount,'CAS') }} CAS Fee
      </p>
      <!--[routerLink]="['/settings']"
      [queryParams]="{step:'fee'}"-->

      <div *ngIf="userFeeMode==='InCashaa'">
        <span class="red-text mb-1 font-weight-normal" *ngIf="isCashaa">
          &nbsp;Insufficient, <span class="p-0">{{casFeeCheckBalance}} CAS </span> balance
        </span>
        <span class="mb-0 p-2 font-weight-normal ng-star-inserted" *ngIf="!isCashaa">
          You have {{casFeeCheckBalance}}
          CAS balance
        </span>
      </div>
    </div>
    <div class="text-gray mt-3" *ngIf="userFeeMode==='InNative'">
      <p *ngIf="actulaconvertedamount > 0" class="font-weight-bolder ml-2 mr-3 mb-0">
        {{ actulaconvertedamount | number: getDecimalNumber(sendCurrencySymbol)}} {{ getCurrencyName(sendCurrencySymbol)
        }} amount we'll
        transfer
      </p>

      <p *ngIf="actulaconvertedamount <= 0 || !(actulaconvertedamount <= 0 || actulaconvertedamount > 0)"
        class="tran-amt ml-2 mr-3 mb-0">
        0 {{ getCurrencyName(sendCurrencySymbol) }} amount we'll transfer
      </p>
    </div>
    <div class="text-gray mt-3" *ngIf="userFeeMode==='InCashaa'">
      <p *ngIf="actulaconvertedamount > 0" class="font-weight-bolder ml-2 mr-3 mb-0">
        {{ actulaconvertedamount | number: getDecimalNumber(sendCurrencySymbol)}} {{
        getCurrencyName(sendCurrencySymbol) }} amount we'll
        transfer
      </p>

      <p *ngIf="actulaconvertedamount <= 0 || !(actulaconvertedamount <= 0 || actulaconvertedamount > 0)"
        class="font-weight-bolder ml-2 mr-3 mb-0">
        0 {{ getCurrencyName(sendCurrencySymbol) }} amount we'll transfer
      </p>
    </div>
    <span class="line-left">
      <mdb-icon class="line-icon first-icon" fas icon="minus" [style.marginTop]="'1.3rem'">
      </mdb-icon>
    </span>
  </div>
 
  <div class="d-flex justify-content-center mb-2">
    <cashaa-form-select-transfer-currency [form]="form" [disable]="true" [amountControl]="form.controls.receiverAmount"
      [currencyControl]="form.controls.receivercurrencyId" [send]="false" label="Recipient gets" [isSendModule]="true"
      amountControlName="receiverAmount" currencyControlName="receivercurrencyId" type="receive" class="w-100">
    </cashaa-form-select-transfer-currency>
  </div>
  <div class="mb-4" *ngIf="sendCurrencySymbol == 'USD' || sendCurrencySymbol == 'AUSD'">
    <cashaa-form-select [form]="form" [control]="form.controls.deliveryMech" name="deliveryMech"
      label="Delivery Mechanism" [options]="deliverMechOptions" (changed)="deliveryMechChanges($event)">
    </cashaa-form-select>
  </div>
   <!-- <div class="mb-2"
    *ngIf="!this.fiatCurrencies.includes(this.sendCurrencySymbol) && !(this.sendCurrencySymbol === 'BTC' || this.sendCurrencySymbol === 'BNB')"> -->
    
    <div class="pt-3" *ngIf="isFromBeneficiary && !isFiatCurrency()">
      <ng-container *ngFor="let nt of networkTypeOptions">
        <span *ngIf="nt.networkValue === form.controls.networkType.value" ><p class="text-primary-dark font-weight-bold mb-0">Network Type: </p>{{nt.name}}</span>
        </ng-container>
    </div>
    <div class="py-3" *ngIf="!isFromBeneficiary && !isFiatCurrency()">
    <cashaa-form-select-network-type [form]="form" [control]="form.controls.networkType" name="networkType" label="Network Type"
      [options]="networkTypeOptions" (changed)="networkTypeChanges($event)">
    </cashaa-form-select-network-type>
  </div>

  <p class="red-text mb-2 mt-3 font-weight-normal" *ngIf="isMinSendValueError">
    {{sendCurrencySymbol}} amount should not be less than {{minSendValue}}  
    <ng-container *ngFor="let nt of networkTypeOptions">
      <span *ngIf="nt.networkValue === form.controls.networkType.value">on ({{nt.name}})</span>
      </ng-container>
  </p>
 <!-- <div class="mb-2 pt-4" *ngIf="getMemoReferenceValid()">
    <cashaa-form-input name="memo" type="text" [form]="form" [control]="form.controls.memo" placeholder={{getLabel()}}
      label={{getLabel()}}>
    </cashaa-form-input>
  </div> -->
  <small class="text-black font-weight-normal cursor-pointer" *ngIf="errorMessage && isWithdrawCurrencyFiat() && (userType ==='6' || userType ==='5')" [routerLink]="['/kyc/business']" [queryParams]="{plan:'Broker'}" [innerHTML]="errorMessage">
  </small>
  <small class="text-black font-weight-normal cursor-pointer" *ngIf="errorMessage && isWithdrawCurrencyFiat() && userType ==='4'"  [routerLink]="['/kyc/personal']" [innerHTML]="errorMessage">
  </small>
  <button mdbBtn
    [disabled]="isMinSendValueError||form.invalid || getSentValue() || checkValues() || (!isKycDone && isWithdrawCurrencyFiat()) || !isPremiumPlan"
    block="true" type="button"
    class="w-100 mt-4 rounded-sm position-relative btn btn-secondary sm-custom-sec waves-effect waves-light btn-block"
    mdbWavesEffect (click)="onContinue()">
    Continue
  </button>
</section>

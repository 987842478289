<cashaa-sidenav>
  <section class="mh-100">
    <div class="bg-white p-4">
      <h4 class="font-weight-bold personal-kyc-heading">KYC Verification</h4>
    </div>
  </section>
  <div class="p-4">
    <p *ngIf="isStaking" class="mb-5">
      Complete KYC to stake and receive the bonus rewards.
    </p>
    <div class="w-75 w-phone-100-personal-kyc mx-auto stepper" *ngIf="!loading">
      <div class="text-center" *ngIf="loader">
        <mdb-spinner spinnerColor="blue"></mdb-spinner>
      </div>
      <mdb-stepper #stepper [linear]="true">
        <mdb-step name="Personal Information" title="Basic" class="step">
          <div class="sm-div-height sm-div-height-mobile">
            <cashaa-kyc-us-personal-information (continue)="onBasicInfoContinue($event)">
            </cashaa-kyc-us-personal-information>
          </div>
        </mdb-step>
        <mdb-step name="Postal Address" class="step">
          <cashaa-kyc-us-personal-postal-address (continue)="onPostalAddressContinue($event)">
          </cashaa-kyc-us-personal-postal-address>
        </mdb-step>
        <mdb-step name="Contact Detail" class="step">
          <cashaa-kyc-us-personal-contact-detail (continue)="onContactDetailContinue($event)">
          </cashaa-kyc-us-personal-contact-detail>
        </mdb-step>
        <mdb-step name="Document" class="step testClass">
          <cashaa-kyc-us-personal-documents (continue)="onDocumentContinue($event)">
          </cashaa-kyc-us-personal-documents>
        </mdb-step>
        <mdb-step name="Status" class="step">
          <cashaa-kyc-status [kycCompleteStatus]="kycCompleteStatus">
          </cashaa-kyc-status>
        </mdb-step>
      </mdb-stepper>
    </div>
  </div>
</cashaa-sidenav>
import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";

@Component({
  selector: "cashaa-account-social-signin",
  templateUrl: "./account-social-signin.component.html",
  styleUrls: ["./account-social-signin.component.scss"],
})
export class AccountSocialSigninComponent implements OnInit {
  constructor(private titleService: Title) {}

  ngOnInit(): void {
    this.titleService.setTitle("Cashaa"); 
  }
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MDBModalRef } from 'ng-uikit-pro-standard';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { BASE_PLAN, DIAMOND_PLAN, GOLD_PLAN, SILVER_PLAN, USER_INTEREST_MODE, USER_TYPE } from 'src/app/app.constants';
import { IUserPlan, IUserPlanDetail } from 'src/app/core/models/user-plan/user-detail';
import { ModalService } from 'src/app/core/services/modal/modal.service';
import { UserService } from 'src/app/core/services/user/user.service';
import { CommonSavedData } from 'src/app/shared/services/commonSavedData.service';

@Component({
  selector: 'cashaa-earn-plan-details',
  templateUrl: './earn-plan-details.component.html',
  styleUrls: ['./earn-plan-details.component.scss']
})
export class EarnPlanDetailsComponent implements OnInit {

  userCurrentPlan: IUserPlanDetail;
  loader: boolean = false;
  userPlanDetailByCurrency: IUserPlan[];
  currentPlanUserDetail: IUserPlan;
  diamondPlan: IUserPlan;
  userInterestMode: string = sessionStorage.getItem(USER_INTEREST_MODE);
  upgradePlanName: string;
  upgradeDisable: boolean = false;
  
  casDepositRates: number[];
  isFixedDepositEnable: boolean
  isFixedAndEarnEnable: boolean
  maxInterestModeRate: number[];
  isEarnModuleNotActive: boolean;
  isFiatCurrency: boolean;
  casDepositRatesLessThan365: number[];
  casDepositRatesGreaterThan365: number[];
  userType: string = sessionStorage.getItem(USER_TYPE);
  fiatCurrencies = ["USD", "GBP", "EUR", "AUSD", "CUSD", "CGBP", "CEUR", "SGD", "AUD", "CNH", "CAD", "CHF", "NZD", "JPY"];
  selectedCurrency;
  sub: Subscription;

  loyaltyPlanName: string;
  upgradeTo: string;
  balance: number;
  currencyId: string;
  symbol:string
  fLexTerm: number;
  fixedTerm: number;
  earnUpto: number;
  longEarnUpTo: number;
  minFlexAmount: number;
  isFlexSubscribed: boolean;
  casBonus: boolean;
  constructor(public modalRef: MDBModalRef, public router: Router, private userService: UserService,
    private modalService: ModalService, private commonSavedData: CommonSavedData, private toastrService: ToastrService) { }

  ngOnInit(): void {
    this.getCurrencyInfo();
    if (this.fiatCurrencies.includes(this.symbol)) {
      this.isFiatCurrency = true;
    } else {
      this.isFiatCurrency = false;
    }
  }

  getCurrencyInfo() {
    this.userService.getFlexAndFdDetail().subscribe((res) => {
      let currencyInfo = res;
      this.selectedCurrency = currencyInfo.filter(item => item.currencyId == this.currencyId);
    }, err => {
      // this.loading = false;
    });
  }

  redirectPlans(): void {
    this.modalRef.hide();
    this.router.navigateByUrl('/plans');
  }

  redirectDepositInterest(): void {
    this.modalRef.hide();
    this.router.navigateByUrl(`/deposit-interest?c=${this.symbol}`);
  }
  redirectDepositInterestForLongTerm() {
    this.modalRef.hide();
    this.router.navigateByUrl(`/deposit-interest?c=${this.symbol}&longTerm=true`);
  }

  getRate(): number {
    if (this.userInterestMode === 'InKind') {
      if (this.currentPlanUserDetail) {
        return this.currentPlanUserDetail.kindRate;
      } else {
        return 0;
      }
    } else {
      if (this.currentPlanUserDetail && this.currentPlanUserDetail.casRate) {
        return Number(this.currentPlanUserDetail.casRate + this.currentPlanUserDetail.kindRate);
      } else {
        return 0;
      }
    }

  }

  getMinCasDepositRate(): number {
    // return this.maxInterestModeRate.length > 0 ? Math.max(...this.maxInterestModeRate) : 0;
    if (this.maxInterestModeRate.length > 0) {
      return Math.max(...this.maxInterestModeRate);
    } else {
      return Math.max(...this.casDepositRates);
    }
  }

  getMaxCasDepositRate(): number {
    // return this.casDepositRates.length > 0 ? Math.max(...this.casDepositRates) : 0;
    return this.casDepositRatesGreaterThan365.length > 0 ? Math.max(...this.casDepositRatesGreaterThan365) : 0;

  }
  getMaxCasDepositLessThanEqualRate(): number {
    return this.casDepositRatesLessThan365.length > 0 ? Math.max(...this.casDepositRatesLessThan365) : 0;
  }

  getCurrencyName(curr): string {
    if (curr === "AUSD") return "USD";
    if (curr === "DOG") return "DOGE";
    else return curr;
  }

  deposit() {
    if (this.fiatCurrencies.includes(this.symbol)) {
      this.router.navigateByUrl(`/deposit?currency=${this.symbol}`);
    } else {
      this.router.navigateByUrl(`/wallet?c=${this.symbol}#${this.symbol}`);
    }
    this.modalRef.hide();
  }
  redirectToSetting() {
    this.router.navigateByUrl('/settings');
  }

  subcribe() {
    if (this.balance > this.minFlexAmount) {
      this.modalService.openConfirmationModal(`You need to maintain ${this.minFlexAmount}${this.symbol} in your ${this.symbol} account to receive flex interest.<br> Are you sure you want to subscribe?`);
      this.sub = this.commonSavedData.getConfirmationBoxStatus().subscribe((status: boolean) => {
        this.sub.unsubscribe();
        if (status) {
          this.userService.subscribeUnsubscribe(this.selectedCurrency[0].currencyId).subscribe(res => {
            this.getCurrencyInfo();
            this.toastrService.success(res.message);
  
          }, err => {
            this.toastrService.error(err.error.message);
          })
        }
      });
    } else {
      this.toastrService.error(`Minimum balance is required to subscribe is ${this.minFlexAmount}${this.symbol}. Please deposit the amount.`);
    }

  }

  unsub() {
    this.modalService.openConfirmationModal("Are you sure you want to unsubscribe?");
    this.sub = this.commonSavedData.getConfirmationBoxStatus().subscribe((status: boolean) => {
      this.sub.unsubscribe();
      if (status) {
        this.userService.subscribeUnsubscribe(this.selectedCurrency[0].currencyId).subscribe(res => {
          this.getCurrencyInfo();
          this.toastrService.success(res.message);
          this.sub.unsubscribe();

        }, err => {
          this.toastrService.error(err.error.message);

        })
      }
    });
  }
}






<form [formGroup]="form" class="my-4 minh-100">
    <div class="row mb-4">
        <div class="col-12 col-sm-6">
            <cashaa-form-input name="firstName" type="firstName" [form]="form" [isRequired]="true" [control]="form.controls.firstName" placeholder="Enter First Name" label="First Name"></cashaa-form-input>
        </div>
        <div class="col-12 col-sm-6 mobile-mt-4">
            <cashaa-form-input name="lastName" type="lastName" [form]="form" [isRequired]="true" [control]="form.controls.lastName" placeholder="Enter Last Name" label="Last Name"></cashaa-form-input>
        </div>
    </div>
    <div class="row mb-4">
        <div class="col-12 col-sm-6">
            <label class="heading">Date Of Birth <span class="text-danger">*</span></label>
            <mdb-date-picker [selDate]="form?.controls['dateOfBirth'].value" [inline]="true" name="mydate" [placeholder]="'Date Of Birth'" [options]="myDatePickerOptions" (dateChanged)="onFromDateChange($event)" required class="datepicker-white" [disabled]="isReadOnly"></mdb-date-picker>
        </div>
        <div class="col-12 col-sm-6 mobile-mt-4">
            <cashaa-form-input name="phone" type="phone" [form]="form" [isRequired]="true" [control]="form.controls.phone" placeholder="Enter Phone Number" label="Phone Number"></cashaa-form-input>
        </div>
    </div>
    <div class="row mb-4 d-flex justify-content-center">
        <div class="col-12 col-sm-6">
            <button [disabled]="form.invalid" mdbBtn block="true" type="button" class="rounded-sm position-relative btn text-light waves-effect waves-light btn-block btn_green" mdbWavesEffect (click)="onContinue()">
        Continue
      </button>
        </div>
    </div>
</form>
export interface IUSPersonal {
  tp: string;
  firstName: string;
  middleName: string;
  lastName: string;
  occupation: string;
  birthDate: Date;
  countryOfBirth: string;
  currencyCode: string;
  postalAddress: IPostalAddress;
  contactDetail: IContactDetail;
  documents: IDocuments[];
  consent: string;
  kycStatus?: number;
}

export interface IContactDetail {
  mobileCountryCode: string;
  mobileNumber: string;
  emailAddress: string;
}

export interface IPostalAddress {
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  postalCode: string;
  province: string;
  city: string;
  country: string;
}

export interface IDocuments {
  docType: string;
  docIdentifier: number;
  docExpiryDate: Date;
}

export class USPersonal implements IUSPersonal {
  constructor(
    public tp: string,
    public firstName: string,
    public middleName: string,
    public lastName: string,
    public occupation: string,
    public birthDate: Date,
    public countryOfBirth: string,
    public currencyCode: string,
    public postalAddress: IPostalAddress,
    public contactDetail: IContactDetail,
    public documents: IDocuments[],
    public consent: string
  ) {}
}

export class PostalAddress implements IPostalAddress {
  constructor(
    public addressLine1: string,
    public addressLine2: string,
    public addressLine3: string,
    public postalCode: string,
    public province: string,
    public city: string,
    public country: string
  ) {}
}

export class ContactDetail implements IContactDetail {
  constructor(
    public mobileCountryCode: string,
    public mobileNumber: string,
    public emailAddress: string
  ) {}
}

export class Documents implements IDocuments {
  constructor(
    public docType: string,
    public docIdentifier: number,
    public docExpiryDate: Date
  ) {}
}

export interface IUpgradePayment {
  feeInKind: boolean,
  feeInCAS: boolean,
  subscriptionPlanId: string
}

export interface IPaymentFeesDetail {
  id: string,
  name: string,
  feeInEUR: number,
  feeInCAS: number,
  casFeeSymbol: string,
  fiatFeeSymbol: string,
  discountFeeInFiat: number
}

export class UpgradePayment implements IUpgradePayment {
  constructor(
    public feeInKind: boolean,
    public feeInCAS: boolean,
    public subscriptionPlanId: string
  ) {}
}

import { CurrencyType } from "src/app/core/enums/currencyType";
import {
  USER_FEE_MODE,
  USER_FEE_PERCENT,
  USER_CAS_FEE_RATE,
  UNABLE_TO_PERFORM_ERROR,
  CRYPTO_CURRENCY,
} from "./../../app.constants";
import { IQuote, IQuoteDetail, IQuoteId } from "./../../core/models/quote";
import { ICurrency } from "src/app/core/models/currency";
import { ITransferFees } from "src/app/core/models/transfer-types";
import { ConvertService } from "./../services/convert.service";
import { IConvert } from "./../../core/models/convert";
import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  OnChanges,
} from "@angular/core";
import { DomainService } from "../../core/services/domain/domain.service";
import { ConvertMoneyRequest } from "../../core/models/convert/convert-request-model";
import { IConvertComplete } from "../../core/models/convert/convert-complete-model";
import { Subscription } from "rxjs";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "cashaa-convert-review",
  templateUrl: "./convert-review.component.html",
  styleUrls: ["./convert-review.component.scss"],
})
export class ConvertReviewComponent implements OnInit, OnChanges {
  /** in view */
  @Input() inView = false;

  /** go back given number of steps */
  @Output() goback = new EventEmitter<number>();

  @Output() completed = new EventEmitter<boolean>();

  loading = false;

  initialLoad = false;

  errormsg = false;
  errorMessage = '';

  convertFlowData?: IConvert;

  /** fee amount object */
  feeAmountObject?: ITransferFees;

  // hold pay data request
  fromcurrencyObject: ICurrency;

  /** to currency object */
  tocurrencyObject: ICurrency;

  /** tiem expired */
  timeExpired = false;

  conversionFeePercent = sessionStorage.getItem(USER_FEE_PERCENT);

  casrate: string;

  userFeeMode = sessionStorage.getItem(USER_FEE_MODE);

  feeCurrency: string = " ";

  isQuote = false;

  quoteDetail: IQuoteDetail;

  subscription: Subscription;

  fiatCurrencies = ["USD", "GBP", "EUR", "AUSD", "SGD", "AUD", "CNH", "CAD", "CHF", "NZD", "JPY"];

  constructor(
    private domainService: DomainService,
    private convertService: ConvertService,
    private toastrService: ToastrService
  ) { }

  ngOnInit(): void {
    this.subscription = this.convertService
      .getConvertFlowdDataEmitter()
      .subscribe((data) => {
        this.quoteDetail = undefined;
        this.isQuote = false;
        this.errormsg = false;
        if (data) {
          if (data.crypto && data.wallet) {
            this.isQuote = true;
            this.getQuote(data, true);
          }
          this.convertFlowData = data;
          this.getFee();
          this.getFromCurrency();
          this.getToCurrency();
        }
      });
    this.checkFeeMode();
  }

  getDecimalNumber(symbol) {
    let toFix = "0.0";
    if (symbol === "CAS") {
      toFix = "0.0-0";
    } else if (this.fiatCurrencies.includes(symbol)) {
      toFix = "0.2-2";
    } else {
      if (symbol === CRYPTO_CURRENCY.BTC || symbol === CRYPTO_CURRENCY.ETH) {
        toFix = "0.2-10";
      } else {
        toFix = "0.2-4";
      }

    }
    return toFix;
  }

  getQuote(data: IConvert, flag?): void {
    this.initialLoad = true;
    this.errormsg = false;
    this.errorMessage = '';
    const payload: IQuote = {
      from: data.from,
      to: data.to,
      fromamount: data.convertFrom,
      toamount: data.convertTo
    };

    this.convertService.getQuote(payload).subscribe(
      (res) => {
        let resp = res.message.replace('"', "").replace('"', "");
        this.convertService.getQuoteDetail(resp).subscribe(
          (detail) => {
            if (flag) {
              this.inView = true;
            }
            this.quoteDetail = detail;
            this.initialLoad = false;
            this.timeExpired = false;
          },
          (err) => {
            if (err.error.message) {
              this.toastrService.error(err.error.message);
            } else {
              this.toastrService.error(UNABLE_TO_PERFORM_ERROR);
            }
          }
        );
      },
      (err) => {
        if (err.error.message) {
          this.toastrService.error(err.error.message);
          this.initialLoad = false;
          // if (err.error.message === 'Quotation not created, try again later') {
          //   this.goToAmountPage();
          // } else {
          //   this.errorMessage = err.error.message;
          // }
          this.errorMessage = err.error.message;
        } else {
          this.toastrService.error(UNABLE_TO_PERFORM_ERROR);
          this.initialLoad = false;
          this.errorMessage = UNABLE_TO_PERFORM_ERROR;
        }
      }
    );
  }

  ngOnChanges(): void {
    if (this.inView) {
      this.timeExpired = false;
    }
  }

  /**
   * On submission of form
   */
  onFormSubmit(): void {
    this.errormsg = false;
    if (this.isQuote) {
      this.submitQuoteData();
    }
  }

  submitQuoteData(): void {
    this.loading = true;
    this.inView = false;
    this.errormsg = false;
    delete this.quoteDetail.cashaaUserId;
    this.convertService.emitQuoteData(this.quoteDetail);
    const quoteData: IQuoteId = {
      quoteId: this.quoteDetail.quoteId
    }
    this.convertService.convertMoneyQuote(quoteData).subscribe(
      (res) => {
        this.loading = false;
        const requestData = new ConvertMoneyRequest(
          this.convertFlowData.fromcurrencyId,
          this.convertFlowData.tocurrencyId,
          this.convertFlowData.feeId,
          this.convertFlowData.convertFrom,
          this.convertFlowData.convertTo,
          this.convertFlowData.rate
          //   this.tocurrencyObject,
        );

        const completeData: IConvertComplete = {
          amountConverted: this.convertFlowData.convertTo,
          amountToConvert: this.convertFlowData.convertFrom,
          fromCurrencySymbol: this.fromcurrencyObject.symbol,
          toCurrencySymbol: this.tocurrencyObject.symbol,
          // convertFee: this.feeAmountObject.convertFee,
          convertFee: 0,
          rate: this.convertFlowData.rate,
        };
        this.convertService.emitConvertCompletedData(completeData);
        this.completed.emit(true);
      },
      (err) => {
        this.loading = false;
        this.errormsg = true;
        if (err.error.message) {
          this.toastrService.error(err.error.message);
        } else {
          this.toastrService.error(UNABLE_TO_PERFORM_ERROR);
        }
      }
    );
  }

  /**
   * Get fee data
   */
  getFee(): void {
    // this.domainService
    //   .getFeeByCurrencyId(this.convertFlowData.fromcurrencyId)
    //   .subscribe((feeAmountObject) => {
    //     if (feeAmountObject) {
    //       this.feeAmountObject = feeAmountObject;
    //     }
    //   });
  }

  /**
   * Get from currency
   */
  getFromCurrency(): void {
    this.domainService
      .getPayCurrencyById(this.convertFlowData.fromcurrencyId)
      .subscribe((fromcurrencyObject) => {
        if (fromcurrencyObject) {
          this.fromcurrencyObject = fromcurrencyObject;
        }
      });
  }

  /**
   * Get to currency data
   */
  getToCurrency(): void {
    this.domainService
      .getPayCurrencyById(this.convertFlowData.tocurrencyId)
      .subscribe((tocurrencyObject) => {
        if (tocurrencyObject) {
          this.tocurrencyObject = tocurrencyObject;
        }
      });
  }

  getQuoteAgain(flag): void {
    this.getQuote(this.convertFlowData, flag);
  }

  /**
   * On transfer detail edit click
   */
  goToAmountPage(): void {
    if (this.loading) {
      return;
    }
    this.goback.emit(1);
  }

  checkFeeMode(): number {
    if (this.userFeeMode === "InNative" && this.quoteDetail !== undefined) {
      this.feeCurrency =
        this.quoteDetail.side === 1
          ? this.tocurrencyObject.symbol
          : this.convertFlowData.side === 2
            ? this.fromcurrencyObject.symbol
            : this.fromcurrencyObject.symbol;
      return 2;
    } else if (this.userFeeMode === "InCashaa") {
      this.feeCurrency = "CAS";
      return 1;
    } else {
      return 2;
    }
  }

  feeCalcutain(val: number): void {
    this.casrate = sessionStorage.getItem(USER_CAS_FEE_RATE);
    let fee = Number(Number(val) * (Number(this.conversionFeePercent) / 100));
    // this.convertFlowData.feeInNative = fee;
    // this.quoteDetail.feeInCas = fee / Number(this.casrate);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  getCurrencyName(curr): string {
    if (curr === "AUSD") return "USD";
    if (curr === "DOG") return "DOGE";
    else return curr;
  }
}

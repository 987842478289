import { Injectable } from "@angular/core";
import { HttpClient, HttpResponse } from "@angular/common/http";
import {
  IBusinessProfile,
  BusinessProfile,
} from "src/app/core/models/business-profile";
import { environment } from "src/environments/environment";
import { Observable, BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ProfileService {
  /** Api url */
  private apiUrl = environment.api;

  /** CategoryId data emitter */
  private categoryIdDataEmitter = new BehaviorSubject<string | undefined>(
    undefined
  );

  constructor(private http: HttpClient) {}

  /*** Get Profile Details*/
  getProfileDetails(): Observable<IBusinessProfile> {
    return this.http.get<IBusinessProfile>(
      `${this.apiUrl}/users/getbusinessuserprofile`
    );
  }

  updateBusinessProfile(
    updateProfileData: BusinessProfile
  ): Observable<HttpResponse<string>> {
    return this.http.post<any>(
      `${environment.api}/users/updatebusinessuserprofile`,
      updateProfileData
    );
  }

  /**
   * Emit Categoryid data
   */
  emiCategoryIdData(data: string): void {
    this.categoryIdDataEmitter.next(data);
  }

  /*** Get Category Id data emitter***/
  getCategoryIdDataEmitter(): Observable<string> {
    return this.categoryIdDataEmitter.asObservable();
  }
}

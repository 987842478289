import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { UNABLE_TO_PERFORM_ERROR } from "src/app/app.constants";
import { WithdrawConfirmation } from "src/app/core/models/withdraw-fund";
import { WithdrawService } from "src/app/withdraw/services/withdraw.service";

@Component({
  selector: "cashaa-recipient-address-confirmation",
  templateUrl: "./recipient-address-confirmation.component.html",
  styleUrls: ["./recipient-address-confirmation.component.scss"],
})
export class RecipientAddressConfirmationComponent implements OnInit {
  ConfirmationToken: string;
  currency: string;

  /** Whether to show loading indicator or not */
  showLoadingIndicator = true;

  /** Erorr message if any */
  errorMessage: boolean;

  isVerified = "Invalid Request";
  fiatCurr: Array<string> = ["EUR", "GBP", "USD"];
  isFiat: boolean;
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private withdrawService: WithdrawService,
    private toastrService: ToastrService
  ) {}

  ngOnInit(): void {
    this.getTokenFromURL()
  }

  /**
   * Get Email and token from URL
   */
  getTokenFromURL(): void {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.ConfirmationToken = params["token"];
      this.onWithdrawAddressConfirmation();
    });
  }

  /**
   * On link check
   */
  onWithdrawAddressConfirmation(): void {
    let withdraw = new WithdrawConfirmation(this.ConfirmationToken);

    this.withdrawService.withdrawAddressConfirmation(withdraw).subscribe(
      () => {
        this.showLoadingIndicator = false;
        this.isVerified = "Verification Successful";
      },
      (err) => {
        this.errorMessage = true;
        this.isVerified = "Verification Failed";
        this.showLoadingIndicator = false;
      }
    );
  }

  onContinue() : void {
    this.router.navigateByUrl("/activity/list");
  }
}

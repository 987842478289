import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { KycRoutingModule } from "./kyc-routing.module";
import { SharedModule } from "../shared/shared.module";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { MDBBootstrapModulesPro } from "ng-uikit-pro-standard";
import { KycBusinesApplicationStatusComponent } from "./kyc-busines-application-status/kyc-busines-application-status.component";
import { KycPersonalComponent } from "./kyc-personal/kyc-personal.component";
import { KycPersonalInformationComponent } from "./kyc-personal-information/kyc-personal-information.component";
import { KycPersonalAddressComponent } from "./kyc-personal-address/kyc-personal-address.component";
import { KycPersonalReviewComponent } from "./kyc-personal-review/kyc-personal-review.component";
import { KycBusinessComponent } from "./kyc-business/kyc-business.component";
import { KycBusinessInformationComponent } from "./kyc-business-information/kyc-business-information.component";
import { KycBusinessDirectorDetailComponent } from "./kyc-business-director-detail/kyc-business-director-detail.component";
import { KycBusinessChecklistComponent } from "./kyc-business-checklist/kyc-business-checklist.component";
import { KycUploadContactDocComponent } from "./kyc-upload-contact-doc/kyc-upload-contact-doc.component";
import { KycBusinessUserAddComponent } from "./kyc-business-user-add/kyc-business-user-add.component";
import { KycVerificationEmailComponent } from "./kyc-verification-email/kyc-verification-email.component";
import { KycCustomerVerificationEmailComponent } from "./kyc-customer-verification-email/kyc-customer-verification-email.component";
import { KycUsPersonalHomeComponent } from "./kyc-us-personal-home/kyc-us-personal-home.component";
import { KycUsPersonalInformationComponent } from "./kyc-us-personal-information/kyc-us-personal-information.component";
import { KycUsPersonalPostalAddressComponent } from "./kyc-us-personal-postal-address/kyc-us-personal-postal-address.component";
import { KycUsPersonalContactDetailComponent } from "./kyc-us-personal-contact-detail/kyc-us-personal-contact-detail.component";
import { KycUsPersonalDocumentsComponent } from "./kyc-us-personal-documents/kyc-us-personal-documents.component";
import { ToastrModule } from "ngx-toastr";
import { QRCodeModule } from "angular2-qrcode";
import { KycStatusComponent } from './kyc-status/kyc-status.component';
import { KycPersonalIdentityProofComponent } from './kyc-personal-identity-proof/kyc-personal-identity-proof.component';
import { KycPersonalAddressProofComponent } from './kyc-personal-address-proof/kyc-personal-address-proof.component';
import { KycPersonalSelfieUploadComponent } from './kyc-personal-selfie-upload/kyc-personal-selfie-upload.component';
import { KycBusinessApplicationFormComponent } from './kyc-business-application-form/kyc-business-application-form.component';

@NgModule({
  declarations: [
    KycBusinesApplicationStatusComponent,
    KycPersonalComponent,
    KycPersonalInformationComponent,
    KycPersonalAddressComponent,
    KycPersonalReviewComponent,
    KycBusinessComponent,
    KycBusinessInformationComponent,
    KycBusinessDirectorDetailComponent,
    KycBusinessChecklistComponent,
    KycUploadContactDocComponent,
    KycBusinessUserAddComponent,
    KycVerificationEmailComponent,
    KycCustomerVerificationEmailComponent,
    KycUsPersonalHomeComponent,
    KycUsPersonalInformationComponent,
    KycUsPersonalPostalAddressComponent,
    KycUsPersonalContactDetailComponent,
    KycUsPersonalDocumentsComponent,
    KycStatusComponent,
    KycPersonalIdentityProofComponent,
    KycPersonalAddressProofComponent,
    KycPersonalSelfieUploadComponent,
    KycBusinessApplicationFormComponent,
  ],
  imports: [
    CommonModule,
    KycRoutingModule,
    SharedModule,
    ReactiveFormsModule,
    FormsModule,
    ToastrModule.forRoot(),
    MDBBootstrapModulesPro.forRoot(),
    QRCodeModule,
  ],
})
export class KycModule {}

import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "cashaa-kyc-status",
  templateUrl: "./kyc-status.component.html",
  styleUrls: ["./kyc-status.component.scss"],
})
export class KycStatusComponent implements OnInit {
  @Input()
  kycCompleteStatus: boolean;

  constructor() {}

  ngOnInit(): void {}
}

<section class="activity-box px-3">
  <div class="details">
    <mdb-accordion [multiple]="false" aria-multiselectable="false">
      <mdb-accordion-item class="no-collase ledger-list-item">
        <mdb-accordion-item-head class="p-2" mdbWavesEffect>
          <div class="d-flex flex-row px-2 align-items-center">
            <div class="px-2 circle">
              <img src="../../../assets/images/Fees.png" style="height: 25px; width: 25px;" />
            </div>
            <div class="status ml-2 transaction-detail"><span class="text-primary-dark">{{getName()}}</span></div>
            <div class="amount ml-auto mr-4">
              <b>{{ ledger.amount | number: "0.0-4" }} {{ ledger.currency }}</b><br />
              <span class="text-gray">{{ ledger.amount + ledger.networkFee | number: "0.0-4" }}
                {{ ledger.currency }}</span>
                        </div>
                    </div>
                </mdb-accordion-item-head>
                <mdb-accordion-item-body class="px-4 position-relative">
                    <div class="container px-4 pt-2">
                        <div class="row">
                            <div class="col-sm">
                                To <br />
                                <b class="font-weight-bolder text-primary">Cashaa</b>
                            </div>
                            <div class="col-sm" *ngIf="ledger.ledgerType === 17 || ledger.ledgerType === 21 ">
                                Memo <br />
                                <b class="font-weight-bolder text-primary">{{ledger.transactionData}}</b>
                            </div>
                        </div>
                    </div>
                </mdb-accordion-item-body>
            </mdb-accordion-item>
        </mdb-accordion>
    </div>
</section>
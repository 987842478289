<section class="activity-box px-3">
  <div class="details">
    <mdb-accordion [multiple]="false">
      <mdb-accordion-item class="ledger-list-item">
        <mdb-accordion-item-head mdbWavesEffect>
          <div class="d-flex flex-row p-2 align-items-center">
            <div class="ml-2 px-2 circle" [ngClass]="{'circle-cancel':isCancel()}">
              <img *ngIf="ledger.ledgerType!==11" src="../../../assets/images/Sent.png" />
              <img *ngIf="ledger.ledgerType===11" src="../../../assets/images/Locked.png" style="height: 28px;" />
            </div>
            <div class="status ml-2 transaction-detail">
              To
              <span *ngIf="ledger.ledgerType!==11" class="text-primary-dark">{{
                ledger?.ledgerRecipient
                }}</span>
                            <span *ngIf="ledger.ledgerType===11" class="text-primary-dark font-weight-bold">Locked</span>
                            <br />
                            <span *ngIf="(!ledger.withdrawConfirm && ledger.status=== 1) && !isCancelFlag" class="text-gray">
                Pending</span>
                            <span *ngIf="ledger.withdrawConfirm && ledger.status === 1" class="text-gray"> Processing</span>
                            <span *ngIf="isCancelFlag" class="text-gray">Cancelled</span>
                            <span *ngIf="this.ledger.status === 2 && this.ledger.withdrawConfirm" class="text-gray">
                Sent</span>
                        </div>
                        <div *ngIf="ledger.ledgerType!==11" class="amount ml-auto mr-4">
                            <b>{{ ledger?.convertToamount |number: "0.0-4" }}
                {{ getCurrencyName(ledger?.convertTocurrency) }} </b><br />
                            <span class="text-gray">{{ ledger?.convertFromamount | number: "0.0-4" }}
                {{ getCurrencyName(ledger?.convertfromcurrency) }}</span>
                        </div>
                        <div *ngIf="ledger.ledgerType===11" class="amount ml-auto mr-4">
                            <b>{{ ledger?.convertFromamount |number: "0.0-4" }}
                {{ getCurrencyName(ledger?.currency) }} </b><br />
                        </div>
                    </div>
                </mdb-accordion-item-head>
                <mdb-accordion-item-body class="px-4 position-relative sm-custom-stepper">
                    <div class="px-5 ml-5 pt-2">
                        <div class="row">
                            <div class="col-sm">
                                <!-- Vertical Steppers -->
                                <div class="row mt-1">
                                    <div class="col-md-12">
                                        <!-- Stepers Wrapper -->
                                        <ul class="stepper stepper-vertical green-stepper">
                                            <!-- First Step -->
                                            <li class="success successNext" [ngClass]="{'cancelNext':isCancel()}">
                                                <a class="sm-default-cursor">
                                                    <span *ngIf="ledger.ledgerType!==11" class="date">{{ ledger?.created | date: "MMM d, yyyy"
                            }}</span>
                                                    <span *ngIf="ledger.ledgerType===11" class="date">{{ ledger?.startDate | date: "MMM d, yyyy"
                            }}</span>
                                                    <div class='sm-width-50'><span [ngClass]="{'circle-cancel':isCancel()}" class="circle text-danger"></span></div>
                                                    <span *ngIf="ledger.ledgerType!==11" class="label">You setup your transfer</span>
                                                    <span *ngIf="ledger.ledgerType===11" class="label">You setup your staking</span>
                                                </a>
                                            </li>

                                            <li [ngClass]="{'success':isSuccessClass(2),'successNext':isSuccessClass(3), 'cancelNext':isCancel() }">
                                                <a class="sm-default-cursor">
                                                    <span *ngIf="ledger.ledgerType!==11" class="date">{{ ledger?.created | date: "MMM d, yyyy"
                            }}</span>
                                                    <span *ngIf="ledger.ledgerType===11" class="date">{{ ledger?.startDate | date: "MMM d, yyyy"
                            }}</span>
                                                    <div class='sm-width-50'><span [ngClass]="{'circle-cancel':isCancel()}" class="circle"></span>
                                                    </div>
                                                    <span *ngIf="ledger.ledgerType!==11" class="label">You used your
                            {{ ledger?.convertfromcurrency }} balance</span>
                                                    <span *ngIf="ledger.ledgerType===11" class="label">You used your cashaa balance</span>
                                                </a>
                                            </li>
                                            <li [ngClass]="{'success':isSuccessClass(3),'successNext':isSuccessClass(4), 'cancelNext':isCancel() && isSuccessClass(4)}">
                                                <a class="sm-default-cursor">
                                                    <span *ngIf="ledger.ledgerType !== 11" class="date">{{ ledger?.created | date: "MMM d, yyyy"
                            }}</span>
                                                    <span *ngIf="ledger.ledgerType === 11" class="date">{{ ledger?.startDate | date: "MMM d, yyyy"
                            }}</span>
                                                    <div class="sm-width-50">
                                                        <span class="circle" [ngClass]="{'circle-cancel':isCancel()}"></span>
                                                        <div *ngIf="(!ledger.withdrawConfirm && ledger.status!==2) || (ledger.withdrawConfirm && ledger.status===1)" [ngClass]="isCancel() ? 'dot-cancel' : 'dot'"></div>
                                                        <div *ngIf="ledger.ledgerType===11 && ledger.islocked" class="dot">
                                                        </div>
                                                    </div>
                                                    <div style="display: contents;">
                                                        <span *ngIf="!ledger.withdrawConfirm && ledger.status ===1 && !isCancel()" class="label sm-inline">Confirmation Pending
                              <a (click)="getVerificaionLink()" class="sm-ver-link"
                                [ngClass]="{'disableLink':timerFlag || disableBtn}">Get Verification Link</a>
                              <cashaa-timer [time]="timerTime" [start]="timerStart" [noExpiry]="true"
                                (expire)="timerExpire()">
                              </cashaa-timer>
                            </span>
                                                    </div>
                                                    <span *ngIf="isCancel()" class="label">Your transaction has been cancelled.
                          </span>
                                                    <span *ngIf="ledger.withdrawConfirm && ledger.status===1 && !isCancel() && ledger.ledgerType!==11" class="label">Processing..
                          </span>
                                                    <span *ngIf="ledger.ledgerType===11" class="label">Locked
                          </span>
                                                    <span *ngIf="ledger.withdrawConfirm && ledger.status===2 && !isCancel()" class="label">We paid
                            out your
                            {{ ledger?.convertfromcurrency }}</span>
                                                </a>
                                            </li>

                                            <!-- Second Step  -->
                                            <li [ngClass]="{'success':isSuccessClass(4) }">
                                                <!--Section Title -->
                                                <a class="sm-default-cursor">
                                                    <span *ngIf="ledger.ledgerType!==11" class="date">{{ ledger?.created | date: "MMM d, yyyy"
                            }}</span>
                                                    <span *ngIf="ledger.ledgerType===11" class="date">{{ ledger?.endDate | date: "MMM d, yyyy"
                            }}</span>
                                                    <div class='sm-width-50'>
                                                        <span class="circle"></span>
                                                        <div *ngIf="ledger.withdrawConfirm && ledger.status===2" class="dot"></div>
                                                        <div *ngIf="ledger.ledgerType===11 && !ledger.islocked" class="dot">
                                                        </div>
                                                    </div>
                                                    <span *ngIf="ledger.ledgerType!==11" [ngClass]="{'text-success':ledger.withdrawConfirm && ledger.status===2}" class="label">Your
                            Transfer's complete</span>
                                                    <span *ngIf="ledger.ledgerType===11" [ngClass]="{'text-success':ledger.withdrawConfirm && ledger.status===2}" class="label">Unlocked</span>
                                                </a>
                                            </li>
                                            <li class="conclusion ml-2">
                                                <span *ngIf="ledger.status === 1 && ledger.ledgerType !== 11" class="label">We are sending {{
                          ledger?.convertToamount | number:
                          "0.0-4" }}
                          {{ ledger?.convertTocurrency }} to
                          {{ ledger?.ledgerRecipient }}</span>

                                                <span *ngIf="(this.ledger.status === 2 && this.ledger.withdrawConfirm) && ledger.ledgerType !== 11 && (isCrypto())" class="label">
                          <a target="_blank"
                            [attr.href]="getRef()"
                            class="font-weight-bolder text-primary p-0" target="_blank">We have send {{
                            ledger?.convertToamount | number:
                            "0.0-4" }}
                            {{ ledger?.convertTocurrency }} to
                            {{ ledger?.ledgerRecipient }}</a></span>
                                                <span *ngIf="(this.ledger.status === 2 && this.ledger.withdrawConfirm) && ledger.ledgerType !== 11 && (isFiatAndAccount())" class="label">
                          We have send {{ ledger?.convertToamount | number:
                          "0.0-4" }}
                          {{ getCurrencyName(ledger?.convertTocurrency) }} to
                          {{ ledger?.ledgerRecipient }}
                        </span>
                                            </li>
                                        </ul>
                                        <!-- /.Stepers Wrapper -->
                                        <div class="ml-7 pl-5">
                                            <button type="button" class="btn btn-outline-dark rounded" (click)="help()">
                        Need any help?
                      </button>
                                            <button *ngIf="ledger.status === 1 && !ledger.withdrawConfirm" type="button" [disabled]="isCancel()" class="btn btn-outline-dark rounded" (click)="cancel(ledger.id)">
                        <span *ngIf="isCancelLoading" class="spinner-border spinner-border-sm mr-2" role="status"
                          aria-hidden="true"></span>Cancel
                      </button>
                                        </div>
                                    </div>
                                    <div class="col-md-12"></div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="ledger.ledgerType!==11" class="btn-group mx-4 position-absolute no-arrow details-button" mdbDropdown placement="left">
                        <mdb-icon fas icon="ellipsis-h" classInside="dropdown-toggle" mdbDropdownToggle class="waves-effect" mdbWavesEffect></mdb-icon>
                        <div class="dropdown-menu dropdown-primary">
                            <a class="dropdown-item" (click)="openDetailBox()">View transfer details</a>
                        </div>
                    </div>
                </mdb-accordion-item-body>
            </mdb-accordion-item>
        </mdb-accordion>
    </div>
</section>
<div class="my-4">
    <h3 class="font-weight-bolder text-dark">Application Form</h3>
    <hr>
    <div class="d-inline-flex p-5 w-100 pl-0" *ngIf="plan === 'USD'">
        <div class="file-width">
            <fieldset class="border p-2">
                <legend class="w-auto sm-label font-weight-normal">Opening Account Form</legend>
                <cashaa-form-input-file [docType]="fileUSDApplication"
                    (deleteFile)="onFileDelete($event, fileUSDApplication)" [fileUploadStatus]="true"
                    [isFileUploadCompleted]="fileUSDApplication?.isDeletedValid ? true : false"
                    [isOperationCompleted]="fileUSDApplication.fileActionLoader ? true : false"
                    [isDeletedActive]="true">
                </cashaa-form-input-file>
            </fieldset>
        </div>

        <div class="p-5 ml-5">
            <h4 class="font-weight-bolder text-dark">Please <a href="javascript:void(0)" target="_blank"
                    href={{downloadDoc1()}} class="p-0">download</a> the application from here.</h4>
        </div>
    </div>
    <div class="d-inline-flex p-5 w-100 pl-0" *ngIf="plan !== 'USD'">
        <div class="file-width">
            <fieldset class="border p-2">
                <legend class="w-auto sm-label font-weight-normal">Opening Account Form</legend>
                <cashaa-form-input-file [docType]="fileCorporateAccount"
                    (deleteFile)="onFileDelete($event, fileCorporateAccount)" [fileUploadStatus]="true"
                    [isFileUploadCompleted]="fileCorporateAccount?.isDeletedValid ? true : false"
                    [isOperationCompleted]="fileCorporateAccount.fileActionLoader ? true : false"
                    [isDeletedActive]="true">
                </cashaa-form-input-file>
            </fieldset>
        </div>

        <div class="p-5 ml-5">
            <h4 class="font-weight-bolder text-dark">Please <a href="javascript:void(0)" target="_blank"
                    href={{downloadDoc1()}} class="p-0">download</a> the application from here.</h4>
        </div>
    </div>
    <hr *ngIf="plan !=='USD'">
    <div class="d-inline-flex p-5 w-100 pl-0" *ngIf="plan !=='USD'">
        <div class="file-width">
            <fieldset class="border p-2">
                <legend class="w-auto sm-label font-weight-normal">NDA</legend>
                <cashaa-form-input-file [docType]="fileNda" (deleteFile)="onFileDelete($event, fileNda)"
                    [fileUploadStatus]="true" [isFileUploadCompleted]="fileNda?.isDeletedValid ? true : false"
                    [isOperationCompleted]="fileNda.fileActionLoader ? true : false" [isDeletedActive]="true">
                </cashaa-form-input-file>
            </fieldset>
        </div>
        <div class="p-5 ml-5">
            <h4 class="font-weight-bolder text-dark">Please <a href="javascript:void(0)" target="_blank"
                    href={{downloadDoc2()}} class="p-0">download</a> the application from here.</h4>
        </div>
    </div>
    <div class="d-flex pt-5">
        <div class="w-100">
            <h4 class="font-weight-bolder text-dark p-5 pl-0">
                Note: The form needs to uploaded only when it is scanned and signed.
            </h4>
            <button mdbBtn block="true" type="button" [disabled]="getStatus()" (click)="onNext()"
                class="w-25 rounded-sm position-relative btn btn-primary waves-effect waves-light btn-block"
                mdbWavesEffect>
                Next
            </button>
        </div>
        <img src="../../../assets/images/application_form_kyc.png" />
    </div>

</div>
import { ICurrency } from "src/app/core/models/currency";
import { Component, OnDestroy, OnInit } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  Validators,
  AbstractControl,
} from "@angular/forms";
import { RecipientService } from "../services/recipient.service";
import { ValidatorUtility } from "src/app/core/utility/validator.utility";
import { IMyOptions, MDBModalRef } from "ng-uikit-pro-standard";
import { AddressWhitelist } from "src/app/core/models/address-whitelist";
import { WithdrawService } from "src/app/withdraw/services/withdraw.service";
import {
  APP_ROUTES,
  CUSTOMER_ADDED_SUCCESS,
  RECIPIENT_EMAIL_MESG,
  UNABLE_TO_PERFORM_ERROR,
  USER_TWO_FACTOR_CODE,
  CUSTOMER_UPDATED_SUCCESS,
  SUBSCRIPTION_PERSONAL_PLAN,
  SUBSCRIPTION_BUSINESS_PLAN,
  USER_TYPE,
} from "src/app/app.constants";
import {
  AddRecipientUsd,
  PostalAddress,
  Person,
  Recipient,
  Account,
  RecipientAccount,
  RecipientAddGbp,
  ICustomer,
} from "src/app/core/models/recipient/recipient-add-usd";
import { IFormSelect } from "src/app/core/models/form-select";
import { ToastrService } from "ngx-toastr";
import { CommonSavedData } from "src/app/shared/services/commonSavedData.service";
import { Router } from "@angular/router";
import { CustomerService } from "src/app/customer/services/customer.service";
import { DomainService } from "src/app/core/services/domain/domain.service";
import { FileUpload, IFile } from "src/app/core/models/Lerex/enum-load";
import { UserSubscriptionPlan } from "src/app/core/models/account-upgrade/subscription-plan-response";
import { RecipientType } from "src/app/core/enums/recipientTypeEnum";
import { ModalService } from "src/app/core/services/modal/modal.service";
import { Subscription } from "rxjs";
import { Google2faDto } from "src/app/core/models/EnableTwoFa";
import { AccountService } from "src/app/account/sevices/account.service";
const savingAccountType = require("../../../assets/json/accountType.json");
const addressdocumentTypes = require("../../../assets/json/addressProofDocumentType.json");
const identitydocumentTypes = require("../../../assets/json/identityProofDocumentType.json");
@Component({
  selector: "cashaa-recipient-add",
  templateUrl: "./recipient-add.component.html",
  styleUrls: ["./recipient-add.component.scss"],
})
export class RecipientAddComponent implements OnInit, OnDestroy {
  google2fa: Google2faDto;
  /** Recipient Form Group */
  recipientAddForm: FormGroup;
  /** is some network call in progress */
  isInProgress = false;
  twofactor = false;
  isCrypto = true;
  currentCur: ICurrency;
  userEmail?: string = undefined;
  initialValue;
  initialValueSortCode;
  userName?: string = undefined;
  beneficiarytype?: string;
  addressType?: string;
  symbol?: string;
  currecncyId = "";
  errorMessage = false;
  response = "";
  currencySymbol: string = "";
  savingAcTypesList: IFormSelect[] = [];
  isLoading = true;
  currFlag;
  isEdit: boolean;
  myDatePickerOptions: IMyOptions = {
    minYear: 1900,
    maxYear: 2099,
  };
  code: string = "";
  customer: {
    selectedCustomer: ICustomer;
    isUploadKyc: boolean;
  };
  isUploadKyc: boolean;
  userType = sessionStorage.getItem(USER_TYPE);
  isPremiumPlan: boolean = true;
  initialValueCountry: string;
  public identityfile: IFile = new FileUpload(null);
  public addressfile: IFile = new FileUpload(null);
  public selfiefile: IFile = new FileUpload(null);
  public addressProofOptions: IFormSelect[] = [];
  public identityProofOptions: IFormSelect[] = [];
  networkTypeOptions = [];
  currentUserPlanDetail: UserSubscriptionPlan;
  sub: Subscription;
  fiatCurrencies = ["USD", "GBP", "EUR", "AUSD", "SGD", "AUD", "CNH", "CAD", "CHF", "NZD", "JPY"];
  constructor(
    private fb: FormBuilder,
    public modalRef: MDBModalRef,
    private recipientService: RecipientService,
    private addressWhitelistService: WithdrawService,
    private commonSavedData: CommonSavedData,
    private toastrService: ToastrService,
    private router: Router,
    private customerService: CustomerService,
    private domainService: DomainService,
    private accountService: AccountService,
    private modalService: ModalService
  ) { }

  ngOnInit(): void {
    this.checkTwoFactorEnable();
    if (this.beneficiarytype !== 'broker') {
      this.setPremiumStatus();
    }
    this.identityfile.docType = "IdentityFile";
    this.addressfile.docType = "AddressFile";
    this.selfiefile.docType = "SelfieFile";
    if (this.addressType === 'cryptoAddress') {
      this.initialValue = '43796ac1-8f4e-4875-2533-08d7b39928d1';
    }
    else {
      this.initialValue = "2633816a-dc95-4015-2536-08d7b39928d1";
    }
    this.savingAcTypesList = savingAccountType;
    this.createForm();
    if (this.currentCur?.symbol === "USD") {
      // if (this.userType === "5") {
      //   this.recipientAddForm.controls["lastName"].clearValidators();
      // } else {
      //   this.recipientAddForm.controls["lastName"].setValidators(
      //     Validators.required
      //   );
      // }
      setTimeout(() => {
        // this.recipientAddForm.controls["lastName"].updateValueAndValidity({
        //   onlySelf: true,
        // });
        // this.recipientAddForm.updateValueAndValidity({ onlySelf: true });
      });
    }
    if (this.userType === "4" && (this.customer || this.isUploadKyc)) {
      this.recipientAddForm.get("googleDriveUrl").setValue(undefined);
    }
    this.recipientAddForm.controls["currency"].valueChanges.subscribe(
      (data) => {
        if (data) {
          this.changeCurr(data);
        }
      }
    );
    if (this.beneficiarytype !== 'broker') {
      this.recipientAddForm.controls["recipientType"].valueChanges.subscribe((val) => {
        if (val) {
          if (Number(val) === RecipientType.Others) {
            this.recipientAddForm.controls["firstName"].disable();
            this.recipientAddForm.controls["firstName"].setValue(this.userName)
            if (this.isPremiumPlan) {
              this.recipientAddForm.controls["email"].enable();
              this.recipientAddForm.controls["fullName"].disable();
              this.recipientAddForm.controls["email"].setValue('');
              this.recipientAddForm.controls["firstName"].disable();
              this.recipientAddForm.controls["firstName"].setValue(this.userName)
              // this.recipientAddForm.controls["fullName"].setValue('');
            }
          } else {
            console.log(this.recipientAddForm.controls["firstName"])
            this.recipientAddForm.controls["email"].setValue(this.userEmail);
            this.recipientAddForm.controls["fullName"].setValue(this.userName);
            this.recipientAddForm.controls["firstName"].setValue(this.userName)
            this.recipientAddForm.controls["email"].disable();
            this.recipientAddForm.controls["fullName"].disable();
            // this.isPremiumPlan = true;
          }
        }
      });
    }


    if (this.customer) {
      this.isLoading = true;
      this.addressProofOptions = addressdocumentTypes;
      this.identityProofOptions = identitydocumentTypes;
      this.isUploadKyc = this.customer.isUploadKyc;
      this.editBrokerCustomer(this.customer.selectedCustomer);
      this.domainService.getKycUploadFile().subscribe(data => {
        if (data.docType === "SelfieFile") {
          this.selfiefile = data;
          this.selfiefile.isDeletedValid = true;
        } else if (data.docType === "IdentityFile") {
          this.identityfile = data;
          this.identityfile.isDeletedValid = true;
        } else if (data.docType === "AddressFile") {
          this.addressfile = data;
          this.addressfile.isDeletedValid = true;
        }
      });
    }
  }

  checkTwoFactorEnable() {
    this.accountService.isTwoFactorEnabled().subscribe(res => {
      sessionStorage.setItem(USER_TWO_FACTOR_CODE, res.toString());
      this.twofactor = res;
    }, err => {

    })
  }

  editBrokerCustomer(customer: ICustomer) {
    this.isEdit = true;
    this.beneficiarytype = customer.beneficiaryType;
    this.initialValue = customer.currencyId;
    this.domainService
      .getFiatCurrency()
      .subscribe((currencies: ICurrency[]) => {
        if (currencies && !this.currentCur) {
          this.currentCur = currencies.filter(
            (c) => c.id === this.initialValue
          )[0];
          this.recipientAddForm.get("email").setValue(customer.email);
          this.recipientAddForm.get("fullName").setValue(customer.firstName);
          this.recipientAddForm.get("userType").setValue(customer.userType);
          this.recipientAddForm.get("ibanOrSortCode").setValue(customer.iban);
          this.recipientAddForm.get("swiftOrBic").setValue(customer.swiftCode);
          this.recipientAddForm.get("sortCode").setValue(customer.sortCode);
          this.initialValueSortCode = customer.sortCode;
          this.recipientAddForm.get("branchName").setValue(customer.branchName);
          if (this.currentCur.symbol === "EUR") {
            this.recipientAddForm.get("address1").setValue(customer.address1);
          }
          if (this.currentCur.symbol === "USD") {
            this.recipientAddForm
              .get("routingNumber")
              .setValue(customer.routingNumber);
            this.recipientAddForm.get("bankCode").setValue(customer.swiftCode);
            this.recipientAddForm.get("accountType").setValue(customer.type);
            // if (customer.userType === "4") {
            this.initialValueCountry = customer.countryOfBirth;
            // this.recipientAddForm.get("dob").setValue(customer.dob);
            // }
            this.recipientAddForm.get("firstName").setValue(customer.firstName);
            // this.recipientAddForm
            //   .get("middleName")
            //   .setValue(customer.middleName);
            // this.recipientAddForm.get("lastName").setValue(customer.lastName);
            this.recipientAddForm
              .get("address1")
              .setValue(customer.addressLine1);
            // this.recipientAddForm
            //   .get("address2")
            //   .setValue(customer.addressLine2);
            // this.recipientAddForm
            //   .get("address3")
            //   .setValue(customer.addressLine3);
            // this.recipientAddForm.get("mobile").setValue(customer.mobile);
          }
          this.recipientAddForm.get("postalCode").setValue(customer.postalCode);
          this.recipientAddForm.get("city").setValue(customer.city);
          this.recipientAddForm.get("bankName").setValue(customer.bankName);
          this.recipientAddForm
            .get("accountNumber")
            .setValue(
              this.currentCur.symbol === "USD"
                ? customer.accountNumber
                : customer.accountNo
            );
          this.recipientAddForm
            .get("countryCode")
            .setValue(customer.countryCode);
          if (customer.userType === "5") {
            this.recipientAddForm
              .get("googleDriveUrl")
              .setValue(customer.googleDriveUrl);
          }
          setTimeout(() => {
            this.isLoading = false;
          }, 1000);
        }

        this.recipientAddForm.updateValueAndValidity();
      });
  }

  changeCurr(data: string): void {
    let currencies = this.commonSavedData.getCurrencies()
      ? this.commonSavedData.getCurrencies()
      : null;
    if (currencies) {
      let selectedCurr = currencies.filter((c) => c.id === data);
      this.listenToCurrencyChange(selectedCurr[0]);
      if (this.symbol) {
        selectedCurr = currencies.filter((c) => c.symbol === this.symbol);
        this.initialValue = selectedCurr[0].id;
      }
      setTimeout(() => {
        this.isLoading = false;
      }, 500);
    }
  }

  onCurrencyChange(cur: ICurrency): void {
    this.code = "";
    this.currentCur = cur;
    this.recipientAddForm.patchValue(
      {
        currency: cur.id,
      },
      {
        emitEvent: false,
      }
    );
    this.changeCurr(cur.id);
  }

  /**
   * Create new form
   */
  createForm(): void {
    this.recipientAddForm = this.fb.group({
      currency: [""],
      email: [{ value: this.userEmail, disabled: this.beneficiarytype === 'broker' ? false : true }],
      fullName: [{ value: "", disabled: this.beneficiarytype === 'broker' ? false : true }],
      sortCode: [""],
      ibanOrSortCode: [""],
      swiftOrBic: [""],
      userType: ["4"],
      recipientType: [""],
      cryptoAddress: [""],
      // cryptoEmail: [""],
      bankName: [""],
      branchName: [""],
      postCode: "",
      addressLabel: [""],
      countryCode: [""],
      routingNumber: [""],
      bankCode: [""],
      nickname: [""],
      firstName: [""],
      address1: [""],
      postalCode: [""],
      city: [""],
      province: [],
      country: ["US"],
      accountType: [],
      accountNumber: [""],
      accountCurrencyCode: ["USD"],
      googleDriveUrl: [""],
      identityProof: [""],
      addressProof: [""],
      identityDocumentType: [""],
      addressDocumentType: [""],
      selfie: [""],
      networkType: [""],
      countryOfBirth: [""],
      cryptoMemo: [""]

    });
  }

  listenToCurrencyChange(newValue: ICurrency): void {
    for (let control in this.recipientAddForm.controls) {
      this.recipientAddForm.controls[control].clearValidators();
    }
    setTimeout(() => {
      // if (this.userType === "5") {
      //   this.recipientAddForm.controls["lastName"].clearValidators();
      // }
      // this.recipientAddForm.controls["lastName"].updateValueAndValidity({
      //   onlySelf: true,
      // });
      // this.recipientAddForm.updateValueAndValidity({ onlySelf: true });
    });
    this.recipientAddForm.updateValueAndValidity();
    let tempCurrency = this.recipientAddForm.value.currency;
    if (!this.isEdit) {
      this.recipientAddForm.reset();
      this.recipientAddForm.patchValue(
        {
          currency: tempCurrency,
          userType: "4",
          country: "US",
          accountCurrencyCode: "USD",
          agentCode: "US",
          agentCountryCode: "US",
          email: this.userEmail,
          recipientType: this.beneficiarytype === 'broker' ? "2" : "1",
          fullName: this.userName
        },
        { emitEvent: false, onlySelf: true }
      );
    }
    this.recipientAddForm.updateValueAndValidity();
    this.currecncyId = newValue.id;
    this.currencySymbol = newValue.symbol;
    this.response = "";
    if (newValue.currencyType === 2) {
      this.isCrypto = false;
      // this.recipientAddForm.controls["email"].disable();
      this.recipientAddForm.controls["email"].setValidators([
        Validators.required,
      ]);
      if (this.beneficiarytype === 'broker') {
        this.recipientAddForm.controls["googleDriveUrl"].setValidators([
          ValidatorUtility.UrlValidator,
        ]);
      }

      if (this.currencySymbol === "USD") {
        // this.recipientAddForm.controls["email"].disable();
        this.recipientAddForm.controls["email"].setValue(this.userEmail);
        this.recipientAddForm.controls["routingNumber"].setValidators(
          Validators.required
        );
        this.recipientAddForm.controls["bankCode"].setValidators(
          Validators.required
        );
        // this.recipientAddForm.controls["mobile"].setValidators(
        //   Validators.required
        // );
        // this.recipientAddForm.controls["firstName"].setValidators([
        //   Validators.required,
        //   ValidatorUtility.letterValidator]
        // );
        // this.recipientAddForm.controls["middleName"].setValidators([
        //   ValidatorUtility.letterValidator]);
        // if (this.userType === "4") {
        //   this.recipientAddForm.controls["lastName"].setValidators([
        //     Validators.required,
        //     ValidatorUtility.letterValidator
        //   ]);
        // }
        // this.recipientAddForm.controls["address1"].setValidators([
        //   Validators.required,
        //   ValidatorUtility.max50,
        // ]);
        this.recipientAddForm.controls["city"].setValue('');
        this.recipientAddForm.controls["firstName"].setValue(this.userName);
        this.recipientAddForm.controls["postalCode"].setValue('');
        this.recipientAddForm.controls["address1"].setValue('');
        // this.recipientAddForm.controls["countryOfBirth"].setValidators(
        //   Validators.required
        // );
        // this.recipientAddForm.controls["city"].setValidators([
        //   Validators.required,
        //   ValidatorUtility.max50,
        // ]);
        // this.recipientAddForm.controls["province"].setValidators(
        //   Validators.required
        // );
        this.recipientAddForm.controls["accountType"].setValidators(
          Validators.required
        );
        // this.recipientAddForm.controls["bankName"].setValidators([
        //   Validators.required,
        //   ValidatorUtility.max50,
        // ]);
        this.recipientAddForm.controls["accountNumber"].setValidators([
          ValidatorUtility.OnlyAphaNumericRequired,
          Validators.required,
        ]);
        this.recipientAddForm.updateValueAndValidity();
      } else {
        if (this.currencySymbol === "EUR") {
          this.recipientAddForm.controls["ibanOrSortCode"].setValidators([
            Validators.required,
            // ,ValidatorUtility.IbanRegexEUR,
          ]);
          this.recipientAddForm.controls["swiftOrBic"].setValidators([
            Validators.required,
            // ,ValidatorUtility.swiftOrBicRegex,
          ]);
          this.recipientAddForm.controls["postalCode"].setValidators([
            Validators.required,
            ValidatorUtility.postalcode,
          ]);
          this.recipientAddForm.controls["countryCode"].setValidators([
            Validators.required,
            Validators.maxLength(2),
          ]);
          this.recipientAddForm.controls["address1"].setValidators([
            Validators.required,
            ValidatorUtility.max50,
          ]);
        } else if (this.currencySymbol === "GBP") {
          this.recipientAddForm.controls["sortCode"].setValidators(
            Validators.required
          );
          this.recipientAddForm.controls["accountNumber"].setValidators([
            ValidatorUtility.OnlyAphaNumericRequired,
            Validators.required,
          ]);
        }
        this.recipientAddForm.controls["bankName"].setValidators([
          Validators.required,
          ValidatorUtility.max50,
        ]);
        this.recipientAddForm.controls["branchName"].setValidators([
          Validators.required,
          ValidatorUtility.max50,
        ]);
        this.recipientAddForm.controls["city"].setValidators([
          Validators.required,
          ValidatorUtility.max50,
        ]);
        this.recipientAddForm.controls["fullName"].setValidators([
          Validators.required
        ]);
        this.recipientAddForm.updateValueAndValidity();
      }
    } else {
      this.isCrypto = true;
      if (this.currencySymbol === "CAS" || this.currencySymbol === "BNB") {
        this.recipientAddForm.controls["cryptoAddress"].setValidators([
          Validators.required,
          ValidatorUtility.AddressCASValidator,
        ]);

      } else if (
        this.currencySymbol === "ETH" ||
        this.currencySymbol === "USDT"
      ) {
        this.recipientAddForm.controls["cryptoAddress"].setValidators([
          Validators.required,
          ValidatorUtility.AddressETHUSDTValidator,
        ]);
      } else if (this.currencySymbol === "BTC") {
        this.recipientAddForm.controls["cryptoAddress"].setValidators([
          Validators.required,
          ValidatorUtility.BitcoinAddressValidator,
        ]);
      } else {
        this.recipientAddForm.controls["cryptoAddress"].setValidators(
          Validators.required
        );
      }
      // this.recipientAddForm.controls["addressLabel"].setValidators(
      //   Validators.required
      // );
      if (!(this.currencySymbol === 'BTC' || this.currencySymbol === 'BNB')) {
        this.recipientAddForm.controls["networkType"].setValidators(
          Validators.required
        );
      }
 
      if (!this.fiatCurrencies.includes(this.currencySymbol)) {
        this.networkTypeOptions = this.currentCur.networkType;
        this.networkTypeOptions = this.networkTypeOptions.filter(item => item.isSendActive == true);
        let defaultNetwork = this.networkTypeOptions.filter(item => item.isDefaultNetwork == true);
        this.recipientAddForm.controls["networkType"].setValue(defaultNetwork[0].networkValue);
      }
      if ((this.currencySymbol === "CAS" || this.currencySymbol === "BNB" || this.currencySymbol === "XRP")
        && this.recipientAddForm.get('networkType').value == 0) {
        this.recipientAddForm.controls["cryptoMemo"].setValidators([
          ValidatorUtility.OnlyNumberRequired,
        ]);
      }
      this.recipientAddForm.updateValueAndValidity();
    }

    setTimeout(() => {
      this.recipientAddForm.updateValueAndValidity();
    });
    this.isEdit = false;
  }

  getLabelValue(currency: string): string {
    if (currency === 'BNB') {
      return "BNB Beacon Chain (BEP2)"
    } else {
      return this.getCurrencyName(currency);
    }
  }

  getCurrencyName(curr): string {
    if (curr === "AUSD") return "USD";
    if (curr === "DOG") return "Dogecoin (DOGE)";
    if (curr === "BCH") return "Bitcoin Cash (BCH)";
    if (curr === "XRP") return "Ripple (XRP)";
    if (curr === "LTC") return "Litecoin (LTC)";
    else return curr;
  }

  /**
   * On submission of form
   */
  onFormSubmit(): void {
    const data = this.recipientAddForm.value;
    console.log('data: ', data);
    data.province = "";
    if (this.customer || this.isUploadKyc) {
      this.fillFormCustomerData(data);
    } else {
      this.errorMessage = false;

      if (this.isCrypto) {
        if (!data.cryptoMemo) {
          data.cryptoMemo = "";
        }
        const addressWhitelistData = new AddressWhitelist(
          this.currecncyId,
          data.cryptoAddress,
          data.addressLabel,
          this.code,
          data.networkType == null ? undefined : data.networkType,
          data.cryptoEmail,
          data.userType,
          data.cryptoMemo
        );
        this.isInProgress = true;
        if (data.networkType === '0' && !data.cryptoMemo && (this.currencySymbol === "CAS" || this.currencySymbol === "BNB" || this.currencySymbol === "XRP")) {
          this.modalService.openConfirmationModal('Centralize address need memo for withdraw. Do you want to continue without memo?')
          this.sub = this.commonSavedData.getConfirmationBoxStatus().subscribe((status: boolean) => {
            if (status) {
              this.addCryptoAddress(addressWhitelistData);
              this.sub.unsubscribe();
            } else {
              this.isInProgress = false;
              this.sub.unsubscribe();
            }

          })
        } else {
          this.addCryptoAddress(addressWhitelistData);
        }

      } else if (this.currencySymbol === "USD") {
        const postalAddress = new PostalAddress(
          data.address1,
          data.address2,
          data.address3,
          data.postalCode,
          data.city,
          data.province,
          data.country,
          data.countryOfBirth
        );
        let person;
        person = new Person(
          data.firstName,
          data.middleName ? data.middleName : "",
          data.lastName && this.userType === "4"
            ? data.lastName
            : "",
          data.mobile,
          data.email,
          data.nickname,
          postalAddress
        );
        if (
          data.countryOfBirth &&
          data.countryOfBirth.value
        ) {
          person.countryOfBirth = data.countryOfBirth.value;
        }
        // if (data.dob && this.userType === "4") {
        //   person.dob = data.dob;
        // }
        const recipient = new Recipient(person);
        const account = new Account(
          data.accountType,
          data.bankName,
          data.accountNumber,
          data.currency.value,
          data.routingNumber,
          data.bankCode
        );
        const recipientAccount = new RecipientAccount(account);

        const recipientData = new AddRecipientUsd(
          this.currecncyId.toUpperCase(),
          recipient,
          recipientAccount,
          data.userType,
          this.beneficiarytype === 'broker' ? "2" : data.recipientType,
          this.beneficiarytype
        );

        if (data.googleDriveUrl) {
          recipientData.googleDriveUrl = data.googleDriveUrl;
        }
        this.isInProgress = true;
        this.recipientService.addRecipientRequestUsd(recipientData).subscribe(
          (res) => {
            this.toastrService.success(RECIPIENT_EMAIL_MESG);
            this.isInProgress = false;
            this.modalRef.hide();
            this.recipientAddForm.reset();
            if (this.beneficiarytype === "broker") {
              this.customerService.emitCustomerAdd(true);
            } else {
              this.recipientService.emitaddRecipientFlag(true);
            }
            if (this.router.url.includes("/fiat")) {
              this.router.navigateByUrl(APP_ROUTES.RECIPIENT_BASE);
            }
          },
          (err) => {
            if (err.error.message) {
              this.toastrService.error(err.error.message);
            } else {
              this.toastrService.error(UNABLE_TO_PERFORM_ERROR);
            }
            this.isInProgress = false;
          }
        );
      } else if (
        this.currencySymbol === "GBP" ||
        this.currencySymbol === "EUR"
      ) {
        let recipientAddGbp;
        if (this.currencySymbol === "GBP") {
          recipientAddGbp = new RecipientAddGbp(
            "2",
            this.userType,
            this.beneficiarytype === 'broker' ? "2" : data.recipientType,
            data.fullName,
            data.sortCode,
            data.ibanOrSortCode,
            data.accountNumber,
            this.beneficiarytype,
            data.bankName,
            data.branchName,
            data.city,
            data.email,
            data.swiftOrBic,

          );
        } else {
          recipientAddGbp = new RecipientAddGbp(
            "3",
            data.userType,
            this.beneficiarytype === 'broker' ? "2" : data.recipientType,
            data.fullName,
            data.sortCode,
            data.ibanOrSortCode,
            data.accountNumber,
            this.beneficiarytype,
            data.bankName,
            data.branchName,
            data.city,
            data.email,
            data.swiftOrBic,
            data.address1,
            data.postalCode,
            data.countryCode
          );
        }
        if (data.googleDriveUrl) {
          recipientAddGbp.googleDriveUrl = data.googleDriveUrl;
        }
        this.isInProgress = true;
        this.recipientService.addRecipientRequest(recipientAddGbp).subscribe(
          (res) => {
            this.isInProgress = false;
            this.toastrService.success(
              this.beneficiarytype === "broker"
                ? CUSTOMER_ADDED_SUCCESS
                : RECIPIENT_EMAIL_MESG
            );
            this.modalRef.hide();
            this.recipientAddForm.reset();
            if (this.beneficiarytype === "broker") {
              this.customerService.emitCustomerAdd(true);
            } else {
              this.recipientService.emitaddRecipientFlag(true);
            }
            if (this.router.url.includes("/fiat")) {
              this.router.navigateByUrl(APP_ROUTES.RECIPIENT_BASE);
            }
          },
          (err) => {
            if (err.error.message) {
              this.toastrService.error(err.error.message);
            } else {
              this.toastrService.error(UNABLE_TO_PERFORM_ERROR);
            }
            this.isInProgress = false;
          }
        );
      }
    }
  }

  ibanEvent(data: any) {
    if (this.currencySymbol !== "EUR") {
      if (
        !this.recipientAddForm.value.swiftOrBic &&
        !this.recipientAddForm.value.ibanOrSortCode
      ) {
        this.recipientAddForm.controls["ibanOrSortCode"].clearValidators();
        this.recipientAddForm.controls["swiftOrBic"].clearValidators();
      } else if (
        !this.recipientAddForm.value.swiftOrBic ||
        !this.recipientAddForm.value.ibanOrSortCode
      ) {
        this.recipientAddForm.controls["ibanOrSortCode"].setValidators([
          Validators.required,
          ValidatorUtility.IbanRegexGBP,
        ]);
        this.recipientAddForm.controls["swiftOrBic"].setValidators([
          Validators.required,
          ValidatorUtility.swiftOrBicRegex,
        ]);
      }
      this.recipientAddForm.controls["ibanOrSortCode"].updateValueAndValidity();
      this.recipientAddForm.controls["swiftOrBic"].updateValueAndValidity();
    }
  }

  localBankEvent(data: any) {
    if (this.currencySymbol !== "GBP") {
      if (
        !this.recipientAddForm.value.sortCode &&
        !this.recipientAddForm.value.accountNumber
      ) {
        this.recipientAddForm.controls["sortCode"].clearValidators();
        this.recipientAddForm.controls["accountNumber"].clearValidators();
      } else if (
        !this.recipientAddForm.value.sortCode ||
        !this.recipientAddForm.value.accountNumber
      ) {
        this.recipientAddForm.controls["sortCode"].setValidators(
          Validators.required
        );
        this.recipientAddForm.controls["accountNumber"].setValidators([
          ValidatorUtility.OnlyAphaNumericRequired,
          Validators.required,
        ]);
      }
      this.recipientAddForm.controls["sortCode"].updateValueAndValidity();
      this.recipientAddForm.controls["accountNumber"].updateValueAndValidity();
    }
  }

  clearValidation(...controls: AbstractControl[]) {
    controls.forEach((control: AbstractControl) => {
      control.clearValidators();
      control.updateValueAndValidity();
    });
  }

  getUsdFirstNameLabel(value: string, flag: boolean): string {
    if (value === "5") {
      return "Legal Name";
    } else {
      if (flag) {
        return "Account Holder Name";
      } else {
        return "First Name";
      }
    }
  }

  updateCode(val: string): void {
    this.code = val;
  }

  getCurrencySource(): string {
    return this.beneficiarytype === "broker" ? "wallet" : "all";
  }

  getModalName(): string {
    if (this.beneficiarytype === "broker") {
      return "Add Beneficiary/Customer";
    }
    else if (this.addressType === "cryptoAddress") {
      return 'Add Crypto Address'
    }
    else {
      // this.recipientAddForm.patchValue({recipientType : "2"})
      return "Add Bank Account";
    }
  }

  fillFormCustomerData(data: any) {
    this.isInProgress = true;
    const formData = new FormData();
    const customer: ICustomer = this.customer.selectedCustomer;
    formData.append("customerId", customer.customerId);
    if (this.isUploadKyc) {
      formData.append("selfieFile", this.selfiefile.file);
      formData.append("identityProofFile", this.identityfile.file);
      formData.append("addressProofFile", this.addressfile.file);
      formData.append("identityDocumentType", data.identityDocumentType);
      formData.append("addressDocumentType", data.addressDocumentType);
    }
    if (this.currentCur.symbol === "EUR" || this.currentCur.symbol === "GBP") {
      formData.append("customerName", this.userName);
      if (data.ibanOrSortCode) {
        formData.append("iban", data.ibanOrSortCode);
      }

      if (data.sortCode) {
        formData.append("sortCode", data.sortCode);
      }

      if (data.swiftOrBic) {
        formData.append("bankCode", data.swiftOrBic);
      }

      formData.append("branchName", data.branchName);
      formData.append("city", data.city);

      if (this.currentCur.symbol === "EUR") {
        formData.append("postalCode", data.postalCode);
        formData.append("address1", data.address1);
        formData.append("countryCode", data.countryCode);
      }
    } else {
      formData.append("routingNumber", data.routingNumber);
      if (this.userType === "4") {
        formData.append("firstName", data.firstName);
        // if (data.middleName) {
        //   formData.append("middleName", data.middleName);
        // }

        // formData.append("lastName", data.lastName);
      } else {
        formData.append("firstName", data.firstName);
      }

      formData.append("addressLine1", data.address1);
      // if (data.address2) {
      //   formData.append("addressLine2", data.address2);
      // }
      // if (data.address3) {
      //   formData.append("addressLine3", data.address3);
      // }
      formData.append("city", data.city);
      // formData.append("province", data.province);
      if (this.userType === "4") {
        // if (data.dob) {
        //   formData.append("dob", data.dob.toString());
        // }
        if (data.countryOfBirth) {
          formData.append("countryOfBirth", data.countryOfBirth.value);
        }
      }
      if (data.bankCode) {
        formData.append("bankCode", data.bankCode);
      }
      formData.append("usdAccountType", data.accountType);
      // formData.append("mobile", data.mobile);
      formData.append("postalCode", data.postalCode);
      formData.append("currencyCode", "USD");
      formData.append("country", "US");
    }

    formData.append("currencyId", this.currentCur.id);
    formData.append("userType", data.userType);
    if (this.userEmail) {
      formData.append("email", this.userEmail);
    }
    formData.append("bankName", data.bankName);
    if (data.accountNumber) {
      formData.append("accountNumber", data.accountNumber);
    }
    if (data.googleDriveUrl) {
      formData.append("googleDriveUrl", data.googleDriveUrl);
    }
    if (this.currentCur.symbol === "USD") {
      this.customerService.editCustomerUSD(formData).subscribe(
        (res: any) => {
          if (res.statusCode === 200) {
            this.toastrService.success(CUSTOMER_UPDATED_SUCCESS);
            this.customerService.emitCustomerAdd(true);
            this.isInProgress = false;
            this.modalRef.hide();
            this.recipientAddForm.reset();
          }
        },
        (err) => {
          this.isInProgress = false;
          if (err.error.message) {
            this.toastrService.error(err.error.message);
          } else {
            this.toastrService.error(UNABLE_TO_PERFORM_ERROR);
          }
        }
      );
    } else {
      this.customerService.editCustomer(formData).subscribe(
        (res: any) => {
          if (res.statusCode === 200) {
            this.toastrService.success(CUSTOMER_UPDATED_SUCCESS);
            this.customerService.emitCustomerAdd(true);
            this.isInProgress = false;
            this.modalRef.hide();
            this.recipientAddForm.reset();
          }
        },
        (err) => {
          this.isInProgress = false;
          if (err.error.message) {
            this.toastrService.error(err.error.message);
          } else {
            this.toastrService.error(UNABLE_TO_PERFORM_ERROR);
          }
        }
      );
    }
  }

  getStatusCurrency(): boolean {
    if (this.customer || this.isUploadKyc) {
      return true;
    } else {
      return false;
    }
  }

  getInfoMesg(): string {
    return "Share google drive url (with public access) after uploading the following preliminary documents:Cert. of Incorporation, Memo. & Art. of Assoc., Shareholding pattern, Business Add. Proof";
  }

  onNetworkTypeChanged(select: IFormSelect): void {
    const networkTypeValue = this.recipientAddForm.controls["cryptoAddress"].value;
    if (networkTypeValue == select.value) {
      return;
    }

    if (
      select.value === "2" || select.value === "3"
    ) {
      this.recipientAddForm.controls["cryptoAddress"].setValue("");
      this.recipientAddForm.controls["cryptoAddress"].setValidators([
        Validators.required,
        ValidatorUtility.AddressETHUSDTValidator,
      ]);
    } else if (this.currencySymbol === 'CAS' || this.currencySymbol === 'BNB') {
      if ((select.value === "0" || select.value === "1")) {
        this.recipientAddForm.controls["cryptoAddress"].setValue("");
        this.recipientAddForm.controls["cryptoAddress"].setValidators([
          Validators.required,
          ValidatorUtility.AddressCASValidator,
        ]);
      }
    } else {
      this.recipientAddForm.controls["cryptoAddress"].setValue("");
      this.recipientAddForm.controls["cryptoAddress"].setValidators([
        Validators.required,
      ]);
    }
    this.recipientAddForm.updateValueAndValidity();
  }

  isContinueActiveOthersSelection(): boolean {
    if (this.beneficiarytype === 'broker') {
      return true;
    }

    if (!this.isPremiumPlan && this.recipientAddForm.get('recipientType').value === "2") {
      return false;
    } else {
      return true;
    }
  }

  setPremiumStatus(): void {
    if (this.userType === '4') {
      if (SUBSCRIPTION_PERSONAL_PLAN.BASIC === this.currentUserPlanDetail?.planName) {
        this.isPremiumPlan = false;
      }
    } else {
      if (SUBSCRIPTION_BUSINESS_PLAN.BASIC === this.currentUserPlanDetail?.planName) {
        this.isPremiumPlan = false;
      }
    }
  }

  addCryptoAddress(addressWhitelistData) {
    this.addressWhitelistService
      .addressWhitelist(addressWhitelistData)
      .subscribe(
        (res) => {
          this.addressWhitelistService.emitAddressWhitelistData(
            addressWhitelistData
          );
          this.isInProgress = false;
          this.modalRef.hide();
          this.recipientService.emitaddRecipientFlag(true);
          this.recipientAddForm.reset();
          this.response = "";
          this.toastrService.success(RECIPIENT_EMAIL_MESG);
          if (this.router.url.includes("/fiat")) {
            this.router.navigateByUrl(APP_ROUTES.RECIPIENT_BASE);
          }
        },
        (err) => {
          this.isInProgress = false;
          if (err.error.message) {
            this.toastrService.error(err.error.message);
          } else {
            this.toastrService.error(UNABLE_TO_PERFORM_ERROR);
          }
          this.code = "";
        }
      );
  }

  ngOnDestroy(): void {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }
}

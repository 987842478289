<div *ngIf="info" class="modal-header position-relative sm-form-title">
    <h6 class="modal-title w-100 mt-3 sm-custom-header font-weight-bold" id="myModalLabel">Account Migration Terms & Conditions</h6>

</div>
<div class="modal-body font-weight-bolder" [innerHTML]="info">
</div>
<div *ngIf="info" class="modal-footer text-center mt-3">
    I understand and accept all the terms to transfer my account to Crypto Innovations
  <button mdbBtn type="button" (click)="onClick(true)" color="primary" mdbWavesEffect class="btn-sm">
    I accept
  </button>
</div>
<button type="button" class="close pull-right sm-close" aria-label="Close" (click)="modalRef.hide()">
  <span aria-hidden="true">×</span>
</button>
<img *ngIf="imgSrc" src="{{imgSrc}}" alt="">
<button mdbBtn type="button" (click)="onSelect()" color="success" mdbWavesEffect class="btn-sm">
  Book your spot
</button>
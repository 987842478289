<section class="p-5 w-100 d-flex justify-content-center flex-column mx-auto" *ngIf="quoteData">
  <h3 class="mb-4 text-center font-weight-bolder">All done!</h3>
  <mdb-icon far icon="check-circle" class="text-center green-text mb-4" size="10x"></mdb-icon>

  <p class="text-center mb-4 font-weight-normal">{{ getCurrencyName(convertCompleteData?.fromCurrencySymbol) }} to {{
    getCurrencyName(convertCompleteData?.toCurrencySymbol) }}</p>

  <p class="text-center mb-4 font-weight-bolder">
    Conversion Fee Charged: {{quoteData.feeAmount | number: getDecimalNumber(quoteData.feeSymbol) }}
    {{getCurrencyName(quoteData.feeSymbol)}}
  </p>

  <p class="text-center mb-4 font-weight-bolder">
    Conversion Rate: {{ quoteData?.quotePrice | number: "0.2-10"}} {{
    getCurrencyName(convertCompleteData?.fromCurrencySymbol)
    }}/{{getCurrencyName(convertCompleteData?.toCurrencySymbol)}}
  </p>
  <div class="px-4">
    <button mdbBtn block="true" type="button"
      class="rounded-sm position-relative btn btn-secondary sm-custom-sec waves-effect waves-light btn-block"
      mdbWavesEffect (click)="onContinue()">
      Got it
    </button>
    <div class="mt-3" style="width: 315px;">
      <span class="font-weight-normal text-dark">Contact <a [routerLink]="" (click)="helpdeskConvert()"
          class="p-0 sm-help-color">help desk</a> for any question or
        query.</span>
    </div>
  </div>
</section>
import { CurrencyTypeData } from "./../../core/models/currency-type-data";
import { ICryptoAccountDetails } from "./../../core/models/crypto-account-details";
import { IAccountDetails } from "./../../core/models/account-details";
import { Router, ActivatedRoute } from "@angular/router";
import { WalletService } from "./../services/wallet.service";
import {
    Component,
    OnInit,
    ViewChildren,
    QueryList,
    ViewChild,
    Output,
    EventEmitter,
    ElementRef,
} from "@angular/core";
import {
    APP_ROUTES,
    CURRENCY_TYPE,
    USER_COUNTRY_CODE,
    USER_INTEREST_MODE,
    USER_TYPE,
} from "../../app.constants";
import { IUserBalance } from "src/app/core/models/user-balance";
import { CarouselComponent, SBItemComponent } from "ng-uikit-pro-standard";
import { CryptoAccountRequestModel } from "./../../core/models/crypto-account-request-model";
import { ToastrService } from "ngx-toastr";
import { ModalService } from "src/app/core/services/modal/modal.service";
import { UserService } from "src/app/core/services/user/user.service";
import { IDepositInterestPlan } from "src/app/core/models/deposit-interest/deposit-interest";
import { DepositInterestService } from "src/app/deposit-interest/service/deposit-interest.service";
import { IUserPlan } from "src/app/core/models/user-plan/user-detail";
import { SubSink } from "subsink";
import { ActivityService } from "src/app/activity/services/activity.service";
import { CreditBalance, UserCreditBalance } from "src/app/core/models/credit-line";
import { DomainService } from "src/app/core/services/domain/domain.service";
import { CurrencyType } from "src/app/core/enums/currencyType";

@Component({
    selector: "cashaa-currency-assets",
    templateUrl: "./currency-assets.component.html",
    styleUrls: ["./currency-assets.component.scss"],
})
export class CurrencyAssetsComponent implements OnInit {
    @ViewChild("slide", { static: true }) slideController: CarouselComponent;
    /** Balance data  data */
    creditlineData: UserCreditBalance;
    balanceData?: UserCreditBalance[];
    slides: any = [[]];
    loading = false;
    subSink = new SubSink();
    localBankFiatCurrencyList: IUserBalance[] = [];
    allCurrencyList: IUserBalance[] = [];
    accountBankFiatCurrencyList: IUserBalance[] = [];
    cryptoBankFiatCurrencyList: IUserBalance[] = [];
    hideZeroBalance = false;
    tempFiat = [];
    tempCrypto = [];
    nonBankingFiatCurrency = ["AUD", "JPY", "CAD", "CNH", "SGD", "NZD", "CHF"];
    excludeFiatCurrencies: string[] = ["SGD", "AUD", "CNH", "CAD", "CHF", "NZD", "JPY", "USD", "GBP"];

    excludeCryptoDisable: string[] = ["BNB20"];
    /** selected crypto bank */
    selectedCrypto = -1;
    localBankLoading = false;
    cryptoLoading = false;
    copied = false;
    copiedMemo = false;
    currencyToEmit: string;
    currencyType: string;
    accountDetailsByCurrencyData: IAccountDetails;
    accountDetailsByAccountCurrencyData: IAccountDetails;
    accountDetailsCryptoData: ICryptoAccountDetails;
    accountDetailsETHCryptoData: ICryptoAccountDetails;
    accountDetailsForTRCData: ICryptoAccountDetails;
    // get logged user type
    userType = sessionStorage.getItem(USER_TYPE);
    /** Error message if any */
    errorMessage?: string;

    /** Error message if any */
    errorMessageCrypto?: string;

    // hold personal user kyc details
    casDepositRates: number[] = [];
    maxInterestModeRate: number[] = [];
    @ViewChildren("collapsesCrypto")
    collapsesCrypto: QueryList<SBItemComponent>[];
    rateType: string = sessionStorage.getItem(USER_INTEREST_MODE);
    selectedLocalBank = -1;
    selectedAccountBank = -1;
    showCryptoTopBtn = true;
    rate: number;
    isBalance: boolean;
    /** selected crypto bank */
    popDetails;
    earninterestbalance: number;
    // get logged user type
    @Output() getSelectedStatusChange = new EventEmitter<boolean>();
    userCreditBalance: CreditBalance;
    creditLineList = [];
    currencyByUSD = []
    currencyDepositRateLessThanEqual365: number[] = [];
    currencyDepositRateGreaterThan365: number[] = [];
    isMobile;
    // userDetails;
    // specificCashaaUserId = environment.CASHAA_USER_ID_TRC_ENABLED;
    // isSpecificUser = false;
    isBorrowEnabled = true;
    // sectionFirst = [{name: 'Portfolio'}, {name: 'Interest Earned'}, {name: 'loyalty Level'}, {name: 'CreditLine'}]
    @ViewChild('cryptoSection') cryptoSection: ElementRef;
    isBorrowMore = true;
    isEarn = true;
    showUpgradeOption = false;
    currencyInfo;
    allCurrencyInfo;
    allCurrenciesWithNetworkType;
    constructor(
        private activityService: ActivityService,
        private userService: UserService,
        private router: Router,
        private toastrService: ToastrService,
        private walletService: WalletService,
        private activatedRoute: ActivatedRoute,
        private domainService: DomainService
    ) { }

    ngOnInit(): void {
        this.getAllCurrencies();
        this.activatedRoute.queryParamMap.subscribe((val) => {
           this.isEarn = !Boolean(val.get("isBorrow"));
          })
        // this.userDetails = JSON.parse(localStorage.getItem(SEESION_OBJECT));
        // console.log(this.userDetails);
        // if (this.userDetails.cashaaUserId === this.specificCashaaUserId) {
        //     this.isSpecificUser = true;
        // } else {
        //     this.isSpecificUser = false;
        // }
        this.isMobile = window.screen.width < 1200;
        this.subSink.add(
            this.activityService.getBalanceStatus().subscribe((data) => {
                if (data) {
                    this.getCreditLine();
                }
            }));
        this.getCurrencyInfo();
    }

    getCreditLine() {
        this.loading = true;
        this.walletService.getCreditLines().subscribe((res: UserCreditBalance) => {
            this.loading = false;
            if (res) {
                this.creditlineData = res;
                this.creditLineList = res.creditLines;
                this.userCreditBalance = res.usercreditbalance;
                if(this.userCreditBalance.totalAvailableCreditInUSD <= 0){
                   this.isBorrowEnabled= false;
                }else{
                   this.isBorrowEnabled= true;
                }
                localStorage.setItem('userCreditBalance', JSON.stringify(this.userCreditBalance));
                this.currencyByUSD = this.userCreditBalance.currencyByUSD;
                this.balanceData = this.currencyByUSD;
                for(let i = 0; i < this.currencyInfo.length; i++) {
                    for(let j = 0; j < this.balanceData.length; j++)
                    if(this.balanceData[j]['currencyId'] == this.currencyInfo[i].currencyId) {
                      this.balanceData[j]['borrowAt'] = this.currencyInfo[i].borrowAt;
                      this.balanceData[j]['loyaltyPlanName'] = this.currencyInfo[i].loyaltyPlanName;
                      this.balanceData[j]['upgradeTo'] = this.currencyInfo[i].upgradeTo;
                      this.balanceData[j]['fLexTerm'] = this.currencyInfo[i].fLexTerm;
                      this.balanceData[j]['fixedTerm'] = this.currencyInfo[i].fixedTerm;
                      this.balanceData[j]['earnUpTo'] = this.currencyInfo[i].earnUpTo;
                      this.balanceData[j]['longEarnUpTo'] = this.currencyInfo[i].longEarnUpTo;
                      this.balanceData[j]['minFlexAmount'] = this.currencyInfo[i].minFlexAmount;
                      this.balanceData[j]['isFlexSubscribed'] = this.currencyInfo[i].isFlexSubscribed;
                      this.balanceData[j]['casBonus'] = this.currencyInfo[i].casBonus;
                    }
                  }
                this.earninterestbalance = res.earninterestbalance;
                this.isBalance = this.balanceData.some(ele => ele.isBalanceAvailable === true);
                // if (window.matchMedia("(min-width: 1199px)").matches) {
                //     this.slides = this.chunk(this.sectionFirst, 2);
                //   } else if (window.matchMedia("(min-width: 991px)").matches) {
                //     this.slides = this.chunk(this.balanceData, 2);
                //    if (window.matchMedia("(max-width: 767px)").matches) {
                //     this.slides = this.chunk(this.sectionFirst, 2);
                //   } 
                //   else {
                //     this.slides = this.chunk(this.balanceData, 1);
                //   }
                this.balanceData.sort((a, b) => (a.available > b.available ? -1 : 1));
                this.balanceData.filter(
                    (el) => {
                        el['isSelected'] = true;
                        this.allCurrencyList.push(el);
                        console.log('allCurrencyList: ', this.allCurrencyList);
                    }
                );
            }
            this.loading = false;
            setTimeout(() => this.getGivenCurrency(), 100);
        });
    }

    getGivenCurrency(): void {
        this.activatedRoute.queryParamMap.subscribe((params) => {
            const currency = params.get("c");
            const crytp = params.get("crypto");
            if (crytp || currency ) {
                // window.scroll(0, 1000);
                this.cryptoSection.nativeElement.scrollIntoView({ behavior: "smooth", block: "start" });
            }
            if (currency) {
                const foundCrypto = this.allCurrencyList.filter(
                    (lc) => lc.currencyType === currency && lc.currencynature === 1
                );
                if (foundCrypto.length > 0) {
                    window.scroll(0, 1000);
                    const idx = this.allCurrencyList.indexOf(foundCrypto[0]);
                    const obj = {
                        currency: { ...foundCrypto[0], ...{ isSelected: false } },
                        id: idx
                    };
                    this.getCryptoAccountDetails(obj);
                    //   this.selectedCrypto = i;
                    // }
                    //   });
                }
            }
        });
    }

    // chunk(arr, chunkSize): any {
    //     let R = [];
    //     for (let i = 0, len = arr.length; i < len; i += chunkSize) {
    //         R.push(arr.slice(i, i + chunkSize));
    //     }
    //     return R;
    // }

    next(): void {
        this.slideController.nextSlide();
    }

    previous(): void {
        this.slideController.previousSlide();
    }
    getCryptoAccountDetails(event): void {
        this.rate = event.rate;
        this.popDetails = event.currency;
        if (this.selectedCrypto === event.id) {
            return;
        }
        this.cryptoLoading = true;
        this.collapsesCrypto.forEach((el: any, i: number) => {
            const sbItemBody = el.body.bodyEl.nativeElement;
            if (event.id !== i) {
                el.toggle(true);
                this.allCurrencyList[i].isSelected = true;
                sbItemBody.style.height = "0";
                sbItemBody.style.visibility = 'hidden';
            } else {
                if (event.currency.isSelected) {
                    sbItemBody.style.height = "0";
                    sbItemBody.style.visibility = 'hidden';
                } else {
                    this.allCurrencyList[event.id].isSelected = false;
                    sbItemBody.style.height = "auto";
                    sbItemBody.style.visibility = 'visible';
                }
            }
        });
        this.getCryptoCurrency(event.currency);
        // if (event) {
        //     $("html,body").animate(
        //         {
        //             scrollTop: event.srcElement.offsetParent.offsetTop,
        //         },
        //         600
        //     );
        // }
    }

    onHide(id, currency) {
        this.collapsesCrypto.forEach((el: any, i: number) => {
            const sbItemBody = el.body.bodyEl.nativeElement;
            if (id !== i) {
                el.toggle(true);
                this.allCurrencyList[i].isSelected = true;
                sbItemBody.style.height = "0";
                sbItemBody.style.visibility = 'hidden';
            } else {
                if (currency.isSelected) {
                    sbItemBody.style.height = "auto";
                    sbItemBody.style.visibility = 'visible';
                } else {
                    this.allCurrencyList[id].isSelected = true;
                    sbItemBody.style.height = "0";
                    sbItemBody.style.visibility = 'hidden';
                }
            }
        });
        // this.getCryptoCurrency(currency);  
    }

    // openEarnPlan(): void {
    //     if (this.popDetails.currencyType === 'CAS') {
    //         this.router.navigateByUrl(`deposit-interest?c=${this.popDetails.currencyType}`);
    //     } else {
    //         this.modalService.openEarnPlanDetailPopup(this.popDetails.plan, this.popDetails.diamondPlan, this.popDetails.available, this.popDetails.currencyId, this.popDetails.currencyType,
    //             this.casDepositRates, this.isFixedDepositEnable(this.popDetails.currencyType), this.isFixedDepositAndEarnEnable(this.popDetails.currencyType),
    //             this.maxInterestModeRate, false, this.currencyDepositRateLessThanEqual365, this.currencyDepositRateGreaterThan365, this.minFlexAmount);
    //     }
    // }
    
    getCryptoCurrency(currency: IUserBalance, networkType?: string) {
        if (networkType === 'BEP-20') {
            this.accountDetailsETHCryptoData = null;
        }
        //  else if (networkType === 'TRC-20') {
        //     this.accountDetailsForTRCData = null;
        // }
         else {
            this.accountDetailsCryptoData = null;
        }
        this.walletService.getCryptoAccountDetails(currency.symbol).subscribe(
            (res) => {
                if (networkType === 'BEP-20') {
                    this.accountDetailsETHCryptoData = res;
                }
                //  else if (networkType === 'TRC-20') {
                //     this.accountDetailsForTRCData = res;
                // } 
                 else {
                    this.accountDetailsCryptoData = res;
                }
                // if (this.isEarnAndFixedDepositActive()) {
        
                // } else {
                //     this.cryptoLoading = false;
                // }
                this.cryptoLoading = false;
            },
            (err) => {
                this.cryptoLoading = false;
            }
        );
    }

    filterNonZeroBalance() {
        if (this.allCurrencyList.length > 0 && !this.hideZeroBalance) {
            this.tempFiat = this.allCurrencyList;
            // this.tempCrypto = this.cryptoBankFiatCurrencyList
        }
        if (this.hideZeroBalance) {
            this.allCurrencyList = this.tempFiat;
            // this.cryptoBankFiatCurrencyList = this.tempCrypto;
        } else {
            this.allCurrencyList = this.allCurrencyList.filter(item => item.availableBalance > 0);
            // this.cryptoBankFiatCurrencyList = this.cryptoBankFiatCurrencyList.filter(item => item.available > 0);

        }
    }
    //get given currency

    onAccountReadyPage(currency: IUserBalance, currencyType: string, networkType?: string): void {
        if ("BEP-20" === networkType) {
            this.currencyToEmit = 'ETH';
            currency = this.allCurrencyList.filter(cur => cur.symbol === 'ETH')[0];
        } else {
            this.currencyToEmit = currency.symbol;
        }
        this.currencyType = currencyType;
        const currencyData = new CurrencyTypeData(
            this.currencyToEmit,
            this.currencyType
        );
        if (this.currencyType === "Crypto") {
            this.cryptoLoading = true;
            let payload;
            // const inputCurrencyCrypto = new CryptoAccountRequestModel(this.currencyToEmit);
           if(networkType == 'TRC-20') {
            payload = new CryptoAccountRequestModel(this.currencyToEmit, networkType.split('-').join(''));
           } else {
             payload = new CryptoAccountRequestModel(this.currencyToEmit);
           }
            this.walletService
                .OpenCryptoAccount(payload)
                .subscribe((res) => {
                    this.getCryptoCurrency(currency, networkType);
                });
        } else {
            this.walletService.emitCurrencySymbol(currencyData);
            this.router.navigateByUrl(APP_ROUTES.WALLET_READY_TO_GO);
        }
    }

    copyAddress(): void {
        if (this.accountDetailsCryptoData.address) {
            this.copied = true;
            navigator.clipboard.writeText(this.accountDetailsCryptoData.address);
            this.toastrService.toastrConfig.preventDuplicates = true;
            this.toastrService.success("Text copied to clipboard");
            setTimeout(() => (this.copied = false), 300);
        }
    }

    copyTronAddress(): void {
        if (this.accountDetailsCryptoData.tronAddress) {
            this.copied = true;
            navigator.clipboard.writeText(this.accountDetailsCryptoData.tronAddress);
            this.toastrService.toastrConfig.preventDuplicates = true;
            this.toastrService.success("Text copied to clipboard");
            setTimeout(() => (this.copied = false), 300);
        }
    }

    copyAddressETH(): void {
        if (this.accountDetailsETHCryptoData.address) {
            this.copied = true;
            navigator.clipboard.writeText(this.accountDetailsETHCryptoData.address);
            this.toastrService.toastrConfig.preventDuplicates = true;
            this.toastrService.success("Text copied to clipboard");
            setTimeout(() => (this.copied = false), 300);
        }
    }

    copyMemoAddress(): void {
        if (this.accountDetailsCryptoData.memo) {
            this.copiedMemo = true;
            navigator.clipboard.writeText(this.accountDetailsCryptoData.memo);
            this.toastrService.toastrConfig.preventDuplicates = true;
            this.toastrService.success("Text copied to clipboard");
            setTimeout(() => (this.copiedMemo = false), 300);
        }
    }
    isHidden(currency: string): boolean {
        if (this.nonBankingFiatCurrency.includes(currency)) {
            return true;
        } else {
            return false;
        }
    }
    isDisable(currency: string): boolean {
        if (this.excludeFiatCurrencies.includes(currency)) {
            return true;
        } else {
            return false;
        }
    }

    isSendDisable(currency: string): boolean {
        if (this.excludeFiatCurrencies.includes(currency)) {
            return true;
        } else {
            return false;
        }
    }

    getTootlTip(value, currency) {
        return value === 0 ? "You don't have any " + currency + " to convert" : "";
    }

    getEarnTootlTip(value, currency) {
        return value === 0 ? "You don't have any " + currency + " to Earn Interest" : "";
    }
    onAddClick(currency: string): void {
        this.router.navigateByUrl(`/deposit?cur=${currency}`);
    }
    onConvertClick(currency: string): void {
        this.router.navigateByUrl(`/convert?c=${currency}`);
    }

    onSendClick(currency: string): void {
        this.router.navigateByUrl(`/fiat?c=${currency}`);
    }

    isEarnAndFixedDepositActive(): boolean {
        return sessionStorage.getItem(USER_COUNTRY_CODE) !== 'US';
    }

    isFixedDepositEnable(symbol: string): boolean {
        return sessionStorage.getItem(USER_TYPE) !== '4' || symbol === 'CAS';
    }

    isFixedDepositAndEarnEnable(symbol: string): boolean {
        return sessionStorage.getItem(USER_TYPE) === '4' && symbol !== 'CAS';
    }

    // isEarnModuleNotActive(symbol: string): boolean {
    //     return sessionStorage.getItem(USER_TYPE) === '4' && symbol === 'DOG';
    // }

    isCryptoDisable(currency: string): boolean {
        if (this.excludeCryptoDisable.includes(currency)) {
            return true;
        } else {
            return false;
        }
    }

    isCryptoSendDisable(currency: string): boolean {
        let excludeCurr = [...this.excludeCryptoDisable]
        excludeCurr.push('USDC');
        if (excludeCurr.includes(currency)) {
            return true;
        } else {
            return false;
        }
    }

    public getTabHeaderData1(data: any): string {
        let selectedCurrency = this.allCurrenciesWithNetworkType.filter(item => item.symbol === data.symbol);
        if(selectedCurrency[0].networkType) {
            return selectedCurrency[0].networkType[0].name;
        } else {
            return ""
        }
        
    }

    public getTabHeaderData3(data: any): string {
        let selectedCurrency = this.allCurrenciesWithNetworkType.filter(item => item.symbol === data.symbol);
        if(selectedCurrency[0].networkType) {
            return selectedCurrency[0].networkType[2].name;
        } else {
            return ""
        }
    }
    getCurrencyName(curr): string {
        if (curr === "AUSD") return "USD";
        if (curr === "DOG") return "DOGE";
        else return curr;
    }

    public getContentData(symbol: string) {
        const tabData = this.getTabHeaderData1(symbol);
        symbol === "DOG" ? symbol = "DOGE" : symbol;
        if (tabData === 'BEP-2' || tabData === 'ERC-20') {
            return `${symbol} (${tabData})`;
        } else {
            return symbol;
        }
    }

    public getContentData2(data: any) {
        const tabData = this.getTabHeaderData3(data);
        data.symbol === "DOG" ? data.symbol = "DOGE" : data.symbol;
        if (tabData === 'BEP-2' || tabData === 'ERC-20' || tabData === 'TRC-20') {
            return `${data.symbol} (${tabData})`;
        } else {
            return data.symbol;
        }
    }

    // onDepositInterestClick(currency: IUserBalance): void {
    //     this.modalService.openEarnPlanDetailPopup(currency.plans, currency.diamondPlan, currency.available, currency.currencyId, currency.currencyType,
    //         this.casDepositRates, this.isFixedDepositEnable(currency.currencyType), this.isFixedDepositAndEarnEnable(currency.currencyType),
    //         this.maxInterestModeRate, false, this.currencyDepositRateLessThanEqual365, this.currencyDepositRateGreaterThan365, this.minFlexAmount);
    // }
    openCreditLine() {
        if(this.isBorrowEnabled){
            this.router.navigateByUrl(APP_ROUTES.WALLET_CREDIT_LINE);
        }
       
    }

    goToPlans(): void {
        this.router.navigateByUrl("/plans")
    }

    sortNoInterestRate() {
        this.isBorrowMore = !this.isBorrowMore;
        if(!this.isBorrowMore && this.creditlineData?.loyalityPlan === 'Base') {
         this.showUpgradeOption = true;
        } else {
        this.showUpgradeOption = false;
        }
        this.loading = true;
        this.getInterest();
    }

    getInterest() {
        this.loading = true;
        this.walletService.getInterest(this.isBorrowMore).subscribe(res => {
            this.loading = false;
            this.allCurrencyList = [];
            if (res) {
                this.creditlineData = res;
                this.creditLineList = res.creditLines;
                this.userCreditBalance = res.usercreditbalance;
                localStorage.setItem('userCreditBalance', JSON.stringify(this.userCreditBalance));
                this.currencyByUSD = this.userCreditBalance.currencyByUSD;
                this.balanceData = this.currencyByUSD;
                this.earninterestbalance = res.earninterestbalance;
                this.isBalance = this.balanceData.some(ele => ele.isBalanceAvailable === true);
                this.balanceData.sort((a, b) => (a.available > b.available ? -1 : 1));
                this.balanceData.filter(
                    (el) => {
                        el['isSelected'] = true;
                       
                        this.allCurrencyList.push(el);
                    }
                )
              
            }
            setTimeout(() => this.getGivenCurrency(), 100);
        })
    }
    
    showEarnBorrow() {
    this.isEarn = !this.isEarn;
    if(this.isEarn) {
        this.isBorrowMore = true;
    }
    this.getInterest();
    if(!this.isBorrowMore && this.creditlineData?.loyalityPlan === 'Base') {
        this.showUpgradeOption = true;
    } else {
        this.showUpgradeOption = false; 
    }
    // this.hideZeroBalance = false;
    }

    getCurrencyInfo() {
        this.userService.getFlexAndFdDetail().subscribe((res) => {
          this.currencyInfo = res;
          this.getCreditLine();
        }, err => {
          this.loading = false;
        });
      }

      getAllCurrencies() {
        this.domainService.getCurrenciesList(CurrencyType.all).subscribe((list) => {
            this.allCurrenciesWithNetworkType = list.currencies;
        });
      }

     

    ngOnDestroy(): void {
        this.subSink.unsubscribe();
    }
}

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BusinessHomeComponent } from "./business-home/business-home.component";
import { BusinessRoutingModule } from "./business-routing.module";

@NgModule({
  declarations: [BusinessHomeComponent],
  imports: [CommonModule, BusinessRoutingModule],
})
export class BusinessModule {}

import { Component, OnInit, Input } from '@angular/core';
import { LedgerType } from 'src/app/core/enums/ledgerType';
import { ILedgerList } from 'src/app/core/models/activity/recent-transaction-list';
import { ModalService } from "src/app/core/services/modal/modal.service";

@Component({
  selector: 'cashaa-activity-balance-withdrawn',
  templateUrl: './activity-balance-withdrawn.component.html',
  styleUrls: ['./activity-balance-withdrawn.component.scss']
})
export class ActivityBalanceWithdrawnComponent implements OnInit {

  @Input() ledger: ILedgerList;
  constructor(private modalService: ModalService) { }

  ngOnInit(): void {
  }

  openDetailBox(): void {
    this.modalService.openActivityTransferDetail(this.ledger);
  }

  help(): void {
    this.modalService.openTicketCreateModal(this.ledger.ledgerType);
  }

}

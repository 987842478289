import { Component, OnInit, ViewChild } from "@angular/core";
import { EventFeeService } from "../services/event-fee.service";
import { MdbStepperComponent } from "ng-uikit-pro-standard";
import { ToastrService } from "ngx-toastr";
import { TICKET_CREATED_TIME, UNABLE_TO_PERFORM_ERROR } from "src/app/app.constants";
import { ModalService } from "src/app/core/services/modal/modal.service";
import { Subscription } from "rxjs";
import { CommonSavedData } from "src/app/shared/services/commonSavedData.service";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "cashaa-event-fee-home",
  templateUrl: "./event-fee-home.component.html",
  styleUrls: ["./event-fee-home.component.scss"],
})
export class EventFeeHomeComponent implements OnInit {

  @ViewChild("stepper", { static: true }) stepper: MdbStepperComponent;

  ticketTypeList = [{ label: 'Visitors', value: 1 }, { label: 'Premium', value: 2 }, { label: 'VIP', value: 3 }];
  filteredOptions = [{ value: '', label: 'BTC', icon: './../../../assets/icons/BTC.png', symbol: 'BTC' },
  { value: '', label: 'ETH', icon: './../../../assets/icons/ETH.png', symbol: 'ETH' },
  { value: '', label: 'USDC', icon: './../../../assets/icons/USDC.png', symbol: 'USDC' },
  { value: '', label: 'USDT', icon: './../../../assets/icons/USDT.png', symbol: 'USDT' }];

  optionIcon;
  isConfirmed = false;
  isPaymentSuccess = false;
  ticketType;
  selectedCurrencyId;
  address;
  // createdAt = '2023-01-21T23:05:22.316Z';
  createdAt;
  currentTime;
  timeDiff;
  copiedAddress: boolean;
  paymentAddressData;
  confirmedTicketDetails;
  showPersonalPage = false;
  sub: Subscription;
  otherDetails;
  currencyDetails;
  constructor(
    private eventFeeService: EventFeeService, private toastrService: ToastrService,
    private modalService: ModalService, private commonSavedData: CommonSavedData,
    private activatedRoute: ActivatedRoute, private router: Router
  ) { }

  ngOnInit(): void {
    this.isTicketConfirmed();
    this.startTimer();
  }

  // onCurrencyChange(value: string): void {
  //   this.selectedImg(
  //     this.filteredOptions.find((option: any) => {
  //       return option.value === value;
  //     })
  //   );
  // }

  // selectedImg(v) {
  //   if (v.icon) {
  //     this.optionIcon = v.icon;
  //   }
  // }
  startTimer() {
    // this.timeLeft = Number(this.timeLeft);
    setInterval(() => {
      this.isTicketConfirmed();
    }, 600000)
  }
  confirmTicket() {
    this.stepper.next();

  }

  select(index) {
    this.ticketType = index;
    if (this.ticketType == 1) {
      this.showPersonalPage = true;
    } else {
      this.showPersonalPage = false;
    }
    this.stepper.next();
  }

  isTicketConfirmed() {
    this.eventFeeService.isTicketConfirmed().subscribe(res => {
      this.isConfirmed = res.isConfirmed;
      this.isPaymentSuccess = res.isPaymentSuccess;
      this.createdAt = res.created;
      this.otherDetails = res;
      //----------------------------------------------------------- 
      let dt = new Date(this.createdAt);
      dt.setMinutes(dt.getMinutes() + 330)
      let rs = dt.toLocaleTimeString('en', { hour12: false });
      console.log(rs);
      localStorage.setItem(TICKET_CREATED_TIME, rs);

      // ------------------------------------------------------------

    }, err => {

    })
  }
  selectedCurrency(event) {
    console.log(event);
    this.currencyDetails = event;
    this.selectedCurrencyId = this.currencyDetails.currencyId;
    const payload = {
      "currencyId": this.selectedCurrencyId,
      "eventSubscription": this.ticketType,
    }
    this.eventFeeService.createEventPaymentAddress(payload).subscribe(res => {
      this.paymentAddressData = res;
      this.stepper.next();
    }, err => {
      if (err.error.message) {
        this.toastrService.error(err.error.message);
      } else {
        this.toastrService.error(UNABLE_TO_PERFORM_ERROR);
      }
    })

  }

  getPersonalDetails(event) {
    console.log(event);
    let personalDetails = event.value;
    if(this.showPersonalPage) {
      this.confirmedTicketDetails = {
        currencyId: this.selectedCurrencyId,
        eventSubscription: this.ticketType,
        name: personalDetails.name,
        email: personalDetails.email,
        mobileNo: personalDetails.mobileNo,
        title: personalDetails.title,
        companyName: personalDetails.companyName,
        symbol: this.currencyDetails.name
      };
    } else {
      // amount field is required below
      this.confirmedTicketDetails = {
        currencyId: this.selectedCurrencyId,
        eventSubscription: this.ticketType,
        name: personalDetails.name,
        email: personalDetails.email,
        mobileNo: personalDetails.mobileNo,
        amount: this.paymentAddressData.feeAmount,
        title: personalDetails.title,
        companyName: personalDetails.companyName,
        symbol: this.currencyDetails.name
      };
    }
    this.stepper.next();
  }



  confirmTicketDetails() {
    console.log(this.confirmedTicketDetails);
    this.modalService.openConfirmationModal("Do you want to proceed for payment?");
    this.sub = this.commonSavedData.getConfirmationBoxStatus().subscribe((status: boolean) => {
      if (status) {
        this.eventFeeService.confirmTicketDetails(this.confirmedTicketDetails).subscribe(res => {
          // this.currentTime = new Date(this.currentTime).toLocaleTimeString('en',
          // { hour12: false, timeZone: 'UTC' });
          // if (this.createdAt) {
          //   this.createdAt = new Date(this.createdAt).toLocaleTimeString('en',
          //     {  hour12: false, timeZone: 'UTC' });
          //   console.log('currentTime: ', this.currentTime);
          //   console.log('createdAt: ', this.createdAt);
          //   this.timeDiff = this.minsToStr(this.strToMins(this.currentTime) - this.strToMins(this.createdAt));
          // }
          this.toastrService.success(res.message);
          this.isTicketConfirmed();
          window.location.reload();

        }, err => {
          this.toastrService.error(err.error.message);

        })
      }
    });
  }

  previous() {
    this.stepper.previous();
  }



}


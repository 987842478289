<!-- <a (click)="sidenav.toggle()" color="primary" class="p-3 button-collapse"
  ><mdb-icon fas icon="bars"></mdb-icon
></a> -->

<!-- Sidebar navigation -->
<!--/. Sidebar navigation -->

<div class="d-flex">
    <div class="sidebar-left-margin flex-grow-1 min-height-100 position-relative">
        <mdb-side-nav #sidenav class="fixed" [fixed]="true">
            <!-- Logo -->
            <li>
                <div class="logo-wrapper waves-light">
                    <a routerLink="/activity"><img [src]="
                isBusiness || isBroker
                  ? '../../../../assets/images/logo_business.svg'
                  : '../../../../assets/images/logo.jpg'
              " class="img-fluid flex-center" /></a>
                </div>
            </li>
            <li>
                <ul class="collapsible collapsible-accordion">
                    <mdb-accordion [multiple]="false" aria-multiselectable="false">

                        <mdb-accordion-item class="no-collase">
                            <mdb-accordion-item-head mdbWavesEffect routerLinkActive="customactive" routerLink="/activity">
                                <mdb-icon fas icon="th-large"></mdb-icon> Activity
                            </mdb-accordion-item-head>
                        </mdb-accordion-item>

                        <mdb-accordion-item class="no-collase">
                            <mdb-accordion-item-head mdbWavesEffect routerLink="/wallet" routerLinkActive="customactive">
                                <mdb-icon fas icon="paper-plane"></mdb-icon> Account
                            </mdb-accordion-item-head>
                        </mdb-accordion-item>

                        <mdb-accordion-item class="no-collase">
                            <mdb-accordion-item-head mdbWavesEffect routerLink="/convert" routerLinkActive="customactive">
                                <mdb-icon fas icon="sync"></mdb-icon> Exchange
                            </mdb-accordion-item-head>
                        </mdb-accordion-item>

                        <!-- <mdb-accordion-item class="no-collase">
                          <mdb-accordion-item-head mdbWavesEffect routerLink="/convert" [queryParams]="{c: 'INR'}"  routerLinkActive="customactive">
                              <mdb-icon fas icon="rupee-sign"></mdb-icon> INR Exchange <img src="../../../../assets/images/borrow-beta.svg" alt="">
                          </mdb-accordion-item-head>
                      </mdb-accordion-item> -->

                        <mdb-accordion-item class="no-collase">
                            <mdb-accordion-item-head routerLinkActive="customactive" routerLink="/recipient" mdbWavesEffect>
                                <mdb-icon fas icon="user"></mdb-icon> Add Beneficiary
                            </mdb-accordion-item-head>
                            <mdb-accordion-item-body></mdb-accordion-item-body>
                        </mdb-accordion-item>

                        <!-- <mdb-accordion-item class="no-collase">
              <mdb-accordion-item-head mdbWavesEffect>
                <mdb-icon fas icon="user-plus"></mdb-icon>
                *send and recive money from third party
              </mdb-accordion-item-head>
            </mdb-accordion-item> -->

                        <mdb-accordion-item class="no-collase" *ngIf="isPremiumPlan && (isBroker || isPersonal)">
                            <mdb-accordion-item-head mdbWavesEffect routerLinkActive="customactive" routerLink="/add-customer">
                                <mdb-icon fas icon="user-plus"></mdb-icon>Add Customer
                            </mdb-accordion-item-head>
                        </mdb-accordion-item>
                        
            <mdb-accordion-item class="no-collase" *ngIf="isBusiness || isBroker || isPersonal">
              <mdb-accordion-item-head mdbWavesEffect routerLinkActive="customactive" routerLink="/referral">
                <mdb-icon fas icon="user-plus"></mdb-icon>Referral
              </mdb-accordion-item-head>
            </mdb-accordion-item>

            <mdb-accordion-item class="no-collase">
              <mdb-accordion-item-head mdbWavesEffect  routerLinkActive="customactive" [routerLink]="['/account-wallet']" [queryParams]="{ isBorrow: 'true' }">
                <mdb-icon fas icon="hand-holding-usd"></mdb-icon>Borrow <img src="../../../../assets/images/borrow-beta.svg" alt="">
              </mdb-accordion-item-head>
            </mdb-accordion-item>

                        <!-- <mdb-accordion-item class="no-collase" *ngIf="isPersonal">
              <mdb-accordion-item-head [ngClass]="{'disableLink':isKycDone}" (click)="kycDoneCheck(true)" mdbWavesEffect
                routerLink="/kyc/personal" routerLinkActive="customactive">
                <div style="display: -webkit-inline-box;">
                  <mdb-icon style="margin-right: 18px!important" fas icon="exchange-alt"></mdb-icon>KYC

                  <div *ngIf="isKycDone" class="kycTick">
                    <mdb-icon fas icon="check" style="color: white;"></mdb-icon>
                  </div>
                </div>
              </mdb-accordion-item-head>
            </mdb-accordion-item> -->
                        <div *ngIf="isBasicPlan && (isBusiness || isPersonal)" class="border-top-get-prem mt-3">
                            <h5 class="thrd-prty mt-4">Want to accept third-party payments?</h5>
                        </div>

                        <mdb-accordion-item class="no-collase" *ngIf="isBasicPlan && (isBusiness || isPersonal)">
                            <mdb-accordion-item-head mdbWavesEffect routerLinkActive="customactive" routerLink="/subscription/plan">
                                <mdb-icon fas icon="user-plus"></mdb-icon>Get Premium
                                <!-- {{isBusiness ? 'Get Broker': 'Get Premuim'}} -->
                            </mdb-accordion-item-head>
                        </mdb-accordion-item>




                        <!-- <mdb-accordion-item class="no-collase">
              <mdb-accordion-item-head mdbWavesEffect routerLink="/deposit" routerLinkActive="customactive">
                <mdb-icon fas icon="download"></mdb-icon> Deposit
              </mdb-accordion-item-head>
            </mdb-accordion-item> -->



                        <!-- <mdb-accordion-item class="no-collase" *ngIf="isBusiness">
              <mdb-accordion-item-head mdbWavesEffect routerLink="/kyc/business" routerLinkActive="customactive">
                <mdb-icon fas icon="exchange-alt"></mdb-icon> KYB
              </mdb-accordion-item-head>
            </mdb-accordion-item> -->
                        <!-- <mdb-accordion-item class="no-collase">
              <mdb-accordion-item-head routerLinkActive="customactive" routerLink="/invite/link" mdbWavesEffect>
                <mdb-icon far icon="share-square"></mdb-icon>
                Refer & Earn
              </mdb-accordion-item-head>
              <mdb-accordion-item-body></mdb-accordion-item-body>
            </mdb-accordion-item> -->
                        <!-- <mdb-accordion-item class="no-collase">
              <mdb-accordion-item-head routerLinkActive="customactive" routerLink="/deposit-interest" mdbWavesEffect>
                <div class="earn_interest_icon"></div><span class="earn_interest_text">Earn Interest</span>
              </mdb-accordion-item-head>
              <mdb-accordion-item-body></mdb-accordion-item-body>
            </mdb-accordion-item> -->
                        <!-- <mdb-accordion-item class="no-collase">
              <mdb-accordion-item-head class="my" *ngIf="isPersonal && activeStaking" routerLinkActive="customactive"
                routerLink="{{stakingURL}}" mdbWavesEffect>
                <div class="staking_icon"></div> <span class="staking_text">Stake & Earn 30% APR</span>
              </mdb-accordion-item-head>
              <mdb-accordion-item-body></mdb-accordion-item-body>
            </mdb-accordion-item> -->
                    </mdb-accordion>
                </ul>
            </li>
            <!--/. Side navigation links -->
            <div class="sidenav-bg mask-strong"></div>
        </mdb-side-nav>
        <mdb-navbar SideClass="navbar fixed-top navbar-toggleable-md navbar-expand-lg scrolling-navbar double-nav sm-custom-navbar" [containerInside]="false">

            <navlinks class="navbar-container">
                <!-- SideNav slide-out button -->
                <div class="float-left">
                    <a (click)="sidenav.show()" class="button-collapse">
                        <mdb-icon fas icon="bars"></mdb-icon>
                    </a>
                </div>
                <!--/. SideNav slide-out button -->
            </navlinks>
        </mdb-navbar>
        <ng-content></ng-content>
        <!-- SideNav slide-out button -->
        <!-- <div class="float-left sm-custom-nav">
      <a (click)="sidenav.show()" class="button-collapse">
        <mdb-icon fas icon="bars"></mdb-icon>
      </a>
    </div> -->
        <!--/. SideNav slide-out button -->
        <div mdbDropdown class="mr-2 mt-1 account position-absolute d-flex justify-content-center align-items-center sm-hover">
            <div mdbDropdownToggle style="    display: contents;">
                <div class="media-left">
                    <div class="circle circle-sm text-primary">
                        <div class="tw-badge tw-badge-border-light tw-badge-sm">
                            <div class="tw-badge__children">
                                <div class="tw-avatar tw-avatar--md tw-avatar--initials tw-avatar--light">
                                    <div class="tw-avatar__content sm-color-grey">
                                        <small>{{(username[0]+username[1]).toUpperCase()}}</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <span class="ml-3 mr-3 sm-phone-username-hover font-weight-normal">{{ username }}</span>
                <div dynamicPosition="true" placement="left">
                    <mdb-icon fas icon="angle-down"></mdb-icon>
                </div>
            </div>
            <ul class="dropdown-menu dropdown-menu-overlay dropdown-menu-md dropdown-menu-profile dropdown-menu-xs-left">
                <!-- <li class="profile-dropdown__primary-item"><a class="menu-item" routerLink="/profile/business">
            <span class="tw-icon tw-icon-profile " aria-hidden="true" role="presentation">
              <mdb-icon fas icon="user" class="pull-left"></mdb-icon>
            </span>
            <h5 style="padding-left: 1px;"><span>Your details</span></h5>
          </a>
        </li> -->
                <li class="profile-dropdown__primary-item">
                    <a class="menu-item" routerLink="/plans">
                        <!-- <div class="upgrade_icon"></div> -->
                        <span class="tw-icon tw-icon-settings" aria-hidden="true" role="presentation">
              <!-- <mdb-icon fas icon="level-up-alt" class="pull-left"></mdb-icon> -->
              <img src="../../../../assets/images/profile-menu/loyalty.svg" alt="loyalty">
            </span>
                        <h5><span>Loyalty Levels</span></h5>
                    </a>
                </li>
                <li class="profile-dropdown__primary-item" *ngIf="isBusiness || isBroker">
                    <a class="menu-item" [routerLink]="['/kyc/business']" [queryParams]="{plan:'Broker'}">
                        <span class="tw-icon tw-icon-settings" aria-hidden="true" role="presentation">
              <!-- <mdb-icon fas icon="users" class="pull-left"></mdb-icon> -->
              <img src="../../../../assets/images/profile-menu/kyc.svg" alt="kyc">
            </span>
                        <h5><span>KYC</span></h5>
                    </a>
                </li>
                <!-- <li class="profile-dropdown__primary-item" *ngIf="isPersonal && !isKycDone"> -->
                  <!-- <a class="menu-item" [routerLink]="['/kyc/personal']"> -->
                      <!-- <span class="tw-icon tw-icon-settings" aria-hidden="true" role="presentation"> -->
            <!-- <mdb-icon fas icon="users" class="pull-left"></mdb-icon> -->
            <!-- <img src="../../../../assets/images/profile-menu/kyc.svg" alt="kyc"> -->
          <!-- </span> -->
                      <!-- <h5><span>KYC</span></h5> -->
                  <!-- </a> -->
              <!-- </li> -->
                <li class="profile-dropdown__primary-item" *ngIf="isCASSWAPEnabled">
                    <a class="menu-item" routerLink="/cas-swap">
                        <span class="tw-icon tw-icon-settings " aria-hidden="true" role="presentation">
              <!-- <mdb-icon fas icon="exchange-alt" class="pull-left"></mdb-icon> -->
              <img src="../../../../assets/images/profile-menu/cas-swap.svg" alt="cas-swap">
            </span>
                        <h5><span>CAS Swap</span></h5>
                    </a>
                </li>
                <li *ngIf="!isPersonal" class="profile-dropdown__primary-item">
                    <a class="menu-item" routerLink="/api-manager">
                        <span class="tw-icon tw-icon-settings" aria-hidden="true">
            <img src="../../../../assets/images/profile-menu/api-manager.svg" width="17px" alt="api-manager">
            </span>
                        <h5><span>API Manager</span></h5>
                    </a>
                </li>

                <li class="profile-dropdown__primary-item">
                    <a class="menu-item" routerLink="/settings">
                        <span class="tw-icon tw-icon-settings " aria-hidden="true" role="presentation">
              <!-- <mdb-icon fas icon="cog" class="pull-left"></mdb-icon> -->
              <img src="../../../../assets/images/profile-menu/settings.svg" alt="settings">
            </span>
                        <h5><span>Settings</span></h5>
                    </a>
                </li>
                <li class="profile-dropdown__primary-item">
                    <a class="menu-item" (click)="createHelpdeskTicket()">
                        <span class="tw-icon tw-icon-log-out" aria-hidden="true" role="presentation">
              <!-- <mdb-icon fas icon="info" class="pull-left"></mdb-icon> -->
              <img src="../../../../assets/images/profile-menu/help-desk.svg" alt="help desk">
            </span>
                        <h5><span>Help Desk</span></h5>
                    </a>
                </li>
                <li class="profile-dropdown__primary-item">
                    <a class="menu-item" href="https://help.cashaa.com/" target="_blank">
                        <span class="tw-icon tw-icon-log-out" aria-hidden="true" role="presentation">
              <!-- <mdb-icon fab icon="hire-a-helper" class="pull-left"></mdb-icon> -->
              <img src="../../../../assets/images/profile-menu/help-center.svg" alt="help center">
            </span>
                        <h5><span>Help Center</span></h5>
                    </a>
                </li>
                <!-- <li class="profile-dropdown__primary-item"><a class="menu-item" href="https://help.cashaa.com/faq/" target="_blank">
            <span class="tw-icon tw-icon-log-out" aria-hidden="true" role="presentation">
              <img src="../../../../assets/images/profile-menu/faq.svg" alt="faq">
            </span>
            <h5><span>FAQ</span></h5>
          </a>
        </li> -->
                <!-- <li class="divider m-t-2 m-b-1"></li> -->
                <li class="profile-dropdown__primary-item">
                    <a class="menu-item" (click)="logout()">
                        <!-- <button class="media decision upsell-menu-item" style="padding-left: 18px;" type="button"> -->
                        <!-- <div class="media-left"> -->
                        <span class="tw-icon tw-icon-log-out" aria-hidden="true" role="presentation">
                <!-- <mdb-icon fas icon="sign-out-alt" class="pull-left"></mdb-icon> -->
                <img src="../../../../assets/images/profile-menu/logout.svg" alt="logout">
              </span>
                        <!-- </div> -->
                        <!-- <div class="media-body pl-7"> -->
                        <h5><span>Log Out</span></h5>
                    </a>
                    <!-- </div> -->
                    <!-- </button> -->
                </li>
            </ul>
        </div>



        <!-- <div class="account position-absolute d-flex justify-content-center align-items-center sm-hover">
      <div class="media-left">
        <div class="circle circle-sm text-primary">
          <div class="tw-badge tw-badge-border-light tw-badge-sm">
            <div class="tw-badge__children">
              <div class="tw-avatar tw-avatar--md tw-avatar--initials tw-avatar--light">
                <div class="tw-avatar__content sm-color-grey">
                  <small>{{(username[0]+username[1]).toUpperCase()}}</small></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <span class="ml-3 mr-3">{{ username }}</span>
      <div mdbDropdown dynamicPosition="true" placement="left">
        <mdb-icon fas icon="angle-down" mdbDropdownToggle></mdb-icon>
        <ul
          class="sm-margin dropdown-menu dropdown-menu-overlay dropdown-menu-md dropdown-menu-profile dropdown-menu-xs-left">
          <li class="profile-dropdown__primary-item"><a class="menu-item" routerLink="/profile/business">
              <span class="tw-icon tw-icon-profile " aria-hidden="true" role="presentation">
                <mdb-icon fas icon="user" class="pull-left"></mdb-icon>
              </span>
              <h5 style="padding-left: 1px;"><span>Your details</span></h5>
            </a>
          </li>
          <li class="profile-dropdown__primary-item"><a class="menu-item" routerLink="/settings">
              <span class="tw-icon tw-icon-settings " aria-hidden="true" role="presentation">
                <mdb-icon fas icon="cog" class="pull-left"></mdb-icon>
              </span>
              <h5><span>Settings</span></h5>
            </a>
          </li>
          <li class="profile-dropdown__primary-item"><a class="menu-item" routerLink="/helpdesk/create-ticket">
              <span class="tw-icon tw-icon-log-out " style="padding-left: 4px;" aria-hidden="true" role="presentation">
                <mdb-icon fas icon="info" class="pull-left"></mdb-icon>
              </span>
              <h5 class="pl-7"><span>Helpdesk</span></h5>
            </a></li>
          <li class="divider m-t-2 m-b-1"></li>
          <li><button class="media decision upsell-menu-item" style="padding-left: 18px;" type="button">
              <div class="media-left">
                <span class="tw-icon tw-icon-switch " aria-hidden="true" role="presentation">
                  <mdb-icon fas icon="sign-out-alt" class="pull-left"></mdb-icon>
                </span>
              </div>
              <div class="media-body pl-7">
                <h5 (click)="logout()" class="upsell-menu-item__title"><span>Log Out</span></h5>
              </div>
            </button>
          </li>
        </ul>
      </div>
    </div> -->


    </div>
</div>

import { WithdrawService } from "./../../withdraw/services/withdraw.service";
import { Router } from "@angular/router";
import { ActivatedRoute } from "@angular/router";
import { Component, OnInit } from "@angular/core";
import { WithdrawConfirmation } from "src/app/core/models/withdraw-fund";
import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { UNABLE_TO_PERFORM_ERROR } from "src/app/app.constants";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "cashaa-withdraw-confirmation",
  templateUrl: "./withdraw-confirmation.component.html",
  styleUrls: ["./withdraw-confirmation.component.scss"],
})
export class WithdrawConfirmationComponent implements OnInit {
  /** token from URL */
  ConfirmationToken: string;

  /** Whether to show loading indicator or not */
  showLoadingIndicator = false;

  /** Erorr message if any */
  errorMessage: string;

  isVerified;

  flag: boolean;
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private withdrawService: WithdrawService,
    private toastrService: ToastrService
  ) {}

  ngOnInit(): void {
    this.getTokenFromURL();
  }

  /**
   * Get Email and token from URL
   */
  getTokenFromURL(): void {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.ConfirmationToken = params["token"];
      this.onWithdrawConfirmation();
    });
  }

  /**
   * On link check
   */
  onWithdrawConfirmation(): void {
    this.showLoadingIndicator = true;
    this.errorMessage = undefined;

    const withdraw = new WithdrawConfirmation(this.ConfirmationToken);

    this.withdrawService.withdrawConfirmation(withdraw).subscribe(
      () => {
        this.showLoadingIndicator = false;
        this.isVerified = "Payment Successful";
        this.flag = true;
      },
      (err) => {
        this.showLoadingIndicator = false;
        this.errorMessage = "Invalid token";
        this.isVerified = "Invalid or Expired Request";
        this.flag = true;
        if (err.error.message) {
          this.toastrService.error(err.error.message);
          this.errorMessage = err.error.message;
        } else {
          this.toastrService.error(UNABLE_TO_PERFORM_ERROR);
        }
      }
    );
  }
}

export interface IEmailVerificationRequest {
    bKycid: string;
    email: string;
    buisnessType: string;
}
export class EmailVerificationRequest implements IEmailVerificationRequest {
    constructor(
        public bKycid: string,
        public email: string,
        public buisnessType: string,
    ) { }

}
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from '../core/services/auth-guard/auth-guard.service';
import { AddWebhookURLComponent } from './add-webhook-url/add-webhook-url.component';
import { ApiManagerHomeComponent } from './api-manager-home/api-manager-home.component';
import { ApiManagerOperationsComponent } from './api-manager-operations/api-manager-operations.component';
import { UpdateDomainComponent } from './update-domain/update-domain.component';


const routes: Routes = [{
  path: "api-manager/create",
  component: ApiManagerHomeComponent,
  canActivate: [AuthGuardService],
}, {
  path: "api-manager",
  component: ApiManagerOperationsComponent,
  canActivate: [AuthGuardService],
}, {
  path: "api-manager/edit",
  component: ApiManagerHomeComponent,
  canActivate: [AuthGuardService],
}, {
  path: "api-manager/add-webhook",
  component: AddWebhookURLComponent,
  canActivate: [AuthGuardService],
},
{
  path: "api-manager/verify-domain",
  component: ApiManagerHomeComponent,
  canActivate: [AuthGuardService],
},
{
  path: "api-manager/update-domain",
  component: UpdateDomainComponent,
  canActivate: [AuthGuardService],
},
{
  path: "api-manager/domain-status",
  component: ApiManagerHomeComponent,
  canActivate: [AuthGuardService],
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ApiManagerRoutingModule { }

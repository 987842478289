import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { API_MANAGER_SEND_CODE, APP_ROUTES, USER_TWO_FACTOR_CODE } from 'src/app/app.constants';
import { Create, EmailCode, IAPIData, IApiListRespose, ICreate, IEmailCode, ISecurity, Security } from 'src/app/core/models/api-manager/api-manager';
import { ApiManagerService } from '../services/api-manager.service';

@Component({
  selector: 'cashaa-api-manager-security-verification',
  templateUrl: './api-manager-security-verification.component.html',
  styleUrls: ['./api-manager-security-verification.component.scss']
})
export class ApiManagerSecurityVerificationComponent implements OnInit {
  code: string[] = ["", "", "", "", "", ""];
  twoFAcode: string[] = ["", "", "", "", "", ""];

  /** is checking operation in progress */
  isInProgress = false;

  @Input()
  apiData: IAPIData;

  @ViewChildren("inputs") inputs: QueryList<ElementRef>;

  @ViewChildren("twoFAInputs") twoFAInputs: QueryList<ElementRef>;

  allCodeEntered: boolean = false;
  allCodeEnteredTwoFA: boolean = false;
  @Output()
  continue = new EventEmitter<IApiListRespose>();

  twoFactor: boolean = false;
  codeLoading: boolean = false;

  timerFlag = {};
  timerStart = {};
  timerTime = {};
  disableBtn = {};
  verificationClickCount = {};
  loading = false;
  isUpdated = false;

  constructor(private apiManagerService: ApiManagerService, private toastorService: ToastrService, 
    private activateRoute: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
    const idToken = sessionStorage.getItem(USER_TWO_FACTOR_CODE);
    if (idToken === "true") {
      this.twoFactor = true;
    }

    this.activateRoute.queryParams.subscribe((params) => {
      let id = params["id"];
      if (id) {
        this.isUpdated = true;
      }
    });
  }

  checkIfCodeIsFilledTwoFA(idx: number): void {
    if (idx >= 0 && idx < 5 && this.twoFAcode[idx]) {
      this.twoFAInputs.toArray()[idx + 1].nativeElement.focus();
    }

    this.allCodeEnteredTwoFA = true;
    this.twoFAcode.forEach((val) => {
      if (val === null || val.length === 0) {
        this.allCodeEnteredTwoFA = false;
      }
    });
    if (this.twoFAcode[5] && idx === -1) {
      this.twoFAInputs.toArray()[5].nativeElement.focus();
    }
  }



  onCodePasteTwoFA(event: ClipboardEvent): void {
    const clipboardData = event.clipboardData || (window as any).clipboardData;
    const pastedText = clipboardData.getData("text");

    if (pastedText && pastedText.length === 6) {
      this.twoFAcode = pastedText.split("");
      this.checkIfCodeIsFilledTwoFA(-1);
    }
  }

  removeBackTwoFA(event: any, index: number) {
    const keyCode = event.keyCode;
    if (keyCode === 8) {
      this.twoFAInputs.toArray()[index].nativeElement.focus();
    }
  }

  /**
   * Check if google input authenticator
   * code is filled in all 6 boxes
   */
  checkIfCodeIsFilled(idx: number): void {
    if (idx >= 0 && idx < 5 && this.code[idx]) {
      this.inputs.toArray()[idx + 1].nativeElement.focus();
    }

    this.allCodeEntered = true;
    this.code.forEach((val) => {
      if (val === null || val.length === 0) {
        this.allCodeEntered = false;
      }
    });
    if (this.code[5] && idx === -1) {
      this.inputs.toArray()[5].nativeElement.focus();
    }
  }

  /**
   * On code paste automatically
   * fill all 6 boxes
   */
  onCodePaste(event: ClipboardEvent): void {
    const clipboardData = event.clipboardData || (window as any).clipboardData;
    const pastedText = clipboardData.getData("text");

    if (pastedText && pastedText.length === 6) {
      this.code = pastedText.split("");
      this.checkIfCodeIsFilled(-1);
    }
  }

  removeBack(event: any, index: number) {
    const keyCode = event.keyCode;
    if (keyCode === 8) {
      this.inputs.toArray()[index].nativeElement.focus();
    }
  }

  sendCode(): void {
    let data: IEmailCode = new EmailCode(this.apiData.apiName);
    this.codeLoading = true;
    this.apiManagerService.getEmailOTP(data).subscribe((data: { statusCode: number, message: string }) => {
      if (data.statusCode === 200) {
        this.timerTime[0] = 60;
        this.timerFlag[0] = true;
        this.timerStart[0] = true;
        if (isNaN(this.verificationClickCount[0])) {
          this.verificationClickCount[0] = 1;
        } else {
          this.verificationClickCount[0]++;
        }
        this.toastorService.success(API_MANAGER_SEND_CODE)
      }
      this.codeLoading = false;
    }, (err: HttpErrorResponse) => {
      this.codeLoading = false;
      this.toastorService.error(err.error.message);
    })
  }

  timerExpire(i): void {
    if (this.verificationClickCount[i] < 3) {
      this.timerFlag[i] = false;
      this.timerStart[i] = false;
    } else {
      this.disableBtn[i] = true;
      this.timerStart[i] = false;
    }
  }

  onFormSubmit(): void {
    this.loading = true;
    let apiData: ICreate = new Create(this.apiData.apiFeatureTypeList, this.apiData.apiName, this.apiData.iplist, this.code.join(""),
      this.twoFAcode.join(""), "https://www.cashaa.com/", "https://www.cashaa.com/", this.apiData.id);
    if (this.isUpdated) {
      this.apiManagerService.updateApi(apiData).subscribe(() => {
        this.loading = false;
        this.toastorService.success('API successfully updated');
        this.router.navigateByUrl('api-manager');
      }, (err: HttpErrorResponse) => {
        this.loading = false;
        this.toastorService.error(err.error.message);
      });
    } else {
      this.apiManagerService.createApi(apiData).subscribe((response: IApiListRespose) => {
        this.loading = false;
        this.toastorService.success('API register successfully');
        this.continue.next(response);
      }, (err: HttpErrorResponse) => {
        this.loading = false;
        this.toastorService.error(err.error.message);
      });
    }

  }

}

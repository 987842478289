import { PaymentSuccessComponent } from './../deposit/payment-success/payment-success.component';
import { PaymentFailureComponent } from './../deposit/payment-failure/payment-failure.component';
import { WithdrawConfirmationComponent } from './../send/withdraw-confirmation/withdraw-confirmation.component';
import { NgModule, Component } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AccountSigninComponent } from "./account-signin/account-signin.component";
import { AccountSignupComponent } from "./account-signup/account-signup.component";
import { AccountWrapperComponent } from "./account-wrapper/account-wrapper.component";
import { AccountForgetPasswordComponent } from "./account-forget-password/account-forget-password.component";
import { AccountTwoFactorAuthenticationComponent } from "./account-two-factor-authentication/account-two-factor-authentication.component";
import { AccountWhitelistDeviceComponent } from "./account-whitelist-device/account-whitelist-device.component";
import { AccountResetPasswordComponent } from "./account-reset-password/account-reset-password.component";
import { AuthGuardService } from "./../core/services/auth-guard/auth-guard.service";
import { AccountMesgBoxComponent } from './account-mesg-box/account-mesg-box.component';
const routes: Routes = [
  {
    path: "account",
    component: AccountWrapperComponent,
    children: [
      {
        path: "signin",
        component: AccountSigninComponent,
      },
      {
        path: "signup",
        component: AccountSignupComponent,
      },
      {
        path: "forget-password",
        component: AccountForgetPasswordComponent,
      },
      {
        path: "2fa",
        component: AccountTwoFactorAuthenticationComponent,
        // canActivate: [AuthGuardService],
      },
      {
        path: "whitelist/:id",
        component: AccountWhitelistDeviceComponent,
      },
      {
        path: "reset-password",
        component: AccountResetPasswordComponent,
        
      },
      {
        path: "withdraw-confirmation",
        component: WithdrawConfirmationComponent,
        
      }
      ,

      {
        path: "payment-fail",
        component: PaymentFailureComponent,
      },
      {
        path: "payment-success",
        component: PaymentSuccessComponent,
        canActivate: [AuthGuardService],
      },

      {
        path: "payment-success",
        component: PaymentSuccessComponent,

      },

      {
        path: "mesg-box",
        component: AccountMesgBoxComponent,

      }
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AccountRoutingModule { }

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { IApiListRespose, ICreate, IEmailCode } from 'src/app/core/models/api-manager/api-manager';
import { IFeature } from 'src/app/core/models/api-manager/feature';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiManagerService {

  private apiUrl = environment.api;

  private updateData = new BehaviorSubject<IApiListRespose>(undefined);

  constructor(private http: HttpClient) { }

  getFeatureList(): Observable<IFeature[]> {
    return this.http.get<IFeature[]>(`${this.apiUrl}/b2bservice/getfeaturelist`)
  }

  createApi(data: ICreate): Observable<IApiListRespose> {
    return this.http.post<IApiListRespose>(`${this.apiUrl}/b2bservice/create`, data);
  }

  getEmailOTP(apiName: IEmailCode): Observable<{ statusCode: number, message: string }> {
    return this.http.post<{ statusCode: number, message: string }>(`${this.apiUrl}/b2bservice/apiotp`, apiName);
  }

  updateApi(data: ICreate): Observable<{ statusCode: number, message: string }> {
    return this.http.post<{ statusCode: number, message: string }>(`${this.apiUrl}/b2bservice/updateapiservice`, data);
  }

  getAllApiList(): Observable<IApiListRespose[]> {
    return this.http.get<IApiListRespose[]>(`${this.apiUrl}/b2bservice/getapilist`);
  }

  deleteAPI(id: string, twoFACode: string) {
    return this.http.post<{ statusCode: number, message: string }>(`${this.apiUrl}/b2bservice/deleteapiservice?serviceId=${id}&twoFaAuthCode=${twoFACode}`, null);
  }

  emitAPIData(data: IApiListRespose) {
    this.updateData.next(data);
  }

  getAPIData(): Observable<IApiListRespose> {
    return this.updateData.asObservable();
  }

  getAPIDataById(id: string): Observable<IApiListRespose> {
    return this.http.get<IApiListRespose>(`${this.apiUrl}/b2bservice/getapidetails?apiId=${id}`);
  }

  getWebhookURL(apiManagerId: string): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/b2bservice/getwebhookurl?apiManagerId=${apiManagerId}`);
  }

  addWebhoolURL(payload): Observable<{ statusCode: number, message: string }> {
    return this.http.post<{ statusCode: number, message: string }>(`${this.apiUrl}/b2bservice/addwebhookurl`, payload);
  }

  generateDomainCode(payload): Observable<{ statusCode: number, message: string }> {
    return this.http.post<{ statusCode: number, message: string }>(`${this.apiUrl}/b2bservice/generatedomaincode`, payload);
  }

  updateDomain(payload): Observable<{ statusCode: number, message: string }> {
    return this.http.post<{ statusCode: number, message: string }>(`${this.apiUrl}/b2bservice/updatewebhookdomain`, payload);
  }
}

import { Component, OnInit } from "@angular/core";
import { AccountUpgradeService } from "src/app/account-upgrade/services/account-upgrade.service";
import { SUBSCRIPTION_BUSINESS_PLAN, SUBSCRIPTION_PERSONAL_PLAN, USER_EMAIL_PATH, USER_NAME_ID, USER_TYPE } from "src/app/app.constants";
import { UserType } from "src/app/core/enums/userType";
import { UserSubscriptionPlan } from "src/app/core/models/account-upgrade/subscription-plan-response";
import { ModalService } from "src/app/core/services/modal/modal.service";

@Component({
  selector: "cashaa-recipient-home",
  templateUrl: "./recipient-home.component.html",
  styleUrls: ["./recipient-home.component.scss"],
})
export class RecipientHomeComponent implements OnInit {
  isPremiumPlan = true;
  currentUserPlanDetail: UserSubscriptionPlan;
  constructor(private modalService: ModalService, private upgradeService: AccountUpgradeService) {}

  ngOnInit(): void {
    this.getSubscriptionPlanData();
  }

  getSubscriptionPlanData() {
    this.upgradeService.getUserActionSubscriptionPlan().subscribe((data: UserSubscriptionPlan) => {
      if (data) {
        this.currentUserPlanDetail = data;
        this.getPremiumStatus();
      }
    });
  }
  

  getPremiumStatus() {
    if (+sessionStorage.getItem(USER_TYPE) === UserType.Personal) {
      if (SUBSCRIPTION_PERSONAL_PLAN.BASIC=== this.currentUserPlanDetail?.planName) {
        this.isPremiumPlan = false;
      }
    } else {
      if (SUBSCRIPTION_BUSINESS_PLAN.BASIC === this.currentUserPlanDetail?.planName) {
        this.isPremiumPlan = false;
      }
    }
  }


  /**
   * Open add recipient modal
   */
   addCryptoAddress(): void {
    const name = sessionStorage.getItem(USER_NAME_ID);
    const email = sessionStorage.getItem(USER_EMAIL_PATH);
    this.modalService.openRecipientAddModal(name, email, null,"Other Bank Account", 'cryptoAddress');
  }

  addFiatBankRecipient(): void {
      const name = sessionStorage.getItem(USER_NAME_ID);
      const email = sessionStorage.getItem(USER_EMAIL_PATH);
      this.modalService.openRecipientAddModal(name, email, null,"Self Bank Account", 'fiatAddress');
  }
}

<div class="wrapper-box  p-md-5 p-3 rounded-lg bg-white animated fadeInLeft position-relative sm-width-phone-370">
  <h4 class="text-center text-primary font-weight-bold">
    Welcome to one account with old and new money
  </h4>
  <p class="text-center font-weight-normal">
    Already signed up? <a routerLink="/account/signin">Log in</a>
  </p>
  <form *ngIf="form" [formGroup]="form" class="my-4">
    <div class="row mb-4">
      <div class="form-check col-6">
        <input type="radio" formControlName="accountType" class="form-check-input" id="businessaccount"
          name="accountType" mdbInput value="p" />
        <label style="font-weight: 500;" class="form-check-label" for="businessaccount">Personal</label>
      </div>
      <div class="form-check col-6">
        <input type="radio" formControlName="accountType" class="form-check-input" id="personalaccount"
          name="accountType" mdbInput value="b" />
        <label style="font-weight: 500;" class="form-check-label" for="personalaccount">Business</label>
      </div>
    </div>

    <div class="mb-4">
      <cashaa-form-input name="email" type="email" [form]="form" [control]="form.controls.email"
        placeholder="Enter email address"></cashaa-form-input>
    </div>
    <div class="mb-4">
      <cashaa-form-input name="password" type="password" [form]="form" [control]="form.controls.password"
        placeholder="Enter password"></cashaa-form-input>
      <cashaa-password-strength [passwordToCheck]="form.value.password" (passwordStrength)="passwordValid($event)">
      </cashaa-password-strength>
    </div>
    <div class="mb-4">
    <cashaa-form-select-country [form]="form" [control]="form.controls.country" name="country" (changed)="countryChanged($event)">
    </cashaa-form-select-country>
    </div>
    <cashaa-form-input name="referralId" type="text" [form]="form" [control]="form.controls.referralId"
        placeholder="Enter referral Code (Optional)"></cashaa-form-input>


    <button mdbBtn [disabled]="form.invalid || isSigningUp || !passwordInvalid || isCountryDisable" (click)="onSignupSubmit()" block="true"
      type="button"
      class="rounded-sm position-relative btn btn-secondary sm-custom-sec waves-effect waves-light w-100 signup-btn"
      mdbWavesEffect>
      <i class="fa-lock fas position-absolute"></i>Sign up
    </button>
    <!-- error message if failed -->
   
    <p class="mb-3 small">
      By continuing you accept our <a target="_blank" href="https://cashaa.com/term-condition">Terms of Use</a> and
      <a target="_blank" href="https://cashaa.com/privacy-policy">Privacy Policy</a>
    </p>
    <div class="position-relative">
      <mdb-error class="mt-2 sm-custom-error-msg">{{ errorMessage }}</mdb-error>
    </div>
  </form>
  <!--  <p class="font-weight-normal mb-2">
    Or login with
  </p>
  <div class="mb-4">
    <cashaa-account-social-signin></cashaa-account-social-signin>
  </div> -->

  <!-- Progress bar -->
  <mdb-progress-bar *ngIf="isSigningUp" class="progress primary-color-dark position-absolute" mode="indeterminate">
  </mdb-progress-bar>
</div>
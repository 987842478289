import { Component, OnInit, Input } from '@angular/core';
import { ModalService } from "src/app/core/services/modal/modal.service";
import { ILedgerList } from 'src/app/core/models/activity/recent-transaction-list';

@Component({
  selector: 'cashaa-activity-balance-attention',
  templateUrl: './activity-balance-attention.component.html',
  styleUrls: ['./activity-balance-attention.component.scss']
})
export class ActivityBalanceAttentionComponent implements OnInit {

  @Input() ledger: ILedgerList;

  constructor(private modalService: ModalService) { }

  ngOnInit(): void {
  }

  cancelTransfer(): void {
    this.modalService.attentionCancelTransfer();
  }

}

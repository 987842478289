<div class="modal-header p-4 m-2">
  <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
    <span aria-hidden="true">×</span>
  </button>

  <h6 class="modal-title w-100 font-weight-bolder pl-2" id="myModalLabel">
    <span class="sm-header">Transfer #</span>{{ detail.id | slice: -12 | uppercase }}
    <i mdbTooltip="Copy transaction number" placement="top" class="fas fa-clone"
      [ngClass]="copied ? 'text-primary' : ''" (click)="copyAddress()"></i>
  </h6>
</div>
<div class="modal-body">
  <div class="details p-3">
    <h5 class="font-weight-normal text-primary font-weight-normal text-gray pl-2 m-0 pb-2">
      Transfer Overview
    </h5>
    <div class="d-flex justify-content-between">
      <p class="font-weight-normal text-gray p-2">From</p>
      <h6 [title]="detail?.fromAddress" *ngIf="isCrypto()" class="font-weight-normal text-light-blue p-2">
        {{
        detail?.fromAddress
        ? detail.ledgerType == 1
        ? (detail?.fromAddress | slice: 0:10) +
        "..." +
        (detail.fromAddress
        | slice
        : detail.fromAddress.length - 10
        : detail.fromAddress.length)
        : detail?.fromAddress
        : detail?.fromAddress
        }}
      </h6>

      <h6
        *ngIf="isFiatAndAccount()"
        class="font-weight-normal text-light-blue p-2">
        {{ detail?.fromAddress}}
      </h6>
    </div>

    <div *ngIf="detail.ledgerType === 1 || detail.ledgerType === 34 || detail.ledgerType === 35 || detail.ledgerType === 36" class="d-flex justify-content-between">
      <p class="font-weight-normal text-gray p-2">To</p>
      <h6 [title]="detail?.toAddress" *ngIf="isCrypto()" class="font-weight-normal text-light-blue p-2">
        {{
        detail.ledgerType === 1 || detail.ledgerType === 34 || detail.ledgerType === 35 || detail.ledgerType === 36
        ? (detail?.toAddress | slice: 0:6) +
        "..." +
        (detail.toAddress
        | slice: detail.toAddress.length - 10:detail.toAddress.length)
        : detail?.fullName
        }}
      </h6>

      <h6
        *ngIf="isFiatAndAccount()"
        class="font-weight-normal text-light-blue p-2">
        {{ "Your " + getCurrencyName(detail.currency) + " account" }}
      </h6>
    </div>

    <div class="d-flex justify-content-between">
      <p class="font-weight-normal text-gray p-2">Amount sent</p>

      <h6 class="font-weight-normal text-light-blue p-2">
        {{ getAmountSend() | number: "0.0-4" }} {{getCurrency()}}
      </h6>
    </div>
    <div class="d-flex justify-content-between">
      <p class="font-weight-normal text-gray p-2">Fee</p>
      <h6 class="font-weight-normal text-light-blue p-2">
        {{detail.convertfee | number: "0.0-4" }} {{getCurrency()}}
      </h6>
    </div>

    <div
      *ngIf="(detail.ledgerType === 8 && (isFiatAndAccount())) || detail.ledgerType === 9"
      class="d-flex justify-content-between">
      <p class="font-weight-normal text-gray p-2">Convert to</p>

      <h6 class="font-weight-normal text-light-blue p-2">
        {{ detail.convertToamount }} {{ getCurrencyName(detail.convertTocurrency) }}
      </h6>
    </div>

    <div *ngIf="detail.ledgerType === 8 || detail.ledgerType === 9" class="d-flex justify-content-between">
      <p class="font-weight-normal text-gray p-2">Fees</p>

      <!--I have to take care of InCasha Mode here for fiat-->
      <h6 class="font-weight-normal text-light-blue p-2">
        {{ detail.convertfee | number: "0.0-4"}} {{ getCurrencyName(detail.feeCurrency) }}
      </h6>
    </div>

    <div *ngIf="detail.ledgerType === 9" class="d-flex justify-content-between">
      <p class="font-weight-normal text-gray p-2">Exchange rate</p>

      <h6 class="font-weight-normal text-light-blue p-2">
        {{ detail.exchangeRate }}
      </h6>
    </div>

    <div *ngIf="detail.ledgerType === 1 || detail.ledgerType === 34 || detail.ledgerType === 35 || detail.ledgerType === 36 || detail.ledgerType === 8" class="d-flex justify-content-between">
      <p class="font-weight-normal text-gray p-2" *ngIf="isCrypto()">
        Transaction Hash
      </p>

      <p class="font-weight-normal text-gray p-2"
        *ngIf="isFiatAndAccount()">
        Transfer Number
      </p>

      <h6 [title]="detail?.transactionId" class="font-weight-normal text-light-blue p-2 sm-overflow-hidden"
        [ngClass]="{'na-width':!detail?.transactionId}" *ngIf="isCrypto()">
        <a target="_blank"
          [attr.href]="getRef()"
          class="font-weight-bolder text-primary" target="_blank"
          *ngIf="!isInvalidTransaction() && detail?.transactionId && isCrypto()">{{
          (detail?.transactionId | slice: 0:6) +
          "......" +
          (detail.transactionId
          | slice
          : detail.transactionId.length - 10
          : detail.transactionId.length)
          }}</a>
        <span *ngIf="!isInvalidTransaction() && !detail?.transactionId">N/A</span>
        <span *ngIf="isInvalidTransaction()">{{
          (detail?.transactionId | slice: 0:6) +
          "......" +
          (detail.transactionId
          | slice
          : detail.transactionId.length - 10
          : detail.transactionId.length)
          }}</span>
      </h6>
      <h6 [title]="detail?.transactionId" class="font-weight-normal text-light-blue sm-overflow-hidden"
        [ngClass]="{'na-width':!detail?.transactionId}"
        *ngIf="isFiatAndAccount()">
        {{ detail?.transactionId ? detail?.transactionId : "N/A" }}
      </h6>
    </div>

    <hr *ngIf=" detail.ledgerType !== 1 && detail.ledgerType !== 34 && detail.ledgerType !== 35 && detail.ledgerType !== 36  && detail.ledgerType !== 9" />
    <h5 *ngIf="detail.ledgerType !== 1 && detail.ledgerType !== 34 && detail.ledgerType !== 35 && detail.ledgerType !== 36 && detail.ledgerType !== 9"
      class="font-weight-normal text-primary font-weight-normal text-gray pl-2 m-0 pb-2">
      Recipient Details
    </h5>

    <div *ngIf="detail.ledgerType !== 1 && detail.ledgerType !== 34 && detail.ledgerType !== 35 && detail.ledgerType !== 36  && detail.ledgerType !== 9" class="d-flex justify-content-between">
      <p class="font-weight-normal text-gray p-2">To</p>
      <h6 class="font-weight-normal text-light-blue p-2">
        {{ detail?.ledgerRecipient }}
      </h6>
    </div>

    <div *ngIf="detail.ledgerType !== 1 && detail.ledgerType !== 34 && detail.ledgerType !== 35 && detail.ledgerType !== 36 && detail.ledgerType !== 9 && isCrypto()"
      class="d-flex justify-content-between">
      <p class="font-weight-normal text-gray p-2">Address</p>
      <h6 [title]=" detail?.toAddress" class="font-weight-normal text-light-blue p-2">
        {{
        detail?.toAddress ? (detail?.toAddress | slice: 0:6) + "......" + (detail.toAddress | slice :
        detail.toAddress.length - 10 : detail.toAddress.length)
        : detail?.ledgerRecipient
        }}
      </h6>
    </div>
  </div>
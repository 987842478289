import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { FormGroup, FormControl } from "@angular/forms";
import { IFormSelect } from "src/app/core/models/form-select";
import { DomainService } from "src/app/core/services/domain/domain.service";
import { IBusinessCategory } from "src/app/core/models/business-category";

@Component({
  selector: "cashaa-form-select-business-category",
  templateUrl: "./form-select-business-category.component.html",
  styleUrls: ["./form-select-business-category.component.scss"],
})
export class FormSelectBusinessCategoryComponent implements OnInit {
  /** Placeholder for input */
  @Input() source: string;

  /** Placeholder for input */
  @Input() placeholder = "Business Category";

  /** input form group */
  @Input() form: FormGroup;

  /** form control */
  @Input() control: FormControl;

  /** name of control */
  @Input() name: string;

  @Input() label = "";

  @Output() changed = new EventEmitter<string>();

  /** form select values */
  options: IFormSelect[] = [];

  /** selected currency from dropdown */
  selectedCategory: IBusinessCategory;

  constructor(private domainService: DomainService) {}

  ngOnInit(): void {
    this.getBusinessCategoryList();
  }

  /***Get list of available directors*/

  getBusinessCategoryList(): void {
    this.domainService.getBusinessCategoryList().subscribe((list) => {
      this.options = list.map((category) => {
        return {
          value: category.id,
          label: category.name,
        };
      });
    });
  }

  onValueChange(newValue: IFormSelect): void {
    this.changed.emit(newValue.value);
  }
}

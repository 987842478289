import { Component, Input } from '@angular/core';
import { ILedgerList } from 'src/app/core/models/activity/recent-transaction-list';

@Component({
  selector: 'cashaa-activity-credit-locked-released',
  templateUrl: './activity-credit-locked-released.component.html',
  styleUrls: ['./activity-credit-locked-released.component.scss']
})
export class ActivityCreditLockedReleasedComponent {
  @Input() ledger: ILedgerList;

  getCurrencyName(curr: string): string {
    if (curr === "AUSD") return "USD";
    if (curr === 'DOG') return 'DOGE';
    else return curr;
  }

}



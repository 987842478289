import { DepositModule } from "./deposit/deposit.module";
import { AccountUpgradeModule } from "./account-upgrade/account-upgrade.module";
import { InviteModule } from "./invite/invite.module";
import { ConvertModule } from "./convert/convert.module";
import { WalletModule } from "./wallet/wallet.module";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from "@angular/core";
import { MDBBootstrapModulesPro, TooltipModule } from "ng-uikit-pro-standard";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { SharedModule } from "./shared/shared.module";
import { BusinessModule } from "./business/business.module";
import { AccountModule } from "./account/account.module";
import { KycModule } from "./kyc/kyc.module";
import { HelpdeskModule } from "./helpdesk/helpdesk.module";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { HttpInterceptorService } from "./core/services/interceptor/http-interceptor.service";
import { WithdrawModule } from "./withdraw/withdraw.module";
import { TradeModule } from "./trade/trade.module";
import { MessageBoxComponent } from "./shared/components/message-box/message-box.component";
import { FiatModule } from "./fiat/fiat.module";
import { RecipientModule } from "./recipient/recipient.module";
import { ReceiveModule } from "./receive/receive.module";
import { ProfileModule } from "./profile/profile.module";
import { SettingsModule } from "./settings/settings.module";
import { ActivityModule } from "./activity/activity.module";
import { CustomerModule } from "./customer/customer.module";
import { DatePipe, DecimalPipe } from "@angular/common";
import { QRCodeModule } from "angular2-qrcode";
import { ToastrModule } from "ngx-toastr";
import { SwapModule } from "./swap/swap.module";
import { DepositInterestModule } from "./deposit-interest/deposit-interest.module";
import { ApiManagerModule } from "./api-manager/api-manager.module";
import { UserPlanModule } from "./user-plan/user-plan.module";
import { ReferralRoutingModule } from "./referral/referral-routing.module";
import { ReferralModule } from "./referral/referral.module";
import { StatementModule } from "./statement/statement.module";
import { EventFeeModule } from "./event-fee/event-fee.module";


@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MDBBootstrapModulesPro.forRoot(),
    ToastrModule.forRoot(),
    RecipientModule,
    ReferralModule,
    StatementModule,
    BusinessModule,
    AccountModule,
    SharedModule,
    KycModule,
    HelpdeskModule,
    WithdrawModule,
    TradeModule,
    FiatModule,
    WithdrawModule,
    ReceiveModule,
    ProfileModule,
    SettingsModule,
    ActivityModule,
    WalletModule,
    ConvertModule,
    InviteModule,
    AccountUpgradeModule,
    DepositModule,
    CustomerModule,
    SwapModule,
    EventFeeModule,
    DepositInterestModule,
    ApiManagerModule,
    UserPlanModule,
    AppRoutingModule,
    QRCodeModule,
    TooltipModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true,
    },
    DatePipe,
    DecimalPipe,
  ],
  entryComponents: [MessageBoxComponent],
  bootstrap: [AppComponent],
})
export class AppModule {}

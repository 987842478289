import { CurrencyTypeData } from "./../../core/models/currency-type-data";
import { ICryptoAccountDetails } from "./../../core/models/crypto-account-details";
import { IAccountDetails } from "./../../core/models/account-details";
import { CryptoAccountRequestModel } from "./../../core/models/crypto-account-request-model";
import { FiatAccountRequestModel } from "./../../core/models/fiat-account-request-model";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { Injectable } from "@angular/core";
import { ICurrencyList } from "../../core/models/currency";
import { environment } from "../../../environments/environment";
import { IUserBalance } from "src/app/core/models/user-balance";
import { UserCreditBalance } from "src/app/core/models/credit-line";

@Injectable({
  providedIn: "root",
})
export class WalletService {
  /** api url */
  private apiUrl = environment.api;

  /** Currency Symbol emitter */
  private currencySymbolEmitter = new BehaviorSubject<
    CurrencyTypeData | undefined
  >(undefined);
  public loanData: Subject<any>;
  constructor(private http: HttpClient) {
    
    this.loanData = new Subject();
  }

  /**
   * Get User balance
   */
  getUserBalance(): Observable<IUserBalance[]> {
    return this.http.get<IUserBalance[]>(`${this.apiUrl}/balance`);
  }
  /**
   * get fiat currencies
   */
  getFiatCurrency(): Observable<ICurrencyList> {
    return this.http.get<ICurrencyList>(`${this.apiUrl}/currencies/fiat`);
  }

  /**
   * get Crypto currencies
   */
  getCryptoCurrency(): Observable<ICurrencyList> {
    return this.http.get<ICurrencyList>(`${this.apiUrl}/currencies/crypto`);
  }

  /*** Get KYC data emitter***/
  getCurrencySymbolEmitter(): Observable<CurrencyTypeData> {
    return this.currencySymbolEmitter.asObservable();
  }

  /**
   * Emit Currency Symbol
   */
  emitCurrencySymbol(symbol: CurrencyTypeData): void {
    this.currencySymbolEmitter.next(symbol);
  }

  openFiatAccount(
    currencySymbol: FiatAccountRequestModel
  ): Observable<{ statusCode: number; message: string }> {
    return this.http.post<{ statusCode: number; message: string }>(
      `${environment.api}/fiats/createaccount`,
      currencySymbol
    );
  }
  openPersonalFiatAccount(
    currencySymbol: FiatAccountRequestModel
  ): Observable<{ statusCode: number; message: string }> {
    return this.http.post<{ statusCode: number; message: string }>(
      `${environment.api}/fiats/createpersonalaccount`,
      currencySymbol
    );
  }

  OpenCryptoAccount(
    payload: CryptoAccountRequestModel
  ): Observable<{ statusCode: number; message: string }> {
    return this.http.post<{ statusCode: number; message: string }>(
      `${environment.api}/wallets/create`,
      payload
    );
  }

  getAccountDetailsByCurrency(
    walletCurrency: string
  ): Observable<IAccountDetails> {
    return this.http.get<IAccountDetails>(
      `${this.apiUrl}/fiats/getfiataccountbyusercurrecny/${walletCurrency}`
    );
  }

  getCryptoAccountDetails(currency: string): Observable<ICryptoAccountDetails> {
    return this.http.get<ICryptoAccountDetails>(
      `${this.apiUrl}/wallets/${currency}`
    );
  }

  

  getCreditLoan(
    payload
  ): Observable<{ statusCode: number; message: string }> {
    return this.http.post<{ statusCode: number; message: string }>(
      `${environment.api}/CreditLine/create`,
      payload
    );
  }

  sendEmailOTP(payload) {
    return this.http.post<{ statusCode: number; message: string }>(
      `${environment.api}/CreditLine/creditlineotp`,
      payload
    );
  }

  getDailyInterestRate(currencyId, isInterestFetch) {
  return this.http.get<any>(`${this.apiUrl}/CreditLine/getDailyInterest?Id=${currencyId}&isInterestFetch=${isInterestFetch}`)
  }

  setLoanDetails(loanDetails) {
    this.loanData.next(loanDetails);
  }

  repay(payload): Observable<{ statusCode: number; message: string }> {
    return this.http.post<{ statusCode: number; message: string }>(
      `${environment.api}/CreditLine/repay`,
      payload
    );
  }

  getCreditLoanList() {
    return this.http.get<any>(`${this.apiUrl}/CreditLine/getCreditLoan`);
  }

  getLoanDetailsById(loanId) {
    return this.http.get<any>(`${this.apiUrl}/CreditLine/getCreditLoanDetail?Id=${loanId}`);
  }

  getInterestDeductionAgainstLoan(loanId, page, pageSize) {
    return this.http.get<any>(`${this.apiUrl}/CreditLine/getinterest?page=${page}&pageSize=${pageSize}&LoanId=${loanId}`);
  }
  
  getCreditLines() {
    return this.http.get<UserCreditBalance>(
      `${this.apiUrl}/CreditLine/getDefaultCredit`
    )
  }

  getLoanRepaymentData(loanId) {
    return this.http.get<any>(
      `${this.apiUrl}/CreditLine/getOutstandingLoanById?LoanId=${loanId}`);
  }

  getLockedCurrencyAmount(payload): Observable<{ statusCode: number; message: string }> {
    return this.http.post<{ statusCode: number; message: string }>(
      `${environment.api}/CreditLine/lockedcurrency`,
      payload
    );
  }

  getLoanRepaymentHistory(loanId) {
    return this.http.get<any>(
      `${this.apiUrl}/CreditLine/getrepayment?LoanId=${loanId}`);
  }

  getPorfolioHistory() {
    return this.http.get<ICryptoAccountDetails>(
      `${this.apiUrl}/userplan/getuserportfoliohistory/`
    );
  }

  getCreditLineRate(fromCurrency, toCurrency) {
    return this.http.get<any>(
    `${this.apiUrl}/CreditLine/getcreditlinerate?fromCurrency=${fromCurrency}&toCurrency=${toCurrency}`
    );
  }

  getOutStandingAmount(loanid, fromCurrency, toCurrency, outStandingAmount, amount) {
    return this.http.get<any>(`${this.apiUrl}/CreditLine/getoutstandingamount?loanid=${loanid}&fromCurrency=${fromCurrency}&toCurrency=${toCurrency}&outStandingAmount=${outStandingAmount}&amount=${amount}`)
  }

  getInterest(isInterestFetch) {
    return this.http.get<any>(`${this.apiUrl}/CreditLine/getDefaultCredit?isInterestFetch=${isInterestFetch}`)
  }
  getCreditLineQuote(isInterestFetch) {
    return this.http.get<any>(`${this.apiUrl}/CreditLine/getcreditlinequote?isInterestFetch=${isInterestFetch}`)
  }

  getLockedCurrencyByLoanId(loanId) {
    return this.http.get<any>(`${this.apiUrl}/CreditLine/getlockedamount?loanId=${loanId}`);
  }
  
  
}





import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UploadFile, UploadInput, UploadOutput } from 'ng-uikit-pro-standard';
import { humanizeBytes } from 'ng-uikit-pro-standard';
import { FileUpload, IAPIEnumLoad, IFile } from 'src/app/core/models/Lerex/enum-load';
import { DomainService } from 'src/app/core/services/domain/domain.service';
import { KycService } from 'src/app/kyc/services/kyc.service';

@Component({
  selector: 'cashaa-form-input-file',
  templateUrl: './form-input-file.component.html',
  styleUrls: ['./form-input-file.component.scss']
})
export class FormInputFileComponent implements OnInit {

  formData: FormData;
  files: UploadFile[];
  uploadInput: EventEmitter<File>;
  @Output() deleteFile: EventEmitter<any>;

  humanizeBytes: Function;
  dragOver: boolean;
  @Input() fileUploadStatus = false;
  @Input() isFileUploadCompleted = false;
  @Input() isOperationCompleted = false;
  @Input() docType: IFile;
  @Input() isDeletedActive = false;
  @Output() status: EventEmitter<string>;

  ngOnInit(): void {
  }
  
  constructor(private domainService: DomainService) {
      this.files = [];
      this.status = new EventEmitter<string>();
      this.deleteFile = new EventEmitter<any>();
      this.humanizeBytes = humanizeBytes;
  }

  showFiles() {
      let files = '';
      for (let i = 0; i < this.files.length; i ++) {
        files += this.files[i].name;
         if (!(this.files.length - 1 === i)) {
           files += ',';
        }
      }
      return files;
   }

  startUpload(): void {
      console.log(this.files[0].nativeFile);
      this.docType.file = this.files[0].nativeFile;
      this.docType.fileName = this.files[0].name;
      this.domainService.emitKycUploadFile(this.docType);
      this.status.emit(this.docType.docType);
      this.files = [];
  }

  cancelUpload(): void {
      this.files  = [];
  }

  deleteFileEvent() {
    this.deleteFile.emit();
    
  }

  onUploadOutput(output: UploadOutput | any): void {
    console.log(output);
      if (output.type === 'allAddedToQueue') {
      } else if (output.type === 'addedToQueue') {
        // console.log(output.File.nativeFile);
        this.files.push(output.file); // add file to array when added
      } else if (output.type === 'uploading') {
        // update current data in files array for uploading file
        const index = this.files.findIndex(file => file.id === output.file.id);
        this.files[index] = output.file;
      } else if (output.type === 'removed') {
        // remove file from array when removed
        this.files = this.files.filter((file: UploadFile) => file !== output.file);
      } else if (output.type === 'dragOver') {
        this.dragOver = true;
      } else if (output.type === 'dragOut') {
      } else if (output.type === 'drop') {
        this.dragOver = false;
      }
      // console.log(this.showFiles());
  }

}

import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient, HttpResponse } from "@angular/common/http";
import { environment } from "src/environments/environment";
import {
  IHelpDeskTicket,
  IHelpDeskCategories,
  ICreateHelpDesk,
} from "src/app/core/models/create-helpdesk";

@Injectable({
  providedIn: "root",
})
export class HelpdeskTicketService {
  /** api url */
  private apiUrl = environment.api;

  constructor(private http: HttpClient) {}

  /**
   * Get all the helpdesk tickets  //getallhelpdesktickets
   */

  getAllHelpDeskTickets(): Observable<IHelpDeskTicket[]> {
    return this.http.get<IHelpDeskTicket[]>(
      `${this.apiUrl}/helpdesktickets/getallhelpdesktickets`
    );
  }

  /**
   * Get all catagories for help desk
   */

  getHelpDeskCatagories(): Observable<IHelpDeskCategories[]> {
    return this.http.get<IHelpDeskCategories[]>(
      `${this.apiUrl}/helpdesktickets/gethelpdeskcategories`
    );
  }

  /**
   *
   * @param helpDeskData : Help desk ticket data
   */

  createHelpDesk(
    helpDeskData: FormData
  ): Observable<{ statusCode: number; message: string }> {
    return this.http.post<{ statusCode: number; message: string }>(
      `${this.apiUrl}/helpdesktickets/createhelpdeskticket`,
      helpDeskData
    );
  }

  /**
   *
   * @param ticketId : Get Ticket detail by ticket id
   */

  getHelpDeskTicketById(ticketId: string): Observable<IHelpDeskTicket> {
    return this.http.get<IHelpDeskTicket>(
      `${this.apiUrl}/helpdesktickets/gethelpdeskticketdetails`
    );
  }
}

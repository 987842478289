import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ActivityHomeComponent } from '../activity/activity-home/activity-home.component';
import { AuthGuardService } from '../core/services/auth-guard/auth-guard.service';
import { GenerateStatementComponent } from './generate-statement/generate-statement.component';


const routes: Routes = [
  {
    path: "statement",
    component: GenerateStatementComponent
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class StatementRoutingModule { }

<cashaa-sidenav>
  <section class="mh-100">
    <div class="bg-white p-4">
      <div class="d-flex ml-5" style="height: 46px;">
        <h6 class="heading my-auto">Settings</h6></div>
    </div>

    <div class="p-5 sm-width-phone-700">
      <div class="mb-3">
        <cashaa-settings-email></cashaa-settings-email>
      </div>
      <div class="mb-3">
        <cashaa-settings-password></cashaa-settings-password>
      </div>
      <div class="mb-3">
        <cashaa-settings-two-factor-auth></cashaa-settings-two-factor-auth>
      </div>
      <!--<div class="mb-3">
        <cashaa-settings-fee></cashaa-settings-fee>
      </div>-->
      <div class="mb-3">
        <cashaa-setting-interest-choice></cashaa-setting-interest-choice>
      </div>
      <div class="mb-3">
        <cashaa-setting-close-account></cashaa-setting-close-account>
      </div>
    </div>
  </section>
</cashaa-sidenav>

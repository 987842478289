<div class="modal-header position-relative">
  <button
    type="button"
    class="close pull-right"
    aria-label="Close"
    (click)="modalRef.hide()"
  >
    <span aria-hidden="true">×</span>
  </button>
  <h4
    class="modal-title w-100 text-primary font-weight-bolder"
    id="myModalLabel"
  >
    Send to business / charity
  </h4>
</div>
<div class="modal-body" [formGroup]="form">
  <div class="mb-4">
    <cashaa-form-input
      name="email"
      type="email"
      [form]="form"
      [control]="form.controls.email"
      placeholder="Email (optional)"
    ></cashaa-form-input>
  </div>

  <div class="mb-4">
    <cashaa-form-input
      name="name"
      type="text"
      [form]="form"
      [control]="form.controls.name"
      placeholder="Name of the business / charity"
    >
    </cashaa-form-input>
  </div>

  <!-- WHAT BANK DO -->
  <h5 class="font-weight-normal text-primary">Recipient's address</h5>
  <hr />

  <div class="mb-4">
    <cashaa-form-select-country
      name="countryId"
      [form]="form"
      [control]="form.controls.countryId"
      placeholder="Country"
    >
    </cashaa-form-select-country>
  </div>

  <div class="mb-4">
    <cashaa-form-input
      name="city"
      type="text"
      [form]="form"
      [control]="form.controls.city"
      placeholder="City"
    >
    </cashaa-form-input>
  </div>

  <div class="mb-4">
    <cashaa-form-input
      name="address"
      type="text"
      [form]="form"
      [control]="form.controls.address"
      placeholder="Address"
    >
    </cashaa-form-input>
  </div>

  <div class="mb-4">
    <cashaa-form-input
      name="zipcode"
      type="number"
      [form]="form"
      [control]="form.controls.zipcode"
      placeholder="ZIP code"
    >
    </cashaa-form-input>
  </div>

  <div class="mb-4">
    <cashaa-form-input
      name="state"
      type="text"
      [form]="form"
      [control]="form.controls.state"
      placeholder="State"
    >
    </cashaa-form-input>
  </div>

  <h5 class="font-weight-normal text-primary">Bank details</h5>
  <hr />

  <div class="mb-4">
    <cashaa-form-select-country
      name="bankCountryId"
      [form]="form"
      [control]="form.controls.bankCountryId"
      placeholder="Bank account country"
    >
    </cashaa-form-select-country>
  </div>

  <div class="mb-4">
    <cashaa-form-input
      name="routingNumber"
      type="number"
      [form]="form"
      [control]="form.controls.routingNumber"
      placeholder="Routing number"
    >
    </cashaa-form-input>
  </div>

  <div class="mb-4">
    <cashaa-form-input
      name="accountNumber"
      type="number"
      [form]="form"
      [control]="form.controls.accountNumber"
      placeholder="Account number"
    >
    </cashaa-form-input>
  </div>

  <div class="row mb-4">
    <div class="form-check col-6">
      <input
        type="radio"
        formControlName="accountType"
        class="form-check-input"
        id="checkingaccount"
        name="accountType"
        mdbInput
        value="c"
      />
      <label class="form-check-label" for="checkingaccount">Checking</label>
    </div>
    <div class="form-check col-6">
      <input
        type="radio"
        formControlName="accountType"
        class="form-check-input"
        id="savingaccount"
        name="accountType"
        mdbInput
        value="s"
      />
      <label class="form-check-label" for="savingaccount">Saving</label>
    </div>
  </div>

  <button
    mdbBtn
    (click)="onFormSubmit()"
    block="true"
    type="button"
    class="rounded-sm position-relative btn btn-primary waves-effect waves-light btn-block"
    mdbWavesEffect
  >
    Confirm
  </button>
</div>

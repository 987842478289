import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DepositInterestRoutingModule } from './deposit-interest-routing.module';
import { DepositInterestAmountComponent } from './deposit-interest-amount/deposit-interest-amount.component';
import { DepositInterestHomeComponent } from './deposit-interest-home/deposit-interest-home.component';
import { DepositInterestConfirmComponent } from './deposit-interest-confirm/deposit-interest-confirm.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MDBBootstrapModulesPro } from 'ng-uikit-pro-standard';
import { SharedModule } from '../shared/shared.module';


@NgModule({
  declarations: [DepositInterestAmountComponent, DepositInterestHomeComponent, DepositInterestConfirmComponent],
  imports: [
    CommonModule,
    DepositInterestRoutingModule,
    ReactiveFormsModule,
    MDBBootstrapModulesPro.forRoot(),
    SharedModule,
    FormsModule
  ]
})
export class DepositInterestModule { }

import { Component, OnInit, Input } from "@angular/core";
import { FormGroup, FormControl } from '@angular/forms';
import { IFormSelect } from 'src/app/core/models/form-select';
import { DomainService } from 'src/app/core/services/domain/domain.service';
import { IUserBalance } from 'src/app/core/models/user-balance';
import { WithdrawService } from 'src/app/withdraw/services/withdraw.service';
import { data } from 'jquery';

@Component({
  selector: "cashaa-form-select-withdraw-address",
  templateUrl: "./form-select-withdraw-address.component.html",
  styleUrls: ["./form-select-withdraw-address.component.scss"]
})
export class FormSelectWithdrawAddressComponent implements OnInit {

  /** name of control */
  @Input() symbol: string;
  /** Placeholder for input */
  @Input() placeholder = "Withdraw Address";

  /** input form group */
  @Input() form: FormGroup;

  /** form control */
  @Input() control: FormControl;

  /** name of control */
  @Input() name: string;

  /** form select values */
  options: IFormSelect[] = [];

  constructor(private domainService: DomainService, private withdrawService: WithdrawService) { }

  ngOnInit(): void {
    this.withdrawService.getWithdrawAddressSymbolEmitter().subscribe((data) => {
      if (data) {

        this.getWithdrawAddressList(data);
      }
    });


  }




  /*** Get list of available Withdraw Addresses*/
  getWithdrawAddressList(symbol: string): void {
    this.domainService.getWithdrawAddressList(symbol).subscribe((list) => {

      this.options = list.map((address) => {

        return {
          value: address.currencyId,
          label: address.address,
        };
      });
      this.addOtherOption();
    });
  }

  addOtherOption(): void {
    this.options.push({
      value: "-1",
      label: "Other",
    });
  }
  Onsymbolchanged(UserBalance: IUserBalance): void {



  }


}

import { Component, OnInit, Input, Output } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { EventEmitter } from 'protractor';
import { IFormSelect } from 'src/app/core/models/form-select';
import { DomainService } from 'src/app/core/services/domain/domain.service';
import { IHelpdeskCategory } from "src/app/core/models/helpdesk-category";

@Component({
  selector: 'cashaa-form-select-helpdesk-category',
  templateUrl: './form-select-helpdesk-category.component.html',
  styleUrls: ['./form-select-helpdesk-category.component.scss']
})
export class FormSelectHelpdeskCategoryComponent implements OnInit {

  /** Placeholder for input */
  @Input() source: string;

  /** Placeholder for input */
  @Input() placeholder = "Helpdesk Category";

  /** input form group */
  @Input() form: FormGroup;

  /** form control */
  @Input() control: FormControl;

  /** name of control */
  @Input() name: string;

  @Input() label = "";

  // @Output() changed = new EventEmitter<string>();

  /** form select values */
  options: IFormSelect[] = [];

  /** selected currency from dropdown */
  selectedCategory: IHelpdeskCategory;

  constructor(private domainService: DomainService) { }

  ngOnInit(): void {
    this.getHelpdeskCategoryList();
  }

  getHelpdeskCategoryList(): void {
    this.domainService.getHelpdeskCategoryList().subscribe((list) => {
   
      this.options = list.map((category) => {
        return {
          value: category.id,
          label: category.categoryName,
        };
      });
    });
  }

  // onValueChange(newValue: IFormSelect): void {
  //   this.changed.emit(newValue.value);
  // }

}

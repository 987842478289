import {
  Component,
  ElementRef,
  OnInit,
  QueryList,
  ViewChildren,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { MDBModalRef } from "ng-uikit-pro-standard";
import { ToastrService } from "ngx-toastr";
import {
  BUSINESS_UPGRADE_SUBSCRIPTION,
  PERSONAL_UPGRADE_SUBSCRIPTION,
  UNABLE_TO_PERFORM_ERROR,
  USER_TWO_FACTOR_CODE,
  USER_TYPE,
} from "src/app/app.constants";
import { IUpgradeSubscriptionRequest } from "src/app/core/models/account-upgrade/upgrade-subscription-request";
import { UserService } from "src/app/core/services/user/user.service";
import { AccountUpgradeService } from "../services/account-upgrade.service";
const reservceBalance = require("../../../assets/json/reserveBalance.json");
@Component({
  selector: "cashaa-reserve-modal",
  templateUrl: "./reserve-modal.component.html",
  styleUrls: ["./reserve-modal.component.scss"],
})
export class ReserveModalComponent implements OnInit {
  /** is some network call in progress */
  isInProgress = false;
  twoFactor = false;
  userEmail?: string;
  errorMessageFlag = false;
  errorMessage = "";
  response = "";
  isLoading = true;
  currFlag;
  allCodeEntered = false;
  reserveBalance;
  code: string[] = ["", "", "", "", "", ""];
  @ViewChildren("inputs") inputs: QueryList<ElementRef>;
  errorMsg: string;
  balanceError: boolean;
  balanceErrorMsg: string;
  isBusiness: boolean;
  isStaking: boolean;
  stakingHeading: string;
  stakingBody: string;
  userType = sessionStorage.getItem(USER_TYPE);
  public form: FormGroup;
  constructor(
    private fb: FormBuilder,
    public modalRef: MDBModalRef,
    private toastrService: ToastrService,
    private accountUpgradeService: AccountUpgradeService,
    private userService: UserService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      referralCode: "",
    });
    this.reserveBalance = reservceBalance;
    this.userService.getUserBalance().subscribe((balance) => {
      const found = balance.filter((b) => b.currencyType === "CAS");
      if (
        found.length === 0 ||
        found[0].available < this.reserveBalance.value
      ) {
        this.balanceError = true;
        this.balanceErrorMsg =
          "Minimum balance required is 10,000. Please deposit the amount.";
      } else {
        this.balanceError = false;
      }
    });
    //set GBP as default currency
    const idToken = sessionStorage.getItem(USER_TWO_FACTOR_CODE);
    if (idToken === "true") {
      this.twoFactor = true;
    }
  }

  /**
   * On submission of form
   */
  onFormSubmit(): void {
    this.errorMessageFlag = false;
    let formV = this.form.value;

    let request: IUpgradeSubscriptionRequest = {
      code: this.code.join(""),
      referalcode: formV.referralCode,
    };
    this.isInProgress = true;
    if (this.isStaking && !this.isBusiness) {
      this.accountUpgradeService.createStakingPersonal(request).subscribe(
        (res) => {
          this.isInProgress = false;
          this.modalRef.hide();
          this.response = "";
          this.toastrService.success(PERSONAL_UPGRADE_SUBSCRIPTION);
          this.router.navigateByUrl("subscription/staking/timeline");
        },
        (err) => {
          this.isInProgress = false;
          this.code = ["", "", "", "", "", ""];
          this.allCodeEntered = false;
          if (err.error.message) {
            this.errorMessageFlag = true;
            this.errorMsg = err.error.message;
          } else {
            this.toastrService.error(UNABLE_TO_PERFORM_ERROR);
          }
        }
      );
    } else {
      this.accountUpgradeService.upgradeSubscriptionPlan(request).subscribe(
        (res) => {
          this.isInProgress = false;
          this.modalRef.hide();
          this.response = "";
          this.toastrService.success(BUSINESS_UPGRADE_SUBSCRIPTION);
          if (this.isStaking) {
            this.router.navigateByUrl("subscription/staking/timeline");
          } else {
            this.router.navigateByUrl(
              this.isBusiness ? "/kyc/business" : "/kyc/US/personal"
            );
          }
        },
        (err) => {
          this.isInProgress = false;
          this.code = ["", "", "", "", "", ""];
          this.allCodeEntered = false;
          if (err.error.message) {
            this.errorMessageFlag = true;
            this.errorMsg = err.error.message;
          } else {
            this.toastrService.error(UNABLE_TO_PERFORM_ERROR);
          }
        }
      );
    }
  }

  removeError(): void {
    if (this.errorMessage !== undefined) {
      this.errorMessage = undefined;
    }
  }

  /**
   * Check if google input authenticator
   * code is filled in all 6 boxes
   */
  checkIfCodeIsFilled(idx: number): void {
    this.errorMsg = "";
    if (idx >= 0 && idx < 5 && this.code[idx]) {
      this.inputs.toArray()[idx + 1].nativeElement.focus();
    }

    this.allCodeEntered = true;
    this.code.forEach((val) => {
      if (val === null || val.length === 0) {
        this.allCodeEntered = false;
      }
    });
    if (this.code[5] && idx === -1) {
      this.inputs.toArray()[5].nativeElement.focus();
    }
  }

  /**
   * On code paste automatically
   * fill all 6 boxes
   */
  onCodePaste(event: ClipboardEvent): void {
    const clipboardData = event.clipboardData || (window as any).clipboardData;
    const pastedText = clipboardData.getData("text");

    if (pastedText && pastedText.length === 6) {
      this.code = pastedText.split("");
      this.checkIfCodeIsFilled(-1);
    }
  }

  removeBack(event: any, index: number) {
    const keyCode = event.keyCode;
    if (keyCode === 8) {
      this.inputs.toArray()[index].nativeElement.focus();
    }
  }
}

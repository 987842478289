import { Router } from "@angular/router";
import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { ModalService } from "src/app/core/services/modal/modal.service";
import { UserService } from "src/app/core/services/user/user.service";
import { DepositInterestService } from "src/app/deposit-interest/service/deposit-interest.service";
import { IDepositInterestPlan } from "src/app/core/models/deposit-interest/deposit-interest";
import { USER_COUNTRY_CODE, USER_INTEREST_MODE, USER_TYPE } from "src/app/app.constants";
import { IUserPlan } from "src/app/core/models/user-plan/user-detail";
import { IUserBalance } from "src/app/core/models/user-balance";
import { IAccountDetails } from "src/app/core/models/account-details";
import { ICryptoAccountDetails } from "src/app/core/models/crypto-account-details";

@Component({
  selector: "account-box-mobile",
  templateUrl: "./account-box-mobile.component.html",
  styleUrls: ["./account-box-mobile.component.scss"]
})
export class AccountBoxMobileComponent implements OnInit {
  /** Currency type */
  @Input() currency: string;
  @Input() item;
  @Input() currencyId: string;
  @Input() isDepositActive: boolean;
  @Input() isSendActive: boolean;
  @Input() idx = -1;
  @Input() creditBalanceInUSD;
  @Input() userBalanceInUSD;
  @Input() isEarn;
  selectedCrypto = -1

  /** balance */
  @Input() balance = 0;
  @Output() isLoadingMDBAccordionBody = new EventEmitter();

  diamondPlan: IUserPlan;
  rate: number;
  allCurrenciesPlan: IUserPlan[] = [];
  rateType: string = sessionStorage.getItem(USER_INTEREST_MODE);
  currencyDepositRate: number[] = [];
  maxInterestModeRate: number[] = [];
  nonBankingFiatCurrency = ["AUD", "JPY", "CAD", "CNH", "SGD", "NZD", "CHF"];
  excludeCryptoDisable: string[] = ["BNB20"];
  cryptoLoading = false;
  accountDetailsByCurrencyData: IAccountDetails;
  accountDetailsByAccountCurrencyData: IAccountDetails;
  accountDetailsCryptoData: ICryptoAccountDetails;
  accountDetailsETHCryptoData: ICryptoAccountDetails;
  currencyDepositRateLessThanEqual365: number[] = [];
  currencyDepositRateGreaterThan365: number[] = [];
  userType = sessionStorage.getItem(USER_TYPE);
  constructor(private router: Router, private modalService: ModalService,
    private userService: UserService, private depositService: DepositInterestService) { }

  ngOnInit(): void {
  }

  goToCurrency(): void {
    this.router.navigateByUrl(`/wallet?c=${this.currency}#${this.currency}`);
  }

  getCurrencyName(curr): string {
    if (curr === "AUSD") return "USD";
    if (curr === "DOG") return "DOGE";
    else return curr;
  }

  openEarnPlan(event): void {
    event.stopPropagation();
    if (this.currency === 'CAS') {
      this.router.navigateByUrl(`deposit-interest?c=${this.currency}`);
    }
     else {
      this.modalService.openEarnPlanDetailPopup(this.item.loyaltyPlanName, this.item.upgradeTo, this.item.availableBalance, this.item.currencyId, this.item.symbol, this.item.fLexTerm,
        this.item.fixedTerm, this.item.earnUpTo, this.item.longEarnUpTo, this.item.minFlexAmount, this.item.isFlexSubscribed, this.item.casBonus);
    }
  }

  isEarnAndFixedDepositActive(): boolean {
    return sessionStorage.getItem(USER_COUNTRY_CODE) !== 'US';
  }

  isCryptoDisable(currency: string): boolean {
    if (this.excludeCryptoDisable.includes(currency)) {
      return true;
    } else {
      return false;
    }
  }

  isHide(): boolean {
    if (this.rate && this.rate > 0) {
      return false;
    } else {
      return true;
    }
  }
  isHidden(currency: string): boolean {
    if (this.nonBankingFiatCurrency.includes(currency)) {
      return true;
    } else {
      return false;
    }
  }
  onAddClick(currency: string): void {
    this.router.navigateByUrl(`/deposit?cur=${currency}`);
  }
  onConvertClick(currency: string): void {
    this.router.navigateByUrl(`/convert?c=${currency}`);
  }

  onSendClick(currency: string): void {
    this.router.navigateByUrl(`/fiat?c=${currency}`);
  }
  getCryptoAccountDetails(event, currency: IUserBalance, idx: number): void {
    if (currency && currency.currencynature === 2) {
      this.router.navigateByUrl(`/deposit?cur=${currency.currencyType}`);
    } else {
      currency.isSelected = !currency.isSelected;
      this.isLoadingMDBAccordionBody.emit({ 'rate': this.rate, 'id': idx, 'currency': currency });
    }
  }

  onBuyClick(currency: string): void {
    this.router.navigateByUrl(`/buy?c=${currency}&`);
  }

}

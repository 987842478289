import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FiatFlowAmountComponent } from "./fiat-flow-amount/fiat-flow-amount.component";
import { FiatFlowRecipientComponent } from "./fiat-flow-recipient/fiat-flow-recipient.component";
import { FiatFlowReviewComponent } from "./fiat-flow-review/fiat-flow-review.component";
import { FiatRoutingModule } from "./fiat-routing.module";
import { SharedModule } from "../shared/shared.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MDBBootstrapModulesPro } from "ng-uikit-pro-standard";
import { FiatFlowPayApproveComponent } from "./fiat-flow-pay-approve/fiat-flow-pay-approve.component";
import { FiatFlowPayAuthApprovalComponent } from "./fiat-flow-pay-auth-approval/fiat-flow-pay-auth-approval.component";
import { FiatFlowWrapperComponent } from "./fiat-flow-wrapper/fiat-flow-wrapper.component";
import { FiatFlowRecipientAddBusinessComponent } from "./fiat-flow-recipient-add-business/fiat-flow-recipient-add-business.component";
import { FiatCompletedComponent } from "./fiat-completed/fiat-completed.component";
import { ToastrModule } from "ngx-toastr";

@NgModule({
  declarations: [
    FiatFlowAmountComponent,
    FiatFlowRecipientComponent,
    FiatFlowReviewComponent,
    FiatFlowPayApproveComponent,
    FiatFlowPayAuthApprovalComponent,
    FiatFlowWrapperComponent,
    FiatFlowRecipientAddBusinessComponent,
    FiatCompletedComponent,
  ],
  imports: [
    CommonModule,
    FiatRoutingModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    ToastrModule.forRoot(),
    MDBBootstrapModulesPro.forRoot(),
  ],
})
export class FiatModule {}

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MDBBootstrapModulesPro } from "ng-uikit-pro-standard";
import { SharedModule } from "../shared/shared.module";
import { ReactiveFormsModule } from "@angular/forms";
import { ToastrModule } from "ngx-toastr";
import { QRCodeModule } from "angular2-qrcode";
import { EventFeeHomeComponent } from "./event-fee-home/event-fee-home.component";
import { EventFeeRoutingModule } from "./event-fee-routing.module";
import { TicketTypeComponent } from "./ticket-type/ticket-type.component";
import { CurrencyTypeComponent } from "./currency-type/currency-type.component";
import { AddressComponent } from "./address/address.component";
import { ConfirmComponent } from "./confirm/confirm.component";
import { PaymentHistoryComponent } from "./payment-history/payment-history.component";
import { DepositTimerComponent } from "./timer/deposit-timer.component";
import { OtherDetailsComponent } from "./other-details/other-details.component";

@NgModule({
  declarations: [EventFeeHomeComponent, TicketTypeComponent, CurrencyTypeComponent, AddressComponent, ConfirmComponent, DepositTimerComponent, PaymentHistoryComponent, OtherDetailsComponent],
  imports: [
    CommonModule,
    MDBBootstrapModulesPro.forRoot(),
    ToastrModule.forRoot(),
    SharedModule,
    ReactiveFormsModule,
    EventFeeRoutingModule,
    QRCodeModule,
  ],
})
export class EventFeeModule {}

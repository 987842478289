import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { BusinessHomeComponent } from "./business-home/business-home.component";
import { AuthGuardService } from "./../core/services/auth-guard/auth-guard.service";
const routes: Routes = [
  {
    path: "business",
    canActivate: [AuthGuardService],
    children: [
      {
        path: "",
        component: BusinessHomeComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class BusinessRoutingModule { }

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import {
  ISwapAddress,
  ISwapReceivingAddress,
  ISwapListData,
} from "src/app/core/models/swap/swap";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class SwapService {
  private apiUrl = environment.api;
  private isLoading = new BehaviorSubject<boolean>(false);
  private customerAddFlag = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient) {}

  getDepositAddress(data: {
    currencyId: string;
    currency: string;
  }): Observable<ISwapAddress> {
    return this.http.post<ISwapAddress>(`${this.apiUrl}/swap/create`, data);
  }
  getReceivingWallet(): Observable<ISwapReceivingAddress> {
    return this.http.get<ISwapReceivingAddress>(
      `${this.apiUrl}/swap/getAddress`
    );
  }
  getList(): Observable<ISwapListData[]> {
    return this.http.get<ISwapListData[]>(`${this.apiUrl}/swap/getall`);
  }
  resendVerificationEmail(): Observable<{
    statusCode: number;
    message: string;
  }> {
    return this.http.get<{ statusCode: number; message: string }>(
      `${this.apiUrl}/swap/resendswapaddressconfirmationrequest`
    );
  }

  submitReceivingWallet(
    data: ISwapReceivingAddress
  ): Observable<ISwapReceivingAddress> {
    return this.http.post<ISwapReceivingAddress>(
      `${this.apiUrl}/swap/receiving/address`,
      data
    );
  }

  /*  editCustomerUSD(formData: FormData): Observable<boolean> {
    return this.http.get<ISwapAddress>(`${this.apiUrl}`);
  } */
}

import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { APP_ROUTES, DEPOSIT_INTEREST_SUCCESS } from 'src/app/app.constants';
import { RateType } from 'src/app/core/enums/rateTypeEnum';
import { DepositPlan, ICreateDepositPlan } from 'src/app/core/models/deposit-interest/deposit-interest';
import { DepositInterestService } from '../service/deposit-interest.service';

@Component({
  selector: 'cashaa-deposit-interest-confirm',
  templateUrl: './deposit-interest-confirm.component.html',
  styleUrls: ['./deposit-interest-confirm.component.scss']
})
export class DepositInterestConfirmComponent implements OnInit {
  loading: boolean = false;
  conditionsAccepted: boolean = false;
  confirmData: ICreateDepositPlan;
  @Input() set createPlanData(data: ICreateDepositPlan) {
    if (data) {
      this.confirmData = data;
      this.setEndDate(data);
    }
  }
  currentDate = new Date();
  endDate: Date = new Date();
  rateType = RateType;
  constructor(private depositService: DepositInterestService, private toasterService: ToastrService, private router: Router) { }
  ngOnInit(): void {
  }

  submit(): void {
    this.loading = true;
    const depositPlan = new DepositPlan(Number(this.confirmData.amount.replace(/\,/gi, "")), this.confirmData.depositData.id, this.confirmData.depositData.rate, this.confirmData.isAutoRenew, Number(this.confirmData.interestOptType));
    this.depositService.createDepositPlan(depositPlan).subscribe(data => {
      if (data.statusCode === 200) {
        this.loading = false;
        this.toasterService.success(DEPOSIT_INTEREST_SUCCESS);
        this.router.navigateByUrl(APP_ROUTES.ACTIVITY_BASE);
      }
    }, (err: HttpErrorResponse) => {
      this.loading = false;
      this.toasterService.error(err.error.message);
    })
  }

  setEndDate(data: ICreateDepositPlan): void {
    this.endDate.setDate(this.currentDate.getDate() + data.depositData.duration);
  }

}

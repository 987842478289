import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { ProfileRoutingModule } from "./profile-routing.module";
import { ProfileBusinessComponent } from "./profile-business/profile-business.component";
import { ProfilePersonalComponent } from "./profile-personal/profile-personal.component";
import { SharedModule } from "../shared/shared.module";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { MDBBootstrapModulesPro } from "ng-uikit-pro-standard";
import { ProfileHomeComponent } from './profile-home/profile-home.component';
import { ProfileBusinessEditComponent } from './profile-business-edit/profile-business-edit.component';


@NgModule({
  declarations: [ProfileBusinessComponent, ProfilePersonalComponent, ProfileHomeComponent, ProfileBusinessEditComponent],
  imports: [
    CommonModule,
    ProfileRoutingModule,
    SharedModule,
    ReactiveFormsModule,
    FormsModule,
    MDBBootstrapModulesPro.forRoot()
  ]
})
export class ProfileModule { }

<div class="modal-header position-relative">
  <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
    <span aria-hidden="true">×</span>
  </button>
  <h4 class="modal-title w-100 text-primary font-weight-bolder" id="myModalLabel">
    Add your bank account
  </h4>
</div>
<div class="modal-body">
  <form [formGroup]="recipientAddForm" class="position-relative">
    <div class="row mb-4">
      <div class="form-check col-6">
        <input type="radio" formControlName="userType" class="form-check-input" id="personalAccount" name="userType"
          mdbInput value="4" />
        <label class="form-check-label" for="personalAccount">Personal</label>
      </div>
      <div class="form-check col-6">
        <input type="radio" formControlName="userType" class="form-check-input" id="businessAccount" name="userType"
          mdbInput value="5" />
        <label class="form-check-label" for="businessAccount">Business</label>
      </div>
    </div>

    <div class="mb-4">
      <cashaa-form-select-currency-symbol source="fiatCurrency" name="currency" [form]="recipientAddForm"
        [control]="recipientAddForm.controls.currency" placeholder="Select Currency"
        (changed)="listenToCurrencyChange($event)">
      </cashaa-form-select-currency-symbol>
    </div>
    <div *ngIf="!isCrypto">
      <div class="mb-4">
        <cashaa-form-input name="email" type="email" text="100" [form]="recipientAddForm"
          [control]="recipientAddForm.controls.email" disabled="true" placeholder="Their email (optional)">
        </cashaa-form-input>
      </div>

      <div class="mb-4">
        <cashaa-form-input name="fullName" type="text" text="100" [form]="recipientAddForm"
          [control]="recipientAddForm.controls.fullName" placeholder="Account Name" disabled="true">
        </cashaa-form-input>
      </div>

      <h5 class="font-weight-normal text-primary">Bank Details</h5>
      <hr class="mb-4" />

      <!-- Nav tabs -->
      <mdb-tabset #staticTabs [buttonClass]="'nav md-pills pills-primary mb-2'" class="mx-0 px-0" [justified]="true">
        <!--Panel 1-->
        <mdb-tab heading="Local Bank Account">
          <cashaa-form-input-sortcode name="sortCode" [form]="recipientAddForm"
            [control]="recipientAddForm.controls.sortCode" placeholder="Enter Sort Code" label="Uk Sort code">
          </cashaa-form-input-sortcode>
          <br />
          <cashaa-form-input name="accountNumber" type="text" [form]="recipientAddForm"
            [control]="recipientAddForm.controls.accountNumber" placeholder="Enter Account Number"
            label="Account Number">
          </cashaa-form-input>
        </mdb-tab>
        <!--Panel 2-->
        <mdb-tab heading="IBAN">
          <cashaa-form-input name="ibanOrSortCode" type="text" [form]="recipientAddForm"
            [control]="recipientAddForm.controls.ibanOrSortCode" placeholder="Enter iban Or Sort Code">
          </cashaa-form-input>
        </mdb-tab>
      </mdb-tabset>
    </div>
    <div *ngIf="isCrypto">
      <div class="mb-4">
        <cashaa-form-input name="cryptoAddress" type="text" [form]="recipientAddForm"
          [control]="recipientAddForm.controls.cryptoAddress" placeholder="Receiving Address"></cashaa-form-input>
      </div>
      <div class="mb-4">
        <cashaa-form-input name="cryptoEmail" type="email" [form]="recipientAddForm"
          [control]="recipientAddForm.controls.cryptoEmail" placeholder="Email"></cashaa-form-input>
      </div>
      <div class="mb-4">
        <cashaa-form-input name="cryptoPassword" type="text" [form]="recipientAddForm"
          [control]="recipientAddForm.controls.cryptoPassword" placeholder="2FA code"></cashaa-form-input>
      </div>
    </div>
    <div class="mt-4">
      <!-- [disabled]="isInProgress || recipientAddForm.invalid" -->
      <button mdbBtn (click)="onFormSubmit()" block="true" type="button"
        class="rounded-sm position-relative btn btn-primary waves-light btn-block" mdbWavesEffect>
        <i *ngIf="!isCrypto" class="fa-lock fas position-absolute"></i><img *ngIf="isCrypto"
          class="position-absolute locker-icon" src="../../../assets/media/locker.svg" />Submit
      </button>
    </div>
  </form>

  <!-- Progress bar -->
  <mdb-progress-bar *ngIf="isInProgress" class="progress primary-color-dark position-absolute" mode="indeterminate">
  </mdb-progress-bar>
</div>
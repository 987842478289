<cashaa-sidenav>
  <section class="py-5 pr-0 pr-lg-4 row">
    <div class="col-12 col-lg-6">
      <cashaa-trade-buy-sell-tabs></cashaa-trade-buy-sell-tabs>
    </div>
    <div class="col-12 col-lg-6">
      <cashaa-trade-buy-sell-confirmation></cashaa-trade-buy-sell-confirmation>
    </div>
  </section>
</cashaa-sidenav>

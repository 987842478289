import { MDBBootstrapModulesPro } from "ng-uikit-pro-standard";
import { FormsModule } from "@angular/forms";
import { ReactiveFormsModule } from "@angular/forms";
import { SharedModule } from "./../shared/shared.module";
import { RouterModule } from "@angular/router";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { InviteHomeComponent } from "./invite-home/invite-home.component";
import { InviteHomeLinkComponent } from "./invite-home-link/invite-home-link.component";
import { InviteShareLinkComponent } from "./invite-share-link/invite-share-link.component";
import { InviteHomeEmailComponent } from "./invite-home-email/invite-home-email.component";
import { InviteHomeCompletedComponent } from "./invite-home-completed/invite-home-completed.component";
import { ToastrModule } from "ngx-toastr";
import { AuthGuardService } from "./../core/services/auth-guard/auth-guard.service";
@NgModule({
  declarations: [
    InviteHomeComponent,
    InviteHomeLinkComponent,
    InviteShareLinkComponent,
    InviteHomeEmailComponent,
    InviteHomeCompletedComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    ReactiveFormsModule,
    FormsModule,
    ToastrModule.forRoot(),
    MDBBootstrapModulesPro.forRoot(),
    RouterModule.forChild([
      {
        path: "invite",
        component: InviteHomeComponent,
        children: [
          {
            path: "link",
            component: InviteHomeLinkComponent,
            canActivate: [AuthGuardService],
          },
          {
            path: "email",
            component: InviteHomeEmailComponent,
            canActivate: [AuthGuardService],
          },
          {
            path: "done",
            component: InviteHomeCompletedComponent,
            canActivate: [AuthGuardService],
          },
        ],
      },
    ]),
  ],
})
export class InviteModule {}

import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthGuardService } from "./../core/services/auth-guard/auth-guard.service";
import { AddCustomerHomeComponent } from "./add-customer-home/add-customer-home.component";
import { AddCustomerComponent } from './add-customer/add-customer.component';

const routes: Routes = [
  {
    path: "add-customer",
    component: AddCustomerHomeComponent,
    canActivate: [AuthGuardService],
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})

export class CustomerRoutingModule { }

<!-- <p class="red-text mb-1 font-weight-normal" *ngIf="!noInput && amountControl.hasError('max')">
    Amount is higher than available balance
  </p>
  <p class="red-text mb-1 font-weight-normal" *ngIf="
      !noInput &&
      amountControl.value &&
      amountControl.hasError('min') &&
      (this.module === 'depositInterest') &&
      amountControl.value != 0 &&
      !amountControl.hasError('max')
    ">
    {{ controlCurrencyVal }} amount should not be less than {{this.balanceDetail.minFDAmount}}
  </p> -->
<section class="d-flex" [ngClass]="{ 'sm-height': noInput }">
  <div class="btn-group" [ngClass]="{ 'sm-width': noInput }" mdbDropdown [isDisabled]="disable ? true : false">
    <a mdbDropdownToggle mdbBtn class="dropdown-toggle dropdown black-text text-left d-flex"
       [ngClass]="{ 'sm-a-align': noInput ,'disable-background':disable && noInput}" type="button">
      <span class="sm-placeholder" *ngIf="noInput && !this.selectedPlan">Select term</span>
      <ng-template *ngIf="selectedPlan" [ngTemplateOutlet]="information"></ng-template>

      <mdb-icon [ngClass]="{ 'sm-mdb-icon-right': noInput }" class="text-white pl-2 mdb-icon" fas
                [icon]="'chevron-' + (isDropdownOpened ? 'up' : 'down')">
      </mdb-icon>
    </a>
    <div *ngIf="send" class="dropdown-menu m-0 p-3" [ngClass]="{ 'sm-width-div': noInput }">
      <ng-template [ngTemplateOutlet]="currencyList"></ng-template>
    </div>
  </div>
</section>

<!-- for selected currency in drodown -->
<ng-template #information>
  <!-- <div *ngIf="selectedPlan">
    <img [ngClass]="{ 'sm-img-width': noInput, 'mr-1 ml-2': noInput, 'mr-3': !noInput }"
      [src]="'../../../../assets/icons/' + selectedPlan?.symbol + '.png'" class="icon" />
  </div> -->
  <!-- <h6 class="heading mb-0" [ngClass]="send ? 'mr-3' : 'recieved-panel'"
    [ngClass]="{'heading pl-2': noInput}">
    {{ selectedPlan?.rate }}%
  </h6>
  <p *ngIf="noInput" class="ml-2 mb-0 small">{{ selectedPlan?.planName }}</p> -->
  <div *ngIf="noInput" class="d-flex selected-plan">
    <div class="rate-circle" style="left:5px;">
      <small class="font-weight-bolder">{{ selectedPlan?.rate }}%</small>
    </div>
    <div class="ml-5 mb-0 desc">
      <small class="heading mb-0">{{ selectedPlan?.planName }} </small>
      <div class="description" style="font-size:10px">{{ selectedPlan?.description }}</div>
    </div>
  </div>

</ng-template>
<!-- currency list in dropdown -->
<ng-template #currencyList>
  <!-- <div class="d-flex align-items-center">
    <mdb-icon fas icon="search" aria-hidden="true"></mdb-icon>
    <input class="form-control form-control-sm ml-3 w-75 search-input" type="text"
      placeholder="Plan Name" aria-label="Search" mdbInput [(ngModel)]="searchTerm"
      (input)="onSearch($event)" (click)="$event.stopPropagation()" />
  </div> -->
  
  <div *ngIf="dropdownInfoList?.length > 0">
    <!-- <hr /> -->
    <p class="small">Select term</p>
    <ng-container *ngFor="let currency of dropdownInfoList">
     
      <div *ngIf="currency?.clientType == 3  else other " class="d-flex mb-3 dropdown-item border-bottom custom_mobapp_plan" (click)="onPlanSelection(currency)">       
        <div class="rate-circle">
          <small class="font-weight-bolder">{{ currency?.rate }}%</small>
        </div>
        <div class="ml-5 mb-2 desc">
          <p class="spcl_offer">Special limited time offer</p>
          <small class="heading mb-0 mnth_term">{{ currency?.planName }} </small>
          <p class="plan_cndtion">Assets will be held for <b>3 months</b>, and will<br>earn 24% APR, interest paid monthly.</p>
          <!-- <p [innerHTML]="currency?.description" class="plan_cndtion"></p> -->
          <p *ngIf="currency?.clientType == 3" class="plan_cndtion_two">* This plan is only available for Cashaa Mobile App</p>
          <!-- <div class="description">{{ currency?.description }}</div> -->
        </div>
      </div>
      <ng-template #other>
        <div class="d-flex mb-3 dropdown-item border-bottom" (click)="onPlanSelection(currency)">
          <div class="rate-circle">
            <small class="font-weight-bolder">{{ currency?.rate }}%</small>
          </div>
          <div class="ml-5 mb-2 desc">
            <small class="heading mb-0">{{ currency?.planName }} </small>
            <div class="description">{{ currency?.description }}</div>
          </div>
        </div>
      </ng-template>

      <!-- </div> -->
    </ng-container>
  </div>
</ng-template>

<section class="px-5 w-100 d-flex justify-content-center flex-column mx-auto">
  <!-- <h3 class="text-primary-dark font-weight-bolder mb-3 text-center">
    Confirm your transaction
  </h3> -->
  <div class="details px-4 py-2">
    <div class="text-center" [hidden]="!loading">
      <mdb-spinner spinnerColor="blue"></mdb-spinner>
    </div>
    <h3 *ngIf="isVerified" class="mb-4 text-center font-weight-bolder">All done!</h3>
    <mdb-icon *ngIf="isVerified" far icon="check-circle" class="text-center green-text mb-4" style="display: block;"
      size="10x"></mdb-icon>

    <p class="text-center mb-4 font-weight-bolder">
      You've Sent <b>{{ fiatFlowData?.senderAmount }}
        {{ fromcurrencyObject?.symbol }}</b> to <b>{{ fiatRecipientData?.name }}</b>
    </p>

    <p class="text-center mb-4 font-weight-bolder" *ngIf="userFeeMode==='InNative'">
      Fee Charged: <b>{{ fiatFlowData?.fees| number:getDecimalNumber(fromcurrencyObject?.symbol) }}
        {{ fromcurrencyObject?.symbol }}</b>
    </p>
    <p class="text-center mb-4 font-weight-bolder" *ngIf="userFeeMode==='InCashaa'">
      Fee Charged: {{ fiatFlowData?.fees | number:getDecimalNumber(fromcurrencyObject?.symbol) }} CAS
    </p>
    <p class="text-center mb-4 font-weight-bolder sm-custom-msg" *ngIf="!isVerified">
      Please verify the transaction. Verification mail has been sent to you email id.
    </p>

    <div class="px-4">
      <button *ngIf="isVerified" mdbBtn block="true" type="button"
        class="rounded-sm position-relative btn btn-secondary sm-custom-sec waves-effect waves-light btn-block"
        mdbWavesEffect (click)="onContinue()">
        Got it
      </button>
    </div>
  </div>
  <div class="mt-3" style="width: 315px;">
    <span class="font-weight-normal text-dark">Contact <a [routerLink]="" (click)="helpDeskSend()"
        class="p-0 sm-help-color">help desk</a> for any question or
      query.</span>
  </div>
</section>

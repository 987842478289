<mdb-card class="balance-card cursor-pointer" (click)="goToAccount()">
    <mdb-card-body class="p-3 p-sm-3 wrapper-box position-relative">
        <!-- <input type="checkbox" class="checkbox_1 position-absolute " (change)="selectCurrency($event)"> -->
        <mdb-checkbox [default]="true" class="balance-card checkbox_1 position-absolute"
            (change)="selectCurrency($event)"></mdb-checkbox>

        <div class="row">
            <div class="col-11">
                <div class="d-flex align-items-center mb-3 pr-0">

                    <img class="icon" [src]="'../../../../assets/icons/' + allCurrencyInfo.currencyType + '.png'" />
                    <p *ngIf="allCurrencyInfo.currencyType !== 'DOG' && allCurrencyInfo.currencyType !== 'USD'" class="ml-2 mb-0 text-gray font-weight-bolder">
                        {{ allCurrencyInfo.currencyType }} <i *ngIf="allCurrencyInfo.onhold" class="d-block fa fa-lock text-hold" [mdbPopover]="template" triggers="mouseenter:mouseleave"
                        placement="bottom"  (mouseover)="open()" 
                        >&nbsp;{{allCurrencyInfo.onhold}}</i>
                    </p>
                    
                    <p *ngIf="allCurrencyInfo.currencyType === 'DOG'" class="ml-2 mb-0 text-gray font-weight-bolder">
                        DOGE <i *ngIf="allCurrencyInfo.onhold" class="d-block fa fa-lock text-hold">&nbsp;{{allCurrencyInfo.onhold}}</i>
                    </p>
                    <p *ngIf="allCurrencyInfo.currencyType === 'USD'" class="ml-2 mb-0 text-gray font-weight-bolder">
                        USD <i *ngIf="allCurrencyInfo.onhold" class="d-block fa fa-lock text-hold">&nbsp;{{allCurrencyInfo.onhold}}</i>
                    </p>
                </div>
                
                <p class="text-gray mb-2">Available balance</p>
                <h6 *ngIf="allCurrencyInfo.currencynature === 2 || getCurrencyName(allCurrencyInfo.currencyType) === 'USDT' || getCurrencyName(allCurrencyInfo.currencyType) === 'USDC'; else nonStableCrypto"
                    class="text-dark font-weight-bolder">
                    {{ allCurrencyInfo.available | number: "0.0-2" }} {{ getCurrencyName(allCurrencyInfo.currencyType) }}
                </h6>
                <ng-template #nonStableCrypto>
                    <h6 class="text-dark font-weight-bolder">
                        {{ allCurrencyInfo.available | number: "0.0-4" }} {{ getCurrencyName(allCurrencyInfo.currencyType) }}
                    </h6>
                </ng-template>
               

                <!-- <a *ngIf="isEarnAndFixedDepositActive(); else US" (click)="openEarnPlan();
                $event.stopPropagation()" class="offer-sec text-center" [ngClass]="{'hide': isHide()}">
          Earn up to {{rate}}%
        </a> -->
                <!-- <ng-template #US>
                    <a *ngIf="userType === ''" (click)="openEarnPlan();$event.stopPropagation()" class="offer-sec text-center" [ngClass]="{'hide': isHide()}">
        Earn up to {{rate}}%
      </a>
                </ng-template> -->
            </div>
            <div class="col-1 d-flex align-items-center justify-content-center text-primary-dark p-0">
                <mdb-icon fas icon="chevron-right" class="arrow"></mdb-icon>
            </div>
        </div>
       
        <div class="d-flex justify-content-between">
            <a *ngIf="allCurrencyInfo.isFDActive && allCurrencyInfo.earnUpTo > 0" (click)="openEarnPlan();$event.stopPropagation()"
                class="offer-sec text-center">
                Earn up to {{allCurrencyInfo.earnUpTo}}%
            </a>
            <a *ngIf="allCurrencyInfo.isBorrowActive" (click)="openBorrowFlow();$event.stopPropagation()"
                class="text-center borrow-btn">
                Borrow at {{allCurrencyInfo.borrowAt}}%
            </a>
        </div>
    </mdb-card-body>
</mdb-card>
<ng-template #template>
    <div style="min-width: 125px;z-index: 99999;" class="mb-1 px-1">
        <div class="font-weight-bold my-1">Credit Line</div>
  <div class="my-1">FD Locked</div>
  <i class="text-hold fa fa-lock"><span class="ml-1">&nbsp;${{lockedInfo?.totalFdLocked}}</span></i>
  <div *ngIf="lockedInfo?.totalCreditLocked > 0">Credit</div>
  <i *ngIf="lockedInfo?.totalCreditLocked > 0" class="text-hold fa fa-lock"><span class="ml-1">&nbsp;${{lockedInfo.totalCreditLocked}}</span></i>
  <div *ngIf="lockedInfo?.totalRollingReserve > 0">Rolling Reserve</div>
  <i *ngIf="lockedInfo?.totalRollingReserve > 0" class="text-hold fa fa-lock"><span class="ml-1">&nbsp;${{lockedInfo.totalRollingReserve}}</span></i>
  <div *ngIf="lockedInfo?.totalStackedRollingReserve > 0">Staking Rolling Reserve</div>
  <i *ngIf="lockedInfo?.totalStackedRollingReserve > 0" class="text-hold fa fa-lock"><span class="ml-1">&nbsp;${{lockedInfo.totalStackedRollingReserve}}</span></i>
    </div>
</ng-template>

<button
  style="height: 80px;visibility: hidden;">
</button>
<!-- Nav tabs -->
<mdb-tabset
  [buttonClass]="'classic-tabs'"
  [contentClass]="'card'"
  class="classic-tabs"
>
  <mdb-tab heading="Received History">
    <div class="row">
      <div class="col-12">
        <div
          class="d-flex justify-content-between mx-5 my-4 bdr"
          *ngFor="let item of acceptedList"
        >
          <div class="d-flex">
            <img
              class="icon-img bdr-right p-3"
              [src]="'../../../assets/images/deposit_' + item.currency + '.svg'"
            />
            <div class="text-center bdr-right p-3">
              <p class="font-weight-bolder mb-1">
                {{ item.depositDate | date: "MMM" }}
              </p>
              <p class="font-weight-normal mb-0">
                {{ item.depositDate | date: "dd" }}
              </p>
            </div>
            <div class="bdr-right p-3">
              <p class="font-weight-bolder mb-1">
                Received {{ item.currency }}
              </p>
              <p class="font-weight-normal mb-0">{{ item.transactionId }}</p>
            </div>
          </div>
          <p class="font-weight-bolder mb-1 p-3">
            +{{ item.amount | number: "0.2" }}
          </p>
        </div>
      </div>
    </div>
  </mdb-tab>
  <mdb-tab heading="Request History">
    <div class="row">
      <div class="col-12">
        <div
          class="d-flex justify-content-between mx-5 my-4 bdr"
          *ngFor="let item of pendingList"
        >
          <div class="d-flex">
            <img
              class="icon-img bdr-right p-3"
              [src]="'../../../assets/images/deposit_' + item.currency + '.svg'"
            />
            <div class="text-center bdr-right p-3">
              <p class="font-weight-bolder mb-1">
                {{ item.depositDate | date: "MMM" }}
              </p>
              <p class="font-weight-normal mb-0">
                {{ item.depositDate | date: "dd" }}
              </p>
            </div>
            <div class="bdr-right p-3">
              <p class="font-weight-bolder mb-1">
                Deposited {{ item.currency }}
              </p>
              <p class="font-weight-normal mb-0">To {{ item.bankName }}</p>
            </div>
          </div>
          <p class="font-weight-bolder mb-1 p-3">
            +{{ item.amount | number: "0.2" }}
          </p>
        </div>
      </div>
    </div>
  </mdb-tab>
</mdb-tabset>

<!-- amount: 101
bankName: "Enumis"
currency: "GBP"
depositDate: "2020-09-05T00:00:00"
remark: null
serviceFee: 1
status: 1
transactionId: "12321" -->

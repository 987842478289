import {
  IFiatInOutTransferFees,
  ITransferFeesByCurrencyModel,
} from "./../../models/transfer-types";
import { IUserFeeMode } from "./../../models/user-fee-mode";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { ICountryList } from "../../models/country";
import { IDirectorRoleList } from "../../models/director-role";
import { HttpClient } from "@angular/common/http";
import { Observable, BehaviorSubject, Subject } from "rxjs";
import { ICurrencyList, ICurrency } from "../../models/currency";
import { CurrencyType } from "../../enums/currencyType";
import { ITransferFeesList, ITransferFees } from "../../models/transfer-types";
import { IsubscriptionPlansList } from "../../models/subsciption";
import { UserType } from "../../enums/userType";
import { IWithdrawAddress } from "../../models/withdraw-address";
import { IBusinessCategory } from "../../models/business-category";
import { IBusinessSubcategory } from "../../models/business-subcategory";
import { RateDto } from "../../models/rate";
import { IHelpdeskCategory } from "../../models/helpdesk-category";
import { IFile } from "../../models/Lerex/enum-load";
import { IUserInterestChoice } from "../../models/interest-mode";

@Injectable({
  providedIn: "root",
})
export class DomainService {
  /** Api url */
  private apiUrl = environment.api;

  private fiatCurrency = new BehaviorSubject<ICurrency[]>(undefined);

  public fiatCurrencies : ICurrency[];

  private uploadFile = new Subject<IFile>();


  constructor(private http: HttpClient) {}

  /**
   * Get List of available countries
   */
  getCountriesList(): Observable<ICountryList> {
    return this.http.get<ICountryList>(`${this.apiUrl}/countries`);
  }

  /**
   * Get List of available currenies
   */
  getCurrenciesList(currencytype: CurrencyType): Observable<ICurrencyList> {
    const currencyroute =
      currencytype === CurrencyType.all
        ? "/getall"
        : currencytype === CurrencyType.Fiat
        ? "/fiat"
        : "/crypto";
    return this.http.get<ICurrencyList>(
      `${this.apiUrl}/currencies${currencyroute}`
    );
  }

  /**
   * Get List of available countries
   */
  getTransferTypeList(): Observable<ITransferFeesList> {
    return this.http.get<ITransferFeesList>(`${this.apiUrl}/transferfees`);
  }
  /**
   * Get Network fee type
   *
   * @param {ITransferFeesByCurrencyModel} data
   * @returns {Observable<ITransferFeesList>}
   *
   * @memberOf DomainService
   */
  getTransferFeeByCurrecny(
    data: ITransferFeesByCurrencyModel
  ): Observable<IFiatInOutTransferFees> {
    // tslint:disable-next-line: max-line-length
    return this.http.get<IFiatInOutTransferFees>(
      `${this.apiUrl}/transferfees/${data.currencyId}/${data.transferType}/${data.transactionAmount}/${data.isBroker}`
    );
  }
  getFeeByCurrencyId(id: string): Observable<ITransferFees> {
    return this.http.get<ITransferFees>(`${this.apiUrl}/transferfees/${id}`);
  }
  getPayCurrencyById(id: string): Observable<ICurrency> {
    return this.http.get<ICurrency>(`${this.apiUrl}/currencies/${id}`);
  }
  /** Get pay fee details */
  getPayFeeById(id: string): Observable<ITransferFees> {
    return this.http.get<ITransferFees>(`${this.apiUrl}/transferfees/${id}`);
  }

  /*** Get List of available subsciptions for business
   */
  getSubscription(usertype: UserType): Observable<IsubscriptionPlansList> {
    const route =
      usertype === UserType.Personal
        ? "/getpersonal"
        : usertype === UserType.Business
        ? "/getbusiness"
        : "/getall";
    return this.http.get<IsubscriptionPlansList>(
      `${this.apiUrl}/subscriptionplans${route}`
    );
  }

  getRoles(): Observable<IDirectorRoleList> {
    return this.http.get<IDirectorRoleList>(
      `${this.apiUrl}/kyc/getalldirectorroles`
    );
  }
  // get the list of user fee mode
  getUserFeeModeList(): Observable<IUserFeeMode[]> {
    return this.http.get<IUserFeeMode[]>(`${this.apiUrl}/feemodes`);
  }

  getUserInterestModeList(): Observable<IUserInterestChoice[]> {
    return this.http.get<IUserInterestChoice[]>(`${this.apiUrl}/userchoice/getDefault`);
  }

  getWithdrawAddressList(symbol: string): Observable<Array<IWithdrawAddress>> {
    return this.http.get<Array<IWithdrawAddress>>(
      `${this.apiUrl}/WithdrawAddress/${symbol}`
    );
  }

  getBusinessCategoryList(): Observable<Array<IBusinessCategory>> {
    return this.http.get<Array<IBusinessCategory>>(
      `${this.apiUrl}/users/getbusinesscategorylist`
    );
  }

  getBusinessSubcategoryList(
    categoryId: string
  ): Observable<IBusinessSubcategory[]> {
    return this.http.get<IBusinessSubcategory[]>(
      `${this.apiUrl}/users/${categoryId}`
    );
  }

  getHelpdeskCategoryList(): Observable<Array<IHelpdeskCategory>> {
    return this.http.get<Array<IHelpdeskCategory>>(
      `${this.apiUrl}/helpdesktickets/gethelpdeskcategories`
    );
  }

  getRate(from: string, to: string): Observable<RateDto> {
    return this.http.get<RateDto>(
      `${this.apiUrl}/currencies/rate?from=${from}&to=${to}`
    );
  }

  getINRRate(from: string, to: string): Observable<RateDto> {
    return this.http.get<RateDto>(
      `${this.apiUrl}/currencies/rate/${from}/${to}`
    );
  }

  emitFiatCurrency(fiatCurrency: ICurrency[]): void {
    this.fiatCurrencies = fiatCurrency;
    this.fiatCurrency.next(fiatCurrency);
  }

  getFiatCurrency(): Observable<ICurrency[]> {
    return this.fiatCurrency.asObservable();
  }

  emitKycUploadFile(data: IFile): void {
    this.uploadFile.next(data);
  }

  getKycUploadFile(): Observable<IFile> {
    return this.uploadFile.asObservable();
  }

  getCurrencyPairListById(currencyId) {
    console.log(currencyId);
    return this.http.get<any>(
      `${this.apiUrl}/currencies/get-pairs?currencyId=${currencyId}`
    );
  }
}

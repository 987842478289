export interface IResetPassword {
  email: string;
  token: string;
  newPassword: string;
  registerType: number;
}

export class ResetPassword {
  constructor(
    public email: string,
    public token: string,
    public newPassword: string,
    public registerType: number
  ) {}
}

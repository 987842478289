<section class="p-5 w-75 mx-auto">
  <h3 class="text-primary-dark font-weight-bolder mb-5 text-center">
    Review details of your transfer
  </h3>
  <div class="details px-4 py-2">
    <div class="d-flex justify-content-between mb-4">
      <p class="font-weight-normal text-gray">Transfer details</p>
      <p class="font-weight-normal text-primary-light link" (click)="onTransferDetailEdit()">
        <u>Edit</u>
      </p>
    </div>
    <div class="d-flex justify-content-between">
      <p class="font-weight-normal text-gray">You send</p>
      <h6 class="sm-bold-font text-primary">
        {{ convertToDecimal(fiatFlowData?.senderAmount,fromcurrencyObject?.symbol) }}
        {{ getCurrencyName(fromcurrencyObject?.symbol) }}
      </h6>
    </div>
    <div class="d-flex justify-content-between">
      <p class="font-weight-normal text-gray">Total fees (included)</p>
      <h6 *ngIf="userFeeMode==='InNative'" class=" text-primary">
        {{ convertToDecimal(fiatFlowData?.fees,fromcurrencyObject?.symbol) }}
        {{ getCurrencyName(fromcurrencyObject?.symbol) }}
      </h6>
      <h6 *ngIf="userFeeMode==='InCashaa'" class="sm-normal-font text-primary">
        {{ convertToDecimal(fiatFlowData?.fees,'CAS') }}
        <!-- {{ fromcurrencyObject?.symbol }} -->CAS
      </h6>

    </div>
    <div class="d-flex justify-content-between">
      <p class="font-weight-normal text-gray">amount we'll send</p>
      <h6 *ngIf="userFeeMode==='InNative'" class="text-primary">
        {{ convertToDecimal(fiatFlowData?.reciverAmount,fromcurrencyObject?.symbol)
        }}
        {{ getCurrencyName(tocurrencyObject?.symbol) }}
      </h6>
      <h6 *ngIf="userFeeMode==='InCashaa'" class="sm-normal-font text-primary">
        {{ convertToDecimal(fiatFlowData?.reciverAmount,fromcurrencyObject?.symbol) }}
        {{ getCurrencyName(tocurrencyObject?.symbol) }}
      </h6>
    </div>
    <div class="d-flex justify-content-between">
      <p class="font-weight-normal text-gray">
        {{ fiatRecipientData?.name }} gets
      </p>
      <h6 *ngIf="userFeeMode==='InNative'" class="sm-bold-font text-primary">
        {{ convertToDecimal(fiatFlowData?.reciverAmount,fromcurrencyObject?.symbol)
        }}
        {{ getCurrencyName(tocurrencyObject?.symbol) }}
      </h6>
      <h6 *ngIf="userFeeMode==='InCashaa'" class="sm-bold-font text-primary">
        {{ convertToDecimal(fiatFlowData?.reciverAmount,fromcurrencyObject?.symbol) }}
        {{ getCurrencyName(tocurrencyObject?.symbol) }}
      </h6>
    </div>
    <hr />

    <div class="d-flex justify-content-between mb-4">
      <p class="font-weight-normal text-gray">Recipient details</p>
      <p class="font-weight-normal text-primary-light link" (click)="onRecipientDetailEdit()">
        <u>Change</u>
      </p>
    </div>
    <div class="d-flex justify-content-between">
      <p *ngIf="
          fromcurrencyObject?.symbol === 'EUR' ||
          fromcurrencyObject?.symbol === 'GBP'
        " class="font-weight-normal text-gray">
        Name
      </p>
      <p *ngIf="
          fromcurrencyObject?.symbol !== 'EUR' &&
          fromcurrencyObject?.symbol !== 'GBP'
        " class="font-weight-normal text-gray">
        Label
      </p>
      <h6 class="sm-normal-font text-primary">
        {{ fiatRecipientData?.name }}
      </h6>
    </div>
    <div class="d-flex justify-content-between">
      <p *ngIf="fiatRecipientData?.sortCode &&
          fromcurrencyObject?.symbol === 'EUR' ||
          fromcurrencyObject?.symbol === 'GBP'
        " class="font-weight-normal text-gray">
        Swift or BIC Code
      </p>

      <h6 class="sm-normal-font text-primary" *ngIf="
          fiatRecipientData?.sortCode &&
          (fromcurrencyObject?.symbol === 'EUR' ||
            fromcurrencyObject?.symbol === 'GBP')
        ">
        {{ fiatRecipientData?.sortCode }}
      </h6>
    </div>
    <div class="d-flex justify-content-between">
      <p *ngIf="
          fromcurrencyObject?.symbol === 'EUR' ||
          fromcurrencyObject?.symbol === 'GBP' ||
          fromcurrencyObject?.symbol === 'AUSD' ||
          fromcurrencyObject?.symbol === 'USD'
        " class="font-weight-normal text-gray">
        Account number
      </p>
      <p *ngIf="
          fromcurrencyObject?.symbol !== 'EUR' &&
          fromcurrencyObject?.symbol !== 'GBP' &&
          fromcurrencyObject?.symbol !== 'AUSD' &&
          fromcurrencyObject?.symbol !== 'USD'
        " class="font-weight-normal text-gray">
        Address
      </p>
      <h6 class="sm-normal-font text-primary pl-2" class="sm-tooltip text-primary font-weight-normal" mdbTooltip={{fiatRecipientData?.accountNumber}}>
        {{ fiatRecipientData?.accountNumber.substr(0, 4) }} ... {{fiatRecipientData?.accountNumber.substr(fiatRecipientData?.accountNumber.length -5,
          fiatRecipientData?.accountNumber.length-1)}}
      </h6>
    </div>
    <div *ngIf="this.isMemoActive || fiatRecipientData?.memo" class="d-flex justify-content-between">
      <p class="font-weight-normal text-gray">{{getLabel()}}</p>
      <h6 class="sm-normal-font text-primary">
        {{ this.isMemoActive ? this.memo : fiatRecipientData?.memo}}
      </h6>
    </div>
  </div>

  <div class="mb-4 mt-4" *ngIf="getMemoReferenceValid()">
    <cashaa-form-input name="memo" [form]="form" [control]="form.controls.memo" type="text" placeholder='Memo'
      label='Memo'>
    </cashaa-form-input>
  </div>

  <div class="conditions d-flex mt-4 p-3 mb-4">
    <mdb-checkbox [(ngModel)]="conditionsAccepted">
      <span class="font-weight-normal">I accept the
        <a target="_blank" href="https://cashaa.com/term-condition" style="padding: 0px;">Terms of Use</a></span>
    </mdb-checkbox>
  </div>

  <button [disabled]="!conditionsAccepted || loading || (getMemoReferenceValid() && form.invalid)" mdbBtn block="true"
    type="button"
    class="w-100 rounded-sm position-relative btn btn-secondary sm-custom-sec waves-effect waves-light btn-block"
    mdbWavesEffect (click)="onFormSubmit()">
    <span *ngIf="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
    Confirm and Send
  </button>
</section>
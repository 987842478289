<form [formGroup]="form" class="my-4">
  <div class="p-4 text-center" [hidden]="!loader">
    <mdb-spinner spinnerColor="blue"></mdb-spinner>
  </div>
  <div formArrayName="directorKycdetailsList" *ngFor="let item of directorKycdetailsList.controls; let i = index">
    <div class="row mb-4 position-relative">
      <div class="form-check col-3">

        <div [formGroupName]="i">

          <input type="hidden" class="form-check-input" formControlName="id">
        </div>
        <div [formGroupName]="i">
          <cashaa-form-input name="firstName" type="firstName" [form]="item" [control]="item.get('firstName')"
            placeholder="Enter First Name" label="First Name">
          </cashaa-form-input>
        </div>
      </div>
      <div class="form-check col-3">
        <div [formGroupName]="i">
          <cashaa-form-input name="lastName" type="lastName" [form]="item" placeholder="Enter Last Name"
            [control]="item.get('lastName')" label="Last Name">
          </cashaa-form-input>
        </div>
      </div>

      <div class="form-check col-3">
        <div [formGroupName]="i">
          <label class="heading">Date Of Birth</label>
          <mdb-date-picker [selDate]="item?.controls['dateOfBirth'].value" [inline]="true" name="dateOfBirth"
            [options]="myDatePickerOptions" [placeholder]="'Enter Date Of Birth'"
            (dateChanged)="setFormControl($event,i)">
          </mdb-date-picker>
        </div>
      </div>

      <div class="form-check col-3">
        <div [formGroupName]="i">
          <cashaa-form-input name="email" [control]="item.get('email')" type="email" [form]="item"
            placeholder="Enter Email" label="Email">
          </cashaa-form-input>
        </div>
      </div>
      <div class="form-check col-3 mt-3">
        <div [formGroupName]="i">
          <cashaa-form-input name="phone" type="phone" [control]="item.get('phone')" [form]="item"
            placeholder="Enter Phone" label="Phone">
          </cashaa-form-input>
        </div>
      </div>
      <div class="form-check col-3 mt-3">
        <div [formGroupName]="i">
          <cashaa-form-select [form]="item" [control]="item.get('type')" name="type" label="Contact Type"
            placeholder="Select Contact Type" [options]="orgType"></cashaa-form-select>
        </div>
      </div>

      <div class="form-check col-3 mt-3 pr-0">
        <div [formGroupName]="i">
          <cashaa-form-input name="percentageShares" type="percentageShares" [form]="item"
            [control]="item.get('percentageShares')" placeholder="Enter Company share in percentage"
            label="Company share in percentage">
          </cashaa-form-input>
        </div>
      </div>
      <div class="form-check col-3 mt-3">
        <div [formGroupName]="i">
          <div>
            <label class="heading">is the person with significant control?
            </label>
          </div>
          <div class="mb-4 d-flex">
            <div class="form-check mr-3 pl-0">
              <input type="radio" value="true" formControlName="{{'isMainApplicant'+i}}" class="form-check-input"
                id="{{ 'radiobtn0-' + i }}" name="{{'isMainApplicant'+i}}" mdbInput value="true" />
              <label class="form-check-label font-weight-bolder" for="{{ 'radiobtn0-' + i }}">True</label>
            </div>
            <div class="form-check">
              <input type="radio" value="false" class="form-check-input" id="{{ 'radiobtn1-' + i }}"
                formControlName="{{'isMainApplicant'+i}}" name="{{'isMainApplicant'+i}}" mdbInput value="false" />
              <label class="form-check-label font-weight-bolder" for="{{ 'radiobtn1-' + i }}">False</label>
            </div>
          </div>
        </div>
      </div>


      <div class="form-check col-3 mt-3">
        <div [formGroupName]="i">
          <cashaa-form-input name="homeAddressLine1" type="homeAddressLine1" [form]="item"
            [control]="item.get('homeAddressLine1')" placeholder="Enter Address Line 1" label="Address Line 1">
          </cashaa-form-input>
        </div>
      </div>
      <div class="form-check col-3 mt-3">
        <div [formGroupName]="i">
          <cashaa-form-input name="homeAddressCountryCode" type="homeAddressCountryCode" [form]="item"
            [control]="item.get('homeAddressCountryCode')" placeholder="Enter Country Code" label="Country Code"
            infoMesg="UK/US/EU">
          </cashaa-form-input>
        </div>
      </div>

      <div class="form-check col-3 mt-3">
        <div [formGroupName]="i">
          <cashaa-form-input name="homeAddressPostCode" type="homeAddressPostCode" [form]="item"
            [control]="item.get('homeAddressPostCode')" placeholder="Enter Zip / Postal Code" label="Zip / Postal Code">
          </cashaa-form-input>
        </div>
      </div>

      <div class="form-check col-3 mt-3">
        <div [formGroupName]="i">
          <cashaa-form-input name="homeAddressCity" type="homeAddressCity" [form]="item" placeholder="Enter City"
            [control]="item.get('homeAddressCity')" label="City">
          </cashaa-form-input>
        </div>
      </div>

      <div class="position-absolute icons pointer" *ngIf="i > 0">
        <mdb-icon fas icon="times" size="2x" (click)="removeItem(i)"></mdb-icon>
      </div>
    </div>

    <hr style="margin: 40px 0 40px 0;">
  </div>



  <div class="m-auto">
    <div class="ml-2 mt-4 pb-2">
      <p (click)="addItem()"
        [ngClass]="{ 'disableAddItem':this.directorKycdetailsList.value.length > 9 || this.form.invalid}"
        class="pointer font-weight-bolder text-primary-dark m-auto my-4" style="width: fit-content;">
        <span class="heading"><U>Add another +</U></span>
      </p>
    </div>
    <label *ngIf="errorMessage || isEmailDuplicate()" class="font-weight-normal red-text ml-1">{{errorMessage}}</label>
    <label *ngIf="errorMessage2" class="font-weight-normal red-text ml-1">{{errorMessage2}}</label>
    <button mdbBtn (click)="onFormSubmit()" block="true" type="button"
      class="w-25 rounded-sm position-relative btn btn_color waves-effect waves-light btn-block " mdbWavesEffect
      [disabled]="form.invalid || isEmailDuplicate()">
      Submit
    </button>
  </div>
</form>
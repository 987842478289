import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { ReceiveFundComponent } from "./receive-fund/receive-fund.component";
import { AuthGuardService } from "./../core/services/auth-guard/auth-guard.service";

const routes: Routes = [
  {
    path: "receive",
    canActivate: [AuthGuardService],
    children: [
      {
        path: "fund",
        component: ReceiveFundComponent,
        canActivate: [AuthGuardService],
      },
    ],
  },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ReceiveRoutingModule { }

import { KycService } from "./../services/kyc.service";
import {
  ICreateBusinessKYC,
  IStakeholders,
} from "./../../core/models/create-business-kyc";
import { MdbStepperComponent } from "ng-uikit-pro-standard";
import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { AccountUpgradeService } from "src/app/account-upgrade/services/account-upgrade.service";
import { UserSubscriptionPlan } from "src/app/core/models/account-upgrade/subscription-plan-response";
import { SUBSCRIPTION_BUSINESS_PLAN } from "src/app/app.constants";

@Component({
  selector: "cashaa-kyc-business",
  templateUrl: "./kyc-business.component.html",
  styleUrls: ["./kyc-business.component.scss"],
})
export class KycBusinessComponent implements OnInit {
  @ViewChild("stepper") stepper: MdbStepperComponent;

  kycCompleteStatus: boolean;

  data: ICreateBusinessKYC;

  completed = false;

  isVerified = false;

  loading = false;

  informationData: ICreateBusinessKYC;

  directorData: IStakeholders[];
  plan: string;

  businessId: string;

  isPaymentTabActive: boolean = false;

  constructor(
    private kycService: KycService,
    private activatedRoute: ActivatedRoute,
    private upgardeService: AccountUpgradeService
  ) { }

  ngOnInit(): void {
    this.getKycDetail();
    this.loading = true;
    this.upgardeService.usdStatus().subscribe(
      (flag) => {
        if (flag.status === 7) {
          this.kycCompleteStatus = true;
        }
        this.loading = false;
      },
      (err) => {
        this.loading = false;
      }
    );
    this.loading = true;
    // this.upgardeService.getUserActionSubscriptionPlan().subscribe((data: UserSubscriptionPlan) => {
    //   if (data) {
    //     if (data.planName === SUBSCRIPTION_BUSINESS_PLAN.BASIC) {
    //       this.isPaymentTabActive = true;
    //     }
    //   }
    //   this.loading = false;
    // },
    //   (err) => {
    //     this.loading = false;
    //   });
  }

  getKycDetail(): void {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.plan = params["plan"];
    });
  }

  /**
   * on information continue
   */
  onInformationContinue(data: ICreateBusinessKYC): void {
    this.informationData = data;
    this.stepper.next();
  }

  onDirectorContinue(data: ICreateBusinessKYC): void {
    this.informationData = data;
    this.directorData = data.stakeholdersList;
    this.stepper.next();
  }

  onChecklistContinue(businessId: string): void {
    this.businessId = businessId;
    this.stepper.next();
  }
  onUploadDirectorDocContinue(): void {
    this.stepper.next();
  }

  onCreateBusinessUserContinue(): void {
    this.stepper.next();
  }

  onStatusContinue(): void {
    this.stepper.next();
  }

  onApplicationFormContinue(): void {
    this.stepper.next();
  }

  isActive(): boolean {
    return this.plan === 'GBP_EUR' || this.plan === 'USD' || this.plan === 'Crypto';
  }
}

<!-- <strong>{{ledger | json }}</strong> -->
<section class="activity-box p-3">
  <div class="date p-2">
    Thursday, 12th
  </div>
  <div class="details mb-3">
    <mdb-accordion [multiple]="false" aria-multiselectable="false">
      <mdb-accordion-item class="no-collase">
        <mdb-accordion-item-head class="p-2" mdbWavesEffect>
          <mdb-icon fas icon="praying-hands" class="pull-left text"></mdb-icon>
          <div class="status pl-4 mb-2">
            To <b>Janina Lowisz</b>
            <br>
            Cancelled
          </div>
          <div class="amount pr-1 mb-2 text-right">
            <b>99.56 EUR</b><br>
            <span class="text-right">
              100EUR
            </span>
          </div>
        </mdb-accordion-item-head>
        <mdb-accordion-item-body class="border-top border-light px-4">
          <div class="container px-4 pt-2">
            <div class="row">
              <div class="col-sm">
                To <br> <b>Your USD account</b>
              </div>

              <div class="col-sm">
                Reference <br> <b>Invoice 21</b>
              </div>
              <div class="col-sm">

                <div class="text-right">
                  <i class="fa fa-ellipsis-h border p-2 m-3 rounded border-dark"></i></div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-sm">
                Transfer Number <br> <b>653654</b>
              </div>
            </div>
          </div>
        </mdb-accordion-item-body>
      </mdb-accordion-item>
    </mdb-accordion>
  </div>
</section>
<form [formGroup]="form" class="my-4">
  <div class="p-4 text-center" [hidden]="!loader">
    <mdb-spinner spinnerColor="blue"></mdb-spinner>
  </div>
  <div class="row mb-5">
    <h4 class="heading">
      Upload documents for company directors
    </h4>
  </div>
  <div class="row mb-2">
    <div class="col-12 col-sm-6">
      <h6 class="heading">Select Director's Name</h6>
    </div>
  </div>
  <div class="row mb-5">
    <div class="w-100 mx-2" *ngIf="ContactSelector">
      <cashaa-form-select (changed)="onContactchange($event)" [form]="form" [control]="form.controls.directors"
        name="directors" placeholder="Select director" [options]="ContactSelector"></cashaa-form-select>
    </div>
  </div>
</form>
<div class="p-4 text-center" [hidden]="!loader">
  <mdb-spinner spinnerColor="blue"></mdb-spinner>
</div>
<div *ngIf="isFileUploadValid">
  <h4 class="heading pt-5">
    Documents List
  </h4>
  <table mdbTable>
    <thead style="background: #E7ECF9;">
      <tr>
        <th style="width:50%"><h5 class="heading">Document Type</h5></th>
        <th style="width:50%;padding-left: 150px;"><h5 class="heading">Actions</h5></th>
      </tr>
    </thead>
    <tbody class="bg-white">
      <tr mdbTableCol *ngFor="let item of documentTypes; let i = index">
        <td style="padding-top:45px">
          <span class="content">{{item.apiData.key}}</span>
        </td>
        <td>
          <cashaa-form-input-file [docType]="item" (deleteFile)="onFileDelete($event, item)"
            [fileUploadStatus]="isFileUploadValid" [isFileUploadCompleted]="item.isDeletedValid ? true : false"
            [isOperationCompleted]="item.fileActionLoader ? true : false" [isDeletedActive]="true">
          </cashaa-form-input-file>
        </td>
      </tr>
    </tbody>
  </table>
  <div class="txt_centre pt-2">
    <button [disabled]="fileCount == 0" mdbBtn (click)="onSubmit()" block="true" type="button"
      class="w-25 rounded-sm position-relative btn btn_color waves-effect waves-light btn-block" mdbWavesEffect>
      Next
    </button>
  </div>
</div>
import { Injectable, OnChanges } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { ITradeChange } from "src/app/core/models/trade";

@Injectable({
  providedIn: "root",
})
export class TradeService {
  /** on trade change emitter */
  tradeChangeEmitter = new BehaviorSubject<ITradeChange | undefined>(undefined);

  constructor() {}

  /**
   * Get trade change emitter
   */
  getTradeChangeEmitter(): Observable<ITradeChange> {
    return this.tradeChangeEmitter.asObservable();
  }

  /**
   * emit trade change
   * @param change change
   */
  emitTradeChange(change: ITradeChange): void {
    this.tradeChangeEmitter.next(change);
  }
}

import { DatePipe } from "@angular/common";
import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { IMyOptions } from "ng-uikit-pro-standard";
import { ICountry, ICountryList } from "src/app/core/models/country";
import { ICurrency } from "src/app/core/models/currency";
import { IUSPersonal } from "src/app/core/models/kyc-us-personal/us-personal";
import { ValidatorUtility } from "src/app/core/utility/validator.utility";
import { KycService } from "../services/kyc.service";

@Component({
  selector: "cashaa-kyc-us-personal-information",
  templateUrl: "./kyc-us-personal-information.component.html",
  styleUrls: ["./kyc-us-personal-information.component.scss"],
})
export class KycUsPersonalInformationComponent implements OnInit {
  public form: FormGroup;
  @Output()
  continue = new EventEmitter<IUSPersonal>();
  selectedCountry: any;
  selectedCurrency: any;
  isDataLoaded: boolean = false;
  setCurrencyValue = "0adf9ec2-21b3-49a8-a983-a87f8edeee1c";
  setCountryValue = "";
  loading = false;
  myDatePickerOptions: IMyOptions = {
    minYear: 1900,
    maxYear: 2099,
  };
  constructor(
    private fb: FormBuilder,
    private kycService: KycService,
    private datePipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.loading = true;
    this.createForm();
    this.kycService.getKycUsPersonal().subscribe((data: IUSPersonal) => {
      if (data !== undefined) {
        this.isDataLoaded = true;
        this.loadFormData(data);
      }
    });

    setTimeout(() => {
      this.form.patchValue(
        {
          countryOfBirth: this.selectedCountry,
          currencyCode: this.selectedCurrency,
        },
        {
          emitEvent: false,
        }
      );
      this.loading = false;
    }, 2000);
  }

  loadFormData(data: IUSPersonal): void {
    if (data) {
      this.form.controls["firstName"].setValue(data.firstName);
      this.form.controls["middleName"].setValue(data.middleName);
      this.form.controls["lastName"].setValue(data.lastName);
      this.form.controls["birthDate"].setValue(data.birthDate);
      this.form.controls["occupation"].setValue(data.occupation);
      this.setCountryValue = data.countryOfBirth;
    }
  }

  createForm(): void {
    this.form = this.fb.group({
      firstName: ["", [ValidatorUtility.Required, Validators.maxLength(70)]],
      middleName: ["", [Validators.maxLength(35)]],
      lastName: ["", [Validators.maxLength(70), ValidatorUtility.Required]],
      birthDate: ["", ValidatorUtility.Required],
      occupation: ["", [Validators.maxLength(256)]],
      countryOfBirth: [{ value: "" }, Validators.required],
      currencyCode: [{ value: "", disabled: true }, ValidatorUtility.Required],
    });
  }

  onSubmit(data: IUSPersonal) {
    data.countryOfBirth = this.selectedCountry.value;
    data.currencyCode = this.selectedCurrency.label;
    this.continue.emit(data);
  }

  onCountryChange(data: { option: any; country: ICountry }): void {
    this.selectedCountry = data.option;
  }

  onCurrencyChange(currency: ICurrency): void {
    this.selectedCurrency = currency;
  }
}

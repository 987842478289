import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ActivityService } from "src/app/activity/services/activity.service";
import { ILedgerList } from "src/app/core/models/activity/recent-transaction-list";
import { ModalService } from "src/app/core/services/modal/modal.service";
import { AccountUpgradeService } from "../services/account-upgrade.service";

@Component({
  selector: "cashaa-staking-timeline",
  templateUrl: "./staking-timeline.component.html",
  styleUrls: ["./staking-timeline.component.scss"],
})
export class StakingTimelineComponent implements OnInit {
  ledger: ILedgerList;
  mondayList = [];
  isLoading: boolean;
  isWorldWide;
  isNormalStaking;
  usdNoKyc: boolean;
  isDotEnable;
  weeklyMondayDays = {
    1: "First",
    2: "Second",
    3: "Third",
    4: "Fourth",
    5: "Fifth",
    6: "Sixth",
    7: "Seventh",
    8: "Eighth",
    9: "Ninth",
    10: "Tenth",
    11: "Eleventh",
    12: "Twelve",
    13: "Thirteenth",
    14: "Fourteenth",
    15: "Fifteenth",
    16: "Sixteenth",
    17: "Seventeenth",
    18: "Eighteenth",
    19: "Nineteenth",
    20: "Twentieth",
    21: "Twenty first",
    22: "Twenty second",
    23: "Twenty third",
    24: "Twenty fourth",
    25: "Twenty fifth",
    26: "Twenty sixth",
    27: "Twenty seventh",
    28: "Twenty eighth",
    29: "Twenty ninth",
    30: "Thirtieth",
  };
  constructor(
    private activateRoute: ActivatedRoute,
    private modalService: ModalService,
    private upgardeService: AccountUpgradeService,
    private router: Router,
    private activityService: ActivityService
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.getData();
  }

  getData() {
    this.isWorldWide = false;
    let filterData = {
      activityType: "14",
      fromDate: "",
      toDate: "",
      symbol:[],
      searchText: "",
    };
    this.activityService.getRecentTransactions(filterData).subscribe(
      (res) => {
        if (res.length !== 0) {
          if (res[0].activitylist[0].mondaylist) {
            this.isWorldWide = true;
            this.processData(res[0].activitylist[0]);
          }
        } else {
          filterData.activityType = "";
          this.activityService.getRecentTransactions(filterData).subscribe(
            (res) => {
              let filteredData;
              res.forEach((dateRow) => {
                dateRow.activitylist.forEach((row) => {
                  if (row.mondaylist !== null) {
                    filteredData = row;
                  }
                });
              });
              if (filteredData) {
                if (filteredData.mondaylist) {
                  this.processData(filteredData);
                }
                this.upgardeService.userKycStatus().subscribe(
                  (flag) => {
                    if (flag.status != 2) {
                      this.usdNoKyc = true;
                    } else {
                      this.usdNoKyc = false;
                    }
                    this.isLoading = false;
                  },
                  (err) => {
                    this.isLoading = false;
                  }
                );
                this.isLoading = false;
              } else {
                filterData.activityType = "11";
                this.activityService
                  .getRecentTransactions(filterData)
                  .subscribe((res) => {
                    let filteredData;
                    res.forEach((dateRow) => {
                      dateRow.activitylist.forEach((row) => {
                        if (!filteredData) {
                          filteredData = row;
                        } else if (
                          new Date(filteredData.endDate).setHours(0, 0, 0, 0) <=
                          new Date(row.endDate).setHours(0, 0, 0, 0)
                        ) {
                          filteredData = row;
                        }
                      });
                    });
                    this.isNormalStaking = true;
                    this.ledger = filteredData;
                    //this.processData(filterData);
                  });
              }
            },
            (err) => {
              this.isLoading = false;
            }
          );
        }
        this.isLoading = false;
      },
      (err) => {
        this.isLoading = false;
      }
    );
  }

  processData(res) {
    this.ledger = res;
    let maxLength = Object.keys(res.mondaylist).length;
    for (let a in res.mondaylist) {
      if (
        new Date(res.mondaylist[a]).setHours(0, 0, 0, 0) <=
          new Date().setHours(0, 0, 0, 0) &&
        a != maxLength.toString()
      ) {
        this.mondayList.push({
          isSuccess: true,
          isSuccessNext: true,
          dot: false,
          text: this.weeklyMondayDays[a] + " weekly staking reward",
          date: res.mondaylist[a],
        });
      }
    }
    if (this.mondayList.length === 1) {
      this.mondayList[0].isSuccessNext = false;
      this.mondayList[0].dot = true;
    }

    if (this.mondayList.length === 0) {
      this.mondayList.push({
        isSuccess: false,
        isSuccessNext: false,
        dot: false,
        text: this.weeklyMondayDays[1] + " weekly staking reward",
        date: res.mondaylist[1],
      });
    }

    if (
      new Date(this.ledger.endDate).setHours(0, 0, 0, 0) <
      new Date(res.mondaylist[maxLength]).setHours(0, 0, 0, 0)
    ) {
      this.mondayList.push({
        isSuccess: false,
        isSuccessNext: false,
        dot: false,
        text: "Unlocked",
        date: this.ledger.endDate,
      });
      this.mondayList.push({
        isSuccess: false,
        isSuccessNext: false,
        dot: false,
        text: this.weeklyMondayDays[maxLength] + " weekly staking reward",
        date: res.mondaylist[maxLength],
      });
    } else {
      this.mondayList.push({
        isSuccess: false,
        isSuccessNext: false,
        dot: false,
        text: this.weeklyMondayDays[maxLength] + " weekly staking reward",
        date: res.mondaylist[maxLength],
      });
      this.mondayList.push({
        isSuccess: false,
        isSuccessNext: false,
        dot: false,
        text: "Unlocked",
        date: this.ledger.endDate,
      });
    }

    if (
      new Date().setHours(0, 0, 0, 0) >=
      new Date(this.ledger.endDate).setHours(0, 0, 0, 0)
    ) {
      let index = -1;
      let flag = false;
      if (this.mondayList[this.mondayList.length - 1].text === "Unlocked") {
        index = this.mondayList.length - 1;
        flag = true;
      } else {
        index = this.mondayList.length - 2;
      }
      if (flag) {
        this.mondayList[index - 1].isSuccess = true;
        this.mondayList[index - 1].isSuccessNext = true;
        this.mondayList[index - 1].dot = false;
        this.mondayList[index].isSuccess = true;
        this.mondayList[index].isSuccessNext = false;
        this.mondayList[index].dot = true;
      } else {
        this.mondayList[index + 1].isSuccess = true;
        this.mondayList[index + 1].isSuccessNext = false;
        this.mondayList[index + 1].dot = false;
        this.mondayList[index].isSuccess = true;
        this.mondayList[index].isSuccessNext = true;
        this.mondayList[index].dot = true;
      }
    } else if (
      new Date().setHours(0, 0, 0, 0) >=
      new Date(res.mondaylist[maxLength]).setHours(0, 0, 0, 0)
    ) {
      let index = -1;
      if (this.mondayList[this.mondayList.length - 1].text === "Unlocked") {
        index = this.mondayList.length - 2;
      } else {
        index = this.mondayList.length - 1;
      }
      this.mondayList[index].isSuccess = true;
      this.mondayList[index].isSuccessNext = false;
      this.mondayList[index].dot = true;
    } else if (this.mondayList.length > 3) {
      this.mondayList[this.mondayList.length - 3].isSuccessNext = false;
      this.mondayList[this.mondayList.length - 3].dot = true;
    }

    this.mondayList.push({ text: "no", date: this.ledger.endDate });
    this.isLoading = false;

    this.mondayList.forEach((item) => {
      if (item.dot) {
        this.isDotEnable = true;
      }
    });
  }

  isSuccessClass(step) {
    if (step === 4) {
      if (this.ledger.islocked) {
        return false;
      } else {
        return true;
      }
    }
    return true;
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IDepositPlan, IDepositInterestPlan, ICancelAutoRenewal, CancelFee } from 'src/app/core/models/deposit-interest/deposit-interest';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DepositInterestService {

  private apiUrl = environment.api;

  constructor(private http: HttpClient) { }

  getDepositInterestPlan(currencyId: string): Observable<IDepositInterestPlan[]> {
    return this.http.get<IDepositInterestPlan[]>(`${this.apiUrl}/depositinterest/getbycurrency?CurrencyId=${currencyId}`);
  }
  // getAllCurrencyDepositInterest(): Observable<IDepositInterestPlan[]> {
  //   return this.http.get<IDepositInterestPlan[]>(`${this.apiUrl}/depositinterest/getDefault`);
  // }
  getAllCurrencyDepositInterest(): Observable<IDepositInterestPlan[]> {
    return this.http.get<IDepositInterestPlan[]>(`${this.apiUrl}/depositinterest/getfddefaultlist`);
  }
  getByDepositFDCurrency(currencyId: string): Observable<IDepositInterestPlan[]> {
    return this.http.get<IDepositInterestPlan[]>(`${this.apiUrl}/depositinterest/getfdbycurrency?CurrencyId=${currencyId}`);
  }
  createDepositPlan(plan: IDepositPlan): Observable<{ statusCode: number, message: string }> {
    return this.http.post<{ statusCode: number, message: string }>(`${this.apiUrl}/depositinterest/create`, plan);
  }

  cancelAutoRenewal(cancel: ICancelAutoRenewal) {
    return this.http.post<{ statusCode: number, message: string }>(`${this.apiUrl}/depositinterest/update/autorenewinterestplan`, cancel);
  }
  cancelFixedDeposit(cancel: ICancelAutoRenewal) {
    return this.http.post<{ statusCode: number, message: string }>(`${this.apiUrl}/depositinterest/cancelfixeddeposit`, cancel);
  }
  getCancelFee(depositInterestId) {
    return this.http.get<CancelFee>(`${this.apiUrl}/depositinterest/getfdcanceldetails?depositInterestId=${depositInterestId}`);
  }
}

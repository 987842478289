<cashaa-sidenav>
    <section class="mh-100">
        <div class="bg-white p-4 sm-media-padding">
            <div class="d-flex sm-phone-display-none mr-3" style="height: 47px;">
                <div class="d-flex sm-phone-display-none mr-3" style="height: 47px;">
                <button routerLink="/repay" mdbBtn type="button" class="cursor-pointer btn-sm app-primary-btn">Back</button>
            </div>
            </div>
            <div class="sm-recipient-btn-padding-mobile sm-phone-display-visible" style="display: none;">
            </div>
        </div>
    </section>

    <!--  -->
    <div class="text_cntnt table_btn_section" style="padding: 24px;">
        <h5 style="color: #1531AA;">Collateral against loan</h5>
        <!-- <p class="" style="color: #3E4B5B;">Interest charged daily will automatically be deducted from your saving wallet in the currency of the loan availed. In case of unavailability of ballance, the interest amount will accrue to your outstanding loan amount</p> -->


        <table class="table table-bordered border-primary align-middle">
            <thead>
                <tr class="">
                    <th scope="col">Date</th>
                    <th scope="col">Collateral locked (initially)</th>
                    <th scope="col">Remaining collateral locked (currently)</th>
                    <th scope="col">Worth in Cas</th>
                    <th scope="col">Status</th>
                    
                </tr>
            </thead>
            <tbody class="">
                <tr class="" *ngFor="let item of loanDetails">
                    <td>{{item.date | date: 'dd MMMM, yyyy'}}</td>
                    <td>{{item.amount}}&nbsp;{{item.currencySymbol}}</td>
                    <td>{{item.remainingAmount | number}}&nbsp; {{item.currencySymbol}}</td>
                    <td>{{item.amountInCas}} CAS</td>
                    <td>{{item.creditLoanStatus}}</td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="p-4 text-center" [hidden]="!loading">
        <mdb-spinner spinnerColor="blue"></mdb-spinner>
    </div>
    <div class="text_cntnt table_btn_section" style="padding: 24px;">
        <h5 style="color: #1531AA;">Payment history </h5>
        <table class="table table-bordered border-primary align-middle">
            <thead>
                <tr class="">
                    <th scope="col">Date</th>
                    <th scope="col">Transaction Id</th>
                    <th scope="col">UNL</th>
                    <th scope="col">Amount</th>
                </tr>
            </thead>
            <tbody class="">
                <tr class="" *ngFor="let info of loanRepaymentHistoryInfo">
                    <td>{{info?.date | date: 'dd MMMM, yyyy'}}</td>
                    <td>{{info?.id}}</td>
                    <td>{{info?.loanNumber}}</td>
                    <td><img src="../../../assets/icons/{{info?.currencySymbol}}.png" alt="{{info?.currencySymbol}}" width="25px">&nbsp;&nbsp;{{info?.amount}}</td>
                </tr>
            </tbody>
        </table>
</div>
</cashaa-sidenav>


import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthGuardService } from "./../core/services/auth-guard/auth-guard.service";
import { HelpdeskHomeTicketComponent } from "./helpdesk-home-ticket/helpdesk-home-ticket.component";
const routes: Routes = [
  {
    path: "helpdesk",
    canActivate: [AuthGuardService],
    children: [
      {
        path: "create-ticket",
        component: HelpdeskHomeTicketComponent,
      },

    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class HelpdeskRoutingModule { }

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AccountSigninComponent } from "./account-signin/account-signin.component";
import { AccountSignupComponent } from "./account-signup/account-signup.component";
import { AccountForgetPasswordComponent } from "./account-forget-password/account-forget-password.component";
import { AccountResetPasswordComponent } from "./account-reset-password/account-reset-password.component";
import { AccountTwoFactorAuthenticationComponent } from "./account-two-factor-authentication/account-two-factor-authentication.component";
import { MDBBootstrapModulesPro } from "ng-uikit-pro-standard";
import { AccountRoutingModule } from "./account-routing.module";
import { AccountWrapperComponent } from "./account-wrapper/account-wrapper.component";
import { SharedModule } from "../shared/shared.module";
import { AccountSocialSigninComponent } from "./account-social-signin/account-social-signin.component";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { AccountWhitelistDeviceComponent } from "./account-whitelist-device/account-whitelist-device.component";
import { ToastrModule } from "ngx-toastr";
import { AccountMesgBoxComponent } from './account-mesg-box/account-mesg-box.component';

@NgModule({
  declarations: [
    AccountSigninComponent,
    AccountSignupComponent,
    AccountForgetPasswordComponent,
    AccountResetPasswordComponent,
    AccountTwoFactorAuthenticationComponent,
    AccountWrapperComponent,
    AccountSocialSigninComponent,
    AccountWhitelistDeviceComponent,
    AccountMesgBoxComponent,
  ],
  imports: [
    CommonModule,
    AccountRoutingModule,
    SharedModule,
    ReactiveFormsModule,
    FormsModule,
    MDBBootstrapModulesPro.forRoot(),
    ToastrModule.forRoot(),
  ],
})
export class AccountModule {}

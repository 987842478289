import { IUpgradeSubscriptionRequest } from "./../../core/models/account-upgrade/upgrade-subscription-request";
import { ValidatorUtility } from "./../../core/utility/validator.utility";
import { AccountUpgradeService } from "./../services/account-upgrade.service";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Component, OnInit } from "@angular/core";

@Component({
  selector: "cashaa-upgrade-account-premier-staking",
  templateUrl: "./upgrade-account-premier-staking.component.html",
  styleUrls: ["./upgrade-account-premier-staking.component.scss"],
})
export class UpgradeAccountPremierStakingComponent implements OnInit {
  upgradeSubscriptionForm: FormGroup;

  subscriptionPlanId: string;

  constructor(
    private fb: FormBuilder,
    private upgardeService: AccountUpgradeService
  ) {}

  ngOnInit(): void {
    this.createForm();
    this.getUpgradedSubscriptionData();
  }

  createForm(): void {
    this.upgradeSubscriptionForm = this.fb.group({
      lockingAmount: [
        "",
        [ValidatorUtility.Required],
        ValidatorUtility.OnlyNumberRequired,
      ],
      lockingPeriod: [
        "",
        [ValidatorUtility.Required],
        ValidatorUtility.OnlyNumberRequired,
      ],
      password: [
        "",
        [ValidatorUtility.Required, ValidatorUtility.PasswordValidator],
      ],
    });
  }

  getUpgradedSubscriptionData(): void {
    // this.upgardeService.getreservePrimierDataEmitter().subscribe((planId) => {
    //   this.subscriptionPlanId = planId;
    // });
  }
  // tslint:disable-next-line: typedef
  onSignupSubmit() {
    // const data = this.upgradeSubscriptionForm.value;
    // const requestData = new IUpgradeSubscriptionRequest(
    //   data.lockingAmount,
    //   data.lockingPeriod,
    //   data.password
    // );
    // // Send a request to upgrade table
    // this.upgardeService.upgradeSubscriptionPlan(requestData).subscribe((res) => {
    //   if (res) {
    //   }
    // }
    // );
  }
}

<section class="d-flex">
  <div class="position-relative w-100" (click)="onLinkClick(inviteinput)">
    <input mdbInput type="text" class="input-box form-control p-2" [(ngModel)]="invite" readonly #inviteinput />
  </div>
  <div class="btn-group no-arrow" mdbDropdown>
    <a mdbDropdownToggle mdbBtn class="dropdown-toggle dropdown waves-light black-text text-left d-flex" type="button"
      mdbWavesEffect>
      Share

      <mdb-icon style="margin-top: 3px;" class="ml-2 text-primary-light" fas [icon]="'chevron-' + (isDropdownOpened ? 'up' : 'down')"></mdb-icon>
    </a>
    <div class="dropdown-menu  sm-mobile-dropdown-width m-0 p-3">
      <ng-template [ngTemplateOutlet]="options"></ng-template>
    </div>
  </div>
</section>

<ng-template #options>
  <div>
    <div class="d-flex align-items-center option mb-3" (click)="copyLink()">
      <mdb-icon fas icon="link" class="mr-3 text-blue" size="2x"></mdb-icon>
      <p class="font-weight-normal mb-0">Copy link</p>
    </div>
    <div class="d-flex align-items-center option mb-3" (click)="shareWhtsapp()">
      <mdb-icon far icon="comment" class="mr-3 light-green-text" size="2x"></mdb-icon>
      <p class="font-weight-normal mb-0">Send with Whatsapp</p>
    </div>
    <div class="d-flex align-items-center option mb-3" (click)="shareFacebook()">
      <mdb-icon fab icon="facebook-messenger" class="mr-3 indigo-text" size="2x"></mdb-icon>
      <p class="font-weight-normal mb-0">Send with Messenger</p>
    </div>
    <div class="d-flex align-items-center option mb-3" (click)="shareFacebook()">
      <mdb-icon fab icon="facebook-f" class="mr-3 pr-1 text-primary-dark" size="2x"></mdb-icon>
      <p class="font-weight-normal mb-0">Post to Facebook</p>
    </div>
    <div class="d-flex align-items-center option" (click)="shareTwitter()">
      <mdb-icon fab icon="twitter" class="mr-3" class="blue-text mr-3" size="2x"></mdb-icon>
      <p class="font-weight-normal mb-0">Tweet</p>
    </div>
  </div>
</ng-template>
<mdb-accordion>
  <mdb-accordion-item [collapsed]="true">
    <mdb-accordion-item-head [customClass]="isOpen ? 'pl-4' : 'settings-card pl-4'" (click)="isOpen = !isOpen">
      <div class="d-flex align-items-center">
        <img [src]="'../../../../assets/images/settings/2-factor.svg'" />
        <!-- <mdb-icon far icon="envelope" class="p-2 card-icon" [ngClass]="isOpen ? '' : 'bg-white'"></mdb-icon> -->
        <div class="ml-4 setting-card-open">
          <h6 class="mb-1 heading">Close Account</h6>
          <span class="mb-0 font-weight-normal fs-075rem">Please make sure your account balance is 0.00 before you begin.</span>
        </div>
      </div>
    </mdb-accordion-item-head>
    <mdb-accordion-item-body customClass="pl-5 position-relative">
      <div *ngIf="firstScreen" class="m-4">
        <div class="card m-4 bg-sky">
          <div class="card-body my-3">
            <p class="font-weight-normal">We can close your account once you meet the following requirements</p>
            <h6 class="mb-4 text-success fs-075rem font-weight-600">Please make sure you meet the requirements</h6>
            <ng-container *ngFor="let req of requirements">
              <ul class="custom-red-bullet ml-n4">
                <li class="li-item text-primary">{{req.label}}</li>
              </ul>
            </ng-container>
          </div>
        </div>
        <div class="card m-4">
          <div class="card-body my-3">
            <p class="font-weight-normal">Why are you closing your account? Please help us with your feedback</p>
            <ng-container *ngFor="let fb of feedback;let i = index">
              <mdb-checkbox [default]="true" [ngModel]="selectedIndex === i" (change)="selectFeedback($event, i)">
                <p>{{fb.label}}</p>
              </mdb-checkbox>
            </ng-container>
            <textarea *ngIf="isOtherTextBox" type="text" cols="50" rows="5" maxlength="256" [(ngModel)]="otherText"></textarea>
          </div>
        </div>
        <div class="m-4">
          <mdb-checkbox [default]="true" [(ngModel)]="agreement" (change)="selectAgreement($event)">
            <p class="font-weight-normal">I hereby confirm that to have read and agree to the <a target="_blank" href="https://cashaa.com/term-condition">Terms & Conditions</a>, Wallet Terms, Earn
              Terms, Exchange Terms, and <a target="_blank" href="https://cashaa.com/privacy-policy">Privacy Policy</a> of Cashaa.</p>
          </mdb-checkbox>
        </div>
        <button mdbBtn (click)="closeAccount()" block="true" type="button" [disabled]="(selectedIndex === 7 && otherText.length < 10) || (selectedIndex < 0) || !agreement"
          class="m-4 rounded-sm position-relative btn btn-primary waves-effect waves-light btn-block w-60 w-25 ml-0 mb-4"
          mdbWavesEffect>
          Close Account
        </button>

      </div>
      <div *ngIf="secondScreen" class="m-4">
        <div class="text-center">
          <img src="../../../assets/images/settings/virtual-customer.svg" alt="">
        </div>
        <div class="card m-4">
          <div class="card-body my-3">
          <p class="font-weight-normal">In order to close your account a support ticket will be created on your behalf. An executive from our
            support team will be in touch</p>
          </div>
        </div>
          <div class="card m-4 bg-sky">
            <div class="card-body my-3">
          <p class="font-weight-normal text-primary">Any deposits made to your existing Cashaa Fiat & Crypto wallet addresses after your account is closed will
            be lost. You also lose your account data and transaction history.</p>
        </div>
        </div>
        <div class="m-4">
          <p class="font-weight-normal">As a financial institution, there are certain instances where
            Cashaa is required to hold or process data for a specific
            period of time to ensure compliance with global legal and/
            or regulatory obligations (including anti-money laundering
            and counter terrorism financing laws and regulations).
            In these cases, we may be unable to delete specific data until
            such time has passed as outlined in our Privacy Policy.</p>
        </div>
        <div class="d-flex justify-content-between">
          <button mdbBtn (click)="backToFirst()" block="true" type="button"
          class="m-4 rounded-sm position-relative btn btn-primary waves-effect waves-light btn-block w-60 w-25 ml-0 mb-4"
          mdbWavesEffect>
          Back
        </button>
        <button mdbBtn (click)="continue()" block="true" type="button"
          class="m-4 rounded-sm position-relative btn btn-primary waves-effect waves-light btn-block w-60 w-25 ml-0 mb-4"
          mdbWavesEffect>
          Continue
        </button>
      </div>
      </div>
      <div *ngIf="thirdScreen" class="m-4">
        <div class="m-4">
          <div class="my-3">
            <h6 class="text-primary font-weight-600">Are you sure you want to close your Cashaa Account?</h6>
            <p>Here are a few reasons to stay with Cashaa</p>
          </div>
        </div>
          <div class="card m-4 bg-sky">
            <div class="card-body my-3">
              <div class="d-flex">
                <div>
                  <h6 class="text-primary font-weight-600">Earn on your crypto and fiat
                  <img height="48px" style="margin-top:-15px;" src="../../../assets/images/settings/bar-chart.svg" alt="">
                </h6>
                  <p>Earn inflation-beating yields on your favourite cyrpto and fiat assets safely with Cashaa</p>
                </div>
              </div>
            </div>
          </div>
          <div class="card m-4 bg-light-green">
            <div class="card-body my-3">
            <h6 class="text-green font-weight-600">Instant Exchange
              <!-- <img height="48px" style="margin-top:-15px;" src="../../../assets/images/settings/instant-exchange.svg" alt=""> -->
            </h6>
            <p>Get the best price and liquidity for your swaps while continuing to earn yields on your assets</p>
          </div>
        </div>
          <div class="card m-4 bg-grey">
            <div class="card-body my-3">
            <h6 class="text-primary font-weight-600">The Power of CAS
              <!-- <img height="48px" style="margin-top:-15px;" src="../../../assets/images/settings/power-of-cas.svg" alt=""> -->
            </h6>
            <p>Boost your yield income, get free withdrawals, save on transaction fees and grow your wealth with CAS</p>
          </div>
        </div>
        <div class="d-flex justify-content-between">
          <button mdbBtn (click)="navigateToActivity()" block="true" type="button"
          class="m-4 rounded-sm position-relative text-white btn-success waves-effect waves-light btn-block w-25 ml-0 mb-4"
          mdbWavesEffect>
          Keep Account
        </button>
        <a (click)="confirmClose()" style="margin: auto 30px;" class="text-danger font-weight-600">
          Close Account
      </a>
     </div>
        
      </div>
      <div *ngIf="fourthScreen" class="m-4">
        <div class="text-center">
          <img src="../../../assets/images/settings/account.svg" alt="">
        </div>
        <div class="card m-4">
          <div class="card-body my-3">
          <p class="font-weight-normal">Your account closure request has been 
            received, so you can relax now.</p>
            <p class="text-primary font-weight-normal">An executive from our support team will contact
              you with the next steps very soon. </p>
          </div>
        </div>
        <button mdbBtn (click)="navigateToActivity()" block="true" type="button"
          class="m-4 rounded-sm position-relative btn btn-primary waves-effect waves-light btn-block w-25 ml-0 mb-4"
          mdbWavesEffect>
          Got it
        </button>
      </div>

      <!-- Progress bar -->
      <mdb-progress-bar *ngIf="isInProgress" class="progress primary-color-dark position-absolute" mode="indeterminate">
      </mdb-progress-bar>
    </mdb-accordion-item-body>
  </mdb-accordion-item>
</mdb-accordion>
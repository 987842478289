<section *ngIf="!loader" class="p-5 w-80 mx-auto fiat-flow-amount">
  <h5 class="font-weight-bold sm-custom-font" *ngIf="depositData?.option != 1 && depositData?.option != 2">
    Our bank details for this transfer
  </h5>
  <h5 class="font-weight-bold" *ngIf="depositData?.option == 1">
    Call up bank's telephone banking and make a payment
  </h5>
  <h5 class="font-weight-bold" *ngIf="depositData?.option == 2">
    How to pay from your bank account
  </h5>

  <hr />
  <!-- <span class="font-weight-bolder sm-custom-sub-heading" *ngIf="depositData?.option != 2">You'll need to do this next
    bit manually. Tell your
    bank to make a payment using the details below.
  </span>

  <span class="blue-text pointer sm-blue-custom" (click)="onBack()">
    <mdb-icon fas icon="arrow-left"> </mdb-icon>
    <span style="padding-left: 10px;">Or, pay another way</span>
  </span>-->
  <p class="small mt-4 mb-4">
    <b>Important:</b> The name on the bank account you are depositing from must
    match the name entered for verification in your Cashaa account you are
    depositing into.
  </p> 
  <p *ngIf="!isKycDone" class="small mt-4 mb-4">
    <b>Warning:</b> <span *ngIf="userType ==='6' || userType ==='5'" class="font-weight-600 text-black cursor-pointer"  [routerLink]="['/kyc/business']" [queryParams]="{plan:'Broker'}">
      In order to deposit funds, complete your KYC by <b class="text-info">clicking here.</b>
    </span>
    <span *ngIf="userType == '4'" class="font-weight-600 text-blackcursor-pointer"  [routerLink]="['/kyc/personal']">
      In order to deposit funds, complete your KYC by <b class="text-info">clicking here.</b>
    </span>
  </p>
  <div class="sm-card">
    <p class="font-weight-bold sm-card-heading">Bank Transfer Details</p>
    <div class="row mb-3">
      <div class="col-6">
        <label class="small text-lightgray mb-1">Payee name</label>
        <p class="font-weight-bolder">{{ bankDetail?.payeeName }}</p>
      </div>
      <div class="col-6">
        <label class="small text-lightgray mb-1">Use this reference</label>
        <p *ngIf="depositData?.option == '3' && depositData?.symbol === 'USD'" class="font-weight-bolder red-text">
          <!-- {{bankDetail?.otherCountryWalletId}} -->
          <span></span>{{ bankDetail?.referanceNumber}}
        </p>
        <p *ngIf="depositData?.option == '4' && depositData?.symbol === 'USD'" class="font-weight-bolder red-text">
          {{ bankDetail?.referanceNumber}}
        </p>
        <p *ngIf="depositData?.symbol == 'EUR' || depositData?.symbol === 'GBP'" class="font-weight-bolder red-text">
          {{ bankDetail?.referanceNumber}}
        </p>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-6">
        <label class="small text-lightgray mb-1">Net amount you will deposit</label>
        <p class="font-weight-bolder red-text" *ngIf="depositData?.fee > 0; else zeroFee">
          <span *ngIf="depositData?.symbol == 'GBP'">&pound;</span><span
            *ngIf="depositData?.symbol == 'EUR'">&euro;</span>
          <span *ngIf="depositData?.symbol == 'USD'">$</span>{{depositData?.amount  | number: "0.0-4"}} +
          <span *ngIf="depositData?.symbol == 'GBP'">&pound;</span><span
          *ngIf="depositData?.symbol == 'EUR'">&euro;</span>
        <span *ngIf="depositData?.symbol == 'USD'" >$</span>{{depositData?.fee }} (fee) = 
        <span *ngIf="depositData?.symbol == 'GBP'">&pound;</span><span
        *ngIf="depositData?.symbol == 'EUR'">&euro;</span>
      <span *ngIf="depositData?.symbol == 'USD'">$</span>{{depositData?.amount + depositData?.fee | number: "0.0-4"}}
        </p>
        <ng-template #zeroFee>
          <p class="font-weight-bolder red-text">
          <span *ngIf="depositData?.symbol == 'GBP'">&pound;</span><span
          *ngIf="depositData?.symbol == 'EUR'">&euro;</span>
        <span *ngIf="depositData?.symbol == 'USD'">$</span>{{depositData?.amount - depositData?.fee | number: "0.0-4"}}
          </p>
        </ng-template>
      </div>
      <div class="col-6" *ngIf="depositData?.symbol == 'EUR'">
        <label class="small text-lightgray mb-1">IBAN</label>
        <p class="font-weight-bolder">{{ bankDetail?.iban }}</p>
      </div>
      <div class="col-6" *ngIf="(depositData?.symbol == 'USD')
       && depositData?.option == '3' ">
        <label class="small text-lightgray mb-1">Account Number</label>
        <p class="font-weight-bolder">{{ bankDetail?.accountNumber }}</p>
      </div>
      <div class="col-6" *ngIf="(depositData?.symbol == 'USD')
      && depositData?.option =='4' ">
        <label class="small text-lightgray mb-1">Account Number</label>
        <p class="font-weight-bolder">{{bankDetail?.otherCountryWalletId}}</p>
      </div>
      <div class="col-6" *ngIf="depositData?.symbol == 'GBP'">
        <label class="small text-lightgray mb-1">Account Number</label>
        <p class="font-weight-bolder">{{ bankDetail?.accountNumber }}</p>
      </div>
    </div>
    <div class="row mb-3">
      <!-- <div class="col-6" *ngIf="depositData?.symbol === 'EUR'; else otherThanEUR">
        <label class="small text-lightgray mb-1">Bank code (BIC)</label>
          <p class="font-weight-bolder">{{ bankDetail?.bankCode}}</p>
      </div> -->
     
        <div class="col-6" *ngIf="depositData?.option =='4' && depositData?.symbol === 'GBP'; else otherThanGBP">
          <label class="small text-lightgray mb-1">Sort Code</label>
            <p class="font-weight-bolder">{{ bankDetail?.sortCode}}</p>
        </div>
      <ng-template #otherThanGBP>
        <div class="col-6" >
          <label class="small text-lightgray mb-1">Bank code (BIC)</label>
            <p class="font-weight-bolder">{{ bankDetail?.bankCode}}</p>
        </div>
      </ng-template>
      <div class="col-6" *ngIf="depositData?.symbol == 'USD'">
        <label class="small text-lightgray mb-1">Routing Number</label>
        <p class="font-weight-bolder">{{ bankDetail?.sortCode }}</p>
      </div>
      <div class="col-6">
        <label class="small text-lightgray mb-1">Bank Name</label>
        <p class="font-weight-bolder">
          {{ bankDetail?.bankName }}
        </p>
      </div>
      <div class="col-6">
        <label class="small text-lightgray mb-1">Bank Address</label>
        <p class="font-weight-bolder">
          {{ bankDetail?.address1 }} {{ bankDetail?.address2 }}
          {{ bankDetail?.address3 }}
        </p>
      </div>
    </div>
  </div>
  <!-- <p class="font-weight-bold small">For transfer within United Kingdom</p>
  <div class="row mb-3">
    <div class="col-6">
      <label class="small text-lightgray mb-1">Sort Code</label>
      <p class="font-weight-bolder">{{ bankDetail?.sortCode }}</p>
    </div>
    <div class="col-6">
      <label class="small text-lightgray mb-1">Account Number</label>
      <p class="font-weight-bolder">{{ bankDetail?.accountNumber }}</p>
    </div>
  </div>
  <p class="small mt-4 mb-4">
    Cashaa never takes money automatically from your account.
  </p> -->
  <div class="d-flex sm-card-2">
    <button style="width: 30%!important;" mdbBtn block="true" type="button"
      class="w-auto sm-btn-input-hover btn-border btn-sm undefined btn btn-outline- mr-3" mdbWavesEffect
      (click)="onReset()">
      Cancel
    </button>
    <button style="width: 70%!important;" mdbBtn block="true" type="button"
      class="btn-sm btn-primary btn btn-height w-auto" mdbWavesEffect (click)="onContinue()">
      Enter bank transfer number
    </button>
  </div>
</section>
<section class="text-ceter" *ngIf="loader">
  <mdb-spinner spinnerColor="blue"></mdb-spinner>
</section>
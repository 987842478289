import { BeneficiaryTypes } from "./../../core/models/fIat/fiat-beneficiaryTypes";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { Observable, BehaviorSubject, Subject } from "rxjs";
import {
  AddRecipient,
  IAddRecipient,
} from "src/app/core/models/recipient/recipient-add";
import { HttpClient } from "@angular/common/http";
import { IRecipient, IRecipientDelete } from "src/app/core/models/fIat/recipients";
import { IBankAccount } from "src/app/core/models/fIat/bankaccount";
import {
  AddRecipientUsd,
  IGBPEURO,
  RecipientAddGbp,
} from "src/app/core/models/recipient/recipient-add-usd";

@Injectable({
  providedIn: "root",
})
export class RecipientService {
  /** Api URL */
  private apiUrl = environment.api;

  /** Receipient flag emitter */
  private addReceipientFlagEmitter = new BehaviorSubject<boolean | undefined>(
    undefined
  );

  private deleteRecipientFlg = new Subject<boolean>();

  private sendMoneyRecipient = new BehaviorSubject<IRecipient | undefined>(
    undefined
  );

  constructor(private http: HttpClient) {}

  /**
   * Get Recipient list
   */
  getRecipientList(): Observable<IRecipient[]> {
    return this.http.get<IRecipient[]>(`${this.apiUrl}/recipients/getbyid`);
  }
  /**
   * Get Beneficiary Type list
   */
  getBeneficiaryTypes(): Observable<BeneficiaryTypes[]> {
    return this.http.get<BeneficiaryTypes[]>(
      `${this.apiUrl}/recipients/getbeneficiarytypes`
    );
  }

  /**
   * Get Recipient list
   */
  getBankaccountList(): Observable<IBankAccount[]> {
    return this.http.get<IBankAccount[]>(`${this.apiUrl}/fiats/getaccountlist`);
  }

  /**
   * Add fiate recipient request
   * /api/v1/recipients
   */
  addRecipientRequest(
    addrecipientdata: IGBPEURO
  ): Observable<{ statusCode: number; message: string }> {
    return this.http.post<{ statusCode: number; message: string }>(
      `${this.apiUrl}/recipients`,
      addrecipientdata
    );
  }
  /**
   * Add fiate recipient request
   * /api/v1/recipients
   */
  addRecipientRequestUsd(
    addrecipientusddata: AddRecipientUsd
  ): Observable<{ statusCode: number; message: string }> {
    return this.http.post<{ statusCode: number; message: string }>(
      `${this.apiUrl}/recipients/usd`,
      addrecipientusddata
    );
  }

  /**
   * Emit add recipient flag
   */
  emitaddRecipientFlag(flag: boolean): void {
    this.addReceipientFlagEmitter.next(flag);
  }

  /*** Get add recipient flag emitter***/
  getaddRecipientFlagEmitter(): Observable<boolean> {
    return this.addReceipientFlagEmitter.asObservable();
  }

  deleteRecipient(recipient: IRecipientDelete) : Observable<{ statusCode: number; message: string }> {
    return this.http.post<{ statusCode: number; message: string }>(
      `${this.apiUrl}/recipients/deleterecipient`,
      recipient
    );
  }

  emitDeleteRecipientFlag(flag: boolean): void {
    this.deleteRecipientFlg.next(flag);
  }

  getDeleteRecipientFlagEmitter(): Observable<boolean> {
    return this.deleteRecipientFlg.asObservable();
  }

  emitSendMoneyRecipient(recipient: IRecipient) : void {
    this.sendMoneyRecipient.next(recipient);
  }

  getSendMoneyRecipient() :  Observable<IRecipient> {
    return this.sendMoneyRecipient.asObservable();
  }

  updateMemo(payload): Observable<any> {
    return this.http.post<{ statusCode: number; message: string }>(
      `${this.apiUrl}/WithdrawAddress/updatememo`,
      payload
    );
  }
}

import { HttpErrorResponse } from '@angular/common/http';
import { Route } from '@angular/compiler/src/core';
import { Component, ElementRef, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { MDBModalRef } from 'ng-uikit-pro-standard';
import { ToastrService } from 'ngx-toastr';
import { USER_TWO_FACTOR_CODE, USER_TYPE } from 'src/app/app.constants';
import { CommonSavedData } from 'src/app/shared/services/commonSavedData.service';
import { ApiManagerService } from '../services/api-manager.service';

@Component({
  selector: 'cashaa-ap-manager-delete-api-confirm',
  templateUrl: './ap-manager-delete-api-confirm.component.html',
  styleUrls: ['./ap-manager-delete-api-confirm.component.scss']
})
export class ApManagerDeleteApiConfirmComponent implements OnInit {

  isInProgress = false;
  twoFactor = false;
  userEmail?: string;
  errorMessageFlag = false;
  errorMessage = "";
  response = "";
  isLoading = true;
  currFlag;
  allCodeEntered = false;
  reserveBalance;
  code: string[] = ["", "", "", "", "", ""];
  @ViewChildren("inputs") inputs: QueryList<ElementRef>;
  errorMsg: string;
  balanceError: boolean;
  balanceErrorMsg: string;
  isBusiness: boolean;
  isStaking: boolean;
  stakingHeading: string;
  stakingBody: string;
  userType = sessionStorage.getItem(USER_TYPE);
  apiId: string;
  public form: FormGroup;
  constructor(
    private fb: FormBuilder,
    public modalRef: MDBModalRef,
    private toastrService: ToastrService,
    private router: Router, private apiManagerService: ApiManagerService,
    private commanData: CommonSavedData
  ) { }

  ngOnInit(): void {
    const idToken = sessionStorage.getItem(USER_TWO_FACTOR_CODE);
    if (idToken === "true") {
      this.twoFactor = true;
    }
  }

  /**
   * On submission of form
   */
  onFormSubmit(): void {
    this.isInProgress = true;
    this.apiManagerService.deleteAPI(this.apiId, this.code.join("")).subscribe((data) => {
      if (data.statusCode === 200) {
        this.modalRef.hide();
        this.toastrService.success('API delete successfully');
        this.commanData.emitConfirmationBoxStatus(true);
      }
      this.isInProgress = false;
    }, (err: HttpErrorResponse) => {
      this.isInProgress = false;
      this.toastrService.error(err.error.message);
    })
  }

  removeError(): void {
    if (this.errorMessage !== undefined) {
      this.errorMessage = undefined;
    }
  }

  /**
   * Check if google input authenticator
   * code is filled in all 6 boxes
   */
  checkIfCodeIsFilled(idx: number): void {
    this.errorMsg = "";
    if (idx >= 0 && idx < 5 && this.code[idx]) {
      this.inputs.toArray()[idx + 1].nativeElement.focus();
    }

    this.allCodeEntered = true;
    this.code.forEach((val) => {
      if (val === null || val.length === 0) {
        this.allCodeEntered = false;
      }
    });
    if (this.code[5] && idx === -1) {
      this.inputs.toArray()[5].nativeElement.focus();
    }
  }

  /**
   * On code paste automatically
   * fill all 6 boxes
   */
  onCodePaste(event: ClipboardEvent): void {
    const clipboardData = event.clipboardData || (window as any).clipboardData;
    const pastedText = clipboardData.getData("text");

    if (pastedText && pastedText.length === 6) {
      this.code = pastedText.split("");
      this.checkIfCodeIsFilled(-1);
    }
  }

  removeBack(event: any, index: number) {
    const keyCode = event.keyCode;
    if (keyCode === 8) {
      this.inputs.toArray()[index].nativeElement.focus();
    }
  }

}

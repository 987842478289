import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SBItemComponent } from 'ng-uikit-pro-standard';
import { ToastrService } from 'ngx-toastr';
import { AccountService } from 'src/app/account/sevices/account.service';
import { USER_EMAIL_PATH, USER_TWO_FACTOR_CODE } from 'src/app/app.constants';
import { WalletService } from '../../services/wallet.service';

@Component({
  selector: 'cashaa-review-security-verification',
  templateUrl: './review-security-verification.component.html',
  styleUrls: ['./review-security-verification.component.scss']
})
export class ReviewSecurityVerificationComponent implements OnInit {

  @Output() onContinue = new EventEmitter();
  loading = false;
  /** is some call in progress */
  isInProgress = false;
  isTwoFAInProgress = false;
  /** form group */
  form: FormGroup;

  errorMessage: string;

  isCollapsed = true;

  allCodeEntered = false;
  allEmailCodeEntered = false;
  emailCode: string[] = ["", "", "", "", "", ""];
  code: string[] = ["", "", "", "", "", ""];
  emailOTPCode: string = "";
  twoFACode: string = "";
  creditLineLockedCurrency;

  @ViewChildren("emailOTPInputs") emailOTPInputs: QueryList<ElementRef>;
  @ViewChildren("twoFAInputs") twoFAInputs: QueryList<ElementRef>;

  @ViewChildren(SBItemComponent) collapses: QueryList<SBItemComponent>[];

  userEmailId: string = sessionStorage.getItem(USER_EMAIL_PATH);

  @Input() currencyId;
  @Input() loanAmount;
  @Output() goback = new EventEmitter<number>();


  encodeKey(key: string): string {
    return encodeURIComponent(key);
  }
  encodeValue(value: string): string {
    return encodeURIComponent(value);
  }
  decodeKey(key: string): string {
    return decodeURIComponent(key);
  }
  decodeValue(value: string): string {
    return decodeURIComponent(value);
  }

  amountBeforeLoan: number;
  amountAfterLoan: number;
  dailyInterestCharged;
  lockedCurrency;
  totalLockedAmount = 0;
  isTwoFactorEnable = false;
  totalAmountBorrowed;
  totalAmountBorrowedInUSD;
  amountBorrowedSymbol;
  private navigationKeys = [
    'Backspace',
    'Delete',
    'Tab',
    'Escape',
    'Enter',
    'Home',
    'End',
    'ArrowLeft',
    'ArrowRight',
    'Clear',
    'Copy',
    'Paste'
  ];
  approxDailyInterest;
  constructor(private toastrService: ToastrService, private walletService: WalletService, private accountService: AccountService) { }

  ngOnInit(): void {
    this.checkTwoFactorEnable();
    this.walletService.loanData.subscribe(data => {
      this.loanAmount = data.amount;
      console.log(data);
      this.dailyInterestCharged = data.dailyInterestCharged; // actually this is annual interest
       this.approxDailyInterest = data.dailyInterestCharged /365;
      this.lockedCurrency = data.lockedCurrency;
      this.amountBeforeLoan = this.lockedCurrency.totalCredit;
      this.amountAfterLoan = this.lockedCurrency.afterLockedAmount;
      this.totalLockedAmount = this.lockedCurrency.totalLockedAmount;
      this.creditLineLockedCurrency = this.lockedCurrency.creditLineLockedCurrency;
      this.totalAmountBorrowed = this.lockedCurrency.totalAmountBorrowed;
      this.totalAmountBorrowedInUSD = this.lockedCurrency.totalAmountBorrowedInUSD;
      this.amountBorrowedSymbol = this.lockedCurrency.amountBorrowedSymbol;
      // this.lockedCurrency.forEach((val) => {
      //   this.totalLockedAmount += val.usdamount;
      // });
    });
  }

  checkTwoFactorEnable() {
    this.accountService.isTwoFactorEnabled().subscribe(res => {
      sessionStorage.setItem(USER_TWO_FACTOR_CODE, res.toString());
      this.isTwoFactorEnable = res;
    }, err => {

    })
  }

  removeError(): void {
    if (this.errorMessage !== undefined) {
      this.errorMessage = undefined;
    }
  }


  /**
   * On code paste automatically
   * fill all 6 boxes
   */
  onEmailCodePaste(event: ClipboardEvent): void {
    const clipboardData = event.clipboardData || (window as any).clipboardData;
    const pastedText = clipboardData.getData("text");

    if (pastedText && pastedText.length === 6) {
      this.emailCode = pastedText.split("");
      this.checkIfEmailCodeIsFilled(-1);
    }
  }
  /**
 * On code paste automatically
 * fill all 6 boxes
 */
  onTwoFACodePaste(event: ClipboardEvent): void {
    const clipboardData = event.clipboardData || (window as any).clipboardData;
    const pastedText = clipboardData.getData("text");

    if (pastedText && pastedText.length === 6) {
      this.code = pastedText.split("");
      this.checkIfTwoFACodeIsFilled(-1);
    }
  }

  removeEmailOTPBack(event: any, index: number) {
    const keyCode = event.keyCode;
    if (keyCode === 8) {
      this.emailOTPInputs.toArray()[index].nativeElement.focus();
    }
  }

  removeTwoFAOTPBack(event: any, index: number) {
    const keyCode = event.keyCode;
    if (keyCode === 8) {
      this.twoFAInputs.toArray()[index].nativeElement.focus();
    }
  }

  continue() {
    this.loading = true;
    const payload = {
      emailOTPCode: this.emailOTPCode,
      twoFAOTPCode: this.twoFACode
    }
    this.onContinue.emit(payload);
    this.loading = false;
  }

  sendEmailCode(): void {
    this.loading = true;
    const payload = {
      currencyId: this.currencyId,
      amount: this.loanAmount
    }
    console.log(payload);
    this.walletService.sendEmailOTP(payload).subscribe(res => {
      this.isInProgress = false;
      this.loading = false;
      this.toastrService.success("Check your email for OTP");
      this.emailCode = ["", "", "", "", "", ""];
      this.emailOTPCode = " ";
    }, err => {
      this.loading = false;
      this.isInProgress = false;
      this.errorMessage = err.error.message;
      this.emailCode = ["", "", "", "", "", ""];
      this.emailOTPCode = " ";

    })
  }


  /**
   * Check if google input authenticator
   * code is filled in all 6 boxes
   */
  checkIfTwoFACodeIsFilled(idx: number): void {
    if (idx >= 0 && idx < 5 && this.code[idx]) {
      this.twoFAInputs.toArray()[idx + 1].nativeElement.focus();
    }

    this.allCodeEntered = true;
    this.code.forEach((val) => {
      if (val === null || val.length === 0) {
        this.allCodeEntered = false;
      }
    });
    if (this.allCodeEntered) {
      this.isTwoFAInProgress = true;
      this.errorMessage = "";
      this.twoFACode = this.code.join("");
    }
    // if (this.code[5] && idx === -1) {
    //   this.twoFAInputs.toArray()[5].nativeElement.focus();
    // }

  }
  /**
 * Check if google input authenticator
 * code is filled in all 6 boxes
 */
  checkIfEmailCodeIsFilled(idx: number): void {
    if (idx >= 0 && idx < 5 && this.emailCode[idx]) {
      this.emailOTPInputs.toArray()[idx + 1].nativeElement.focus();
    }

    this.allEmailCodeEntered = true;
    this.emailCode.forEach((val) => {
      if (val === null || val.length === 0) {
        this.allEmailCodeEntered = false;
      }
    });

    if (this.allEmailCodeEntered) {
      this.isInProgress = true;
      this.errorMessage = "";
      this.emailOTPCode = this.emailCode.join("");
    }
  }

  goBack() {
    this.emailCode = ["", "", "", "", "", ""];
    this.emailOTPCode = "";
    this.code = ["", "", "", "", "", ""];
    this.twoFACode = "";
    this.goback.emit();
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(e: KeyboardEvent) {
    if (
      // Allow: Delete, Backspace, Tab, Escape, Enter, etc
      this.navigationKeys.indexOf(e.key) > -1 ||
      (e.key === 'a' && e.ctrlKey === true) || // Allow: Ctrl+A
      (e.key === 'c' && e.ctrlKey === true) || // Allow: Ctrl+C
      (e.key === 'v' && e.ctrlKey === true) || // Allow: Ctrl+V
      (e.key === 'x' && e.ctrlKey === true) || // Allow: Ctrl+X
      (e.key === 'a' && e.metaKey === true) || // Cmd+A (Mac)
      (e.key === 'c' && e.metaKey === true) || // Cmd+C (Mac)
      (e.key === 'v' && e.metaKey === true) || // Cmd+V (Mac)
      (e.key === 'x' && e.metaKey === true) // Cmd+X (Mac)
    ) {
      return;
    }
    if (e.key === ' ' || isNaN(Number(e.key))) {
      e.preventDefault();
    }
  }

}

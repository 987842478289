import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthGuardService } from "../core/services/auth-guard/auth-guard.service";
import { SwapHomeComponent } from "./swap-home/swap-home.component";

const routes: Routes = [
  {
    path: "cas-swap",
    component: SwapHomeComponent,
    canActivate: [AuthGuardService],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SwapRoutingModule {}

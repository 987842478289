<cashaa-sidenav>
  <section class="mh-100">
      <div class="bg-white p-4">
      <!-- <div class="sm-customer-btn-padding-mobile">
      </div> -->
      <div class="d-flex ml-5" style="height: 46px;">
        <h6 class="heading my-auto">CAS SWAP</h6>
      </div>
    </div>
  </section>
  <div class="p-4 text-center" [hidden]="!loader">
    <mdb-spinner spinnerColor="blue"></mdb-spinner>
  </div>
  <div>
    <div style="margin: auto;text-align: center;margin-top: 25px;">
      <img src="../../../assets/icons/ETH_large.png" alt="" height="100">
      <mdb-icon class="right-arrow" fas icon="arrow-right"></mdb-icon>
      <img src="../../../assets/icons/BNB.png" alt="" height="100">
    </div>
    <div class="d-flex justify-content-between pt-4 pb-4">

      <div class="p-4 w-100">
        <section class="banks">
          <div class="bg-unset">
            <div>
              <!--Second container for QR code and BTC Deposit Address-->
              <div class="">
                <div class="row px-5 py-4" style="background-color: white;border-radius: 7px;">
                  <div class="col-md-12">
                    <div class="mb-5">
                      <h5 class="mb-3 heading">
                        CAS-ERC20 Deposit Address
                      </h5>

                      <div class="input-group mb-3 sm-nowrap">
                        <div class="sm-qr-box sm-padding-qr-box">
                          <div class="sm-text-center">
                            <h4 class="sm-heading-text" style="font-weight: 400;">Address</h4>
                          </div>
                          <div class="sm-qr-box-inner">
                            <qr-code [size]="150" [value]="depositAddress"></qr-code>
                          </div>
                          <div class="sm-qr-input-box">
                            <span class="sm-spam-text sm-span-text-padding">{{depositAddress }}</span>
                            <div class="input-group-append">
                              <span class="sm-input-group-text"><i mdbTooltip="Copy address" placement="top"
                                  class="fas fa-clone sm-copy-color" [ngClass]="copiedAddress ? 'text-primary' : ''"
                                  (click)="copyMemoAddress()"></i></span>
                            </div>
                          </div>
                          <span class="sm-spam-text2 pt-2 pb-2">Received CAS (ERC20) : {{receivedBalance}}</span>
                        </div>
                      </div>
                    </div>
                    <p class="text-dark font-weight-bolder text-center">You
                      will receive your BEP2 token after 13 January 2023 (Completed all steps before 13 December 2022,
                      4:00 AM BST) – Fees 15%"
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div class="p-4 w-100">
        <div class="border border-primary rounded-lg p-4 bg-white w-100" style="height: 100%;">
          <h5 class="heading mb-3">Deposit History
          </h5>
          <div class=" bg-white table-responsive table-border">
            <table #tableEl="mdbTable" id="userListTable" class="table" mdbTable bordered="true" hover="true">
              <thead class="tableHeaderColor">
                <tr>
                  <th aria-controls="userListTable" scope="col" style="width: 20px;">Amount</th>
                  <th aria-controls="userListTable" scope="col" style="width: 165px;">Transaction ID</th>
                  <th aria-controls="userListTable" scope="col" style="width: 165px;">From Address</th>
                  <th aria-controls="userListTable" scope="col">Status</th>
                  <th aria-controls="userListTable" scope="col" style="width: 5px;">Is Released</th>
                </tr>
              </thead>
              <tbody>
                <tr style="height: 560px;" mdbTableCol *ngIf="!listData">
                  <td colspan="5" style="text-align: center;padding-top: 100px;"> No Data Found</td>
                </tr>
                <tr mdbTableCol *ngFor="let item of listData; let i = index">
                  <td> {{item.amount}}&nbsp;</td>
                  <td title="{{item.transactionId}}" (click)="copyItem(item.transactionId)"> <a
                      href={{getValue(item.transactionId)}} target="_blank">{{item.transactionId}}</a>
                  </td>
                  <td (click)="copyItem(item.fromAddress)"> {{item.fromAddress}}&nbsp;
                  </td>
                  <td> {{status[item.status]}}&nbsp;
                  </td>
                  <td> {{item.isReleased}}&nbsp;
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</cashaa-sidenav>
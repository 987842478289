<div class="modal-content">
  <div class="modal-header">
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span style="font-size: 35px;" aria-hidden="true">×</span>
    </button>
    <h4 class="modal-title w-100 mt-3 sm-custom-header" id="myModalLabel">Create New Ticket</h4>
  </div>
  <form [formGroup]="createHelpdeskTicket">
    <div class="modal-body">
      <div class="mt-3">
        <cashaa-form-select name="helpDeskcatagories" type="text" [form]="createHelpdeskTicket"
          [options]="helpCategories" [control]="createHelpdeskTicket.controls.categoryId" label="Category"
          placeholder="Select Category">
        </cashaa-form-select>
      </div>
      <div class="mt-5">
        <cashaa-form-input name="subject" type="text" [form]="createHelpdeskTicket"
          [control]="createHelpdeskTicket.controls.subject" label="Subject" placeholder="Enter the subject">
        </cashaa-form-input>
      </div>
      <div class="mt-5">
        <cashaa-form-textarea [rows]="4" name="message" [form]="createHelpdeskTicket"
          [control]="createHelpdeskTicket.controls.message" label="Message" placeholder="Enter the message">
        </cashaa-form-textarea>
      </div>
      <div class="mt-5">
        <cashaa-form-input name="attachment" id="attachment" isFile="true" type="file" [form]="createHelpdeskTicket"
        [control]="createHelpdeskTicket.controls.attachment" (change)="onFileChange($event,'attachment')"></cashaa-form-input>
      </div>
    </div>
    <div class="modal-footer mt-3">
      <button mdbBtn type="button" outline="true" mdbWavesEffect class="sm-btn-input-hover btn-border btn-sm"
        (click)="close()">
        Cancel
      </button>
      <button mdbBtn type="button" color="primary" [disabled]="createHelpdeskTicket.invalid" mdbWavesEffect
        (click)="submit()" class="btn-sm">
        Submit
      </button>
      <mdb-progress-bar *ngIf="isInProgress" class="progress primary-color-dark " mode="indeterminate">
      </mdb-progress-bar>
    </div>
  </form>
</div>

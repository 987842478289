import {
  Component,
  OnInit,
  ElementRef,
  Input,
  SimpleChange,
  Output,
  EventEmitter,
} from "@angular/core";

@Component({
  selector: "cashaa-password-strength",
  templateUrl: "./password-strength.component.html",
  styleUrls: ["./password-strength.component.scss"],
})
export class PasswordStrengthComponent implements OnInit {
  bar0: string;
  bar1: string;
  bar2: string;
  bar3: string;

  @Input() public passwordToCheck: string;
  @Input() public isSetting: boolean;
  @Output() passwordStrength = new EventEmitter<boolean>();
  lowerLetters;
  upperLetters;
  numbers;
  symbols;
  length;
  password;
  errorShow;
  private colors = ["red", "orange", "#e1d70d", "#2ed06e"];

  constructor() {}

  ngOnInit(): void {}

  checkStrength(p) {
    // 1
    let force = 0;

    // 2
    const regex = /[$-/:-?{-~!"^_@`\[\]]/g;
    this.lowerLetters = /[a-z]+/.test(p);
    this.upperLetters = /[A-Z]+/.test(p);
    this.numbers = /[0-9]+/.test(p);
    this.symbols = regex.test(p);
    this.length = p.length;

    // 3
    const flags = [
      this.lowerLetters,
      this.upperLetters,
      this.numbers,
      this.symbols,
    ];

    // 4
    let passedMatches = 0;
    for (const flag of flags) {
      passedMatches += flag === true ? 1 : 0;
    }

    // 5
    force += 2 * p.length + (p.length >= 10 ? 1 : 0);
    force += passedMatches * 10;

    // 6
    force = p.length <= 8 ? Math.min(force, 10) : force;

    // 7
    force = passedMatches === 1 ? Math.min(force, 10) : force;
    force = passedMatches === 2 ? Math.min(force, 20) : force;
    force = passedMatches === 3 ? Math.min(force, 30) : force;
    force = passedMatches === 4 ? Math.min(force, 40) : force;

    return force;
  }

  ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
    const password = changes.passwordToCheck.currentValue;
    this.password = password;
    this.setBarColors(4, "#DDD");
    if (password) {
      const c = this.getColor(this.checkStrength(password));
      this.setBarColors(c.index, c.color);
      const pwdStrength = this.checkStrength(password);
      if (pwdStrength === 40) {
        this.passwordStrength.emit(true);
        this.errorShow = false;
      } else {
        this.passwordStrength.emit(false);
        this.errorShow = true;
      }
    }
  }

  private getColor(s) {
    let index = 0;
    if (s === 10) {
      index = 0;
    } else if (s === 20) {
      index = 1;
    } else if (s === 30) {
      index = 2;
    } else if (s === 40) {
      index = 3;
    } else {
      index = 4;
    }
    return {
      index: index + 1,
      color: this.colors[index],
    };
  }

  private setBarColors(count, col) {
    for (let n = 0; n < count; n++) {
      this["bar" + n] = col;
    }
  }
}

<h5 class="heading text-center mb-4">Personal Details?</h5>
<div class="card p-4 m-auto w-50">
  <!-- <div class="d-flex justify-content-between"> -->
    <form [formGroup]="otherDetailsForm" class="my-4">
      <p class="small mt-4 mb-4">
        <b>Important:</b>We will send you the ticket and payment confirmation on the email which you provided in your personal information. Make sure your email address is valid before submitting.
      </p> 
      <div class="row mb-4">
        <div class="mb-4 col-6">
          <cashaa-form-input name="name" type="text" [form]="otherDetailsForm"
            [control]="otherDetailsForm.controls.name" placeholder="Name" label="Name"></cashaa-form-input>
        </div>
        <div class="mb-4 col-6">
          <cashaa-form-input name="email" type="email" [form]="otherDetailsForm"
            [control]="otherDetailsForm.controls.email" placeholder="Enter email address" label="Email Address"></cashaa-form-input>
        </div>
        <div class="mb-4 col-6">
          <cashaa-form-input name="mobileNo" type="text" [form]="otherDetailsForm"
            [control]="otherDetailsForm.controls.mobileNo" placeholder="Enter mobile no" label="Mobile No"></cashaa-form-input>
        </div>
        <div class="mb-4 col-6">
          <cashaa-form-input name="companyName" type="text" [form]="otherDetailsForm"
            [control]="otherDetailsForm.controls.companyName" placeholder="Enter company name" label="Company Name">
          </cashaa-form-input>
          </div>
        <div class="mb-4 col-6">
          <cashaa-form-input name="title" type="text" [form]="otherDetailsForm"
            [control]="otherDetailsForm.controls.title" placeholder="Enter title" label="Title">
          </cashaa-form-input>
          </div>
      </div>
      
    </form>
  <!-- </div> -->
  </div>

  <div class="text-center py-4 m-auto w-50">
    <button mdbBtn type="button" class="btn-sm app-success-btn m-auto w-100" [disabled]="otherDetailsForm.invalid" (click)="continue()">Continue
    </button>
  </div>
  <div class="go-back cursor-pointer sm-blue-custom" (click)="goToPreviousPage()">
    <mdb-icon class="sm-cursor-pointer" fas icon="arrow-left"> </mdb-icon>
    <span class="sm-cursor-pointer" style="padding-left: 10px;">Go back</span>
  </div>
  <div class="p-4 text-center" [hidden]="!loading">
    <mdb-spinner spinnerColor="blue"></mdb-spinner>
  </div>
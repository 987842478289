<div class="row my-4">
    <div class="col-md-8">
          <div class="mt-2 mb-4 font-weight-normal">
            <h4 class="font-weight-bolder">Document must meet the following
              guidelines:</h4>
            <ul class="font-weight-normal">
              <li class="mb-1 item">All documents must be high
                resolution, color image files.</li>
              <li class="mb-1 item">Allowed file type extensions
                are: jpg, jpeg, png, pdf.</li>
              <li class="mb-1 item">Files must be at most 5MB.</li>
              <li class="mb-1 item">Proof of residence document must
                contain name, address and be
                dated less than three months
                ago.</li>
              <li class="mb-1 item">For language such as Russian,
                Chinese, Thai, Hebrew etc. that
                use non-Latin characters, a
                notarized translation of your
                proof of residence document will
                greatly speed up process time.
                Japanese documents do not
                require a notarized translation.</li>
            </ul>
          </div>
          <form [formGroup]="form">
            <div class="col-12">
                <div class="row justify-content-between">
                  <div class="col-12">
                      <cashaa-form-select [form]="form" [control]="form.controls.identitydocumentType" 
                      label ="Identity Document Type" name="identitydocumentType" 
                      placeholder="Select document type" [options]="identityProofOptions">
                      </cashaa-form-select>
                      <div class="mt-4 mb-4">
                        <ul class="font-weight-normal">
                          <li class="mb-1 item"><span class="mr-2"><img src="../../../assets/images/check_blue.svg" alt=""
                                width="18"></span>
                            Color
                            scan</li>
                          <li class="mb-1 item"><span class="mr-2"><img src="../../../assets/images/check_blue.svg" alt=""
                                width="18"></span>
                            Has
                            expiration
                            date</li>
                          <li class="mb-1 item"><span class="mr-2"><img src="../../../assets/images/check_blue.svg" alt=""
                                width="18"></span>
                            Government
                            issued
                            ID</li>
                          <li class="mb-1 item"><span class="mr-2"><img src="../../../assets/images/check_blue.svg" alt=""
                                width="18"></span>
                            Shows
                            your
                            date of
                            birth</li>
                          <li class="mb-1 item"><span class="mr-2"><img src="../../../assets/images/check_blue.svg" alt=""
                                width="18"></span>
                            Front
                            and back
                            both
                            required</li>
                        </ul>
                      </div>
                  </div>
                  <div class="d-flex justify-content-start w-100">
                    <div class="m-2 w-100">
                      <fieldset class="border p-2">
                        <legend class="w-auto sm-label font-weight-normal">Identity Front</legend>
                        <cashaa-form-input-file (status)="status($event)" [docType] = "identityFront" [fileUploadStatus]="true" [isFileUploadCompleted]="identityFront.isDeletedValid" [isOperationCompleted]="false">
                        </cashaa-form-input-file>
                      </fieldset>
                    </div>
                     <div class="m-2 w-100">
                      <fieldset class="border p-2">
                        <legend class="w-auto sm-label font-weight-normal">Identity Back</legend>
                        <cashaa-form-input-file (status)="status($event)" [docType] = "identityBack" [fileUploadStatus]="true" [isFileUploadCompleted]="identityBack.isDeletedValid" [isOperationCompleted]="false">
                        </cashaa-form-input-file>
                      </fieldset>
                     </div> 
                      
                  </div>
                </div>
              </div>
          </form>
          
    </div>    
</div>    
<button style="width: 30%!important; text-align: center; margin-top: 30px;" [disabled] = "!(count == 2 && form.valid)" (click)="onAddressSubmit()" block="true" type="button"
              class="w-25 rounded-sm position-relative btn btn-primary waves-effect waves-light btn-block"
              mdbWavesEffect>
              <!-- <span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true" *ngIf="loader"></span> -->
              Next
</button>

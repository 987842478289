<h5 class="heading text-center">{{addressInfo?.symbol}} - Deposit Address</h5>
<div class="pt-4 w-100">
  <section class="banks">
    <div class="bg-unset">
      <div>
        <div class="">
          <h4 class="heading-desc text-center">Address</h4>
          <div class="row px-5 pt-2">
            <div class="col-md-12">
              <div class="input-group mb-3 sm-nowrap">
                <div class="sm-qr-box sm-padding-qr-box">
                  <div class="sm-qr-box-inner">
                    <qr-code [size]="150" [value]="addressInfo?.address"></qr-code>
                  </div>
                  <div class="sm-qr-input-box">
                    <span class="sm-spam-text sm-span-text-padding">{{addressInfo?.address}}</span>
                    <div class="input-group-append">
                      <span class="sm-input-group-text"><i mdbTooltip="Copy address" placement="top"
                          class="fas fa-clone sm-copy-color" [ngClass]="copiedAddress ? 'text-primary' : ''"
                          (click)="copyMemoAddress()"></i></span>
                    </div>
                  </div>
                  <span class="sm-spam-text2 pt-2 pb-2">Deposit {{addressInfo?.symbol}} : {{addressInfo?.feeAmount}}
                  </span>
                  <p class="text-dark font-weight-bolder text-center">
                    This deposit details valid for 30 minutes.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>

<div class="text-center pb-3 m-auto w-50">
  <button mdbBtn type="button" class="btn-sm app-success-btn m-auto w-100" (click)="continue()">Continue
  </button>
</div>
<div class="go-back cursor-pointer sm-blue-custom" (click)="goToPreviousPage()">
  <mdb-icon class="sm-cursor-pointer" fas icon="arrow-left"> </mdb-icon>
  <span class="sm-cursor-pointer" style="padding-left: 10px;">Go back</span>
</div>
<div class="p-4 text-center" [hidden]="!loading">
  <mdb-spinner spinnerColor="blue"></mdb-spinner>
</div>
<cashaa-sidenav>
    <section class="mh-100">
        <div class="bg-white p-4">
            <div class="d-flex sm-phone-display-none mr-3" style="height: 47px;">
              <h6 class="heading my-auto">Choose your subscription plan</h6>
            </div>
            <!-- <h4 class="font-weight-bold sm-profile-heading-mobile-2">
        Subscription Plans
      </h4> -->
      
        </div>
    </section>
    <div class="p-4">
        
        <!-- <h6 class="py-3 font-weight-normal">
      Accessible pricing that's flexible for your needs
    </h6> -->
    </div>
    <div class="text-center" [hidden]="!loading">
        <mdb-spinner spinnerColor="blue" class="sm-loader-center"></mdb-spinner>
    </div>
    <div class="sm-width-phone-700">

        <!-- <div class="d-flex justify-content-center m-3 personal-upgrade" *ngIf="!loading">
      <div class="shadow-sm bg-white p-3 w-25 w-45-mobile">
        <table class="mb-2">
          <thead>
            <th class="font-weight-bold text-primary pl-4 pt-4">
              <h3 class="font-weight-bolder text-primary-dark">
                <strong>Basic</strong>
              </h3>
            </th>
          </thead>
          <tr>
            <td>
              <div class="pricing__price">
                <span class="pricing__period align-top pt-2 pr-1">&pound;</span>0<span
                  class="pricing__period">/month</span>
              </div>
              <ul class="account-list">
                <li class="item font-weight-bold text-red"><b>Zero processing fee
                  </b></li>
                <li class="item">USD, EUR and GBP wallet</li>
                <li class="item">
                  Free crypto wallet for 12 crypto currencies and stablecoins including Bitcoin, Ether, Tether, USDC,
                  CAS and others
                </li>
                <li class="item">Free digital wallet for 10 fiat currencies including USD, EUR, GBP, CAD, SGD and others
                </li>
                <li class="item">Exchange in 12 crypto currencies and 10 fiat currencies - no monthly limit</li>
                <li class="item">25% discount on exchange fees if paid in CAS</li>
                <li class="item">Earn up to 13% APY on crypto and fiat wallet balance and upto 24% APR on fixed deposits
                </li>
              </ul>
            </td>
          </tr>
        </table>
        <div class="sm-active-font-2" *ngIf="!isPremuimPlan">
          <div class="align-self-end">
            <p class="font-weight-bold text-primary-dark">Active</p>
          </div>
        </div>
      </div>
      <div class="p-3 w-25 w-45-mobile reserve" (mouseenter)="showReserveButton()" (mouseleave)="showReserveButton()">
        <table class="mb-2 w-100">
          <thead>
            <th class="pl-4 pt-4">
              <h3 class="font-weight-bolder text-primary-dark">
                <strong>Premium</strong>
              </h3>
            </th>
          </thead>
          <tr>
            <td>
              <ul class="account-list">
                <li class="text-red item font-weight-bold">
                  Application processing fee &pound; {{premuimFeesDetails?.feeInEUR | number}} or 25% Less if paid with CAS
                </li>
                <li class="item">GBP, EUR and USD wallet</li>
                <li class="item">3rd Party payments are allowed</li>
                <li class="item">KYC and AML service for your customers</li>
                <li class="item">
                  Exchange in 12 crypto currencies and 10 fiat currencies - no monthly limit
                </li>
                <li class="item">Accept Bitcoin/crypto payments and convert to fiat</li>
                <li class="item">25% discount on exchange fees if paid in CAS</li>
                <li class="item">Earn up to 24% APR on crypto and fiat fixed deposits.</li>
              </ul>
            </td>
          </tr>
        </table>
        <div class="d-flex justify-content-center w-100" *ngIf="!isPremuimPlan">
          <div class="align-self-end">
            <form>
              <div class="pb-4 pt-4">
                <button block="true" type="button" outline="true"
                  class="btn rounded-pill text-primary-dark bg-white m-0" routerLink="/payment">
                  Apply
                </button>
              </div>
            </form>
          </div>
        </div>
        <div class="sm-active-font-2" *ngIf="isPremuimPlan">
          <div class="align-self-end">
            <p class="font-weight-bold text-primary-dark">Active</p>
          </div>
        </div>
      </div>
    </div> -->

        <div class="d-flex justify-content-center px-5" *ngIf="!loading">
            <div class="col-6 p-5 m-2 sub-hover border" [ngClass]="{'bg-white':!isPremuimPlan}">
                <h3 class="text-primary-dark font-weight-600" *ngIf="userType === '4'">Personal Wallet</h3>
                <h3 class="text-primary-dark font-weight-600" *ngIf="userType !== '4'">Business Wallet</h3>
                <p class="font-weight-medium border-bottom" style="min-height: 45px">
                    Only for personal use, no third-party payments
                </p>
                <div class="pricing__price">
                    <span class="pricing__period align-top pt-2 pr-1">&euro;</span>0<span class="pricing__period black-color">Monthly
            Fees</span>
                </div>
                <ul class="account-list">
                    <li class="txt_color2 item font-weight-bold">Zero processing fee</li>
                    <li class="item">Wallet for cryptocurrencies and stablecoins</li>
                    <li class="item">Wallet for 10 national fiat currencies</li>
                    <li class="item">Earn up to 36% interest on crypto and fiat</li>
                    <li class="item">Deposit and withdraw from your<b> own bank account</b></li>
                    <li class="item">Crypto to crypto and fiat to crypto exchange</li>
                    <li class="item">Crypto exchange fee 1%</li>
                    <!-- <li class="item">
            Free crypto wallet for 12 crypto currencies and stablecoins including <br>Bitcoin, Ether, Tether, USDC, CAS and
            others
          </li>
          <li class="item">Free digital wallet for 10 fiat currencies including <br>USD, EUR, GBP, CAD, SGD and others</li>
          <li class="item">Exchange in 12 crypto currencies and 10 fiat currencies - no monthly limit</li>
          
          <li class="item">Earn up to 13% APY on crypto and fiat wallet balance and upto 24% APR on fixed deposits</li> -->
                </ul>

                <div *ngIf="!isPremuimPlan" class="text-initial mt-4">
                    <p class="font-weight-bold text-primary-dark" style="position: absolute;
          bottom: 43px;">Active</p>
                </div>
            </div>
            <div class="col-6 p-5 m-2 sub-hover border" [ngClass]="{'bg-white':isPremuimPlan}" (mouseenter)="showBrokenButton()" (mouseleave)="showBrokenButton()">
                <h3 class="text-primary-dark font-weight-600">Premium Wallet</h3>
                <p class="font-weight-medium border-bottom" style="min-height: 45px">
                    Premium Wallet

                </p>
                <div class="pricing__price">
                    <span class="pricing__period align-top pt-2 pr-1">&euro;</span><span class="pricing_ammount">0</span><span class="pricing__period black-color">Monthly Fees</span>
                </div>
                <ul class="account-list">
                  <li class="txt_color2 item font-weight-bold">
                    Application and set-up fee of {{premuimFeesDetails?.feeInEUR | number}} {{premuimFeesDetails?.fiatFeeSymbol}} or its equivalent in {{premuimFeesDetails?.casFeeSymbol}}
                  </li>

                  <!-- <li class="item">USD, EUR and GBP wallet</li> -->

                  <li class="item">Third-party payments are allowed</li>
                  <li class="item"><b>KYC and AML</b> service for your customers</li>
                  <li class="item">Wallet for cryptocurrencies and stablecoins</li>
                  <li class="item">Wallet for 10 national fiat currencies</li>
                  <li class="item">Earn up to 36% interest on crypto and fiat</li>
                  <li class="item">Deposit and withdraw from <b>any bank account</b></li>
                  <li class="item">Crypto to crypto and fiat to crypto exchange</li>
                  <li class="item">Crypto exchange fee 0.50%</li>


                  <!-- <li class="item">
    Exchange in 12 crypto currencies and 10 fiat currencies - no monthly limit
  </li>

  <li class="item">Accept Bitcoin/crypto payments and convert to fiat</li>
  <li class="item">25% discount on exchange fees if paid in CAS</li>
  <li class="item">Earn up to 13% APY on crypto and fiat wallet balance and upto 24% APR on fixed deposits</li>
  <li class="item">
    For more details click
    <a href="javascript:void(0)" target="_blank" href="https://cashaa.com/broker-wallet">here</a>
  </li> -->

                </ul>
                <button *ngIf="!isPremuimPlan" mdbBtn type="button" class="btn-sm text-white btn-success" mdbWavesEffect routerLink="/payment">
            Upgrade
          </button>
                <div *ngIf="isPremuimPlan" class="text-center mt-4">
                    <p class="font-weight-bold text-primary-dark">Active</p>
                </div>
            </div>
        </div>
        <!-- <div class="d-flex justify-content-around m-3" *ngIf="userType === '5' || userType === '6'">
      <div class="w-25 sub-hover" (mouseenter)="showUSButton(true)" (mouseleave)="showUSButton(false)"
        [ngClass]="{'backgroundColor':isUSBtnVisible}">
        <h3 class="text-primary-dark font-weight-bold">US Account</h3>
        <p class="font-weight-normal">
          Ideal for start-ups and crypto businesses worldwide
        </p>
        <div class="pricing__price">
          <span class="pricing__period align-top pt-2 pr-1">$</span>200<span class="pricing__period">/month</span>
        </div>
        <ul class="account-list">
          <li class="text-red item font-weight-bold">
            Application and set-up fee of $ 1,500 or 25% less if paid with CAS
          </li>
          <li class="item">US current account (USD)</li>
          <li class="item ml-4 pb-1 mt-0 pt-0">Account and Routing number</li>
          <li class="item ml-4 mb-0 pb-0">ACH, Wire and SWIFT</li>

          <li class="item">GBP, EUR and USD wallet</li>

          <li class="item">
            Free crypto wallet for Bitcoin, Tether, Ether and Cashaa
          </li>

          <li class="item">
            Exchange in 4 crypto currencies - no monthly limit
          </li>
          <li class="item">Fiat/Crypto on-ramp & off-ramp</li>
          <li class="item">Accept Bitcoin payments converted into fiat</li>
          <li class="item">25% discount on exchange fees if paid in CAS</li>
          <li class="item">
            For more details click
            <a href="javascript:void(0)" target="_blank" href="https://cashaa.com/usd-current-account">here</a>
          </li>

          <button [disabled]='usdButtonDisable' mdbBtn type="button" [ngClass]="{'display-none':usdButtonDisable}"
            class="btn rounded-pill text-primary-dark bg-white m-0" size="md" mdbWavesEffect
            (click)="onSubmitPremier(true, 'USD')">
            {{usdButtonText}}
          </button>
        </ul>
      </div>
      <div class="w-25 sub-hover" (mouseenter)="showUKButton()" (mouseleave)="showUKButton()">
        <h3 class="text-primary-dark font-weight-bold">UK Account</h3>
        <p class="font-weight-normal">
          Ideal for crypto startup and exchanges worldwide
        </p>
        <div class="pricing__price">
          <span class="pricing__period align-top pt-2 pr-1">&pound;</span>300<span class="pricing__period">/month</span>
        </div>
        <ul class="account-list">
          <li class="text-red item font-weight-bold">
            Application processing fee &pound; 1,000 or 25% Less if paid with CAS
          </li>
          <li class="item">UK current account (GBP)</li>
          <li class="item ml-4 pb-1 mt-0 pt-0">Account no with sort code</li>
          <li class="item ml-4 mb-0 pb-0">FPS, CHAPS and BACS</li>

          <li class="item">GBP, EUR and USD wallet</li>

          <li class="item">
            Free crypto wallet for Bitcoin, Tether, Ether and Cashaa
          </li>

          <li class="item">
            Exchange in 4 crypto currencies - no monthly limit
          </li>

          <li class="item">Accept Bitcoin payments converted into fiat</li>
          <li class="item">25% discount on exchange fees if paid in CAS</li>

          <li class="item">
            For more details click
            <a href="javascript:void(0)" target="_blank" href="https://cashaa.com/UK-current-account">here</a>
          </li>
          <button mdbBtn type="button" class="btn rounded-pill text-primary-dark bg-white m-0" size="md" mdbWavesEffect
            routerLink="/kyc/business" [queryParams]="{ plan: 'GBP_EUR'}">
            Get UK Account
          </button>
        </ul>
      </div>
      <div class="w-25 sub-hover" (mouseenter)="showEuroButton()" (mouseleave)="showEuroButton()">
        <h3 class="text-primary-dark font-weight-bold">EU Account</h3>
        <p class="font-weight-normal">
          Ideal for crypto startup and exchanges worldwide
        </p>
        <div class="pricing__price">
          <span class="pricing__period align-top pt-2 pr-1">&euro;</span>200<span class="pricing__period">/month</span>
        </div>
        <ul class="account-list">
          <li class="text-red item font-weight-bold">
            Application processing fee &pound; 1,000 or 25% Less if paid with CAS
          </li>

          <li class="item">EU Current account (EUR)</li>

          <li class="item ml-4 pb-1 mt-0 pt-0">IBAN with BIC code</li>
          <li class="item ml-4 mb-0 pb-0">SEPA payments</li>

          <li class="item">GBP, EUR and USD wallet</li>

          <li class="item">
            Free crypto wallet for Bitcoin, Tether, Ether and Cashaa
          </li>
          <li class="item">
            Exchange in 4 crypto currencies - no monthly limit
          </li>
          <li class="item">Accept Bitcoin payments converted into fiat</li>

          <li class="item">25% discount on exchange fees if paid in CAS</li>
          <li class="item">
            For more details click
            <a href="javascript:void(0)" target="_blank" href="https://cashaa.com/euro-current-account">here</a>
          </li>
          <button mdbBtn type="button" class="btn rounded-pill text-primary-dark bg-white m-0" size="md" mdbWavesEffect
            routerLink="/kyc/business" [queryParams]="{ plan: 'GBP_EUR'}">
            Get EU Account
          </button>
        </ul>
      </div>
    </div> -->
        <div class="container-fluid p-5">
            <div class="row position-relative bottom-banner">
                <img class="sphere-blue" src="../../../assets/images/sphere-blue-glossy.png">
                <img class="angle-blue" src="../../../assets/images/subscr-banner-angle.png">
                <div class="col-8 p-5 m-auto">
                    <h6 class="font-weight-bold">Interested in opening a dedicated bank account?</h6>
                    <p class="mb-0">More than <b>400 crypto companies</b> are using us worldwide.</p>
                    <p class="mb-4">Apply for a business bank account for your company.</p>
                    <a class="btn-white txt-primary meet-btn p-2 mt-2" target="_blank" href="https://calendly.com/saswatdas">Book a meeting</a>
                </div>
                <div class="col-3 text-right py-1">
                    <img class="subsc-calendar" src="../../../assets/images/subscription-calendar.png">
                </div>
                <div class="col-1"></div>
            </div>

            <!-- </div> -->
        </div>

    </div>



</cashaa-sidenav>

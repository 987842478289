<mdb-card class="no-shadow p-1 p-lg-5">
  <div class="text-center">
    <p class="font-weight-normal">
      {{ mode == "buy" ? "You are buying" : "You will get" }}
    </p>
    <h1 class="text-primary-light font-weight-normal mb-3">
      {{ tradeData?.toAmount || 0 }} {{ tradeData?.to }}
    </h1>
    <p class="text-gray font-weight-normal">
      {{ tradeData?.rate }} {{ tradeData?.from }} per {{ tradeData?.to }}
    </p>
  </div>
  <hr />
  <div class="d-flex mb-5 mt-3">
    <img
      class="icon"
      [src]="'../../../assets/icons/' + tradeData?.from + '.png'"
    />
    <div class="ml-3">
      <p class="small text-gray mb-0">
        {{ mode == "buy" ? "Payment method" : "Withdraw from" }}
      </p>
      <h6 class="text-primary-light font-weight-normal">
        {{ tradeData?.from }} wallet
      </h6>
    </div>
  </div>
  <div class="d-flex mb-3">
    <img
      class="icon"
      [src]="'../../../assets/icons/' + tradeData?.to + '.png'"
    />
    <div class="ml-3">
      <p class="small text-gray mb-0">Deposit to</p>
      <h6 class="text-primary-light font-weight-normal">
        {{ tradeData?.to }} wallet
      </h6>
    </div>
  </div>
  <hr />
  <div class="d-flex justify-content-between mt-3">
    <p class="mb-0">{{ tradeData?.toAmount || 0 }} {{ tradeData?.to }}</p>
    <p class="mb-0">&pound; {{ tradeData?.fromAmount || 0 }}</p>
  </div>
  <hr class="dashed" />
  <div class="d-flex justify-content-between mt-0">
    <p class="mb-2">Fee</p>
    <p class="mb-2">&pound; 0</p>
  </div>
  <hr class="dashed" />
  <div class="d-flex justify-content-between mt-0">
    <p class="mb-2">Total</p>
    <p class="mb-2">&pound; {{ tradeData?.fromAmount || 0 }}</p>
  </div>
  <hr class="mb-5" />
  <button
    [disabled]="tradeData?.error"
    mdbBtn
    block="true"
    type="button"
    class="rounded-sm position-relative btn btn-primary waves-effect waves-light btn-block"
    mdbWavesEffect
  >
    {{ mode == "buy" ? "Buy " + tradeData?.to : "Sell" }}
  </button>
</mdb-card>

<div class="modal-header position-relative">
  <button
    type="button"
    class="close pull-right"
    aria-label="Close"
    (click)="modalRef.hide()"
  >
    <span aria-hidden="true">×</span>
  </button>
  <h4
    class="modal-title w-100 text-primary font-weight-bolder"
    id="myModalLabel"
  >
    Cancel transfer #5464645
  </h4>
</div>
<div class="modal-body">
  <form class="position-relative">
    <div class="row mb-3 px-4">
      <label>Did you send the money?</label>

      <div class="col-12 p3 border my-2">
        <div class="custom-control custom-radio">
          <input type="radio" class="custom-control-input" id="option1" name="payStatus" mdbInput>
          <label class="custom-control-label" for="option1">I paid </label>
        </div>
      </div>

      <div class="col-12 p3 border my-2">
        <div class="custom-control custom-radio">
          <input type="radio" class="custom-control-input" id="option2" name="payStatus" mdbInput>
          <label class="custom-control-label" for="option2">I didn't paid </label>
        </div>
      </div>

    </div>
    <div class="row mb-4 px-4">
      <label>Where would you like us to refund the money?</label>

      <div class="col-12 p3 border my-2">
        <div class="custom-control custom-radio">
          <input type="radio" class="custom-control-input" id="option1" name="bankOption" mdbInput>
          <label class="custom-control-label" for="option1">An exisiting account </label>
        </div>
      </div>

      <div class="col-12 p3 border my-2">
        <div class="custom-control custom-radio">
          <input type="radio" class="custom-control-input" id="option2" name="bankOption" mdbInput>
          <label class="custom-control-label" for="option2">A new account exisiting account </label>
        </div>
      </div>
      <!-- <div class="form-check col-12 border p3  my-2">
        <input
          type="radio"
          formControlName="userType"
          class="form-check-input"
          id="notPaid"
          name="userType"
          mdbInput
          value="1"
        />
        <label class="form-check-label" for="businessAccount">A new account</label>
      </div> -->
    </div>


    <div>
      <button
        mdbBtn
        block="true"
        type="button"
        class="rounded-sm position-relative btn btn-primary waves-effect waves-light btn-block"
        mdbWavesEffect
      >Download
      </button>
    </div>
  </form>

</div>

<section class="p-5 w-65 mx-auto fiat-flow-amount">
  <div class="p-4 text-center" [hidden]="!loader">
    <mdb-spinner spinnerColor="blue"></mdb-spinner>
  </div>
  <div class="mb-4">
    <cashaa-form-input [form]="form" name="transferNumber" type="number" [control]="form.controls.transferNumber"
      label="Transfer Reference Number*">
    </cashaa-form-input>
  </div>
  <div class="mb-4">
    <cashaa-form-input [form]="form" name="remarks" type="text" [control]="form.controls.remarks" label="Remarks*">
    </cashaa-form-input>
  </div>
  <div class="w-100 mb-1">
    <label class="text-primary-dark position-absolute font-weight-600"> Transfer Date* </label>
    <mdb-date-picker #datePicker name="mydate" (dateChanged)="onDateChange($event)" [options]="myDatePickerOptions"
      class="w-100" required></mdb-date-picker>
  </div>
  <div class="mb-4">
    <!-- <label class="text-primary-dark position-absolute font-weight-600"> Transaction Receipt</label> -->  
    <cashaa-form-input name="receiptFile" id="receiptFile" isFile="true" type="file" [form]="form"
        [control]="form.controls.receiptFile" (change)="onFileChange($event,'receiptFile')" class="upload" label="Transaction Receipt"></cashaa-form-input>
  </div>
  <div class="mb-4">
    <!-- <label class="text-primary-dark position-absolute font-weight-600"></label> -->
    <cashaa-form-input name="sourceOfFund" id="sourceOfFund" isFile="true" type="file" [form]="form" class="upload"
        [control]="form.controls.sourceOfFund" (change)="onFileChange($event,'sourceOfFund')" label="Source Of Fund"></cashaa-form-input>
        <!-- <cashaa-form-input-file [docType]="item" (deleteFile)="onFileDelete($event, item)"
            [fileUploadStatus]="isFileUploadValid" [isFileUploadCompleted]="item.isDeletedValid ? true : false"
            [isOperationCompleted]="item.fileActionLoader ? true : false" [isDeletedActive]="true">
          </cashaa-form-input-file> -->
  </div>
  <div class="d-flex">
    <button style="width: 50%!important;" [disabled]="loader" mdbBtn block="true" type="button"
      class="w-auto sm-btn-input-hover btn-border btn-sm undefined btn btn-outline- mr-3" mdbWavesEffect
      (click)="onReset()">
      Cancel
    </button>
    <button [disabled]="form.invalid || loader" style="width: 50%!important;" mdbBtn block="true" type="button"
      class="btn-sm btn-primary btn btn-height w-auto" type="submit" mdbWavesEffect (click)="onContinue()">
      <span class="spinner-border spinner-border-sm" class="mr-2" role="status" aria-hidden="true"
        *ngIf="loader"></span>Continue
    </button>
  </div>
</section>
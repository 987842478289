<div *ngIf="password" class="mt-2">
  <div *ngIf="this.errorShow" [ngClass]="{'error-c':!isSetting,'error-c-setting':isSetting}">
    <p class="font-size-12-mobile">Password must meet the following requirements:</p>
    <ul class="p-box" style="display: table;">
      <li [ngClass]="{'green-c':lowerLetters}"><span class="font-size-12-mobile">At least one letter</span></li>
      <li [ngClass]="{'green-c':upperLetters}"><span class="font-size-12-mobile">At least one capital letter</span></li>
      <li [ngClass]="{'green-c':numbers}"><span class="font-size-12-mobile">At least one number</span></li>
      <li [ngClass]="{'green-c':symbols}"><span class="font-size-12-mobile">At least one special character</span></li>
      <li [ngClass]="{'green-c':length >= 8}"><span class="font-size-12-mobile">At least 8 character</span></li>
    </ul>
  </div>
  <div class="strength">
    <ul class="strengthBar">
      <li class="point" [style.background-color]="bar0"></li>
      <li class="point" [style.background-color]="bar1"></li>
      <li class="point" [style.background-color]="bar2"></li>
      <li class="point" [style.background-color]="bar3"></li>
    </ul>
    <br>
  </div>
</div>
import { Location } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { USER_TYPE } from 'src/app/app.constants';
import { ValidatorUtility } from 'src/app/core/utility/validator.utility';
import { CommonSavedData } from 'src/app/shared/services/commonSavedData.service';
import { ApiManagerService } from '../services/api-manager.service';

@Component({
  selector: 'cashaa-update-domain',
  templateUrl: './update-domain.component.html',
  styleUrls: ['./update-domain.component.scss']
})
export class UpdateDomainComponent implements OnInit {

  isInProgress = false;
  twoFactor = false;
  userEmail?: string;
  errorMessageFlag = false;
  errorMessage = "";
  response = "";
  isLoading = true;
  currFlag;
  allCodeEntered = false;
  reserveBalance;
  code: string[] = ["", "", "", "", "", ""];
  @ViewChildren("inputs") inputs: QueryList<ElementRef>;
  errorMsg: string;
  balanceError: boolean;
  balanceErrorMsg: string;
  isBusiness: boolean;
  isStaking: boolean;
  stakingHeading: string;
  stakingBody: string;
  userType = sessionStorage.getItem(USER_TYPE);
  apiId: string;
  public updateDomainForm: FormGroup;
  webhookDetails;
  isWebhookURLAdded = false;
  // sourcePath = 
  fileName = "";
  isChecked;
  isDownloaded = false;
  constructor(
    private fb: FormBuilder,
    private toastrService: ToastrService,
    private apiManagerService: ApiManagerService,
    private commanData: CommonSavedData,
    private location: Location,
    private activateRoute: ActivatedRoute

  ) {
    this.updateDomainForm = this.fb.group({
      domainURL: ["", ValidatorUtility.Required],
      domainVerificationCode: ["", ValidatorUtility.Required],
      makeSureCheck: ["", ValidatorUtility.Required]
    });
  }

  ngOnInit(): void {
    this.activateRoute.queryParams.subscribe((params) => {
      this.apiId = params["id"];
    });
    this.updateDomainForm.controls.makeSureCheck.valueChanges.subscribe(res => {
      this.isChecked = res;
    })
  }

  generateCode() {
    const payload = {
      apiManagerId: this.apiId
    }
    this.apiManagerService.generateDomainCode(payload).subscribe(res => {
      this.updateDomainForm.controls.domainVerificationCode.setValue(res.message);
      this.fileName = `${res.message}.txt`;
    }, err => {

    })
  }

  download() {
    let newVariable: any = window.navigator;
    let file = new Blob([this.updateDomainForm.controls.domainVerificationCode.value], { type: '.txt' });
    if (newVariable.msSaveOrOpenBlob) {
      newVariable.msSaveOrOpenBlob(file, this.fileName);
    } else {

      let a = document.createElement("a"),
        url = URL.createObjectURL(file);
      a.href = url;
      a.download = this.fileName;
      document.body.appendChild(a);
      a.click();
      this.isDownloaded = true;
      setTimeout(function () {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      }, 0);
    }
  }

  /**
   * On submission of form
   */
  onFormSubmit(): void {
    console.log(this.updateDomainForm.controls);
    if (this.isDownloaded) {
      this.isInProgress = true;
      const payload = {
        "apiManagerId": this.apiId,
        "domainURL": this.updateDomainForm.value.domainURL,
        "domainVerificatioinCode": this.updateDomainForm.value.domainVerificationCode,
        "domainVerificationUrl": this.updateDomainForm.value.domainURL + "/" + this.fileName
      }
      this.apiManagerService.updateDomain(payload).subscribe((data) => {
        if (data.statusCode === 200) {
          this.webhookDetails = data;
          this.toastrService.success('Domain updated successfully');
          this.commanData.emitConfirmationBoxStatus(true);
        }
        this.isInProgress = false;
      }, (err: HttpErrorResponse) => {
        this.isInProgress = false;
        this.toastrService.error(err.error.message);
      })
    } else {
      this.toastrService.error('Please download the file from link before submit')
    }

  }

  goBack() {
    this.location.back();
  }

}

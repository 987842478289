import { Component, OnInit, Input, EventEmitter, Output } from "@angular/core";
import { IFormSelect } from "src/app/core/models/form-select";

@Component({
  selector: "cashaa-form-select-filter",
  templateUrl: "./form-select-filter.component.html",
  styleUrls: ["./form-select-filter.component.scss"],
})
export class FormSelectFilterComponent implements OnInit {
  /** Placeholder for input */
  @Input() placeholder = "";

  @Input() label = "";

  @Output() changed = new EventEmitter<IFormSelect>();

  @Input() options: IFormSelect[] = [];

  /** selected fee */
  selectedOption: IFormSelect;

  isDropdownOpened = false;
  @Input() optionImg: boolean;

  constructor() {}

  ngOnInit(): void {
  }

  onValueChange(value: IFormSelect): void {
    this.selectedOption = value;
    this.changed.emit(value);
  }
}

import { SUBSCRIPTION_BUSINESS_PLAN, SUBSCRIPTION_PERSONAL_PLAN, USER_ID, USER_NAME_ID, USER_NAME_PATH, USER_TYPE } from "./../../app.constants";
import { Component, OnInit, Output, EventEmitter, OnDestroy, Input } from "@angular/core";
import { FiatService } from "../services/fiat.service";
import { Router } from "@angular/router";
import { FiatFlow } from "src/app/core/models/fIat/fiat-flow";
import { IRecipient } from "src/app/core/models/fIat/recipients";
import { APP_ROUTES, USER_EMAIL_PATH } from "src/app/app.constants";
import { ModalService } from "src/app/core/services/modal/modal.service";
import { SubSink } from "subsink";
import { IFiatRecipient } from "src/app/core/models/fIat/fiat-pay";
import { AccountUpgradeService } from "../../account-upgrade/services/account-upgrade.service"
import { UserSubscriptionPlan } from "src/app/core/models/account-upgrade/subscription-plan-response";

@Component({
  selector: "cashaa-fiat-flow-recipient",
  templateUrl: "./fiat-flow-recipient.component.html",
  styleUrls: ["./fiat-flow-recipient.component.scss"],
})
export class FiatFlowRecipientComponent implements OnInit, OnDestroy {
  /** Recipients */
  recipients: IRecipient[];
  selectedRecipients: IRecipient[];
  symbol: string;
  loading = false;
  currentPlanDetails;
  isPremuimPlan;
  @Input() selected: IRecipient;
  fiatFlowData: FiatFlow;
  data;
  fiatCurrencies = ["USD", "GBP", "EUR", "AUSD", "SGD", "AUD", "CNH", "CAD", "CHF", "NZD", "JPY"];
  @Input() set skipped(status: boolean) {
    if (status) {
        this.selectRecipients(this.selected);
    }
  }

  /** on continue click */
  @Output() continue = new EventEmitter<void>();

  subSink = new SubSink();
  networkTypeOptions = [];
  networkVal: number;
  constructor(
    private fiatService: FiatService,
    private modalService: ModalService,
    private router: Router,
    private upgardeService:  AccountUpgradeService
  ) {}
 
  ngOnInit(): void {

    this.getActionSubscriptionPlan();
    this.getCurrencySymbol();
    this.fiatService.getfiatFlowPaydDataEmitter().subscribe((fiatFlowData) => {
      if (fiatFlowData) {
        this.fiatFlowData = fiatFlowData;
        console.log(this.fiatFlowData);
      }
      });
  }

  /**
   * Get recipient list
   */
  getRecipientList(symbol: string, network: number, recipientType): void {
    this.loading = true;
    this.networkVal = network;
    this.subSink.add(this.fiatService.getRecipents(symbol, network, recipientType).subscribe((recipentsListData) => {
      this.loading = false;
      if (recipentsListData) {
        this.recipients = recipentsListData;
      }
    }));
  }

  /**
   * on some recipient is selected
   * Selected recipient
   */
  selectRecipients(recipient: IRecipient): void {
    if(recipient?.isCashaaAddress && !recipient?.memo && recipient?.network === 0) {
    } else {
      this.fiatService.emitFiatRecipientdData(recipient);
      this.continue.emit();
    } 
  }
   
  getCurrencySymbol(): void {
    this.loading = true;
    this.subSink.add(this.fiatService.getCurrencySymbolEmitter().subscribe((data:IFiatRecipient) => {
      if (data) {
        this.data = data;
        this.symbol = data.currencySymbol;
        this.loading = false;
        this.updateNetworkType();
        this.getRecipientList(this.symbol, data.network, data.recipientType);
        
      } else {
        this.loading = false;
      }
    }));
  }

  /**
   * Add new business
   */
  openAddBusiness(): void {
    this.modalService.openSendMoneyAddRecipientBusiness(this.symbol);
  }

  openAddRecipient(): void {
    const name = sessionStorage.getItem(USER_NAME_ID);
    const email = sessionStorage.getItem(USER_EMAIL_PATH);
    this.router.navigateByUrl('recipient');
    // this.modalService.openRecipientAddModal(name, email, this.symbol, "Self Bank Account");
  }
  

  openAddRecipientOther(): void {
    if(this.isPremuimPlan) {
      this.router.navigateByUrl('add-customer');
    } else {
      this.router.navigateByUrl('recipient');
    }

    // this.modalService.openRecipientAddModal("", "", this.symbol, "Other Bank Account");
  }

  openAddRecipientCharity(): void {
    this.modalService.openRecipientAddModal("", "", this.symbol, "Charitable");
  }

  getActionSubscriptionPlan() {
    this.upgardeService.getUserActionSubscriptionPlan().subscribe((data: UserSubscriptionPlan) => {
      if (data) {
        this.currentPlanDetails = data;
        if (sessionStorage.getItem(USER_TYPE) === '4') {
          if (SUBSCRIPTION_PERSONAL_PLAN.PREMIUM === this.currentPlanDetails.planName) {
            this.isPremuimPlan = true;
          }
        } else {
          if (SUBSCRIPTION_BUSINESS_PLAN.PREMIUM === this.currentPlanDetails.planName) {
            this.isPremuimPlan = true;
          }
        }
      }
    });
  }

  getLabelValue(currency: string): string {
    if (currency === 'CAS' || currency === 'BNB') {
        return "BNB Beacon Chain (BEP2)"
    } else {
      return this.getCurrencyName(currency);
    }
  }

  getCurrencyName(curr): string {
    if (curr === "AUSD") return "USD";
    if (curr === "DOG") return "Dogecoin (DOGE)";
    if (curr === "BCH") return "Bitcoin Cash (BCH)";
    if (curr === "XRP") return "Ripple (XRP)";
    if (curr === "LTC") return "Litecoin (LTC)";
    else return curr;
  }

  updateNetworkType() {
    if(this.symbol === 'BNB') {
      this.networkTypeOptions = [{ value: "0", label: "BNB Beacon Chain (BEP-2)" }];
    }

    if(this.symbol === 'BTC') {
      this.networkTypeOptions = [{ value: "0", label: "BTC (Bitcoin)" }];
    }

    if (!this.fiatCurrencies.includes(this.symbol) && !(this.symbol === 'BTC' || this.symbol === 'BNB')) {
      this.networkTypeOptions = [{ value: "2", label: "BNB Smart Chain (BEP-20)" }];
      if(this.symbol === 'USDT') {
        this.networkTypeOptions.push({ value: "4", label: "Tron (TRC-20)" });
      }
      if (this.symbol === 'BNB' || this.symbol === 'BCH' || this.symbol === 'DOG' ||
        this.symbol === 'LTC' || this.symbol === 'XRP' || this.symbol === 'CAS') {
        this.networkTypeOptions.push({ value: "0", label: this.getLabelValue(this.symbol) });
      } else {
        this.networkTypeOptions.push({ value: "1", label: "Ethereum (ERC-20)" });
      }

      // if (this.symbol === 'CAS') {
      //   this.networkTypeOptions.push({ value: "3", label: "Polygon" });
      // }
      if (this.symbol === 'ETH' || this.symbol === 'USDT' || this.symbol === 'UNI' ||
      this.symbol === 'USDC' || this.symbol === 'LINK') {
    }
    if (this.symbol === 'BNB' || this.symbol === 'BCH' || this.symbol === 'CAS'
      || this.symbol === 'DOG' || this.symbol === 'LTC' || this.symbol === 'XRP') {
      // this.recipientAddForm.controls["networkType"].setValue("0");
    }
    }
  }


  ngOnDestroy(): void {
    this.subSink.unsubscribe();
  }
}

<!--Amjad or Nish Please set condition below to show the appropriate message-->

<cashaa-sidenav>
    <!-- <p>{{response|json}}</p> -->
    <br>
<!-- <p>{{response|json}}</p> -->
<h3 *ngIf="response==='success'">Thank you, your email is confirmed now!</h3>
<h3 *ngIf="response==='failed'">Sorry, email confirmation is failed.
    <a href="/kyc/business"> Resend Verification Link</a>
</h3>
<h3 *ngIf="response==='verified'">Your email is already confirmed.</h3>
<p *ngIf="errorMessage">{{errorMessage|json}}</p>
</cashaa-sidenav>

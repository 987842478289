import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { ProfileService } from "../services/profile.service";
import { IBusinessProfile } from "src/app/core/models/business-profile";
import { IFormSelect } from "src/app/core/models/form-select";
import { USER_EMAIL_PATH } from "src/app/app.constants";
import { MDBModalService } from "ng-uikit-pro-standard";
import { SubSink } from "subsink";
const organisationType = require("../../../assets/json/organisationType.json");
const contactType = require("../../../assets/json/contactType.json");

@Component({
  selector: "cashaa-profile-business",
  templateUrl: "./profile-business.component.html",
  styleUrls: ["./profile-business.component.scss"],
})
export class ProfileBusinessComponent implements OnInit, OnDestroy {
  @Output() editProfile = new EventEmitter<void>();
  public organisationType: IFormSelect[] = [];
  public contactType: IFormSelect[] = [];
  profileName: string;
  companyType: string;
  profile: IBusinessProfile;
  loading: boolean = false;
  sink = new SubSink();
  constructor(
    private profileService: ProfileService,
    private mdbModalService: MDBModalService
  ) {}

  ngOnInit(): void {
    this.loading = true;
    this.organisationType = organisationType;
    this.contactType = contactType;
    this.getProfileDetail();
    this.sink.add(
      this.mdbModalService.closed.subscribe(() => {
        this.getProfileDetail();
      })
    );
  }

  /**
   * Get Profile detail
   */
  getProfileDetail(): void {
    this.profileService.getProfileDetails().subscribe((profile) => {
      this.loading = false;
      this.profile = profile;
      this.companyType = this.getOrganisationType(profile.companyType);
      const userEmail = sessionStorage.getItem(USER_EMAIL_PATH);
      this.profileName = (
        userEmail[0].toString() + userEmail[1].toString()
      ).toUpperCase();
    });
  }

  getOrganisationType(value: any): string {
    return this.organisationType.filter((org: IFormSelect) => {
      return org.value === value?.toString();
    })[0]?.label;
  }

  getContactType(value: any): string {
    return this.contactType.filter((cont: IFormSelect) => {
      return cont.value === value?.toString();
    })[0]?.label;
  }

  editDetails(): void {
    this.editProfile.emit();
  }

  ngOnDestroy(): void {
    this.sink.unsubscribe();
  }
}

import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { MDBModalRef } from 'ng-uikit-pro-standard';
import { AccountService } from 'src/app/account/sevices/account.service';
import { CommonSavedData } from '../../services/commonSavedData.service';

@Component({
  selector: 'cashaa-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss']
})
export class TermsAndConditionsComponent implements OnInit {

  info: string;
  imgSrc;
  constructor(public modalRef: MDBModalRef, private commonSavedData: CommonSavedData,
     private accountService: AccountService, private router: Router) { }

  ngOnInit(): void {
  }
  onClick(status: boolean): void  {
    this.accountService.isAgreementApproved().subscribe(res => {
      sessionStorage.setItem('isConsent', 'true');
    });
    this.modalRef.hide();
    this.commonSavedData.emitConfirmationBoxStatus(status);
   } 

   onSelect() {
   this.modalRef.hide();
   this.router.navigateByUrl('spot');
   }

}

<div class="modal-header position-relative sm-form-title">
  <button type="button" class="close pull-right sm-close" aria-label="Close" (click)="modalRef.hide()">
    <span aria-hidden="true">×</span>
  </button>
  <h3 *ngIf="!isStaking" class="modal-title w-100 text-primary font-weight-bolder" id="myModalLabel">
    {{isBusiness ? 'USD Account Onboarding Fee' : 'Stake to Open a USD Account & Earn 30% APR'}}
  </h3>
  <h3 *ngIf="isStaking && isBusiness" style="font-size: 1.175rem !important;"
    class="modal-title w-100 text-primary font-weight-bolder" id="myModalLabel">
    {{stakingHeading}}
  </h3>
  <h3 *ngIf="isStaking && !isBusiness" class="modal-title w-100 text-primary font-weight-bolder" id="myModalLabel">
    {{stakingHeading}}
  </h3>
</div>
<div class="modal-body">
  <!-- <div class="p-4 text-center" [hidden]="!isLoading">
    <mdb-spinner spinnerColor="blue"></mdb-spinner>
  </div> -->
  <form class="position-relative sm-font-family sm-form-padding">
    <div class="mb-4" style="text-align: center;">
      <img alt="" height="=50%"
        src="{{isBusiness ? '../../../assets/images/business_reserve.png' : '../../../assets/images/personal_reserve.png'}}"
        width="50%">
    </div>
    <div *ngIf="!isStaking" class="mb-4">
      <label for="" style="text-align: center;">{{isBusiness?'Confirm the business account onboarding fee of 10,000 CAS
        will be deducted from your CAS balance.' : 'Confirm to stake 10,000 CAS. Your CAS will be locked for a 6 month
        term. You will receive a 30% APR, paid out weekly.'}}</label>
    </div>
    <div *ngIf="isStaking" class="mb-4">
      <label for="" style="text-align: center;">{{stakingBody}}</label>
    </div>
    <cashaa-form-input *ngIf="userType === '4'" name="referralCode" type="text" [form]="form" [control]="form.controls.referralCode"
      placeholder="Enter Referral Code" label="Enter Referral Code (if applicable)">
    </cashaa-form-input>
  </form>
  <hr>
  <div *ngIf="twoFactor" class="sm-form-padding">
    <p class="mb-3">Authentication Code</p>
    <div class="row google-code" (paste)="onCodePaste($event)">
      <div class="col-1 sm-code">
        <input type="text" [disabled]="isInProgress" maxlength="1" [(ngModel)]="code[0]"
          (input)="checkIfCodeIsFilled(0)" autofocus #inputs (change)="removeError()" />
      </div>
      <div class="col-1 sm-code">
        <input type="text" [disabled]="isInProgress" maxlength="1" [(ngModel)]="code[1]"
          (input)="checkIfCodeIsFilled(1)" #inputs (change)="removeError()" (keyup)="removeBack($event, 0)" />
      </div>
      <div class="col-1 sm-code">
        <input type="text" [disabled]="isInProgress" maxlength="1" [(ngModel)]="code[2]"
          (input)="checkIfCodeIsFilled(2)" #inputs (change)="removeError()" (keyup)="removeBack($event, 1)" />
      </div>
      <div class="col-1 sm-code">
        <input type="text" [disabled]="isInProgress" maxlength="1" [(ngModel)]="code[3]"
          (input)="checkIfCodeIsFilled(3)" #inputs (change)="removeError()" (keyup)="removeBack($event, 2)" />
      </div>
      <div class="col-1 sm-code">
        <input type="text" [disabled]="isInProgress" maxlength="1" [(ngModel)]="code[4]"
          (input)="checkIfCodeIsFilled(4)" #inputs (change)="removeError()" (keyup)="removeBack($event, 3)" />
      </div>
      <div class="col-1 ">
        <input type="text" [disabled]="isInProgress" maxlength="1" [(ngModel)]="code[5]"
          (input)="checkIfCodeIsFilled(5)" #inputs (change)="removeError()" (keyup)="removeBack($event, 4)" />
      </div>
    </div>
  </div>
  <!-- <div class="mb-4" *ngIf="errorMessageFlag">
    <span style="color: red">{{ errorMesg }} </span>
  </div> -->
  <div class="position-relative mt-3 mb-5">
    <mdb-error class="mt-2 w-100 text-center error-message" *ngIf="errorMessageFlag">{{ errorMsg }}</mdb-error>
    <mdb-error class="mt-2 w-100 text-center error-message" *ngIf="balanceError">{{ balanceErrorMsg }}</mdb-error>
  </div>

  <div class="mb-4 ml-4" *ngIf="!twoFactor">
    <span>
      Your google 2FA is not enabled
      <a [routerLink]="['/settings']" [queryParams]="{step:'2fa'}" (click)="modalRef.hide()">click here</a> to
      enable.</span>
  </div>
  <div class="mt-4">
    <button (click)="onFormSubmit()" block="true" type="button"
      [disabled]="!allCodeEntered || !twoFactor || balanceError"
      class="rounded-sm position-relative btn btn-primary waves-light btn-block sm-font" mdbWavesEffect>
      Confirm
    </button>
  </div>

  <mdb-progress-bar *ngIf="isInProgress" class="progress primary-color-dark position-absolute" mode="indeterminate">
  </mdb-progress-bar>
</div>
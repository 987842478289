<section class="bg-white p-3 w-phone-100 w-75 mx-auto mt-4">
  <h3 class="title w-100 heading" id="myModalLabel">
    Security Verification
  </h3>
  <hr>
  <table class="table table-striped table-bordered">
    <thead>
      <tr>
        <th scope="col">Total amount borrowed</th>
        <th scope="col">{{totalAmountBorrowed}}&nbsp;{{amountBorrowedSymbol}}
          <tr class="font-weight-500">~ ${{totalAmountBorrowedInUSD}}</tr>
        </th>
      </tr>
      <tr>
        <th scope="col">Available credit before loan availed</th>
        <th scope="col">${{amountBeforeLoan}}</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td class="font-weight-600">Available credit after loan availed</td>
        <td class="font-weight-600">${{amountAfterLoan}}</td>
      </tr>
      <tr>
        <td>
          <div class="font-weight-600">Total Collateral Locked</div>
        </td>
        <td>
          <div class="font-weight-600">${{totalLockedAmount | number}}</div>
        </td>
      </tr>
      <tr *ngFor="let locked of creditLineLockedCurrency">
        <td>
          <div class="font-weight-600">Collateral amount (
            <img class="icon" src="../../../../assets/icons/{{locked.currencySymbol}}.png" alt="">&nbsp;{{locked.amount}}&nbsp;
            {{locked.currencySymbol}}&nbsp;<i class="fa fa-lock text-hold"></i>)
            <!-- <div style="font-size: 0.625em;margin-left: 26px;"> {{locked.currencySymbol}} = ${{locked.rate}}</div> -->
            <!-- <div style="font-size: 0.625em;margin-left: 26px;"> Daily Interest Charged = {{locked.dailyInterest}}%</div> -->
          </div>
        </td>
        <td>
          <div class="font-weight-600"> ${{locked.usdamount | number}}</div>
        </td>
      </tr>
      <tr>
        <td class="font-weight-600">Annual interest payable</td>
        <td class="font-weight-600">{{dailyInterestCharged}}% ~({{approxDailyInterest | number: '0.0-3'}}%&nbsp;Daily)</td>
      </tr>
    </tbody>
  </table>
  <div class="body border-top py-3">
    <ng-container *ngIf="isTwoFactorEnable">
      <h6 class="heading">
        Email Verification Code
      </h6>
      <div class="d-flex google-code my-2" (paste)="onEmailCodePaste($event)">
        <div>
          <input type="text" pattern="[0-9]" maxlength="1" [(ngModel)]="emailCode[0]"
            (input)="checkIfEmailCodeIsFilled(0)" autofocus #emailOTPInputs (change)="removeError()" />
        </div>
        <div>
          <input type="text" pattern="[0-9]" maxlength="1" [(ngModel)]="emailCode[1]"
            (input)="checkIfEmailCodeIsFilled(1)" #emailOTPInputs (change)="removeError()"
            (keyup)="removeEmailOTPBack($event, 0)" />
        </div>
        <div>
          <input type="text" pattern="[0-9]" maxlength="1" [(ngModel)]="emailCode[2]"
            (input)="checkIfEmailCodeIsFilled(2)" #emailOTPInputs (change)="removeError()"
            (keyup)="removeEmailOTPBack($event, 1)" />
        </div>
        <div>
          <input type="text" pattern="[0-9]"  maxlength="1" [(ngModel)]="emailCode[3]"
            (input)="checkIfEmailCodeIsFilled(3)" #emailOTPInputs (change)="removeError()"
            (keyup)="removeEmailOTPBack($event, 2)" />
        </div>
        <div>
          <input type="text" pattern="[0-9]"  maxlength="1" [(ngModel)]="emailCode[4]"
            (input)="checkIfEmailCodeIsFilled(4)" #emailOTPInputs (change)="removeError()"
            (keyup)="removeEmailOTPBack($event, 3)" />
        </div>
        <div>
          <input type="text" pattern="[0-9]" maxlength="1" [(ngModel)]="emailCode[5]"
            (input)="checkIfEmailCodeIsFilled(5)" #emailOTPInputs (change)="removeError()"
            (keyup)="removeEmailOTPBack($event, 4)" />
        </div>
      </div>
      <button mdbBtn type="button" class="btn-sm app-secondary-btn ml-0 mb-4" (click)="sendEmailCode()">Send
        Code</button>
    </ng-container>
    <div *ngIf="isTwoFactorEnable">
      <h6 class="heading">
        2FA Code
      </h6>
      <div class="d-flex google-code my-2" (paste)="onTwoFACodePaste($event)">
        <div>
          <input type="text" pattern="[0-9]" maxlength="1" [(ngModel)]="code[0]"
            (input)="checkIfTwoFACodeIsFilled(0)" autofocus #twoFAInputs (change)="removeError()" />
        </div>
        <div>
          <input type="text" pattern="[0-9]" maxlength="1" [(ngModel)]="code[1]"
            (input)="checkIfTwoFACodeIsFilled(1)" #twoFAInputs (change)="removeError()"
            (keyup)="removeTwoFAOTPBack($event, 0)" />
        </div>
        <div>
          <input type="text" pattern="[0-9]" maxlength="1" [(ngModel)]="code[2]"
            (input)="checkIfTwoFACodeIsFilled(2)" #twoFAInputs (change)="removeError()"
            (keyup)="removeTwoFAOTPBack($event, 1)" />
        </div>
        <div>
          <input type="text" pattern="[0-9]" maxlength="1" [(ngModel)]="code[3]"
            (input)="checkIfTwoFACodeIsFilled(3)" #twoFAInputs (change)="removeError()"
            (keyup)="removeTwoFAOTPBack($event, 2)" />
        </div>
        <div>
          <input type="text" pattern="[0-9]" maxlength="1" [(ngModel)]="code[4]"
            (input)="checkIfTwoFACodeIsFilled(4)" #twoFAInputs (change)="removeError()"
            (keyup)="removeTwoFAOTPBack($event, 3)" />
        </div>
        <div>
          <input type="text" pattern="[0-9]" maxlength="1" [(ngModel)]="code[5]"
            (input)="checkIfTwoFACodeIsFilled(5)" #twoFAInputs (change)="removeError()"
            (keyup)="removeTwoFAOTPBack($event, 4)" />
        </div>
      </div>
    </div>
    <div class="font-weight-bold mb-3" *ngIf="!isTwoFactorEnable">
      <span>
        Your 2-Factor Authentication is not enabled.
        <a [routerLink]="['/settings']" [queryParams]="{step:'2fa'}" class="p-0"> click here </a> to enable before proceeding.</span>
    </div>
    <div class="position-relative" *ngIf="errorMessage">
      <mdb-error class="mt-2 w-100 text-center">{{ errorMessage }}</mdb-error>
    </div>
    <div class="d-flex justify-content-between p-4">
      <button mdbBtn type="button" class="btn-sm app-secondary-btn w-50" (click)="goBack()">Cancel
      </button>
      <button mdbBtn type="button" class="btn-sm app-primary-btn w-50"
        [disabled]="!allCodeEntered || !allEmailCodeEntered || !isTwoFactorEnable" (click)="continue()">Continue
      </button>
    </div>
    <div class="p-4 text-center" [hidden]="!loading">
      <mdb-spinner spinnerColor="blue"></mdb-spinner>
    </div>
  </div>
</section>
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { APPLICATION_FORM_ACCOUNT_OPPENING_USD, APPLICATION_FORM_CORPORATE_ACCOUNT_GBP_EUR, APPLICATION_FORM_NDA_GBP_EUR, FILE_DELETE_MESG, FILE_SUCCESS_MESG, UNABLE_TO_PERFORM_ERROR } from 'src/app/app.constants';
import { ApplicationFormDocType } from 'src/app/core/enums/applicationFormDocTypeEnum';
import { DeleteApplicationFormDoc } from 'src/app/core/models/create-kyc';
import { FileUpload, IFile } from 'src/app/core/models/Lerex/enum-load';
import { DomainService } from 'src/app/core/services/domain/domain.service';
import { ModalService } from 'src/app/core/services/modal/modal.service';
import { CommonSavedData } from 'src/app/shared/services/commonSavedData.service';
import { SubSink } from 'subsink';
import { KycService } from '../services/kyc.service';

@Component({
  selector: 'cashaa-kyc-business-application-form',
  templateUrl: './kyc-business-application-form.component.html',
  styleUrls: ['./kyc-business-application-form.component.scss']
})
export class KycBusinessApplicationFormComponent implements OnInit, OnDestroy {

  public fileCorporateAccount: IFile = new FileUpload(null);
  public fileNda: IFile = new FileUpload(null);
  public fileUSDApplication: IFile = new FileUpload(null);
  @Input() plan: string = "";
  @Input() businessId: string;
  private sub: Subscription;
  private subSink: SubSink = new SubSink();
  public fileCountUSD: number = 0;
  public fileCountGbp: number = 0;
  @Output() continue: EventEmitter<void> = new EventEmitter();

  constructor(private modalService: ModalService, private commonSavedData: CommonSavedData,
    private kycService: KycService, private toastrService: ToastrService, private domainService: DomainService) { }

  ngOnInit(): void {
    this.fileCorporateAccount.docType = "GBP_EUR_Corporate_Account";
    this.fileNda.docType = "GBP_EUR_NDA";
    this.fileUSDApplication.docType = "USD_Bank_Application";
    this.subSink.add(this.domainService.getKycUploadFile().subscribe(data => {
      if (data && (data.docType === "GBP_EUR_Corporate_Account" ||
        data.docType === "GBP_EUR_NDA" ||
        data.docType === "USD_Bank_Application")) {
        this.onFileSubmit(data);
      }
    }));
    this.subSink.add(
      this.kycService.getApplicationFormDoc().subscribe((data: any[]) => {
        if (data) {
          let id = data.filter(doc => doc.fileType == ApplicationFormDocType.GBP_EUR_Corporate_Account)[0]?.id;
          if (id) {
            this.fileCorporateAccount.fileId = id;
            this.fileCorporateAccount.isDeletedValid = true;
            ++this.fileCountGbp;
          }
          id = data.filter(doc => doc.fileType == ApplicationFormDocType.GBP_EUR_NDA)[0]?.id;
          if (id) {
            this.fileNda.fileId = id;
            this.fileNda.isDeletedValid = true;
            ++this.fileCountGbp;
          }
          id = data.filter(doc => doc.fileType == ApplicationFormDocType.USD_Bank_Application)[0]?.id;
          if (id) {
            this.fileUSDApplication.fileId = id;
            this.fileUSDApplication.isDeletedValid = true;
            ++this.fileCountUSD;
          }
        }
      })
    );
  }

  onFileDelete(event, item: IFile) {
    this.modalService.openConfirmationModal("Do you want to delete the file?");
    this.sub = this.commonSavedData.getConfirmationBoxStatus().subscribe((status: boolean) => {
      if (status) {
        item.fileActionLoader = true;
        this.kycService.deleteApplicationFormDoc(new DeleteApplicationFormDoc(item.fileId)).subscribe((res: any) => {
          item.fileActionLoader = false;
          if (res.message === "success") {
            item.isDeletedValid = false;
            this.toastrService.success(FILE_DELETE_MESG);
            if (this.plan === 'USD') {
              --this.fileCountUSD;
            } else {
              --this.fileCountGbp;
            }
          } else {
            this.toastrService.error(UNABLE_TO_PERFORM_ERROR);
          }
        },
          (err) => {
            item.fileActionLoader = false;
            if (err.error.message) {
              this.toastrService.error(err.error.message);
            } else {
              this.toastrService.error(UNABLE_TO_PERFORM_ERROR);
            }
          });
      }
      this.sub.unsubscribe();
    });
  }

  downloadDoc1(): string {
    return (this.plan === "GBP_EUR" || this.plan === "Crypto") ?
      APPLICATION_FORM_CORPORATE_ACCOUNT_GBP_EUR :
      APPLICATION_FORM_ACCOUNT_OPPENING_USD;
  }

  downloadDoc2(): string {
    return APPLICATION_FORM_NDA_GBP_EUR;
  }

  onFileSubmit(docValue: IFile): void {
    const formData = new FormData();
    formData.append("File", docValue.file);
    formData.append("documentType", ApplicationFormDocType[docValue.docType]);
    formData.append("BusinessKYCStakeholdersId", this.businessId);
    formData.append("DocFor", "BusinessApplicationfile");
    docValue.fileActionLoader = true;
    this.kycService.uploadApplicationFormDoc(formData).subscribe(
      (res: any) => {
        docValue.fileActionLoader = false;
        this.toastrService.success(FILE_SUCCESS_MESG);
        docValue.isDeletedValid = true;
        docValue.fileId = res.message;
        if (this.plan === 'USD') {
          ++this.fileCountUSD;
        } else {
          ++this.fileCountGbp;
        }
      },
      (err) => {
        if (err.error.message) {
          this.toastrService.error(err.error.message);
        } else {
          this.toastrService.error(UNABLE_TO_PERFORM_ERROR);
        }
        docValue.fileActionLoader = false;
      }
    );
  }

  getStatus(): boolean {
    if (this.plan === 'USD' && this.fileCountUSD !== 1) {
      return true;
    } else if ((this.plan === 'GBP_EUR' || this.plan === 'Crypto') && this.fileCountGbp !== 2) {
      return true;
    } else {
      return false;
    }
  }

  onNext(): void {
    this.continue.emit();
  }

  ngOnDestroy(): void {
    this.subSink.unsubscribe();
  }


}

import { Router } from "@angular/router";
import { Component, OnInit, Input, Output, EventEmitter, ViewChild, TemplateRef, AfterViewInit } from "@angular/core";
import { ModalService } from "src/app/core/services/modal/modal.service";
import { UserService } from "src/app/core/services/user/user.service";
import { DepositInterestService } from "src/app/deposit-interest/service/deposit-interest.service";
import { IDepositInterestPlan } from "src/app/core/models/deposit-interest/deposit-interest";
import { USER_COUNTRY_CODE, USER_INTEREST_MODE, USER_TYPE } from "src/app/app.constants";
import { IUserPlan } from "src/app/core/models/user-plan/user-detail";
import { MDBModalService } from "ng-uikit-pro-standard";
import { ActivityService } from "src/app/activity/services/activity.service";

@Component({
  selector: "cashaa-balance-box",
  templateUrl: "./balance-box.component.html",
  styleUrls: ["./balance-box.component.scss"],
})
export class BalanceBoxComponent implements OnInit {

  /** Currency type */
  @Input() allCurrencyInfo;
  diamondPlan: IUserPlan;
  rate: number;
  allCurrenciesPlan: IUserPlan[] = [];
  rateType: string = sessionStorage.getItem(USER_INTEREST_MODE);
  userType = sessionStorage.getItem(USER_TYPE);
  currencyDepositRate: number[] = [];
  maxInterestModeRate: number[] = [];
  currencyDepositRateLessThanEqual365: number[] = [];
  currencyDepositRateGreaterThan365: number[] = [];
  @Output() emitCurrency = new EventEmitter<any>();
  lockedInfo;
  selectedCurrency;
  constructor(private router: Router, private modalService: ModalService, private mdbModalService: MDBModalService
     ,private userService: UserService, private depositService: DepositInterestService,
     private activityService: ActivityService) { }

  ngOnInit(): void {
  }

  goToAccount() {
    this.router.navigateByUrl(`/wallet?c=${this.allCurrencyInfo.currencyType}#${this.allCurrencyInfo.currencyType}`);
  }

  getCurrencyName(curr): string {
    if (curr === "AUSD") return "USD";
    if (curr === "DOG") return "DOGE";
    else return curr;
  }

  openEarnPlan(): void {
    if (this.allCurrencyInfo.currencyType === 'CAS') {
      this.router.navigateByUrl(`deposit-interest?c=${this.allCurrencyInfo.currencyType}`);
    } else {
      this.modalService.openEarnPlanDetailPopup(this.allCurrencyInfo.loyaltyPlanName, this.allCurrencyInfo.upgradeTo, this.allCurrencyInfo.available, this.allCurrencyInfo.currencyId, this.allCurrencyInfo.currencyType, this.allCurrencyInfo.fLexTerm,
        this.allCurrencyInfo.fixedTerm, this.allCurrencyInfo.earnUpTo, this.allCurrencyInfo.longEarnUpTo, this.allCurrencyInfo.minFlexAmount, this.allCurrencyInfo.isFlexSubscribed, this.allCurrencyInfo.casBonus);
    }

  }

  isEarnAndFixedDepositActive(): boolean {
    return sessionStorage.getItem(USER_COUNTRY_CODE) !== 'US';
  }

  isFixedDepositEnable(): boolean {
    // sessionStorage.getItem(USER_TYPE) !== '4' || this.currency === 'CAS'
    return this.allCurrencyInfo.currencyType === 'CAS';
  }

  isFixedDepositAndEarnEnable(): boolean {
    // return sessionStorage.getItem(USER_TYPE) === '4' &&  this.currency !== 'CAS'
    // const userType = sessionStorage.getItem(USER_TYPE);
    return (this.userType === '4' || this.userType === '5' || this.userType === '6') && this.allCurrencyInfo.currencyType !== 'CAS';
  }

  // isEarnModuleNotActive(): boolean {
  //   // return sessionStorage.getItem(USER_TYPE) === '4' && this.currency === 'DOG';
  // }

  isHide(): boolean {
    if (this.rate && this.rate > 0) {
      return false;
    } else {
      return true;
    }
  }
  selectCurrency(event) {
    const data = {
      currency: this.allCurrencyInfo.currencyType,
      isSelected: event.checked
    }
    this.emitCurrency.emit(data);
  }
  openBorrowFlow() {
    this.router.navigateByUrl(`credit-line?c=${this.allCurrencyInfo.currencyType}`);
  }

  open() {
    this.activityService.getLockedAmountByCurrency(this.allCurrencyInfo.currencyId).subscribe(res => {
      this.lockedInfo = res;
    })
  }

  // getCurrencyInfo() {
  //   this.userService.getFlexAndFdDetail().subscribe((res) => {
  //     let currencyInfo = res;
  //     this.selectedCurrency = currencyInfo.filter(item => item.currencyId == this.currencyId);
  //   }, err => {
  //   });
  // }
}

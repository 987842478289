<div class="modal-header position-relative sm-form-title">
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span style="font-size: 35px;" aria-hidden="true" (click)="onClick(false)">×</span>
    </button>
    <h3 class="modal-title w-100 mt-3 sm-custom-header" id="myModalLabel">Confirmation</h3>
  </div>
<div class="modal-body font-weight-bolder" [innerHTML]="mesg">
</div>
<div class="modal-footer text-center mt-3">
  <button mdbBtn type="button" (click)="onClick(true)" color="primary" mdbWavesEffect class="btn-sm">
    Yes
  </button>
  <button mdbBtn type="button" (click)="onClick(false)" outline="true" mdbWavesEffect
    class="sm-btn-input-hover btn-border btn-sm">
    No
  </button>
</div>
import { CashaaUserFeeMode } from "./../../core/models/cashaa-user-fee-request";

import { IFormSelect } from "./../../core/models/form-select";
import { DomainService } from "src/app/core/services/domain/domain.service";
import {
  USER_ID,
  USER_FEE_MODE,
  USER_FEE_PERCENT,
  UNABLE_TO_PERFORM_ERROR,
  FEE_MODE_UPDATED,
} from "./../../app.constants";
import { FormGroup } from "@angular/forms";
import { SettingsService } from "./../services/settings.service";
import { FormBuilder } from "@angular/forms";
import { ValidatorUtility } from "./../../core/utility/validator.utility";
import { Component, OnInit } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "cashaa-settings-fee",
  templateUrl: "./settings-fee.component.html",
  styleUrls: ["./settings-fee.component.scss"],
})
export class SettingsFeeComponent implements OnInit {
  /** is opened */
  isOpen = false;

  /** show form or not */
  showForm = false;

  /** is some call in progress */
  isInProgress = false;

  /** form group */
  form: FormGroup;
  feeMode?: IFormSelect[];
  /** selected fee mode */
  selectedFeeMode: any;
  isChecked:boolean;
  /** user user fee mode address */
  userFeeMode = sessionStorage.getItem(USER_FEE_MODE);
  userId = sessionStorage.getItem(USER_ID);

  errorMessage: string;

  isCollapsed = true;

  constructor(
    private fb: FormBuilder,
    private settingsService: SettingsService,
    private domainService: DomainService,
    private toastrService: ToastrService,
    private activateRoute: ActivatedRoute
  ) {
    this.createForm();
  }

  ngOnInit(): void {
    this.getUserFeeMode();
    this.createForm();
  }
  //
  /**
   * create new form
   */
  createForm(): void {
    this.activateRoute.queryParams.subscribe((params) => {
      const fee = params["step"];
      if (fee === "fee") {
        this.isCollapsed = false;
        this.isOpen = true;
      }
    });
    this.form = this.fb.group({
      ufeeMode: [this.userFeeMode, ValidatorUtility.Required],
    });
  }

  onFeeChangeFormSubmit(): void {
    
    if(this.isChecked) {
      this.form.controls["ufeeMode"].setValue('InNative');
    } else {
      this.form.controls["ufeeMode"].setValue('InCashaa');
    }
    this.isInProgress = true;
    this.isChecked = !this.isChecked;
    const reqData = new CashaaUserFeeMode(this.form.controls["ufeeMode"].value);
   console.log(this.form.controls["ufeeMode"].value)
   const userFreeModeValue = this.form.controls["ufeeMode"].value;
    this.settingsService.updateUserMode(reqData).subscribe(
      (res) => {
        this.showForm = false;
        this.isInProgress = false;
        sessionStorage.setItem(USER_FEE_MODE, userFreeModeValue);
        this.userFeeMode = userFreeModeValue;
        // this.userFeeMode === 'InCashaa'? this.isChecked = true: this.isChecked = false;
        this.toastrService.success(FEE_MODE_UPDATED);
      },
      (err) => {
        if (err.error.message) {
          this.toastrService.error(err.error.message);
          this.errorMessage = err.error.message;
        } else {
          this.toastrService.error(UNABLE_TO_PERFORM_ERROR);
        }
      }
    );
  }

  getUserFeeMode() {
    this.domainService.getUserFeeModeList().subscribe((resData) => {
      this.feeMode = resData.map((d) => {
        return {
          value: d.key,
          label: this.getUserFeeModeLabel(d.key),
        };
      });
      console.log(this.feeMode);
      console.log(this.userFeeMode);
      this.userFeeMode === 'InCashaa'? this.isChecked = true: this.isChecked = false;
    });
  }

  getUserFeeModeLabel(label: string) {
    if (label.startsWith('In')) {
        return label.split('In').join('In ');
    } else {
      return label;
    }
  }
}

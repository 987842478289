<div class="position-relative" [formGroup]="form" *ngIf="form">
  <input
    mdbInput
    type="number"
    class="form-contro pl-2"
    [formControlName]="name"
    [placeholder]="placeholder"
    [ngClass]="{
      'error-border': control?.invalid
    }"
    (change)="onInput($event.target.value)"
  />

  <mdb-error class="error-text" *ngIf="control?.invalid">{{
    getErrorMessage()
  }}</mdb-error>

  <h6 class="currencyType position-absolute font-weight-bold">
    {{ currency }}
  </h6>
</div>

import { UnauthorizedComponent } from "./shared/components/unauthorized/unauthorized.component";
import { NotFoundComponent } from "./shared/components/not-found/not-found.component";
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

const routes: Routes = [
  {
    path: "",
    pathMatch: "full",
    redirectTo: "account/signin",
  },
  {
    path: "unauthorized",
    component: UnauthorizedComponent,
  },
  {
    path: "**",
    component: NotFoundComponent,
  },
  {
    path: "notauthorized",
    component: NotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}

<!--test new-->
<mdb-accordion>
  
  <mdb-accordion-item [collapsed]='isCollapsed'>
    <mdb-accordion-item-head [customClass]="isOpen ? 'pl-4' : 'settings-card pl-4'"
      (click)="isOpen = !isOpen; showForm = false">
      <div class="d-flex align-items-center">
        <img [src]="'../../../../assets/images/settings/2-factor.svg'" />
        <!-- <mdb-icon fas icon="mobile-alt" class="p-2 card-icon" [ngClass]="isOpen ? '' : 'bg-white'"></mdb-icon> -->

        <div class="ml-4 setting-card-open">
          <h6 class="mb-1 heading">2-Step Login / 2-Factor Authentication</h6>
          <span class="mb-0 font-weight-normal">
            Status:
            <span class="green-text" *ngIf="isTwoFaEnable()">
              On (2-Factor Authentication)
            </span>
            <span class="red-text" *ngIf="!isTwoFaEnable()">
              Off (2-Factor Authentication)
            </span>
            <span *ngIf="isLoading"> Loading...
              <mdb-spinner spinnerColor="blue"></mdb-spinner>
            </span>
          </span>
        </div>
      </div>
    </mdb-accordion-item-head>
    <mdb-accordion-item-body customClass="position-relative">
      <div *ngIf="!isTwoFaEnable()">
        <hr class="mb-5" />
        <div class="email-info ml-5" *ngIf="!showForm">
          <!-- click)="showForm = true" -->
          <button mdbBtn (click)="get2fakey()" block="true" type="button"
            class="rounded-sm position-relative btn btn-primary waves-effect waves-light btn-block w-auto ml-0 mb-4"
            mdbWavesEffect>
            Enable 2-step login
          </button>

        </div>

        <div class="d-flex flex-column justify-content-center email-form" *ngIf="showForm">
          <div>
            <img style="width: 200px;height:200px;" src="{{google2fa?.url}}">
          </div>

          <div class="position-relative mb-5 mt-2">
            <div class="mt-2 border d-flex p-3 justify-content-between copy-download">
              <div style="font-weight: 700!important">{{google2fa.secrateKey | uppercase}}</div>
              <div>
                <i mdbTooltip="Copy 2FA secret key" placement="top" class="fas fa-clone ml-2"
                [ngClass]="copied ? 'text-primary' : ''" (click)="copyAddress()"></i>
                <a [href]="fileUrl" download={{downloadFileName()}} style="color:#626262;"><i mdbTooltip="Save your 2FA secret key" class="fas fa-download ml-2"></i></a>
              </div>
             
            </div>
            <div class="d-flex google-code mt-4" (paste)="onCodePaste($event)">
              <div>
                <input type="text" [disabled]="isInProgress" maxlength="1" [(ngModel)]="code[0]"
                  (input)="checkIfCodeIsFilled(0)" autofocus #inputs (change)="removeError()" />
              </div>
              <div><hr class="dash"></div>
              <div>
                <input type="text" [disabled]="isInProgress" maxlength="1" [(ngModel)]="code[1]"
                  (input)="checkIfCodeIsFilled(1)" #inputs (change)="removeError()" (keyup)="removeBack($event, 0)" />
              </div>
              <div><hr class="dash"></div>
              <div>
                <input type="text" [disabled]="isInProgress" maxlength="1" [(ngModel)]="code[2]"
                  (input)="checkIfCodeIsFilled(2)" #inputs (change)="removeError()" (keyup)="removeBack($event, 1)" />
              </div>
              <div><hr class="dash"></div>
              <div>
                <input type="text" [disabled]="isInProgress" maxlength="1" [(ngModel)]="code[3]"
                  (input)="checkIfCodeIsFilled(3)" #inputs (change)="removeError()" (keyup)="removeBack($event, 2)" />
              </div>
              <div><hr class="dash"></div>
              <div>
                <input type="text" [disabled]="isInProgress" maxlength="1" [(ngModel)]="code[4]"
                  (input)="checkIfCodeIsFilled(4)" #inputs (change)="removeError()" (keyup)="removeBack($event, 3)" />
              </div>
              <div><hr class="dash"></div>
              <div>
                <input type="text" [disabled]="isInProgress" maxlength="1" [(ngModel)]="code[5]"
                  (input)="checkIfCodeIsFilled(5)" #inputs (change)="removeError()" (keyup)="removeBack($event, 4)" />
              </div>
            </div>
            <mdb-error class="mt-2" *ngIf="errorMessage">{{ errorMessage }}</mdb-error>

          </div>
          <!-- <mdb-error class="mt-2" *ngIf="errorMessage">{{ errorMessage }}</mdb-error> -->

          <!-- <button [disabled]="isInProgress || form.invalid" mdbBtn (click)="onAuthFormSubmit()" block="true"
            type="button"
            class="rounded-sm position-relative btn btn-primary waves-effect waves-light btn-block w-25 w-60 ml-0 mb-4"
            mdbWavesEffect>
            Enable 2-step login
          </button> -->
        </div>
      </div>
      <div *ngIf="isTwoFaEnable()">
        <hr class="mb-5" />
        <div class="email-info ml-5" *ngIf="!twofaBoxEnable">
          <!-- click)="showForm = true" -->
          <button mdbBtn (click)="disableTwoFa()" block="true" type="button"
            class="rounded-sm position-relative btn btn-primary waves-effect waves-light btn-block w-auto ml-0 mb-4"
            mdbWavesEffect>
            Disable 2-step login
          </button>
        </div>

        <div class="d-flex flex-column justify-content-center email-form" *ngIf="twofaBoxEnable">
          <div class="position-relative mb-5 w-100 mt-2 text-center">
            <div class="d-flex justify-content-center google-code mt-4" (paste)="onCodePaste($event)">
              <div>
                <input type="text" [disabled]="isInProgress" maxlength="1" [(ngModel)]="code[0]"
                  (input)="checkIfCodeIsFilled(0)" autofocus #inputs (change)="removeError()" />
              </div>
              <div>
                <input type="text" [disabled]="isInProgress" maxlength="1" [(ngModel)]="code[1]"
                  (input)="checkIfCodeIsFilled(1)" #inputs (change)="removeError()" (keyup)="removeBack($event, 0)" />
              </div>
              <div>
                <input type="text" [disabled]="isInProgress" maxlength="1" [(ngModel)]="code[2]"
                  (input)="checkIfCodeIsFilled(2)" #inputs (change)="removeError()" (keyup)="removeBack($event, 1)" />
              </div>
              <div>
                <input type="text" [disabled]="isInProgress" maxlength="1" [(ngModel)]="code[3]"
                  (input)="checkIfCodeIsFilled(3)" #inputs (change)="removeError()" (keyup)="removeBack($event, 2)" />
              </div>
              <div>
                <input type="text" [disabled]="isInProgress" maxlength="1" [(ngModel)]="code[4]"
                  (input)="checkIfCodeIsFilled(4)" #inputs (change)="removeError()" (keyup)="removeBack($event, 3)" />
              </div>
              <div>
                <input type="text" [disabled]="isInProgress" maxlength="1" [(ngModel)]="code[5]"
                  (input)="checkIfCodeIsFilled(5)" #inputs (change)="removeError()" (keyup)="removeBack($event, 4)" />
              </div>
            </div>
            <mdb-error class="mt-2" *ngIf="errorMessage">{{ errorMessage }}</mdb-error>
          </div>
        </div>
      </div>

      <!-- Progress bar -->
      <mdb-progress-bar *ngIf="isInProgress" class="progress primary-color-dark position-absolute" mode="indeterminate">
      </mdb-progress-bar>
    </mdb-accordion-item-body>
  </mdb-accordion-item>
</mdb-accordion>
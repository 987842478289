import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AccountService } from "../sevices/account.service";

@Component({
  selector: "cashaa-account-wrapper",
  templateUrl: "./account-wrapper.component.html",
  styleUrls: ["./account-wrapper.component.scss"],
})
export class AccountWrapperComponent implements OnInit {
  disableCross: boolean;
  constructor(private accountService: AccountService) {}

  ngOnInit(): void {
    this.accountService.disableCrossEmitter().subscribe((data) => {
      setTimeout(() => {
        this.disableCross = data;
      });
    });
  }
}

import { USER_NAME_PATH, USER_EMAIL_PATH } from "./../../app.constants";
import { AddRecipient } from "./../../core/models/recipient/recipient-add";
import { ICurrency } from "./../../core/models/currency";
import { ValidatorUtility } from "./../../core/utility/validator.utility";
import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder } from "@angular/forms";
import { RecipientService } from "../services/recipient.service";
import { MDBModalRef } from "ng-uikit-pro-standard";

@Component({
  selector: "cashaa-recipient-add-bankaccount",
  templateUrl: "./recipient-add-bankaccount.component.html",
  styleUrls: ["./recipient-add-bankaccount.component.scss"],
})
export class RecipientAddBankaccountComponent implements OnInit {
  /** Recipient Form Group */
  recipientAddForm: FormGroup;

  /** is some network call in progress */
  isInProgress = false;

  isCrypto = true;

  constructor(
    private fb: FormBuilder,
    public modalRef: MDBModalRef,
    private recipientService: RecipientService
  ) {}

  ngOnInit(): void {
    this.createForm();
  }

  /**
   * Create new form
   */
  createForm(): void {
    const userEmail = sessionStorage.getItem(USER_EMAIL_PATH);
    const userName = sessionStorage.getItem(USER_NAME_PATH);
    this.recipientAddForm = this.fb.group({
      currency: ["", [ValidatorUtility.Required]],
      email: [
        { value: userEmail, disabled: true },
        ,
        [ValidatorUtility.Required],
      ],
      fullName: [
        { value: userName, disabled: true },
        [ValidatorUtility.Required],
      ],
      accountNumber: ["", [ValidatorUtility.OnlyNumberRequired]],
      sortCode: ["", [ValidatorUtility.Required]],
      ibanOrSortCode: ["", [ValidatorUtility.Required]],
      userType: ["4"],
      cryptoAddress: ["", ValidatorUtility.Required],
      cryptoEmail: ["", ValidatorUtility.Required],
      cryptoPassword: ["", ValidatorUtility.Required],
    });
  }

  listenToCurrencyChange(newValue: ICurrency): void {
    if (newValue.currencyType === 2) {
      this.isCrypto = false;
    } else {
      this.isCrypto = true;
    }
  }

  /**
   * On submission of form
   */
  onFormSubmit(): void {
    if (this.isCrypto) {
      // call new api here for crypto
    }

  //   const data = this.recipientAddForm.value;

  //   const recipientData = new AddRecipient(
  //     data.currency.value,
  //     parseInt(data.userType, 10),
  //     data.email,
  //     data.fullName,
  //     data.ibanOrSortCode,
  //     data.sortCode,
  //     data.accountNumber,
  //     "Self Bank Account"
  //   );

  //   this.isInProgress = true;
  //   this.recipientService.addRecipientRequest(recipientData).subscribe(
  //     (res) => {
  //       this.isInProgress = false;
  //       this.modalRef.hide();
  //       this.recipientService.emitaddRecipientFlag(true);
  //     },
  //     (err) => {
  //       this.isInProgress = false;
  //       this.modalRef.hide();
  //     }
  //   );
  }
}

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class EventFeeService {
  private apiUrl = environment.api;
  private isLoading = new BehaviorSubject<boolean>(false);
  private customerAddFlag = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient) { }


  isTicketConfirmed() {
    return this.http.get<any>(
      `${this.apiUrl}/event/isticketconfirmed`
    );
  }

  /**
* Create event payment
* @param payload  contains currency Id and selected event subscription type value.
*/
  createEventPaymentAddress(
    payload
  ): Observable<{ statusCode: number; message: string }> {
    return this.http.post<{ statusCode: number; message: string }>(
      `${this.apiUrl}/event/create`,
      payload
    );
  }

  /**
* Create event payment
* @param payload  contains currencyId, event Subscription, amount.
*/
  confirmEventPaymentTicket(
    payload
  ): Observable<{ statusCode: number; message: string }> {
    return this.http.post<{ statusCode: number; message: string }>(
      `${this.apiUrl}/event/create`,
      payload
    );
  }

   /**
* Create event payment
* @param payload  contains currencyId, event Subscription, amount.
*/
confirmTicketDetails(
  payload
): Observable<{ statusCode: number; message: string }> {
  return this.http.post<{ statusCode: number; message: string }>(
    `${this.apiUrl}/event/confirmticket`,
    payload
  );
}

  getDepositPaymentHistory() {
    return this.http.get<any>(
      `${this.apiUrl}/event/geteventledgerlist`
    );
  }

  getTicketDetails() {
    return this.http.get<any>(
      `${this.apiUrl}/event/getticketdetails`
    );
  }

}



<section
    class="wrapper bg-white d-flex justify-content-center position-relative sm-width-phone-700 sm-width-phone-800-height">
    <img class="position-absolute logo" src="../../../assets/media/logo-dark.svg" />

    <div class="position-absolute close text-primary-light" (click)="backRoute()">
        <mdb-icon fas icon="times"></mdb-icon>
    </div>

    <div class="w-stepper-60 w-phone-100">
        <mdb-stepper #stepper [linear]="true" class="w-60 sm-convert-mdbStepper-mob">
            <mdb-step name="Amount" class="step">
                <cashaa-deposit-interest-amount (continue)="onAmount($event)">
                </cashaa-deposit-interest-amount>
            </mdb-step>
            <mdb-step name="Confirm" class="step">
                <cashaa-deposit-interest-confirm [createPlanData]="createPlan"></cashaa-deposit-interest-confirm>
            </mdb-step>
        </mdb-stepper>
    </div>
</section>    
<section class="activity-box px-3 mb-2">
    <div class="details">
        <mdb-accordion [multiple]="false" aria-multiselectable="false">
            <mdb-accordion-item class="no-collase">
                <mdb-accordion-item-head class="p-2" mdbWavesEffect>
                    <div class="d-flex flex-row px-4 py-2 align-items-center">
                        <div class="">
                            <img src="../../../assets/images/Fees.png" style="height: 35px; width: 35px;" />
                        </div>
                        <div class="status ml-4"><span class="text-primary-dark font-weight-bold">Staked for 30% APR</span></div>
                        <div class="amount ml-auto mr-4">
                            <b>{{ ledger.amount | number: "0.0-4" }} {{ ledger.currency }}</b><br />
                            <span class="text-gray">{{ ledger.amount + ledger.networkFee | number: "0.0-4" }}
                {{ ledger.currency }}</span>
                        </div>
                    </div>
                </mdb-accordion-item-head>
                <mdb-accordion-item-body class="border-top border-light px-4 position-relative">
                    <div class="container px-4 pt-2">
                        <div class="row">
                            <div class="col-sm">
                                To <br />
                                <b class="font-weight-bolder text-primary">Cashaa</b>
                            </div>
                        </div>
                    </div>
                </mdb-accordion-item-body>
            </mdb-accordion-item>
        </mdb-accordion>
    </div>
</section>
<cashaa-sidenav>
  <section class="mh-100">
    <div class="bg-white p-4">
      <h1 class="sm-page-heading sm-profile-heading-mobile">Business Details</h1>
    </div>
  </section>
  <div class="container sm-width-phone-700 ">

    <cashaa-profile-business (editProfile)="editProfile()"></cashaa-profile-business>
  </div>
  <!-- <section class="mh-100">
    <div class="bg-white p-4">
      <h4 class="mb-4 text-dark font-weight-bolder">Business details</h4>
      <button
        mdbBtn
        type="button"
        outline="true"
        mdbWavesEffect
        (click)="editProfile()"
      >
        Edit Profile
      </button>
    </div>
  </section>
  <cashaa-profile-business></cashaa-profile-business> -->
</cashaa-sidenav>
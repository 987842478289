<cashaa-sidenav>
  <div class="text-title">
    <div class="bg-white p-4">
      <h3 class="font-weight-normal">Select Subscription Plan to be reserved</h3>

    </div>
  </div>
  <form [formGroup]="upgradeSubscriptionForm" class="my-4">
    <div class="mb-4">
      <cashaa-form-input name="lockingAmount" type="lockingAmount" [form]="upgradeSubscriptionForm"
        [control]="upgradeSubscriptionForm.controls.lockingAmount" placeholder="Enter locking amount 100K">
      </cashaa-form-input>
    </div>
    <div class="mb-4">
      <cashaa-form-input name="lockingPeriod" type="lockingPeriod" [form]="upgradeSubscriptionForm"
        [control]="upgradeSubscriptionForm.controls.lockingPeriod" placeholder="Enter locking year in numeric">
      </cashaa-form-input>
    </div>
    <div class="mb-4">
      <cashaa-form-input name="password" type="password" [form]="upgradeSubscriptionForm"
        [control]="upgradeSubscriptionForm.controls.password" placeholder="Enter locking year in numeric">
      </cashaa-form-input>
    </div>
    <div class="mb-4">
      <button mdbBtn (click)="onSignupSubmit()" block="false" type="button"
        class="rounded-sm position-relative btn btn-secondary sm-custom-sec waves-effect waves-light btn-block"
        mdbWavesEffect>
        <i class="fa-lock fas position-absolute"></i>Create Account
      </button>
    </div>

  </form>


</cashaa-sidenav>
import { CashaaFeeMode } from "./../enums/cashaa-fee-mode";
export interface ICashaaUserFeeMode {
    userFeeMode: CashaaFeeMode;
}

export class CashaaUserFeeMode implements ICashaaUserFeeMode {
    constructor(
        public userFeeMode: CashaaFeeMode,
    ) { }
}

export interface IPay {
  fromCurrencyId: string;
  toCurrencyId: string;
  amount: number;
  feeTypeId: string;
  remark: string;
  fiatBeneficiaryId: string;
  isrecipientSkiped: boolean;
  currency?: string;
  accountNumber?: string;
  password?: PasswordType;
  networkType?: string;
  recipientType?: number;
}
export class Pay implements IPay {
  constructor(
    public fromCurrencyId: string,
    public toCurrencyId: string,
    public amount: number,
    public feeTypeId: string,
    public remark: string,
    public fiatBeneficiaryId: string,
    public isrecipientSkiped: boolean,
    public currency?: string,
    public accountNumber?: string,
    public password?: PasswordType,
    public networkType?: string,
    public recipientType?: number,
  ) { }
}

export interface PasswordType {
  password?: string;
  twoFA?: string
}
export interface ISkipBenificry {
  fiatBeneficiaryId: string;
  isRecipientSkip: boolean;
  currencySymbol: string;

}
export class SkipBenificry implements ISkipBenificry {
  constructor(

    public fiatBeneficiaryId: string,
    public isRecipientSkip: boolean,
    public currencySymbol: string,

  ) { }

  
}

export interface IFiatRecipient {
  currencySymbol: string;
  network: number;
  recipientType?: number
}

export class FiatRecipient {
  constructor(
    public currencySymbol: string,
    public network: number,
    public recipientType?: number
  ) {}
}
import { Component, OnInit, ElementRef } from "@angular/core";
import { MDBModalRef } from "ng-uikit-pro-standard";

@Component({
  selector: "cashaa-message-box",
  templateUrl: "./message-box.component.html",
  styleUrls: ["./message-box.component.scss"],
})
export class MessageBoxComponent implements OnInit {
  title = "";
  body = "";

  constructor(public modalRef: MDBModalRef) {}

  ngOnInit(): void {}
}

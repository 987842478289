<div class="modal-header position-relative sm-form-title">
  <button type="button" class="close pull-right sm-close" aria-label="Close" (click)="modalRef.hide()">
    <span aria-hidden="true">×</span>
  </button>
  <h3 class="modal-title w-100 heading" id="myModalLabel">
    {{getModalName()}}
  </h3>
</div>
<div class="modal-body">
  <div class="p-4 text-center" [hidden]="!isLoading">
    <mdb-spinner spinnerColor="blue"></mdb-spinner>
  </div>
  <form [formGroup]="recipientAddForm" class="position-relative sm-font-family sm-form-padding"
    [ngClass]="{ hide: isLoading }">
    <div class="mb-4">
      <cashaa-form-select-transfer-currency [initialValue]="initialValue"
        (sendCurrencyChangeEvent)="onCurrencyChange($event)" [noInput]='true' [noAccountCurrency]='true'
        [form]="recipientAddForm" class="width-100" [currencyControl]="recipientAddForm.controls.currency"
        label="Currency" currencyControlName="currency" source={{getCurrencySource()}} [disable]='getStatusCurrency()'
        [isRecipientComponent]='true' [addAddress]="addressType">
      </cashaa-form-select-transfer-currency>

    </div>
    <div class="row mb-4" *ngIf="beneficiarytype==='broker'">
      <div class="form-check col-5 sm-userType-boxes">
        <input type="radio" formControlName="userType" class="form-check-input" id="personalAccount" name="userType"
          mdbInput value="4" />
        <label class="form-check-label" for="personalAccount">Individual</label>
      </div>
      <div class="form-check col-5 sm-userType-boxes">
        <input type="radio" formControlName="userType" class="form-check-input" id="businessAccount" name="userType"
          mdbInput value="5" />
        <label class="form-check-label" for="businessAccount">Business</label>
      </div>
    </div>
    <div class="row mb-4" *ngIf="
        (currencySymbol === 'GBP' ||
        currencySymbol === 'EUR' ||
        currencySymbol === 'USD') && (!isPremiumPlan)
      ">
      <div class="form-check col-5 sm-userType-boxes">
        <input type="radio" formControlName="recipientType" class="form-check-input" id="selfType" name="recipientType"
          mdbInput value="1" />
        <label class="form-check-label" for="selfType">Self</label>
        <button type="button" class="sm-tooltip" mdbTooltip="Own account" placement="top">
          <mdb-icon class="pl-2 info-circle" title="" fas icon="info-circle"></mdb-icon>
        </button>
      </div>
      <div class="form-check col-5 sm-userType-boxes">
        <input type="radio" formControlName="recipientType" class="form-check-input" id="otherType" name="recipientType"
          mdbInput value="2" />
        <label class="form-check-label" for="otherType">Others</label>
        <button type="button" class="sm-tooltip" mdbTooltip="Third-party bank account" placement="top">
          <mdb-icon class="pl-2 info-circle" title="" fas icon="info-circle"></mdb-icon>
        </button>
      </div>
    </div>

    <div *ngIf="!isCrypto">
      <div *ngIf="currencySymbol === 'GBP'">
        <div class="mb-4">
          <cashaa-form-input name="email" type="email" text="100" [form]="recipientAddForm"
            [control]="recipientAddForm.controls.email" label="Email">
          </cashaa-form-input>
        </div>

        <div class="mb-4">
          <cashaa-form-input name="fullName" type="text" text="100" [form]="recipientAddForm"
            [control]="recipientAddForm.controls.fullName" [label]="getUsdFirstNameLabel(userType,true)">
          </cashaa-form-input>
          <p *ngIf="!isPremiumPlan && recipientAddForm.get('recipientType').value === '2'" class="mt-2 font-weight-normal">* Do you want to send and receive money from someone
            else.?
            <a [routerLink]="['/subscription/plan']" (click)="modalRef.hide()">
              Click here to upgrade.
            </a>
          </p>
        </div>

        <div>
          <!-- <h5 class="heading">
            Bank Details
          </h5>
          <hr class="mb-4" /> -->
          <!-- Nav tabs -->
          <mdb-tabset #staticTabs [buttonClass]="'nav md-pills pills-primary mb-2'" [justified]="true"
            class="recipientTab">
            <!--Panel 1-->

            <span class="mandatory">*</span>
            <mdb-tab heading="Local Bank Account">
              <div class="row">
                <div class="col-12">
                  <cashaa-form-input-sortcode [initialValue]="initialValueSortCode" name="sortCode"
                    (change)="localBankEvent($event)" [form]="recipientAddForm"
                    [control]="recipientAddForm.controls.sortCode" label="UK Sort code">
                  </cashaa-form-input-sortcode>
                  <br />
                  <cashaa-form-input name="accountNumber" (change)="localBankEvent($event)" type="text"
                    [form]="recipientAddForm" [control]="recipientAddForm.controls.accountNumber" placeholder="12345678"
                    label="Account Number">
                  </cashaa-form-input>
                  <br />
                </div>
              </div>
            </mdb-tab>
            <mdb-tab heading="IBAN">
              <div class="row">
                <div class="col-12">
                  <cashaa-form-input name="ibanOrSortCode" (change)="ibanEvent($event)" type="text"
                    [form]="recipientAddForm" [control]="recipientAddForm.controls.ibanOrSortCode"
                    placeholder="GB89370400440532013000" label="IBAN">
                  </cashaa-form-input>
                  <br />
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <cashaa-form-input name="swiftOrBic" (change)="ibanEvent($event)" type="text"
                    [form]="recipientAddForm" [control]="recipientAddForm.controls.swiftOrBic" placeholder="BUKBGB22"
                    label="SWIFT OR BIC Code">
                  </cashaa-form-input>
                  <br />
                </div>
              </div>
            </mdb-tab>

            <!--Panel 2-->

          </mdb-tabset>
        </div>
        <!--New Fields Entry-->
        <!-- <h5 class="heading">Bank Address</h5> -->
        <!-- <p>*Bank address required for payments made outside of UK</p> -->
        <!-- <hr class="mb-4" /> -->
        <div class="mb-4">
          <cashaa-form-input name="bankName" type="bankName" text="100" [form]="recipientAddForm"
            [control]="recipientAddForm.controls.bankName" label="Bank Name">
          </cashaa-form-input>
        </div>
        <div class="mb-4">
          <cashaa-form-input name="branchName" type="branchName" text="100" [form]="recipientAddForm"
            [control]="recipientAddForm.controls.branchName" label="Bank Branch Name">
          </cashaa-form-input>
        </div>

        <div class="mb-4">
          <cashaa-form-input name="city" type="city" text="100" [form]="recipientAddForm"
            [control]="recipientAddForm.controls.city" label="Bank City">
          </cashaa-form-input>
        </div>
        <div class="mb-4"
          *ngIf="beneficiarytype === 'broker' && recipientAddForm.get('userType').value === '5'">
          <cashaa-form-input name="googleDriveUrl" type="text" [form]="recipientAddForm"
            [control]="recipientAddForm.controls.googleDriveUrl" label="Google Drive Url" [infoMesg]="getInfoMesg()">
          </cashaa-form-input>
        </div>

        <hr class="mb-4" />
        <!--End New Entry -->
      </div>

      <div *ngIf="currencySymbol === 'EUR' && (isPremiumPlan || recipientAddForm.get('recipientType').value === '1')">
        <div class="mb-4">
          <cashaa-form-input name="email" type="email" text="100" [form]="recipientAddForm"
            [control]="recipientAddForm.controls.email" label="Email" 
            [hidden]="recipientAddForm.controls.recipientType.value == '1'">
          </cashaa-form-input>
        </div>

        <div class="mb-4">
          <cashaa-form-input name="fullName" type="text" text="100" [form]="recipientAddForm" [hidden]="recipientAddForm.controls.recipientType.value == '1'"
            [control]="recipientAddForm.controls.fullName" [label]="getUsdFirstNameLabel(userType,true)">
          </cashaa-form-input>
          <!-- <p *ngIf="!isPremiumPlan && recipientAddForm.get('recipientType').value === '2'" class="mt-2 font-weight-normal">* Do you want to send and receive money
            from someone else?
            <a [routerLink]="['/subscription/plan']" (click)="modalRef.hide()">
              Click here to upgrade.
            </a>
          </p> -->
        </div>

        <div>
          <!-- <h5 class="font-weight-normal text-primary">
            Bank Details
          </h5>
          <hr class="mb-4" /> -->
          <!-- Nav tabs -->
          <!-- <mdb-tabset #staticTabs [buttonClass]="'nav md-pills pills-primary mb-2'" [justified]="true"
            class="recipientTab"> -->
            <!--Panel 1-->
            <!-- <span class="mandatory-EUR">*</span> -->
            <!-- <mdb-tab heading="IBAN"> -->
              <div class="row">
                <div class="col-12">
                  <cashaa-form-input name="ibanOrSortCode" (change)="ibanEvent($event)" type="text"
                    [form]="recipientAddForm" [control]="recipientAddForm.controls.ibanOrSortCode"
                    placeholder="GB89370400440532013000" label="IBAN">
                  </cashaa-form-input>
                  <br />
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <cashaa-form-input name="swiftOrBic" (change)="ibanEvent($event)" type="text"
                    [form]="recipientAddForm" [control]="recipientAddForm.controls.swiftOrBic" placeholder="BUKBGB22"
                    label="SWIFT OR BIC Code">
                  </cashaa-form-input>
                  <br />
                </div>
              </div>
            <!-- </mdb-tab> -->
            <!-- <mdb-tab heading="Local Bank Account">
              <div class="row">
                <div class="col-12">
                  <cashaa-form-input-sortcode [initialValue]="initialValueSortCode" name="sortCode"
                    (change)="localBankEvent($event)" [form]="recipientAddForm"
                    [control]="recipientAddForm.controls.sortCode" label="UK Sort Code">
                  </cashaa-form-input-sortcode>
                  <br />
                  <cashaa-form-input name="accountNumber" (change)="localBankEvent($event)" type="text"
                    [form]="recipientAddForm" [control]="recipientAddForm.controls.accountNumber" placeholder="12345678"
                    label="Account Number">
                  </cashaa-form-input>
                  <br />
                </div>
              </div>
            </mdb-tab> -->
            <!--Panel 2-->

          <!-- </mdb-tabset> -->
        </div>
        <!--New Fields Entry-->
        <!-- <h5 class="font-weight-normal text-primary">Bank Address</h5> -->
        <!-- <p>*Bank address required for payments made outside of UK</p> -->
        <!-- <hr class="mb-4" /> -->
        <div class="mb-4">
          <cashaa-form-input name="bankName" type="bankName" text="100" [form]="recipientAddForm"
            [control]="recipientAddForm.controls.bankName" label="Bank Name">
          </cashaa-form-input>
        </div>
        <div class="mb-4">
          <cashaa-form-input name="branchName" type="branchName" text="100" [form]="recipientAddForm"
            [control]="recipientAddForm.controls.branchName" label="Bank Branch Name">
          </cashaa-form-input>
        </div>

        <div class="mb-4">
          <cashaa-form-input name="address1" type="address1" text="100" [form]="recipientAddForm"
            [control]="recipientAddForm.controls.address1" label="Bank Address">
          </cashaa-form-input>
        </div>

        <div class="mb-4">
          <cashaa-form-input name="city" type="city" text="100" [form]="recipientAddForm"
            [control]="recipientAddForm.controls.city" label="Bank City">
          </cashaa-form-input>
        </div>
        <div class="mb-4">
          <cashaa-form-input name="postalCode" type="postalCode" [form]="recipientAddForm"
            [control]="recipientAddForm.controls.postalCode" label="Zip / Postal Code">
          </cashaa-form-input>
        </div>

        <div class="mb-4">
          <cashaa-form-input name="countryCode" type="countryCode" [form]="recipientAddForm"
            [control]="recipientAddForm.controls.countryCode" label="Country Code" infoMesg="UK/US/EU">
          </cashaa-form-input>
        </div>
        <div class="mb-4"
          *ngIf="beneficiarytype === 'broker'&& recipientAddForm.get('userType').value === '5'">
          <cashaa-form-input name="googleDriveUrl" type="text" [form]="recipientAddForm"
            [control]="recipientAddForm.controls.googleDriveUrl" label="Google Drive Url" [infoMesg]="getInfoMesg()">
          </cashaa-form-input>
        </div>
        <hr class="mb-4" />
        <!--End New Entry -->
      </div>

      <div *ngIf="currencySymbol === 'USD' && (isPremiumPlan ||  recipientAddForm.get('recipientType').value === '1')">
        <!-- <h5 class="font-weight-normal text-primary">New Recipient</h5> -->
        <div class="mb-4">
          <cashaa-form-input name="email" type="email" text="100" [form]="recipientAddForm"
            [control]="recipientAddForm.controls.email" label="Email"
            [hidden]="recipientAddForm.controls.recipientType.value == '1'">
          </cashaa-form-input>
        </div>
        <!-- <div class="mb-4">
          <cashaa-form-input name="nickname" type="text" [form]="recipientAddForm"
            [control]="recipientAddForm.controls.nickname" label="Recipient Nickname">
          </cashaa-form-input>
        </div> -->
        <!-- <div class="mb-4">
          <cashaa-form-input name="mobile" type="number" [form]="recipientAddForm"
            [control]="recipientAddForm.controls.mobile" label="Mobile">
          </cashaa-form-input>
        </div> -->
        <!-- <h5 class="font-weight-normal text-primary">{{userType === '4'? 'Personal
          Information':'Business Information'}}</h5> -->
          <div class="mb-4" *ngIf="recipientAddForm.get('recipientType').value === '2'" >
            <cashaa-form-input name="firstName" type="text" [form]="recipientAddForm"
              [control]="recipientAddForm.controls.firstName" [label]="getUsdFirstNameLabel(userType, true)">
            </cashaa-form-input>
          </div>
        <p *ngIf="!isPremiumPlan && recipientAddForm.get('recipientType').value === '2'" class="mt-2 font-weight-normal">* Do you want to send and receive money from someone
          else?
          <a [routerLink]="['/subscription/plan']" (click)="modalRef.hide()">
            Click here to upgrade.
          </a>
        </p>
        <!-- <hr class="mb-4" /> -->
        <!-- <div class="mb-4" *ngIf="userType === '4'">
          <cashaa-form-input name="middleName" type="text" [form]="recipientAddForm"
            [control]="recipientAddForm.controls.middleName" label="Middle Name">
          </cashaa-form-input>
        </div>
        <div class="mb-4" *ngIf="userType === '4'">
          <cashaa-form-input name="lastName" type="text" [form]="recipientAddForm"
            [control]="recipientAddForm.controls.lastName" label="Last Name">
          </cashaa-form-input>
        </div> -->
        <!-- <div class="mb-4"  *ngIf="recipientAddForm.get('recipientType').value === '2'">
          <cashaa-form-input name="address1" type="text" [form]="recipientAddForm"
            [control]="recipientAddForm.controls.address1" label="Address">
          </cashaa-form-input>
        </div> -->
        <!-- <div class="mb-4">
          <cashaa-form-input name="address2" type="text" [form]="recipientAddForm"
            [control]="recipientAddForm.controls.address2" label="Address Line 2">
          </cashaa-form-input>
        </div>
        <div class="mb-4">
          <cashaa-form-input name="address3" type="text" [form]="recipientAddForm"
            [control]="recipientAddForm.controls.address3" label="Address Line 3">
          </cashaa-form-input>
        </div> -->
        <!-- <div class="mb-4" *ngIf="recipientAddForm.get('recipientType').value === '2'">
          <cashaa-form-input name="postalCode" type="text" [form]="recipientAddForm"
            [control]="recipientAddForm.controls.postalCode" label="Zip / Postal Code">
          </cashaa-form-input>
        </div> -->
        <!-- <div class="mb-4">
          <cashaa-form-input name="city" type="text" [form]="recipientAddForm"
            [control]="recipientAddForm.controls.city" label="City">
          </cashaa-form-input>
        </div> -->
        <!-- <div class="mb-4">
          <cashaa-form-input name="province" type="text" [form]="recipientAddForm"
            [control]="recipientAddForm.controls.province" label="State">
          </cashaa-form-input>
        </div>
        <div class="mb-4" *ngIf="userType === '4'">
          <label class="heading">Date Of Birth</label>
          <mdb-date-picker [inline]="true" name="birthDate" [placeholder]="'Date Of Birth'" formControlName="dob"
            [options]="myDatePickerOptions" class="datepicker-white"></mdb-date-picker>
        </div>-->
        <!-- <div class="mb-4" *ngIf="recipientAddForm.get('recipientType').value === '2'">
          <cashaa-form-select-country name="countryOfBirth" [initialValue]="initialValueCountry" type="countryOfBirth"
            [form]="recipientAddForm" [control]="recipientAddForm.controls.countryOfBirth"
            placeholder="Select Country" label="Country"></cashaa-form-select-country>
        </div>  -->
        <!-- <h5 class="font-weight-normal text-primary">
          Bank Account Information
        </h5> -->
        <hr class="mb-4" />
        <div class="mb-4">
          <cashaa-form-select name="accountType" [form]="recipientAddForm" [options]="savingAcTypesList"
            [control]="recipientAddForm.controls.accountType" placeholder="Select Account Type"
            label="Select Account Type">
          </cashaa-form-select>
        </div>
        <div class="mb-4">
          <cashaa-form-input name="bankName" type="text" [form]="recipientAddForm"
            [control]="recipientAddForm.controls.bankName" label="Bank Name">
          </cashaa-form-input>
        </div>
        <div class="mb-4">
          <cashaa-form-input name="accountNumber" type="text" [form]="recipientAddForm"
            [control]="recipientAddForm.controls.accountNumber" label="Account Number" placeholder="12345678">
          </cashaa-form-input>
        </div>
        <div class="mb-4">
          <cashaa-form-input name="routingNumber" type="text" [form]="recipientAddForm"
            [control]="recipientAddForm.controls.routingNumber" label="Routing Number">
          </cashaa-form-input>
        </div>
        <div class="mb-4">
          <cashaa-form-input name="bankCode" type="text" [form]="recipientAddForm"
            [control]="recipientAddForm.controls.bankCode" label="Swift Code">
          </cashaa-form-input>
        </div>
        <div class="mb-4" *ngIf="beneficiarytype === 'broker' && recipientAddForm.get('userType').value === '5'">
          <cashaa-form-input name="googleDriveUrl" type="text" [form]="recipientAddForm"
            [control]="recipientAddForm.controls.googleDriveUrl" label="Google Drive Url" [infoMesg]="getInfoMesg()">
          </cashaa-form-input>
        </div>
      </div>
      <div class="text-center mt-5" *ngIf="!isPremiumPlan && (currencySymbol ==='EUR' || currencySymbol ==='USD') && recipientAddForm.get('recipientType').value === '2'">
        <h4 class="heading" style="word-break: break-all;
        margin: auto;
        width: 52%;">
          Want to receive money from someone else?
        </h4>
        <img src="../../../assets/images/receive-other.svg" alt="receive money">
        <button  mdbBtn *ngIf="recipientAddForm.get('recipientType').value === '2'" class="mt-4 btn-sm text-white btn-success"
        [routerLink]="['/subscription/plan']" (click)="modalRef.hide()">
         Click here to upgrade.
       </button>
        <div>
          <!-- <a class="bg-green" [routerLink]="['/subscription/plan']" (click)="modalRef.hide()">
            Click here to upgrade.
          </a> -->
        </div>
            
      </div>
    </div>
    <div *ngIf="isCrypto">
      <div class="mb-5">
        <cashaa-form-select-network-type [form]="recipientAddForm" [control]="recipientAddForm.controls.networkType"
          name="networkType" label="Network Type" [options]="networkTypeOptions"
          (changed)="onNetworkTypeChanged($event)">
        </cashaa-form-select-network-type>
      </div>
      <div class="mb-4">
        <cashaa-form-input name="cryptoAddress" type="text" [form]="recipientAddForm"
          [control]="recipientAddForm.controls.cryptoAddress" label="Address"></cashaa-form-input>
      </div>
      <div class="mb-4">
        <cashaa-form-input name="addressLabel" type="text" [form]="recipientAddForm"
          [control]="recipientAddForm.controls.addressLabel" label="Label (Optional)"></cashaa-form-input>
      </div>
      <!-- <div class="mb-5" *ngIf="currencySymbol ==='BTC' || currencySymbol ==='BNB'">
        <cashaa-form-input [form]="recipientAddForm" [control]="recipientAddForm.controls.networkType"
          name="networkType" label="Network Type"
         >
        </cashaa-form-input>
      </div> -->
      <!-- <div class="mb-4">
        <cashaa-form-input name="cryptoEmail" type="email" [form]="recipientAddForm"
          [control]="recipientAddForm.controls.cryptoEmail" label="Email (Optional)"></cashaa-form-input>
      </div> -->
      <div class="mb-4" *ngIf="currencySymbol === 'CAS' || currencySymbol === 'BNB' || currencySymbol === 'XRP'">
        <div *ngIf="recipientAddForm.get('networkType').value === '0' ">
          <cashaa-form-input name="cryptoMemo" type="text" [form]="recipientAddForm"
          [control]="recipientAddForm.controls.cryptoMemo" label="{{currencySymbol === 'XRP'? 'Tag (Optional)': 'Memo (Optional)'}}" [maxLength]="10">
        </cashaa-form-input>
        <small *ngIf="currencySymbol === 'CAS' || currencySymbol === 'BNB'" class="text-warning">You must fill your Memo correctly to withdraw through BEP2, or the funds may be lost.</small>
        <small *ngIf="currencySymbol === 'XRP'" class="text-warning">You must fill your Tag correctly to withdraw through XRP, or the funds may be lost.</small>
        </div>
      </div>
      <div class="mb-4" *ngIf="twofactor">
        <cashaa-google-auth-boxes [(finalCode)]="code" (updateCode)="updateCode($event)">
        </cashaa-google-auth-boxes>
      </div>
      <div class="mb-4" *ngIf="errorMessage">
        <span style="color: red">{{ response }} </span>
      </div>

      <div class="mb-4" *ngIf="!twofactor">
        <span>
          Your google 2FA is not enabled
          <a [routerLink]="['/settings']" [queryParams]="{step:'2fa'}" (click)="modalRef.hide()">click here</a> to
          enable.</span>
      </div>
    </div>
    <div class="mb-4" *ngIf="userType === '5' && isUploadKyc">
      <cashaa-form-input name="identityProof" type="text" [form]="recipientAddForm"
        [control]="recipientAddForm.controls.identityProof" placeholder="Enter the Identity Proof Link"
        label="Identity Proof Link">
      </cashaa-form-input>
    </div>
    <div class="mb-4" *ngIf="userType === '5' && isUploadKyc">
      <cashaa-form-input name="addressProof" type="text" [form]="recipientAddForm"
        [control]="recipientAddForm.controls.addressProof" placeholder="Enter the Address Proof Link"
        label="Address Proof Link">
      </cashaa-form-input>
    </div>
    <div class="mb-4" *ngIf="userType === '4' && isUploadKyc">
      <label>Document
        Type</label>
      <cashaa-form-select [form]="recipientAddForm" [control]="recipientAddForm.controls.identityDocumentType"
        name="identityDocumentType" placeholder="Select document type" [options]="identityProofOptions">
      </cashaa-form-select>
    </div>
    <div class="mb-4" *ngIf="userType === '4' && isUploadKyc">
      <fieldset class="border p-2">
        <legend class="w-auto sm-label">Identity Document</legend>
        <cashaa-form-input-file [docType]="identityfile" [fileUploadStatus]="true"
          [isFileUploadCompleted]="identityfile.isDeletedValid" [isOperationCompleted]="false">
        </cashaa-form-input-file>
      </fieldset>
    </div>
    <div class="mb-4" *ngIf="userType === '4' && isUploadKyc">
      <label>Document
        Type</label>
      <cashaa-form-select [form]="recipientAddForm" [control]="recipientAddForm.controls.addressDocumentType"
        name="addressDocumentType" placeholder="Select document type" [options]="addressProofOptions">
      </cashaa-form-select>
    </div>
    <div class="mb-4" *ngIf="userType === '4' && isUploadKyc">
      <fieldset class="border p-2">
        <legend class="w-auto sm-label">Address Proof Document</legend>
        <cashaa-form-input-file [docType]="addressfile" [fileUploadStatus]="true"
          [isFileUploadCompleted]="addressfile.isDeletedValid" [isOperationCompleted]="false">
        </cashaa-form-input-file>
      </fieldset>
    </div>
    <div class="mb-4" *ngIf="isUploadKyc">
      <fieldset class="border p-2">
        <legend class="w-auto sm-label">Upload Selfie</legend>
        <cashaa-form-input-file [docType]="selfiefile" [fileUploadStatus]="true"
          [isFileUploadCompleted]="selfiefile.isDeletedValid" [isOperationCompleted]="false">
        </cashaa-form-input-file>
      </fieldset>
    </div>
    <div class="mt-4">
      <button *ngIf="recipientAddForm.get('recipientType').value === '1' || (recipientAddForm.get('recipientType').value === '2' && isPremiumPlan)" mdbBtn (click)="onFormSubmit()" block="true" type="button"
        [disabled]="recipientAddForm.invalid || (isCrypto && this.code.length!=6) || !isContinueActiveOthersSelection()"
        class="rounded-sm position-relative btn btn-primary waves-light btn-block sm-font" mdbWavesEffect>
        <i *ngIf="!isCrypto" class="fa-lock fas position-absolute"></i><img *ngIf="isCrypto"
          class="position-absolute locker-icon" src="../../../assets/media/locker.svg" />Confirm
      </button>
    </div>
  </form>

  <!-- Progress bar -->
  <mdb-progress-bar *ngIf="isInProgress" class="progress primary-color-dark position-absolute" mode="indeterminate">
  </mdb-progress-bar>
</div>
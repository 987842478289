<section class="p-5 w-100 mx-auto">
    <h3 class="text-primary-dark font-weight-bolder mb-5 text-center">
      Who are you receiving money from?
    </h3>
    <p class="font-weight-bolder text-primary-dark">Existing Sender</p>
    <hr />
    <div class="p-4 text-center" [hidden]="!loading">
      <mdb-spinner spinnerColor="blue"></mdb-spinner>
    </div>
    <div class=" justify-content-between mb-5">
      <h4 class="sm-align-font sm-custom-link" *ngIf="(recipients?.length===0 || !recipients) && !loading && symbol"
        (click)="openAddRecipient()">
        Add a sender</h4>
      <cashaa-recipient-box *ngFor="let recipient of recipients" [recipient]="recipient"
        (click)="selectRecipients(recipient)"></cashaa-recipient-box>
    </div>
  
    <p class="font-weight-bolder text-primary-dark">New sender</p>
    <hr />
    <div class="d-flex justify-content-between flex-wrap">
      <cashaa-recipient-box [isNew]="true" type="addsender" (click)="openAddRecipient()"></cashaa-recipient-box>
      <!-- <cashaa-recipient-box [isNew]="true" type="someone" (click)="openAddRecipientOther()"></cashaa-recipient-box> -->
      <!-- <cashaa-recipient-box [isNew]="true" type="business" (click)="openAddRecipientCharity()"></cashaa-recipient-box> -->
    </div>
  </section>
  
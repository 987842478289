export interface ICustomerEmailConfermation {
        email: string;
        customerId: string;
}
export class CustomerEmailConfermation implements ICustomerEmailConfermation {
        constructor(
            public email: string,
            public customerId: string,
        ) { }
    }

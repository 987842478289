import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { APP_ROUTES } from "src/app/app.constants";

@Component({
  selector: "cashaa-unauthorized",
  templateUrl: "./unauthorized.component.html",
  styleUrls: ["./unauthorized.component.scss"],
})
export class UnauthorizedComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit(): void {}

  /**
   * Open sign in user page
   */
  signIn(): void {
    this.router.navigateByUrl(APP_ROUTES.SIGN_IN);
  }
}

import { UserService } from "./../../core/services/user/user.service";
import { CurrencyType } from "./../../core/enums/currencyType";
import {
  WALLET_CURRENCY,
  WALLET_CURRENCY_NUM,
  CRYPTO_CURRENCY_NUM,
  USER_FEE_MODE,
  USER_FEE_PERCENT,
  USER_CAS_FEE_RATE,
  CRYPTO_CURRENCY,
  USER_TYPE,
  SUBSCRIPTION_PERSONAL_PLAN,
} from "./../../app.constants";
import { ActivatedRoute, Router } from "@angular/router";
import { IConvert } from "./../../core/models/convert";
import { ConvertService } from "./../services/convert.service";
import { ICurrency } from "src/app/core/models/currency";
import { ValidatorUtility } from "src/app/core/utility/validator.utility";
import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  OnDestroy,
} from "@angular/core";
import { FormGroup, FormBuilder } from "@angular/forms";
import { DomainService } from "src/app/core/services/domain/domain.service";
import { SubSink } from "subsink";
import { DecimalPipe } from "@angular/common";
import { IBrokerFees } from "src/app/core/models/quote";
import { AccountUpgradeService } from "src/app/account-upgrade/services/account-upgrade.service";
import { UserSubscriptionPlan } from "src/app/core/models/account-upgrade/subscription-plan-response";
import { IFormSelect } from "src/app/core/models/form-select";
import { WalletService } from "src/app/wallet/services/wallet.service";
import { CryptoAccountRequestModel } from "src/app/core/models/crypto-account-request-model";

@Component({
  selector: "cashaa-convert-amount",
  templateUrl: "./convert-amount.component.html",
  styleUrls: ["./convert-amount.component.scss"],
})
export class ConvertAmountComponent implements OnInit, OnDestroy {
  /***  in view */
  @Input() inView = false;

  /** on continue click, emit event */
  @Output() continue = new EventEmitter<void>();

  /** input form */
  form: FormGroup;

  /** rate varibale */
  rate: number;

  rateSymbol: string;

  /** casrate varibale */
  casrate: number = 0;

  /** sendCurrencySymbol varibale */
  sendCurrencySymbol: string;

  /** casSymbol varibale */
  casSymbol: string;

  receiveCurrencySymbol: string;

  /** actulaconvertedamount varibale */
  actulaconvertedamount: number;

  /** selectedfeeamount varibale */
  selectedfeeamount: number;

  /** feemode variable */
  userFeeMode = sessionStorage.getItem(USER_FEE_MODE);

  /** conversionfeepercent variable */

  conversionFeePercent = sessionStorage.getItem(USER_FEE_PERCENT);

  userType = sessionStorage.getItem(USER_TYPE);

  /** cas fee check varibale */
  casFeeCheckBalance: number;

  isInvalid = false;

  disableCryptoInFrom = false;

  disableFiatInFrom = false;

  disableCryptoInTo = false;

  disableFiatInTo = true;

  iscasfee = false;

  defaultFromCurrency: ICurrency;

  defaultToCurrency: ICurrency;

  fiatCurrencies = ["INR", "USD", "GBP", "EUR", "AUSD", "SGD", "AUD", "CNH", "CAD", "CHF", "NZD", "JPY"];

  subsink = new SubSink();

  currencyMap = new Map();

  multiplyCryptoMap = new Map<string, string[]>();

  usdRate: number;

  brokerFees: IBrokerFees;

  currentUserPlanDetail: UserSubscriptionPlan;
  minAmount: number;
  minAmountSymbol: string;
  minCASAmount: number;
  minCASAmountSymbol: string;
  isMinSendValueError: boolean = false;
  isMinReceiveValueError: boolean = false;
  exchangeFeeDiscount;
  networkTypeOptions = [];
  networkForm: FormGroup;
  merchantRecognitionId;
  appId;
  isAddressGenerated = false;
  thirdPartyConditionsAccepted = false;
  termsAndConditionsAccepted = false;
  isKYCRedoWithDigiLocker = false;
  walletAddress;
  hideMainPage = false;
  currencyWiseNetworkList;
  srcUrl;
  loader = false;
  addressTag;
  memo;
  isOnRamp = false;
  constructor(
    private fb: FormBuilder,
    private convertService: ConvertService,
    private domainService: DomainService,
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private decimalNumber: DecimalPipe,
    private upgardeService: AccountUpgradeService,
    private walletService: WalletService,
    private router: Router
  ) {
    this.createForm();
    this.rate = 1;
    this.sendCurrencySymbol = "EUR";
    this.selectedfeeamount = 0;
    this.actulaconvertedamount = 0;
    this.receiveCurrencySymbol = "CAS";
    this.casSymbol = "CAS";
  }

  ngOnInit(): void {
    this.sendAmountChangeEvent();
    this.reciveAmountChangeEvent();
    this.getUserBalance();
    this.getGivenCurrency();


    if (
      this.form.controls["sendercurrencyId"].value !==
      this.form.controls["receivercurrencyId"].value
    ) {
      this.domainService
        .getRate(
          this.form.controls["sendercurrencyId"].value,
          this.form.controls["receivercurrencyId"].value
        )
        .subscribe((rateobj) => {
          this.rate = rateobj.rate
          this.minAmount = rateobj.minAmount;
          this.minAmountSymbol = rateobj.minAmountSymbol;
          this.minCASAmount = rateobj.minCASAmount;
          this.minCASAmountSymbol = rateobj.minCASAmountSymbol;
          this.rateSymbol = rateobj.rateSymbol;
          this.casrate = rateobj.casRate;
          this.usdRate = rateobj.usdRate;
        });
    }
    this.subsink.add(this.upgardeService.getUserActionSubscriptionPlan().subscribe((data: UserSubscriptionPlan) => {
      this.currentUserPlanDetail = data;
    }));
    this.getUserPlanDetails();
  }

  getGivenCurrency(): void {
    const pathName = window.location.pathname;
    this.subsink.add(
      this.activatedRoute.queryParamMap.subscribe((val) => {
        const currency = val.get("c");
        // if (currency === 'INR') {
        //   this.setINRMapValues();
        // } else {
        //   this.setMapValues();
        // }
        this.domainService
          .getCurrenciesList(CurrencyType.all)
          .subscribe((list) => {
            if (currency) { 
              const found = list.currencies.filter(
                (c) => c.symbol === currency
              );
              if (found.length > 0) {
                this.defaultFromCurrency = found[0];
                if (pathName === '/buy') {
                  this.form.controls.receivercurrencyId.setValue(
                    this.defaultFromCurrency.id);
                } else {
                  this.form.controls.sendercurrencyId.setValue(
                    this.defaultFromCurrency.id);
                }
                //  if crypto else fiat : added buy only for fiat now
                if (this.defaultFromCurrency.currencyType === 1) {
                  this.defaultToCurrency = list.currencies.filter(
                    (c) => c.symbol === WALLET_CURRENCY.EUR
                  )[0];
                  if (pathName === '/buy') {
                    this.form.controls.sendercurrencyId.setValue(
                      this.defaultToCurrency.id);
                  } else {
                    this.form.controls.receivercurrencyId.setValue(
                      this.defaultToCurrency.id
                    );
                  }
                } else {
                  this.defaultToCurrency = list.currencies.filter(
                    (c) => c.symbol === CRYPTO_CURRENCY.CAS
                  )[0];
                  if (pathName === '/buy') {
                    this.form.controls.sendercurrencyId.setValue(
                      this.defaultToCurrency.id);
                    this.sendCurrencySymbol = this.defaultToCurrency.symbol;
                  } else {
                    this.form.controls.receivercurrencyId.setValue(
                      this.defaultToCurrency.id
                    );
                  }

                }
              }
            } else {
              this.defaultFromCurrency = list.currencies.filter(
                (c) => c.symbol === WALLET_CURRENCY.EUR
              )[0];
              this.defaultToCurrency = list.currencies.filter(
                (c) => c.symbol === CRYPTO_CURRENCY.CAS
              )[0];
              this.form.controls.sendercurrencyId.setValue(
                this.defaultFromCurrency.id
              );
              this.form.controls.receivercurrencyId.setValue(
                this.defaultToCurrency.id
              );
            }
          });
      })
    );
  }

  /***
   * Create new form
   */
  createForm(): void {
    this.form = this.fb.group({
      sendercurrencyId: [""],
      senderAmount: ["", [ValidatorUtility.Required]],
      receivercurrencyId: [""],
      receiverAmount: ["", [ValidatorUtility.Required]],
      sendertransferFeeId: [""],

    });
    this.form.controls.receiverAmount.disable();
    this.networkForm = this.fb.group({
      networkType: [""]
    })
    this.listenToCurrencyChange();
  }

  /***
   * Listen to currency change
   */
  listenToCurrencyChange(): void {
    this.form.valueChanges.subscribe(() => {
      // this.form.controls.receiverAmount.setValue(0);
    });
  }

  /***
   * On continue click
   */
  onContinue(): void {
    const payData: IConvert = {
      convertFrom: this.form.controls["senderAmount"].value.replaceAll(",", ""),
      convertTo: this.form.controls["receiverAmount"].value.replaceAll(",", ""),
      fromcurrencyId: this.form.controls["sendercurrencyId"].value,
      tocurrencyId: this.form.controls["receivercurrencyId"].value,
      feeId: this.form.controls["sendertransferFeeId"].value,
      rate: this.rate,
      side: this.checkIsBuyOrSell(),
      wallet:
        this.checkIsBuyOrSell() === 2
          ? WALLET_CURRENCY_NUM[this.sendCurrencySymbol]
          : this.checkIsBuyOrSell() === 1
            ? WALLET_CURRENCY_NUM[this.receiveCurrencySymbol]
            : WALLET_CURRENCY_NUM[this.sendCurrencySymbol] && WALLET_CURRENCY_NUM[this.receiveCurrencySymbol] ?
              WALLET_CURRENCY_NUM[this.sendCurrencySymbol] :
              CRYPTO_CURRENCY_NUM[this.sendCurrencySymbol],
      crypto:
        this.checkIsBuyOrSell() === 2
          ? CRYPTO_CURRENCY_NUM[this.receiveCurrencySymbol]
          : this.checkIsBuyOrSell() === 1
            ? CRYPTO_CURRENCY_NUM[this.sendCurrencySymbol]
            : WALLET_CURRENCY_NUM[this.sendCurrencySymbol] && WALLET_CURRENCY_NUM[this.receiveCurrencySymbol] ?
              WALLET_CURRENCY_NUM[this.receiveCurrencySymbol]
              : CRYPTO_CURRENCY_NUM[this.receiveCurrencySymbol],
      feeAmount: this.selectedfeeamount,
      from: this.sendCurrencySymbol,
      to: this.receiveCurrencySymbol,
    };
    this.convertService.emitConvertFlowData(payData);
    this.continue.emit();
  }

  resetAmountvalue(): void {
    this.form.controls["senderAmount"].patchValue(null, {
      emitEvent: false,
      onlySelf: true,
    });
    this.form.controls["receiverAmount"].patchValue(null, {
      emitEvent: false,
      onlySelf: true,
    });
    this.actulaconvertedamount = 0;
    this.isMinSendValueError = false;
    this.isMinReceiveValueError = false;
  }

  onsendCurrencyChangeEvent(data: ICurrency): void {
    this.iscasfee = false;
    this.isOnRamp = data.isOnramp;
    this.sendCurrencySymbol = data.symbol;
    this.resetAmountvalue();
    if (this.isOnRamp) {
      this.getCurrencyOnRampPairList(data);
    } else {
      this.setMapValues()
    }
    if (this.sendCurrencySymbol === 'CAS') {
      if (this.sendCurrencySymbol === this.receiveCurrencySymbol) {
        this.form.controls.receivercurrencyId.setValue(
          this.defaultFromCurrency.id
        );
      }
    } else if (this.sendCurrencySymbol === 'GBP') {
      if (this.sendCurrencySymbol === this.receiveCurrencySymbol) {
        this.form.controls.receivercurrencyId.setValue(
          this.defaultToCurrency.id
        );
      }
    } else if (this.sendCurrencySymbol === 'XRP') {
      this.form.controls.receivercurrencyId.setValue(
        "b40190d2-f80d-4a48-83a7-7dba72d238e3"
      );
      //set to LTC id
    }
    else if (this.receiveCurrencySymbol === 'XRP') {
      this.form.controls.sendercurrencyId.setValue(
        "b40190d2-f80d-4a48-83a7-7dba72d238e3"
      );
      //set to LTC id
    }

    if (this.form.controls["receivercurrencyId"].value === data.id) {
      this.rate = 1;
    } else if (
      this.form.controls["receivercurrencyId"].value !== null &&
      this.form.controls["receivercurrencyId"].value !== "" && !this.isOnRamp
    ) {
      this.subsink.add(
        this.domainService
          .getRate(data.id, this.form.controls["receivercurrencyId"].value)
          .subscribe((rateobj) => {
            this.rate = rateobj.rate
            this.rateSymbol = rateobj.rateSymbol;
            this.casrate = rateobj.casRate;
            this.usdRate = rateobj.usdRate;
            this.minAmount = rateobj.minAmount;
            this.minAmountSymbol = rateobj.minAmountSymbol;
            this.minCASAmount = rateobj.minCASAmount;
            this.minCASAmountSymbol = rateobj.minCASAmountSymbol;
            sessionStorage.setItem(USER_CAS_FEE_RATE, this.casrate.toString());
            this.getNewConversionForSendChange(
              this.form.controls["senderAmount"].value,
              true
            );
          })
      );
    }
    if (this.userType === "6" || (this.userType === "4" && SUBSCRIPTION_PERSONAL_PLAN.PREMIUM === this.currentUserPlanDetail.planName)) {
      this.subsink.add(this.convertService.getBrokerFees(this.sendCurrencySymbol, this.userFeeMode).subscribe((fees: IBrokerFees) => {
        this.brokerFees = fees;
      }));
    }
  }

  onChangingAmountField(event) {
    this.form.controls["senderAmount"].valueChanges.subscribe((val) => {
      if (this.isOnRamp) {
        if (this.form.controls.senderAmount.value < this.minAmount) {
          this.isMinSendValueError = true;
        }
      }

      this.getNewConversionForSendChange(val, false);
    })
  }
  onRampPairedCurrencyInfo;
  receiveCurrencyInfo;
  receivedAndSelectedPair
  getCurrencyOnRampPairList(data) {
    this.convertService.getOnRampPairList(data.id).subscribe(res => {
      console.log('receiveCurrencyInfo: ', this.receiveCurrencyInfo);
      this.onRampPairedCurrencyInfo = res;
      this.receivedAndSelectedPair = this.onRampPairedCurrencyInfo.filter(item => item.symbol === this.receiveCurrencyInfo.symbol);
      let pairsOfRespectiveCoin = res.map(item => item.symbol);
      console.log(pairsOfRespectiveCoin)
      if (this.receiveCurrencyInfo.currencyType == 1) {
        let res = this.receiveCurrencyInfo.networkType;
        this.networkTypeOptions = res;
        let defaultNetworkType = this.networkTypeOptions.filter(item => item.isDefaultNetwork == true)[0].networkValue
        this.networkForm.controls.networkType.setValue(defaultNetworkType);
        this.getWalletAddress(this.receiveCurrencySymbol, defaultNetworkType);
      }
      this.currencyMap.set(data.symbol, pairsOfRespectiveCoin);
    })

  }

  getDecimalNumber(symbol) {
    let toFix = "0.0";
    if (symbol === "CAS") {
      toFix = "0.0-0";
    } else if (this.fiatCurrencies.includes(symbol)) {
      toFix = "0.2-4";
    } else {
      if (symbol === CRYPTO_CURRENCY.BTC || symbol === CRYPTO_CURRENCY.ETH) {
        toFix = "0.2-10";
      } else {
        toFix = "0.4";
      }
    }
    return toFix;
  }

  onreciveCurrencyChangeEvent(data: ICurrency): void {
    this.iscasfee = false;
    this.receiveCurrencyInfo = data;
    this.resetAmountvalue();
    this.receiveCurrencySymbol = data.symbol;
   

    if (this.sendCurrencySymbol === this.receiveCurrencySymbol) {
      this.form.controls.sendercurrencyId.setValue(
        this.defaultFromCurrency.id
      );
    }
    // let res = this.currencyWiseNetworkList.filter(item => item.symbol === this.receiveCurrencySymbol)[0].networkType;
    // this.networkTypeOptions = res;
    // let defaultNetworkType = this.networkTypeOptions.filter(item => item.default == true)[0].value
    // this.networkForm.controls.networkType.setValue(defaultNetworkType);
    // this.getWalletAddress(this.receiveCurrencySymbol, defaultNetworkType);
    if (this.form.controls["sendercurrencyId"].value === data.id) {
      this.rate = 1;
    } else {
      if (!this.isOnRamp) {
        this.subsink.add(
          this.domainService
            .getRate(this.form.controls["sendercurrencyId"].value, data.id)
            .subscribe((rateobj) => {
              this.rate = rateobj.rate;
              this.rateSymbol = rateobj.rateSymbol;
              this.casrate = rateobj.casRate;
              this.usdRate = rateobj.usdRate;
              this.minAmount = rateobj.minAmount;
              this.minAmountSymbol = rateobj.minAmountSymbol;
              this.minCASAmount = rateobj.minCASAmount;
              this.minCASAmountSymbol = rateobj.minCASAmountSymbol;
              sessionStorage.setItem(USER_CAS_FEE_RATE, this.casrate.toString());
              this.getNewConversionForReceiveChange(
                this.form.controls["receiverAmount"].value,
                true
              );
            })
        );
      } else {
        if (this.onRampPairedCurrencyInfo) {
          let onChnageReceivedAndSelectedPair = this.onRampPairedCurrencyInfo.filter(item => item.symbol === this.receiveCurrencyInfo.symbol);
          let chainId = onChnageReceivedAndSelectedPair[0].networkType.filter(item => item.networkValue == this.networkForm.controls.networkType.value);
          this.getOnRampRate(chainId)
        }
     
      }
    }

    // if (this.userType === "6" || (this.userType === "4" && SUBSCRIPTION_PERSONAL_PLAN.PREMIUM === this.currentUserPlanDetail.planName)) {
    //   this.subsink.add(this.convertService.getBrokerFees(this.sendCurrencySymbol, this.userFeeMode).subscribe((fees: IBrokerFees) => {
    //     this.brokerFees = fees;
    //   }));
    // }
  }

  sendAmountChangeEvent(): void {
    this.iscasfee = false;
    this.subsink.add(
    
    );
  }

  reciveAmountChangeEvent(): void {
    this.iscasfee = false;
    this.subsink.add(
      this.form.controls["receiverAmount"].valueChanges.subscribe((val) => {
        if (val !== ".") {
          this.getNewConversionForReceiveChange(val, false);
        }
      })
    );
  }

  getNewConversionForSendChange(val: any, flag): void {
    val = val ? val.replaceAll(",", "") : val;
    if (!this.isOnRamp) {
      this.feeCalcutain(val);
    } else {
      this.selectedfeeamount = 0;
    }

    if (this.checkFeeMode() === 2 && (this.sendCurrencySymbol === CRYPTO_CURRENCY.BTC || this.sendCurrencySymbol === CRYPTO_CURRENCY.ETH)) {
      let splittedNum = (val - this.selectedfeeamount).toString().split('.');
      if (splittedNum.length == 2) {
        let beforeDeci = splittedNum[1].slice(0, 4);
        this.actulaconvertedamount = Number(splittedNum[0] + '.' + beforeDeci);
      } else {
        this.actulaconvertedamount = Number(val - this.selectedfeeamount);
      }
    } else {
      this.actulaconvertedamount = this.checkFeeMode() === 2 ? Number((Number(val) - this.selectedfeeamount).toFixed(4)) :
        (this.checkFeeMode() === 1 && this.sendCurrencySymbol === 'CAS') ? Number((Number(val) - this.selectedfeeamount).toFixed(4)) : Number(val);
    }
    let toFix = 0;
    if (
      this.receiveCurrencySymbol === "CAS"
    ) {
      toFix = 0;
    } else if (this.fiatCurrencies.includes(this.receiveCurrencySymbol)) {
      toFix = 2;
    } else {
      toFix = 4;
    }
    let reciverValue = Number(this.getReciverPatchValue().toFixed(toFix));
    this.form.controls["receiverAmount"].patchValue(
      (reciverValue).toFixed(toFix),
      {
        emitEvent: false,
        onlySelf: true,
      }
    );
    if (flag) {
      if (
        this.sendCurrencySymbol === "CAS"
      ) {
        this.form.controls["senderAmount"].patchValue(0, {
          emitEvent: false,
          onlySelf: true,
        });
      } else if (this.fiatCurrencies.includes(this.sendCurrencySymbol)) {
        this.form.controls["senderAmount"].patchValue((0).toFixed(2), {
          emitEvent: false,
          onlySelf: true,
        });
      } else {
        this.form.controls["senderAmount"].patchValue((0).toFixed(4), {
          emitEvent: false,
          onlySelf: true,
        });
      }
    }
    if (parseFloat(this.form.controls["receiverAmount"].value) !== 0) {
      this.form.controls["receiverAmount"].patchValue(
        this.decimalNumber.transform(
          this.form.controls["receiverAmount"].value.replaceAll(",", ""),
          "0.0-4"
        ),
        {
          emitEvent: false,
          onlySelf: true,
        }
      );
    }

    if (reciverValue > 0 && reciverValue < this.minAmount && this.minAmountSymbol === this.receiveCurrencySymbol) {
      this.isMinReceiveValueError = true;
    } else {
      this.isMinReceiveValueError = false;
    }

    if (!this.isMinReceiveValueError && this.actulaconvertedamount > 0 && this.actulaconvertedamount < this.minAmount && this.minAmountSymbol === this.sendCurrencySymbol) {
      this.isMinSendValueError = true;
    } else {
      this.isMinSendValueError = false;
    }


    // if (this.minCASAmountSymbol) {
    //   if (this.sendCurrencySymbol === this.minCASAmountSymbol) {
    //     if (!this.isMinReceiveValueError && this.actulaconvertedamount > 0 && this.actulaconvertedamount < this.minAmount) {
    //       this.isMinSendValueError = true;
    //     } else {
    //       this.isMinSendValueError = false;
    //     }
    //   } else {
    //     if (reciverValue > 0 && reciverValue < this.minAmount) {
    //       this.isMinReceiveValueError = true;
    //     } else {
    //       this.isMinReceiveValueError = false;
    //     }
    //   }
    // }
  }

  getNewConversionForReceiveChange(val: any, flag): void {
    val = val ? val.replaceAll(",", "") : val;
    let toFix = 0;
    if (
      this.sendCurrencySymbol === "CAS"
    ) {
      toFix = 0;
    } else if (this.fiatCurrencies.includes(this.sendCurrencySymbol)) {
      toFix = 2;
    } else {
      toFix = 4;
    }

    let sendValue = this.getSendAmountPatchValue(Number(val)).toFixed(toFix);
    if(!this.isOnRamp) {
    this.feeCalcutain(sendValue);

    }


    if (val > 0) {
      if (this.checkFeeMode() === 1) {
        if (this.sendCurrencySymbol === 'CAS') {
          sendValue = (Number(sendValue) + this.selectedfeeamount).toString();
        } else {
          sendValue = Number(sendValue).toString();
        }
      } else {
        sendValue = (Number(sendValue) + this.selectedfeeamount).toString();
      }
    }

    this.form.controls["senderAmount"].patchValue(
      sendValue,
      {
        emitEvent: false,
        onlySelf: true,
      }
    );

    this.actulaconvertedamount = this.checkFeeMode() === 2 ? Number((Number(sendValue) - this.selectedfeeamount).toFixed(4)) :
      (this.checkFeeMode() === 1 && this.sendCurrencySymbol === 'CAS') ? Number((Number(sendValue) - this.selectedfeeamount).toFixed(4)) : Number(sendValue);
    // this.actulaconvertedamount = this.checkFeeMode() === 2 ? Number((Number(sendValue) - this.selectedfeeamount).toFixed(4)) : Number(sendValue);
    if (flag) {
      if (
        this.receiveCurrencySymbol === "CAS"
      ) {
        this.form.controls["receiverAmount"].patchValue(0, {
          emitEvent: false,
          onlySelf: true,
        });
      } else if (this.fiatCurrencies.includes(this.receiveCurrencySymbol)) {
        this.form.controls["receiverAmount"].patchValue((0).toFixed(2), {
          emitEvent: false,
          onlySelf: true,
        });
      } else {
        this.form.controls["receiverAmount"].patchValue((0).toFixed(4), {
          emitEvent: false,
          onlySelf: true,
        });
      }
    }

    if (parseFloat(this.form.controls["senderAmount"].value) !== 0) {
      this.form.controls["senderAmount"].patchValue(
        this.decimalNumber.transform(
          this.form.controls["senderAmount"].value.replaceAll(",", ""),
          "0.0-4"
        ),
        {
          emitEvent: false,
          onlySelf: true,
        }
      );
    }

    let reciverValue = Number(this.form.controls["receiverAmount"].value);
    if (reciverValue > 0 && reciverValue < this.minAmount && this.minAmountSymbol === this.receiveCurrencySymbol) {
      this.isMinReceiveValueError = true;
    } else {
      this.isMinReceiveValueError = false;
    }


    if (!this.isMinReceiveValueError && this.actulaconvertedamount > 0 && this.actulaconvertedamount < this.minAmount && this.minAmountSymbol === this.sendCurrencySymbol) {
      this.isMinSendValueError = true;
    } else {
      this.isMinSendValueError = false;
    }

    if (this.minCASAmountSymbol) {
      if (this.sendCurrencySymbol === this.minCASAmountSymbol) {
        if (!this.isMinReceiveValueError && this.actulaconvertedamount > 0 && this.actulaconvertedamount < this.minCASAmount) {
          this.isMinSendValueError = true;
        } else {
          this.isMinSendValueError = false;
        }
      } else {
        let reciverValue = Number(this.form.controls["receiverAmount"].value);
        if (reciverValue > 0 && reciverValue < this.minCASAmount) {
          this.isMinReceiveValueError = true;
        } else {
          this.isMinReceiveValueError = false;
        }
      }
    }
  }

  checkIsBuyOrSell(): number {
    const from = this.sendCurrencySymbol;
    const to = this.receiveCurrencySymbol;
    // fiat -> crypto
    if (this.fiatCurrencies.includes(from) && !this.fiatCurrencies.includes(to)) {
      return 2;
    } else
      // crypto -> fiat
      if (!this.fiatCurrencies.includes(from) && this.fiatCurrencies.includes(to)) {
        return 1;
      }
      else {
        // cryto -> crypto
        return -1;
      }
  }

  checkFeeMode(): number {
    if (this.userFeeMode === "InCashaa") {
      return 1;
    } else {
      return 2;
    }
  }

  feeCalcutain(val: string): void {
    this.iscasfee = false;
    if (this.userType === "6" || (this.userType === "4" && SUBSCRIPTION_PERSONAL_PLAN.PREMIUM === this.currentUserPlanDetail.planName)) {
      this.brokerFeesCal(Number(val));
    } else {
      let fee = Number(Number(val) * Number(this.conversionFeePercent));
      if (this.sendCurrencySymbol === 'CAS') {
        this.selectedfeeamount = fee;
      } else {
        this.selectedfeeamount = this.checkFeeMode() === 2 ? fee : fee / this.casrate;
      }

      if (this.checkFeeMode() == 1) {
        this.iscasfee = this.selectedfeeamount > this.casFeeCheckBalance ? true : false;
        if (this.sendCurrencySymbol !== 'CAS') {
          this.selectedfeeamount = this.selectedfeeamount - this.selectedfeeamount * 0.25;
        }
      }
      if (this.selectedfeeamount > 0) {
        this.selectedfeeamount = this.selectedfeeamount - ((this.exchangeFeeDiscount / 100) * this.selectedfeeamount);
      }
      Number(this.selectedfeeamount).toFixed(4);
    }
  }
  /**
   * get user balance
   */
  getUserBalance(): void {
    this.subsink.add(
      this.userService.getUserBalance().subscribe((balance) => {
        const found = balance.filter(
          (b) => b.currencyType === "CAS"
        );
        if (found.length > 0) {
          this.casFeeCheckBalance = found[0].available;
          this.casFeeCheckBalance = Number(this.casFeeCheckBalance.toFixed(4));
        } else {
          this.casFeeCheckBalance = 0;
        }
      })
    );
  }

  checkValues(): boolean {
    return (
      this.form.controls.senderAmount.value === "." ||
      this.form.controls.receiverAmount.value === "." ||
      parseFloat(this.form.controls.senderAmount.value) === 0 ||
      parseFloat(this.form.controls.receiverAmount.value) === 0
    );
  }

  ngOnDestroy(): void {
    this.subsink.unsubscribe();
  }
  getCurrencyName(curr): string {
    if (curr === "AUSD") return "USD";
    if (curr === "DOG") return "DOGE";
    else return curr;
  }

  isMultiply(isSend: boolean): boolean {
    if ((this.receiveCurrencySymbol === "CAS" && !isSend) || (this.sendCurrencySymbol === "CAS" && isSend) || (this.rateSymbol === this.receiveCurrencySymbol)) {
      return true;
    }
    return this.multiplyCryptoMap.get(isSend ? this.sendCurrencySymbol : this.receiveCurrencySymbol)?.includes(isSend ?
      this.receiveCurrencySymbol : this.sendCurrencySymbol);
  }

  getReciverPatchValue(): number {
    if (this.actulaconvertedamount > 0) {
      if (this.checkIsBuyOrSell() == 2) {
        return this.actulaconvertedamount / this.rate;
      } else if (this.checkIsBuyOrSell() == 1) {
        return this.actulaconvertedamount * this.rate;
      } else if (this.checkIsBuyOrSell() == -1) {
        if (this.isMultiply(true)) {
          return this.actulaconvertedamount * this.rate;
        } else {
          return this.actulaconvertedamount / this.rate;
        }
      }
    } else {
      return 0;
    }
  }

  getSendAmountPatchValue(value: number): number {
    if (this.checkIsBuyOrSell() == 2) {
      return value * this.rate;
    } else if (this.checkIsBuyOrSell() == 1) {
      return value / this.rate;
    } else if (this.checkIsBuyOrSell() == -1) {
      if (this.isMultiply(false)) {
        return value * this.rate;
      } else {
        return value / this.rate;
      }
    }
  }

  // setMapValues(): void {
  //   const values = ["CAS", "BTC", "ETH", "XRP", "LTC", "BCH", "USDT", "EOS", "ADA", "DOG", "BNB", "JPY", "CHF", "USDC"];
  //   const values1 = ["CAS", "ETH", "XRP", "LTC", "BCH", "EOS", "ADA", "DOG", "BNB", "GBP", "EUR", "USD", "USDT"];
  //   const values2 = ["XRP", "BTC", "LTC", "USDT", "DOG", "GBP", "EUR", "USD", "ADA", "CAS"];
  //   const values3 = ["GBP", "EUR", "USD", "DOG", "BTC", "USDT", "ADA", "CAS", "JPY", "CAD", "AUD", "SGD", "CHF", "NZD", "ETH", "USDC"];
  //   const values4 = ["ETH", "XRP", "LTC", "BCH", "DOG", "BNB", "GBP", "EUR", "USD", "ADA", "CAS"];
  //   const values5 = ["CAS", "USD", "EUR", "GBP", "BTC", "USDT"];
  //   const values6 = ["BTC", "ETH", "XRP", "LTC", "BCH", "USDT", "DOG", "CAS"];
  //   const values7 = ["BTC", "XRP", "LTC", "DOG", "GBP", "EUR", "USD", "ADA", "CAS"];
  //   const values8 = ["BTC", "BCH", "USDT", "GBP", "EUR", "USD", "ADA", "CAS"];
  //   let usdVal = [...values];
  //   usdVal.push("LINK");
  //   usdVal.push("UNI");
  //   usdVal.push("GBP");
  //   usdVal.push("EUR");
  //   usdVal.push("AUD");
  //   usdVal.push("CAD");
  //   this.currencyMap.set("USD", usdVal);

  //   let gbpVal = [...values];
  //   gbpVal.push("LINK");
  //   gbpVal.push("UNI");
  //   gbpVal.push("EUR");
  //   gbpVal.push("USD")
  //   let eurVal = [...values];
  //   eurVal.push("USD");
  //   eurVal.push("GBP");
  //   eurVal.push("LINK");
  //   eurVal.push("UNI");
  //   eurVal.push("AUD");
  //   eurVal.push("CAD");
  //   this.currencyMap.set("EUR", eurVal);
  //   this.currencyMap.set("GBP", gbpVal);
  //   this.currencyMap.set("BTC", ["JPY", "CAD", "AUD", "SGD", "CHF", "NZD", "USDT", "CNH", "USDC", "LINK", "UNI"].concat(...values4));
  //   this.currencyMap.set("ETH", ["JPY", "CAD", "AUD", "SGD", "CHF", "USDT", "NZD", "USDC", "BCH", "BNB", "LINK"].concat(...values7));
  //   this.currencyMap.set("BNB", values3);
  //   this.currencyMap.set("USDT", ["JPY", "CAD", "AUD", "SGD", "CHF", "NZD", "CNH", "LINK", "UNI", "BTC", "USDC"].concat(...values4));
  //   this.currencyMap.set("BCH", ["JPY", "CAD", "AUD", "SGD", "CHF", "NZD", "USDT", "ETH", "XRP", "LTC"].concat(...values8));
  //   this.currencyMap.set("EOS", ["USD", "EUR", "GBP", "BTC", "CAS"]);
  //   this.currencyMap.set("ADA", ["USD", "EUR", "GBP", "USDT", "BTC", "ETH", "BNB", "CAS"]);
  //   this.currencyMap.set("DOG", ["USD", "EUR", "GBP", "BTC", "USDT", "ETH", "BNB", "CAS", "JPY", "CAD", "AUD", "SGD", "CHF", "NZD"]);
  //   this.currencyMap.set("LTC", ["USD", "EUR", "GBP", "USDT", "BTC", "ETH", "CAS", "JPY", "CAD", "AUD", "SGD", "CHF", "NZD", "XRP", "BCH", "USDC"]);
  //   this.currencyMap.set("XRP", ["USD", "EUR", "GBP", "USDT", "BTC", "ETH", "CAS", "JPY", "CAD", "AUD", "CHF", "NZD", "SGD", "BCH", "USDC", "LTC"]);
  //   this.currencyMap.set("JPY", ["USD", "EUR", "GBP", "BNB", "LINK", "UNI", "USDC"].concat(...values6));
  //   this.currencyMap.set("CAD", ["USD", "EUR", "BNB", "LINK", "UNI", "USDC"].concat(...values6));
  //   this.currencyMap.set("AUD", ["EUR", "USD", "BNB", "LINK", "UNI", "USDC"].concat(...values6));
  //   this.currencyMap.set("SGD", ["BTC", "ETH", "LTC", "BCH", "USDT", "DOG", "CAS", "BNB", "XRP", "LINK", "UNI", "USDC"]);
  //   this.currencyMap.set("CNH", ["USDT", "CAS", "BTC"]);
  //   this.currencyMap.set("NZD", ["BTC", "XRP", "BCH", "USDT", "DOG", "CAS", "ETH", "BNB", "LTC", "LINK", "UNI", "USDC"]);
  //   this.currencyMap.set("CHF", ["BTC", "ETH", "XRP", "LTC", "BCH", "USDT", "DOG", "CAS", "USD", "EUR", "GBP", "BNB", "LINK", "UNI", "USDC"]);
  //   this.currencyMap.set("LINK", ["USDT", "USD", "CAS", "EUR", "GBP", "JPY", "CAD", "AUD", "SGD", "CHF", "NZD", "BTC", "ETH", "USDC"]);
  //   this.currencyMap.set("UNI", ["GBP", "USD", "CAS", "EUR", "JPY", "CAD", "AUD", "SGD", "CHF", "NZD", "BTC", "UST", "USDC", "USDT"]);
  //   this.currencyMap.set("CAS", ["USD", "GBP", "EUR", "AUSD", "SGD", "AUD", "CNH", "CAD", "CHF", "NZD", "JPY", "BTC", "USDT", "ETH", "BNB", "BCH", "XRP", "UNI", "LTC", "DOG", "LINK", "USDC"]);
  //   this.currencyMap.set("USDC", ["CAS", "USD", "GBP", "EUR", "JPY", "CAD", "AUD", "SGD", "CHF", "NZD", "BTC", "ETH", "BNB", "XRP", "LTC", "LINK", "UNI", "USDT"]);
  //   this.multiplyCryptoMap.set("BNB", ["BTC", "USDT"]);
  //   this.multiplyCryptoMap.set("BTC", ["USDT"]);
  //   this.multiplyCryptoMap.set("DOG", ["BNB", "BTC", "ETH", "USDT"]);
  //   this.multiplyCryptoMap.set("XRP", ["BTC", "ETH", "USDT"]);
  //   this.multiplyCryptoMap.set("ETH", ["BTC", "USDT"]);
  //   this.multiplyCryptoMap.set("BCH", ["BTC", "USDT"]);
  //   this.multiplyCryptoMap.set("LTC", ["BTC", "USDT", "ETH"]);
  //   this.multiplyCryptoMap.set("LINK", ["USDT"]);
  // }

  setMapValues(): void {
    const values = ["CAS", "BTC", "ETH", "LTC", "BCH", "USDT", "EOS", "ADA", "DOG", "JPY", "CHF", "USDC"];
    const values4 = ["ETH", "LTC", "BCH", "DOG", "GBP", "EUR", "USD", "ADA", "CAS"];
    const values6 = ["BTC", "ETH", "XRP", "LTC", "BCH", "USDT", "DOG", "CAS"];
    const values7 = ["BTC", "LTC", "DOG", "GBP", "EUR", "USD", "ADA", "CAS"];
    const values8 = ["BTC", "BCH", "USDT", "GBP", "EUR", "USD", "ADA", "CAS"];
    const values9 = ["BTC", "ETH", "LTC", "BCH", "USDT", "DOG", "CAS"];
    let usdVal = [...values];
    usdVal.push("LINK");
    usdVal.push("UNI");
    usdVal.push("GBP");
    usdVal.push("EUR");
    usdVal.push("AUD");
    usdVal.push("CAD");
    this.currencyMap.set("USD", usdVal);
    const forInrVal = ['ETH', 'USDT', 'USDC', 'CAS', 'BNB', 'XRP', 'BTC'];
    this.currencyMap.set("INR", forInrVal);
    let gbpVal = [...values];
    gbpVal.push("LINK");
    gbpVal.push("UNI");
    gbpVal.push("EUR");
    gbpVal.push("USD")
    let eurVal = [...values];
    eurVal.push("USD");
    eurVal.push("GBP");
    eurVal.push("LINK");
    eurVal.push("UNI");
    eurVal.push("AUD");
    this.currencyMap.set("EUR", eurVal);
    this.currencyMap.set("GBP", gbpVal);
    this.currencyMap.set("BTC", ["JPY", "CAD", "AUD", "SGD", "CHF", "NZD", "USDT", "USDC", "LINK", "UNI"].concat(...values4));
    this.currencyMap.set("ETH", ["JPY", "INR", "CAD", "AUD", "SGD", "CHF", "USDT", "NZD", "USDC", "BCH", "LINK"].concat(...values7));
    // this.currencyMap.set("BNB", values3);
    this.currencyMap.set("USDT", ["JPY", "CAD", "AUD", "SGD", "CHF", "NZD", "LINK", "UNI", "BTC", "INR"].concat(...values4));
    this.currencyMap.set("BCH", ["JPY", "CAD", "AUD", "SGD", "CHF", "NZD", "USDT", "ETH", "XRP", "LTC"].concat(...values8));
    this.currencyMap.set("EOS", ["USD", "EUR", "GBP", "BTC", "CAS"]);
    this.currencyMap.set("ADA", ["USD", "EUR", "GBP", "USDT", "BTC", "ETH", "CAS"]);
    this.currencyMap.set("DOG", ["USD", "EUR", "GBP", "BTC", "USDT", "ETH", "CAS", "JPY", "CAD", "AUD", "SGD", "CHF", "NZD"]);
    this.currencyMap.set("LTC", ["USD", "EUR", "GBP", "USDT", "BTC", "ETH", "CAS", "JPY", "CAD", "AUD", "SGD", "CHF", "NZD", "XRP", "BCH", "USDC"]);
    this.currencyMap.set("XRP", ["AUD", "LTC"]);
    this.currencyMap.set("JPY", ["USD", "EUR", "GBP", "LINK", "UNI", "USDC"].concat(...values9));
    this.currencyMap.set("CAD", ["USD", "LINK", "UNI", "USDC"].concat(...values9));
    this.currencyMap.set("AUD", ["EUR", "USD", "LINK", "UNI", "USDC"].concat(...values6));
    this.currencyMap.set("SGD", ["BTC", "ETH", "LTC", "BCH", "USDT", "DOG", "CAS", "LINK", "UNI"]);
    this.currencyMap.set("NZD", ["BTC", "BCH", "USDT", "DOG", "CAS", "ETH", "LTC", "LINK", "UNI", "USDC"]);
    this.currencyMap.set("CHF", ["BTC", "ETH", "LTC", "BCH", "USDT", "DOG", "CAS", "USD", "EUR", "GBP", "LINK", "UNI", "USDC"]);
    this.currencyMap.set("LINK", ["USDT", "USD", "CAS", "EUR", "GBP", "JPY", "CAD", "AUD", "SGD", "CHF", "NZD", "BTC", "ETH", "USDC"]);
    this.currencyMap.set("UNI", ["GBP", "USD", "CAS", "EUR", "JPY", "CAD", "AUD", "SGD", "CHF", "NZD", "BTC", "UST", "USDC", "USDT"]);
    this.currencyMap.set("CAS", ["USD", "GBP", "EUR", "AUSD", "SGD", "AUD", "CNH", "CAD", "CHF", "NZD", "JPY", "BTC", "USDT", "ETH", "BCH", "UNI", "LTC", "DOG", "LINK", "USDC"]);
    this.currencyMap.set("USDC", ["CAS", "USD", "GBP", "EUR", "JPY", "INR", "CAD", "AUD", "CHF", "NZD", "BTC", "ETH", "LTC", "LINK", "UNI"]);
    this.multiplyCryptoMap.set("BNB", ["BTC", "USDT"]);
    this.multiplyCryptoMap.set("BTC", ["USDT"]);
    this.multiplyCryptoMap.set("DOG", ["BNB", "BTC", "ETH", "USDT"]);
    this.multiplyCryptoMap.set("XRP", ["BTC", "ETH", "USDT"]);
    this.multiplyCryptoMap.set("ETH", ["BTC", "USDT"]);
    this.multiplyCryptoMap.set("BCH", ["BTC", "USDT"]);
    this.multiplyCryptoMap.set("LTC", ["BTC", "USDT", "ETH"]);
    this.multiplyCryptoMap.set("LINK", ["USDT"]);
  }


  brokerFeesCal(amount: number): void {
    const calFees = this.brokerFees.fees;
    const brokerFee = this.brokerFees.brokerFee;
    const from = this.sendCurrencySymbol;
    const to = this.receiveCurrencySymbol;
    if (this.checkIsBuyOrSell() === 2 || (this.fiatCurrencies.includes(from) && this.fiatCurrencies.includes(to))) {
      if ((amount * brokerFee) > calFees) {
        this.selectedfeeamount = amount * brokerFee;
      } else {
        this.selectedfeeamount = calFees;
      }
    }
    else {
      if (((amount * brokerFee) * this.usdRate) > calFees) {
        this.selectedfeeamount = amount * brokerFee;
      } else {
        this.selectedfeeamount = calFees / this.usdRate;
      }
    }
    if (this.checkFeeMode() === 1 && this.sendCurrencySymbol !== 'CAS') {
      this.selectedfeeamount = this.selectedfeeamount / this.casrate;
    }
    if (this.selectedfeeamount > 0) {
      this.selectedfeeamount = this.selectedfeeamount - ((this.exchangeFeeDiscount / 100) * this.selectedfeeamount);
    }
    Number(this.selectedfeeamount).toFixed(4);
  }

  getUserPlanDetails() {
    this.userService.getUserPlanDetail().subscribe(res => {
      console.log(res);
      this.exchangeFeeDiscount = res.exchangeFeeDiscount;
    }, err => {

    })
  }

  networkTypeChanges(event: IFormSelect): void {
    console.log(event)
   if (this.isOnRamp) {
    this.networkForm.controls.networkType.setValue(event.networkValue);
    let chainId = this.receivedAndSelectedPair[0].networkType.filter(item => item.networkValue == event.networkValue);
     this.getOnRampRate(chainId);
    this.getWalletAddress(this.receiveCurrencySymbol, event.networkValue);
  }
}

  getMerchantRecognitionId() {
    this.convertService.getMerchantRecognitionId().subscribe(res => {
      this.merchantRecognitionId = res.merchantRecognitionId;
      this.appId = res.appId;
      this.onBuyFromOnRamp();
    })
  }

  onBuyFromOnRamp() {
    this.hideMainPage = true;
    let networkVal = this.networkForm.controls.networkType.value;
    const payload = {
      "merchantId": this.merchantRecognitionId,
      "toCurrencyId": this.form.controls["receivercurrencyId"].value,
      "amount": this.form.controls["senderAmount"].value,
      "network": networkVal,
      "walletAddress": this.walletAddress
    };
    this.convertService.getExchangeRateOnRamp(payload).subscribe(res => {

    });

    if ((this.receiveCurrencySymbol == 'CAS' || this.receiveCurrencySymbol == 'BNB' || this.receiveCurrencySymbol == 'XRP')
      && (networkVal == 'bep2' || networkVal == "xrp") && this.addressTag) {
      this.srcUrl = `https://onramp.money/main/buy?appId=${this.appId}&coinCode=${this.receiveCurrencySymbol}&fiatAmount=${this.form.controls["senderAmount"].value.split(",").join('')}&network=${this.networkForm.controls.networkType.value}&walletAddress=${this.walletAddress}&merchantRecognitionId=${this.merchantRecognitionId}&addressTag=${this.memo}`
    } else {
      this.srcUrl = `https://onramp.money/main/buy?appId=${this.appId}&coinCode=${this.receiveCurrencySymbol}&fiatAmount=${this.form.controls["senderAmount"].value.split(",").join('')}&network=${this.networkForm.controls.networkType.value}&walletAddress=${this.walletAddress}&merchantRecognitionId=${this.merchantRecognitionId}`
    }

  }

  onGetProcess() {
    this.getMerchantRecognitionId();
  }

  getWalletAddress(symbol, networkType?: string) {
    this.walletService.getCryptoAccountDetails(symbol).subscribe(
      (res) => {
        this.walletAddress = res.address;
        this.memo = res.memo;
      },
      (err) => {
        this.walletAddress = "";
      }
    );
  }

  generateAddress() {
    this.router.navigateByUrl(`wallet?c=${this.receiveCurrencySymbol}#${this.receiveCurrencySymbol}`);
  }

  getOnRampRate(chainId) {
    this.subsink.add(
      this.convertService
        .getOnRampRate(this.form.controls.sendercurrencyId.value, this.receivedAndSelectedPair[0], chainId[0])
        .subscribe((rateobj) => {
          this.rate = rateobj.rate;
          this.rateSymbol = rateobj.rateSymbol;
          this.casrate = rateobj.casRate;
          this.usdRate = rateobj.usdRate;
          this.minAmount = rateobj.minAmount;
          this.minAmountSymbol = rateobj.minAmountSymbol;
          this.minCASAmount = rateobj.minCASAmount;
          this.minCASAmountSymbol = rateobj.minCASAmountSymbol;
          sessionStorage.setItem(USER_CAS_FEE_RATE, this.casrate.toString());
          this.getNewConversionForReceiveChange(
            this.form.controls["receiverAmount"].value,
            true
          );
        })
    );
  }

}

<div class="p-4 text-center" [hidden]="!loading">
    <mdb-spinner spinnerColor="blue"></mdb-spinner>
</div>
<section *ngIf="!loading">
    <div class="ml-3">
        <h5 class="font-weight-bolder textdark">
            Cashaa offers robust API services. You can create API according to your needs and therefore access or trade
            in
            the
            market of cashaa.
        </h5>
    </div>

    <form [formGroup]="form" class="my-4">
        <div class="col-sm-4">
            <cashaa-form-input name="apiName" type="apiName" [form]="form" [control]="form.controls.apiName"
                placeholder="Enter the API Name" label="API Name">
            </cashaa-form-input>
        </div>
        <hr class="mt-4" />
        <div class="text-dark ml-3 mt-4">
            <h5 class="font-weight-bolder">Api Restrictions</h5>
        </div>
        <div class="pt-3 ml-3">
            <div class="d-inline-flex">
                <mdb-checkbox [checked]="true" [disabled]="true">
                </mdb-checkbox>
                <h5 class="font-weight-bold">General</h5>
            </div>
            <p class="font-weight-bolder mt-3 ml-4 pl-3">Users can only carry out read-only operations such as checking
                the account information, account statement and order information, etc. Users cannot place orders,
                withdraw, etc.</p>
        </div>
        <div formArrayName="features" *ngFor="let feature of featuresFormArray.controls; let i = index"
            class="pt-3 ml-3">
            <div>
                <mdb-checkbox [formControlName]="i">
                    <h5 class="font-weight-bold">{{featureList[i].name}}</h5>
                </mdb-checkbox>
                <p class="font-weight-bolder mt-3 ml-4 pl-3">{{featureList[i].description}}</p>
            </div>
        </div>
        <hr class="mt-5" />
        <div class="text-dark ml-3">
            <h5 class="font-weight-bolder"> IP Addess </h5>
        </div>
        <div formArrayName="ipAddress" class="pt-3 ml-3">
            <table mdbTable>
                <thead class="sm-back-color white-text">
                    <tr>
                        <th class="text-center">ID</th>
                        <th class="text-center"> IP Address</th>
                        <th class="text-center"> CIDR </th>
                        <th class="text-center"> Label </th>
                        <th class="text-center"> Service </th>
                        <th class="text-center"> Action </th>
                    </tr>
                </thead>
                <tbody>
                    <tr mdbTableCol *ngFor="let address of ipAddressFormArray.controls; let i = index">
                        <td class="text-center text-dark font-weight-bolder pt-4">{{i+1}}</td>
                        <td>
                            <cashaa-form-input name="ip" type="ip" [form]="address" [control]="address.get('ip')"
                                placeholder="IP address">
                            </cashaa-form-input>
                        </td>
                        <td>
                            <div
                                class="text-dark font-weight-bolder border address-length p-3 sm-input-height text-center">
                                /32
                            </div>
                        </td>
                        <td>
                            <cashaa-form-input name="label" type="label" [form]="address"
                                [control]="address.get('label')" placeholder="Label">
                            </cashaa-form-input>
                        </td>
                        <td>
                            <div class="text-dark font-weight-bolder border address-length p-3 sm-input-height">
                                Rest API
                            </div>
                        </td>
                        <td>
                            <div class="position-absolute icons pointer pt-2" *ngIf="i > 0">
                                <mdb-icon fas icon="times" size="2x" (click)="removeItem(i)"></mdb-icon>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="ml-1 mt-4 sm-pos-right">
            <div class="ml-2 mt-4">
                <p (click)="addItem()" [ngClass]="{ 'disableAddItem':this.ipAddressFormArray.value.length > 9 || this.form.invalid}"
                    class="pointer font-weight-bolder text-primary-dark " style="width: fit-content;">
                    Add another +
                </p>
            </div>
            <button mdbBtn (click)="onFormSubmit()" block="true" type="button"
                class="w-25 rounded-sm position-relative btn btn-primary waves-effect waves-light btn-block"
                mdbWavesEffect [disabled]="form.invalid">
                Submit
            </button>
        </div>
    </form>
</section>
import { FiatService } from "./../services/fiat.service";
import {
  Component,
  OnInit,
  ViewChildren,
  ViewChild,
  OnDestroy,
} from "@angular/core";
import { MdbStepperComponent } from "ng-uikit-pro-standard";
import { FormGroup, FormBuilder } from "@angular/forms";
import { ValidatorUtility } from "src/app/core/utility/validator.utility";
import { NavigationService } from "src/app/core/services/navigation/navigation.service";
import { RecipientService } from "src/app/recipient/services/recipient.service";
import { IRecipient, IRecipientDelete } from "src/app/core/models/fIat/recipients";
import { SubSink } from "subsink";

@Component({
  selector: "cashaa-fiat-flow-wrapper",
  templateUrl: "./fiat-flow-wrapper.component.html",
  styleUrls: ["./fiat-flow-wrapper.component.scss"],
})
export class FiatFlowWrapperComponent implements OnInit, OnDestroy {
  @ViewChild("stepper", { static: true }) stepper: MdbStepperComponent;

  /** amount form */
  amountForm: FormGroup;

  /** recipient form */
  recipientForm: FormGroup;

  /** is flow completed */
  completed = false;

  transId: string;

  isRecipientSkipped = false;

  subSink = new SubSink();

  sendMoneyRecipient: IRecipient;
  constructor(private fb: FormBuilder, private fiatService: FiatService, 
    private navigationService: NavigationService, private recipientService: RecipientService) {
    this.createEmptyForm();
  }

  ngOnInit(): void {
    this.subSink.add(this.recipientService.getSendMoneyRecipient().subscribe((rec: IRecipient) => {
      if (rec) {
          this.sendMoneyRecipient = rec;
      }
    }));
  }

  /**
   * create initial empty forms
   */
  createEmptyForm(): void {
    this.amountForm = this.fb.group({
      valid: ["", ValidatorUtility.Required],
    });
    this.recipientForm = this.fb.group({
      valid: ["", ValidatorUtility.Required],
    });
  }

  /**
   * on amount form continue click
   */
  onAmountFormContinue(event: boolean): void {
    this.amountForm.controls.valid.setValue(true);
    this.fiatService.setTransactionId(null);
    if (!event) {
      setTimeout(()=> {
      this.isRecipientSkipped = true;
      },10);
    }
    this.stepper.next();
  }

  /**
   * on amount form continue click
   */
  onRecipientFormContinue(): void {
    this.recipientForm.controls.valid.setValue(true);
    this.stepper.next();
  }

  /**
   * on go back click, go back
   * given number of steps
   */
  onGoBack(step: number): void {
    if (step === 1) {
      this.stepper.previous();
    } else if (step === 2) {
      this.stepper.resetAll();
    }
  }

  response(res: string): void {
    if (res !== null) {
      this.transId = res;
      this.completed = true;
    }
  }

  ngOnDestroy(): void {
    this.fiatService.emitCurrencySymbolEmitter(undefined);
    this.fiatService.emitFiatRecipientdData(undefined);
    this.fiatService.emitPasswordAuthDataEmitter(undefined);
    this.fiatService.emitPayRequestDataEmitter(undefined);
    this.fiatService.emitfiatFlowPaydDataEmitter(undefined);
    this.subSink.unsubscribe();
  }

  backRoute() {
    this.navigationService.back();
  }

  
}
